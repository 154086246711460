import {
  EDIT_PLAN_CREATED_STATUS,
  EDIT_PLAN_SUCCESS,
  EditPlanActionTypes,
  HIDE_EDIT_PLAN_MODAL,
  SHOW_EDIT_PLAN_MODAL,
} from '../actions/EditPlanActionTypes'
import { RootStore } from './Store'

interface IDefaultState {
  showModal: boolean
  section: number
  planCreatedFromEditor: boolean
  editPlanId: number | null
}

const defaultState: IDefaultState = {
  showModal: false,
  section: 0,
  planCreatedFromEditor: false,
  editPlanId: null,
}

const editPlanReducer = (
  state: IDefaultState = defaultState,
  action: EditPlanActionTypes
) => {
  switch (action.type) {
    case SHOW_EDIT_PLAN_MODAL:
      return {
        ...state,
        showModal: true,
        section: action.payload,
      }
    case HIDE_EDIT_PLAN_MODAL:
      return { ...state, showModal: false }
    case EDIT_PLAN_CREATED_STATUS:
      return { ...state, planCreatedFromEditor: action.payload }
    case EDIT_PLAN_SUCCESS:
      return {
        ...state,
        planCreatedFromEditor: true,
        editPlanId: action.payload.id,
      }
    default:
      return defaultState
  }
}

export const selectPlanCreatedFromEditor = (state: RootStore) => {
  return state.editPlan.planCreatedFromEditor
}

export const selectEditPlanId = (state: RootStore) => {
  return state.editPlan.editPlanId
}

export default editPlanReducer
