import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const DirectMailIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="800.000000pt"
      height="800.000000pt"
      viewBox="0 0 800.000000 800.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke={stroke || '#666'}
        strokeWidth="2.0"
      >
        <path
          d="M1159 6246 c-185 -52 -332 -213 -374 -411 -22 -105 -22 -3565 0
-3670 42 -200 190 -361 379 -411 76 -20 5596 -20 5672 0 189 50 337 211 379
411 22 105 22 3565 0 3670 -42 200 -190 361 -379 411 -73 20 -5606 19 -5677 0z
m5704 -159 c59 -29 161 -121 155 -139 -6 -16 -3002 -2378 -3013 -2375 -5 2
-687 539 -1516 1193 l-1506 1189 21 28 c29 39 107 95 164 118 45 17 145 18
2842 16 l2795 -2 58 -28z m-4400 -1482 c1257 -992 1513 -1191 1542 -1193 32
-3 131 73 1554 1197 l1520 1200 6 -32 c3 -18 4 -833 3 -1812 -3 -1678 -4
-1783 -21 -1825 -43 -110 -133 -201 -237 -241 -49 -18 -127 -19 -2829 -19
-3080 0 -2812 -6 -2919 67 -60 41 -115 111 -146 186 l-21 52 -3 1775 c-2 976
0 1794 3 1818 6 38 8 41 24 30 10 -7 696 -548 1524 -1203z"
        />
      </g>
    </svg>
  )
}

export default DirectMailIcon
