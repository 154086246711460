import { Alert, AlertProps } from '@gain-theory/alert'
import { Button } from '@gain-theory/button'
import { FileUpload, FileUploadProps } from '@gain-theory/file-upload'
import { Icon } from '@gain-theory/icon'
import { IconButton, IconButtonProps } from '@gain-theory/icon-button'
import { MenuButton, MenuButtonProps } from '@gain-theory/menu-button'
import { Tooltip } from '@gain-theory/tooltip'
import { Typography } from '@gain-theory/typography'
import { FC, useState } from 'react'
import { formatNumberString } from '../../../../utils/format-number-string'

interface OwnProps {
  currencyLabel: string
  budget: number
  numberFormat: string | undefined
  validationAlertProps: AlertProps
  anyRowSelected: boolean
  showFileUpload: boolean
  fileUploadProps: FileUploadProps
  planGenerationType: 'create' | 'edit'
  handleDownload: () => void
  handleToggleFileUpload: (show: boolean) => void
  handleAddRow: () => void
  handleActionClick: (action: 'delete' | 'disable' | 'enable' | 'reset') => void
}

type Props = OwnProps

const TimePeriodBudgetConstraintsTableHeader: FC<Props> = ({
  currencyLabel,
  budget,
  numberFormat,
  validationAlertProps,
  anyRowSelected,
  showFileUpload,
  fileUploadProps,
  planGenerationType,
  handleDownload,
  handleToggleFileUpload,
  handleAddRow,
  handleActionClick,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(true)

  const TOOLTIP_CONTENT_TITLE: Record<typeof planGenerationType, string> = {
    create:
      'Pre-populated constraints are either default or user added constraints from prior edits, and modifying them will impact only this plan. New or additional min, max, equal, and index constraints can be added.',
    edit: 'Pre-populated constraints are either default or user added constraints from prior edits, and modifying them will impact only this plan. New or additional min, max, equal, and index constraints can be added.',
  }

  const infoAlertProps: AlertProps = {
    inline: true,
    variant: 'info',
    show: showAlert,
    description: 'When using index constraints, blank or zero values result in zero budget allocation.',
    onClose: () => setShowAlert(false),
  }

  const headerIconActions = [
    {
      name: 'CloudArrowUpIcon',
      tip: 'Upload',
      variant: showFileUpload ? 'brand-primary' : 'primary',
      onClick: () => handleToggleFileUpload(!showFileUpload),
    },
    {
      name: 'ArrowDownTrayIcon',
      tip: 'Download',
      variant: 'primary',
      onClick: handleDownload,
    },
  ]

  const menuButtonProps: MenuButtonProps = {
    position: 'bottom-right',
    menuToggle: {
      label: 'Actions',
      leadingIconName: 'PencilSquareIcon',
      variant: 'secondary',
    },
    menuItems: [
      {
        label: 'Delete selected',
        leadingIconName: 'TrashIcon',
        disabled: !anyRowSelected,
        onClick: () => handleActionClick('delete'),
      },
      {
        label: 'Disable selected',
        leadingIconName: 'PauseCircleIcon',
        disabled: !anyRowSelected,
        onClick: () => handleActionClick('disable'),
      },
      {
        label: 'Enable selected',
        leadingIconName: 'PlayCircleIcon',
        disabled: !anyRowSelected,
        onClick: () => handleActionClick('enable'),
      },
      {
        label: 'Reset table',
        leadingIconName: 'ArrowPathIcon',
        onClick: () => handleActionClick('reset'),
      },
    ],
  }

  const TooltipContent = (
    <div className="flex flex-col gap-md">
      <Typography.Body1 className="text-info-dark">
        {'Using constraints'}
      </Typography.Body1>
      <Typography.Body2>
        {TOOLTIP_CONTENT_TITLE[planGenerationType]}
      </Typography.Body2>
      <Typography.Body2>
        {`Index constraint allows for proportional flighting, which acts as a
        ratio between each time period in the plan relative to the overall
        channel budget.`}{' '}
        <br />
        {`(Example: Week 1 index: 1 || Week 2 index: 2 || Week 3 index: 4 - in
        this example, the budget that GTi allocates for the channel will be
        allocated so that week 2 is double that of week 1, and week 3 is double
        that of week 2 and four times that of week 1.)`}
      </Typography.Body2>
      <Typography.Body2>
        <em>
          {`Note: While min and max constraints may be used on the same curve to
          create a range, index cannot be used in conjunction with min, max, or
          equal.`}
        </em>
      </Typography.Body2>
    </div>
  )

  const Title = (
    <div className="flex gap-2 items-center w-1/2">
      <Typography.H6>Set budget by time period</Typography.H6>
      <Tooltip interactive content={TooltipContent} position="bottom">
        <Icon name="InformationCircleIcon" color="info-main" />
      </Tooltip>
    </div>
  )

  const HeaderIconActions = (
    <div className="flex gap-4 w-1/2 flex-row-reverse">
      {headerIconActions.map((action) => (
        <Tooltip key={action.tip} content={action.tip} position="bottom">
          <IconButton
            iconName={action.name as IconButtonProps['iconName']}
            variant={action.variant as IconButtonProps['variant']}
            onClick={action.onClick}
          />
        </Tooltip>
      ))}
    </div>
  )

  const PlanCurrency = (
    <div className="flex flex-col gap-xxs justify-start items-start">
      <Typography.Overline>Plan currency</Typography.Overline>
      <Typography.Body1>{currencyLabel}</Typography.Body1>
    </div>
  )

  const PlanBudget = (
    <div className="flex flex-col gap-xxs justify-start items-start">
      <Typography.Overline>Plan budget</Typography.Overline>
      <Typography.Number>
        {formatNumberString({
          value: budget.toString(),
          decimalSeparator: numberFormat === 'euro' ? ',' : '.',
          thousandSeparator: numberFormat === 'euro' ? '.' : ',',
        })}
      </Typography.Number>
    </div>
  )

  const AlertsContainer = (
    <span className="flex flex-col gap-xs">
      <Alert {...infoAlertProps} />
      <Alert {...validationAlertProps} />
    </span>
  )

  const HeaderTableActions = (
    <div className="flex items-center gap-md">
      <Button
        variant="tertiary"
        onClick={handleAddRow}
        leadingIcon={<Icon name="PlusCircleIcon" color="brand-primary-main" />}
      >
        Add row
      </Button>
      <MenuButton {...menuButtonProps} />
    </div>
  )

  return (
    <div className="flex flex-col gap-xl relative z-10">
      <div className="flex gap-2 items-center">
        {Title}
        {HeaderIconActions}
      </div>
      {showFileUpload && <FileUpload {...fileUploadProps} />}
      <div className="flex gap-4 items-center">
        {PlanCurrency}
        {PlanBudget}
      </div>
      <div className="flex items-center justify-between">
        {AlertsContainer}
        {HeaderTableActions}
      </div>
    </div>
  )
}

export default TimePeriodBudgetConstraintsTableHeader
