import { useNotificationContext } from '../../../../../../shared/notification-provider'

export const useTimeConstraintsNotification = () => {
  const { toast } = useNotificationContext()

  const notification = {
    fileDownloadError: (error: string) =>
      toast(
        'error',
        'Error downloading file',
        [
          'An error occurred while downloading the file. Please try again.',
          error,
        ].join(' ')
      ),

    rowsDeletedSuccessfully: () =>
      toast(
        'success',
        'Deleted successfully',
        'The selected rows have been deleted for this plan.'
      ),

    rowsDisabledSuccessfully: () =>
      toast(
        'success',
        'Disabled successfully',
        'The selected rows have been disabled and will be ignored for this plan.'
      ),

    rowsEnabledSuccessfully: () =>
      toast(
        'success',
        'Enabled successfully',
        'The selected rows have been enabled for this plan.'
      ),

    planFileUploadedSuccessfully: () =>
      toast(
        'success',
        'Plan file uploaded successfully',
        'All changes were applied.'
      ),
  }

  return notification
}
