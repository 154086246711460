import React, { FC, Fragment } from 'react'
import { Channel } from '../types/MarketingDiagnosticsTypes'
import DownArrowIcon from '../icons/DownArrowIcon'
import UpArrowIcon from '../icons/UpArrowIcon'
import StableIcon from '../icons/StableIcon'
import NoValueIcon from '../icons/NoValueIcon'
import Tooltip from 'rc-tooltip'
import { type } from 'os'

interface OwnProps {
  previous: Channel | undefined
  metric: string
  channel: Channel
  type: string
}

type Props = OwnProps

const showOverlay = (comparisonDisplay: any, props: any)  => {
 
  return(
    <div className='bg-white opacity-100 border-1 border-gray-300 w-72 py-1 px-8'>
      <p className={'uppercase mb-5'}>{props.type}</p>
      <div className={'flex-row flex'}>
        <div className={'w-6/12'}>
          <span>{props.type === 'effectiveness'? props.channel.effectiveness.toFixed(1) : props.channel.productivity.toFixed(1)}</span>
          {comparisonDisplay(props.metric)}
        </div>
        {props.previous && <div className={'w-6/12'}>
          Your Paid Search {props.type} score {props.metric} compared to {props.type === 'effectiveness'? props.previous.effectiveness.toFixed(1) : props.previous.productivity.toFixed(1)} last period.
        </div>}
      </div>
    </div>
  )
}

const MetricComparisonCell: FC<Props> = (props) => {
  const tooltipOverlayInnerStyle = {
    fontSize: '14px',
  }

  const comparisonDisplay = (metric: string) => {
    if (metric === 'is lower') {
      return <DownArrowIcon className="w-7 h-7 cursor-pointer" />
    }
    if (metric === 'is higher') {
      return <UpArrowIcon className="w-7 h-7 cursor-pointer" />
    }
    return <StableIcon className="w-7 h-7 cursor-pointer" />
  }

  if (props.metric) {
    return (
      <Fragment>
        <div className="flex">
          <Tooltip
            placement="right"
            trigger={['hover']}
            overlay={showOverlay(comparisonDisplay, props)}
            overlayInnerStyle={tooltipOverlayInnerStyle}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
          >
          <a
            href='#'
            >
            {comparisonDisplay(props.metric)}
          </a>
          </Tooltip>
        </div>
      </Fragment>
    )
  }

  return (
    <div
      style={{
        marginLeft: '30px',
      }}
    >
      {props.channel.effectiveness === 0 ? (
        <NoValueIcon className="w-5 h-5" />
      ) : (
        props.channel.effectiveness.toFixed(2)
        
      )}
    </div>
  )
}

export default MetricComparisonCell