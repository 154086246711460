import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
  GroupingLevel,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { HierarchyOptionType } from '../../types'

interface IUseHierarchyOptionsProps {
  groupingLevels: GroupingLevel[]
  transformationOptions: Transformation[]
}

export const useHierarchyOptions = ({
  groupingLevels,
  transformationOptions,
}: IUseHierarchyOptionsProps) => {
  const [options, setOptions] = useState<HierarchyOptionType[]>([])

  const getParentsForOption = (level: number, optionKey: string) => {
    if (level === 0) return []

    const parents: string[][] = []

    const previousLevels = groupingLevels.slice(0, level).map((x) => x.key)
    const currentLevelKey = groupingLevels[level].key

    const transformationsWithCurrentLevel = transformationOptions
      .filter((x) => x.identifiers[currentLevelKey] === optionKey)
      .map((x) => _.pick(x.identifiers, previousLevels))

    transformationsWithCurrentLevel.forEach((transformation) => {
      const parentsKeys = Object.values(transformation)
      parents.push(parentsKeys)
    })

    return _.uniqWith(parents, _.isEqual)
  }

  const calculateOptionsForLevel = (level: number) => {
    const levelKey = groupingLevels[level].key

    const levelOptionsKeys = transformationOptions.map(
      (x) => x.identifiers[levelKey]
    )

    const levelOptions: HierarchyOptionType[] = _.uniq(levelOptionsKeys).map(
      (optionKey) => ({
        levelKey,
        key: optionKey,
        label:
          groupingLevels[level].values.find((x) => x.key === optionKey)
            ?.label || '',
        parents: getParentsForOption(level, optionKey),
      })
    )

    return levelOptions
  }

  useEffect(() => {
    const numberOfLevels = groupingLevels.length
    const newOptions: HierarchyOptionType[] = []

    for (let i = 0; i < numberOfLevels; i++) {
      const levelOptions = calculateOptionsForLevel(i)
      newOptions.push(...levelOptions)
    }

    setOptions(newOptions)
  }, [transformationOptions, groupingLevels])

  return options
}
