import { BaseInputProps, Input } from '@gain-theory/input'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useClassNames } from './classes'
import { DateFormat } from './constants'
import { DatePickerProps } from './types'

export const DatePicker = ({
  label,
  state,
  helperText,
  errorMessage,
  dateFormat = DateFormat.DD_MM_YYYY,
  ...props
}: DatePickerProps<boolean | undefined>) => {
  const classNames = useClassNames()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DatePickInput = (props: any) => (
    <Input.Base
      {...(props as BaseInputProps)}
      autoComplete="off"
      label={label}
      leadingIcon={undefined}
      state={state}
      trailingIcon={{ name: 'CalendarIcon', onClick: props.onClick }}
      helperText={helperText}
      errorMessages={{
        default: errorMessage,
      }}
    />
  )

  return (
    <div className={classNames.wrapper}>
      <ReactDatePicker
        {...props}
        customInput={<DatePickInput />}
        dateFormat={dateFormat}
        popperModifiers={[
          {
            name: 'arrow',
            options: { padding: 24 },
          },
        ]}
      />
    </div>
  )
}

DatePicker.displayName = 'DatePicker'
