import { Card } from '@gain-theory/card'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { memo } from 'react'
import { LinkCardProps } from './types'

export const LinkCard = memo(
  ({ id, title, url, disabled, onCardClick }: LinkCardProps) => {
    return (
      <Card
        id={id}
        data-testid={id ? `${id}-link-card` : `link-card`}
        disabled={disabled}
        onClick={() => onCardClick(url)}
        className={`flex flex-row gap-md w-full items-center select-none justify-center ${
          Boolean(disabled) ? '' : 'cursor-pointer'
        }`}
      >
        <Icon
          name="LinkIcon"
          library="heroicon-outline"
          color="brand-primary-main"
        />
        <Typography.H6>{title}</Typography.H6>
      </Card>
    )
  }
)
