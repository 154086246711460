import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const SponsorshipIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M58.645 27.667C58.645 37.242 45.81 45 29.978 45S1.31 37.242 1.31 27.667c0-9.573 12.835-17.334 28.667-17.334s28.667 7.761 28.667 17.334z" />
          <path strokeLinecap="round" d="M47.978 31.667h14.61" />
          <path d="M43.2 12.855l2.11-2.521h16l-5.226 9.059M43.514 43.045L45.31 45h16l-5.24-9.034M17.311 43.941V53h21.334V43.94" />
        </g>
      </g>
    </svg>
  )
}

export default SponsorshipIcon
