import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import { ROUTES } from '../../Routes'
import { NavbarMenuItem } from './types'

const reportingSubmenuBase = [
  {
    label: 'Highlights',
    route: ROUTES.MARKETING_DIAGNOSTICS.HIGHLIGHTS,
  },
] as NavbarMenuItem['submenu']

const reportingMenuBase = {
  label: 'Reporting',
  iconName: 'MegaphoneIcon',
  route: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
  showBadge: false,
} as any as NavbarMenuItem

function useReportingMenu() {
  const { modules } = useSelector((state: AppState) => state.session)
  const { highlights } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const [reportingMenu, setReportingMenu] = useState({ ...reportingMenuBase })
  const [active, setActive] = useState(false)
  const [sensor, setSensor] = useState(false)

  useEffect(() => {
    let moduleActive = false

    if (modules && modules.length > 0) {
      const gtModule = modules.find((gtModule) => gtModule.key === 'md')
      if (
        gtModule &&
        gtModule?.submodules &&
        gtModule?.submodules.length > 0 &&
        gtModule?.submodules.find(
          (submodule) => submodule.toLowerCase() === 'sensor'
        ) !== undefined
      ) {
        setSensor(true)
      }

      moduleActive = !!gtModule && gtModule.active
    }

    setActive(moduleActive)
  }, [modules])

  useEffect(() => {
    const showSubmenu =
      active &&
      highlights &&
      (!highlights.error || highlights.error === '') &&
      sensor

    const newReportingMenu = {
      ...reportingMenuBase,
      disabled: !active,
      ...(showSubmenu && { submenu: [...reportingSubmenuBase] }),
    } as any as NavbarMenuItem

    setReportingMenu(newReportingMenu)
  }, [active, highlights])

  return reportingMenu
}

export default useReportingMenu
