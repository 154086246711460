import {
  InChannelSettings,
  OktaSetting,
  Settings,
} from '../../types/SettingTypes'
import {
  ConfigActionTypes,
  CREATE_APP_CONFIG_SUCCESS,
  CREATE_MD_CONFIG_SUCCESS,
  CREATE_SPO_CONFIG_SUCCESS,
} from '../actions/ConfigActionTypes'
import {
  CREATE_GROUP_SUCCESS,
  SessionActionTypes,
} from '../actions/SessionActionTypes'
import {
  SettingActionTypes,
  SETTINGS_GET_ATTEMPT,
  SETTINGS_GET_FAIL,
  SETTINGS_GET_OKTA_SETTINGS_ATTEMPT,
  SETTINGS_GET_OKTA_SETTINGS_FAIL,
  SETTINGS_GET_OKTA_SETTINGS_SUCCESS,
  SETTINGS_GET_SUCCESS,
  SETTINGS_SELECT_OKTA_SETTING,
  SETTINGS_UPDATE_SUCCESS,
} from '../actions/SettingActionTypes'
import { RootStore } from './Store'

interface DefaultState {
  loading: boolean
  oktaSettings: OktaSetting[]
  settings: Settings | null
  inChannelSettings: InChannelSettings | null
  selectedOktaSetting: OktaSetting | null
  adminSuccess: string
}

const initState: DefaultState = {
  loading: false,
  oktaSettings: [],
  settings: null,
  inChannelSettings: null,
  selectedOktaSetting: null,
  adminSuccess: '',
}

const settingsReducer = (
  state: DefaultState = initState,
  action: SettingActionTypes | SessionActionTypes | ConfigActionTypes
) => {
  switch (action.type) {
    case SETTINGS_GET_ATTEMPT:
      return { ...state, loading: true }
    case SETTINGS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.settings,
        inChannelSettings: action.inChannelSettings,
      }
    case SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
        adminSuccess: 'Success!',
      }
    case CREATE_GROUP_SUCCESS:
      return { ...state, adminSuccess: 'Success!' }
    case CREATE_APP_CONFIG_SUCCESS:
      return { ...state, adminSuccess: 'Success!' }
    case CREATE_MD_CONFIG_SUCCESS:
      return { ...state, adminSuccess: 'Success!' }
    case CREATE_SPO_CONFIG_SUCCESS:
      return { ...state, adminSuccess: 'Success!' }
    case SETTINGS_GET_FAIL:
      return { ...state, loading: false }
    case SETTINGS_GET_OKTA_SETTINGS_ATTEMPT:
      return { ...state, loading: true, oktaSettings: [] }
    case SETTINGS_GET_OKTA_SETTINGS_FAIL:
      return { ...state, loading: false, oktaSettings: [] }
    case SETTINGS_GET_OKTA_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        oktaSettings: action.payload,
        selectedOktaSetting: action.payload[0],
      }
    case SETTINGS_SELECT_OKTA_SETTING:
      return { ...state, selectedOktaSetting: action.payload }
    default:
      return state
  }
}

// export const selectSettingLoading = (state: RootStore) => {
//   return state.settings.loading
// }

export const selectSettings = (state: RootStore) => {
  return state.settings.settings
}

export const adminSuccess = (state: RootStore) => {
  return state.settings.adminSuccess
}

export default settingsReducer
