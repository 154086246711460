import _ from "lodash";


export function getUniqueIOs(scenario) {
    scenario.config.transformations.map(transformation =>
        transformation.io.map(
            io => (io.match = io.label.toLowerCase().replace(/ /g, "_"))
        )
    );
    return scenario.config.transformations.reduce((memo, transformation) => {
        return {
            ...memo,
            ...transformation.io.reduce((memo_, io) => {
                return {
                    ...memo_,
                    [io.match]: io
                };
            }, {})
        };
    }, {});
}


export function getIOTotals(scenario) {
    const uniqueIOs = getUniqueIOs(scenario);

    const iototals = Object.entries({
        ...scenario.optimised_totals,
        ...scenario.calculated_totals
    }).map(([match, mixedTotal]) => {
        return {
            ...uniqueIOs[match],
            id: undefined,
            mixedTotal,
            optimisedTotal: (scenario.optimised_totals || {})[match],
            outputTotal: (scenario.calculated_totals || {})[match]
        };
    });
    return iototals;
}

export function getTotalSpendForScenario(scenario) {
    return getTotalSpendFromTransformations(
        getScenarioTransformations(scenario),
        scenario
    );
}

export function getTotalSpendFromTransformations(transformations, scenario) {
    return transformations.map(mapTransformationForMainInput(scenario)).reduce(
        (memo, transformation) => {
            return {
                unit: transformation.mainInput.unit,
                total:
                    memo.total +
                    transformation.mainInput.values.reduce(
                        (memo_, val) => memo_ + (val ? val : 0)
                    )
            };
        },
        {
            unit: undefined,
            total: 0
        }
    );
}


export function getScenarioBrandsForSelectedRegions(
    selectedRegionTransforms,
    scenario
) {
    const allTransformations = getScenarioTransformations(scenario);

    const brands = Object.keys(
        selectedRegionTransforms.reduce(
            (memo, transformation) => ({
                ...memo,
                [transformation.identifiers.brand]: true
            }),
            {}
        )
    )
        .map(brandKey =>
            scenario.config.grouping_levels
                .find(level => level.key === "brand")
                .values.find(value => value.key === brandKey)
        )
        .map(brand => ({
            ...brand,
            ...getFilteredTransformations(allTransformations, {
                brand: [brand.key]
            })
                .map(
                    mapRegionsTransformationForMainInput(
                        scenario,
                        selectedRegionTransforms
                    )
                )
                .reduce(
                    (memo, transformation) => {
                        return {
                            regions: [
                                ...memo.regions,
                                transformation.identifiers.region_key
                            ],
                            optimisedTotal:
                                memo.optimisedTotal +
                                (
                                    transformation.mainInput.optimised || []
                                ).reduce(
                                    (memo, val) => memo + (val ? val : 0),
                                    0
                                ),
                            outputTotal:
                                memo.outputTotal +
                                (transformation.mainInput.output || []).reduce(
                                    (memo, val) => memo + (val ? val : 0),
                                    0
                                ),
                            mixedTotal:
                                memo.mixedTotal +
                                (transformation.mainInput.values || []).reduce(
                                    (memo, val) => memo + (val ? val : 0),
                                    0
                                ),
                            unit: transformation.mainInput.unit
                        };
                    },
                    {
                        optimisedTotal: 0,
                        outputTotal: 0,
                        mixedTotal: 0,
                        unit: undefined,
                        regions: []
                    }
                )
        }));

    return brands;
}



export function mapRegionsTransformationForMainInput(
    scenario,
    regionTransforms
) {
    return cTransformation => {
        // determine main input, then apply output, input, optimised and default values for it
        const sTransformation = getTransformationByIdentifiers(
            regionTransforms,
            cTransformation.identifiers
        );

        const cMainInput = cTransformation.io.find(
            io => ["spend"].indexOf(io.key) !== -1
        );
        const sInput = ((sTransformation || {}).inputs || {})[cMainInput.key];
        const sOutput = ((sTransformation || {}).calculated || {})[
            cMainInput.key
            ];
        const sOptimised = ((sTransformation || {}).optimised || {})[
            cMainInput.key
            ];

        return {
            ...cTransformation,
            mainInput: {
                ...cMainInput,
                input: sInput,
                optimised: sOptimised,
                output: sOutput,
                values:
                    sInput ||
                    sOutput ||
                    sOptimised ||
                    cMainInput.values.slice(
                        scenario.observations_min,
                        scenario.observations_max + 1
                    )
            }
        };
    };
}


export function getTransformsForRegions(regionKeys, scenario) {
    const transformations = regionKeys
        .map(key => {
            return scenario.transformations.filter(transform => {
                return transform.identifiers.region_key === key;
            });
        })
        .reduce((prev, next) => {
            return [...prev, ...next];
        }, []);

    return transformations;
}

export function getRemainingBudgetForThisFilter(scenario, filters) {
    const totalBudget =
        scenario.user_input.optimisationType === "budget"
            ? { total: scenario.user_input.overallConstraint }
            : getTotalSpendForScenario(scenario);

    const transformations = getFilteredTransformations(
        getScenarioTransformations(scenario),
        filters,
        true
    );
    const spentElsewhere = getTotalSpendFromTransformations(
        transformations,
        scenario
    );

    return {
        unit: spentElsewhere.unit,
        total: totalBudget.total - spentElsewhere.total
    };
}

export function getScenarioOutputs(scenario, filters) {
    const scenarioOutputs = getOutputsWithSpend(
        getScenarioIOs(scenario, filters)
    );
    return scenarioOutputs;
}

export function getScenarioIOs(scenario, filters) {
    return getIOs(
        getFilteredTransformations(
            getScenarioTransformations(scenario),
            filters
        )
    );
}

export function getScenarioGroupingLevelsWithFilteredValues(scenario) {
    return scenario.config.grouping_levels.map(level => {
        return {
            ...level,
            values: level.values.filter(val => {
                const filter = scenario.grouping_levels[level.key] || [];
                return filter.length < 1 || filter.indexOf(val.key) !== -1;
            })
        };
    });
}


export function getConstraintTable({ constraints, grouping_levels }) {
    const constraintsArr = (constraints || []).map(constraint => {
        return [
            // find by label because HandsOnTable doesnt support key=>val in autocomplete
            ...grouping_levels.map(
                level =>
                    level.values.find(
                        val => val.key === constraint.identifiers[level.key]
                    )
                        ? level.values.find(
                        val =>
                            val.key === constraint.identifiers[level.key]
                        ).label
                        : ""
            ),
            // constraint.variable,
            constraint.fn,
            ...constraint.values
        ];
    });
    //swap these two columns around as constraints expect media before campaign
    for (let row of constraintsArr) {
        [row[2], row[3]] = [row[3], row[2]];
    }
    return constraintsArr;
}


export function getMediaChannelAllocations(scenario, filters) {
    const transforms = getFilteredTransformations(
        getScenarioTransformations(scenario),
        filters
    );
    const mainInputTransforms = transforms.map(
        mapTransformationForMainInput(scenario)
    );
    const mediaChannelAlloc = mainInputTransforms.map(transformation => {
        return {
            ...transformation,
            mainInput: {
                ...transformation.mainInput,
                optimisedTotal: (
                    transformation.mainInput.optimised || []
                ).reduce((memo, val) => memo + (val ? val : 0), 0),
                outputTotal: (transformation.mainInput.output || []).reduce(
                    (memo, val) => memo + (val ? val : 0),
                    0
                ),
                mixedTotal: (transformation.mainInput.values || []).reduce(
                    (memo, val) => memo + (val ? val : 0),
                    0
                )
            }
        };
    });
    return mediaChannelAlloc;
}


export function getObservationAllocation(scenario, filters) {
    console.log(scenario)
    const transforms = getFilteredTransformations(
        getScenarioTransformations(scenario),
        filters
    );
    console.log(transforms)
    const transformsMainInput = transforms.map(
        mapTransformationForMainInput(scenario)
    );

    console.log(transformsMainInput)

    // now establish ranges of zero spend obs and non-zero spend obs
    const obsAllocation = transformsMainInput.map(transformation => {
        transformation.mainInput.ranges = transformation.mainInput.values.reduce(
            (memo, value) => {
                const currentRangeIsNonZero = !(memo.length % 2); // odd is zero
                const valueIsNonZero = (value ? value : 0) > 0;

                if (currentRangeIsNonZero !== valueIsNonZero) {
                    memo.push([]);
                }

                memo[memo.length - 1].push(value);

                return memo;
            },
            [
                [] // first item is always zero (so that odd items are always zero)
            ]
        );

        // make arrays where the first element is the range position and second element is the length
        // ONLY NON-ZERO RANGES HERE
        transformation.mainInput.rangePositions = transformation.mainInput.ranges.reduce(
            (memo, value, key) => {
                const isNonZero = key % 2;

                if (isNonZero) {
                    memo[memo.length - 1].push(value.length);
                } else if (key !== transformation.mainInput.ranges.length - 1) {
                    // is not last empty range
                    const previousPosition =
                        key === 0 ? 0 : memo[memo.length - 1][0];
                    const previousLength =
                        key === 0 ? 0 : memo[memo.length - 1][1];
                    const absolutePosition =
                        value.length + previousPosition + previousLength;
                    memo.push([absolutePosition]);
                }

                return memo;
            },
            []
        );

        return transformation;
    });
    return obsAllocation;
}

const calculateAllBrandsMixedTotal = brands => {
    if (brands) {
        let totalSpend = 0;
        for (let brand of brands) {
            totalSpend += brand.mixedTotal;
        }
        return totalSpend;
    }

    return 0;
};

export function getVariableNames(config) {
    return Object.keys(
        config.transformations
            .reduce((memo, transformation) => {
                return [...memo, ...transformation.io.map(io => io.key)];
            }, [])
            .reduce((memo, name) => {
                return {
                    ...memo,
                    [name]: true
                };
            }, {})
    );
}



export function getScenarioDefaultFourthLevelSelected(scenario) {
    return getScenarioFourthLevel(scenario).map(level => {
        return level.key;
    });
}

export function getScenarioFourthLevel(scenario) {
    const allTransformations = getScenarioTransformations(scenario);
    const fourthLevelName = scenario.config.grouping_levels.find(x => x.key === "campaign").key;
    const fourthLevelFromTransforms = _.uniq(
        allTransformations.map(trans => trans.identifiers[fourthLevelName])
    );

    let fourthLevels = [];
    for (let i in fourthLevelFromTransforms) {
        fourthLevels[i] = scenario.config.grouping_levels.find(x => x.key === "campaign").values.filter(
            val => val.key === fourthLevelFromTransforms[i]
        );
    }

    const fourthLevelsReturn = [].concat.apply([], fourthLevels);

    return fourthLevelsReturn;
}


export function getOutputs(ios) {
    return ios.filter(io => io.type === "output");
}


export function getConfigOutputs(config) {
    const outputs = getOutputs(getIOs(config.transformations));
    return outputs;
}


export function getIOs(transformations) {
    const ios = Object.values(
        transformations.reduce((memo, transformation) => {
            return {
                ...memo,
                ...transformation.io.reduce((memo_, io) => {
                    return {
                        ...memo_,
                        [io.key]: io
                    };
                }, {})
            };
        }, {})
    ).map(io => ({
        ...io,
        id: undefined,
        values: undefined
    }));
    return ios
}


export function getOptimisedValuesForRegion(scenario, regionKey) {
    return scenario.transformations
        .filter(
            transformation => transformation.identifiers.region === regionKey
        )
        .map(transformation => transformation.optimised)
        .reduce((prev, next) => {
            let result = {};
            Object.keys(next).forEach(key => {
                const val = next[key].reduce((p, n) => {
                    return p + n;
                }, 0);

                if (prev[key]) {
                    result[key] = prev[key] += val;
                    return { [key]: (prev[key] += val) };
                } else {
                    result[key] = val;
                    return { [key]: (prev[key] = val) };
                }
            });

            if (result["roi"]) {
                result["roi"] = result["profit"] / result["spend"];
            }
            return result;
        }, {});
}


export function getOutputValuesForRegion(scenario, regionKey) {
    return scenario.transformations
        .filter(
            transformation => transformation.identifiers.region === regionKey
        )
        .map(transformation => {
            if (transformation.calculated) {
                return transformation.calculated;
            }
            return transformation.optimised;
        })
        .reduce((prev, next) => {
            let result = {};
            Object.keys(next).forEach(key => {
                const val = next[key].reduce((p, n) => {
                    return p + n;
                }, 0);
                if (prev[key]) {
                    result[key] = prev[key] += val;
                    return {[key]: (prev[key] += val)};
                } else {
                    result[key] = val;
                    return {[key]: (prev[key] = val)};
                }
            });

            if (result["roi"]) {
                result["roi"] = result["profit"] / result["spend"];
            }

            return result;
        }, {});
}

export function getOutputsWithSpend(ios) {
    return ios.filter(io => io.type === "output" || io.key === "spend");
}

export function getScenarioTotalsByGroupingLevel(scenario, filters, level) {
    const tr = getScenarioTransformations(scenario);

    const sTransIntermediate = getFilteredTransformations(tr, filters);

    const sTransformations = sTransIntermediate.map(
        cTransformation => cTransformation.sTransformation
    );

    return getOutputsWithSpend(getScenarioIOs(scenario, filters)).map(
        (output, key, filteredTransformations) => {
            const totals = sTransformations
                .map(transformation => {
                    // const values = transformation.calculated[output.key] || transformation.optimised[output.key];
                    // const total = (values || []).reduce((memo, value) => memo + value, 0)

                    let optimisedTotal, outputTotal;
                    if (output.key === "spend") {
                        if (
                            transformation.optimised &&
                            transformation.optimised.spend
                        ) {
                            optimisedTotal = transformation.optimised.spend.reduce(
                                (prev, next) => prev + next
                            );
                        }

                        if (
                            transformation.calculated &&
                            transformation.calculated.spend
                        ) {
                            outputTotal = transformation.calculated.spend.reduce(
                                (prev, next) => prev + next
                            );
                        }
                    } else {
                        optimisedTotal = (transformation.optimised_totals ||
                            {})[output.key];
                        outputTotal = (transformation.calculated_totals || {})[
                            output.key
                            ];
                        if (!optimisedTotal) {
                            optimisedTotal = (transformation.optimised_totals ||
                                {})[output.match];
                        }
                        if (!outputTotal) {
                            outputTotal = (transformation.calculated_totals ||
                                {})[output.match];
                        }
                        if (!optimisedTotal) {
                            optimisedTotal = (transformation.optimised_totals ||
                                {})[
                                output.label.toLowerCase().replace(/ /g, "_")
                                ];
                        }
                        if (!outputTotal) {
                            outputTotal = (transformation.calculated_totals ||
                                {})[
                                output.label.toLowerCase().replace(/ /g, "_")
                                ];
                        }
                    }
                    const mixedTotal = outputTotal || optimisedTotal;

                    return [
                        transformation.identifiers[level],
                        {
                            optimisedTotal,
                            outputTotal,
                            mixedTotal
                        }
                    ];
                })
                .reduce(
                    (memo, [level, theseTotals]) => ({
                        ...memo,
                        [level]: theseTotals
                    }),
                    {}
                );

            return {
                ...output,
                totals
            };
        }
    );
}

export function getScenarioTotalsByMediaChannel(scenario, filters) {
    return getScenarioTotalsByGroupingLevel(scenario, filters, "media");
}

export function getScenarioTotalsByBrand(scenario, filters) {
    return getScenarioTotalsByGroupingLevel(scenario, filters, "brand");
}

export function getScenarioTotalsByRegion(scenario, filters) {
    return getScenarioTotalsByGroupingLevel(scenario, filters, "region");
}

export function getScenarioRegions(scenario) {
    const scenarioRegions = scenario.config.grouping_levels.find(
        level => level.key === "region"
    ).values;
    const regions = Object.keys(
        scenario.transformations.reduce((memo, transformation) => {
            // .values.find(value => value.key === regionKey)

            return {
                ...memo,
                [transformation.identifiers.region]: true
            };
        }, {})
    ).map(regionKey => {
        const region = scenarioRegions.find(region => region.key === regionKey);
        const filter = { region: [regionKey] };
        const data = getScenarioTotalsByRegion(scenario, filter);
        const values = data.map(item => {
            return {
                key: item.key,
                label: item.label,
                totals: item.totals[regionKey]
            };
        });
        return {
            key: regionKey,
            ISO_A2: region.ISO_A2,
            label: region.label,
            values
        };
    });

    for (let region of regions) {
        region.optimisedTotal = getOptimisedValuesForRegion(
            scenario,
            region.key
        );
        region.mixedTotal = getOutputValuesForRegion(scenario, region.key);
    }
    return regions;
}

export function getScenarioDefaultRegionsSelected(scenario) {
    return getScenarioRegions(scenario).map(region => {
        return region.key;
    });
}


export function mapTransformationForMainInput(scenario) {
    return cTransformation => {
        // determine main input, then apply output, input, optimised and default values for it
        const sTransformation = getTransformationByIdentifiers(
            scenario.transformations,
            cTransformation.identifiers
        );

        const cMainInput = cTransformation.io.find(
            io => ["spend"].indexOf(io.key) !== -1
        );
        const sInput = ((sTransformation || {}).inputs || {})[cMainInput.key];
        const sOutput = ((sTransformation || {}).calculated || {})[
            cMainInput.key
            ];
        const sOptimised = ((sTransformation || {}).optimised || {})[
            cMainInput.key
            ];

        return {
            ...cTransformation,
            mainInput: {
                ...cMainInput,
                input: sInput,
                optimised: sOptimised,
                output: sOutput,
                values:
                    sInput ||
                    sOutput ||
                    sOptimised ||
                    cMainInput.values.slice(
                        scenario.observations_min,
                        scenario.observations_max + 1
                    )
            }
        };
    };
}


export function getScenarioBrands(scenario) {
    const allTransformations = getScenarioTransformations(scenario);
    const brands = Object.keys(
        scenario.transformations.reduce(
            (memo, transformation) => ({
                ...memo,
                [transformation.identifiers.brand]: true
            }),
            {}
        )
    )
        .map(brandKey =>
            scenario.config.grouping_levels
                .find(level => level.key === "brand")
                .values.find(value => value.key === brandKey)
        )
        .map(brand => ({
            ...brand,
            ...getFilteredTransformations(allTransformations, {
                brand: [brand.key]
            })
                .map(mapTransformationForMainInput(scenario))
                .reduce(
                    (memo, transformation) => ({
                        regions: [
                            ...memo.regions,
                            transformation.identifiers.region_key
                        ],
                        optimisedTotal:
                            memo.optimisedTotal +
                            (transformation.mainInput.optimised || []).reduce(
                                (memo, val) => memo + (val ? val : 0),
                                0
                            ),
                        outputTotal:
                            memo.outputTotal +
                            (transformation.mainInput.output || []).reduce(
                                (memo, val) => memo + (val ? val : 0),
                                0
                            ),
                        mixedTotal:
                            memo.mixedTotal +
                            (transformation.mainInput.values || []).reduce(
                                (memo, val) => memo + (val ? val : 0),
                                0
                            ),
                        unit: transformation.mainInput.unit
                    }),
                    {
                        optimisedTotal: 0,
                        outputTotal: 0,
                        mixedTotal: 0,
                        unit: undefined,
                        regions: []
                    }
                )
        }));

    return brands;
}


export function getScenarioDefaultBrandSelected(scenario) {
    return getScenarioBrands(scenario).map(brand => {
        return brand.key;
    });
}


export function getTransformationByIdentifiers(transformations, identifiers) {
    return _.find(transformations, transformation =>
        _.isEqual(transformation.identifiers, identifiers)
    );
}

export function getFilteredTransformations(transformations, filters, reverse) {
    const filteredTransforms = transformations.filter(cTransformation => {
        let entries = Object.entries(filters || {});
        let notAllFiltersMatched = entries.find(([levelKey, values]) => {
            return (
                values.length >= 1 &&
                ((!reverse &&
                    values.indexOf(cTransformation.identifiers[levelKey]) ===
                    -1) ||
                    (reverse &&
                        values.indexOf(
                            cTransformation.identifiers[levelKey]
                        ) !== -1))
            );
        });

        return !notAllFiltersMatched;
    });
    return filteredTransforms;
}

export function getScenarioTransformations(scenario) {
    const scenarioTransforms = getFilteredTransformations(
        scenario.config.transformations.filter(transform => !transform.is_halo),
        scenario.config.grouping_levels
    ).map(cTransformation => {
        return {
            ...cTransformation,
            sTransformation: getTransformationByIdentifiers(
                scenario.transformations,
                cTransformation.identifiers
            )
        };
    });

    return scenarioTransforms.filter(
        // eslint-disable-next-line
        trans => {
            for (let scenarioTrans of scenario.transformations) {
                if (_.isEqual(trans.identifiers, scenarioTrans.identifiers)) {
                    return trans;
                }
            }
        }
    );
}

export function getScenarioTable(scenario) {
    const output = getScenarioTransformations(scenario).reduce(
        (memo, cTransformation) => {
            return [
                ...memo,
                ...cTransformation.io
                    .filter(
                        trans =>
                            trans.type === "output" || trans.key === "spend"
                    )
                    .reduce((memo_, cIO) => {
                        const key = cIO.label.toLowerCase().replace(/ /g, "_");
                        const sInput = ((cTransformation.sTransformation || {})
                            .inputs || {})[key];

                        const sCalculated = ((
                            cTransformation.sTransformation || {}
                        ).calculated || {})[key];
                        const sOptimised = ((
                            cTransformation.sTransformation || {}
                        ).optimised || {})[key];
                        return [
                            ...memo_,
                            [
                                {
                                    // meta, not for display
                                    transformation: cTransformation,
                                    io: cIO
                                },
                                ...Object.values(
                                    scenario.config.grouping_levels
                                ).map(
                                    level =>
                                        level.values.find(
                                            value =>
                                                value.key ===
                                                cTransformation.identifiers[
                                                    level.key
                                                    ]
                                        ).label
                                ),
                                cIO.label,
                                ...(sInput ||
                                    sCalculated ||
                                    sOptimised ||
                                    cIO.values.slice(
                                        scenario.observations_min,
                                        scenario.observations_max + 1
                                    ))
                            ]
                        ];
                    }, [])
            ];
        },
        []
    );
    return output;
}


export function getBrandAllocations(scenario, filters) {
    const transforms = getFilteredTransformations(
        getScenarioTransformations(scenario),
        filters
    );

    return transforms
        .map(mapTransformationForMainInput(scenario))
        .map(transformation => {
            transformation.mainInput.ranges = transformation.mainInput.values.reduce(
                (memo, value) => {
                    const currentRangeIsNonZero = !(memo.length % 2); // odd is zero
                    const valueIsNonZero = (value ? value : 0) > 0;

                    if (currentRangeIsNonZero !== valueIsNonZero) {
                        memo.push([]);
                    }

                    memo[memo.length - 1].push(value);

                    return memo;
                },
                [
                    [] // first item is always zero (so that odd items are always zero)
                ]
            );

            // make arrays where the first element is the range position and second element is the length
            // ONLY NON-ZERO RANGES HERE
            transformation.mainInput.rangePositions = transformation.mainInput.ranges.reduce(
                (memo, value, key) => {
                    const isNonZero = key % 2;

                    if (isNonZero) {
                        memo[memo.length - 1].push(value.length);
                    } else if (
                        key !==
                        transformation.mainInput.ranges.length - 1
                    ) {
                        // is not last empty range
                        const previousPosition =
                            key === 0 ? 0 : memo[memo.length - 1][0];
                        const previousLength =
                            key === 0 ? 0 : memo[memo.length - 1][1];
                        const absolutePosition =
                            value.length + previousPosition + previousLength;
                        memo.push([absolutePosition]);
                    }

                    return memo;
                },
                []
            );

            return {
                ...transformation,
                mainInput: {
                    ...transformation.mainInput,
                    optimisedTotal: (
                        transformation.mainInput.optimised || []
                    ).reduce((memo, val) => memo + (val ? val : 0), 0),
                    outputTotal: (transformation.mainInput.output || []).reduce(
                        (memo, val) => memo + (val ? val : 0),
                        0
                    ),
                    mixedTotal: (transformation.mainInput.values || []).reduce(
                        (memo, val) => memo + (val ? val : 0),
                        0
                    )
                }
            };
        });
}
