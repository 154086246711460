import { createContext } from 'react'
import { NotificationContextType } from './types'

const noop = () => {
  return
}

export const NotificationContext = createContext<NotificationContextType>({
  toast: noop,
})
