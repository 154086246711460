import { useEffect, useState } from 'react'
import { useWindowSize } from '@gain-theory/hooks'
import { IMoveXBg } from './types'

export function useMoveXBg({ referenceWidth }: IMoveXBg) {
  const { width } = useWindowSize()
  const [moveXBg, setMoveXBg] = useState<number>(0)

  useEffect(() => {
    if (!width) return

    const widthDiff = width - referenceWidth
    const xCoord = width > referenceWidth ? 0 : widthDiff / 2

    setMoveXBg(xCoord)
  }, [width])

  return { moveXBg }
}
