import React, { FC } from 'react'
import partnerIcon from '../../icons/svg/icon-generic-partner.png'

interface OwnProps {
  className: string
}

type Props = OwnProps

const PartnerIcon: FC<Props> = ({ className }) => {
  return <img src={partnerIcon} className={className} />
}

export default PartnerIcon
