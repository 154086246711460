import React, { FC, useRef, useState } from 'react'
import { Brand, IMR, View } from '../../../types/MarketingDiagnosticsTypes'
import { Link } from 'react-router-dom'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../Routes'
import { uuid } from 'uuidv4'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'

interface OwnProps {
  brand: Brand
  views: View[]
  imr: IMR
  sensorEnabled: boolean
}

type Props = OwnProps

const BrandCard: FC<Props> = (props) => {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef(null)

  const { marketingDiagnostics } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const handleShowMenu = () => {
    setShowMenu(false)
  }

  const onViewButtonClick = () => {
    setShowMenu(!showMenu)
  }

  useOnClickOutside(ref, handleShowMenu)

  // Sort brand view-links based on 'Sort' column of 'views' tab in Reporting config
  const sortedBrandViewLinks: View[] = [...props.views].sort(
    (a, b) => a.sort - b.sort
  )

  const viewLinksForBrand = marketingDiagnostics?.view_link
    .filter((x) => x.brand === props.brand.brand)
    .map((x) => x.view)

  const performanceOverviewUrl = UrlAssembler()
    .template(ROUTES.MARKETING_DIAGNOSTICS.PERFORMANCE_OVERVIEW)
    .param('brand', props.brand.label)
    .toString()

  const filteredBrandViewLinks = sortedBrandViewLinks.filter((link) => {
    if (viewLinksForBrand?.indexOf(link.key) !== -1) {
      return link
    }
  })

  return (
    <div className="relative flex-1 h-40" ref={ref}>
      <div className="border rounded h-60 max-h-40 bg-white">
        <img src={`./img/${props.brand.logoUrl}`} className="px-20 py-2" />
      </div>

      {
        <>
          <button
            className="bg-gtPink text-white absolute bottom-0 text-xxs uppercase w-full h-10 input-focus rounded-t-none"
            onClick={(_) => onViewButtonClick()}
          >
            view
          </button>

          {showMenu ? (
            <div className="rounded-b bg-white border w-full absolute -bottom-30 flex flex-col z-20">
              {props.imr.nodes.length > 0 ? (
                <Link
                  className="w-full px-6 py-2 text-xs text-gray-500 hover:bg-gray-100"
                  to={UrlAssembler()
                    .template(ROUTES.MARKETING_DIAGNOSTICS.IMR_VIEW_LINK)
                    .toString()}
                >
                  IMR
                </Link>
              ) : null}
              {filteredBrandViewLinks.map((view) => (
                <Link
                  key={uuid()}
                  className="w-full px-6 py-2 text-xs text-gray-500 hover:bg-gray-100"
                  to={UrlAssembler()
                    .template(
                      ROUTES.MARKETING_DIAGNOSTICS.UNIFIED_ADVANCED_VIEW
                    )
                    .param('brand', props.brand.brand)
                    .param('view', view.label)
                    .toString()}
                >
                  {view.label}
                </Link>
              ))}
              {props.sensorEnabled && (
                <Link
                  className="w-full px-6 py-2 text-xs text-gray-500 hover:bg-gray-100"
                  to={performanceOverviewUrl}
                >
                  Performance Overview
                </Link>
              )}
            </div>
          ) : null}
        </>
      }
    </div>
  )
}

export default BrandCard
