import { FC } from 'react'
import { TabGroup, TabGroupProps } from '@gain-theory/tab-group'

interface OwnProps {
  selectedTab: MediaAllocationEnum
  setSelectedTab: (tab: MediaAllocationEnum) => void
}

type Props = OwnProps

export const enum MediaAllocationEnum {
  SPEND_VIEW = 'spend_view',
  WEEKLY_VIEW = 'weekly_view',
}

export const MediaAllocationTab: FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const tabs: TabGroupProps['tabs'] = [
    {
      id: MediaAllocationEnum.SPEND_VIEW,
      label: 'Spend',
      leadingIcon: 'ChartBarIcon',
    },
    {
      id: MediaAllocationEnum.WEEKLY_VIEW,
      label: 'Costs',
      leadingIcon: 'CalendarDaysIcon',
    },
  ]

  const handleTabChange: TabGroupProps['onTabChange'] = (tabId: string) => {
    const targetTab = tabId as MediaAllocationEnum

    setSelectedTab(targetTab)
  }

  return (
    <TabGroup
      tabs={tabs}
      selectedTabId={selectedTab}
      onTabChange={handleTabChange}
    />
  )
}