import {
  AllBrandsActionTypes,
  CREATE_ALL_BRANDS,
  SELECT_ALL_BRANDS,
} from '../actions/AllBrandsActionTypes'
import {
  PLAN_GET_ITEM_ATTEMPT,
  PlanActionTypes,
} from '../actions/PlanActionTypes'
import {
  AllBrandTransformation,
  PlanOutput,
} from '../../types/PlanTypes'
import {
  getMediaChannelAllocations,
  getScenarioBrands,
} from '../../utils/PlanUtils'

interface IDefaultState {
  isSelected: boolean
  outputTotals: number[]
  transformations: AllBrandTransformation[]
  budget: { total: number; unit: string }
}

const defaultState: IDefaultState = {
  isSelected: false,
  outputTotals: [],
  transformations: [],
  budget: { total: 0, unit: '' },
}

const allBrandsReducer = (
  state: IDefaultState,
  action: AllBrandsActionTypes | PlanActionTypes
) => {
  switch (action.type) {
    case PLAN_GET_ITEM_ATTEMPT:
      return {
        ...state,
        isSelected: true,
      }
    case CREATE_ALL_BRANDS:

    case SELECT_ALL_BRANDS:
      return {
        ...state,
        isSelected: action.payload,
      }
    default:
      return defaultState
  }
}

export default allBrandsReducer
