import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { GetPlanById } from '../../../redux/actions/PlanActions'
import { RootStore } from '../../../redux/reducers/Store'
import { ChangeComparisonKPIs } from '../../../socket.io'
import { ComparisonKPIs } from '../../../types/PlanComparisonTypes'
import { IO, Key } from '../../../types/PlanTypes'

const mapState = (state: RootStore) => ({
})
const mapDispatchToProps = {
  onGetPlan: GetPlanById,
  changeComparisonKPIs: ChangeComparisonKPIs,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {
  kpiModalToggle: () => void
  kpis: ComparisonKPIs[]
  allKPIs: ComparisonKPIs[]
  planId: number
  comparisonId: string
}

const ChangeComparisonKPIModal: FC<OwnProps> = (props) => {
  const [kpis, setKPIs] = useState<ComparisonKPIs[]>([])
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>([])
  const [error, setError] = useState("")

  useEffect(() => { 
      const selectedKPIs = props.kpis.map(x => x.key)

      setKPIs(props.allKPIs)
      setSelectedKPIs(selectedKPIs)
  }, [])

  const changeKPIs = () => {
    if(selectedKPIs.length > 0){
      props.changeComparisonKPIs(selectedKPIs, props.comparisonId)
    } else {
      setError("You must select at least one KPI to continue")
    }
  }

  const selectKPI = (e: any) => {
    let newSelectedKpis: string[] = []
    if (selectedKPIs.indexOf(e.target.value) === -1) {
      newSelectedKpis = [...selectedKPIs, e.target.value]
    } else {
      newSelectedKpis = selectedKPIs.filter((x) => x !== e.target.value)
    }
    setSelectedKPIs(newSelectedKpis)
    setError("")
  }


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 h-3/4 my-6 mx-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h1 className="text-gray-500 my-6 text-xl w-3/4">EDIT KPIS</h1>
              <button
                className="p-1key ml-auto border-0 text-gray-500 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                onClick={props.kpiModalToggle}
              >
                <span className="text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {error !== "" && <div className={'p-4 text-xs text-gtBlueSecondary'}>{error}</div>}
            <div className="relative p-6 flex-auto overflow-y-auto">
              <div
                className={`grid grid-flow-rows grid-cols-2 gap-4 p-4 max-h-96 overflow-auto justify-center`}
              >
                {kpis.map((kpi, index) => {
                  let checked = selectedKPIs.indexOf(kpi.key) !== -1
                  return (
                    <div key={index} className="check-box">
                      <label className="">
                        <input
                          type={'checkbox'}
                          onChange={selectKPI}
                          checked={checked}
                          value={kpi.key}
                        />
                        <div className="option">{kpi.label}</div>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
                onClick={props.kpiModalToggle}
              >
                Close
              </button>
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 ml-5 mt-4"
                onClick={changeKPIs}
              >
                Regenerate Comparison
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default connector(ChangeComparisonKPIModal)
