import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFeatures } from '../../../../../features'
import { AppState } from '../../../../../redux/reducers/RootReducer'
import { FEATURE_FLAGS } from '../../../../../utils/constants'

export const useBudgetConstaint = () => {
  const FLAG_DISABLE_BUDGET_CONSTRAINTS: string =
    'FLAG_DISABLE_BUDGET_CONSTRAINTS'

  const features = useFeatures([FLAG_DISABLE_BUDGET_CONSTRAINTS]) as Record<
    string,
    unknown
  >

  const { featureFlags } = useSelector((state: AppState) => state.session)

  const isFeatureEnabled = useMemo(() => {
    return !features[FLAG_DISABLE_BUDGET_CONSTRAINTS]
  }, [features])

  const isFeatureFlagEnabled = useMemo(() => {
    let enabled: boolean = false

    if (featureFlags && featureFlags.length > 0) {
      const timeConstraintsFeature = featureFlags.find(
        (feature) => feature.key === FEATURE_FLAGS.PROPORTIONAL_FLIGHTING
      )

      enabled = !!timeConstraintsFeature && timeConstraintsFeature.enabled
    }

    return enabled
  }, [featureFlags])

  return {
    isBudgetConstraintEnabled: isFeatureEnabled && isFeatureFlagEnabled,
  }
}
