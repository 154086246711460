import { Config } from '../../types/ConfigTypes'
import {
  CONFIG_GET_ALL_SUCCESS,
  ConfigActionTypes,
  CONFIG_GET_BY_ID_SUCCESS,
} from '../actions/ConfigActionTypes'
import { RootStore } from './Store'

interface IDefaultState {
  configs: Config[] | null
  latestCrossChannelConfig: Config | null
}

const defaultState: IDefaultState = {
  configs: null,
  latestCrossChannelConfig: null,
}

const configReducer = (
  state: IDefaultState = defaultState,
  action: ConfigActionTypes
) => {
  switch (action.type) {
    case CONFIG_GET_ALL_SUCCESS:
      return { ...state, configs: action.payload }
    case CONFIG_GET_BY_ID_SUCCESS:
      return { ...state, latestCrossChannelConfig: action.payload }
    default:
      return state
  }
}

export const selectConfigs = (state: RootStore) => {
  return state.configs.configs
}

export const getConfigById = (state: RootStore) => {
  return state.configs.latestCrossChannelConfig
}

export default configReducer
