import React, { FC } from 'react'
import {
  EffectiveHighlight,
  KPI,
  Phase,
} from '../../../../types/MarketingDiagnosticsTypes'
import SearchIcon from '../../../../icons/media/SearchIcon'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../../Routes'
import MediaChannelIcon from '../../../../icons/media/MediaChannelIcon'

interface OwnProps {
  highlight: EffectiveHighlight | undefined
  phase: Phase | undefined
  kpi: KPI | undefined
  color: string
}

type Props = OwnProps

const MostEffectiveCard: FC<Props> = (props) => {
  if (!props.highlight || !props.phase || !props.kpi) {
    return <div />
  }
  return (
    <div className="relative w-1/4 bg-white border rounded content-center flex flex-col justify-center items-center pt-4 space-y-2">
      <div className="text-gray-500 text-xs">MOST EFFECTIVE CHANNEL</div>
      <MediaChannelIcon
        type={props.highlight?.channel?.toLowerCase()}
        className="w-10 h-10"
        stroke={props.color}
      />
      <div className="text-gray-400 text-xs p-4">
        {`${props.highlight.channel}   has the highest
                                        effectiveness score. ${props.highlight.flex_value} was the
                                        most effective ${props.highlight.flex_name} for phase
                                        ${props.phase.phase}.`}
      </div>
      <Link
        to={UrlAssembler()
          .template(ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE)
          .param('channel', props.highlight.channel)
          .param('kpiId', props.kpi.id.toString())
          .param('phaseId', props.phase.phase)
          .param('brand', props.highlight.brand)
          .param('kpiName', props.kpi.label)
          .toString()}
        className="w-full bg-gtPink p-4 text-white text-xs flex items-center justify-center input-focus rounded-t-none"
      >
        VIEW
      </Link>
    </div>
  )
}

export default MostEffectiveCard
