import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const DownArrowIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          className="fill-brand-primary-main"
          d="M0 32c0 17.672 14.325 32 32 32 17.672 0 32-14.328 32-32S49.672 0 32 0C14.325 0 0 14.328 0 32z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M29.293 45.44c-.003-1.11.894-2.008 2.002-2.006l9.312.002L17.15 19.98c-.781-.781-.781-2.048 0-2.829l.174-.155c.785-.621 1.93-.57 2.654.155l23.455 23.455.001-9.311c-.003-1.105.897-2.004 2.004-2.001 1.101 0 1.996.894 1.996 1.998v14.142c.003 1.105-.897 2.004-2.004 2.001h-14.14c-1.018 0-1.86-.762-1.982-1.747l-.016-.248z"
        />
      </g>
    </svg>
  )
}

export default DownArrowIcon
