import { Avatar } from '@gain-theory/avatar'
import { Card } from '@gain-theory/card'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { memo } from 'react'
import { ContactInfoCardProps } from './types'

export const ContactInfoCard = memo(
  ({ name, imageSrc, email, onMailClick }: ContactInfoCardProps) => {
    const AvatarImage = <Avatar size="huge" name={name} imgSrc={imageSrc} />

    const IconImage = (
      <Icon
        name="gtiIconGradient"
        library="svg"
        size="huge"
        style={{ height: '160px', width: 'auto' }}
      />
    )

    return (
      <Card className="w-fit min-h-fit">
        <div className="border border-brand-primary-main rounded-md flex flex-col items-center gap-xl p-md tex-center">
          <Typography.H6>{name}</Typography.H6>
          {!!imageSrc && imageSrc !== '' ? AvatarImage : IconImage}
          <button
            data-testid={`contact-info-button`}
            onClick={onMailClick}
            className="max-w-full overflow-hidden flex flex-row items-center gap-xs border-box p-xs h-xxl rounded-sm shadow-sm cursor-pointer hover:shadow-color-primary-lg active:outline active:outline-1 active:outline-offset-2 active:outline-brand-primary-light focus:outline-offset-[4px] focus:outline-brand-primary-light"
          >
            <Icon
              name="EnvelopeIcon"
              library="heroicon-outline"
              color="brand-primary-main"
            />
            <Typography.Caption
              title={email}
              className="max-w-[200px] overflow-hidden text-ellipsis"
            >
              <span className="text-neutral-gray-1000">{email}</span>
            </Typography.Caption>
          </button>
        </div>
      </Card>
    )
  }
)
