import { Switch } from '@gain-theory/switch'
import * as React from 'react'

export class GtiToggleButton extends React.Component {
  render() {
    return (
      <Switch
        active={this.props.value}
        onClick={this.props.onToggle}
        label={this.props.label}
      />
    )
  }
}
