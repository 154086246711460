import { InChannelSettings, OktaSetting, Settings } from '../../types/SettingTypes'

export const SETTINGS_GET_ATTEMPT = 'SETTINGS_GET_ATTEMPT'
export const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS'
export const SETTINGS_GET_FAIL = 'SETTINGS_GET_FAIL'
export const SETTINGS_UPDATE_ATTEMPT = 'SETTINGS_UPDATE_ATTEMPT'
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS'
export const SETTINGS_UPDATE_FAIL = 'SETTINGS_UPDATE_FAIL'
export const SETTINGS_GET_OKTA_SETTINGS_ATTEMPT =
  'SETTINGS_GET_OKTA_SETTINGS_ATTEMPT'
export const SETTINGS_GET_OKTA_SETTINGS_SUCCESS =
  'SETTINGS_GET_OKTA_SETTINGS_SUCCESS'
export const SETTINGS_GET_OKTA_SETTINGS_FAIL = 'SETTINGS_GET_OKTA_SETTINGS_FAIL'

export const SETTINGS_SELECT_OKTA_SETTING = 'SETTINGS_SELECT_OKTA_SETTING'

interface SettingsGetAttempt {
  type: typeof SETTINGS_GET_ATTEMPT
}

interface SettingsGetSuccess {
  type: typeof SETTINGS_GET_SUCCESS
  settings: Settings,
  inChannelSettings: InChannelSettings
}

interface SettingsGetFail {
  type: typeof SETTINGS_GET_FAIL
}

interface SettingsUpdateAttempt {
  type: typeof SETTINGS_UPDATE_ATTEMPT
}

interface SettingsUpdateSuccess {
  type: typeof SETTINGS_UPDATE_SUCCESS
  payload: Settings
}

interface SettingsUpdateFail {
  type: typeof SETTINGS_UPDATE_FAIL
}

interface SettingsGetOktaSettingsAttempt {
  type: typeof SETTINGS_GET_OKTA_SETTINGS_ATTEMPT
}

interface SettingsGetOktaSettingsSuccess {
  type: typeof SETTINGS_GET_OKTA_SETTINGS_SUCCESS
  payload: OktaSetting[]
}

interface SettingsGetOktaSettingsFail {
  type: typeof SETTINGS_GET_OKTA_SETTINGS_FAIL
}

interface SettingSelectOktaSetting {
  type: typeof SETTINGS_SELECT_OKTA_SETTING
  payload: OktaSetting
}

export type SettingActionTypes =
  | SettingsGetAttempt
  | SettingsGetSuccess
  | SettingsGetFail
  | SettingsUpdateAttempt
  | SettingsUpdateSuccess
  | SettingsUpdateFail
  | SettingsGetOktaSettingsAttempt
  | SettingsGetOktaSettingsSuccess
  | SettingsGetOktaSettingsFail
  | SettingSelectOktaSetting
