import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const SalesIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="#666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M38.8 36.288c0 1.306-5.992 2.362-13.387 2.362-7.396 0-13.388-1.056-13.388-2.362 0-1.305 5.992-2.363 13.388-2.363 7.395 0 13.387 1.058 13.387 2.363z" />
          <path d="M12.025 36.075v5.25c0 1.452 5.992 2.625 13.388 2.625 7.395 0 13.387-1.173 13.387-2.625v-5.25" />
          <path d="M38.8 41.8v5.25c0 1.452-5.992 2.625-13.387 2.625-7.396 0-13.388-1.173-13.388-2.625V41.8" />
          <path d="M38.8 47.1v5.25c0 1.452-5.992 2.625-13.387 2.625-7.396 0-13.388-1.173-13.388-2.625V47.1" />
          <path d="M38.8 52.825v5.25c0 1.452-5.992 2.625-13.387 2.625-7.396 0-13.388-1.173-13.388-2.625v-5.25M11.683 42.8H1V5h61.425v37.8H39.203" />
          <path d="M32.5 31.775a7.875 7.875 0 0 1-7.875-7.875 7.875 7.875 0 0 1 7.875-7.875 7.875 7.875 0 0 1 7.875 7.875 7.875 7.875 0 0 1-7.875 7.875zM15.175 17.6c0 .873-.706 1.575-1.575 1.575a1.576 1.576 0 1 1 1.575-1.575zM52.975 31.775c0 .873-.706 1.575-1.575 1.575a1.576 1.576 0 1 1 1.575-1.575z" />
          <path d="M11.196 36.5c-3.02 0-5.471-2.399-5.471-5.355V15.08c0-2.956 2.451-5.355 5.471-5.355H52.23c3.02 0 5.471 2.399 5.471 5.355v16.065c0 2.956-2.451 5.355-5.471 5.355H38.55" />
        </g>
      </g>
    </svg>
  )
}

export default SalesIcon
