import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import RegionIcon from '../../../icons/RegionIcon'
import { AppState } from '../../../redux/reducers/RootReducer'
import Truncate from 'react-truncate'
import { GroupingLevel } from '../../../types/PlanTypes'

interface OwnProps {
  filtersText: string[]
  onEdit: (section: number) => void
}
type Props = OwnProps

const RegionSummary: FC<Props> = (props) => {
  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)
  const { planItem } = useSelector((state: AppState) => state.plans)

  const editNumber = planItem.config.grouping_levels.findIndex((x: GroupingLevel) => x.key === "region")

  return (
    <div className="text-neutral-gray-1100  text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold">
          {!planItem.options.in_channel ? settings?.region_plural.toUpperCase() : inChannelSettings!["region"].plural.toUpperCase()}
        </span>
        {!planItem.locked_at && (
          <button
            onClick={(_) => props.onEdit(editNumber)}
            className="text-gtPink text-xxs"
          >
            edit
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        <RegionIcon className="w-10 h-10 text-xxs" />
        <Truncate className="text-xxs">{props.filtersText.join(', ')}</Truncate>
      </div>
    </div>
  )
}

export default RegionSummary
