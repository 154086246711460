import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const SalesGoalIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="62"
      viewBox="0 0 64 62"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        >
          <path d="M1.333 61h61.334M15 60V50H7v10M29 60.667V42h-8v18.667M43 60.333V32h-8v28.333M57 61V21h-8v40M6.5 41.5l49-39M56 7.9V1.777h-6.213" />
        </g>
      </g>
    </svg>
  )
}

export default SalesGoalIcon
