import {
  GroupingLevel,
  Identifier,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { formatNumberString } from '../../../../../../utils/format-number-string'
import { TimeConstraintErrorType, Errors } from '../../types'
import { CONSTRAINT_FN_OPTIONS, TABLE_COLUMNS } from '../../utils/constants'

interface IParseFileRows {
  rows: string[][]
  hierarchyHeaderKeys: string[]
  observationsHeaderKeys: string[]
  groupingLevels: GroupingLevel[]
  transformationOptions: Transformation[]
  hierarchyStartIndex: number
  lastHierarchyLevelIndex: number
  observationsStartIndex: number
  numberFormat: string | undefined
}

export function parseFileRows({
  rows,
  hierarchyHeaderKeys,
  observationsHeaderKeys,
  groupingLevels,
  transformationOptions,
  hierarchyStartIndex,
  lastHierarchyLevelIndex,
  observationsStartIndex,
  numberFormat,
}: IParseFileRows) {
  const parsedRows: Record<string, string>[] = []
  const rowParsingErrors: TimeConstraintErrorType[] = []

  const nonHaloTransformations = transformationOptions.filter(
    (trans) => !trans.is_halo
  )

  const getTransformationOptionFromCurve = (
    dimensionValues: Record<string, string>
  ) => {
    const transformation = nonHaloTransformations.find((transformation) => {
      const { identifiers } = transformation
      const identfierObj = identifiers as any as Identifier

      return Object.keys(identfierObj)
        .filter((key) => key !== 'region_key')
        .every((key) => identfierObj[key] === dimensionValues[key])
    })

    return transformation
  }

  const getHierarchyMapFromRow = (
    dimensionHeaderKeys: string[],
    row: string[],
    rowIndex: number
  ) => {
    const dimensionsInRow = row.slice(
      hierarchyStartIndex,
      lastHierarchyLevelIndex + 1
    )

    const mappedDimensionKeys = new Map<string, string>()
    const mappedDimensionLabels = new Map<string, string>()

    dimensionsInRow.forEach((dimensionValue, index) => {
      const levelValues = groupingLevels[index].values

      const valueIndex = levelValues.findIndex(
        (levelValue) => levelValue.label === dimensionValue
      )

      if (valueIndex === -1) {
        return
      }

      mappedDimensionKeys.set(
        dimensionHeaderKeys[index],
        levelValues[valueIndex].key
      )
      mappedDimensionLabels.set(
        dimensionHeaderKeys[index],
        levelValues[valueIndex].label
      )
    })

    if (mappedDimensionLabels.size !== dimensionHeaderKeys.length) {
      const invalidCurveError: TimeConstraintErrorType = {
        errorType: Errors.INVALID_CURVE,
        replacers: [rowIndex.toString()],
      }

      return {
        hierarchyMap: mappedDimensionLabels,
        parsingHierarchyError: invalidCurveError,
      }
    }

    const matchingTransformation = getTransformationOptionFromCurve(
      Object.fromEntries(mappedDimensionKeys)
    )

    const parsingHierarchyError: TimeConstraintErrorType | null =
      !matchingTransformation
        ? {
            errorType: Errors.INVALID_CURVE,
            replacers: [rowIndex.toString()],
          }
        : null

    return { hierarchyMap: mappedDimensionLabels, parsingHierarchyError }
  }

  const getTypeMapFromRow = (row: string[], rowIndex: number) => {
    const type = row[lastHierarchyLevelIndex + 1]

    const mappedType = new Map<string, string>()
    const matchingType = TABLE_COLUMNS.TYPE.options.find(
      (options) => options.toLowerCase() === type.toLowerCase()
    )

    mappedType.set(TABLE_COLUMNS.TYPE.field, matchingType || type)

    const parsingTypeError: TimeConstraintErrorType | null = !matchingType
      ? {
          errorType: Errors.TYPE_NOT_FOUND,
          replacers: [
            rowIndex.toString(),
            TABLE_COLUMNS.TYPE.options.join(', '),
          ],
        }
      : null

    return { typeMap: mappedType, parsingTypeError }
  }

  const getStatusMapFromRow = (row: string[], rowIndex: number) => {
    const status = row[lastHierarchyLevelIndex + 2]

    const mappedStatus = new Map<string, string>()
    const matchingStatus = TABLE_COLUMNS.STATUS.options.find(
      (options) => options.toLowerCase() === status.toLowerCase()
    )

    mappedStatus.set(TABLE_COLUMNS.STATUS.field, matchingStatus ?? status)

    const parsingStatusError: TimeConstraintErrorType | null = !matchingStatus
      ? {
          errorType: Errors.STATUS_NOT_FOUND,
          replacers: [
            rowIndex.toString(),
            TABLE_COLUMNS.STATUS.options.join(', '),
          ],
        }
      : null

    return { statusMap: mappedStatus, parsingStatusError }
  }

  const getObservationsMapFromRow = (
    observationsHeaderKeys: string[],
    row: string[],
    typeValue: string
  ) => {
    const observationsInRow = row.slice(observationsStartIndex)

    const mappedObservations = new Map<string, string>()

    const isBlankRow = observationsInRow.every(
      (observation) => observation === ''
    )

    if (isBlankRow) {
      return { observationsMap: mappedObservations }
    }

    observationsInRow.forEach((observationValue, index) => {
      let newObservationValue = observationValue

      const isIndexType =
        typeValue.toLowerCase() === CONSTRAINT_FN_OPTIONS.INDEX.toLowerCase()

      if (observationValue !== '') {
        newObservationValue = formatNumberString({
          value: newObservationValue.toString(),
          decimalSeparator: numberFormat === 'euro' ? ',' : '.',
          thousandSeparator: numberFormat === 'euro' ? '.' : ',',
        })
      }

      if (isIndexType && observationValue === '') {
        newObservationValue = '0'
      }

      mappedObservations.set(observationsHeaderKeys[index], newObservationValue)
    })

    return { observationsMap: mappedObservations }
  }

  rows.forEach((row, index) => {
    const rowNumber = index + 2 // +1 for header row, +1 for 0 based index

    const { hierarchyMap, parsingHierarchyError } = getHierarchyMapFromRow(
      hierarchyHeaderKeys,
      row,
      rowNumber
    )
    const { typeMap, parsingTypeError } = getTypeMapFromRow(row, rowNumber)
    const { statusMap, parsingStatusError } = getStatusMapFromRow(
      row,
      rowNumber
    )
    const { observationsMap } = getObservationsMapFromRow(
      observationsHeaderKeys,
      row,
      typeMap.get(TABLE_COLUMNS.TYPE.field) ?? ''
    )

    parsingHierarchyError && rowParsingErrors.push(parsingHierarchyError)
    parsingTypeError && rowParsingErrors.push(parsingTypeError)
    parsingStatusError && rowParsingErrors.push(parsingStatusError)

    if (observationsMap.size === 0) {
      return
    }

    const mappedRow = new Map<string, string>([
      ...hierarchyMap,
      ...typeMap,
      ...statusMap,
      ...observationsMap,
    ])

    mappedRow.set('rowNumber', rowNumber.toString())

    parsedRows.push(Object.fromEntries(mappedRow))
  })

  return {
    parsedRows,
    rowParsingErrors,
  }
}
