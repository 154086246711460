import { useContext } from 'react';
import { FeatureContext } from '../feature-context';

export const useFeatures = (flags: string | string[]) => {
  const context = useContext(FeatureContext);

  if (!context) {
    throw Error('useFeatures must be used within a FeatureProvider');
  }
  if (typeof flags === 'string') {
    return context.features[flags] ? true : false;
  }
  const features: Record<string, boolean> = {};
  for (const key of flags) {
    features[key] = context.features[key] ? true : false;
  }
  return features;
};
