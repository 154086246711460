import { Children } from "react"

type Option = {
  value: string | number;
  label: string;
  disabled?: boolean;
}

type TSelect = {
  onChange: any;
  value: string;
  options: Option[];
  classNames?: string;
}
export const Select = ({
  onChange,
  value,
  options,
  classNames,
}: TSelect) => (
    <select
      className={`${ options.length === 0 ? "animate-pulse bg-gray-200" : ""} text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none ${classNames || ''}`}
      onChange={onChange}
      value={value}
      disabled={options.length === 0}
    >
      {options.length === 0 && (<option selected></option>)}
      {Children.toArray(options.map(({ label, value, ...props }) => (
        <option value={value} {...props}> {label} </option>
      )))}
    </select>
  );
