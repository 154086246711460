import { Typography } from '@gain-theory/typography'
import { Children, memo } from 'react'
import { CanWeHelpBg } from '../assets'
import { useFeatureContentHeight } from '../hooks'
import { CanWeHelpCard } from './can-we-help-card'
import { CanWeHelpProps } from './types'

const CAN_WE_HELP_TITLE = 'Can we help?'

export const CanWeHelp = memo(({ id, cards, onCardClick }: CanWeHelpProps) => {
  const { rootRef, bgRef, height } = useFeatureContentHeight()

  const Background = (
    <div
      ref={bgRef}
      className="-mx-xxl absolute top-0 m-auto order-0 flex-grow-0 z-0 self-center justify-self-center min-w-[853px] w-[853px] max-w-7xl xl:w-[67.5%]"
    >
      <CanWeHelpBg className="fill-brand-primary-main" />
    </div>
  )

  const Title = (
    <div className="col-span-full items-center justify-center z-10">
      <Typography.H4 className="text-center text-text-white">
        {CAN_WE_HELP_TITLE}
      </Typography.H4>
    </div>
  )

  const Cards = (
    <div className="col-span-full xl:col-span-6 xl:col-start-4 items-strecth justify-center flex flex-row gap-md z-10 h-max">
      {Children.toArray(
        cards.map((card) => (
          <div
            key={card.id}
            id={`${card.id}-card`}
            className="min-w-32 w-1/3 max-w-xs"
          >
            <CanWeHelpCard {...card} onClick={() => onCardClick(card.id)} />
          </div>
        ))
      )}
    </div>
  )

  return (
    <div
      id={id}
      ref={rootRef}
      style={{ height: height }}
      className="mx-xxl col-span-full grid grid-cols-6 xl:grid-cols-12 gap-y-xxl relative pt-xxxxxl auto-rows-min"
    >
      {Background}
      {Title}
      {Cards}
    </div>
  )
})
