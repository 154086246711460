import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Input } from '@gain-theory/input'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'
import { ROUTES } from '../../../Routes'

interface OwnProps {
  selectedName: string
  setSelectedName: Dispatch<SetStateAction<string>>
  setStep: Dispatch<SetStateAction<number>>
}

type Props = OwnProps

const PlanNameStep: FC<Props> = ({
  selectedName,
  setSelectedName,
  setStep,
}) => {
  const history = useHistory()
  const [name, setName] = useState<string>('')

  const PLAN_NAME_TEXTS = {
    PAGE_TITLE: 'Name your plan',
    INPUT_LABEL: 'Plan name',
    INPUT_BADGE: 'optional',
    INPUT_PLACEHOLDER: 'Type a name for your plan',
  }

  useEffect(() => {
    setName(selectedName)
  }, [])

  const handleBackButton = () => {
    history.push(ROUTES.SPO.INDEX)
  }

  const handleNextButton = () => {
    setSelectedName(name)
    setStep((prev) => prev + 1)
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value

    setName(targetValue)
  }

  return (
    <CreateMarketingPlanLayout
      title={PLAN_NAME_TEXTS.PAGE_TITLE}
      component={
        <div className="w-fit min-w-64 max-w-3xl 2xl:min-w-128">
          <Input.Base
            autoComplete="off"
            label={PLAN_NAME_TEXTS.INPUT_LABEL}
            badgeLabel={PLAN_NAME_TEXTS.INPUT_BADGE}
            placeholder={PLAN_NAME_TEXTS.INPUT_PLACEHOLDER}
            value={name}
            onChange={handleChangeInput}
          />
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanNameStep
