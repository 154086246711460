import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import { GroupingLevel, IO, OutputTotalInfo } from '../../../types/PlanTypes'
import {
  getSummedKPICalculatedTotals,
  getSummedKPIOptimisedTotals,
} from '../../../utils/kpiDisplay'
import { uuid } from 'uuidv4'
import _, { filter } from 'lodash'
import appendUnit from '../../../utils/appendUnit'
import GroupingLevelAllocationItem from './grouping-level-allocation/GroupingLevelAllocationItem'
import { responsive } from '../../../utils/CarouselUtils'
import Carousel from 'react-multi-carousel'
import { ButtonGroupProps } from 'react-multi-carousel/lib/types'
import { ReactComponent as GenericProductIcon } from '../../../../src/icons/svg/icon-generic-product-m.svg'
import { SelectOutputFilter } from '../../../redux/actions/PlanActions'
import 'react-multi-carousel/lib/styles.css'

interface OwnProps {
  name: string
}

type Props = OwnProps

interface CarouselButtonGroupProps extends ButtonGroupProps {
  previous?: any
  next?: any
}

const GroupingLevelAllocation: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { planItem, scenarioOutputSelected, planLoadingComplete } = useSelector(
    (state: AppState) => state.plans
  )

  const { settings } = useSelector((state: AppState) => state.settings)

  const { levelTransforms, scenarioFilters, appliedFilters } = useSelector(
    (state: AppState) => state.planFilters
  )

  const allOutputTotals = planItem.config.transformations[0].io.filter(
    (io: { type: string; key: string }) =>
      io.type === 'output' || io.key === 'spend'
  )

  const [levels, setLevels] = useState<{ key: string; label: string }[]>([])
  const [shownLevels, setShownLevels] = useState<
    { key: string; label: string }[]
  >([])

  useEffect(() => {
    let groupingLevelOptions: { key: string; label: string }[] = []
    let levelFromTransforms: string[] = []
    if (
      planItem.config.grouping_levels.find(
        (x: GroupingLevel) => x.key === props.name
      )
    ) {
      for (let i in planItem.config.grouping_levels.find(
        (x: GroupingLevel) => x.key === props.name
      ).values) {
        groupingLevelOptions.push(
          planItem.config.grouping_levels.find(
            (x: GroupingLevel) => x.key === props.name
          ).values[i]
        )
      }

      levelFromTransforms = levelTransforms.map(
        (trans: any) =>
          // @ts-ignore
          trans.identifiers[
            planItem.config.grouping_levels.find(
              (x: GroupingLevel) => x.key === props.name
            ).key
          ]
      )

      levelFromTransforms = Array.from(new Set(levelFromTransforms))

      const filteredLevel = groupingLevelOptions.filter(
        (level) => levelFromTransforms.indexOf(level.key) !== -1
      )

      setLevels([...filteredLevel])
    }
  }, [planLoadingComplete])

  useEffect(() => {
    const filteredLevels = levels.filter((lev) => {
      if (scenarioFilters[props.name].indexOf(lev.key) !== -1) {
        return lev
      }
    })
    setShownLevels(filteredLevels)
  }, [levels])

  const getDisplayTransforms = (level: string) => {
    const filterKeys = Object.keys(appliedFilters)
    const currentIndex = filterKeys.indexOf(props.name)

    let filteredTransforms = planItem.transformations.filter((x: any) => {
      if (level === 'all') {
        return x
      }
      if (x.identifiers[props.name] === level) {
        return x
      }
    })
    //need to filter these by all the filters above props.name
    filteredTransforms = filteredTransforms.filter((trans: any) => {
      let allowTrans = true
      for (let i = 0; i <= currentIndex; i++) {
        if (filterKeys[i] !== props.name) {
          allowTrans =
            appliedFilters[filterKeys[i]].length === 0
              ? true
              : appliedFilters[filterKeys[i]].indexOf(
                  trans.identifiers[filterKeys[i]]
                ) !== -1
          if (!allowTrans) {
            break
          }
        }
      }
      if (allowTrans) {
        return trans
      }
    })

    return filteredTransforms
  }

  const getLevelOptimisedKPI = (level: { key: string; label: string }) => {
    const filteredTransforms = getDisplayTransforms(level.key)
    const roiNumerator = allOutputTotals.filter(
      (x: IO) => x.type === 'output'
    )[0]
      ? outputSelectOptions
          .filter((x: IO) => x.type === 'output')[0]
          .label.toLowerCase()
          .replace(/ /g, '_')
      : ''
    const calcSum = getSummedKPIOptimisedTotals(
      filteredTransforms,
      scenarioOutputSelected,
      roiNumerator,
      planItem.config.options.kpiDisplay
    )
    return calcSum
  }

  const getLevelCalculatedKPI = (
    level: { key: string; label: string },
    spend: string | null
  ) => {
    const filteredTransforms = getDisplayTransforms(level.key)
    const roiNumerator = allOutputTotals.filter(
      (x: IO) => x.type === 'output'
    )[0]
      ? outputSelectOptions
          .filter((x: IO) => x.type === 'output')[0]
          .label.toLowerCase()
          .replace(/ /g, '_')
      : ''
    let calcSum = 0
    if (spend === 'spend') {
      calcSum = getSummedKPICalculatedTotals(
        filteredTransforms,
        'spend',
        roiNumerator,
        planItem.config.options.kpiDisplay
      )
    } else {
      calcSum = getSummedKPICalculatedTotals(
        filteredTransforms,
        spend,
        roiNumerator,
        planItem.config.options.kpiDisplay
      )
    }
    return calcSum
  }

  const isUploadedPlan =
    planItem && planItem.optimised_totals
      ? _.sum(Object.values(planItem.optimised_totals)) === 0
      : false

  const planLevels = planItem.grouping_levels
  const levelNames = Object.keys(planLevels)
  for (let lev of levelNames) {
    if (planLevels[lev].length === 0) {
      planLevels[lev] = _.uniqBy(
        planItem.transformations.map((x: any) => x.identifiers[lev]),
        function (e) {
          return e
        }
      )
    }
  }
  const handleSelectLevel = (key: string) => {
    dispatch(SelectOutputFilter(props.name, key, planLevels))
  }

  const handleSelectAllLevel = () => {
    dispatch(SelectOutputFilter(props.name, 'all', planLevels))
  }

  const outputSelectOptions = planItem.config.transformations[0].io.filter(
    (io: { type: string; key: string }) =>
      io.type === 'output' || io.key === 'spend'
  )
  const getSelectedKPIUnit = () => {
    let unit = 'currency'
    if (
      outputSelectOptions.find(
        (output: { label: string }) =>
          output.label.toLowerCase().replace(/ /g, '_') ===
          scenarioOutputSelected.toLowerCase()
      )
    ) {
      unit = outputSelectOptions.find(
        (output: { label: string }) =>
          output.label.toLowerCase().replace(/ /g, '_') ===
          scenarioOutputSelected.toLowerCase()
      ).unit
    }
    return unit
  }

  const AllGroupingLevel = () => {
    const levelKey = props.name + '_plural'
    const levelPlural = settings![levelKey] ? settings![levelKey] : props.name

    const roiNumerator = allOutputTotals.filter(
      (x: IO) => x.type === 'output'
    )[0]
      ? outputSelectOptions
          .filter((x: IO) => x.type === 'output')[0]
          .label.toLowerCase()
          .replace(/ /g, '_')
      : ''
    return (
      <>
        <button
          key={uuid()}
          onClick={handleSelectAllLevel}
          className={`${
            appliedFilters[props.name].length === 0
              ? ' bg-white border-gtPink '
              : 'border-gray-100'
          } group rounded  w-44 border   hover:border-gtPink  hover:shadow-lg hover:bg-white  ring-0`}
        >
          <div className="my-4 flex flex-col">
            <div className="flex flex-col content-center items-center justify-center">
              <div className="mx-auto border rounded-full w-16 h-16 flex">
                <GenericProductIcon className="w-14 h-14 bg-gray-300 mx-auto my-auto rounded-full" />
              </div>
              <div className=" text-xs font-light   ">
                {'All ' + levelPlural}
              </div>
              <div className="text-xxs text-gray-400 mx-auto uppercase">
                Spend
              </div>
              <h6 className="text-lg">
                {appendUnit(
                  getSummedKPICalculatedTotals(
                    getDisplayTransforms('all'),
                    'spend',
                    roiNumerator,
                    planItem.config.options.kpiDisplay
                  ),
                  'currency',
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
              </h6>
            </div>
            <hr
              className={`${
                appliedFilters[props.name].length === 0 ? 'border-gtPink' : ''
              } border-t group-hover:border-gtPink my-5 mx-4`}
            />
            <div className="flex flex-col content-center items-center justify-center">
              <div className="text-xxs text-gray-400 mx-auto uppercase">
                {scenarioOutputSelected.replace(/_/g, ' ')}
              </div>
              <div
                className={
                  !isUploadedPlan
                    ? 'text-xxs text-gray-400 mx-auto uppercase'
                    : 'displayNone'
                }
              >
                {' '}
                Optimal
              </div>

              <div
                className={
                  !isUploadedPlan ? 'text-lg text-success-main' : 'displayNone'
                }
                data-testid="all-brands-optimised-total"
              >
                {appendUnit(
                  getSummedKPIOptimisedTotals(
                    getDisplayTransforms('all'),
                    scenarioOutputSelected,
                    roiNumerator,
                    planItem.config.options.kpiDisplay
                  ),
                  getSelectedKPIUnit(),
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
              </div>
              <span
                className={
                  !isUploadedPlan
                    ? 'text-xxs text-gray-400 mx-auto uppercase'
                    : 'hidden'
                }
              >
                This Plan
              </span>
              <div
                className="text-lg"
                data-testid="all-brands-difference-total"
              >
                {appendUnit(
                  getSummedKPICalculatedTotals(
                    getDisplayTransforms('all'),
                    scenarioOutputSelected,
                    roiNumerator,
                    planItem.config.options.kpiDisplay
                  ),
                  getSelectedKPIUnit(),
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
              </div>
            </div>
          </div>
        </button>
      </>
    )
  }
  const showAllocationItems = () => {
    return shownLevels.map((level) => {
      return (
        <GroupingLevelAllocationItem
          spend={appendUnit(
            getLevelCalculatedKPI(level, 'spend'),
            'currency',
            planItem.options.exchangeRates.defaultSymbol || '',
            settings?.number_format || ''
          )}
          key={uuid()}
          level={level}
          handleSelectLevel={handleSelectLevel}
          isSelected={
            appliedFilters[props.name].indexOf(level.key) !== -1 ||
            shownLevels.length === 1
          }
          calculatedKpi={appendUnit(
            getLevelCalculatedKPI(level, scenarioOutputSelected),
            getSelectedKPIUnit(),
            planItem.options.exchangeRates.defaultSymbol || '',
            settings?.number_format || ''
          )}
          optimisedKpi={appendUnit(
            getLevelOptimisedKPI(level),
            getSelectedKPIUnit(),
            planItem.options.exchangeRates.defaultSymbol || '',
            settings?.number_format || ''
          )}
          selectedOutput={scenarioOutputSelected}
          isUploadedPlan={isUploadedPlan}
        />
      )
    })
  }

  const CarouselButtonGroup = ({
    next,
    previous,
  }: CarouselButtonGroupProps) => {
    return (
      <div className="w-9/12 absolute right-0 h-full">
        <button
          onClick={() => previous()}
          style={{ left: '-6%', background: 'none' }}
          className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left inset-y-0 m-auto text-gtPink"
          type="button"
        />
        <button
          onClick={() => next()}
          style={{ right: 0, background: 'none' }}
          className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right inset-y-0 m-auto text-gtPink"
          type="button"
        />
      </div>
    )
  }

  return (
    <div
      className={`${
        shownLevels.length > 1 ? 'justify-between relative' : ' justify-center'
      } w-full flex flex-row `}
    >
      {shownLevels.length > 1 ? <AllGroupingLevel /> : null}
      {shownLevels.length > 2 ? (
        <Carousel
          responsive={responsive}
          className="w-9/12"
          renderButtonGroupOutside={true}
          arrows={false}
          customButtonGroup={<CarouselButtonGroup />}
        >
          {showAllocationItems()}
        </Carousel>
      ) : (
        showAllocationItems()
      )}
    </div>
  )
}

export default GroupingLevelAllocation
