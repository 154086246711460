import { getGtiEnvironment } from "@gain-theory/hooks";
import { IUser } from "../types";

const GTI_ENVIRONMENT = getGtiEnvironment();

const isGtDev = GTI_ENVIRONMENT.hostname.includes('gtdev.net');

const getAccountName = () => {
  let name = GTI_ENVIRONMENT.hostname.replace(/\.gaintheoryinteractive\.com/g, '').trim();
  name = name.replace(/\.gaintheoryinteractive\.io/g, '').trim();
  name = name.replace(/\.gti\.gtdev\.net/g, ' ').trim();

  if (isGtDev) {
    return {
      name,
    }
  }
  if (GTI_ENVIRONMENT.isUat) {
    const [, ...rest] = name.split('.');
    const restOfHostname = rest.join();
    return {
      name: restOfHostname,
    };
  }

  return {
    name,
  };
}
export const getVisitorMetadata = (user: IUser | null) => ({
  username: user?.username || 'unknown',
  isProduction: !isGtDev ? (GTI_ENVIRONMENT.isUat || GTI_ENVIRONMENT.isProduction) : false,
  isUat: !isGtDev ? GTI_ENVIRONMENT.isUat : false,
  permissions: (user?.permissions || []).toString(),
  browser: navigator.userAgent,
  language: navigator.language,
  platform: navigator.platform,
  isMobile: navigator.userAgent.includes('Mobile'),
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  resolution: `${window.screen.width}x${window.screen.height}`,
});


export const getAccountsMetadata = () => {
  const url = window.location.href;
  const [date, timeWithMs] = new Date().toISOString().split('T');
  const [time] = timeWithMs.split('.');
  const lastVisit = `${date} ${time}`;
  const { name } = getAccountName();
  return {
    id: name,
    name,
    url,
    lastVisit,
  };
};