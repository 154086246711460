import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const EditBudgetIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="64"
      viewBox="0 0 56 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5">
          <g strokeLinecap="round">
            <path d="M9 26c0 2.212 1.792 4 3.999 4a3.999 3.999 0 1 0 0-7.999A4 4 0 1 1 17 18M13 30v3M13 11v3" />
            <path d="M24.476 58H1V1h26.53L42 15.87V36.4" />
            <path d="M27 1v15h14M9 40h27M23 35h12M28 30h7M28 25h7M9 45h23M9 49.75h18.592" />
          </g>
          <path
            strokeLinecap="round"
            d="M27 63l2.545-8.907L48.638 35 55 41.362 35.907 60.455z"
          />
          <path d="M44 39l6 6M30 54l6 6" />
        </g>
      </g>
    </svg>
  )
}

export default EditBudgetIcon
