import readXlsxFile from 'read-excel-file'
import {
  GroupingLevel,
  Observation,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { Errors, TimeConstraintErrorType } from '../../types'
import { TABLE_COLUMNS } from '../../utils/constants'
import { validateCurves } from '../../utils/validate-curves'
import { parseFileHeaders } from './parse-file-headers'
import { parseFileRows } from './parse-file-rows'
import { Settings } from '../../../../../../types/SettingTypes'

interface IParseFileProps {
  file: File
  observations: Observation[]
  groupingLevels: GroupingLevel[]
  transformationOptions: Transformation[]
  hierarchyStartIndex: number
  lastHierarchyLevelIndex: number
  observationsStartIndex: number
  numberFormat: string | undefined
  updateProgress: (progress: number) => void
  settings: Settings | null
}

export function parseFile({
  file,
  observations,
  groupingLevels,
  transformationOptions,
  hierarchyStartIndex,
  lastHierarchyLevelIndex,
  observationsStartIndex,
  numberFormat,
  settings,
  updateProgress,
}: IParseFileProps) {
  const validateFileText = (event: ProgressEvent<FileReader>) => {
    const text = event.target?.result

    if (!text) {
      const noDataError: TimeConstraintErrorType = {
        errorType: Errors.NO_DATA,
        replacers: [],
      }

      return { text: '', fileTextError: noDataError }
    }

    if (typeof text !== 'string') {
      const dataTypeError: TimeConstraintErrorType = {
        errorType: Errors.DATA_TYPE,
        replacers: [],
      }

      return { text: '', fileTextError: dataTypeError }
    }

    return { text, fileTextError: null }
  }

  const onFileLoad = async (
    fileExtension: string,
    event: ProgressEvent<FileReader>,
    resolve: (reason?: any) => void,
    reject: (reason?: any) => void,
    progress: (progress: number) => void
  ) => {
    progress(0)
    let data: string[] = []

    if (fileExtension === 'csv') {
      const { text, fileTextError } = validateFileText(event)

      if (fileTextError) {
        reject(fileTextError)
        return
      }

      data = text
        .split('\n')
        .map((row) => row.replace(/[\r"]/g, ''))
        .filter((row) => row !== '')
    }

    if (fileExtension === 'xlsx') {
      await readXlsxFile(file)
        .then((rows) => {
          data = rows.map((row) => row.join(','))
        })
        .catch((error) => {
          const xlsxError: TimeConstraintErrorType = {
            errorType: Errors.XLSX_PARSE,
            replacers: [error],
          }

          reject(xlsxError)
        })
    }

    if (!data || data.length === 0) {
      const noDataError: TimeConstraintErrorType = {
        errorType: Errors.NO_DATA,
        replacers: [],
      }

      reject(noDataError)
      return
    }

    progress(10)

    const headers = data[0].split(',')
    const hasEmptyColumns = headers.some((header) => header === '')

    if (hasEmptyColumns) {
      const emptyColumnsError: TimeConstraintErrorType = {
        errorType: Errors.HEADER_EMPTY_COLUMN,
        replacers: [],
      }

      reject(emptyColumnsError)
      return
    }

    progress(20)

    const { hierarchyHeaderKeys, observationsHeaderKeys, headerParsingErrors } =
      parseFileHeaders({
        hierarchyStartIndex,
        lastHierarchyLevelIndex,
        observationsStartIndex,
        headers,
        groupingLevels,
        observations,
        settings
      })

    progress(40)

    if (headerParsingErrors.length > 0) {
      reject(headerParsingErrors)
      return
    }

    const values = data.slice(1).map((value) => value.split(','))

    const { parsedRows, rowParsingErrors } = parseFileRows({
      rows: values,
      hierarchyHeaderKeys,
      observationsHeaderKeys,
      groupingLevels,
      transformationOptions,
      hierarchyStartIndex,
      lastHierarchyLevelIndex,
      observationsStartIndex,
      numberFormat,
    })

    progress(70)

    if (rowParsingErrors.length > 0) {
      reject(rowParsingErrors)
      return
    }

    const curvesErrors = validateCurves({
      identifierKey: 'rowNumber',
      parsedRows,
      hierarchyKeys: hierarchyHeaderKeys,
    })

    progress(90)

    if (curvesErrors.length > 0) {
      reject(curvesErrors)
      return
    }

    const finalRowsArray = parsedRows.map((row, index) => {
      const { rowNumber, ...rest } = row

      return {
        [TABLE_COLUMNS.ID.field]: (index + 1).toString(),
        ...rest,
      }
    })

    progress(100)

    resolve({
      rows: finalRowsArray,
    })
  }

  const onFileError = (
    event: ProgressEvent<FileReader>,
    reject: (reason?: any) => void
  ) => {
    const { error } = event.target as FileReader

    if (error) {
      const fileReaderError: TimeConstraintErrorType = {
        errorType: Errors.FILE_READER_ERROR,
        replacers: [],
      }

      reject(fileReaderError)
    }
  }

  const parseFilePromise: Promise<{
    rows: Record<string, string>[]
  }> = new Promise((resolve, reject) => {
    const fileExtension = file.name.split('.').pop() || ''

    const reader = new FileReader()
    reader.onload = (event) =>
      onFileLoad(fileExtension, event, resolve, reject, updateProgress)
    reader.onerror = (event) => onFileError(event, reject)
    reader.readAsText(file)
  })

  return parseFilePromise
}
