import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MAIL_TO_SUBJECT } from '../utils'
import { SupportProps } from '../../support'
import { AppState } from '../../../../redux/reducers/RootReducer'

const contactInitial: SupportProps['contact'] = {
  name: '',
  email: '',
}

export function useSupportContact() {
  const { systemLinks } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const [contact, setContact] = useState<SupportProps['contact']>({
    ...contactInitial,
  })

  const mapContact = () => {
    const hasSupportContact =
      systemLinks !== null &&
      !!systemLinks.contact &&
      systemLinks.contact.length > 0

    let mappedContact = { ...contactInitial }

    if (hasSupportContact) {
      mappedContact = {
        name: systemLinks.contact[0].name,
        email: systemLinks.contact[0].email,
      }
    }

    setContact({ ...mappedContact })
  }

  const onMailClick = () => {
    window.open(`mailto:${contact.email}?subject=${MAIL_TO_SUBJECT}`, '_blank')
  }

  useEffect(() => {
    mapContact()
  }, [systemLinks?.contact])

  return { contact, onMailClick }
}
