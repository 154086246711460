import { Dispatch } from 'redux'
import {
  AddOrCutActionTypes,
  ADD_OR_CUT_BUDGET_ATTEMPT,
  ADD_OR_CUT_BUDGET_FAIL,
  ADD_OR_CUT_BUDGET_SUCCESS,
  SET_ORIGINAL_CONSTRAINT_ATTEMPT,
  SET_ORIGINAL_CONSTRAINT_ERROR,
  SET_ORIGINAL_CONSTRAINT_SUCCESS,
} from './AddOrCutBudgetActionTypes'

export const SetOriginalConstraint = (value: number) => async (
  dispatch: Dispatch<AddOrCutActionTypes>
) => {
  dispatch({ type: SET_ORIGINAL_CONSTRAINT_ATTEMPT })

  try {
    dispatch({ type: SET_ORIGINAL_CONSTRAINT_SUCCESS, payload: value })
  } catch (error) {
    dispatch({ type: SET_ORIGINAL_CONSTRAINT_ERROR, error: error })
  }
}
