import { Typography } from '@gain-theory/typography'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { GetConfigList } from '../../redux/actions/ConfigActions'
import { GetUsers, CreateGroup } from '../../redux/actions/SessionActions'
import { selectConfigs } from '../../redux/reducers/ConfigReducer'
import {
  selectSessionAllUsers,
  selectSessionModule,
} from '../../redux/reducers/SessionReducer'
import { adminSuccess } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import { CreateUserAttrs } from '../../types/AdminTypes'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
  modules: selectSessionModule(state),
  configs: selectConfigs(state),
  adminSuccess: adminSuccess(state),
})
const mapDispatchToProps = {
  onGetUsers: GetUsers,
  onGetConfigs: GetConfigList,
  onCreateGroup: CreateGroup,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type PropsFunction = (e: CreateUserAttrs) => void

interface OwnProps extends PropsFromRedux {
  userId: string
  submit: PropsFunction
}

const CreateGroupView: FC<OwnProps> = (props) => {
  useEffect(() => {
    props.onGetConfigs()
  }, [])
  const [groupName, setGroupName] = useState('')
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [createGroupStatus, setCreateGroupStatus] = useState<string>('')
  const createGroup = () => {
    const attrs = {
      name: groupName,
      regions: selectedRegions,
      modules: selectedModules,
    }
    props.onCreateGroup(attrs)
    setCreateGroupStatus('Success!')
  }
  const selectModule = (e: any) => {
    if (selectedModules.includes(e.target.value)) {
      setSelectedModules((prevModules) =>
        prevModules.filter((x) => x !== e.target.value)
      )
    } else {
      setSelectedModules((prevModules) => [...prevModules, e.target.value])
    }
  }
  const selectRegion = (e: any) => {
    if (selectedRegions.includes(e.target.value)) {
      setSelectedRegions((prevRegions) =>
        prevRegions.filter((x) => x !== e.target.value)
      )
    } else {
      setSelectedRegions((prevRegions) => [...prevRegions, e.target.value])
    }
  }

  return (
    <div className={'container'}>
      <AdminHeader></AdminHeader>
      <Typography.H4 className="my-6">Create Group</Typography.H4>
      <input
        className="form-control"
        type="text"
        onChange={(e) => setGroupName(e.target.value)}
        placeholder="Group Name"
      />
      <h1 className="text-gray-500 my-6 text-md w-3/4">Modules</h1>
      <div className="flex flex-col">
        {props.modules?.map((module, index) => {
          return (
            <div
              key={index}
              className="border rounded px-3 py-2 flex flex-row max-w-xs my-4 bg-white shadow w-3/4 h-14"
            >
              <label className="flex flex-row items-center space-x-2 h-full w-full">
                <input
                  type="checkbox"
                  onChange={selectModule}
                  checked={selectedModules.includes(module.key)}
                  className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                  value={module.key}
                  key={module.key}
                />
                <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                  {module.label}
                </div>
              </label>
            </div>
          )
        })}
      </div>
      <h1 className="text-gray-500 my-6 text-md w-3/4">Regions</h1>
      {props.configs && props.configs[0]
        ? (
            props.configs[0].grouping_levels.find((x) => x.key === 'region')
              ?.values || []
          ).map((region, index) => {
            return (
              <div
                key={index}
                className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white shadow my-4 w-3/4 h-14"
              >
                <label className="flex flex-row items-center space-x-2 h-full w-full">
                  <input
                    type="checkbox"
                    onChange={selectRegion}
                    checked={selectedRegions.includes(region.key)}
                    className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                    value={region.key}
                    key={region.key}
                  />
                  <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                    {region.label}
                  </div>
                </label>
              </div>
            )
          })
        : null}
      <div className={'flex flex-col'}>
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 my-6 w-32"
          onClick={createGroup}
        >
          SAVE
        </button>
      </div>
      {createGroupStatus !== '' && (
        <div className={'mt-4 w-full p-5 bg-gtLightGreen'}>
          {createGroupStatus}
        </div>
      )}
    </div>
  )
}

export default connector(MainLayout(CreateGroupView))
