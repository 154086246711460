import React, { FC } from 'react'
import new_loading_icon from '../../../src/icons/svg/new_loading_icon.gif'

const LoadingModal: FC = () => {
  return (
    <div className="bg-gray-900 bg-opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-150 outline-none focus:outline-none">
      <div className="relative flex justify-center mx-auto w-9/12">
        <section id="sect">
          <img
            src={new_loading_icon}
            alt="Logo"
            className="mx-3 mt-3 mb-1 inline-block"
          />
          <p className="mb-8">L O A D I N G</p>
        </section>
      </div>
    </div>
  )
}

export default LoadingModal
