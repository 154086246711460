import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as _ from 'lodash'
import { CSVLink } from 'react-csv'
import { RootStore } from '../../../redux/reducers/Store'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { selectPlanItem } from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { EditSPOConstraints } from '../../../socket.io'
import {
  Constraint,
  GroupingLevel,
  GroupingLevelValue,
  Observation,
  TimeConstraint,
} from '../../../types/PlanTypes'
import ConstraintsRow from './ConstraintsRow'
import { getGroupingLevels } from '../../../utils/getPlanGroupingLevels'
import { GetPlanItem } from '../../../redux/actions/PlanActions'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import {
  getConstraintsError,
  getConstraintsLoading,
  getConstraintsSuccess,
} from '../../../redux/reducers/ConstraintsReducer'
import { SOCKET_RESET_CONSTRAINTS_ERROR } from '../../../redux/actions/SocketActionTypes'
import { number } from 'yup/lib/locale'
import { AppState } from '../../../redux/reducers/RootReducer'

type showModalFunction = (a: boolean) => void

interface OwnProps {
  showModal: showModalFunction
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  plan: selectPlanItem(state),
  loading: getConstraintsLoading(state),
  error: getConstraintsError(state),
  success: getConstraintsSuccess(state),
})

const mapDispatchToProps = {
  editSPOTimeConstraints: EditSPOConstraints,
  onGetPlan: GetPlanItem,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ConstraintsTable: FC<PropsFromRedux & Props> = (props) => {
  const numberOfLevels = Object.keys(props.plan.grouping_levels).length
  const groupingLevels: GroupingLevel[] = getGroupingLevels(props.plan)
  const [constraints, setConstraints] = useState<
    (string | number | undefined)[][]
  >([
    new Array(
      props.plan.config.observations.slice(
        props.plan.observations_min,
        props.plan.observations_max + 1 - props.plan.options.carryoverWeeks
      ).length +
        numberOfLevels +
        2
    ).fill(null),
  ])
  const [error, setError] = useState<string | null>(null)
  const [uploaded, setUploaded] = useState(false)
  const [file, setFile] = useState([])
  const [data, setData] = useState<(string | number | undefined)[][]>([])
  const [loadingFinished, setLoadingFinished] = useState(false)

  const { settings } = useSelector((state: AppState) => state.settings)

  const dispatch = useDispatch()

  useEffect(() => {
    setError(props.error)
  }, [props.error])

  useEffect(() => {
    if (props.success) {
      props.showModal(false)
      props.onGetPlan(props.plan.id)
    }
  }, [props.success])

  useEffect(() => {
    const currentConstraints = _.cloneDeep(props.plan.constraints)
    const filteredToTimeConstraints = currentConstraints.filter(
      // eslint-disable-next-line
      (con: any) => {
        if (Object.keys(con.identifiers).length === groupingLevels.length) {
          return con
        }
      }
    )

    let constraintArray = parseConstraints(filteredToTimeConstraints)

    const constraint = new Array(
      props.plan.config.observations.slice(
        props.plan.observations_min,
        props.plan.observations_max + 1 - props.plan.options.carryoverWeeks
      ).length +
        numberOfLevels +
        2
    ).fill(null)

    constraintArray.push(constraint)

    setConstraints(constraintArray)
    setLoadingFinished(true)
  }, [])

  const handleSubmit = () => {
    if (file.length > 0) {
      let container: (string | number)[][] = []

      for (let i = 0; i < file.length; i++) {
        container = [...container, file[i]]
      }

      setFile([])
      setConstraints([...container])
      setUploaded(true)
    }
  }

  const onChange = (e: any) => {
    const reader = new FileReader()
    reader.onload = onReaderLoad
    reader.readAsText(e.target.files[0])
  }

  const onReaderLoad = (e: any) => {
    const data = e.target.result.split('\n')
    const check = data
      .map((x: string) => x.split(','))[0]
      .map((y: string) => y.replace(/"/g, ''))

    const obsCheck =
      props.plan.observations_max +
      1 -
      props.plan.observations_min -
      props.plan.options.carryoverWeeks +
      numberOfLevels +
      1

    if (
      check[numberOfLevels] &&
      check[numberOfLevels] === 'Function' &&
      check.length === obsCheck
    ) {
      const removeHeader = data
        .slice(1, data.length)
        .map((x: string) => x.split(','))
      const formatArray = removeHeader.map((x: string[]) =>
        x.map((y) => y.replace(/"/g, ''))
      )
      const parsed = formatArray.map((x: any[]) => {
        for (let i = numberOfLevels + 1; i < x.length; i++) {
          if (x[i] !== null) {
            x[i] = parseInt(x[i])
          }
          if (isNaN(x[i])) {
            x[i] = null
          }
        }
        x.splice(numberOfLevels + 1, 0, null)
        return x
      })

      const filteredZeroValueConstraints = parsed.filter((x: any) => {
        let allNull = true
        for (let i = numberOfLevels + 1; i < x.length; i++) {
          if (x[i] !== null) {
            allNull = false
          }
        }
        if (!allNull) {
          return x
        }
      })
      setFile(filteredZeroValueConstraints)
      setError(null)
    } else {
      setError(
        'There is a problem with the file. Please check the dates match your current plan.'
      )
    }
  }

  const downloadCurrentConstraints = () => {
    if (!props.plan.config) {
      return false
    }

    const config = props.plan.config
    const levels: string[] = config.grouping_levels.map((x: any) => x.key)

    const observations = config.observations.slice(
      props.plan.observations_min,
      props.plan.observations_max + 1 - config.options.carryoverWeeks
    )

    const levelLabels = levels.map((x) => {
      const levelKey = x + '_name'
      const levelName = settings![levelKey] ? settings![levelKey] : x
      return levelName
    })

    let headers: string[] = [...levelLabels, 'Function']

    headers = [
      ...headers,
      ...observations.map((observation: GroupingLevel) => observation.label),
    ]


    let data: (string | number | undefined)[][] = []

    const newConstraints = _.cloneDeep(constraints)

      for (let c in newConstraints) {
          let constraint = _.cloneDeep(newConstraints[c])
          constraint.splice(levels.length + 1, 1)
          if (props.plan.options.carryoverWeeks > 0) {
            const sizeNoCarryover =
              props.plan.observations_max -
              props.plan.observations_min +
              1 -
              props.plan.options.carryoverWeeks
            if (constraint.length - levels.length + 1 !== sizeNoCarryover) {
              constraint = constraint.slice(
                0,
                sizeNoCarryover + levels.length + 1
              )
            }
            data = [...data, constraint]
          }
      }
    data.unshift(headers)
    setData(data)
  }

  const parseConstraints = (constraints: TimeConstraint[]) => {
    //takes all time constraints and puts them into the correct format for the table
    let constraintHolder = []
    const levelKeys = groupingLevels.map((x) => x.key)
    for (let con of constraints) {
      let parsedCon = []
      for (let i = 0; i < levelKeys.length; i++) {
        const level = groupingLevels.find(
          (lev: GroupingLevel) => lev.key === levelKeys[i]
        )?.values
        parsedCon[i] = level?.find(
          (val: GroupingLevelValue) => val.key === con.identifiers[levelKeys[i]]
        )?.label
      }
      parsedCon[levelKeys.length] =
        con.fn.charAt(0).toUpperCase() + con.fn.slice(1)
      for (let i in con.values) {
        parsedCon.push(con.values[i])
      }
      constraintHolder.push(parsedCon)
    }
    return constraintHolder
  }

  const addNewRow = () => {
    //clone current constraints and add a new one which is blank
    let currentConstraints = _.cloneDeep(constraints)
    const constraint = new Array(
      props.plan.config.observations.slice(
        props.plan.observations_min,
        props.plan.observations_max + 1 - props.plan.options.carryoverWeeks
      ).length +
        numberOfLevels +
        2
    ).fill(null)

    currentConstraints = [...currentConstraints, constraint]

    setConstraints(currentConstraints)
  }

  const resetConstraint = (value: number, rowId: number, position: number) => {
    let currentConstraints = _.cloneDeep(constraints)

    let constraint: (string | number | undefined)[] = new Array(
      props.plan.config.observations.slice(
        props.plan.observations_min,
        props.plan.observations_max + 1 - props.plan.options.carryoverWeeks
      ).length +
        numberOfLevels +
        2
    ).fill(null)

    constraint.forEach((con, ind) => {
      if (ind === position) {
        con = value
      } else if (ind < position) {
        con = currentConstraints[rowId][ind]
      }
      return con
    })

    currentConstraints[rowId] = constraint

    setConstraints(currentConstraints)
  }

  const removeRow = (rowId: number) => {
    //clone current constraints and slice out the row
    let currentConstraints = _.cloneDeep(constraints)

    currentConstraints.splice(rowId, 1)
    currentConstraints.pop()
    const constraint = new Array(
      props.plan.config.observations.slice(
        props.plan.observations_min,
        props.plan.observations_max + 1 - props.plan.options.carryoverWeeks
      ).length +
        numberOfLevels +
        2
    ).fill(null)

    currentConstraints.push(constraint)

    setConstraints(currentConstraints)
  }

  const getSelectedCurrency = () => {
    const exRates = props.plan.config.options.exchangeRates

    const index = exRates.currencies.indexOf(exRates.defaultCurrency)
    const selectedCurrency = {
      rate: exRates.rates[index],
      symbol: exRates.currencySymbols[index],
      currency: exRates.defaultCurrency,
    }

    return selectedCurrency
  }

  const getGroupingSelections = () => {
    let selectableLevels = _.cloneDeep(groupingLevels)
    if (settings) {
      selectableLevels.map((x) => {
        //pineapple resolve
        x.label = x.key
      })
    }
    return selectableLevels
  }

  const toggle = () => {
    // Reset global state for constraints error
    dispatch({ type: SOCKET_RESET_CONSTRAINTS_ERROR })
    props.showModal(false)
  }

  const submit = () => {
    const constraintsHolder = constraints.filter(
      // eslint-disable-next-line
      (con: any) => {
        if (con[0] !== null && con[0] !== '') {
          return con
        }
      }
    )

    let allBoxesPopulated = false

    //check that the first numberOfLevels + 1 fields are always populated (first level - constraint type)
    for (let con of constraintsHolder) {
      for (let i = 0; i < numberOfLevels + 1; i++) {
        if (con[i] === null || con[i] === '') {
          allBoxesPopulated = true
        }
      }
    }

    //add carryover weeks at the end of the constraints, and give the "total" as null
    let newConstraints: (string | number | undefined)[][] = []
    for (let constr of constraintsHolder) {
      const lengthOfPeriods = constr.length - numberOfLevels + 2
      const lengthWithCarryover =
        props.plan.observations_max - props.plan.observations_min + 1
      let concatArray = constr
      if (
        props.plan.options.carryoverWeeks &&
        props.plan.options.carryoverWeeks > 1
      ) {
        if (lengthOfPeriods !== lengthWithCarryover) {
          const carryoverArray = new Array(
            props.plan.options.carryoverWeeks
          ).fill(0)
          concatArray = constr.concat(carryoverArray)
        }
      }
      newConstraints = [...newConstraints, concatArray]
    }

    const newPlan = prepareNewPlan(newConstraints)

    if (!allBoxesPopulated && props.user) {
      setError(null)
      props.editSPOTimeConstraints(newPlan, newPlan.id, props.user)
    } else {
      setError(
        'Please make sure all dropdowns are selected for each constraint'
      )
    }
  }

  const prepareNewPlan = (constraints: (string | number | undefined)[][]) => {
    const constraintInputs = constraints.map((constraint) => {
      const levelKeys = groupingLevels.map((x) => x.key)

      const constraintActual: any = {
        identifiers: {},
        fn: constraint[numberOfLevels]?.toString().toLowerCase(),
        values: constraint.splice(numberOfLevels + 1),
      }
      for (let i = 0; i < levelKeys.length; i++) {
        const level =
          props.plan.config.grouping_levels
            .find((x: GroupingLevel) => x.key === levelKeys[i])
            ?.values.find((x: GroupingLevelValue) => x.label === constraint[i])
            ?.key || ''
        constraintActual.identifiers[levelKeys[i]] = level
      }
      return constraintActual
    })

    const existingNonTimeConstraints = props.plan.constraints.filter(
      (x: Constraint) => {
        if (Object.keys(x.identifiers).length !== numberOfLevels) {
          return x
        }
      }
    )

    const newPlan = {
      ...props.plan,
      constraints: existingNonTimeConstraints.concat(constraintInputs),
    }
    return newPlan
  }

  const changeObservationInput = (
    row: number,
    value: number,
    position: number
  ) => {
    //add numberOfLevels + 1 to make room for dimensions
    const index = position + numberOfLevels + 1

    let constraintCopy = _.cloneDeep(constraints)
    constraintCopy[row][index] = value

    setConstraints(constraintCopy)
  }

  const changeGroupingInput = (
    row: number,
    value: number,
    position: number
  ) => {
    let constraintCopy = _.cloneDeep(constraints)
    constraintCopy[row][position] = value

    setConstraints(constraintCopy)
  }
  const selectableLevels = getGroupingSelections()

  if (props.loading) {
    return <LoadingModal />
  }

  return (
    <div>
      <div className="justify-center flex overflow-x-hidden fixed inset-0 z-150 outline-none focus:outline-none max-w-screen-2xl m-auto h-full">
        <div className="my-auto mx-auto w-10/12 bg-white">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
            {props.plan && (
              <div className="w-full border">
                <h1 className="text-gray-500 text-md p-5 uppercase">{`Set Budget By Time Period (${props.plan.options.exchangeRates.defaultSymbol})`}</h1>
              </div>
            )}

            {/*body*/}
            <div className="p-5 flex flex-row w-full">
              <div className={'flex flex-col w-9/12'}>
                <h3 className="text-gray-500 uppercase font-bold">
                  File Upload
                </h3>
                <div className="my-2">
                  <input
                    type="file"
                    accept=".csv"
                    onChange={onChange}
                    className={'w-1/4'}
                  />
                  <button
                    onClick={handleSubmit}
                    className="clear-left rounded bg-gtPink text-white text-xs py-2 w-24"
                  >
                    Upload
                  </button>
                </div>
                <div>
                  <CSVLink
                    data={data}
                    filename={'TimeConstraintsTemplate.csv'}
                    onClick={downloadCurrentConstraints}
                    className={'text-gtPink text-xs'}
                  >
                    Download constraints
                  </CSVLink>
                </div>
                {error && <div className={'text-gtBlueSecondary'}>{error}</div>}
              </div>
            </div>
            <div className="flex flex-col items-start justify-between border-b border-solid border-blueGray-200 rounded-t h-full overflow-y-auto">
              {selectableLevels && props.plan && (
                <div className={'flex flex-row w-full'}>
                  <table>
                    <thead className="w-full border-t-2 border-b-2">
                      <tr>
                        <th />
                        {selectableLevels.map((level) => {
                          return (
                            <th key={level.label} className="text-xs px-5 py-3">
                              {level.label === 'Media'
                                ? 'Channel'
                                : level.label}
                            </th>
                          )
                        })}
                        <th className="text-xs px-5">Function</th>
                        {props.plan.config.observations
                          .slice(
                            props.plan.observations_min,
                            props.plan.observations_max +
                              1 -
                              props.plan.options.carryoverWeeks
                          )
                          .map((obs: Observation) => {
                            return (
                              <th key={obs.label} className="px-5">
                                {obs.label}
                              </th>
                            )
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="outline-none h-2"></tr>
                      {loadingFinished &&
                        constraints.map((constraint, index) => {
                          return (
                            <ConstraintsRow
                              resetConstraint={resetConstraint}
                              constraint={constraint}
                              changeGroupingInput={changeGroupingInput}
                              changeObservationInput={changeObservationInput}
                              addNewRow={addNewRow}
                              deleteRow={removeRow}
                              key={index}
                              rowNumber={index}
                              groupingLevels={groupingLevels}
                              uploaded={uploaded}
                              {...props}
                            />
                          )
                        })}
                      {constraints.length === 0 && (
                        <ConstraintsRow
                          resetConstraint={resetConstraint}
                          constraint={[]}
                          changeGroupingInput={changeGroupingInput}
                          changeObservationInput={changeObservationInput}
                          addNewRow={addNewRow}
                          deleteRow={removeRow}
                          key={0}
                          rowNumber={0}
                          groupingLevels={groupingLevels}
                          uploaded={uploaded}
                          {...props}
                        />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/*footer*/}
            <div className="flex flex-row py-5 justify-center align-middle w-full">
              <button
                className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 mr-4 uppercase"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                onClick={submit}
                className="clear-left rounded bg-gtPink text-white text-xs py-4 px-4 uppercase"
              >
                Regenerate plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(ConstraintsTable)
