import { useEffect, useState } from 'react'
import { IconType } from '@gain-theory/icon'
import { SectionId, scrollToId } from '../utils'
import { IUseCanWeHelpProps } from './types'
import { CanWeHelpProps } from '../../can-we-help/types'

const CAN_WE_HELP_FAQ_CARD_TITLE = 'FAQ'
const CAN_WE_HELP_FAQ_CARD_DESCRIPTION = 'Answer your most common questions.'
const CAN_WE_HELP_HELPFUL_LINKS_CARD_TITLE = 'Helpful links'
const CAN_WE_HELP_HELPFUL_LINKS_CARD_DESCRIPTION =
  'A collection of useful links for easy access.'
const CAN_WE_HELP_CONTACT_US_CARD_TITLE = 'Contact us'
const CAN_WE_HELP_CONTACT_US_CARD_DESCRIPTION =
  'Still have questions? Contact support.'

export function useCanWeHelp({
  hasFaq,
  hasHelpfulLinks,
  hasContactUs,
}: IUseCanWeHelpProps) {
  const [helpCards, setHelpCards] = useState<CanWeHelpProps['cards']>([])

  const faqHelpCard = {
    id: SectionId.FAQS,
    title: CAN_WE_HELP_FAQ_CARD_TITLE,
    description: CAN_WE_HELP_FAQ_CARD_DESCRIPTION,
    iconProps: {
      name: 'QuestionMarkCircleIcon' as IconType['name'],
    },
  }

  const helpfulLinkCard = {
    id: SectionId.HELPFUL_LINKS,
    title: CAN_WE_HELP_HELPFUL_LINKS_CARD_TITLE,
    description: CAN_WE_HELP_HELPFUL_LINKS_CARD_DESCRIPTION,
    iconProps: { name: 'LinkIcon' as IconType['name'] },
  }

  const contactUsCard = {
    id: SectionId.CONTACT_US,
    title: CAN_WE_HELP_CONTACT_US_CARD_TITLE,
    description: CAN_WE_HELP_CONTACT_US_CARD_DESCRIPTION,
    iconProps: { name: 'EnvelopeOpenIcon' as IconType['name'] },
  }

  const onCardClick = (id: string) => {
    const sectionId = id as SectionId

    scrollToId(sectionId)
  }

  useEffect(() => {
    const cards = [
      ...(hasFaq ? [faqHelpCard] : []),
      ...(hasHelpfulLinks ? [helpfulLinkCard] : []),
      ...(hasContactUs ? [contactUsCard] : []),
    ]

    setHelpCards([...cards])
  }, [hasFaq, hasHelpfulLinks, hasContactUs])

  return { helpCards, onCardClick }
}
