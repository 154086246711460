import {
  IComparisonData,
  PlanComparisons,
} from '../../types/PlanComparisonTypes'

export const PLAN_GET_COMPARISONS_ATTEMPT = 'PLAN_GET_COMPARISONS_ATTEMPT'
export const PLAN_GET_COMPARISONS_FAIL = 'PLAN_GET_COMPARISONS_FAIL'
export const PLAN_GET_COMPARISONS_SUCCESS = 'PLAN_GET_COMPARISONS_SUCCESS'
export const PLAN_SAVE_COMPARISONS_ATTEMPT = 'PLAN_SAVE_COMPARISONS_ATTEMPT'
export const PLAN_SAVE_COMPARISONS_FAIL = 'PLAN_SAVE_COMPARISONS_FAIL'
export const PLAN_SAVE_COMPARISONS_SUCCESS = 'PLAN_SAVE_COMPARISONS_SUCCESS'
export const PLAN_NAME_COMPARISONS_ATTEMPT = 'PLAN_NAME_COMPARISONS_ATTEMPT'
export const PLAN_NAME_COMPARISONS_FAIL = 'PLAN_NAME_COMPARISONS_FAIL'
export const PLAN_NAME_COMPARISONS_SUCCESS = 'PLAN_NAME_COMPARISONS_SUCCESS'
export const PLAN_GET_COMPARISONS_BY_ID_ATTEMPT =
  'PLAN_GET_COMPARISONS_BY_ID_ATTEMPT'
export const PLAN_GET_COMPARISONS_BY_ID_FAIL = 'PLAN_GET_COMPARISONS_BY_ID_FAIL'
export const PLAN_GET_COMPARISONS_BY_ID_SUCCESS =
  'PLAN_GET_COMPARISONS_BY_ID_SUCCESS'
export const PLAN_GET_COMPARISONS_DATA_ATTEMPT =
  'PLAN_GET_COMPARISONS_DATA_ATTEMPT'
export const PLAN_GET_COMPARISONS_DATA_FAIL = 'PLAN_GET_COMPARISONS_DATA_FAIL'
export const PLAN_GET_COMPARISONS_DATA_SUCCESS =
  'PLAN_GET_COMPARISONS_DATA_SUCCESS'
export const PLAN_COMPARISON_DATA = 'PLAN_COMPARISON_DATA'
export const PLAN_COMPARISON_DELETE_ATTEMPT = 'PLAN_COMPARISON_DELETE_ATTEMPT'
export const PLAN_COMPARISON_DELETE_SUCCESS = 'PLAN_COMPARISON_DELETE_SUCCESS'
export const PLAN_COMPARISON_DELETE_FAIL = 'PLAN_COMPARISON_DELETE_FAIL'
export const PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT =
  'PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT'
export const PLAN_COMPARISONS_UPDATE_BASE_SUCCESS =
  'PLAN_COMPARISONS_UPDATE_BASE_SUCCESS'
export const PLAN_COMPARISONS_UPDATE_BASE_FAIL =
  'PLAN_COMPARISONS_UPDATE_BASE_FAIL'
export const UPDATE_PLAN_COMPARISON = 'UPDATE_PLAN_COMPARISON'


export const CHANGE_KPIS_COMPARISONS_ATTEMPT = 'CHANGE_KPIS_COMPARISONS_ATTEMPT'
export const CHANGE_KPIS_COMPARISONS_SUCCESS = 'CHANGE_KPIS_COMPARISONS_SUCCESS'
export const CHANGE_KPIS_COMPARISONS_FAIL = 'CHANGE_KPIS_COMPARISONS_FAIL'

export const CHANGE_CURRENCY_COMPARISONS_ATTEMPT = "CHANGE_CURRENCY_COMPARISONS_ATTEMPT"
export const CHANGE_CURRENCY_COMPARISONS_SUCCESS = "CHANGE_CURRENCY_COMPARISONS_SUCCESS"
export const CHANGE_CURRENCY_COMPARISONS_FAIL = "CHANGE_CURRENCY_COMPARISONS_FAIL"


interface PLAN_GET_COMPARISONS_ATTEMPT {
  type: typeof PLAN_GET_COMPARISONS_ATTEMPT
}

interface PLAN_GET_COMPARISONS_FAIL {
  type: typeof PLAN_GET_COMPARISONS_FAIL
}

interface PLAN_GET_COMPARISONS_SUCCESS {
  type: typeof PLAN_GET_COMPARISONS_SUCCESS
  payload: PlanComparisons[]
}

interface PLAN_GET_COMPARISONS_BY_ID_ATTEMPT {
  type: typeof PLAN_GET_COMPARISONS_BY_ID_ATTEMPT
}

interface PLAN_GET_COMPARISONS_BY_ID_FAIL {
  type: typeof PLAN_GET_COMPARISONS_BY_ID_FAIL
}

interface PLAN_GET_COMPARISONS_BY_ID_SUCCESS {
  type: typeof PLAN_GET_COMPARISONS_BY_ID_SUCCESS
  payload: PlanComparisons
}

interface PLAN_GET_COMPARISONS_DATA_ATTEMPT {
  type: typeof PLAN_GET_COMPARISONS_DATA_ATTEMPT
}

interface PLAN_GET_COMPARISONS_DATA_FAIL {
  type: typeof PLAN_GET_COMPARISONS_DATA_FAIL
}

interface PLAN_GET_COMPARISONS_DATA_SUCCESS {
  type: typeof PLAN_GET_COMPARISONS_DATA_SUCCESS
}

interface PLAN_SAVE_COMPARISONS_ATTEMPT {
  type: typeof PLAN_SAVE_COMPARISONS_ATTEMPT
}

interface PLAN_SAVE_COMPARISONS_FAIL {
  type: typeof PLAN_SAVE_COMPARISONS_FAIL
}

interface PLAN_SAVE_COMPARISONS_SUCCESS {
  type: typeof PLAN_SAVE_COMPARISONS_SUCCESS
  payload: PlanComparisons
}

interface PLAN_NAME_COMPARISONS_ATTEMPT {
  type: typeof PLAN_NAME_COMPARISONS_ATTEMPT
}

interface PLAN_NAME_COMPARISONS_FAIL {
  type: typeof PLAN_NAME_COMPARISONS_FAIL
}

interface PLAN_NAME_COMPARISONS_SUCCESS {
  type: typeof PLAN_NAME_COMPARISONS_SUCCESS
}

interface PLAN_COMPARISON_DATA {
  type: typeof PLAN_COMPARISON_DATA
  payload: IComparisonData
}

interface PLAN_COMPARISON_DELETE_ATTEMPT {
  type: typeof PLAN_COMPARISON_DELETE_ATTEMPT
}

interface PLAN_COMPARISON_DELETE_SUCCESS {
  type: typeof PLAN_COMPARISON_DELETE_SUCCESS
}

interface PLAN_COMPARISON_DELETE_FAIL {
  type: typeof PLAN_COMPARISON_DELETE_FAIL
}

interface PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT {
  type: typeof PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT
}

interface PLAN_COMPARISONS_UPDATE_BASE_SUCCESS {
  type: typeof PLAN_COMPARISONS_UPDATE_BASE_SUCCESS
}

interface PLAN_COMPARISONS_UPDATE_BASE_FAIL {
  type: typeof PLAN_COMPARISONS_UPDATE_BASE_FAIL
}

interface UPDATE_PLAN_COMPARISON {
  type: typeof UPDATE_PLAN_COMPARISON
}

interface CHANGE_KPIS_COMPARISONS_ATTEMPT {
  type: typeof CHANGE_KPIS_COMPARISONS_ATTEMPT
}
interface CHANGE_KPIS_COMPARISONS_SUCCESS {
  type: typeof CHANGE_KPIS_COMPARISONS_SUCCESS
}

interface CHANGE_KPIS_COMPARISONS_FAIL {
  type: typeof CHANGE_KPIS_COMPARISONS_FAIL
}

interface CHANGE_CURRENCY_COMPARISONS_ATTEMPT {
  type: typeof CHANGE_CURRENCY_COMPARISONS_ATTEMPT
}
interface CHANGE_CURRENCY_COMPARISONS_SUCCESS {
  type: typeof CHANGE_CURRENCY_COMPARISONS_SUCCESS
}

interface CHANGE_CURRENCY_COMPARISONS_FAIL {
  type: typeof CHANGE_CURRENCY_COMPARISONS_FAIL
}


export type PlanComparisonActionTypes =
  | PLAN_GET_COMPARISONS_ATTEMPT
  | PLAN_GET_COMPARISONS_FAIL
  | PLAN_GET_COMPARISONS_SUCCESS
  | PLAN_SAVE_COMPARISONS_ATTEMPT
  | PLAN_SAVE_COMPARISONS_FAIL
  | PLAN_SAVE_COMPARISONS_SUCCESS
  | PLAN_NAME_COMPARISONS_ATTEMPT
  | PLAN_NAME_COMPARISONS_FAIL
  | PLAN_NAME_COMPARISONS_SUCCESS
  | PLAN_GET_COMPARISONS_BY_ID_ATTEMPT
  | PLAN_GET_COMPARISONS_BY_ID_FAIL
  | PLAN_GET_COMPARISONS_BY_ID_SUCCESS
  | PLAN_GET_COMPARISONS_DATA_SUCCESS
  | PLAN_GET_COMPARISONS_DATA_FAIL
  | PLAN_GET_COMPARISONS_DATA_ATTEMPT
  | PLAN_COMPARISON_DATA
  | PLAN_COMPARISON_DELETE_SUCCESS
  | PLAN_COMPARISON_DELETE_ATTEMPT
  | PLAN_COMPARISON_DELETE_FAIL
  | PLAN_COMPARISONS_UPDATE_BASE_FAIL
  | PLAN_COMPARISONS_UPDATE_BASE_SUCCESS
  | PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT
  | UPDATE_PLAN_COMPARISON
  | CHANGE_KPIS_COMPARISONS_ATTEMPT
  | CHANGE_KPIS_COMPARISONS_SUCCESS
  | CHANGE_KPIS_COMPARISONS_FAIL
  | CHANGE_CURRENCY_COMPARISONS_ATTEMPT
  | CHANGE_CURRENCY_COMPARISONS_SUCCESS
  | CHANGE_CURRENCY_COMPARISONS_FAIL
