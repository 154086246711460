import { memo } from 'react';
import { FeatureContext } from './feature-context';
import { IFeatureProvider } from './types';

export const FeatureProvider = memo(
  ({ children, ...rest }: IFeatureProvider.Props) => {
    return (
      <FeatureContext.Provider value={rest}>{children}</FeatureContext.Provider>
    );
  }
);
