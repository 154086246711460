import { FC, useState } from 'react'
import Slider from 'rc-slider'
import appendUnit from '../../../../utils/appendUnit'

interface OwnProps {
  barBudget: any
  calculatedMaxScale: number
  realMax: number
  onUpdate: (value: number, barBudget: any) => void
  color: string
}

type Props = OwnProps

const BudgetSlider: FC<Props> = (props) => {
  const value = props.barBudget.sTransformation.calculated_spend

  const [sliderValue, setSliderValue] = useState(value)
  const onChange = (value: number) => {

    let valueInput = value

    if(value > props.realMax){
      valueInput = props.realMax
    }

    if (
      valueInput >
      props.barBudget.sTransformation.calculated_spend + props.realMax
    ) {
      setSliderValue(
        props.barBudget.sTransformation.calculated_spend + props.realMax
      )
    } else {
      setSliderValue(valueInput)
    }
  }

  const onAfterChange = (value: number) => {
    props.onUpdate(value, props.barBudget)
  }

  const getHandleComponent = ({ offset, value }: any) => {
    return (
        <div
            className={`${offset < 15 ? `absolute ml-3 mt-3` : `absolute text-white z-50 right-4 mt-3 -mr-3`}`}
            style={offset < 15 ? { left: `${offset}%` } : { left: `${offset - 7}%` }}
        >
            <div>
                {value >= 0 && appendUnit(value, "currency", "", "")}
            </div>
        </div>
    );
}

  return (
    <div className="w-7/12 text-gray-600 text-xs relative">
      <div
        className="h-full bg-gtGreen "
        style={{
          width: `${
            (props.barBudget.sTransformation.optimised_spend /
              props.calculatedMaxScale) *
            100
          }%`,
          opacity: '40%',
        }}
      />
      <div className="media-alloc-channel-budget absolute w-full left-0 top-0 h-full z-40 items-center flex">
        <Slider
          min={0}
          value={sliderValue}
          onChange={onChange}
          onAfterChange={onAfterChange}
          max={props.calculatedMaxScale}
          trackStyle={{
            backgroundColor: props.color,
          }}
          handle={getHandleComponent}
        />
      </div>
    </div>
  )
}

export default BudgetSlider
