import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Welcome } from '../welcome'
import { Modules } from '../modules'
import { CanWeHelp } from '../can-we-help'
import { Faq } from '../faq'
import { HelpfulLinks } from '../helpful-links'
import { Support } from '../support'
import {
  useCanWeHelp,
  useFaqs,
  useHelpfulLinks,
  useProductsAndModules,
  useSupportContact,
} from './hooks'
import { SectionId } from './utils'
import { MainLayout } from '../../../shared/MainLayout'
import { GetLaunchpadData } from '../../../redux/actions/MarketingDiagnosticsActions'
import { AppState } from '../../../redux/reducers/RootReducer'
import LoadingModal from '../../../shared/plan-view/LoadingModal'

const LaunchpadHome = memo(() => {
  const { loading } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const dispatch = useDispatch()
  const history = useHistory()

  const {
    serviceAreaChips,
    selectedServiceArea,
    productCards,
    selectedProduct,
    moduleCards,
    highlightedModule,
    productDescription,
    onServiceAreaClick,
    onProductCardClick,
    getSelectedModuleHref,
  } = useProductsAndModules()
  const { faqs } = useFaqs()
  const { helpfulLinks, onLinkClick } = useHelpfulLinks()
  const { contact, onMailClick } = useSupportContact()

  const hasFaq = faqs.length > 0
  const hasHelpfulLinks = helpfulLinks.length > 0
  const hasContactUs = contact.email !== '' && contact.name !== ''

  const showCanWeHelp = hasFaq || hasHelpfulLinks || hasContactUs

  const { helpCards, onCardClick } = useCanWeHelp({
    hasFaq,
    hasHelpfulLinks,
    hasContactUs,
  })

  useEffect(() => {
    dispatch(GetLaunchpadData())
  }, [])

  const onModuleActionClick = () => {
    const href = getSelectedModuleHref()

    if (href !== null) {
      history.push(href)
      return
    }

    onMailClick()
  }

  if (loading) {
    return <LoadingModal />
  }

  return (
    <div className="grid grid-cols-6 xl:grid-cols-12 gap-x-md gap-y-xxxxxl">
      <Welcome
        id={SectionId.WELCOME}
        chips={serviceAreaChips}
        selectedChip={selectedServiceArea}
        selectedCard={selectedProduct}
        cards={productCards}
        onChipClick={onServiceAreaClick}
        onCardClick={onProductCardClick}
      />
      <Modules
        id={SectionId.MODULES}
        modules={moduleCards}
        selectedModule={highlightedModule}
        description={productDescription}
        onModuleActionClick={onModuleActionClick}
      />
      {showCanWeHelp && (
        <CanWeHelp
          id={SectionId.CAN_WE_HELP}
          cards={helpCards}
          onCardClick={onCardClick}
        />
      )}
      {hasFaq && <Faq id={SectionId.FAQS} faqs={faqs} />}
      {hasHelpfulLinks && (
        <HelpfulLinks
          id={SectionId.HELPFUL_LINKS}
          links={helpfulLinks}
          onCardClick={onLinkClick}
        />
      )}
      {hasContactUs && (
        <Support
          id={SectionId.CONTACT_US}
          contact={contact}
          onMailClick={onMailClick}
        />
      )}
    </div>
  )
})

export default MainLayout(LaunchpadHome, true)
