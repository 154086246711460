import { VisualPerformance } from "../visual-performance";
import { Channel, KPI } from '../../../../../types/MarketingDiagnosticsTypes';
import { ERRNTable } from "./errn-table";
import { BestWorst } from "./best-worst";

type TDoubleDrilldown = {
  defaultHeaders: string[];
  chartDrillData: any[];
  channel: Channel;
  marketingDiagnostics: any;
  selectedKpi: string;
  settings: any;
  activeERRN: any;
  kpis: KPI[]
}
export const DoubleDrilldown = ({
  defaultHeaders,
  chartDrillData,
  channel,
  marketingDiagnostics,
  selectedKpi,
  settings,
  activeERRN,
  kpis
}: TDoubleDrilldown) => {
  return (
    <div className="grid gap-3 -mt-4 border-gtPink border border-t-0 rounded rounded-t-none p-5">
      <VisualPerformance
        chartDataChannel={chartDrillData}
        defaultHeaders={defaultHeaders}
        marketingDiagnostics={marketingDiagnostics}
        selectedKpi={selectedKpi}
        settings={settings}
        channel={channel}
      />
      <div className="grid grid-flow-col auto-cols-min gap-5 grid-cols-5">

        <ERRNTable activeERRN={activeERRN} />
        <BestWorst
          channel={channel}
          defaultHeaders={defaultHeaders || []}
          kpis={kpis}
        />
      </div>

    </div>
  );
};
