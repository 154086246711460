import { memo } from 'react'
import shapeMain from '../../img/login-shape-main.svg'

export const ShapeMain = memo(() => {
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      style={{
        backgroundImage: `url(${shapeMain})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
      }}
      className="absolute left-0 w-[calc((100%/12)*11)] lg:w-[calc((100%/12)*9)] aspect-[965/742]"
    />
  )
})
