import { Typography } from '@gain-theory/typography';
import { Children, ReactNode } from 'react';
import { Channel } from '../../../../../types/MarketingDiagnosticsTypes';
import appendUnit from '../../../../../utils/appendUnit';
import { Chart } from './chart';

type TVisualPerformance = {
  heading?: string;
  defaultHeaders: string[];
  selectedKpi: string;
  chartDataChannel: any[];
  settings: any;
  marketingDiagnostics: any;
  channel?: Channel;
}
export const VisualPerformance = ({
  heading,
  defaultHeaders,
  selectedKpi,
  chartDataChannel,
  settings,
  marketingDiagnostics,
  channel,
}: TVisualPerformance) => {
  const getData = (drill: string) => {
    const key =
      drill !== selectedKpi
        ? drill.toLowerCase().replace(/ /g, "_")
        : "kpi";
    const chartData = chartDataChannel.map(
      x => x[key]
    ).filter(Boolean);
    const dates = chartData.length ? chartDataChannel.map(
      x => x.date
    ) : [];
    let totalData = chartData.reduce(
      (a, b) => a + b,
      0
    );
    if (drill === "Effectiveness") {
      let totalKPI = chartDataChannel
        .map(x => x.kpi)
        .reduce((a, b) => a + b, 0);
      let totalImp = chartDataChannel
        .map(x => x.impressions)
        .reduce((a, b) => a + b, 0);
      totalData = totalKPI / (totalImp / 1000000);
    }
    if (drill === "Productivity") {
      let totalKPI = chartDataChannel
        .map(x => x.kpi)
        .reduce((a, b) => a + b, 0);
      let totalSpend = chartDataChannel
        .map(x => x.spend)
        .reduce((a, b) => a + b, 0);
      totalData = totalKPI / (totalSpend / 1000);
    }
    if (channel) {
      totalData = (channel as any)[key];
    }
    return {
      chartData,
      dates,
      totalData,
      showLoading: chartDataChannel.length === 0
    };
  }
  const { showLoading } = getData('spend');
  return (<div className="grid gap-3">
    {(showLoading && heading && heading.length !== undefined) && (
      <div className="w-1/3 h-10 rounded-md animate-pulse bg-gray-200" />
    )}
    {(!showLoading && heading && heading.length !== undefined) && (
      <h2 className='text-gtDarkGray'>
        {heading}
      </h2>
    )}
    <div className="grid grid-flow-col gap-3 auto-cols-fr">
      {Children.toArray(defaultHeaders.map((drill: string) => {
        const {
          chartData,
          dates,
          totalData,
          showLoading
        } = getData(drill);
        return (
          <div className="grid gap-3 p-2 border rounded-lg shadow  bg-white">
            {showLoading ? (
              <div className={`w-10/12 h-3 rounded-md animate-pulse bg-gray-200`} />
            ) : (
              <Typography.Subtitle1 className="text-gtPink">
                {drill}
              </Typography.Subtitle1>
            )}
            <Chart
              name={drill}
              data={chartData}
              dates={dates}
              showLoading={showLoading}
              dateFormat={settings?.date_format} />
            {!(chartData.length === 0 || dates.length === 0) && (
              <h3 className='text-gtPink text-center pb-4'>
                {drill === "Spend" && marketingDiagnostics
                  ? appendUnit(
                    totalData,
                    "currency",
                    marketingDiagnostics.currency[0]
                      .symbol, ""
                  )
                  : appendUnit(totalData, "", "", "")}
              </h3>
            )}
            {showLoading && (
              <div className="w-10/12 h-3 rounded-md animate-pulse bg-gray-200" />
            )}
          </div>
        );
      }))}
    </div>
  </div>)
}