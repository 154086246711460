import { EditPlanRequest } from '../../types/PlanTypes'
import { Dispatch } from 'redux'
import {
  EditPlanActionTypes,
  HIDE_EDIT_PLAN_MODAL,
  SHOW_EDIT_PLAN_MODAL,
} from './EditPlanActionTypes'

export const ShowEditPlanModal = (section: number) => (
  dispatch: Dispatch<EditPlanActionTypes>
) => {
  dispatch({ type: SHOW_EDIT_PLAN_MODAL, payload: section })
}

export const HideEditPlanModal = () => (
  dispatch: Dispatch<EditPlanActionTypes>
) => {
  dispatch({ type: HIDE_EDIT_PLAN_MODAL })
}

export const EditPlan = (data: EditPlanRequest, configId: string) => {}
