import React, { FC, useState, useEffect } from 'react'
import { MainLayout } from '../../../shared/MainLayout'
import { Link, useHistory } from 'react-router-dom'
import { Options, RouteComponentProps, SelectedOptions } from '../../../types/CreatePlanTypes'
import { RootStore } from '../../../redux/reducers/Store'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { getConfigById } from '../../../redux/reducers/ConfigReducer'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { Settings } from '../../../types/SettingTypes'
import { GetConfigById } from '../../../redux/actions/ConfigActions'
import { Observation, Transformation } from '../../../types/PlanTypes'
import _, { over } from 'lodash'
import TimeSlider from '../create-plan/TimeSlider'
import appendUnit from '../../../utils/appendUnit'
import { ROUTES } from '../../../Routes'
import {
  selectCreateError,
  selectLoading,
  selectPlanItem,
  selectPlanCreatedComplete,
} from '../../../redux/reducers/PlanReducer'
import * as moment from 'moment'
import NumberFormat from 'react-number-format'
import { CheckBoxItem } from '../../../types/CreatePlanTypes'
import { treemapBinary } from 'd3-hierarchy'
import { MaxConstraint } from '../../../types/ConfigTypes'
import { createSPOScenario } from '../../../socket.io'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import UrlAssembler from 'url-assembler'
import { AppState } from '../../../redux/reducers/RootReducer'

interface OwnProps {}

interface MatchParams {
  id: string
}

const mapState = (state: RootStore) => ({
  config: getConfigById(state),
  user: selectSessionUser(state),
  optimiserError: selectCreateError(state),
  loading: selectLoading(state),
  plan: selectPlanItem(state),
  planCreated: selectPlanCreatedComplete(state),
})

const mapDispatchToProps = {
  onGetConfigById: GetConfigById,
  createSPOPlan: createSPOScenario,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const CreateInChannelPlan: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const [page, setPage] = useState(0)
  const [error, setError] = useState('')
  const [options, setOptions] = useState<Options>({})
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const [optimisationGoal, setOptimisationGoal] = useState('')
  const [selectedCurrencySymbol, setCurrencySymbol] = useState('')
  const [transformationOptions, setTransformationOptions] = useState<
    Transformation[]
  >([])
  const [levelKeys, setLevelKeys] = useState<string[]>([])
  const [overallConstraint, setOverallConstraint] = useState(0)
  const [selectAll, setSelectAll] = useState('Select all')
  const [observations, setObservations] = useState<Observation[]>([])
  const [sliderPosition, setSliderPosition] = useState([0, 1])
  const [planName, setPlanName] = useState("")
  const [numberOfLevels, setNumberOfLevels] = useState(0)
  const [hasPermission, setHasPermission] = useState(false)

  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)

  const { user } = useSelector(
    (state: AppState) => state.session
  )

  useEffect(() => {
    if (user) {
      if (user?.userGroups.length > 0) {
        const hasSpoPermission =
          user?.userGroups.find((userGroup) =>
            userGroup.group.modules?.includes('spo')
          ) != undefined
        setHasPermission(hasSpoPermission)
      }
    }
  }, [user])



  useEffect(() => {
    props.onGetConfigById(props.match.params.id)
  }, [])

  const getSelectedCurrency = (symbol: string) => {
    const index = props.config
      ? props.config.options.exchangeRates.currencySymbols.indexOf(symbol)
      : 0
    const overallConstraintCurrencyDivisor = props.config
      ? props.config.options.exchangeRates.rates[index]
      : 0

    const defaultCurrency = props.config
      ? props.config.options.exchangeRates.currencies[index]
      : ''

    const defaultCurrencySymbol = props.config
      ? props.config.options.exchangeRates.currencySymbols[index]
      : ''

    const selectedCurrency = {
      rate: overallConstraintCurrencyDivisor,
      currency: defaultCurrency,
      symbol: defaultCurrencySymbol,
    }

    return selectedCurrency
  }

  useEffect(() => {
    if(props.config){
    const level1Values = props.config.grouping_levels[0].values 
    const alphabeticalLevel1Values = level1Values.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })

    const checkBoxLevel1 = alphabeticalLevel1Values.map((x) => {
      const lev = {
        ...x,
        isChecked: false,
      }
      return lev
    }).filter(x => {
      if(props.user?.permissions.indexOf(x.key) !== -1){
        return x
      }
    })

    if(checkBoxLevel1.length === 0){
      setHasPermission(false)
      return
    }



    const optimisationGoal = props.config?.options.optimisableKPIs[0] || ''
    const currencySymbol =
      props.config?.options.exchangeRates.defaultSymbol || ''
    const transOptions =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []

    const carryoverObs =
      props.config?.observations.slice(
        0,
        props.config?.observations.length - props.config?.options.carryoverWeeks
      ) || []

    let dateFormat = settings?.date_format

    carryoverObs.map(
      (obs) => (obs.label = moment.utc(obs.label).format(dateFormat))
    )

    const levKeys = props.config.grouping_levels.map(x => x.key)
    setLevelKeys(levKeys)

    let optionsVals: Options = {}
    let selectedVals: SelectedOptions = {}
    for(let key of levKeys){
      optionsVals[key] = []
      selectedVals[key] = []
    }
    checkBoxLevel1[0].isChecked = true
    selectedVals[levKeys[0]] = [checkBoxLevel1[0].key]
    optionsVals[levKeys[0]] = checkBoxLevel1

    setNumberOfLevels(levKeys.length)
    setSliderPosition([0, carryoverObs.length - 1])
    setObservations(carryoverObs)
    setOptions(optionsVals)
    setSelectedOptions(selectedVals)
    setOptimisationGoal(optimisationGoal)
    setCurrencySymbol(currencySymbol)
    setTransformationOptions(transOptions)
    }
  }, [props.config])

  useEffect(() => {
    setError(props.optimiserError!)
  }, [props.optimiserError])

  let history = useHistory()
  useEffect(() => {
    if (props.planCreated) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.IN_CHANNEL_PLAN_ITEM)
        .param('id', props.plan.id)
        .toString()

      history.push(url)
    }
  }, [props.planCreated])

  const nextPage = () => {
    getNewOptions()
    setPage((prevPage) => prevPage + 1)
    setSelectAll('Select all')
  }

  const previousPage = () => {
    unFilterTransformations()
    setPage((prevPage) => prevPage - 1)
    setError('')
    setSelectAll('Select all')
  }

  const getNewOptions = () => {
    if(page !== 0 && page < numberOfLevels){
      const nextLevelKey = levelKeys[page]
      const nextLevels = calculateOptions(levelKeys[page - 1], nextLevelKey)
      setOptions({...options, [nextLevelKey]: nextLevels})
    }
  }

  const calculateOptions = (
    parent: string,
    groupingLevel: string
  ): CheckBoxItem[] => {
    let newSelectedOptions = selectedOptions
    if(levelKeys[page - 1] && options[levelKeys[page - 1]].length === 1){
      newSelectedOptions = {...selectedOptions, [levelKeys[page - 1]]: [options[levelKeys[page - 1]][0].key]}
    }
    const newTransformationOptions = transformationOptions.filter((x) => {
      if (newSelectedOptions[parent].length !== 0) {
        if (newSelectedOptions[parent].includes(x.identifiers[parent])) {
          return x
        }
      } else {
        return x
      }
    })
    const keys = newTransformationOptions.map(
      (x) => x.identifiers[groupingLevel]
    )
    const uniqueKeys = _.uniqBy(keys, function (e) {
      return e
    })
    const allLevels =
      props.config?.grouping_levels.find((x) => x.key === groupingLevel)
        ?.values || []

    const levels = uniqueKeys.map((x) => {
      const level = {
        key: x,
        label: allLevels.find((y) => y.key === x)?.label || '',
        isChecked: newSelectedOptions[groupingLevel].includes(x) ? true : false,
      }
      return level
    })

    setSelectedOptions(newSelectedOptions)

    setTransformationOptions(newTransformationOptions)
    return levels
  }

  const unFilterTransformations = () => {
    let newTransforms =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []
    //this stops the user from hitting 'Previous' but the transformation list still being filtered from calculateOptions()
    if (page <= numberOfLevels ) {
      for (let i = page - 1; i > 1; i--) {
        newTransforms = newTransforms.filter((x) => {
          if (selectedOptions[levelKeys[i - 2]].length !== 0) {
            if (
              selectedOptions[levelKeys[i - 2]].includes(
                x.identifiers[levelKeys[i - 2]]
              )
            ) {
              return x
            }
          } else {
            return x
          }
        })
      }
      setTransformationOptions(newTransforms)
    }
  }

  const getOptimisationText = (settings: any) => {
    if(inChannelSettings){
      if(page > 0 && page <= numberOfLevels){
        const levelKey = levelKeys[page - 1]
        const levelLabel = props.config?.grouping_levels.find(lev => lev.key === levelKey)
        //pineapple: NEED TO REFACTOR ONCE ADMIN IS HOOKED UP
        const levelName = levelLabel ? levelLabel.label : ''
        const levelNameup = levelKey + '_plural'
        let name = inChannelSettings[levelKey] && inChannelSettings[levelKey].plural ?  inChannelSettings[levelKey].plural : settings[levelNameup] ? settings[levelNameup].toLowerCase() : levelName.toLowerCase()
  
        return `What ${name} do you want to include?`
      }
      if (page === numberOfLevels + 1) {
        return 'What time period?'
      }
      if (page === numberOfLevels + 2) {
        return 'What is your goal?'
      }
      if (page === numberOfLevels + 3) {
        return "What's your budget?"
      }
    }
    if (page === 0) {
      return "Name your plan"
    }


  }

  const getOptimisationOptions = () => {
    const optimisableKPIs = props.config
      ? props.config.options.optimisableKPIs
      : ''

    if (page < numberOfLevels + 1 && page !== 0) {
      return options[levelKeys[page - 1]]
    }
    if (page === numberOfLevels + 2) {
      const outputs =
        props.config && props.config.transformations[0]
          ? props.config.transformations[0].io
              .filter((x) => x.type === 'output')
              .filter((x) => {
                if (optimisableKPIs.indexOf(x.key) !== -1) {
                  return x
                }
              })
              .sort(function (a, b) {
                return (
                  optimisableKPIs.indexOf(a.key) -
                  optimisableKPIs.indexOf(b.key)
                )
              })
          : []
      return outputs
    }
    return []
  }

  const selectOption = (e: any) => {
    if (page < numberOfLevels && page !== 0) {
      const updateBoxes = options[levelKeys[page - 1]].map((x) => {
        if (x.key === e.target.value) {
          x.isChecked = true
        } else {
          x.isChecked = false
        }
        return x
      })
      setOptions({ ...options, [levelKeys[page - 1]]: updateBoxes })
      setSelectedOptions({
        ...selectedOptions,
        [levelKeys[page - 1]]: [e.target.value],
      })
    }
    if (page === numberOfLevels) {
      const updateBoxes = options[levelKeys[page - 1]].map((x) => {
        if (x.key === e.target.value) {
          x.isChecked = !x.isChecked
        }
        return x
      })
      setOptions({ ...options, [levelKeys[page - 1]]: updateBoxes })

      if (selectedOptions[levelKeys[page - 1]].includes(e.target.value)) {
        const options = selectedOptions[levelKeys[page - 1]].filter(
          (x) => x !== e.target.value
        )
        setSelectedOptions({
          ...selectedOptions,
          [levelKeys[page - 1]]: options,
        })
      } else {
        setSelectedOptions({
          ...selectedOptions,
          [levelKeys[page - 1]]: [
            ...selectedOptions[levelKeys[page - 1]],
            e.target.value,
          ],
        })
      }
    }
    if (page === numberOfLevels + 2) {
      setOptimisationGoal(e.target.value)
    }
  }

  const handleClickCurrency = (e: any) => {
    setCurrencySymbol(e.target.value)
  }

  const handleSelectAll = () => {
    const isSelectAll = selectAll === 'Select all'
    const text = isSelectAll ? 'Clear all' : 'Select all'
    let allLevels: string[] = []
    if (page < numberOfLevels + 2 && page !== 0) {
      setOptions({
        ...options,
        [levelKeys[page - 1]]: options[levelKeys[page - 1]].map((x) => {
          return {
            ...x,
            isChecked: isSelectAll ? true : false,
          }
        }),
      })
      allLevels = options[levelKeys[page - 1]].map((x) => x.key)
    }
    setSelectAll(text)
    setSelectedOptions({
      ...selectedOptions,
      [levelKeys[page - 1]]: isSelectAll ? allLevels : [],
    })
  }

  const validateOverallConstraint = (): boolean => {
    const spendRange = props.config?.options.kpiRanges.find(
      (range) => range.kpi === 'spend'
    )
    const minOptimisationMetric = spendRange?.min || 1000
    const maxOptimisationMetric = spendRange?.max || 100000000

    const currencySymbol = props.plan?.options.exchangeRates.defaultSymbol || ''
    const numberFormat = settings?.number_format || ''

    if (isNaN(overallConstraint)) {
      setError('Please enter a valid number')
      return false
    } else if (
      overallConstraint > maxOptimisationMetric ||
      overallConstraint < minOptimisationMetric
    ) {
      setError(`Please enter a valid number between ${appendUnit(
        minOptimisationMetric,
        'currency',
        currencySymbol,
        numberFormat
      )}
      and ${appendUnit(
        maxOptimisationMetric,
        'currency',
        currencySymbol,
        numberFormat
      )}.`)
      return false
    }
    return true
  }

  const handleMoveSlider = (position: number[]) => {
    setSliderPosition(position)
  }

  const submit = () => {
    const validated = validateOverallConstraint()
    if (validated && props.config) {
      const currency = getSelectedCurrency(selectedCurrencySymbol)
      const attrs = {
        optimisationType: 'budget',
        grouping_levels: selectedOptions,
        observations: sliderPosition,
        optimisationMetric: optimisationGoal,
        overallConstraint: overallConstraint,
        defaultCurrency: currency,
        inChannel: true,
        max_constraints: props.config.max_constraints,
      }
      if (attrs.observations[1] !== props.config.observations.length - 1) {
        attrs.observations[1] =
          attrs.observations[1] + props.config.options.carryoverWeeks
      }

      if (props.config.max_constraints) {
        let maxConstraints: MaxConstraint = {
          ...props.config.max_constraints,
          values: [],
        }
        for (let val of props.config.max_constraints.values) {
          const constVal =
            (val / props.config.observations.length) *
            (attrs.observations[1] + 1 - attrs.observations[0])
          maxConstraints.values = [...maxConstraints.values, constVal]
        }

        attrs.max_constraints = maxConstraints
      }
      if (props.user) {
        props.createSPOPlan({
          user: props.user,
          configId: props.match.params.id,
          attrs: attrs,
        }, planName)
      }
    }
  }

  const setBudget = (e: any) => {
    const parsed = e.target.value.replace(/,/g, '')
    const budget = parseFloat(parsed)
    setOverallConstraint(budget)
  }

  const checkboxPages = (page !== 0 && page <= numberOfLevels) || page === numberOfLevels + 2

  if (props.loading) {
    return <LoadingModal />
  }

  if (!hasPermission) {
    return (
      <div className="mx-auto my-auto ">
        <div>You do not have the correct permissions. Please contact your admin...</div>
      </div>
    )
  }

  return (
    <>
      {settings && props.config ? (
        <div className="flex flex-col h-screen place-content-center align-middle">
          <h1 className="text-gray-500 my-6 content-center text-center text-xl">
            {getOptimisationText(settings)}
          </h1>
          {page === numberOfLevels + 3 && (
            <div className={'flex justify-center'}>{'To maximise...'}</div>
          )}
          {page === numberOfLevels && (
            <Link
              className={'text-gtPink text-center'}
              to={window.location.pathname}
              onClick={handleSelectAll}
            >
              {selectAll}
            </Link>
          )}
          {checkboxPages && (
            <div className="flex flex-col w-full space-y-5 justify-center">
              <div
                className={`grid grid-flow-rows ${
                  getOptimisationOptions().length === 1 || page === numberOfLevels + 2
                    ? 'grid-cols-1'
                    : 'grid-cols-2'
                } gap-4 p-4 max-h-96 overflow-auto justify-center`}
              >
                {getOptimisationOptions().map((option: any, index: number) => {
                  let checked =
                    option.isChecked ||
                    getOptimisationOptions().length === 1 ||
                    optimisationGoal === option.key
                  return (
                    <div
                      key={index}
                      className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white items-center shadow m-auto w-3/4 h-14"
                    >
                      <label className="flex flex-row items-center space-x-2 h-full w-full">
                        <input
                          type={page === numberOfLevels ? 'checkbox' : 'radio'}
                          onChange={selectOption}
                          checked={checked}
                          className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                          value={option.key}
                          key={option.key}
                        />
                        <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                          {option.label}
                        </div>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {page === numberOfLevels + 1 && (
            <TimeSlider
              observations={observations}
              position={sliderPosition}
              handleMoveSlider={handleMoveSlider}
            />
          )}
          {page === numberOfLevels + 3 && (
            <div className={'flex justify-center'}>
              <select
                onChange={handleClickCurrency}
                value={selectedCurrencySymbol}
              >
                {props.config.options.exchangeRates.currencySymbols.map(
                  (sym) => {
                    return <option key={sym}>{sym}</option>
                  }
                )}
              </select>
              <NumberFormat
                onChange={setBudget}
                placeholder={'Amount'}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={
                  settings.number_format === 'euro' ? '.' : ','
                }
                decimalSeparator={
                  settings.number_format === 'euro' ? ',' : '.'
                }
              ></NumberFormat>
            </div>
          )}
          {page === 0 && 
            <div className={'flex justify-center'}>
              <input placeholder={planName === "" ? "Enter plan name" : undefined} 
              value= {planName === "" ? undefined : planName}
              className="p-2 w-72 rounded-md focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none" 
              onChange={(e) => setPlanName(e.target.value.replace(/[^a-zA-Z0-9\s]/gi, ''))}></input>
            </div>
          }
          <div className={'flex flex-row justify-center mt-8'}>
            {page > 0 && (
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                onClick={previousPage}
              >
                Previous
              </button>
            )}
            {page !== numberOfLevels + 3 ? (
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                onClick={nextPage}
              >
                Next
              </button>
            ) : (
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                onClick={submit}
              >
                Generate Marketing Plan
              </button>
            )}
          </div>
          {page === numberOfLevels + 3 && (
            <Link
              className={'text-gtPink text-center m-2'}
              to={ROUTES.SPO.IN_CHANNEL_INDEX}
            >
              Or, cancel
            </Link>
          )}
          {error !== '' && (
            <div className={'text-center text-destructive-dark'}>{error}</div>
          )}
        </div>
      ) : (
        <div />
      )}
    </>
  )
}

export default connector(MainLayout(CreateInChannelPlan))
