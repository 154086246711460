import { AgGridReact } from '@gain-theory/ag-grid'
import _ from 'lodash'
import { RefObject } from 'react'
import { RowDataType, TableColumnType, TableRowType } from '../../types'
import { CONSTRAINT_STATUS_OPTIONS, TABLE_COLUMNS } from '../../utils/constants'
import { useTimeConstraintsNotification } from '../use-time-constraints-notification'

interface IUseTableActionsProps {
  gridRef: RefObject<AgGridReact>
  rowData: TableRowType[]
  columns: TableColumnType[]
  localRowData: RowDataType[]
  updateLocalRowData: (newData: RowDataType[]) => void
}

export const useTableActions = ({
  gridRef,
  rowData,
  columns,
  localRowData,
  updateLocalRowData,
}: IUseTableActionsProps) => {
  const notification = useTimeConstraintsNotification()

  const handleAddNewRow = () => {
    const newRowValue: Record<string, string> = {
      [TABLE_COLUMNS.ID.field]: '1',
      [TABLE_COLUMNS.TYPE.field]: '',
      [TABLE_COLUMNS.STATUS.field]: CONSTRAINT_STATUS_OPTIONS.ENABLED,
    }

    const newRow = columns.reduce((accumulator, column) => {
      const columnHasField = 'field' in column

      if (!columnHasField || !column.field) {
        return { ...accumulator }
      }

      const columnField = column.field

      if (
        [
          TABLE_COLUMNS.ID.field,
          TABLE_COLUMNS.TYPE.field,
          TABLE_COLUMNS.STATUS.field,
        ].includes(columnField)
      ) {
        return { ...accumulator, [columnField]: newRowValue[columnField] }
      }

      return { ...accumulator, [columnField]: '' }
    }, {} as typeof localRowData[number])

    const updatedRowData = localRowData.map((row) => ({
      ...row,
      id: (parseInt(row.id) + 1).toString(),
    }))

    updateLocalRowData([newRow, ...updatedRowData])
  }

  const handleDeleteRows = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows()

    if (!selectedRows) return

    const selectedRowIds = selectedRows.map((row) => row.id)

    const newRowData = localRowData.filter(
      (row) => !selectedRowIds.includes(row.id)
    )

    updateLocalRowData(newRowData)
    notification.rowsDeletedSuccessfully()
  }

  const handleDisableRows = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows()

    if (!selectedRows) return

    const hasAnyRowEnabled = selectedRows.some(
      (row) => row.status === CONSTRAINT_STATUS_OPTIONS.ENABLED
    )

    if (!hasAnyRowEnabled) return

    const selectedRowIds = selectedRows.map((row) => row.id)

    const newLocalRowData = localRowData.map((row) => ({
      ...row,
      status: selectedRowIds.includes(row.id)
        ? CONSTRAINT_STATUS_OPTIONS.DISABLED
        : row.status,
    }))

    updateLocalRowData(newLocalRowData)
    gridRef.current?.api?.deselectAll()
    notification.rowsDisabledSuccessfully()
  }

  const handleEnableRows = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows()

    if (!selectedRows) return

    const hasAnyRowDisabled = selectedRows.some(
      (row) => row.status === CONSTRAINT_STATUS_OPTIONS.DISABLED
    )

    if (!hasAnyRowDisabled) return

    const selectedRowIds = selectedRows.map((row) => row.id)
    const newLocalRowData = localRowData.map((row) => ({
      ...row,
      status: selectedRowIds.includes(row.id)
        ? CONSTRAINT_STATUS_OPTIONS.ENABLED
        : row.status,
    }))

    updateLocalRowData(newLocalRowData)
    gridRef.current?.api?.deselectAll()
    notification.rowsEnabledSuccessfully()
  }

  const handleResetTable = () => {
    const newData = _.cloneDeep(rowData)
    updateLocalRowData(newData)
    gridRef.current?.api?.deselectAll()
  }

  return {
    handleAddNewRow,
    handleDeleteRows,
    handleDisableRows,
    handleEnableRows,
    handleResetTable,
  }
}
