import { Children } from "react"

export const LoadingRows = ({ rows = 3, height = 14, chartsAfterFirst = false }: { rows?: number; height?: number; chartsAfterFirst?: boolean }) => (
  <>
    {Children.toArray(Array(rows).fill('').map((_, i) => !i && chartsAfterFirst ? (
      <div className="grid gap-3">
        <div className={`w-full h-${height} rounded-md animate-pulse bg-gray-200`} />
        <div className="grid gap-3 grid-flow-col auto-cols-fr">
          {Children.toArray(Array(5).fill('').map(() => (
            <div className="grid gap-3 p-2 border rounded-lg shadow  bg-white">
              <div className="w-10/12 h-3 rounded-md animate-pulse bg-gray-200" />
              <div className="h-36 rounded-md animate-pulse bg-gray-200" />
              <div className="w-10/12 h-3 rounded-md animate-pulse bg-gray-200" />
            </div>
          )))}
        </div>
        <div className="grid grid-flow-col auto-cols-min gap-5 grid-cols-5">
          <div className="grid grid-flow-col gap-1 auto-cols-fr p-5 col-span-2 bg-white shadow border">
            {Children.toArray(Array(2).fill('').map(() => (
              <div className="grid gap-1">
                {Children.toArray(Array(2).fill('').map(() => (
                  <div className="h-10  animate-pulse bg-gray-200" />
                )))}
              </div>
            )))}
          </div>
          <div className="grid gap-4 auto-rows-min col-span-3 bg-white shadow border">
            <div className="grid grid-flow-col grid-cols-4 px-5 py-3 ">
              {Children.toArray(Array(2).fill('').map(() => (
                <div className="w-10/12 h-5 rounded-md animate-pulse bg-gray-200" />
              )))}
            </div>
            {Children.toArray(Array(2).fill('').map(() => (
              <div className="px-5">
                <div className="w-full h-5 rounded-md animate-pulse bg-gray-200" />
              </div>
            )))}
          </div>
        </div>
      </div>
    ) : (
      <div className={`w-full h-${height} rounded-md animate-pulse shadow bg-gray-200`} />
    )))}
  </>
)