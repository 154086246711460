import { Errors } from '../types'

export const CONSTRAINT_FN_OPTIONS = {
  MIN: 'Min',
  MAX: 'Max',
  EQUAL: 'Equal',
  INDEX: 'Index',
}

export const CONSTRAINT_STATUS_OPTIONS = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
}

export const TABLE_COLUMNS = {
  ID: {
    field: 'id',
    headerName: 'ID',
  },
  TYPE: {
    field: 'fn',
    headerName: 'Type',
    otherAccepetedValues: ['Function'],
    options: Object.values(CONSTRAINT_FN_OPTIONS),
  },
  STATUS: {
    field: 'status',
    headerName: 'Status',
    options: Object.values(CONSTRAINT_STATUS_OPTIONS),
  },
}

export const ErrorMessages: Record<Errors, string> = {
  [Errors.FILE_READER_ERROR]: 'Failed to read file. Please try again later.',
  [Errors.NO_DATA]: 'No data in file.',
  [Errors.XLSX_PARSE]: 'Failed to parse XLSX file. Please try again later.',
  [Errors.DATA_TYPE]: 'File data is not a string.',
  [Errors.HEADER_EMPTY_COLUMN]: 'Row 1: The columns cannot be empty.',
  [Errors.HEADER_MATCHING_HIERARCHY]:
    'Row 1: The hierarchy in the file does not match the current hierarchy {0}.',
  [Errors.HEADER_MATCHING_STATUS_COLUMN]:
    'Cell {0}1: Invalid entry - this value should be “{1}”.',
  [Errors.HEADER_MATCHING_TYPE_COLUMN]:
    'Cell {0}1: Invalid entry - this value should be “{1}”.',
  [Errors.HEADER_MATCHING_OBSERVATION_COLUMN]:
    'Column {0}: “{1}” is not a valid date.',
  [Errors.DIMENSION_NOT_FOUND]:
    'Cell {0}{1}: Invalid curve - this value does not exist.',
  [Errors.INVALID_CURVE]: 'Row {0}: Invalid curve.',
  [Errors.CURVE_NOT_FOUND]:
    'Row {0}: Invalid curve - this combination does not exist.',
  [Errors.DUPLICATE_CURVES]: 'Rows {0}: Duplicate curve.',
  [Errors.INVALID_CURVE_COMBINATION]:
    'Rows {0}: Invalid curve combination - “{1}” cannot be paired with “{2}” for the same curve.',
  [Errors.TYPE_NOT_FOUND]:
    'Row {0}: Invalid type - type values should be “{1}”.',
  [Errors.STATUS_NOT_FOUND]:
    'Row {0}: Invalid status - status values should be “{1}”.',
  [Errors.GENERIC_ERROR]:
    'There is a problem with the file. Please check if the columns match your current plan.',
  [Errors.EMPTY_ROW]: 'Row {0}: Empty row.',
  [Errors.ZERO_INDEX_TOTALS]: 'Row {0}: The sum of index constraint values cannot equal zero.',
}

export const VALIDATION_ALERT_TILE = {
  CREATE: 'Error when generating plan',
  REGENERATE: 'Error when regenerating plan',
}
