import { useMemo } from 'react'
import { GroupingLevel, Observation } from '../../../../../../types/PlanTypes'
import { TableColumnType } from '../../types'
import { TABLE_COLUMNS } from '../../utils/constants'
import moment from 'moment'

interface IUseTableColumnProps {
  groupingLevels: GroupingLevel[]
  observations: Observation[]
  dateFormat?: string
}

export const useTableColumns = ({
  groupingLevels,
  observations,
  dateFormat,
}: IUseTableColumnProps) => {
  const idColumn: TableColumnType = {
    field: TABLE_COLUMNS.ID.field,
    headerName: TABLE_COLUMNS.ID.headerName,
  }

  const hierarchyColumns: TableColumnType[] = useMemo(
    () =>
      groupingLevels.map(({ key, label }) => ({
        field: key,
        headerName: label,
      })),
    [groupingLevels]
  )

  const typeColumn: TableColumnType = {
    field: TABLE_COLUMNS.TYPE.field,
    headerName: TABLE_COLUMNS.TYPE.headerName,
  }

  const statusColumn: TableColumnType = {
    field: TABLE_COLUMNS.STATUS.field,
    headerName: TABLE_COLUMNS.STATUS.headerName,
  }

  const observationColumns: TableColumnType[] = useMemo(
    () =>
      observations.map((observation) => ({
        field: observation.key,
        headerName: dateFormat
          ? moment.utc(observation.label).format(dateFormat)
          : observation.label,
      })),
    [observations]
  )

  const columns: TableColumnType[] = useMemo(() => {
    return [
      idColumn,
      ...hierarchyColumns,
      typeColumn,
      statusColumn,
      ...observationColumns,
    ]
  }, [hierarchyColumns, observationColumns])

  const columnIndexing = useMemo(() => {
    return {
      hierarchy: columns.indexOf(hierarchyColumns[0]),
      observations: columns.indexOf(observationColumns[0]),
    }
  }, [columns, hierarchyColumns, observationColumns])

  return {
    idColumn,
    hierarchyColumns,
    typeColumn,
    statusColumn,
    observationColumns,
    columns,
    columnIndexing,
  }
}
