import { Dispatch } from 'redux'

import api from '../../api'
import { CreateComparisonAttrs } from '../../types/PlanComparisonTypes'
import { Options } from '../../types/PlanTypes'
import {
  CHANGE_CURRENCY_COMPARISONS_ATTEMPT,
  CHANGE_CURRENCY_COMPARISONS_FAIL,
  CHANGE_CURRENCY_COMPARISONS_SUCCESS,
  PlanComparisonActionTypes,
  PLAN_COMPARISON_DELETE_ATTEMPT,
  PLAN_COMPARISON_DELETE_FAIL,
  PLAN_COMPARISON_DELETE_SUCCESS,
  PLAN_GET_COMPARISONS_ATTEMPT,
  PLAN_GET_COMPARISONS_BY_ID_ATTEMPT,
  PLAN_GET_COMPARISONS_BY_ID_FAIL,
  PLAN_GET_COMPARISONS_BY_ID_SUCCESS,
  PLAN_GET_COMPARISONS_FAIL,
  PLAN_GET_COMPARISONS_SUCCESS,
  PLAN_NAME_COMPARISONS_ATTEMPT,
  PLAN_NAME_COMPARISONS_FAIL,
  PLAN_NAME_COMPARISONS_SUCCESS,
  PLAN_SAVE_COMPARISONS_ATTEMPT,
  PLAN_SAVE_COMPARISONS_FAIL,
  PLAN_SAVE_COMPARISONS_SUCCESS,
  UPDATE_PLAN_COMPARISON,
} from './PlanComparisonActionTypes'

export const GetAllPlanComparisons = () => async (
  dispatch: Dispatch<PlanComparisonActionTypes>
) => {
  dispatch({ type: PLAN_GET_COMPARISONS_ATTEMPT })

  try {
    const response = await api.Plans.getAllPlanComparisons()
    dispatch({ type: PLAN_GET_COMPARISONS_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PLAN_GET_COMPARISONS_FAIL })
  }
}


export const SelectCurrencyComparison = (id: string, options: Options, finalConversion: Boolean) => async (
  dispatch: Dispatch<PlanComparisonActionTypes>
) => {
  try {
    dispatch({ type: CHANGE_CURRENCY_COMPARISONS_ATTEMPT})

    const response = await api.Plans.putCurrency(id, options)
    if(finalConversion){
      dispatch({ type: CHANGE_CURRENCY_COMPARISONS_SUCCESS})
      dispatch({ type: UPDATE_PLAN_COMPARISON})
    }
  } catch (error) {
    dispatch({ type: CHANGE_CURRENCY_COMPARISONS_FAIL})
  }
}

export const SavePlanComparison = (attrs: CreateComparisonAttrs) => async (
  dispatch: Dispatch<PlanComparisonActionTypes>
) => {
  dispatch({ type: PLAN_SAVE_COMPARISONS_ATTEMPT })

  try {
    const response = await api.Plans.savePlanComparison(attrs)
    dispatch({ type: PLAN_SAVE_COMPARISONS_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PLAN_SAVE_COMPARISONS_FAIL })
  }
}

export const GetComparisonById = (id: string) => async (
  dispatch: Dispatch<PlanComparisonActionTypes>
) => {
  dispatch({ type: PLAN_GET_COMPARISONS_BY_ID_ATTEMPT })

  try {
    const response = await api.Plans.getPlanComparisonById(id)
    dispatch({
      type: PLAN_GET_COMPARISONS_BY_ID_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({ type: PLAN_GET_COMPARISONS_BY_ID_FAIL })
  }
}
