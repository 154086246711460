import axios from 'axios'

export const getReleaseVersion = async () => {
  try {
    const { data } = await axios.get('/version.json')

    const { major = '0', minor = '0', patch = '0' } = data

    return `${major}.${minor}.${patch}`
  } catch (error) {
    // Future:Log_Exception
    return '0.0.0'
  }
}
