interface IGetCookieFormatProps {
  name: string
  value: string
  maxAgeDays?: number
  maxAgeMonths?: number
  maxAgeYears?: number
}

export function getCookieFormat({
  name,
  value,
  maxAgeDays,
  maxAgeMonths,
  maxAgeYears,
}: IGetCookieFormatProps) {
  const day = 24 * 60 * 60

  const days = day * Number(maxAgeDays || 0)
  const month = 30 * day * Number(maxAgeMonths || 0)
  const year = 365 * day * Number(maxAgeYears || 0)

  const maxAge = year + month + days

  return [`${name}=${value}`, `Path=/`, `max-age=${maxAge}`].join('; ')
}
