import React, { FC, useState } from 'react'
import { OnetrustSdk } from '@gain-theory/onetrust-sdk'
import pinkBackground from '../img/pink-shape@2x.df65f074.png'
import fiberOptics from '../img/fibre-optics-bg.acafd360.png'
import LogoWhiteIcon from '../icons/LogoWhiteIcon'
import EnvelopeIcon from '../icons/EnvelopeIcon'
import KeyIcon from '../icons/KeyIcon'
import PhoneIcon from '../icons/PhoneIcon'
import BookIcon from '../icons/BookIcon'
import { RootStore } from '../redux/reducers/Store'
import { selectSessionErrorMessage } from '../redux/reducers/SessionReducer'
import { CreateSession } from '../redux/actions/SessionActions'
import { connect, ConnectedProps } from 'react-redux'
import cookieImgSrc from '../img/cookie.png'

const mapState = (state: RootStore) => ({
  errors: selectSessionErrorMessage,
})

const mapDispatch = {
  onLogin: CreateSession,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface OwnProps {}

type Props = OwnProps

const AdminLogin: FC<PropsFromRedux> = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (email.length > 3 && password.length > 6) {
      props.onLogin(email, password)
      // try {
      //   const res = await api.Session.createSession(email, password)
      // } catch (e) {
      // }
    }
  }

  return (
    <div className="w-full h-screen relative flex flex-row pt-20 px-36 ">
      <div
        style={{
          backgroundImage: `url(${fiberOptics})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
        }}
        className="w-8/12 h-full absolute top-0 right-0 z-0"
      />
      <div
        style={{
          backgroundImage: `url(${pinkBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
        }}
        className="w-6/12 h-full absolute top-0 left-0 z-0"
      />

      <div className="w-1/2 z-40 px-2">
        <LogoWhiteIcon className="w-16 h-16" />
        {/* name property needed for oneTrust platform to scan the application  */}
        <form
          name="admin-login"
          className="pt-10 ml-2 flex flex-col space-y-5 "
          onSubmit={handleSubmit}
        >
          <div className="flex flex-wrap items-stretch w-96 relative h-15 bg-white items-center rounded ">
            <div className="flex -mr-px justify-center pr-2 w-9 ">
              <span className="flex items-center leading-normal bg-white px-3 rounded rounded-r-none text-lg text-gray-600">
                <EnvelopeIcon className="text-gtPink w-6 h-6" />
              </span>
            </div>
            {/* name property needed for oneTrust platform to scan the application  */}
            <input
              name="user-name"
              type="text"
              className="focus:bg-white outline-none w-full py-6 focus:ring-0 focus:border-gray-300  border-l-2 border-gray-300 leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative  outline-none"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap items-stretch w-96 relative h-15 bg-white items-center rounded ">
            <div className="flex -mr-px justify-center pr-2 w-9">
              <span className="flex items-center leading-normal bg-white px-3 rounded rounded-r-none text-lg text-gray-600">
                <KeyIcon className="text-gtPink w-6 h-6 rotate-12" />
              </span>
            </div>
            {/* name property needed for oneTrust platform to scan the application  */}
            <input
              name="password"
              type="password"
              className="flex-shrink focus:ring-0 focus:border-gray-300 bg-white focus:bg-white flex-grow flex-auto py-6  border-l-2 border-gray-300 leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative  outline-none"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            {/* name property needed for oneTrust platform to scan the application  */}
            <button
              name="sign-in"
              className="border-2 border-white rounded-md mt-10 px-7 py-4 text-white"
            >
              SIGN IN
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/2 z-40 flex flex-row  content-end justify-end">
        <div className="w-3/4 content-end space-y-3">
          <h1 className="text-white text-xl mb-10">
            Gain Theory Interactive is an online platform that empowers informed
            marketing decisions, anytime, anywhere.
          </h1>
          <div className="bg-white rounded-full w-full p-4 px-6 flex flex-row space-x-5">
            <PhoneIcon className="w-6 h-6 text-pink my-auto" />
            <div className="flex flex-col">
              <span className="text-xs">Contact our Support Team</span>
              <span
                onClick={() =>
                  (window.location.href = 'mailto:gti_support@gaintheory.com')
                }
                className="text-gtPink text-xxs"
              >
                Get in touch
              </span>
            </div>
          </div>

          <div className="bg-white rounded-full w-full p-4 px-6 flex flex-row space-x-5">
            <BookIcon className="w-6 h-6 text-pink my-auto" />
            <div className="flex flex-col">
              <span className="text-xs">About Gain Theory</span>
              <a
                className="text-gtPink text-xxs cursor-pointer"
                href="https://www.gaintheory.com/"
                target="_blank"
              >
                Find out more
              </a>
            </div>
          </div>
          <div className="bg-white rounded-full w-full p-4 px-6 flex flex-row space-x-5">
            <img
              src={cookieImgSrc}
              alt="cookie preferences"
              style={{ height: '30px', marginTop: '6px', marginLeft: '-6px' }}
            />
            <div className="flex flex-col">
              <span className="text-xs">Cookie Preferences</span>
              <button
                className="text-gtPink text-xxs cursor-pointer text-left"
                onClick={OnetrustSdk.openPreferenceCenter}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(AdminLogin)
