import { BestWorst } from '../../../../../../types/MarketingDiagnosticsTypes'
import { ReactComponent as bestMedal } from './assets/best-medal.svg'
import { ReactComponent as worstMedal } from './assets/worst-medal.svg'

const Icons = {
  bestMedal,
  worstMedal,
}

interface OwnProps {
  labels: string[]
  data?: BestWorst
  icon?: keyof typeof Icons
  isHeading?: boolean
  title?: string
}
const getTextColor = (t: keyof typeof Icons) =>
  t === 'bestMedal' ? 'text-success-dark' : 'text-destructive-dark'
const getIcon = (props: Partial<OwnProps>) => {
  const t = props.icon as keyof typeof Icons
  const Icon = Icons[t]
  return <Icon className={getTextColor(t)} title={props.title} />
}
type Props = OwnProps

export const Row = ({ labels, data, icon, isHeading, title }: Props) => (
  <div
    className={`grid grid-flow-col gap-3 auto-cols-fr grid-cols-${
      labels.length
    } ${isHeading ? 'pb-3 border-b border-gray-200' : ''} px-5`}
  >
    {labels.map((label, i) =>
      isHeading ? (
        <h4 key={label} className="text-left">
          {label}
        </h4>
      ) : (
        <div
          className={`grid grid-flow-col ${
            !i ? 'auto-cols-min' : 'auto-cols-fr'
          } gap-2 place-content-start ${icon ? '' : ''}`}
        >
          {!i && icon && getIcon({icon, title})}
          <span
            className={`truncate ${
              icon ? getTextColor(icon) : 'text-gray-700'
            }  grid ${
              !i ? 'place-content-center text-xs' : 'items-center text-xxs'
            }`}
            title={data ? data[label] : ''}
          >
            {data ? data[label] : ''}
          </span>
        </div>
      )
    )}
  </div>
)
