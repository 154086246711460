import { FC, useEffect, useState, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import * as _ from 'lodash'

// Redux
import { connect, ConnectedProps } from 'react-redux'

// Components

// Constants

import { RootStore } from '../../../redux/reducers/Store'
import {
  selectPlanComparisons,
  selectUpdateComparison,
} from '../../../redux/reducers/PlanComparisonsReducer'
import { MainLayout } from '../../../shared/MainLayout'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { GetAllPlanComparisons } from '../../../redux/actions/PlanComparisonActions'
import { PlanComparisons } from '../../../types/PlanComparisonTypes'
import { ROUTES } from '../../../Routes'
import PlanComparisonsActionsMenu from './PlanComparisonsActionsMenu'
import { selectLoadingComparisons } from '../../../redux/reducers/PlanComparisonsReducer'

const mapState = (state: RootStore) => ({
  planComparisons: selectPlanComparisons(state),
  updateComparisons: selectUpdateComparison(state),
  loadingComparisons: selectLoadingComparisons(state),
})

const mapDispatchToProps = {
  getAllComparisons: GetAllPlanComparisons,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanComparisonsSummary: FC<PropsFromRedux> = (props) => {
  const history = useHistory()
  useEffect(() => {
    props.getAllComparisons()
  }, [])

  useEffect(() => {
    props.getAllComparisons()
  }, [props.updateComparisons])

  const viewScenario = (id: string) => {
    const url = UrlAssembler()
      .template(ROUTES.SPO.COMPARISONS_OUTPUT)
      .param('id', id)
      .toString()
    history.push(url)
  }

  const comparePlans = () => {
    return UrlAssembler()
      .template(ROUTES.SPO.COMPARISON_CREATE_FLOW)
      .param('baseId', '-1')
      .toString()
  }

  if (props.loadingComparisons || !props.planComparisons) {
    return <LoadingModal />
  }

  const showEmptyPlanComparisonPage =
    props.planComparisons.filter((planComp) => planComp.enabled).length === 0

  if (showEmptyPlanComparisonPage) {
    return (
      <div>
        <div className="text-lg text-gray-500 mb-6 flex flex-row justify-between">
          <div className="flex">
            <span className="m-3">Plan Comparisons</span>
          </div>
          <Link to={comparePlans}>
        <button
              className="rounded bg-gtPink text-white text-xs px-8 py-2 input-focus m-2 "
          onClick={comparePlans}
        >
              Create Comparison
        </button>
          </Link>
        </div>

        <div className="w-full h-96 bg-white rounded border p-10 flex">
          <span className="mx-auto my-auto font-black text-lg">
            You have yet to create any plan comparisons
          </span>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="text-lg text-gray-500 mb-6 flex flex-row justify-between items-center">
        <div className="flex"><span className="m-3">Plan Comparisons</span></div>
          <Link to={comparePlans}>
          <button  
            onClick={comparePlans}
            className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 btn"
          >
            CREATE COMPARISON
          </button>
          </Link>
      </div>
      <table className="min-w-full table-auto mb-96 divide-y divide-gray-300 border rounded text-text-medium-emphasis">
        <thead>
          <tr  className="p-2">
            <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Comparison Name</th>
            <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">KPIs</th>
            <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Created By</th>
            <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Actions</th>
            <th />
          </tr>
        </thead>
        <tbody  className="bg-white divide-y divide-gray-200">
          {props.planComparisons &&
            props.planComparisons
              .filter((x) => x.enabled)
              .reverse()
              .map((plan: PlanComparisons) => {
                const kpiString = plan.kpis.length < 5 ? plan.kpis.toString() : plan.kpis.slice(0,4).toString() + "..."
                return (
                  <tr key={plan.id} className="p-2">
                    <td className="px-6 py-4 text-gtPink cursor-pointer" onClick={() => viewScenario(plan.id)}>{plan.name}</td>
                    <td  className="px-6 py-4" onClick={() => viewScenario(plan.id)}>
                      {kpiString}
                    </td>
                    <td  className="px-6 py-4" onClick={() => viewScenario(plan.id)}>
                      {plan.created_by ? `${plan.created_by}` : `N/A`}
                    </td>
                    <td  className="px-6 py-4">
                      <PlanComparisonsActionsMenu id={plan.id} name={plan.name} fromOutputPage={false} detailedData={[]}/>
                    </td>
                  </tr>
                )
              })}
        </tbody>
      </table>
    </Fragment>
  )
}

export default connector(MainLayout(PlanComparisonsSummary))
