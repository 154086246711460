import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useElementSize, useWindowSize } from '@gain-theory/hooks'

const BG_MINIMUM_WIDTH = 1280
const BG_MINIMUM_HEIGHT = 782

const defaultStyle: CSSProperties = {
  width: '100%',
  left: 'auto',
  right: 'auto',
  top: 'auto',
  aspectRatio: `${BG_MINIMUM_WIDTH} / ${BG_MINIMUM_HEIGHT}`,
}

export function useWelcomeBg() {
  const bgAspectRatio = BG_MINIMUM_WIDTH / BG_MINIMUM_HEIGHT

  const rootRef = useRef<HTMLDivElement | null>(null)

  const { width } = useWindowSize()

  const { height: rootHeight, width: rootWidth } = useElementSize(rootRef)
  const [bgStyle, setBgStyle] = useState<CSSProperties>({ ...defaultStyle })

  const getStyleForSmallerAspectRatio = (
    rootWidth: number,
    rootHeight: number
  ) => {
    let newWidth: number | undefined = undefined
    let newHeight: number | undefined = undefined

    if (rootWidth < BG_MINIMUM_WIDTH && rootHeight < BG_MINIMUM_HEIGHT) {
      newWidth = BG_MINIMUM_WIDTH
    }

    if (rootWidth < BG_MINIMUM_WIDTH && rootHeight >= BG_MINIMUM_HEIGHT) {
      newHeight = rootHeight
    }

    return {
      ...(!!newWidth && { width: newWidth }),
      ...(!!newHeight && { height: newHeight }),
      aspectRatio: `${BG_MINIMUM_WIDTH} / ${BG_MINIMUM_HEIGHT}`,
    }
  }

  useEffect(() => {
    const rootAspectRatio = rootWidth / rootHeight

    let style: CSSProperties = {}

    if (rootAspectRatio >= bgAspectRatio) {
      style = { ...defaultStyle }
    } else {
      style = getStyleForSmallerAspectRatio(rootWidth, rootHeight)
    }

    setBgStyle(style)
  }, [rootRef, width, rootHeight, rootWidth])

  return { rootRef, bgStyle }
}
