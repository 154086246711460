import React, { FC, useEffect } from 'react'
import { MainLayout } from './../shared/MainLayout'
import { RootStore } from './../redux/reducers/Store'
import { connect, ConnectedProps } from 'react-redux'

const mapState = (state: RootStore) => ({})

const mapDispatchToProps = {}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const NoPermissions: FC<PropsFromRedux> = (props) => {
  return (
    <>
      <div className="text-gray-500 text-lg">
        You do not have permission to access this module. Please contact support
        to have your permissions updated.
      </div>
    </>
  )
}

export default connector(MainLayout(NoPermissions))
