import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const RadioIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={stroke || '#666'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.0"
        >
          <path d="M2.313 28.563a5.25 5.25 0 0 1 5.25-5.25h49.874a5.25 5.25 0 0 1 5.25 5.25v26.25a5.25 5.25 0 0 1-5.25 5.25H7.563a5.25 5.25 0 0 1-5.25-5.25v-26.25z" />
          <path d="M57.438 40.375a3.939 3.939 0 0 1-3.938 3.938h-42a3.939 3.939 0 0 1-3.938-3.938V32.5a3.939 3.939 0 0 1 3.938-3.938h42a3.939 3.939 0 0 1 3.938 3.938v7.875zM15.438 52.188a2.623 2.623 0 0 1-2.626 2.624 2.624 2.624 0 1 1 2.625-2.625zM54.813 52.188a2.623 2.623 0 0 1-2.626 2.624 2.624 2.624 0 1 1 2.625-2.625zM44.313 52.188a2.623 2.623 0 0 1-2.626 2.624H23.314a2.624 2.624 0 1 1 0-5.25h18.375a2.624 2.624 0 0 1 2.624 2.626zM7.563 33.813h49.874M7.563 39.063h49.874M36.438 49.563v5.25M7.563 23.313l42-21M15.438 60.063v2.624M49.563 60.063v2.624" />
        </g>
      </g>
    </svg>
  )
}

export default RadioIcon
