import { GroupingLevel, Plan } from '../types/PlanTypes'

export const getGroupingLevels = (plan: Plan) => {
  let groupingLevels: GroupingLevel[] = plan.config.grouping_levels
  const levelKeys = groupingLevels.map(x => x.key)
  for(let key of levelKeys) {
    if(plan.grouping_levels[key].length !== 0) {
      const levelIndex = groupingLevels.findIndex((lev) => lev.key === key)
      if (levelIndex !== -1) {
        const filteredLevels = groupingLevels[levelIndex].values.filter((val) =>
          plan.grouping_levels[key].includes(val.key)
        )
        groupingLevels[levelIndex].values = filteredLevels
      }
    }
  }
  return groupingLevels
}
