import React, { FC, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { UpdatePassword } from '../../redux/actions/SessionActions'
import {
  selectSessionUser,
  selectUpdatePasswordError,
} from '../../redux/reducers/SessionReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  passwordError: selectUpdatePasswordError(state),
})

const mapDispatchToProps = {
  onUpdatePassword: UpdatePassword,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ChangePassword: FC<PropsFromRedux> = (props) => {
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [error, setError] = useState('')

  const updatePassword = () => {
    if (newPassword.length <= 5) {
      setError('New password must be more than 5 characters')
      return
    }
    if (newPassword !== newPasswordConfirm) {
      setError('New passwords do not match')
      return
    }
    if (props.user) {
      props.onUpdatePassword({
        id: props.user.id,
        current_password: userPassword,
        password: newPassword,
      })
    }
  }
  return (
    <>
      <div className="text-gray-500 text-lg mt-4">Change Password</div>
      <div className={'mt-4'}>Current Password</div>
      <input
        type="password"
        onChange={(e) => setUserPassword(e.target.value)}
      />
      <div className={'mt-4'}>New Password</div>
      <input type="password" onChange={(e) => setNewPassword(e.target.value)} />
      <div className={'mt-4'}>Confirm New Password</div>
      <input
        type="password"
        onChange={(e) => setNewPasswordConfirm(e.target.value)}
      />
      <div>
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-8"
          onClick={updatePassword}
        >
          SAVE
        </button>
      </div>
      {error !== '' && (
        <div className={'mt-4 text-gtBlueSecondary'}>{error}</div>
      )}
      {props.passwordError !== '' && (
        <div className={'mt-4 text-gtBlueSecondary'}>{props.passwordError}</div>
      )}
    </>
  )
}

export default connector(MainLayout(ChangePassword))
