import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const CouponsIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M63 40.13L40.127 63 1.022 23.9 1 1h22.891z" />
          <path d="M18.522 13.13a5.393 5.393 0 0 1-5.392 5.392 5.393 5.393 0 0 1-5.39-5.392 5.393 5.393 0 0 1 5.39-5.39 5.393 5.393 0 0 1 5.392 5.39z" />
        </g>
      </g>
    </svg>
  )
}

export default CouponsIcon
