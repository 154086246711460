import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import TimeSlider from '../create-plan/TimeSlider'
import _ from 'lodash'

interface OwnProps {
  changeTime: (value: number[]) => void
  timeRange: number[]
}

type Props = OwnProps

const EditTime: FC<Props> = (props) => {
  const { planItem } = useSelector((state: AppState) => state.plans)
  const [sliderPosition, setSliderPosition] = useState([0, 1])

  const observations = _.cloneDeep(planItem.config.observations)

  const carryoverObs = observations.slice(
    0,
    observations.length - planItem.options.carryoverWeeks
  )

  useEffect(() => {
    setSliderPosition(props.timeRange)
  }, [])

  useEffect(() => {
    props.changeTime(sliderPosition)
  }, [sliderPosition])

  const handleMoveSlider = (position: number[]) => {
    setSliderPosition(position)
  }

  return (
    <div className="flex flex-col w-full">
      <div className="mb-5">Time</div>
      <div className="w-full">
        <TimeSlider
          observations={carryoverObs}
          position={sliderPosition}
          handleMoveSlider={handleMoveSlider}
        />
      </div>
    </div>
  )
}

export default EditTime
