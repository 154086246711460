import { useEffect, useState } from 'react'
import {
  BestWorst as TBestWorst,
  Channel,
  KPI,
} from '../../../../../../types/MarketingDiagnosticsTypes'
import { Select } from '../../select'
import { ReactComponent as bestMedal } from './assets/best-medal.svg'
import { ReactComponent as worstMedal } from './assets/worst-medal.svg'
import { Row } from './row'

interface OwnProps {
  defaultHeaders: string[]
  channel: Channel
  kpis: KPI[]
}

type Props = OwnProps

export const BestWorst = (props: Props) => {
  const [data, setData] = useState<TBestWorst[]>([])
  const [selectOption, setSelectOption] = useState('')
  const [bwLabels, setBwLabels] = useState<string[]>([])
  const [best, setBest] = useState<TBestWorst>()
  const [worst, setWorst] = useState<TBestWorst>()

  const showBestWorst = props.channel.bestWorst && props.channel.bestWorstLabels
  useEffect(() => {
    if (showBestWorst) {
      const sortBy = props.defaultHeaders[0].toLowerCase()
      const { best, worst } = calculateBestWorst(sortBy, [
        ...(props.channel.bestWorst || []),
      ])
      setBest(best)
      setWorst(worst)
      setData([...(props.channel.bestWorst || [])])
      setBwLabels(props.channel.bestWorstLabels || [])
      setSelectOption(props.defaultHeaders ? props.defaultHeaders[0] : '')
    }
  }, [showBestWorst])

  const selectBestWorstFilter = (e: any) => {
    e.preventDefault()
    const sortBy = e.target.value.toLowerCase()
    const { best, worst } = calculateBestWorst(sortBy, [...data])
    setSelectOption(e.target.value)
    setBest(best)
    setWorst(worst)
  }

  const calculateBestWorst = (sortBy: string, data: TBestWorst[]) => {
    let sortVal = sortBy
    const kpiFilter = props.kpis.find(
      (kpi) => kpi.label.toLowerCase() === sortBy
    )
    if (kpiFilter) {
      sortVal = kpiFilter.label.toLowerCase()
    }
    data.sort((a, b) =>
      a[sortVal] > b[sortVal] ? -1 : b[sortVal] > a[sortVal] ? 1 : 0
    )

    return { best: data[0], worst: data[data.length - 1] }
  }

  return (
    <div className="grid gap-4 auto-rows-min col-span-3 bg-white shadow border">
      <div className="grid grid-flow-col grid-cols-4 px-5 py-3 ">
        <h2 className="text-md text-gray-600 col-span-1">Rankings</h2>
        <Select
          classNames="col-span-2"
          onChange={selectBestWorstFilter}
          value={selectOption}
          options={props.defaultHeaders.map((value) => ({
            value,
            label: value,
          }))}
        />
        <div />
      </div>
      <Row labels={bwLabels} isHeading />
      <Row labels={bwLabels} data={best} icon="bestMedal" title="Best Medal" />
      <Row labels={bwLabels} data={worst} icon="worstMedal" title="Worst Medal" />
    </div>
  )
}
