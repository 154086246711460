import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const ChevronRightDefaultIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          className='stroke-brand-primary-main'
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.25"
          d="M19.5 32.5l9-9-9-9"
        />
      </g>
    </svg>
  )
}

export default ChevronRightDefaultIcon
