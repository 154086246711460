import { AllBrandsActionTypes, SELECT_ALL_BRANDS } from '../actions/AllBrandsActionTypes'
import {
  CreatePlanActionTypes,
  PLAN_CREATE_ATTEMPT,
  PLAN_CREATE_SUCCESS,
  PLAN_CREATE_FAIL,
} from '../actions/SPOFlowActions/CreatePlanActionTypes'
import { RootStore } from './Store'

interface DefaultState {
  error: string
  loading: boolean
}

const initState: DefaultState = {
  error: '',
  loading: false,
}

const createPlanReducer = (
  state: DefaultState = initState,
  action: CreatePlanActionTypes | AllBrandsActionTypes
) => {
  switch (action.type) {
    case PLAN_CREATE_ATTEMPT:
      return { ...state, loading: true }
    case PLAN_CREATE_SUCCESS:
      return { ...state, scenario: action.scenario }
    case SELECT_ALL_BRANDS:
      return {...state, loading: false }
    case PLAN_CREATE_FAIL:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export const selectOptimiserError = (state: RootStore) => {
  return state.createPlan.error
}

export default createPlanReducer
