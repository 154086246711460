import { ModalProps } from '@gain-theory/modal'
import { Typography } from '@gain-theory/typography'
import { useCallback, useState } from 'react'

const initialProps: ModalProps = {
  header: { text: '' },
  open: false,
}

interface IUseModalProps {
  closeValidationAlert: () => void
  handleResetTable: () => void
  handleBack: () => void
  handleDeleteRows: () => void
}

export const useModalProps = ({
  closeValidationAlert,
  handleResetTable,
  handleBack,
  handleDeleteRows,
}: IUseModalProps) => {
  const [modalProps, setModalProps] = useState<ModalProps>({ ...initialProps })

  const handleSetModalProps = (
    headerText: string,
    confirmButtonLabel: string,
    cancelButtonLabel: string,
    modalContentText: string,
    confirmButtonCallback: () => void
  ) => {
    const handleConfirm = () => {
      confirmButtonCallback()
      setModalProps({ ...initialProps })
    }

    const handleGoBack = () => {
      setModalProps({ ...initialProps })
    }

    const modalHeader: ModalProps['header'] = {
      text: headerText,
      icon: 'warning',
    }

    const modalFooter: ModalProps['footer'] = {
      primary: {
        variant: 'destructive',
        label: confirmButtonLabel,
        leadingIconLibrary: 'heroicon-outline',
        leadingIconName: 'TrashIcon',
        onClick: handleConfirm,
      },
      secondary: {
        label: cancelButtonLabel,
        leadingIconLibrary: 'heroicon-outline',
        leadingIconName: 'XMarkIcon',
        onClick: handleGoBack,
      },
    }

    const ModalContent = <Typography.Body1>{modalContentText}</Typography.Body1>

    const newProps: ModalProps = {
      header: modalHeader,
      footer: modalFooter,
      open: true,
      children: ModalContent,
    }

    setModalProps({ ...newProps })
  }

  const openModalForTableReset = useCallback(() => {
    const headerText = 'Discard changes?'
    const confirmButtonLabel = 'Discard changes'
    const cancelButtonLabel = 'Cancel'
    const modalContentText =
      "The changes will not be applied. This action can't be undone."

    const confirmButtonCallback = () => {
      handleResetTable()
      closeValidationAlert()
    }

    handleSetModalProps(
      headerText,
      confirmButtonLabel,
      cancelButtonLabel,
      modalContentText,
      confirmButtonCallback
    )
  }, [handleResetTable, closeValidationAlert])

  const openModalForGoBack = useCallback(() => {
    const headerText = 'Discard changes?'
    const confirmButtonLabel = 'Discard changes'
    const cancelButtonLabel = 'Cancel'
    const modalContentText =
      "The changes will not be applied. This action can't be undone."

    const confirmButtonCallback = () => {
      handleBack()
    }

    handleSetModalProps(
      headerText,
      confirmButtonLabel,
      cancelButtonLabel,
      modalContentText,
      confirmButtonCallback
    )
  }, [handleBack])

  const openModalForRowDelete = useCallback(() => {
    const headerText = 'Delete rows?'
    const confirmButtonLabel = 'Delete'
    const cancelButtonLabel = 'Cancel'
    const modalContentText =
      "Are you sure you want to delete the selected rows? This action can't be undone."

    const confirmButtonCallback = () => {
      handleDeleteRows()
    }

    handleSetModalProps(
      headerText,
      confirmButtonLabel,
      cancelButtonLabel,
      modalContentText,
      confirmButtonCallback
    )
  }, [handleDeleteRows])

  return {
    modalProps,
    openModalForTableReset,
    openModalForGoBack,
    openModalForRowDelete,
  }
}
