export const getKPIOptimisedTotals = (
    trans,
    kpiSelected,
    roiGoal,
    kpiDisplay
) => {
    const kpiSel = kpiSelected.toLowerCase();
    let calcSum;
    let numeratorSum;
    let denominatorSum;
    let index = kpiDisplay.kpi.indexOf(kpiSel);
    if (kpiDisplay && kpiDisplay.kpi.indexOf(kpiSel) !== -1 && kpiDisplay.function[index] !== "base") {
        if (kpiDisplay.function[index] === "divide") {
            calcSum = divide([trans], kpiDisplay.parameters[index], "optimal");
        }
    } else {
        if (kpiSel === "spend") {
            calcSum = trans.optimised_spend;
        } else if (kpiSel === "roi") {
            numeratorSum = trans.optimised_totals[roiGoal];
            denominatorSum = trans.optimised_spend;
            calcSum = denominatorSum === 0 ? 0 : numeratorSum / denominatorSum;
        } else {
            calcSum = trans.optimised_totals[kpiSel];
        }
    }

    if (isNaN(calcSum)) {
        calcSum = 0;
    }

    return calcSum;
};

export const getKPICalculatedTotals = (
    trans,
    kpiSelected,
    roiGoal,
    kpiDisplay
) => {
    const kpiSel = kpiSelected.toLowerCase();
    let calcSum;
    let numeratorSum;
    let denominatorSum;
    let index = kpiDisplay.kpi.indexOf(kpiSel);

    if (kpiDisplay && kpiDisplay.kpi.indexOf(kpiSel) !== -1 && kpiDisplay.function[index] !== "base") {
        if (kpiDisplay.function[index] === "divide") {
            calcSum = divide(
                [trans],
                kpiDisplay.parameters[index],
                "calculated"
            );
        }
    } else {
        if (kpiSel === "spend") {
            calcSum = trans.calculated_spend;
        } else if (kpiSel === "roi") {
            numeratorSum = trans.calculated_totals[roiGoal];
            denominatorSum = trans.calculated_spend;
            calcSum = denominatorSum === 0 ? 0 : numeratorSum / denominatorSum;
        } else {
            calcSum = trans.calculated_totals[kpiSel];
        }
    }
    if (isNaN(calcSum)) {
        calcSum = 0;
    }
    return calcSum;
};

export const getSummedKPIOptimisedTotals = (
    transforms,
    kpiSelected,
    roiGoal,
    kpiDisplay
) => {
    const kpiSel = kpiSelected.toLowerCase();
    let calcSum = 0;
    let profitSum = 0;
    let spendSum = 0;
    let index = kpiDisplay.kpi.indexOf(kpiSel);

    if (kpiDisplay && kpiDisplay.kpi.indexOf(kpiSel) !== -1 && kpiDisplay.function[index] !== "base") {
        if (kpiDisplay.function[index] === "divide") {
            calcSum = divide(
                transforms,
                kpiDisplay.parameters[index],
                "optimal"
            );
        }
    } else {
        for (let trans of transforms) {
            if (kpiSel === "spend") {
                calcSum += trans.optimised_spend;
            } else if (kpiSel === "roi") {
                profitSum += trans.optimised_totals[roiGoal];
                spendSum += trans.optimised_spend;
                calcSum = spendSum === 0 ? 0 : profitSum / spendSum;
            } else {
                calcSum += trans.optimised_totals[kpiSel];
            }
        }
    }

    if (isNaN(calcSum)) {
        calcSum = 0;
    }
    return calcSum;
};

export const getSummedKPICalculatedTotals = (
    transforms,
    kpiSelected,
    roiGoal,
    kpiDisplay
) => {

    const kpiSel = kpiSelected.toLowerCase();
    let calcSum = 0;
    let profitSum = 0;
    let spendSum = 0;
    let index = kpiDisplay.kpi.indexOf(kpiSel);

    if (kpiDisplay && kpiDisplay.kpi.indexOf(kpiSel) !== -1 && kpiDisplay.function[index] !== "base") {
        if (kpiDisplay.function[index] === "divide") {
            calcSum = divide(
                transforms,
                kpiDisplay.parameters[index],
                "calculated"
            );
        }
    } else {
        for (let trans of transforms) {
            if (kpiSel === "spend") {
                calcSum += trans.calculated_spend;
            } else if (kpiSel === "roi") {
                profitSum += trans.calculated_totals[roiGoal];
                spendSum += trans.calculated_spend;
                calcSum = spendSum === 0 ? 0 : profitSum / spendSum;
            } else {
                calcSum += trans.calculated_totals[kpiSel];
            }
        }
    }
    if (isNaN(calcSum)) {
        calcSum = 0;
    }
    return calcSum;
};

const divide = (trans, params, type) => {
    let numeratorKey = params[0];
    let denominatorKey = params[1];

    let numeratorSum = 0;
    let denominatorSum = 0;
    let calcSum = 0;
    for (let tr of trans) {
        if(tr.sTransformation){
            tr = tr.sTransformation
        }
        if (type === "optimal") {
            let numerator =
                numeratorKey === "spend"
                    ? tr.optimised_spend
                    : tr.optimised_totals[numeratorKey];
            let denominator =
                denominatorKey === "spend"
                    ? tr.optimised_spend
                    : tr.optimised_totals[denominatorKey];
            numeratorSum += numerator;
            denominatorSum += denominator;
        } else {
            let numerator =
                numeratorKey === "spend"
                    ? tr.calculated_spend
                    : tr.calculated_totals[numeratorKey];
            let denominator =
                denominatorKey === "spend"
                    ? tr.calculated_spend
                    : tr.calculated_totals[denominatorKey];
            numeratorSum += numerator;
            denominatorSum += denominator;
        }
    }

    calcSum = denominatorSum === 0 ? 0 : numeratorSum / denominatorSum;

    return calcSum;
};


export const getKPITotalsByArray = (
    trans,
    kpiSelected,
    kpiDisplay,
    optimal
) => {
    let calcSum = 0;
    if(trans.length > 0){
        const kpiSel = kpiSelected.toLowerCase();
        let index = kpiDisplay.kpi.indexOf(kpiSel);
        if (kpiDisplay.function[index] === "divide") {
            if(optimal){
                calcSum += divide(trans, kpiDisplay.parameters[index], "optimal");
            } else {
                calcSum += divide(trans, kpiDisplay.parameters[index], "calculated");
            }
        } 
        else {
            for(let transf of trans){
                if(transf.sTransformation){
                    transf = transf.sTransformation
                }
                if (kpiSel === "spend") {
                    if(optimal){
                        calcSum += transf.optimised_spend;
                    } else {
                        calcSum += transf.calculated_spend;
                    }
                }  else {
                    if(optimal){
                        calcSum += transf.optimised_totals[kpiSel];
                    } else {
                        calcSum += transf.calculated_totals[kpiSel];
                    }
                    
                }
            }
        }
        }
    if (isNaN(calcSum)) {
        calcSum = 0;
    }

    return calcSum;
};