import { AgGrid, AgGridProps, AgGridReact } from '@gain-theory/ag-grid'
import { Card } from '@gain-theory/card'
import { Modal } from '@gain-theory/modal'
import { FC, useMemo, useRef, useState } from 'react'
import {
  Constraint,
  GroupingLevel,
  Observation,
  Transformation,
} from '../../../../types/PlanTypes'
import { Settings } from '../../../../types/SettingTypes'
import PlanTimePeriodBudgetConstraintsHeader from './TimePeriodBudgetConstraintsHeader'
import TimePeriodBudgetConstraintsTableHeader from './TimePeriodBudgetConstraintsTableHeader'
import { useDownloadConstraintsTemplate } from './hooks/use-download-constraints-template'
import { useFileUpload } from './hooks/use-file-upload'
import { useHasData } from './hooks/use-has-data'
import { useModalProps } from './hooks/use-modal-props'
import { useSubmitChanges } from './hooks/use-submit-changes'
import { useTableActions } from './hooks/use-table-actions'
import { useTableColumns } from './hooks/use-table-columns'
import { useTableData } from './hooks/use-table-data'
import { useTableEdit } from './hooks/use-table-edit'

interface OwnProps {
  budget: number
  constraints: Constraint[]
  currencyLabel: string
  planGenerationType: 'create' | 'edit'
  groupingLevels: GroupingLevel[]
  observations: Observation[]
  transformationOptions: Transformation[]
  settings: Settings | null
  generatePlanError: string | null
  handleBack: () => void
  handleGeneratePlan: (constraints?: Constraint[]) => void
  carryoverWeeks: number
}

type Props = OwnProps

export const TimePeriodBudgetConstraints: FC<Props> = ({
  budget,
  constraints,
  currencyLabel,
  planGenerationType,
  groupingLevels,
  observations,
  transformationOptions,
  settings,
  generatePlanError,
  handleBack,
  handleGeneratePlan,
  carryoverWeeks
}) => {
  const gridRef = useRef<AgGridReact>(null)

  const [anyRowSelected, setAnyRowSelected] = useState<boolean>(false)

  const groupingLevelsReplacedLabel: GroupingLevel[] = useMemo(
    () =>
      groupingLevels.map(({ key, label, values }) => {
        const levelSettingsKey = `${key}_name`

        const replacedLabel: string = settings
          ? settings[levelSettingsKey]
          : label

        return { key, label: replacedLabel, values }
      }),
    [groupingLevels, settings]
  )

  const {
    hierarchyColumns,
    typeColumn,
    statusColumn,
    observationColumns,
    columns,
    columnIndexing,
  } = useTableColumns({
    observations,
    groupingLevels: groupingLevelsReplacedLabel,
    dateFormat: settings?.date_format,
  })

  const rowData = useTableData({
    columnIndexing,
    columns,
    constraints,
    groupingLevels: groupingLevelsReplacedLabel,
    numberFormat: settings?.number_format,
  })

  const { columnDefs, localRowData, updateLocalRowData, handleEditCell } =
    useTableEdit({
      gridRef,
      rowData,
      columns,
      transformationOptions,
      groupingLevels: groupingLevelsReplacedLabel,
      numberFormat: settings?.number_format,
    })

  const {
    handleAddNewRow,
    handleDeleteRows,
    handleDisableRows,
    handleEnableRows,
    handleResetTable,
  } = useTableActions({
    gridRef,
    rowData,
    localRowData,
    columns,
    updateLocalRowData,
  })

  const { validationAlertProps, closeValidationAlert, handleSubmit } =
    useSubmitChanges({
      planGenerationType,
      handleGeneratePlan,
      hierarchyColumns,
      localRowData,
      observationColumns,
      statusColumn,
      typeColumn,
      generatePlanError,
      groupingLevels: groupingLevelsReplacedLabel,
      numberFormat: settings?.number_format,
    })

  const { fileUploadProps, showFileUpload, handleToggleFileUpload } =
    useFileUpload({
      observations,
      transformationOptions,
      numberFormat: settings?.number_format,
      groupingLevels: groupingLevelsReplacedLabel,
      onUploadSuccess: updateLocalRowData,
      settings: settings
    })

  const {
    modalProps,
    openModalForTableReset,
    openModalForGoBack,
    openModalForRowDelete,
  } = useModalProps({
    closeValidationAlert,
    handleResetTable,
    handleDeleteRows,
    handleBack,
  })

  const { hasData, handleOnFilterChanged } = useHasData({
    localRowData,
  })

  const handleDownload = useDownloadConstraintsTemplate({
    constraints,
    hierarchyColumns,
    observationColumns,
    transformationOptions,
    typeColumn,
    statusColumn,
    groupingLevels: groupingLevelsReplacedLabel,
    carryoverWeeks
  })

  const handleActionClick = (
    action: 'delete' | 'disable' | 'enable' | 'reset'
  ) => {
    if (action === 'delete') {
      openModalForRowDelete()
    }

    if (action === 'disable') {
      handleDisableRows()
    }

    if (action === 'enable') {
      handleEnableRows()
    }

    if (action === 'reset') {
      openModalForTableReset()
    }
  }

  const handleOnSelectionChange: AgGridProps['onSelectionChanged'] = () => {
    const selectedRows = gridRef?.current?.api.getSelectedRows()

    setAnyRowSelected(!!selectedRows && selectedRows.length > 0)
  }

  const paginationProps: AgGridProps['paginationProps'] = useMemo(
    () => ({
      perPage: 10,
      pageSizeOptions: [10, 20, 50, 100],
      translations: {
        OF: 'of',
        TO: 'to',
        PAGE: 'Page',
        ROWS_PER_PAGE: 'Rows per page',
        ROWS: 'Rows',
      },
    }),
    []
  )

  const agGridProps: AgGridProps = {
    editable: true,
    resizable: true,
    pagination: true,
    filterable: true,
    suppressMovableColumns: true,
    sortable: true,
    suppressRowClickSelection: true,
    loading: fileUploadProps.uploadProgress?.status === 'inProgress',
    rowSelection: 'multiple',
    rowData: localRowData,
    columnDefs: columnDefs,
    generalMenuItems: [
      'pinSubMenu',
      'autoSizeThis',
      'autoSizeAll',
      'resetColumns',
    ],
    domLayout: hasData ? 'autoHeight' : 'normal',
    paginationProps: paginationProps,
    onCellValueChanged: handleEditCell,
    onSelectionChanged: handleOnSelectionChange,
    onFilterChanged: handleOnFilterChanged,
    onGridReady: () => gridRef?.current?.api.sizeColumnsToFit(),
    ...(!hasData && { wrapperStyle: { height: '250px' } }),
  }

  return (
    <div className="flex flex-col h-full w-full items-center p-xxl">
      <PlanTimePeriodBudgetConstraintsHeader
        planGenerationType={planGenerationType}
        onBackBtnClick={openModalForGoBack}
        onGeneratePlanBtnClick={handleSubmit}
      />
      <Card>
        <TimePeriodBudgetConstraintsTableHeader
          currencyLabel={currencyLabel}
          budget={budget}
          numberFormat={settings?.number_format}
          validationAlertProps={validationAlertProps}
          anyRowSelected={anyRowSelected}
          showFileUpload={showFileUpload}
          fileUploadProps={fileUploadProps}
          planGenerationType={planGenerationType}
          handleDownload={handleDownload}
          handleToggleFileUpload={handleToggleFileUpload}
          handleAddRow={handleAddNewRow}
          handleActionClick={handleActionClick}
        />
        <AgGrid {...agGridProps} ref={gridRef} />
      </Card>
      <Modal {...modalProps} />
    </div>
  )
}
