import readXlsxFile from 'read-excel-file'

const uploadAppConfig = async (file) => {
  let config = {
    modules: [],
    okta_settings: [],
    systemLinks: { contact: [], faqs: [], links: [] },
    featureFlags: [],
  }

  await readXlsxFile(file, { sheet: 1 }).then((rows) => {
    rows.shift()

    rows.forEach((row) => {
      const newModule = {
        key: row[0],
        label: row[1],
        active: row[2],
        submodules: row[3] ? row[3].split(' ') : null,
      }
      config.modules.push(newModule)
    })
  })

  await readXlsxFile(file, { sheet: 'links' }).then((data) => {
    data.shift()
    config.systemLinks.links = data.map((item) => {
      return {
        system: item[0],
        label: item[1],
        url: item[2],
        sort: item[3],
      }
    })
  })

  await readXlsxFile(file, { sheet: 'contact' }).then((data) => {
    data.shift()
    config.systemLinks.contact = data.map((item) => {
      return {
        name: item[0],
        email: item[1],
      }
    })
  })

  await readXlsxFile(file, { sheet: 'faqs' }).then((data) => {
    data.shift()
    config.systemLinks.faqs = data.map((item) => {
      return {
        faq: item[0],
        answer: item[1],
        sort: item[2],
      }
    })
  })

  await readXlsxFile(file, { sheet: 'okta' }).then((data) => {
    data.shift()
    config.okta_settings = data.map((item) => {
      return {
        enabled: item[0],
        label: item[1],
        domain: item[2],
        client_id: item[3],
        userinfo_endpoint: item[4],
        idp_name: item[5],
        custom_idps: item[6] ?? [],
      }
    })
  })

  await readXlsxFile(file, { getSheets: true }).then((sheets) => {
    const featureSheetIndex = sheets.findIndex((x) => x.name === 'features')

    if (featureSheetIndex === -1) {
      return
    }

    const featureSheet = sheets[featureSheetIndex]

    return readXlsxFile(file, { sheet: featureSheet.name }).then((data) => {
      data.shift()
      config.featureFlags = data.map((item) => {
        return {
          key: item[0],
          label: item[1],
          module_key: item[2],
          enabled: ['1', 'true'].includes(item[3].toString().toLowerCase()),
          beta: ['1', 'true'].includes(item[4].toString().toLowerCase()),
        }
      })
    })
  })

  console.log(config)

  return config
}

export default uploadAppConfig
