import React, { FC } from 'react'
import { IPlanSummary } from '../types/PlanTypes'
import appendUnit from '../utils/appendUnit'
import { Settings } from '../types/SettingTypes'
import PlanSummaryListItemActionsMenu from './PlanSummaryListItemActionsMenu'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../Routes'
import { uuid } from 'uuidv4'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

interface OwnProps {
  planSummary: IPlanSummary[]
  settings: Settings
  addOrCutBudget: boolean
  planType?: 'crossChannel' | 'inChannel'
}

type Props = OwnProps

const PlanSummaryRow = (
  addOrCutBudget: boolean,
  item: IPlanSummary,
  settings: Settings,
  planType: 'crossChannel' | 'inChannel'
) => {
  const firstKPI =
    item &&
    item.output_keys &&
    item.options.optimisableKPIs &&
    item.output_keys.find(
      (x) => Object.keys(x)[0] === item.options.optimisableKPIs[0]
    )
      ? Object.values(
          item.output_keys.find(
            (x) => Object.keys(x)[0] === item.options.optimisableKPIs[0]
          ) || ''
        )[0].toLowerCase()
      : ''

  const firstKPIUnit =
    item &&
    item.unit_keys &&
    item.options.optimisableKPIs &&
    item.unit_keys.find((x) => x.label === firstKPI)
      ? item.unit_keys.find((x) => x.label === firstKPI)?.unit
      : ''

  const firstKPIValue =
    item && item.options.optimisableKPIs && item.calculated_totals
      ? appendUnit(
          item.calculated_totals[firstKPI?.replace(/ /g, '_')],
          firstKPIUnit || '',
          item.options.exchangeRates.defaultSymbol,
          settings.number_format
        )
      : 'N/A'

  const getName = (item: IPlanSummary) => {
    const inChannelPlanItemUrl = UrlAssembler()
      .template(ROUTES.SPO.IN_CHANNEL_PLAN_ITEM)
      .param('id', item.id)
      .toString()

    const crossChannelPlanItemUrl = UrlAssembler()
      .template(ROUTES.SPO.PLAN_ITEM)
      .param('id', item.id)
      .toString()

    const editBudgetUrl = UrlAssembler()
      .template(ROUTES.SPO.EDIT_BUDGET_SELECTION)
      .param('id', item.id)
      .param('currency', item.options.exchangeRates.defaultSymbol)
      .toString()

    const spoPlanItemUrl =
      planType === 'inChannel' ? inChannelPlanItemUrl : crossChannelPlanItemUrl

    const url = !addOrCutBudget ? spoPlanItemUrl : editBudgetUrl

    return (
      <Link to={url} className="text-gtPink hover:underline text-xs">
        {item.name || 'Plan ' + item.id}
      </Link>
    )
  }

  const getBudgetTotal = () => {
    const optimisationType = item['input_optimisation_type']

    let total = 0

    if (optimisationType === 'goal') {
      total = item['transformations_spend_value']
    } else {
      if (item['input_overall_constraint'] !== null) {
        total = item['input_overall_constraint']
      } else {
        total = 0
      }
    }
    return appendUnit(
      total,
      'currency',
      item.options.exchangeRates.defaultSymbol,
      settings.number_format
    )
  }

  const formatCalculatedTotal = (total: any, key: any) => {
    const unitVal = item.unit_keys.find(
      (el) => el.label === key.replace(/\_/g, ' ')
    )

    if (unitVal) {
      return unitVal.unit === 'currency'
        ? appendUnit(
            total,
            'currency',
            item.options.exchangeRates.defaultSymbol,
            settings.number_format
          )
        : appendUnit(
            total,
            '',
            item.options.exchangeRates.defaultSymbol,
            settings.number_format
          )
    }

    return appendUnit(
      total,
      '',
      item.options.exchangeRates.defaultSymbol,
      settings.number_format
    )
  }

  const tooltipOverlayInnerStyle = {
    backgroundColor: '#000000',
    color: '#FCFCFC',
    border: '2px',
    fontSize: '14px',
    borderColor: '#000000',
  }

  const showOverlay = () => {
    return item && item.options.optimisableKPIs && item.calculated_totals
      ? Object.keys(item.calculated_totals).map((key) => (
          <div key={key}>
            {key.replace(/\_/g, ' ')}
            {': '}
            {formatCalculatedTotal(item.calculated_totals[key], key)}
          </div>
        ))
      : null
  }

  return (
    <tr key={uuid()} className="p-2">
      <td className="px-6 py-4">
        {getName(item)}
        <span className="ml-0.5"> {item.locked_at && '🔒'}</span>
      </td>
      <td className="px-6 py-4">{getBudgetTotal()}</td>

      <Tooltip
        placement="right"
        trigger={['hover']}
        overlay={showOverlay}
        overlayInnerStyle={tooltipOverlayInnerStyle}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
      >
        <td className="px-6 py-4" id={'TooltipExample' + item.id}>
          {firstKPIValue}
        </td>
      </Tooltip>

      <td className="px-6 py-4">{item.created_at}</td>
      <td className="px-6 py-4">
        {item['config_observation_min_label']}
        {' - '}
        {item['config_observation_max_label']}
      </td>
      <td className="px-6 py-4">
        <PlanSummaryListItemActionsMenu fromPlan={false} item={item} />
      </td>
    </tr>
  )
}

const PlanSummaryTable: FC<Props> = (props) => {
  const { planType = 'crossChannel' } = props

  if (props.planSummary.length === 0) {
    return (
      <div className="w-full h-96 bg-white rounded border p-10 flex ">
        <span className="mx-auto my-auto font-black text-lg">
          You have yet to set up any marketing plans
        </span>
      </div>
    )
  }
  return (
    <table className="min-w-full table-auto mb-10 divide-y divide-gray-300 border rounded text-gray-400">
      <thead>
        <tr className="p-2">
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500"
          >
            Plan Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500"
          >
            Budget
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500"
          >
            KPIs
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500"
          >
            Date Created
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500"
          >
            Timeframe
          </th>
          <th scope="col" className="relative px-6 py-3 tracking-wider">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {props.planSummary.map((plan) =>
          PlanSummaryRow(props.addOrCutBudget, plan, props.settings, planType)
        )}
      </tbody>
    </table>
  )
}

export default PlanSummaryTable
