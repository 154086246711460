import { ticks as d3ticks } from 'd3-array'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { FC } from 'react'
import { Observation } from '../../../types/PlanTypes'

type PropsFunction = (a: number[]) => void

interface OwnProps {
  observations: Observation[]
  position: number[]
  handleMoveSlider: PropsFunction
}

type Props = OwnProps

const TimeSlider: FC<Props> = (props) => {
  const getObservationTicks = () => {
    const keys =
      props.observations.length > 10
        ? d3ticks(1, props.observations.length, 10)
        : d3ticks(1, props.observations.length, props.observations.length - 1)

    let obsTicks: any = {}
    keys.map((x) => {
      obsTicks[x - 1] = props.observations[x - 1].label
    })

    return obsTicks
  }

  return (
    <div className="w-full flex flex-col">
      <div className="bg-white rounded border p-6 pt-10 w-9/12 space-y-8 mx-auto my-auto flex flex-col ">
        <div className="w-full pl-7">
          <Range
            min={0}
            max={props.observations.length - 1}
            value={props.position}
            onChange={props.handleMoveSlider}
            marks={getObservationTicks()}
          />
        </div>
        <div className="w-full flex flex-row justify-center space-x-8">
          <div className="flex flex-col ">
            <span className="text-xs text-gray-500">START DATE</span>
            <span className="text-xxs text-gray-600 justify-center   ">
              {props.observations[props.position[0]].label}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-xs text-gray-500">END DATE</span>
            <span className="text-xxs text-gray-600">
              {props.observations[props.position[1]].label}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeSlider
