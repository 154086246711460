import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const VideoIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={stroke || '#666'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.0"
        >
          <path d="M1.333 45.667h61.334V16.333H1.333zM1.333 16.333h61.334v-8H1.333zM44 8.333v8M20 8.333v8M1.333 53.667h61.334v-8H1.333zM44 45.667v8M20 45.667v8" />
          <path d="M25.333 37.667V24.333L40 31z" />
        </g>
      </g>
    </svg>
  )
}

export default VideoIcon
