import React, { FC } from 'react'
import BrandCard from '../pages/md/launchpad/BrandCard'
import { uuid } from 'uuidv4'
import { Brand, IMR, View } from '../types/MarketingDiagnosticsTypes'

interface OwnProps {
  brands: Brand[]
  views: View[]
  imr: IMR
  sensorEnabled: boolean
}

type Props = OwnProps

const MDBrandsGrid: FC<Props> = (props) => {
  // Sort brands based on 'Sort' column of 'brands' tab in Reporting config
  const sortedBrands: Brand[] = [...props.brands].sort(
    (a, b) => a.sort - b.sort
  )

  return (
    <div className="grid grid-cols-3 gap-md">
      {sortedBrands.map((brand) => (
          <BrandCard
            brand={brand}
            key={uuid()}
            views={props.views}
            imr={props.imr}
            sensorEnabled={props.sensorEnabled}
          />
        ))}
    </div>
  )
}

export default MDBrandsGrid
