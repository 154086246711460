import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const GenericBrandIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
    >
      <defs>
        <circle id="a" cx="20" cy="20" r="20" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#F1F1F1" />
        <g
          stroke="#999"
          strokeLinejoin="round"
          strokeWidth="1.25"
          mask="url(#b)"
        >
          <path d="M31 23.623L23.622 31 11.007 18.387 11 11h7.384z" />
          <path d="M17.475 15.618a1.858 1.858 0 1 1-3.716-.001 1.858 1.858 0 0 1 3.716 0z" />
        </g>
      </g>
    </svg>
  )
}

export default GenericBrandIcon
