import { Alert } from '@gain-theory/alert'
import { Button } from '@gain-theory/button'
import { Card } from '@gain-theory/card'
import { Chip } from '@gain-theory/chip'
import { Combobox, ComboboxProps } from '@gain-theory/combobox'
import { FileUpload, FileUploadProps } from '@gain-theory/file-upload'
import { Icon } from '@gain-theory/icon'
import { Input } from '@gain-theory/input'
import { Switch } from '@gain-theory/switch'
import { Tooltip } from '@gain-theory/tooltip'
import { Typography } from '@gain-theory/typography'
import { FC, useEffect, useState } from 'react'
import { useIsBetaFeature } from '../../../shared/hooks/use-is-beta-feature'
import { ExchangeRate } from '../../../types/PlanTypes'
import { FEATURE_FLAGS } from '../../../utils/constants'

interface IUploadPlanDataProps {
  planName: string
  selectedCurrency: string
  currencyError: boolean
  file: File | null
  exchangeRates: ExchangeRate | undefined
  fileUploadAlert: FileUploadProps['alertProps']
  editConstraints: boolean
  isBudgetConstraintEnabled: boolean
  onChangeName: (name: string) => void
  onChangeCurrency: (selectedValue: string) => void
  onSwitchClick: () => void
  onChangeFile: (file: File | null) => void
  onInvalidFile: FileUploadProps['onInvalidFile']
  onDownloadTemplate: () => void
}

const UploadPlanData: FC<IUploadPlanDataProps> = ({
  planName,
  selectedCurrency,
  currencyError,
  file,
  exchangeRates,
  fileUploadAlert,
  editConstraints,
  isBudgetConstraintEnabled,
  onChangeName,
  onChangeCurrency,
  onSwitchClick,
  onChangeFile,
  onInvalidFile,
  onDownloadTemplate,
}: IUploadPlanDataProps) => {
  const [currency, setCurrency] = useState<ComboboxProps['selectedValues']>([])
  const [currencyOptions, setCurrencyOptions] = useState<
    ComboboxProps['options']
  >([])

  const [showPlanTableAlert, setShowPlanTableAlert] = useState<boolean>(true)

  const isBeta = useIsBetaFeature({
    flagKey: FEATURE_FLAGS.PROPORTIONAL_FLIGHTING,
  })

  const mapExchangeRateToCurrencyOption = (
    symbol: string,
    currency: string
  ) => ({
    id: symbol,
    label: `${symbol} (${currency})`,
  })

  useEffect(() => {
    if (!exchangeRates) {
      setCurrencyOptions([])
      return
    }

    const mappedOptions: ComboboxProps['options'] =
      exchangeRates.currencySymbols.map((symbol, index) =>
        mapExchangeRateToCurrencyOption(symbol, exchangeRates.currencies[index])
      )

    setCurrencyOptions(mappedOptions)
  }, [exchangeRates])

  useEffect(() => {
    if (!selectedCurrency || !exchangeRates) {
      setCurrency([])
      return
    }

    const selectedSymbolIndex = exchangeRates.currencySymbols.findIndex(
      (symbol) => symbol === selectedCurrency
    )

    if (selectedSymbolIndex === -1) {
      setCurrency([])
      return
    }

    const selectedCurrencyOption = mapExchangeRateToCurrencyOption(
      selectedCurrency,
      exchangeRates.currencies[selectedSymbolIndex]
    )

    setCurrency([selectedCurrencyOption])
  }, [selectedCurrency, exchangeRates])

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeName(e.target.value)
  }

  const handleChangeCurrencyValue: ComboboxProps['setSelectedValues'] = (
    values
  ) => {
    setCurrency(values)

    if (!values.length || values.length === 0) {
      onChangeCurrency('')
      return
    }

    const selectedCurrency = values[0]
    const selectedCurrencySymbol =
      selectedCurrency.id ?? selectedCurrency.label.split(' ')[0]

    onChangeCurrency(selectedCurrencySymbol.toString())
  }

  const PlanNameInput = (
    <Input.Base
      label="Plan name"
      badgeLabel="optional"
      placeholder="Enter plan name"
      value={planName}
      onChange={handleChangeName}
    />
  )

  const CurrencyCombobox = (
    <Combobox
      disallowTextManipulation
      multiple={false}
      allowNewValues={false}
      label="Currency"
      placeholder="Select currency"
      options={currencyOptions}
      selectedValues={currency}
      setSelectedValues={handleChangeCurrencyValue}
      state={currencyError ? 'error' : 'default'}
      helperText={currencyError ? 'This field is required.' : undefined}
    />
  )

  const TooltipContent = (
    <div className="flex flex-col gap-md">
      <Typography.Body1 bold>
        <span className="text-info-dark">Constraints</span>
      </Typography.Body1>
      <Typography.Body2>
        View, modify, add, or remove time period value or relative index
        constraints. Constraints are inherited automatically from the uploaded
        configuration. These constraints can be changed at any time after the
        plan is created.
      </Typography.Body2>
    </div>
  )

  const EditConstraintsToggle = !isBudgetConstraintEnabled ? null : (
    <div className="flex flex-row gap-xs items-center w-fit z-10">
      {isBeta && <Chip>Beta</Chip>}
      <Tooltip interactive position="top" content={TooltipContent}>
        <Icon name="InformationCircleIcon" color="info-main" />
      </Tooltip>
      <Switch
        label="Edit constraints after upload"
        active={editConstraints}
        onClick={onSwitchClick}
      />
    </div>
  )

  const PlanFile = (
    <div className="flex flex-col gap-xs">
      <div className="flex flex-row gap-xs justify-between items-center">
        <Typography.Body1>Plan file</Typography.Body1>
        {EditConstraintsToggle}
      </div>
      <FileUpload
        selectedFile={file}
        fileType="csv"
        maxFileSizeInKB={10 * 1024} // 10 MB
        translations={{
          DRAG_TO_UPLOAD_CTA: 'Drag your file here to upload',
          OR: 'or',
          UP_TO: 'up to',
          SELECT_FILE: 'select file',
          DROP_FILE_TO_UPLOAD_CTA: 'Drop your file here to upload.',
          DROP_FILE_TO_REPLACE_CTA:
            'Drop your file here to replace the current.',
          FILE_UPLOAD: 'File upload',
          REMOVE_FILE_TOOLTIP: 'Remove file',
        }}
        alertProps={fileUploadAlert}
        onFileDrop={onChangeFile}
        onInvalidFile={onInvalidFile}
        onDeleteFile={() => onChangeFile(null)}
      />
    </div>
  )

  const TemplateAlert = (
    <Alert
      variant="info"
      title="Plan table template"
      description="Plans need to follow a specific template to work. Check if the file is in the correct format before uploading."
      show={showPlanTableAlert}
      onClose={() => setShowPlanTableAlert(false)}
    />
  )

  const DownloadTemplateButton = (
    <div className="w-fit items-start justify-start">
      <Button
        variant="tertiary"
        onClick={onDownloadTemplate}
        leadingIcon={
          <Icon name="ArrowDownTrayIcon" color="brand-primary-main" />
        }
      >
        Download template
      </Button>
    </div>
  )

  return (
    <Card className="flex flex-col gap-xl w-3/5">
      <div className="flex flex-row gap-xl justify-between items-start">
        {PlanNameInput}
        {CurrencyCombobox}
      </div>
      {PlanFile}
      {TemplateAlert}
      {DownloadTemplateButton}
    </Card>
  )
}

export default UploadPlanData
