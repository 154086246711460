import React, { FC, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { RootStore } from '../../../redux/reducers/Store'
import { MainLayout } from '../../../shared/MainLayout'
import { connect, ConnectedProps } from 'react-redux'
import { SetOriginalConstraint } from '../../../redux/actions/SPOFlowActions/AddOrCutBudgetActions'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import {
  selectPlanCreatedComplete,
  selectPlanItem,
  selectCreateError,
  selectLoading,
} from '../../../redux/reducers/PlanReducer'
import { AddOrCutBudget } from '../../../socket.io'
import { GetPlanItem } from '../../../redux/actions/PlanActions'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../Routes'
import appendUnit from '../../../utils/appendUnit'
import api from '../../../api'

interface OwnProps {}

type Props = OwnProps

interface MatchParams {
  id: string
  currency: string
}

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
  loading: selectLoading(state),
  plan: selectPlanItem(state),
  optimiserError: selectCreateError(state),
  planCreated: selectPlanCreatedComplete(state),
  user: selectSessionUser(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  addCutBudget: AddOrCutBudget,
  setOriginalConstraint: SetOriginalConstraint,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const AddOrCutBudgetAmount: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const [editAmount, setEditAmount] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    props.onGetPlan(props.match.params.id)
  }, [])

  const addOrCutBudget = (e: any) => {
    const parsed = e.target.value.replace(/,/g, '')
    const budget = parseFloat(parsed)
    setEditAmount(budget)
  }

  const createEditedPlan = async (e: any) => {
    props.setOriginalConstraint(props.plan.optimised_spend)

    let constraintArray = []

    const validated = validateAmount(e.target.value)
    if (validated === '') {
      setError('')
      //if goal seek, set the optimised spend as the overall constraint and set plan to a budget plan
      let overallConstraint =
        e.target.value === 'add'
          ? props.plan.optimised_spend + editAmount
          : props.plan.optimised_spend - editAmount

      const newPlan = {
        ...props.plan,
        user_input: {
          ...props.plan.user_input,
          overallConstraint: overallConstraint,
          optimisationType: 'budget',
        },
      }

      //add in constraints for original scenario to set as base plan
      for (let i in props.plan.transformations) {
        const constraint = {
          fn: e.target.value === 'add' ? 'min' : 'max',
          variable: 'spend',
          identifiers: props.plan.transformations[i].identifiers,
          values: props.plan.transformations[i].calculated.spend,
        }
        if (props.plan.options.carryoverWeeks > 0) {
          const carryoverArray = new Array(
            props.plan.options.carryoverWeeks
          ).fill(0)
          constraint.values = constraint.values.concat(carryoverArray)
        }
        constraint.values.unshift(null)
        delete constraint.identifiers.region_key
        constraintArray.push(constraint)
      }
      newPlan.constraints = constraintArray

      if (props.user) {
        const response = await api.Plans.copyPlan(props.plan.id.toString()).then((resp) => {
          newPlan.id = resp.data.id
          props.addCutBudget(newPlan, props.user!)
        })

      }
    } else {
      setError(validated)
    }
  }

  const validateAmount = (addOrCut: string) => {
    let min = 10000
    let max = 100000000
    if (props.plan.config.options.kpiRanges) {
      const spendRanges = props.plan.config.options.kpiRanges.find(
        (range: any) => range.kpi === 'spend'
      )
      min = spendRanges.min
      max = spendRanges.max
    }
    if (
      isNaN(editAmount) ||
      typeof editAmount !== 'number' ||
      setEditAmount(0)
    ) {
      return 'Please enter a valid number'
    } else if (
      props.plan.optimised_spend - editAmount < min &&
      addOrCut === 'cut'
    ) {
      return `The total budget is bellow the minimum amount (${appendUnit(
        min,
        props.match.params.currency,
        'currency',
        ''
      )}) and cannot be cut from`
    } else if (
      props.plan.optimised_spend + editAmount > max &&
      addOrCut === 'add'
    ) {
      return `The total budget is above the maximum amount (${appendUnit(
        max,
        props.match.params.currency,
        'currency',
        ''
      )}) and cannot be added to`
    }
    return ''
  }

  const history = useHistory()
  useEffect(() => {
    if (props.planCreated) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', props.plan.id)
        .toString()

      history.push(url)
    }
  }, [props.planCreated])

  if (props.loading) {
    return <LoadingModal />
  }

  return (
    <>
      {
        <div className="flex flex-col h-screen place-content-center align-middle">
          <h1 className="text-gray-500 my-6 content-center text-center text-xl">
            How much do you want to add to or cut from your budget?
          </h1>
          <div className={'flex flex-row justify-center'}>
            <select>
              <option>{decodeURIComponent(props.match.params.currency)}</option>
            </select>
            <NumberFormat
              onChange={addOrCutBudget}
              placeholder={'Amount'}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={
                props.settings?.number_format === 'euro' ? '.' : ','
              }
              decimalSeparator={
                props.settings?.number_format === 'euro' ? ',' : '.'
              }
            />
          </div>
          <div className={'flex flex-row justify-center mt-8'}>
            <button
              className="rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
              onClick={createEditedPlan}
              value={'add'}
            >
              Add
            </button>
            <button
              className="rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
              onClick={createEditedPlan}
              value={'cut'}
            >
              Cut
            </button>
          </div>
          {error !== '' && (
            <div className={'text-center text-gtBlueSecondary'}>{error}</div>
          )}
        </div>
      }
    </>
  )
}

export default connector(MainLayout(AddOrCutBudgetAmount))
