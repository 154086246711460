import { Typography } from '@gain-theory/typography'
import { memo } from 'react'
import { FilterChipProps } from './types'

export const FilterChip = memo(
  ({ chip, selected, onClick }: FilterChipProps) => {
    const { id, label } = chip

    const handleClick = () => {
      onClick(id)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.key === 'Enter' && onClick(id)
    }

    return (
      <div
        role="button"
        id={`filter-chip-${id}`}
        data-testid={id ? `${id}-filter-chip` : `filter-chip`}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        className={`w-huge flex-grow-0 flex-shrink-0 flex items-center justify-center px-sm py-xxs rounded-pill text-center z-30 select-none transition-all duration-300 ease-in-out shadow-sm border-2 whitespace-nowrap border-transparent hover:cursor-pointer hover:shadow-color-primary-lg focus:border-neutral-white focus:outline-brand-primary-light ${
          selected ? 'bg-brand-primary-main' : 'bg-neutral-gray-1100'
        }`}
      >
        <Typography.Body2>
          <span className="text-text-white">{label}</span>
        </Typography.Body2>
      </div>
    )
  }
)
