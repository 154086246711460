import React, { FC } from 'react'
import { Channel } from '../types/MarketingDiagnosticsTypes'
import { Chip, ChipProps } from '@gain-theory/chip'
interface OwnProps {
  previous: Channel | undefined
  prevValue: string | undefined
  value: string
  phaseCount: number
}

type Props = OwnProps

const KpiCell: FC<Props> = (props) => {
  if (props.value === '0.00') {
    return (
      <div>
        <div
          style={{
            width: '70px',
            float: 'left',
          }}
        >
          {props.value}
        </div>
        <div className="percentage-change-md">-</div>
      </div>
    )
  }
  const chipProps: ChipProps = {
    variant: +(props?.prevValue || 0) < 0 ? 'destructive' : 'success',
    size: 'md',
  }
  if (props.phaseCount > 1) {
    return (
      <div className="flex flex-row items-center space-x-2 justify-between">
        <div className="text-xxs text-neutral-gray-1100">{props.value}</div>
        <div className="flex py-1 ">
          {props.prevValue && (
            <Chip {...chipProps}>
              <span className="text-xxs">
                {!props.prevValue ? '-' : `${props.prevValue}%`}
              </span>
            </Chip>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div
        style={{
          width: '80px',
        }}
      >
        <span className="mx-auto my-auto text-xxs">{props.value}</span>
      </div>
    </div>
  )
}

export default KpiCell
