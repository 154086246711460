import React, { FC, useEffect } from 'react'
import { MainLayout } from '../../shared/MainLayout'
import { RootStore } from '../../redux/reducers/Store'
import { selectPlanSummaryList } from '../../redux/reducers/PlanReducer'
import { selectConfigs } from '../../redux/reducers/ConfigReducer'
import { GetPlanSummaryList } from '../../redux/actions/PlanActions'
import { connect, ConnectedProps } from 'react-redux'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { IPlanSummary } from '../../types/PlanTypes'
import PlansLaunchpad from './PlansLaunchpad'
import { GetConfigList } from '../../redux/actions/ConfigActions'

const mapState = (state: RootStore) => ({
  planSummaryList: selectPlanSummaryList(state),
  configList: selectConfigs(state),
  settings: selectSettings(state),
})

const mapDispatchToProps = {
  onGetPlanSummaryList: GetPlanSummaryList,
  onGetConfigList: GetConfigList,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const InChannel: FC<PropsFromRedux> = (props) => {
  useEffect(() => {
    props.onGetPlanSummaryList()
    props.onGetConfigList()
  }, [])

  const inChannelConfigs = props.configList
    ? props.configList.filter((x) => x.options.in_channel)
    : []
  const inChannelPlans = props.planSummaryList
    .filter((x: IPlanSummary) => x.options.in_channel === true)
    .filter((_: any, ind: number) => ind < 10)

  return (
    <>
      <div className="text-gray-500 text-lg">Scenario Planning & Optimisation</div>
      {inChannelConfigs.length > 0 && props.settings && props.configList ? (
        <PlansLaunchpad
          inChannel={true}
          plans={inChannelPlans}
          settings={props.settings}
          latestConfig={inChannelConfigs[0]}
        />
      ) : null}
    </>
  )
}

export default connector(MainLayout(InChannel))
