import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const DigitalIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M41.435 50.522H19.87l1.347-5.392h18.87z" />
          <path d="M57.609 6.043A4.043 4.043 0 0 0 53.565 2h-43.13A4.045 4.045 0 0 0 6.39 6.043v31L1 55.913v1.348a4.045 4.045 0 0 0 4.043 4.043h53.914A4.043 4.043 0 0 0 63 57.261v-1.348l-5.391-20.217V6.043zM6.391 37.043H57.61M1 55.913h62" />
          <path d="M11.783 31.652h40.434V7.392H11.783z" />
        </g>
      </g>
    </svg>
  )
}

export default DigitalIcon
