import React, { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MainLayout } from '../../shared/MainLayout'
import { RootStore } from '../../redux/reducers/Store'
import {
  selectPlanSummaryList,
  selectShowSharePlanModal,
  selectLoadingSummaries,
} from '../../redux/reducers/PlanReducer'
import { selectConfigs } from '../../redux/reducers/ConfigReducer'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { GetPlanSummaryList } from '../../redux/actions/PlanActions'
import { connect, ConnectedProps } from 'react-redux'
import UrlAssembler from 'url-assembler'
import PlanSummaryTable from '../../shared/PlanSummaryTable'
import { ROUTES } from '../../Routes'
import SharePlanModal from './SharePlanModal'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { GetConfigList } from '../../redux/actions/ConfigActions'
import { RouteComponentProps } from '../../types/CreatePlanTypes'

const mapState = (state: RootStore) => ({
  planSummaryList: selectPlanSummaryList(state),
  settings: selectSettings(state),
  configList: selectConfigs(state),
  showModal: selectShowSharePlanModal(state),
  loadingSummaries: selectLoadingSummaries(state),
})

const mapDispatchToProps = {
  onGetPlanSummaryList: GetPlanSummaryList,
  onGetConfigList: GetConfigList,
}

interface MatchParams {
  path: string
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanList: FC<PropsFromRedux & RouteComponentProps<MatchParams>> = (props) => {
  const [planType, setPlanType] = useState<'crossChannel' | 'inChannel'>(
    'crossChannel'
  )
  const [displayPlans, setDisplayPlans] = useState(
    props.planSummaryList.filter(
      (plan: any) => plan.options.in_channel === false
    )
  )

  useEffect(() => {
    if (props.match && props.match.path === ROUTES.SPO.IN_CHANNEL_SCENARIOS) {
      setPlanType("inChannel")
    }
  }, [props.match])

  const crossChannelConfig = props.configList
    ? props.configList.filter((x) => !x.options.in_channel)
    : []

  const inChannelConfig = props.configList
    ? props.configList.filter((x) => x.options.in_channel)
    : []

  const planListHeader =
    planType === 'inChannel' ? 'In-Channel SPO Plans' : 'Cross-Channel Plans'

  const createCrossChannelPlanURL = UrlAssembler()
    .template(ROUTES.SPO.CREATE_PLAN)
    .param('id', crossChannelConfig[0]?.id.toString())
    .param('optimisationType', 'budget')
    .toString()

  const createInChannelPlanURL = UrlAssembler()
    .template(ROUTES.SPO.CREATE_IN_CHANNEL_PLAN)
    .param('id', inChannelConfig[0]?.id.toString())
    .toString()

  const createNewPlan = () =>
    planType === 'inChannel'
      ? createInChannelPlanURL
      : createCrossChannelPlanURL

  useEffect(() => {
    props.onGetPlanSummaryList()
    props.onGetConfigList()
  }, [])

  useEffect(() => {
    if (props.showModal.show) {
      document.body.style.overflow = 'hidden'
    }
    if (!props.showModal.show) {
      document.body.style.overflow = 'auto'
    }
  }, [props.showModal.show])

  useEffect(() => {
    if (planType === 'crossChannel') {
      setDisplayPlans(
        props.planSummaryList.filter(
          (plan: any) => plan.options.in_channel === false
        )
      )
    }
    if (planType === 'inChannel') {
      setDisplayPlans(
        props.planSummaryList.filter(
          (plan: any) => plan.options.in_channel === true
        )
      )
    }
  }, [planType, props.planSummaryList])

  if (
    props.loadingSummaries ||
    (crossChannelConfig.length === 0 && inChannelConfig.length === 0)
  ) {
    return <LoadingModal />
  }

  return (  
    <>
      {props.showModal.show && <SharePlanModal />}
      <div className="text-lg text-gray-500 mb-6 flex flex-row justify-between items-center">
        <div className="flex">
          <span className="m-3">{planListHeader}</span>
        </div>
        <Link to={createNewPlan}>
          <button
            className="rounded bg-gtPink text-white text-xs px-8 py-2 input-focus btn"
            onClick={createNewPlan}
          >
            CREATE NEW PLAN
          </button>
        </Link>
      </div>

      {props.settings && (
        <PlanSummaryTable
          addOrCutBudget={false}
          planSummary={displayPlans}
          settings={props.settings}
          planType={planType}
        />
      )}
    </>
  )
}

export default connector(MainLayout(PlanList))
