import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const downloadPlanAsImage = (plan) => {
  const filename = plan.name ? `${plan.name}.png` : 'Plan.png'
  html2Canvas(document.querySelector('#root')).then((canvas) => {
    let pdf = new jsPDF('p', 'mm', 'a4')
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 168, 415)
    let lnk = document.createElement('a'),
      e

    lnk.download = filename
    lnk.href = canvas.toDataURL('image/png')
    e = document.createEvent('MouseEvents')
    e.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )

    lnk.dispatchEvent(e)
  })
}
