import { useCallback, useState } from 'react'
import useEventListener from './use-event-listener'
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect'
import { Size, UseElementSizeReturnType } from './types'

export function useElementSize<
  T extends HTMLElement = HTMLDivElement
>(): UseElementSizeReturnType {
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    })
  }, [ref?.offsetHeight, ref?.offsetWidth])

  useEventListener('resize', handleSize)

  useIsomorphicLayoutEffect(() => {
    handleSize()
  }, [ref?.offsetHeight, ref?.offsetWidth])

  return [setRef, size]
}
