import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uuid } from 'uuidv4'
import MediaChannelIcon from '../../../icons/media/MediaChannelIcon'
import { AppState } from '../../../redux/reducers/RootReducer'
import {
  GroupingLevel,
  GroupingLevelValue,
  Identifier,
  STransformation,
  Transformation
} from '../../../types/PlanTypes'
// import Slider from 'rc-slider/lib/Slider'
import { Typography } from '@gain-theory/typography'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { UpdateAllBrandsMediaBar } from '../../../redux/actions/PlanActions'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { EditCPM, EditCosts } from '../../../socket.io'
import EditableAttribute from '../../../utils/EditableAttribute'
import { scaleObservationBudgets } from '../../../utils/PlanFilterUtils'
import { getBrandAllocations } from '../../../utils/PlanUtils'
import appendUnit from '../../../utils/appendUnit'
import { createBudgetAllocations } from '../../../utils/calculateCostData'
import { CHART_BAR_COLOR, CHART_BAR_COLORS, CHART_BAR_COLOR_GREY } from '../../../utils/colours'
import { COST_NAME, COST_NAME_IN_CHANNEL } from '../../../utils/constants'
import { getKPITotalsByArray } from '../../../utils/kpiDisplay'
import BudgetSlider from './media-channel-allocation/BudgetSlider'
import {
  MediaAllocationEnum,
  MediaAllocationTab,
} from './media-channel-allocation/MediaAllocationTab'
import WeeklyView from './media-channel-allocation/WeeklyView'
import { useNotificationContext } from '../../../shared/notification-provider'

interface OwnProps {
  inChannel: boolean
  name: string
}

type Props = OwnProps

const MediaChannelAllocation: FC<Props> = (props) => {
  const { toast } = useNotificationContext()

  const { planItem, configOutputs, outputSelectOptions } = useSelector(
    (state: AppState) => state.plans
  )

  const scenarioOutputSelected = useSelector<AppState, string>(
    (state) => state.plans.scenarioOutputSelected
  )

  const { user } = useSelector((state: AppState) => state.session)

  const { settings } = useSelector((state: AppState) => state.settings)

  const dispatch = useDispatch()

  const { levelTransforms, allTransforms, loadingBarMove } = useSelector(
    (state: AppState) => state.planFilters
  )

  const planFilters = useSelector((state: AppState) => state.planFilters)

  const transformsWithMainInput = getBrandAllocations(
    planItem,
    planFilters.appliedFilters
  )

  const [mediaAllocations, setMediaAllocations] = useState<any[]>([])
  const [showWeeklyCosts, setShowWeeklyCosts] = useState(false)

  useEffect(() => {
    if(planItem.transformations[0].inputs[COST_NAME]){
      setShowWeeklyCosts(true)
    }
  }, [planItem])

  // @ts-ignore
  const names = _.uniqBy(
    levelTransforms.map(
      (transformation) => transformation.identifiers[props.name]
    ),
    function (e) {
      return e
    }
  )

  useEffect(() => {
    const barAllocations = createBudgetAllocations(
      names,
      props.name,
      planItem.config.grouping_levels,
      levelTransforms
    )
    setMediaAllocations(barAllocations)
  }, [levelTransforms])

  let calculatedMaxScale: number = planFilters.allTransforms
    .map((item: any) => item.optimised_spend)
    .reduce((acc: any, cur: any) => acc + cur, 0)

  const getUnallocated = (transforms: any[]) => {
    const totalCalc = transforms.reduce((acc, val) => {
      return acc + val.calculated_spend
    }, 0)
    const totalOpt = transforms.reduce((acc, val) => {
      return acc + val.optimised_spend
    }, 0)

    let unallocated_spend = totalOpt - totalCalc
    unallocated_spend = unallocated_spend < 0 ? 0 : unallocated_spend

    return unallocated_spend
  }

  const updateBar = (value: number, barBudget: any) => {
    let v: any[] = []
    let newValue = value
    const currencyRateIndex = planItem.options.exchangeRates.currencies.indexOf(
      planItem.options.exchangeRates.defaultCurrency
    )
    const currencyRate = planItem.options.exchangeRates.rates[currencyRateIndex]
    newValue = value / currencyRate

    const multiplier = newValue / barBudget.sTransformation.calculated_spend

    const affectedLevelTransformation = transformsWithMainInput.filter(
      (transform: any) => transform.identifiers[props.name] === barBudget.name
    )

    if (multiplier !== Infinity) {
      v = affectedLevelTransformation.map((transform: any) => {
        return {
          ...transform,
          inputs: {
            [transform.mainInput.key]: scaleObservationBudgets(
              transform.mainInput.values,
              transform.sTransformation.calculated_spend * multiplier
            ),
          },
        }
      })
    } else {
      //when previous spend is 0, spread the new value equally among all transforms
      const amountPerTransform = newValue / affectedLevelTransformation.length

      const noOfObservations =
        affectedLevelTransformation[0].mainInput.values.length -
        planItem.options.carryoverWeeks

      const amountPerObservation = newValue / noOfObservations

      v = affectedLevelTransformation.map((transform: any) => {
        const inputValues = transform.mainInput.values
          .map((val: any) => (val = amountPerObservation))
          .slice(
            0,
            transform.mainInput.values.length - planItem.options.carryoverWeeks
          )

        return {
          ...transform,
          inputs: {
            [transform.mainInput.key]: scaleObservationBudgets(
              inputValues,
              amountPerTransform
            ),
          },
        }
      })
    }

    const newTransforms: STransformation[] = allTransforms.map(
      (transform: any) => {
        let found = v.find((tr) => {
          let match = true
          for (let lev of Object.keys(planFilters.appliedFilters)) {
            if (tr.identifiers[lev] !== transform.identifiers[lev]) {
              match = false
              break
            }
          }
          if (match) {
            return tr
          }
        })
        if (found) {
          return found
        } else {
          transform.inputs.spend = transform.inputs.spend.map(
            (x: any) => x / currencyRate
          )
        }

        return transform
      }
    )

    if (planFilters.scenarioFilters) {
      dispatch(
        UpdateAllBrandsMediaBar(
          planItem,
          newTransforms,
          planFilters.scenarioFilters
        )
      )
    }

    // dispatch(UpdateMediaBar(planItem, newTransforms))
  }

  const getUnallocatedBarBudget = () => {
    const unallocated = getUnallocated(allTransforms)
    return unallocated
  }

  const getCPMInputs = () => {
    const transformsWithInputs = planItem
      ? planItem.config.transformations
          .filter((x: Transformation) => {
            for (let trans of planItem.transformations) {
              if (_.isEqual(x.identifiers, trans.identifiers)) {
                return trans
              }
            }
          })
          .map((x: Transformation) => {
            const index = planItem.user_cpms.identifiers.findIndex(
              (y: Identifier) => y[props.name] === x.identifiers[props.name]
            )
            return {
              mediaKey: x.identifiers[props.name],
              mediaLabel: planItem.config.grouping_levels
                .find((g: GroupingLevel) => g.key === props.name)
                .values.find(
                  (v: GroupingLevelValue) => v.key === x.identifiers[props.name]
                ).label,
              cpm: planItem.user_cpms.values[index],
              currentSpend: planItem.transformations.find(
                (t: Transformation) =>
                  t.identifiers[props.name] === x.identifiers[props.name]
              ).optimised_spend,
            }
          })
      : []
    return transformsWithInputs
  }

  const getCosts = (name: string) => {
    const transformsWithInputs = planItem
      ? planItem.transformations.filter((x: any) =>  x.identifiers[props.name] === name
          )
      : []
      if(transformsWithInputs.length > 0 && transformsWithInputs[0].inputs[COST_NAME]){
        const costs = transformsWithInputs.map((x: any) => {
          return x.inputs[COST_NAME]
        })
        let costTotal = 0.0
        costs.map((x: number[]) => {
          costTotal += x.reduce((a: number, b: number) => a + b, 0)
        })
        let costAverage = costTotal /= transformsWithInputs.length /= 1
        costAverage /= planItem.transformations[0].inputs.spend.length  
        return costAverage
      }
    return 0
  }
  const editCPMValue = (e: string, cpmInput: any) => {
    // if(e < cpmInput.cpm * 0.8 || e > cpmInput.cpm * 1.2){
    // } else {

    if (parseFloat(e) <= 0) {
      return
    }
    if (user) {
      dispatch(
        EditCPM(
          user,
          planItem.id,
          parseFloat(e) / currencyRate,
          cpmInput.mediaKey,
          planItem.user_cpms
        )
      )
    }
    // }
  }


  const currencyRateIndex = planItem!.options.exchangeRates.currencies.findIndex((x: any) => x === planItem!.options.exchangeRates.defaultCurrency)
  const currencyRate = planItem!.options.exchangeRates.rates[currencyRateIndex]

  const editCostValue = (e: string, barBudget: any) => {
    if (parseFloat(e) <= 0) {
      return
    }
    if (user) {
      toast(
        'success',
        'Costs edited successfully.',
        'All changes were applied.'
      )
      EditCosts(
        user,
        planItem.id,
        parseFloat(e) / currencyRate,
        barBudget.name,
        barBudget.transformations,
        planItem.user_cpms
      )
    }
  }
  const label = planItem.config.grouping_levels.find((x: GroupingLevel) => x.key === props.name).label

  const SpendView = (
    <div className="relative">
      <div className="w-full flex flex-row space-x-1 border bg-neutral-gray-100 h-12 rounded-md">
        <Typography.Body2 className="w-2/12 flex flex-row items-center space-x-3 pl-3">{props.inChannel ? "Partner": label}</Typography.Body2>
        <div className="w-7/12"/>
        {showWeeklyCosts || props.inChannel ? <Typography.Body2 className="w-1/12 flex items-center">Avg. Cost</Typography.Body2>
        : <div className="w-1/12" />}
        <Typography.Body2 className="w-1/12 text-success-main flex items-center">Optimal</Typography.Body2>
        <Typography.Body2 className="w-1/12 flex items-center">KPI</Typography.Body2>
      </div>
      <div className="border-2 bg-white border-gray-300 rounded flex flex-col divide-y divide-gray-300" style={{marginTop: "0px"}}>
        {mediaAllocations.map((barBudget: any, index: number) => {
          const CPMValue = props.inChannel
            ? getCPMInputs().find((x: any) => x.mediaKey === barBudget.name)
                ?.[COST_NAME_IN_CHANNEL]
            : 0
          const costValue = !props.inChannel ? getCosts(barBudget.name) : 0
          const strokeColor = CHART_BAR_COLOR['stroke']
          return (
            <div key={uuid()} className="w-full flex flex-row space-x-1 h-16">
              <div className="w-2/12 flex flex-row items-center space-x-3 pl-3">
                {!props.inChannel && (
                  <MediaChannelIcon
                    className={` w-6 h-6 ${strokeColor[index % strokeColor.length]}`}
                    type={barBudget.name}
                  />
                )}
                <span className="text-xs">{barBudget.label}</span>
              </div>

              <BudgetSlider
                barBudget={barBudget}
                calculatedMaxScale={calculatedMaxScale}
                ray-600
                realMax={
                  getUnallocatedBarBudget() +
                  barBudget.sTransformation.calculated_spend
                }
                onUpdate={updateBar}
                color={CHART_BAR_COLORS[index % CHART_BAR_COLORS.length]}
              />

              {props.inChannel && settings && (
                <div className="w-1/12 flex items-center text-gtBluePrimary text-xs">
                  <EditableAttribute
                    defaultValue={
                      CPMValue   * currencyRate ? Math.round(CPMValue * 100) / 100 : 0
                    }
                    className={'form-control'}
                    cpm={true}
                    onSave={(e: any) =>
                      editCPMValue(
                        e,
                        getCPMInputs().find(
                          (x: any) => x.mediaKey === barBudget.name
                        )
                      )
                    }
                  >
                    {appendUnit(
                      CPMValue   * currencyRate,
                      'currency',
                      planItem.options.exchangeRates.defaultSymbol,
                      settings.number_format
                    )}
                  </EditableAttribute>
                </div>
              )}
              {showWeeklyCosts ? <div className="w-1/12 flex items-center text-gray-600 text-xs">
              <EditableAttribute
                    defaultValue={
                      costValue  * currencyRate ? Math.round(costValue * 100) / 100 : 0
                    }
                    className={'form-control'}
                    onSave={(e: any) =>
                      editCostValue(
                        e, barBudget)
                    }
                  >
                {appendUnit(
                  costValue * currencyRate,
                  'currency',
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
                </EditableAttribute>
              </div> : !props.inChannel && <div className="w-1/12" />}
              <div className="w-1/12 text-success-main flex items-center text-xs">
                {appendUnit(
                  getKPITotalsByArray(
                    barBudget.transformations,
                    scenarioOutputSelected,
                    planItem.config.options.kpiDisplay,
                    true
                  ),
                  scenarioOutputSelected === 'spend'
                    ? 'currency'
                    : configOutputs.find(
                        (x: any) =>
                          x.label.toLowerCase().replace(/ /g, '_') ===
                          scenarioOutputSelected
                      )!.unit,
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
              </div>
              <div className="w-1/12 flex items-center text-gray-600 text-xs">
                {appendUnit(
                  getKPITotalsByArray(
                    barBudget.transformations,
                    scenarioOutputSelected,
                    planItem.config.options.kpiDisplay,
                    false
                  ),
                  scenarioOutputSelected === 'spend'
                    ? 'currency'
                    : configOutputs.find(
                        (x: any) =>
                          x.label.toLowerCase().replace(/ /g, '_') ===
                          scenarioOutputSelected
                      )!.unit,
                  planItem.options.exchangeRates.defaultSymbol || '',
                  settings?.number_format || ''
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div
        key={uuid()}
        className="w-full flex flex-row space-x-1 h-16 bg-white border"
      >
        <div className="w-2/12 flex flex-row items-center space-x-3 pl-3">
          <div className="text-xs">Unallocated Budget</div>
        </div>
        <div className="w-7/12">
          <div className="media-alloc-channel-budget h-full z-40 items-center flex">
            <Slider
              min={0}
              value={getUnallocatedBarBudget()}
              max={calculatedMaxScale}
              trackStyle={{
                background: CHART_BAR_COLOR_GREY,
              }}
              handle={() => <></>}
            />
          </div>
        </div>
        <div className="w-1/12 text-success-main flex items-center"></div>
        <div className="w-1/12 flex items-center text-gray-600 text-xs">
          {appendUnit(
            getUnallocatedBarBudget(),
            'currency',
            planItem.options.exchangeRates.defaultSymbol || '',
            settings?.number_format || ''
          )}
        </div>
        <div className='w-1/12' />
      </div>
    </div>
  )

  const [selectedTab, setSelectedTab] = useState<MediaAllocationEnum>(
    MediaAllocationEnum.SPEND_VIEW
  )

  return (
    <>
      {showWeeklyCosts && <MediaAllocationTab
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />}
      {selectedTab === MediaAllocationEnum.SPEND_VIEW ? (
        SpendView
      ) : (
        <WeeklyView planItem={planItem} mediaAllocations={mediaAllocations} />
      )}

      {loadingBarMove && <LoadingModal />}
    </>
  )
}

export default MediaChannelAllocation
