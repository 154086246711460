import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { HelpfulLinksProps } from '../../helpful-links'
import { AppState } from '../../../../redux/reducers/RootReducer'

export function useHelpfulLinks() {
  const { systemLinks } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const [helpfulLinks, setHelpfulLinks] = useState<HelpfulLinksProps['links']>(
    []
  )

  const mapLinks = () => {
    const hasLinks =
      systemLinks !== null &&
      !!systemLinks.links &&
      systemLinks.links.length > 0

    let mappedLinks: HelpfulLinksProps['links'] = []

    if (hasLinks) {
      mappedLinks = systemLinks.links.map((link, index) => ({
        id: `link-${index + 1}`,
        title: link.label,
        url: link.url,
      }))
    }

    setHelpfulLinks(mappedLinks)
  }

  const onLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    mapLinks()
  }, [systemLinks?.links])

  return { helpfulLinks, onLinkClick }
}
