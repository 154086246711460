import { ISessionResponse, IUser, IGroup } from '../../types'

export const SESSION_CREATE_ATTEMPT = 'SESSION_CREATE_ATTEMPT'
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS'
export const SESSION_CREATE_FAIL = 'SESSION_CREATE_FAIL'
export const SESSION_RECOVER_ATTEMPT = 'SESSION_RECOVER_ATTEMPT'
export const SESSION_RECOVER_SUCCESS = 'SESSION_GET_SUCCESS'
export const SESSION_RECOVER_FAIL = 'SESSION_GET_FAIL'
export const SESSION_DESTROY_ATTEMPT = 'SESSION_DESTROY_ATTEMPT'
export const SESSION_DESTROY_SUCCESS = 'SESSION_DESTROY_SUCCESS'
export const OKTA_SESSION_CREATE_ATTEMPT = 'OKTA_SESSION_CREATE_ATTEMPT'
export const OKTA_SESSION_CREATE_SUCCESS = 'OKTA_SESSION_CREATE_SUCCESS'
//
export const GET_USERS_ATTEMPT = 'GET_USERS_ATTEMPT'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const DELETE_USER_ATTEMPT = 'DELETE_USER_ATTEMPT'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const CREATE_USER_ATTEMPT = 'CREATE_USER_ATTEMPT'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const UPDATE_USER_ATTEMPT = 'UPDATE_USER_ATTEMPT'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const CREATE_GROUP_ATTEMPT = 'CREATE_GROUP_ATTEMPT'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'
export const GET_GROUPS_ATTEMPT = 'GET_GROUPS_ATTEMPT'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL'
export const CREATE_USER_GROUP_ATTEMPT = 'CREATE_USER_GROUP_ATTEMPT'
export const CREATE_USER_GROUP_SUCCESS = 'CREATE_USER_GROUP_SUCCESS'
export const CREATE_USER_GROUP_FAIL = 'CREATE_USER_GROUP_FAIL'
export const DELETE_USER_GROUP_ATTEMPT = 'DELETE_USER_GROUP_ATTEMPT'
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS'
export const DELETE_USER_GROUP_FAIL = 'DELETE_USER_GROUP_FAIL'
export const UPDATE_GROUP_ATTEMPT = 'UPDATE_GROUP_ATTEMPT'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'
export const UPDATE_PASSWORD_ATTEMPT = 'UPDATE_PASSWORD_ATTEMPT'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'
export const SESSION_END_ATTEMPT = 'SESSION_END_ATTEMPT'
export const SESSION_END_SUCCESS = 'SESSION_END_SUCCESS'
export const SESSION_END_FAIL = 'SESSION_END_FAIL'

interface SessionCreateAttempt {
  type: typeof SESSION_CREATE_ATTEMPT
}

interface SessionCreateSuccess {
  type: typeof SESSION_CREATE_SUCCESS
  payload: ISessionResponse
}

interface SessionCreateFail {
  type: typeof SESSION_CREATE_FAIL
  payload: string
}

interface SessionEndAttempt {
  type: typeof SESSION_END_ATTEMPT
}

interface SessionEndSuccess {
  type: typeof SESSION_END_SUCCESS
}

interface SessionEndFail {
  type: typeof SESSION_END_FAIL
}

interface SessionRecoverAttempt {
  type: typeof SESSION_RECOVER_ATTEMPT
}

interface SessionRecoverSuccess {
  type: typeof SESSION_RECOVER_SUCCESS
  payload: ISessionResponse
}

interface SessionRecoverFail {
  type: typeof SESSION_RECOVER_FAIL
  payload: string
}

interface SessionDestroyAttempt {
  type: typeof SESSION_DESTROY_ATTEMPT
}

interface SessionDestroySuccess {
  type: typeof SESSION_DESTROY_SUCCESS
}

interface GetUsersAttempt {
  type: typeof GET_USERS_ATTEMPT
}
interface GetUsersSuccess {
  type: typeof GET_USERS_SUCCESS
  payload: IUser[]
}
interface GetUsersFail {
  type: typeof GET_USERS_FAIL
}

interface DeleteUserAttempt {
  type: typeof DELETE_USER_ATTEMPT
}
interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS
}
interface DeleteUserFail {
  type: typeof DELETE_USER_FAIL
}

interface CreateUserAttempt {
  type: typeof CREATE_USER_ATTEMPT
}
interface CreateUserSuccess {
  type: typeof CREATE_USER_SUCCESS
}
interface CreateUserFail {
  type: typeof CREATE_USER_FAIL
}

interface UpdateUserAttempt {
  type: typeof UPDATE_USER_ATTEMPT
}
interface UpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS
}
interface UpdateUserFail {
  type: typeof UPDATE_USER_FAIL
}

interface CreateGroupAttempt {
  type: typeof CREATE_GROUP_ATTEMPT
}

interface CreateGroupSuccess {
  type: typeof CREATE_GROUP_SUCCESS
}
interface CreateGroupFail {
  type: typeof CREATE_GROUP_FAIL
}

interface GetGroupsAttempt {
  type: typeof GET_GROUPS_ATTEMPT
}
interface GetGroupsSuccess {
  type: typeof GET_GROUPS_SUCCESS
  payload: IGroup[]
}
interface GetGroupsFail {
  type: typeof GET_GROUPS_FAIL
}

interface CreateUserGroupAttempt {
  type: typeof CREATE_USER_GROUP_ATTEMPT
}
interface CreateUserGroupSuccess {
  type: typeof CREATE_USER_GROUP_SUCCESS
}
interface CreateUserGroupFail {
  type: typeof CREATE_USER_GROUP_FAIL
}

interface DeleteUserGroupAttempt {
  type: typeof DELETE_USER_GROUP_ATTEMPT
}
interface DeleteUserGroupSuccess {
  type: typeof DELETE_USER_GROUP_SUCCESS
}
interface DeleteUserGroupFail {
  type: typeof DELETE_USER_GROUP_FAIL
}

interface UpdateGroupAttempt {
  type: typeof UPDATE_GROUP_ATTEMPT
}
interface UpdateGroupSuccess {
  type: typeof UPDATE_GROUP_SUCCESS
}
interface UpdateGroupFail {
  type: typeof UPDATE_GROUP_FAIL
}

interface UpdatePasswordAttempt {
  type: typeof UPDATE_PASSWORD_ATTEMPT
}
interface UpdatePasswordSuccess {
  type: typeof UPDATE_PASSWORD_SUCCESS
}
interface UpdatePasswordFail {
  type: typeof UPDATE_PASSWORD_FAIL
}

interface OktaSessionCreateAttempt {
  type: typeof OKTA_SESSION_CREATE_ATTEMPT
}

interface OktaSessionCreateSuccess {
  type: typeof OKTA_SESSION_CREATE_SUCCESS
}

export type SessionActionTypes =
  | SessionCreateAttempt
  | SessionCreateSuccess
  | SessionCreateFail
  | SessionRecoverAttempt
  | SessionRecoverSuccess
  | SessionRecoverFail
  | SessionDestroyAttempt
  | SessionDestroySuccess
  | GetUsersAttempt
  | GetUsersSuccess
  | GetUsersFail
  | DeleteUserAttempt
  | DeleteUserSuccess
  | DeleteUserFail
  | CreateUserAttempt
  | CreateUserSuccess
  | CreateUserFail
  | UpdateUserAttempt
  | UpdateUserSuccess
  | UpdateUserFail
  | CreateGroupAttempt
  | CreateGroupSuccess
  | CreateGroupFail
  | GetGroupsAttempt
  | GetGroupsSuccess
  | GetGroupsFail
  | CreateUserGroupAttempt
  | CreateUserGroupSuccess
  | CreateUserGroupFail
  | DeleteUserGroupAttempt
  | DeleteUserGroupSuccess
  | DeleteUserGroupFail
  | UpdateGroupAttempt
  | UpdateGroupSuccess
  | UpdateGroupFail
  | UpdatePasswordAttempt
  | UpdatePasswordSuccess
  | UpdatePasswordFail
  | SessionEndAttempt
  | SessionEndSuccess
  | SessionEndFail
  | OktaSessionCreateAttempt
  | OktaSessionCreateSuccess
