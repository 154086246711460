import readXlsxFile from "read-excel-file";
import moment from "moment";
import * as _ from "lodash";
import { DEFAULT_DATE_FORMAT } from './constants'

export const getVersion = async(file) => {
    let version = 1
    await readXlsxFile(file, { sheet: "options" }).then(rows => {
        if(rows[0] && rows[0][0] === "use point calculation"){
            version = 1
        }
        if(!rows[3]) {
            version = 2
        }
    })
    return version
}

export const uploadSPOConfig = async (file) => {
    console.log("VERSION 1")
    const defaultSheets = ["data_parent", "spend", "options"];
    let config = {
        file_name: "unknown",
        name: "unknown",
        observations: [],
        transformations: [],
        grouping_levels: [],
        max_constraints: null,
        constraints: [],
        thresholds: null,
        options: {
            use_point_calculation: false,
            use_shuffle: false,
            in_channel: false,
            carryoverWeeks: 0,
            increment: 10000,
            floodAmount: 5000000,
            exchangeRates: { defaultCurrency: "GBP", defaultSymbol: "£", currencies: [], rates: [], currencySymbols: [] },
            kpiRanges: [],
            optimisableKPIs: [],
            kpiDisplay: {
                kpi: [],
                function: [],
                parameters: []
            },
            imr: [],
            version: 1
        }
    };
    let view_settings = {
        region_name: "Region",
        region_plural: "Regions",
        brand_name: "Brand",
        brand_plural: "Brands",
        campaign_name: "Campaign",
        campaign_plural: "Campaigns",
        media_name: "Channel",
        media_plural: "Channels",
        hide_views: {
            region: false,
            brand: false,
            campaign: false,
            media: false,
            time: false
        },
        region_type: "map",
        high_level_impacts: {
            display_impacts: []
        },
        number_format: "default",
        currency_symbol: "£"
        // kpi_groups: {}
    };
    let error = "";
    let observations = [];
    let sheetValues = { spend: [] };

    await readXlsxFile(file, { getSheets: true }).then(sheets => {
        sheets.map(x => x.name).forEach(sheetName => {
            if (defaultSheets.indexOf(sheetName) === -1) {
                sheetValues[sheetName] = [];
                return readXlsxFile(file, { sheet: sheetName }).then(rows => {
                    return rows.forEach(row => {
                        sheetValues[sheetName].push(row);
                    });
                });
            }
        });
    });
    console.log(sheetValues)

    await readXlsxFile(file, { sheet: "options" }).then(rows => {
        console.log(`options sheet uploading`);

        const use_point_calculation = rows.find(
            x => x[0] === "use point calculation"
        );
        if (use_point_calculation) {
            if (use_point_calculation[1] === 1) {
                config.options.use_point_calculation = true;
            } else {
                config.options.use_point_calculation = false;
            }
        }

        const use_shuffle = rows.find(x => x[0] === "use global optimisation");
        if (use_shuffle) {
            if (use_shuffle[1] === 1) {
                config.options.use_shuffle = true;
            } else {
                config.options.use_shuffle = false;
            }
        }

        const in_channel = rows.find(x => x[0] === "in channel");
        if (in_channel) {
            if (in_channel[1] === 1) {
                config.options.in_channel = true;
            } else {
                config.options.in_channel = false;
            }
        }

        const exchange_rates = rows.find(x => x[0] === "exchange rates");
        if (exchange_rates) {
            config.options.exchangeRates.defaultCurrency = exchange_rates[4];
            config.options.exchangeRates.defaultSymbol = exchange_rates[5];
            view_settings.currency_symbol = exchange_rates[5];
            config.options.exchangeRates.currencies = exchange_rates[1].split(
                ","
            );
            config.options.exchangeRates.currencySymbols = exchange_rates[3].split(
                ","
            );
            config.options.exchangeRates.rates = exchange_rates[2]
                .toString()
                .split(",")
                .map(rate => {
                    const parsed = parseFloat(rate);
                    return parsed;
                });
        }

        const floodAmount = rows.find(x => x[0] === "flood amount");
        if (floodAmount) {
            config.options.floodAmount = floodAmount[1];
        }

        const version = rows.find(
            x => x[0] === "version"
        );
        if (version) {
            config.options.version = version[1];
        }

        const increment = rows.find(x => x[0] === "increment");
        if (increment) {
            config.options.increment = increment[1];
        }

        const carryoverWeeks = rows.find(x => x[0] === "carryover weeks");
        if (carryoverWeeks) {
            config.options.carryoverWeeks = carryoverWeeks[1];
        }

        rows.map(x => {
            if (typeof x[0] !== "string") {
                console.log(
                    "Try reordering your sheets to be data_parent, data_child, YOUR INPUT NAME SHEETS IN ORDER, options, view_settings, kpi_formulae(OPTIONAL)"
                );
                console.log(
                    "If this problem persists, please try and copy your sheets and data into a new excel sheet and then reupload"
                );
            }
        });
        const kpiRow = rows.find(x => x[0].split(",")[0] === "KPIs");

        if (kpiRow) {
            const splitKpis = kpiRow[0].split(",");
            //let i start at 1 here to miss off the KPIs string

            for (let i = 1; i < splitKpis.length; i++) {
                const kpis = {
                    kpi: splitKpis[i],
                    min: kpiRow[1].split(",").map(rate => {
                        const parsed = parseFloat(rate);
                        return parsed;
                    })[i - 1],
                    max: kpiRow[2].split(",").map(rate => {
                        const parsed = parseFloat(rate);
                        return parsed;
                    })[i - 1]
                };
                config.options.kpiRanges.push(kpis);
            }
        }

        const optimisableKPIs = rows.find(x => x[0] === "optimisable KPIs");
        if (optimisableKPIs) {
            const KPIs = optimisableKPIs[1].split(",");
            config.options.optimisableKPIs = KPIs;
        }
        console.log(`options sheet loaded successfully`);

        return;
    });

    if (Object.keys(sheetValues).indexOf("kpi_formulae") !== -1) {
        await readXlsxFile(file, { sheet: "kpi_formulae" }).then(rows => {
            console.log(`kpi_formulae sheet uploading`);

            const kpis = rows.find(x => x[0] === "kpi");
            if (kpis) {
                for (let i = 1; i < kpis.length; i++) {
                    config.options.kpiDisplay.kpi = [
                        ...config.options.kpiDisplay.kpi,
                        kpis[i]
                    ];
                }
            }

            const functions = rows.find(x => x[0] === "function");
            if (functions) {
                for (let i = 1; i < functions.length; i++) {
                    config.options.kpiDisplay.function = [
                        ...config.options.kpiDisplay.function,
                        functions[i]
                    ];
                }
            }

            const params = rows.find(x => x[0] === "parameters");
            if (params) {
                for (let i = 1; i < params.length; i++) {
                    let pars = params[i].split(",");
                    config.options.kpiDisplay.parameters = [
                        ...config.options.kpiDisplay.parameters,
                        pars
                    ];
                }
            }
        });
        console.log(`kpi_formulae sheet loaded successfully`);
    }

    if (Object.keys(sheetValues).indexOf("imr") !== -1) {
        console.log(`imr sheet uploading`);

        await readXlsxFile(file, { sheet: "imr" }).then(rows => {
            for (let row of rows) {
                config.options.imr = [...config.options.imr, row];
            }
            console.log(`imr sheet loaded successfully`);
        });
    }

    await readXlsxFile(file, { sheet: "spend" }).then(rows => {
        console.log(`spend sheet uploading`);

        rows[0].shift();

        observations = rows[0].map(date => createObservation(date));
        config.observations = observations;
        sheetValues.spend = [];
        console.log(`spend sheet loaded successfully`);

        return rows.forEach(row => {
            sheetValues.spend.push(row);
        });
    });

    await readXlsxFile(file, { sheet: "view_settings" }).then(rows => {
        console.log(`view_settings sheet uploading`);

        const regionName = rows.find(x => x[0] === "region display name");
        if (regionName) {
            view_settings.region_name = regionName[1];
            view_settings.region_plural = regionName[2];
        }
        const brandName = rows.find(x => x[0] === "brand display name");
        if (brandName) {
            view_settings.brand_name = brandName[1];
            view_settings.brand_plural = brandName[2];
        }
        const campaignName = rows.find(x => x[0] === "campaign display name");
        if (campaignName) {
            view_settings.campaign_name = campaignName[1];
            view_settings.campaign_plural = campaignName[2];
        }
        const mediaName = rows.find(x => x[0] === "media display name");
        if (mediaName) {
            view_settings.media_name = mediaName[1];
            view_settings.media_plural = mediaName[2];
        }
        const hideViews = rows.find(x => x[0] === "hide views");
        if (hideViews && hideViews[1]) {
            const viewsToHide = hideViews[1].split(",");
            for (let i = 0; i < viewsToHide.length; i++) {
                view_settings.hide_views[viewsToHide[i]] = true;
            }
        }

        const highLevelImpacts = rows.find(x => x[0] === "high level impacts");
        if (highLevelImpacts && highLevelImpacts[1]) {
            const impacts = highLevelImpacts[1].split(",");
            view_settings.high_level_impacts.display_impacts = impacts;
        }

        const regionType = rows.find(x => x[0] === "region type");
        if (regionType && regionType[1]) {
            view_settings.region_type = regionType[1];
        }

        const numberFormat = rows.find(x => x[0] === "number format");
        if (numberFormat && numberFormat[1]) {
            view_settings.number_format = numberFormat[1];
        }

        // const kpiGroups = rows.find(x => x[0] === "kpi groups");
        // if (kpiGroups && kpiGroups[1]) {
        //     for (let i = 1; i < kpiGroups.length; i++) {
        //         const split = kpiGroups[i].split(",");
        //         const label = split[0].toLowerCase();
        //         view_settings.kpi_groups[label] = split.slice(1, split.length);
        //     }
        // }
        console.log(`view_settings sheet loaded successfully`);
    });

    

    if (Object.keys(sheetValues).indexOf("max_constraints") !== -1) {
        console.log(`max constraints sheet uploading`);

        const max_constraints = {
            ids: [],
            values: []
        };

        await readXlsxFile(file, { sheet: "max_constraints" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                max_constraints.ids = [...max_constraints.ids, row[0]];
                max_constraints.values = [...max_constraints.values, row[1]];
            }
            config.max_constraints = max_constraints;
            console.log(`max_constraints sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("constraints_region") !== -1) {
        console.log(`constraints_region sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_region" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                if(row[1].toLowerCase().replace(/ /g, "_") !== "range"){
                    const constraint = {
                        fn: row[1].toLowerCase().replace(/ /g, "_"),
                        identifiers: {
                            region:
                                row[0].toLowerCase().replace(/ /g, "_"),
                        },
                        values: [row[2]],
                        variable: "spend"
                    };
                    config.constraints = [...config.constraints, constraint];
                } else {
                    const maxConstraint = {
                        fn: "max",
                        identifiers: {
                            region:
                                row[0].toLowerCase().replace(/ /g, "_"),
                        },
                        values: [row[3]],
                        variable: "spend"
                    };

                    const minConstraint = {
                        ...maxConstraint,
                        fn: "min",
                        values: [row[2]]
                    }
                    config.constraints = [...config.constraints, minConstraint, maxConstraint];

                }
            }
            console.log(`constraints_region sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("constraints_brand") !== -1) {
        console.log(`constraints_brand sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_brand" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                if(row[2].toLowerCase().replace(/ /g, "_") !== "range"){
                    const constraint = {
                        fn: row[2].toLowerCase().replace(/ /g, "_"),
                        identifiers: {
                            region:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            brand: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[3]],
                        variable: "spend"
                    };
                    config.constraints = [...config.constraints, constraint];
                } else {
                    const maxConstraint = {
                        fn: "max",
                        identifiers: {
                            region:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            brand: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[4]],
                        variable: "spend"
                    };

                    const minConstraint = {
                        ...maxConstraint,
                        fn: "min",
                        values: [row[3]]
                    }
                    config.constraints = [...config.constraints, minConstraint, maxConstraint];

                }
            }
            console.log(`constraints_brand sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("constraints_campaign") !== -1) {
        console.log(`constraints_campaign sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_campaign" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                if(row[2].toLowerCase().replace(/ /g, "_") !== "range"){
                    const constraint = {
                        fn: row[2].toLowerCase().replace(/ /g, "_"),
                        identifiers: {
                            brand:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            campaign: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[3]],
                        variable: "spend"
                    };
                    config.constraints = [...config.constraints, constraint];
                } else {
                    const maxConstraint = {
                        fn: "max",
                        identifiers: {
                            brand:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            campaign: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[4]],
                        variable: "spend"
                    };

                    const minConstraint = {
                        ...maxConstraint,
                        fn: "min",
                        values: [row[3]]
                    }
                    config.constraints = [...config.constraints, minConstraint, maxConstraint];

                }
            }
            console.log(`constraints_campaign sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("constraints_media") !== -1) {
        console.log(`constraints_media sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_media" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                if(row[2].toLowerCase().replace(/ /g, "_") !== "range"){
                    const constraint = {
                        fn: row[2].toLowerCase().replace(/ /g, "_"),
                        identifiers: {
                            campaign:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            media: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[3]],
                        variable: "spend"
                    };
                    config.constraints = [...config.constraints, constraint];
                } else {
                    const maxConstraint = {
                        fn: "max",
                        identifiers: {
                            campaign:
                                row[0].toLowerCase().replace(/ /g, "_"),
                            media: row[1].toLowerCase().replace(/ /g, "_")
                        },
                        values: [row[4]],
                        variable: "spend"
                    };

                    const minConstraint = {
                        ...maxConstraint,
                        fn: "min",
                        values: [row[3]]
                    }
                    config.constraints = [...config.constraints, minConstraint, maxConstraint];

                }
            }
            console.log(`constraints_media sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("constraints_weekly") !== -1) {
        console.log(`constraints_weekly sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_weekly" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                let vals = [null];
                for (let i = 5; i < row.length; i++) {
                    vals = [...vals, row[i]]
                }
                const constraint = {
                    fn: row[4].toLowerCase().replace(/ /g, "_"),
                    identifiers: {
                        region:
                            row[0].toLowerCase().replace(/ /g, "_"),
                        brand: row[1].toLowerCase().replace(/ /g, "_"),
                        campaign: row[2].toLowerCase().replace(/ /g, "_"),
                        media: row[3].toLowerCase().replace(/ /g, "_")
                    },
                    values: vals,
                    variable: "spend"
                };
                config.constraints = [...config.constraints, constraint];
            }
            console.log(`constraints_weekly sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("thresholds") !== -1) {
        console.log(`thresholds sheet uploading`);

        const thresholds = {
            ids: [],
            weeklyValues: [],
            totalValues: []
        };

        await readXlsxFile(file, { sheet: "thresholds" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                thresholds.ids = [...thresholds.ids, row[0]];
                thresholds.weeklyValues = [...thresholds.weeklyValues, row[1]];
                thresholds.totalValues = [...thresholds.totalValues, row[2]];
            }
            config.thresholds = thresholds;
            console.log(`thresholds sheet loaded successfully`);
        });
    }

    await readXlsxFile(file, {
        sheet: "data_parent"
    }).then(
        // create identifiers
        //

        rows => {
            console.log(`data_parent sheet uploading`);

            const headingRow = rows.shift();
            const headerMap = XLSXMapper(headingRow);

            let { groupingLevels, identifiers } = createGroupingLevels(
                rows,
                headingRow,
                headerMap
            );

            config.grouping_levels = groupingLevels;
            rows.forEach(row => {
                let parsedRow = parseRow(
                    row,
                    identifiers,
                    headerMap,
                    sheetValues,
                    observations,
                    config.options
                );
                config.transformations.push(parsedRow);
                console.log(
                    `Successfully added row with grouping ID ${row[0]}`
                );
            });
            console.log(`data_parent sheet loaded successfully`);
        }
    );

    if (Object.keys(sheetValues).indexOf("data_child") !== -1) {
        await readXlsxFile(file, { sheet: "data_child" }).then(rows => {
            console.log(`data_child sheet uploading`);

            const headingRow = rows.shift();
            const headerMap = XLSXMapper(headingRow);

            let { _, identifiers } = createGroupingLevels(
                rows,
                headingRow,
                headerMap
            );
            console.log(_);

            rows.forEach(row => {
                let parsedRow = parseRow(
                    row,
                    identifiers,
                    headerMap,
                    sheetValues,
                    observations,
                    config.options
                );

                // transformations[row[headerMap.groupingID]].push(parsedRow);
                parsedRow.is_halo = true;
                config.transformations.push(parsedRow);
            });
            // config.transformations = transformations;
            console.log(`data_child sheet loaded successfully`);
        });
    }

    config.grouping_levels.shift();
    const output = {
        config: config,
        view_settings: view_settings,
        error: error
    };

    errorCheck(output);

    if(output.config.options.in_channel){
        let settings = {}
        for(let lev of output.config.grouping_levels){
            const singular = output.view_settings[lev.key + "_name"]
            const plural = output.view_settings[lev.key + "_plural"]
            settings[lev.key] = {
                singular: singular,
                plural: plural
            }
            lev.label = singular
        }
        output.view_settings = settings
        output.config.options.carryoverWeeks = 0
    }


    return output;
}

const parseRow = (
    row,
    identifiers,
    headerMapper,
    sheetValues,
    observations,
    options
) => {
    console.log(`Attempting to parse row with grouping ID ${row[0]}`);

    let transform = { io: [] };
    let identifier = {};
    identifiers.forEach((id, index) => {
        identifier[id] = formatValue(row[index + headerMapper.regionKeyIndex]);
    });

    let io = row[headerMapper.inputKeyIndex].split(",").map((input, index) => {
        if (
            row[headerMapper.inputLabelIndex] === null ||
            row[headerMapper.inputUnitIndex] === null
        ) {
            console.log(
                "Ensure that input labels and units are not empty cells. If there are no input units the cell must still have a comma inside"
            );
        }
        const label = row[headerMapper.inputLabelIndex].split(",")[index];
        const unit = row[headerMapper.inputUnitIndex].split(",")[index];

        let visible = true;
        if (row[headerMapper.invisibleIndex]) {
            if (
                row[headerMapper.invisibleIndex].split(",").indexOf(input) > -1
            ) {
                visible = false;
            }
        }
        let editable = false;
        if (
            row[headerMapper.editableIndex] &&
            row[headerMapper.editableIndex].split(",").indexOf(input) > -1
        ) {
            editable = true;
        }

        let currency = options.exchangeRates.defaultCurrency;
        if (row[headerMapper.currencyIndex]) {
            currency = row[headerMapper.currencyIndex];
        }

        const dataRow = row[headerMapper.inputReferenceIndex].length ? row[headerMapper.inputReferenceIndex].split(",")[index] : row[headerMapper.inputReferenceIndex];

        if (!sheetValues[input]) {
            console.log(
                `Please make sure there are no spaces after the commas in row ${row[0]
                }`
            );
        }

        let ioValues = sheetValues[input][Number(dataRow)];
        if (!ioValues) {
            console.log(
                `Please check that the ${input} Input Reference for row ${row[0]
                } exists in the ${input} sheet.`
            );
        }
        ioValues = ioValues.slice();
        ioValues.shift();

        //if currency isnt the default, divide it by the appropriate exchange rate
        if (currency !== options.exchangeRates.defaultCurrency) {
            const index = options.exchangeRates.currencies.indexOf(currency);
            for (let i in ioValues) {
                if (unit === "currency")
                    ioValues[i] =
                        ioValues[i] / options.exchangeRates.rates[index];
            }
        }

        return {
            type: "input",
            key: input,
            label: label,
            unit: unit,
            output_type: null,
            visible: visible,
            editable: editable,
            values: ioValues
        };
    });

    let outputs = row[headerMapper.outputKeyIndex]
        .split(",")
        .map((output, index) => {
            if (
                row[headerMapper.outputLabelIndex] === null ||
                row[headerMapper.outputUnitIndex] === null ||
                row[headerMapper.outputTypeIndex] === null
            ) {
                console.log(
                    "Ensure that output labels, units and types are not empty cells. If there are no output units the cell must still have a comma inside"
                );
            }
            const label = row[headerMapper.outputLabelIndex].split(",")[index];
            const unit = row[headerMapper.outputUnitIndex].split(",")[index];
            const type = headerMapper.outputTypeIndex !== -1 ? row[headerMapper.outputTypeIndex].split(",")[index] : null;

            return {
                type: "output",
                key: output,
                label: label,
                unit: unit,
                output_type: type,
                visible: true,
                editable: false,
                values: new Array(observations.length).fill(0)
            };
        });

    transform.io = [...io, ...outputs];
    transform.identifiers = identifier;
    transform.fn = formatValue(row[headerMapper.functionIndex]);
    transform.grouping_id = row[headerMapper.groupingID];

    transform.params = [];
    transform.output_parameters = headerMapper.outputTypeIndex !== -1 ? JSON.parse(row[headerMapper.editableIndex + 1]) : []
    let paramIndex = headerMapper.outputTypeIndex !== -1  ? headerMapper.editableIndex + 2 : headerMapper.editableIndex + 1;
    for (let i = paramIndex; i < row.length; i++) {
        if (row[i] || row[i] === 0) {
            transform.params.push(row[i]);
        }
    }

    console.log(`Successfully parsed row with grouping ID ${row[0]}`);

    return transform;
};


const createGroupingLevels = (rows, headingRow, headerMap) => {
    let identifiers = [];
    let groupingLevels = [];
    for (let i = headerMap.regionKeyIndex; i < headerMap.currencyIndex; i++) {
        let key = headingRow[i]
            .toLowerCase()
            .split(" ")
            .join("_");

        identifiers.push(key);

        let groupingLevel = {
            key: key,
            label: headingRow[i],
            values: []
        };

        let values = [];
        rows.forEach(row => {
            let value = {
                key: row[i]
                    .toLowerCase()
                    .split(" ")
                    .join("_"),
                label: row[i]
            };

            let found = values.map(val => val.label).indexOf(row[i]);

            if (found === -1) {
                values.push(value);
            }

            if (key === "region") {
                value.ISO_A2 = row[headerMap.regionKeyIndex].toLowerCase();
                value.region_group = row[headerMap.regionGroup];
            }
            // groupingLevel.values.push(value);
        });

        groupingLevel.values = values;
        groupingLevels.push(groupingLevel);
    }

    return { groupingLevels, identifiers };
};



function formatValue(value) {
    return value
        .toLowerCase()
        .split(" ")
        .join("_");
}

export function createObservation(date) {
    let excelDateToJsDate = new Date(Math.round((date - 25569) * 86400 * 1000));
    if (!isValidDate(excelDateToJsDate)) {
        excelDateToJsDate = date;
    }

    return {
        key: moment.utc(excelDateToJsDate).format(DEFAULT_DATE_FORMAT.KEY),
        label: moment.utc(excelDateToJsDate).format(DEFAULT_DATE_FORMAT.LABEL)
    };
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function errorCheck(output) {
    const identifiers = output.config.transformations.map(x =>
        JSON.stringify(Object.values(x.identifiers))
    );
    const uniqueIdentifiers = _.uniqBy(identifiers, function (e) {
        return e;
    });

    if (identifiers.length !== uniqueIdentifiers.length) {
        output.error =
            "In the data parent sheet, two rows cannot have the same identifiers. Please ensure each row has unique identifiers.";
    }

    console.log(output);
}


const XLSXMapper = headingRow => {
    return {
        groupingID: headingRow.indexOf("Grouping ID"),
        regionGroup: headingRow.indexOf("Region Group"),
        regionKeyIndex: headingRow.indexOf("Region Key"),
        regionIndex: headingRow.indexOf("Region"),
        currencyIndex: headingRow.indexOf("Currency"),
        functionIndex: headingRow.indexOf("Function"),
        parametersIndex: headingRow.indexOf("Parameters"),
        inputKeyIndex: headingRow.indexOf("Input Key"),
        inputLabelIndex: headingRow.indexOf("Input Label"),
        inputUnitIndex: headingRow.indexOf("Input Unit"),
        inputReferenceIndex: headingRow.indexOf("Input Reference"),
        outputKeyIndex: headingRow.indexOf("Output Key"),
        outputLabelIndex: headingRow.indexOf("Output Label"),
        outputUnitIndex: headingRow.indexOf("Output Unit"),
        outputTypeIndex: headingRow.indexOf("Output Type"),
        invisibleIndex: headingRow.indexOf("Invisible"),
        editableIndex: headingRow.indexOf("Editable"),
        isHaloIndex: headingRow.indexOf("IsHalo")
    };
};

