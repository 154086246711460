import { Children, useEffect, useState } from "react";
import ChevronRightDefaultIcon from "../../../../../icons/ChevronRightDefaultIcon";
import { Channel, ChannelKey, ChartDataMD, KPI } from "../../../../../types/MarketingDiagnosticsTypes";
import { calculateERRN, determineERRN } from "./errn-utils";
import { getPrevDifference } from "./get-prev-difference";
import { LoadingRows } from "./loading-rows";
import { ChannelLabel } from "./channel-label";
import { KpiCells } from "./kpi-cells";
import { UpDownIndicator } from "./up-down-indicator";
import api from "../../../../../api";
import { DoubleDrilldown } from "../double-drilldown";

type TDrilldown = {
  tabs: any;
  tabData: any;
  defaultHeaders: any;
  previous: any;
  getDrilldownPerformanceArgs: any;
  selectedKpi: string;
  settings: any;
  marketingDiagnostics: any;
  kpis: KPI[];
}
export const Drilldown = ({
  tabs = {},
  tabData,
  defaultHeaders,
  previous,
  getDrilldownPerformanceArgs,
  marketingDiagnostics,
  selectedKpi,
  settings,
  kpis
}: TDrilldown) => {
  const [activeTab, setActiveTab] = useState(0)
  const [rows, setRows] = useState<Channel[]>([])
  const [viewHeaders, setViewHeaders] = useState<string[]>([])
  const [sortBy, setSortBy] = useState<ChannelKey>("spend")
  const [ascOrDesc, setAscOrDesc] = useState("desc")
  const [sortedCurrentData, setSortedCurrentData] = useState([]);
  const [chartDrillData, setChartDrillData] = useState<ChartDataMD[] | null>(null)

  useEffect(() => {
    const flexTab: any = Object.values(tabs)[activeTab];
    if (flexTab) {
      const viewHeadersHolder: string[] = [flexTab, ...defaultHeaders]
      const rowsHolder: any = tabData[activeTab]
        ? tabData[activeTab].data
        : [];
      setViewHeaders(viewHeadersHolder)
      setRows(rowsHolder)
    }
  }, [activeTab, tabs, tabData[activeTab]?.data]);

  useEffect(() => {
    if (viewHeaders.length && rows.length) {
      const rowsNoType: any = rows
      setSortedCurrentData(ascOrDesc === "desc"
        ? rowsNoType.sort(
          (a: any, b: any) =>
            parseFloat(b[sortBy]) -
            parseFloat(a[sortBy])
        )
        : rowsNoType.sort(
          (a: any, b: any) =>
            parseFloat(a[sortBy]) -
            parseFloat(b[sortBy])
        ));
    }
  }, [ascOrDesc, viewHeaders, rows])

  const toggle = (tab: number) => {
    setActiveTab(tab)
  };

  const changeSort = (value: string) => () => {
    let sort = value.toLowerCase().replace(/ /g, "_");

    if (
      sort === viewHeaders[3].toLowerCase().replace(/ /g, "_")
    ) {
      sort = "kpi";
    }
    let ascOrDescLocal = "";
    if (ascOrDesc === "desc") {
      if (sort === sortBy) {
        ascOrDescLocal = "asc";
      } else {
        ascOrDescLocal = "desc";
      }
    } else {
      ascOrDescLocal = "desc";
    }
    setSortBy(sort as ChannelKey)
    setAscOrDesc(ascOrDescLocal)
  }

  const average = calculateERRN(rows);

  const getChannelData = (channel: Channel) => {
    let activeERRN = determineERRN(
      channel,
      average
    );
    const p =
      previous.length > 0
        ? previous.find(
          (prev: Channel) =>
            prev.label ===
            viewHeaders[0]
        )
        : null;

    const previousHolder = p
      ? p.data.find(
        (prev: Channel) => prev.label === channel.label
      )
      : null;

    const prevData = previousHolder
      ? getPrevDifference(channel, previousHolder)
      : null;

    const effectPerformance = prevData
      ? prevData.effectiveness < 0
        ? "is lower"
        : prevData.effectiveness > 0
          ? "is higher"
          : "remained stable"
      : "";

    const productPerformance = prevData
      ? prevData.productivity < 0
        ? "is lower"
        : prevData.productivity > 0
          ? "is higher"
          : "remained stable"
      : "";

    const label = channel.label
      ? channel.label.length > 20
        ? channel.label.substring(0, 20) + "..."
        : channel.label
      : "";

    return {
      activeERRN,
      previousHolder,
      prevData,
      effectPerformance,
      productPerformance,
      label,
    }
  }

  const getDrilldownChartData = (channel: Channel) => {
    setChartDrillData(null);
    if (channel.isOpen) {
      return;
    }
    const openFlexIndex = Object.values(tabs).indexOf(
      viewHeaders[0]
    );

    const openFlexName = Object.keys(tabs)[openFlexIndex];
    const flexName = openFlexName.split("_")[0] + "_value";
    const flexVal: string = channel.label;
    const {
      channelDropdown,
      kpiDropdown,
      phaseId,
      brandDropdown
    } = getDrilldownPerformanceArgs();
    api.PerformanceOverview.getDrilldownPerformance(
      channelDropdown,
      kpiDropdown,
      phaseId,
      brandDropdown,
      flexName,
      flexVal
    ).then(({ data: result }: any) => {
      setChartDrillData(result.chartDrillData)
    });
  }
  const toggleBestWorst = (rowIndex: number, channel: Channel) => {

    getDrilldownChartData(channel);
    const newRows = rows.map(
      (item: any, index: number) =>
        rowIndex === index
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
    );
    setRows(newRows)
  };

  const showLoadingRows = (!sortedCurrentData || sortedCurrentData.length === 0);
  return (
    <div className="grid gap-3">
      {showLoadingRows ? (
        <div className="w-1/3 h-10 rounded-md animate-pulse bg-gray-200" />
      ) : (
        <h2 className='text-gtDarkGray'>
          Performance Drilldown
        </h2>
      )}
      <div className="grid grid-flow-col gap-1">
        {!showLoadingRows &&
          Children.toArray(Object.values(tabs).map((tab, index) => (
            <div onClick={() => toggle(index)}
              className={`text-md text-center py-2 px-4 border-0 border-b-2 border-transparent transition-colors  cursor-pointer ${activeTab === index ? "text-black border-gtPink" : "text-gtPink"}`}
            >
              <>{tab}</>
            </div>
          )))}
      </div>
      <div className={`grid grid-flow-col gap-1 auto-cols-fr place-items-center border-b ${showLoadingRows ? "border-transparent" : "border-gray-300"} pb-3`}>
        {showLoadingRows ? <LoadingRows rows={5} height={8} /> :
          Children.toArray(viewHeaders.map((head: string, index: number) => index !== 0 ? (
            <button
              className="grid grid-flow-col place-items-center uppercase text-xs text-gray-400 w-auto"
              onClick={changeSort(head)}>
              {head}
              {(head.toLowerCase().replace(/ /g, "_") === (sortBy === 'kpi' ? 'volume' : sortBy)) && <ChevronRightDefaultIcon className={`w-5 h-5 ${ascOrDesc === 'desc' ? "" : "-"}rotate-90 transform duration-300`} />}
            </button>
          ) : (
            <span className="text-xs text-center uppercase text-gray-400">{head}</span>
          )))}
      </div>
      <div className="grid gap-3">
        {showLoadingRows ? <LoadingRows chartsAfterFirst /> :
          Children.toArray(sortedCurrentData.map((channel: Channel, index: number) => {
            const {
              activeERRN,
              previousHolder,
              prevData,
              effectPerformance,
              productPerformance,
              label,
            } = getChannelData(channel);
            return (
              <>
                <div className={`grid grid-flow-col auto-cols-fr place-items-center items-center bg-white rounded border py-5 ${channel.isOpen ? "border-gtPink border-b-0 rounded-b-none" : "border-gray-200 "}`}>
                  <ChannelLabel label={label} isOpen={channel.isOpen} onClick={() => toggleBestWorst(index, channel)} />
                  <KpiCells
                    previous={previous}
                    prevData={prevData}
                    current={channel}
                    cells={["spend", "impressions", "kpi"]} />
                  <UpDownIndicator
                    indicatorKey="effectiveness"
                    channel={channel}
                    metric={effectPerformance}
                    previous={previous}
                    previousHolder={previousHolder} />
                  <UpDownIndicator
                    indicatorKey="productivity"
                    channel={channel}
                    metric={productPerformance}
                    previous={previous}
                    previousHolder={previousHolder} />
                </div>
                {channel.isOpen && (
                  <DoubleDrilldown
                    chartDrillData={chartDrillData || []}
                    defaultHeaders={defaultHeaders}
                    marketingDiagnostics={marketingDiagnostics}
                    selectedKpi={selectedKpi}
                    settings={settings}
                    channel={channel}
                    activeERRN={activeERRN}
                    kpis={kpis}
                  />
                )}
              </>
            );
          }))}
      </div>
    </div >
  );
};
