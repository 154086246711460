import { Card } from '@gain-theory/card'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { memo } from 'react'
import { CanWeHelpCardProps } from './types'

export const CanWeHelpCard = memo(
  ({ id, title, description, iconProps, onClick }: CanWeHelpCardProps) => {
    const { name, library = 'heroicon-outline' } = iconProps

    return (
      <Card
        data-testid={id ? `${id}-can-we-help-card` : `can-we-help-card`}
        onClick={onClick}
        className="h-full cursor-pointer"
      >
        <div className="grid grid-flow-row text-center gap-md items-center justify-items-center h-full select-none">
          <Icon
            name={name}
            library={library}
            color="brand-primary-main"
            size="xxxxl"
            style={{ strokeWidth: 1 }}
          />
          <Typography.H6>{title}</Typography.H6>
          <Typography.Body2>{description}</Typography.Body2>
        </div>
      </Card>
    )
  }
)
