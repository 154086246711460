import { Dispatch } from 'redux'
import {
  PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT,
  PERFORMANCE_OVERVIEW_GET_DATA_FAIL,
  PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS,
  PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS,
  PerformanceOverviewActionTypes,
} from './PerformanceOverviewActionTypes'
import api from '../../api'
import { KPI, KpiResponse } from '../../types/MarketingDiagnosticsTypes'

export const GetPerformanceOverview =
  (brands: string, selectedPhases: string[]) =>
  async (dispatch: Dispatch<PerformanceOverviewActionTypes>) => {
    dispatch({ type: PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT })
    try {
      const mdResponse =
        await api.MarketingDiagnostics.getMarketingDiagnostics()
      const systemLinksResponse =
        await api.MarketingDiagnostics.getSystemLinks()
      const kpiResponse = await api.PerformanceOverview.getKpis()

      const kpisResponse: KpiResponse = kpiResponse.data
      const kpi = kpisResponse.kpis[0]
      const phases = kpisResponse.phases
      const currentPhase = selectedPhases[0] || phases[phases.length - 1].phase
      const prevPhase =
        selectedPhases[1] ||
        (phases.length > 1 ? phases[phases.length - 2].phase : '')

      const performanceOverviewResponse =
        await api.PerformanceOverview.getChannelDefault(
          kpi.id.toString(),
          currentPhase,
          prevPhase,
          brands
        )
      dispatch({
        type: PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS,
        payload: kpisResponse,
      })
      dispatch({
        type: PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS,
        payload: performanceOverviewResponse.data,
      })
    } catch (e) {
      dispatch({ type: PERFORMANCE_OVERVIEW_GET_DATA_FAIL })
    }
  }

  export const UpdateChannelPerformanceSelection =
  (kpi: KPI, currentPhase: string, prevPhase: string, brands: string) =>
  async (dispatch: Dispatch<PerformanceOverviewActionTypes>) => {
    dispatch({ type: PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT })
    try {

      let performanceOverviewResponse
      if(!kpi){
        const kpiResponse = await api.PerformanceOverview.getKpis()
        const kpisResponse: KpiResponse = kpiResponse.data
        const kpiFirst = kpisResponse.kpis[0]
        performanceOverviewResponse =
        await api.PerformanceOverview.getChannelDefault(
          kpiFirst.id.toString(),
          currentPhase,
          prevPhase,
          brands
        )
      } else {
        performanceOverviewResponse =
        await api.PerformanceOverview.getChannelDefault(
          kpi.id.toString(),
          currentPhase,
          prevPhase,
          brands
        )
      }

      dispatch({
        type: PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS,
        payload: performanceOverviewResponse.data,
      })
    } catch (e) {
      dispatch({ type: PERFORMANCE_OVERVIEW_GET_DATA_FAIL })
    }
  }
