import { Combobox, ComboboxProps } from '@gain-theory/combobox'
import { Input } from '@gain-theory/input'
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { ExchangeRate } from '../../../types/PlanTypes'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'

interface OwnProps {
  isEuroFormat: boolean
  selectedCurrency: string
  selectedBudget: number
  exchangeRates: ExchangeRate | undefined
  setSelectedCurrency: Dispatch<SetStateAction<string>>
  setSelectedBudget: Dispatch<SetStateAction<number>>
  setStep: Dispatch<SetStateAction<number>>
  hasCurrencySelector: boolean
  optimisationType: string
  goalLabelForKPI: string | undefined
  isBudgetConstraintEnabled: boolean
  handleGeneratePlan: () => void // TODO (TIME BUDGET CONSTRAINTS): Remove this line when time period budget constraints is deployed to develop
}

type Props = OwnProps

const PlanCurrencyAndBudgetStep: FC<Props> = ({
  isEuroFormat,
  selectedCurrency,
  selectedBudget,
  exchangeRates,
  setSelectedCurrency,
  setSelectedBudget,
  setStep,
  hasCurrencySelector,
  optimisationType,
  goalLabelForKPI,
  isBudgetConstraintEnabled,
  handleGeneratePlan, // TODO (TIME BUDGET CONSTRAINTS): Remove this line when time period budget constraints is deployed to develop
}) => {
  const isBudgetOptimisation = optimisationType === 'budget'

  const [currency, setCurrency] = useState<ComboboxProps['selectedValues']>([])
  const [budget, setBudget] = useState<string>('')

  const [options, setOptions] = useState<ComboboxProps['options']>([])

  const [currencyError, setCurrencyError] = useState<boolean>(false)
  const [budgetError, setBudgetError] = useState<boolean>(false)

  const [submit, setSubmit] = useState<boolean>(false) // TODO (TIME BUDGET CONSTRAINTS): Remove this useState when time period budget constraints is deployed to develop

  const BUDGET_MAX_DECIMAL_DIGITS = 2
  const budgetDecimalSeparator = isEuroFormat ? ',' : '.'
  const budgetThousandSeparator = isEuroFormat ? '.' : ','

  const PLAN_CURRENCY_AND_BUDGET_TEXTS = {
    PAGE_TITLE: isBudgetOptimisation
      ? 'Currency and budget'
      : `What ${goalLabelForKPI} would you like to achieve?`,
    COMBOBOX_CURRENCY_LABEL: 'Currency',
    COMBOBOX_CURRENCY_PLACEHOLDER: 'Select a currency',
    COMBOBOX_CURRENCY_ERROR_MESSAGE: 'This field is required.',
    INPUT_BUDGET_LABEL: isBudgetOptimisation ? 'Budget' : 'Amount',
    INPUT_BUDGET_PLACEHOLDER: isBudgetOptimisation
      ? 'Enter a budget'
      : 'Enter an amount',
    INPUT_BUDGET_ERROR_MESSAGE: 'This field is required.',
  }

  useEffect(() => {
    if (!submit) return

    const budgetUpdated =
      selectedBudget.toString() ===
      budget.replaceAll(budgetThousandSeparator, "")

    const currencyUpdated = selectedCurrency === currency[0]?.id

    if (budgetUpdated && currencyUpdated) {
      handleGeneratePlan()
    }

    setSubmit(false)
  }, [submit])

  useEffect(() => {
    setBudget(selectedBudget.toString())
  }, [selectedBudget])

  useEffect(() => {
    if (!exchangeRates || selectedCurrency === '') return

    const targetIndex = exchangeRates.currencySymbols.findIndex(
      (symbol) => symbol === selectedCurrency
    )

    if (targetIndex === -1) return

    const symbol = exchangeRates.currencySymbols[targetIndex]

    const mappedValue: ComboboxProps['selectedValues'][number] = {
      id: symbol,
      label: `${symbol} (${exchangeRates.currencies[targetIndex]})`,
    }

    setCurrency([mappedValue])
  }, [selectedCurrency])

  useEffect(() => {
    if (!exchangeRates) return

    const mappedOptions: ComboboxProps['options'] =
      exchangeRates.currencySymbols.map((symbol, index) => ({
        id: symbol,
        label: `${symbol} (${exchangeRates.currencies[index]})`,
      }))

    setOptions(mappedOptions)
  }, [exchangeRates])

  const handleChangeBudget = (event: ChangeEvent<HTMLInputElement>) => {
    setBudget(event.target.value)
  }

  const handleBackButton = () => {
    setCurrencyError(false)
    setBudgetError(false)
    setStep((prev) => prev - 1)
  }

  const handleNextButton = () => {
    const noCurrencySelected =
      hasCurrencySelector &&
      (!currency[0] ||
        !currency[0].label ||
        currency[0].label.trim().length === 0)
    const noBudget = !budget || budget.trim().length === 0

    if (noCurrencySelected || noBudget) {
      setCurrencyError(noCurrencySelected)
      setBudgetError(noBudget)
      return
    }

    setCurrencyError(false)
    setBudgetError(false)

    setSelectedCurrency(currency[0].id?.toString() ?? currency[0].label)

    const parsedBudget = parseFloat(
      budget.replaceAll(budgetThousandSeparator, "")
    )
    setSelectedBudget(parsedBudget)

    if (isBudgetOptimisation && isBudgetConstraintEnabled) {
      setStep((prev) => prev + 1)
      return
    }

    setSubmit(true)
  }

  const CurrencySelector = (
    <Combobox
      disallowTextManipulation
      multiple={false}
      allowNewValues={false}
      label={PLAN_CURRENCY_AND_BUDGET_TEXTS.COMBOBOX_CURRENCY_LABEL}
      placeholder={PLAN_CURRENCY_AND_BUDGET_TEXTS.COMBOBOX_CURRENCY_PLACEHOLDER}
      options={options}
      selectedValues={currency}
      setSelectedValues={setCurrency}
      state={currencyError ? 'error' : 'default'}
      helperText={
        currencyError
          ? PLAN_CURRENCY_AND_BUDGET_TEXTS.COMBOBOX_CURRENCY_ERROR_MESSAGE
          : undefined
      }
    />
  )

  const BudgetInput = (
    <Input.Number
      enableFloatNumbers
      autoComplete="off"
      min={0}
      label={PLAN_CURRENCY_AND_BUDGET_TEXTS.INPUT_BUDGET_LABEL}
      placeholder={PLAN_CURRENCY_AND_BUDGET_TEXTS.INPUT_BUDGET_PLACEHOLDER}
      value={budget}
      state={budgetError ? 'error' : 'default'}
      numberFormat={{
        decimalSeparator: budgetDecimalSeparator,
        thousandSeparator: budgetThousandSeparator,
        maxDecimalPlaces: BUDGET_MAX_DECIMAL_DIGITS,
      }}
      errorMessages={{
        default: PLAN_CURRENCY_AND_BUDGET_TEXTS.INPUT_BUDGET_ERROR_MESSAGE,
      }}
      onChange={handleChangeBudget}
    />
  )

  return (
    <CreateMarketingPlanLayout
      isLastStep={!isBudgetConstraintEnabled}
      title={PLAN_CURRENCY_AND_BUDGET_TEXTS.PAGE_TITLE}
      component={
        <div className="flex flex-col h-full gap-2 justify-center items-center w-fit min-w-64">
          {hasCurrencySelector && CurrencySelector}
          {BudgetInput}
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanCurrencyAndBudgetStep
