const getColumnLetter = (offset: number) => {
  let columnLetter = ''

  while (offset >= 0) {
    columnLetter = String.fromCharCode(65 + (offset % 26)) + columnLetter
    offset = Math.floor(offset / 26) - 1
  }

  return columnLetter
}

export default getColumnLetter
