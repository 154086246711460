import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import { NavbarMenuItem } from './types'

const bfSubmenuBase = [
  {
    label: 'Sets',
  },
  {
    label: 'Plans',
  },
] as NavbarMenuItem['submenu']

const bfMenuBase = {
  label: 'Business Forecasting',
  iconName: 'PresentationChartLineIcon',
  submenu: [],
  showBadge: false,
} as any as NavbarMenuItem

function useBfMenu() {
  const { modules } = useSelector((state: AppState) => state.session)

  const [bfMenu, setBfMenu] = useState({ ...bfMenuBase })
  const [active, setActive] = useState(false)

  useEffect(() => {
    let moduleActive = false

    if (modules && modules.length > 0) {
      const gtModule = modules.find((gtModule) => gtModule.key === 'bf')

      moduleActive = !!gtModule && gtModule.active
    }

    setActive(moduleActive)
  }, [modules])

  useEffect(() => {
    const newBfMenu = {
      ...bfMenuBase,
      disabled: !active,
      ...(active && { submenu: [...bfSubmenuBase] }),
    } as any as NavbarMenuItem

    setBfMenu(newBfMenu)
  }, [active])

  return bfMenu
}

export default useBfMenu
