import { GroupingLevel, GroupingLevelValue, Observation, Transformation } from "../types/PlanTypes"
import { COST_NAME } from "./constants"
import { sumCalculatedSpend, sumCalculatedTotals, sumOptimisedSpend, sumOptimisedTotals } from "./ObservationAndBarData"

export const createBudgetAllocations = (names: string[], levelName: string, groupingLevels: GroupingLevel[], levelTransforms: any[]) => {
    let budgetAllocations: any[] = []

    if (names) {
      for (let name of names) {
        let budget: any = {
          label: '',
          sTransformation: {
            calculated_spend: 0,
            calculated_totals: { roi: 0, sales: 0 },
            optimised_spend: 0,
            optimised_totals: { roi: 0, sales: 0 },
          },
          // @ts-ignore
          transformations: null,
        }

        // @ts-ignore
        let transformations = levelTransforms.filter(
          (transform: Transformation) =>
            transform.identifiers[levelName] === name
        )
        budget.transformations = transformations
        budget.sTransformation.optimised_spend = sumOptimisedSpend(
          transformations
        )

        budget.sTransformation.calculated_spend = sumCalculatedSpend(
          transformations
        )

        budget.sTransformation.optimised_totals = sumOptimisedTotals(
          transformations
        )

        budget.sTransformation.calculated_totals = sumCalculatedTotals(
          transformations
        )

        budget.name = name

        // Get the media name as it is in the config
        budget.label = groupingLevels
          .find((level: GroupingLevel) => level.key === levelName)!
          .values.find((val: GroupingLevelValue) => val.key === name)?.label || ""

        budgetAllocations.push(budget)
      }
    }

    return budgetAllocations
  }

  export const createCostGridData = (mediaAllocations: any, observations: Observation[]) => {
    let agGridData: any[] = []


    if(mediaAllocations.length > 0 && !mediaAllocations[0].transformations[0].calculated[COST_NAME]){
      return agGridData
    }
  
    const channelsData = getChannelsData(mediaAllocations)
    channelsData.map((channel: any) => {
      const entry: any = {
        label: channel.label,
        name: channel.name,
      }
      observations.map((obs: any, index: number) => {
        entry[obs.key] = channel.costs[index]
      })
      agGridData = [...agGridData, entry] 
    })
  
    return agGridData
  }


const getChannelsData = (mediaAllocations: any) => {
    return mediaAllocations.map((allocation: any) => {
      let costTotal = Array(allocation.transformations[0].calculated[COST_NAME].length).fill(0)
      allocation.transformations.map((trans: any) => {
        costTotal = costTotal.map((a, i) => a + trans.calculated[COST_NAME][i])
      })
      const costAverage = costTotal.map(x => x /= allocation.transformations.length)
      return {
        label: allocation.label,
        name: allocation.name,
        costs: costAverage,
      }
    })
  }