import moment from 'moment'
import { GroupingLevel, Observation } from '../../../../../../types/PlanTypes'
import { DEFAULT_DATE_FORMAT } from '../../../../../../utils/constants'
import { Errors, TimeConstraintErrorType } from '../../types'
import { TABLE_COLUMNS } from '../../utils/constants'
import getColumnLetter from '../../utils/get-column-letter'
import { Settings } from '../../../../../../types/SettingTypes'

interface IParseFileHeadersProps {
  hierarchyStartIndex: number
  lastHierarchyLevelIndex: number
  observationsStartIndex: number
  headers: string[]
  groupingLevels: GroupingLevel[]
  observations: Observation[]
  settings: Settings | null
}

export function parseFileHeaders({
  hierarchyStartIndex,
  lastHierarchyLevelIndex,
  observationsStartIndex,
  headers,
  groupingLevels,
  observations,
  settings
}: IParseFileHeadersProps) {
  const headerParsingErrors: TimeConstraintErrorType[] = []

  const parseHierarchyHeaders = (headers: string[]) => {
    const hierarchyHeaders = headers.slice(
      hierarchyStartIndex,
      lastHierarchyLevelIndex + 1
    )

    const hierarchyHeaderKeys = hierarchyHeaders.map((header) => {
      const levelKey = groupingLevels.find((level) => level.label === header)

      return levelKey?.key || ''
    })

    const foundAllHierarchyHeaders = hierarchyHeaderKeys.every(
      (key) => key !== ''
    )

    if (foundAllHierarchyHeaders) {
      return { hierarchyHeaderKeys, hierarchyParsingError: null }
    }

    const commaSeparatedHierarchy = groupingLevels
      .map((level) => level.label)
      .join(', ')

    const hierarchyParsingError: TimeConstraintErrorType = {
      errorType: Errors.HEADER_MATCHING_HIERARCHY,
      replacers: [commaSeparatedHierarchy],
    }

    return { hierarchyHeaderKeys, hierarchyParsingError }
  }

  const parseTypeHeader = (headers: string[]) => {
    const typeHeader = headers[lastHierarchyLevelIndex + 1]

    const typeHeaderNameMatch =
      typeHeader === TABLE_COLUMNS.TYPE.headerName ||
      TABLE_COLUMNS.TYPE.otherAccepetedValues.includes(typeHeader)

    if (typeHeaderNameMatch) {
      return { typeColumnParsingError: null }
    }

    const typeColumnLetter = getColumnLetter(lastHierarchyLevelIndex + 1)

    const typeColumnParsingError: TimeConstraintErrorType = {
      errorType: Errors.HEADER_MATCHING_TYPE_COLUMN,
      replacers: [typeColumnLetter, TABLE_COLUMNS.TYPE.headerName],
    }

    return { typeColumnParsingError }
  }

  const parseStatusHeader = (headers: string[]) => {
    const statusHeader = headers[lastHierarchyLevelIndex + 2]

    const statusHeaderNameMatch =
      statusHeader === TABLE_COLUMNS.STATUS.headerName

    if (statusHeaderNameMatch) {
      return { statusColumnParsingError: null }
    }

    const statusColumnLetter = getColumnLetter(lastHierarchyLevelIndex + 2)

    const statusColumnParsingError: TimeConstraintErrorType = {
      errorType: Errors.HEADER_MATCHING_STATUS_COLUMN,
      replacers: [statusColumnLetter, TABLE_COLUMNS.STATUS.headerName],
    }

    return { statusColumnParsingError }
  }

  const parseObservationHeader = (headers: string[]) => {
    const observationsParsingError: TimeConstraintErrorType[] = []
    const dateFormat = settings?.date_format || DEFAULT_DATE_FORMAT.LABEL

    const observationHeaders = headers.slice(observationsStartIndex)

    const observationsHeaderKeys = observationHeaders.map((header, index) => {
      const formattedHeader = moment(header, dateFormat)
        .format(DEFAULT_DATE_FORMAT.LABEL)

      const matchingObservationIndex = observations.findIndex(
        (observation) => observation.label === formattedHeader
      )

      if (matchingObservationIndex !== -1) {
        return observations[matchingObservationIndex].key
      }

      const currentColumnLetter = getColumnLetter(
        observationsStartIndex + index
      )

      const observationHeaderError: TimeConstraintErrorType = {
        errorType: Errors.HEADER_MATCHING_OBSERVATION_COLUMN,
        replacers: [currentColumnLetter, header],
      }

      observationsParsingError.push(observationHeaderError)

      return ''
    })

    return { observationsHeaderKeys, observationsParsingError }
  }

  const { hierarchyHeaderKeys, hierarchyParsingError } =
    parseHierarchyHeaders(headers)

  const { typeColumnParsingError } = parseTypeHeader(headers)

  const { statusColumnParsingError } = parseStatusHeader(headers)

  const { observationsHeaderKeys, observationsParsingError } =
    parseObservationHeader(headers)

  if (hierarchyParsingError) {
    headerParsingErrors.push(hierarchyParsingError)
  }

  if (typeColumnParsingError) {
    headerParsingErrors.push(typeColumnParsingError)
  }

  if (statusColumnParsingError) {
    headerParsingErrors.push(statusColumnParsingError)
  }

  if (observationsParsingError.length > 0) {
    headerParsingErrors.push(...observationsParsingError)
  }

  return { hierarchyHeaderKeys, observationsHeaderKeys, headerParsingErrors }
}
