import _ from "lodash"
import { Observation, Plan, PlanGroupingLevel, STransformation } from "../../types/PlanTypes"
import { createBudgetAllocations, createCostGridData } from "../../utils/calculateCostData"
import { MediaChannelActionTypes } from "../actions/MediaChannelActionTypes"
import { COST_EDIT_MODE, DISCARD_COST_CHANGES, PlanActionTypes, PLAN_CREATE_SUCCESS, PLAN_GET_ITEM_SUCCESS, PLAN_SELECT_LEVEL, PUT_PLAN_SUCCESS } from "../actions/PlanActionTypes"

interface IDefaultState {
  scenarioFilters: PlanGroupingLevel
  appliedFilters:any
  levelTransforms: any[]
  allTransforms: STransformation[],
  loadingBarMove: boolean
  costGridData: any[]
  costEditMode: boolean
  planItem: Plan | null
  bottomLevelKey: string
  observations: Observation[]
}

const defaultState: IDefaultState = {
  scenarioFilters: {},
  appliedFilters: {},
  levelTransforms: [],
  allTransforms: [],
  loadingBarMove: false,
  costGridData: [],
  costEditMode: false,
  planItem: null,
  bottomLevelKey: "",
  observations: [],
}

const filtersReducer = (
  state: IDefaultState = defaultState,
  action: PlanActionTypes | MediaChannelActionTypes
): IDefaultState => {
  switch (action.type) {
    case PLAN_GET_ITEM_SUCCESS || PLAN_CREATE_SUCCESS: 
    let appliedFiltersInitial = {} as any
    for(let lev of Object.keys(action.payload.grouping_levels)){
      appliedFiltersInitial[lev] = []
    }    
    const planLevels = action.payload.grouping_levels
    const levelNamesInitial = Object.keys(planLevels)
    for(let lev of levelNamesInitial){
      if(planLevels[lev].length === 0){
        planLevels[lev] = _.uniqBy(action.payload.transformations.map((x: any) => x.identifiers[lev]), function(e){ return e })
      }
    }
    const observations = action.payload.config.observations
    .slice(action.payload.observations_min, action.payload.observations_max + 1)
    const bottomLevelKey = action.payload.config.grouping_levels[action.payload.config.grouping_levels.length - 1].key
    const bottomLevelNames = _.uniqBy(
      action.payload.transformations.map(
        (transformation) => transformation.identifiers[bottomLevelKey]
      ),
      function (e) {
        return e
      }
    )
    const barAllocations = createBudgetAllocations(bottomLevelNames, bottomLevelKey, 
      action.payload.config.grouping_levels, action.payload.transformations)

    const costGridData = createCostGridData(barAllocations, observations)
    return {
      ...state,
      allTransforms: action.payload.transformations,
      scenarioFilters: planLevels,
      levelTransforms: action.payload.transformations,
      appliedFilters: appliedFiltersInitial,
      costGridData: costGridData,
      planItem: action.payload,
      bottomLevelKey: bottomLevelKey,
      observations: observations,
    }
    case PUT_PLAN_SUCCESS:
      let transformsHolder: any[] = []
      for(let trans of state.levelTransforms){
        const newTransform = action.payload.transformations.find(x => {
          const keys = Object.keys(trans.identifiers).filter(x => x!== "region_key")
          let match = true
          for(let key of keys){
            if(x.identifiers[key] !== trans.identifiers[key]){
              match = false
            }
          }
          if(match){
            return x.grouping_id === trans.grouping_id
          }
        })
        transformsHolder = [...transformsHolder, newTransform]
      }

      const bottomLevelNamesPutPlan = _.uniqBy(
        transformsHolder.map(
          (transformation) => transformation.identifiers[state.bottomLevelKey]
        ),
        function (e) {
          return e
        }
      )
      const barAllocationsPutPlan = createBudgetAllocations(bottomLevelNamesPutPlan, state.bottomLevelKey, 
        action.payload.config.grouping_levels, transformsHolder)
      const costGridDataPutPlan = createCostGridData(barAllocationsPutPlan, state.observations)
      
      return {
        ...state,
        levelTransforms: transformsHolder,
        allTransforms: action.payload.transformations,
        costGridData: costGridDataPutPlan,

      }
    case PLAN_SELECT_LEVEL:
      const levelNames = Object.keys(state.scenarioFilters)
      const levelIndex = levelNames.indexOf(action.levelName)
      let newFilters = _.cloneDeep(state.appliedFilters)
      if(action.key !== "all"){
        newFilters[action.levelName] = [action.key]
      } else {
        newFilters[action.levelName] = []
      }

      if(levelIndex !== levelNames.length - 1){
        for(let i = levelIndex + 1; i < levelNames.length; i++){
          newFilters[levelNames[i]] = []
        }
      }
      
      let newFilteredTransforms = state.allTransforms.filter(trans => {
        let allowTrans = true

        for(let lev of levelNames){
          
          allowTrans = newFilters[lev].length === 0 ? true : newFilters[lev].indexOf(trans.identifiers[lev]) !== -1
          if(!allowTrans){
            break
          }
        }

        if(allowTrans){
          return trans
        }
      })

      //all levels lower than the filtered level are recalculated
      let returnLevels = _.cloneDeep(state.scenarioFilters)
      if(levelIndex !== levelNames.length - 1){
        for(let i = levelIndex + 1; i < levelNames.length; i++){
          const allLevels = _.uniqBy(newFilteredTransforms.map(x => x.identifiers[levelNames[i]]), function(e){ return e })
          returnLevels[levelNames[i]] = allLevels
      }
      }
      const bottomLevelNamesSelectLevel = _.uniqBy(
        newFilteredTransforms.map(
          (transformation) => transformation.identifiers[state.bottomLevelKey]
        ),
        function (e) {
          return e
        }
      )
      const barAllocationsSelectLevel = createBudgetAllocations(bottomLevelNamesSelectLevel, state.bottomLevelKey, 
        state.planItem!.config.grouping_levels, newFilteredTransforms)
      const costGridDataSelectLevel = createCostGridData(barAllocationsSelectLevel, state.observations)

      return {
        ...state,
        scenarioFilters: returnLevels,
        levelTransforms: newFilteredTransforms,
        appliedFilters: newFilters,
        costGridData: costGridDataSelectLevel,
      }
      case DISCARD_COST_CHANGES:
        const bottomLevelNamesDiscard = _.uniqBy(
          state.levelTransforms.map(
            (transformation) => transformation.identifiers[state.bottomLevelKey]
          ),
          function (e) {
            return e
          }
        )
        const barAllocationsDiscard = createBudgetAllocations(bottomLevelNamesDiscard, state.bottomLevelKey, 
          state.planItem!.config.grouping_levels, state.levelTransforms)
        const costGridDataDiscard = createCostGridData(barAllocationsDiscard, state.observations)
        return {
          ...state,
          costGridData: costGridDataDiscard

        }
        case COST_EDIT_MODE:
          return {
            ...state,
            costEditMode: action.payload
  
          }
    default:
      return state
  }
}

export default filtersReducer
