export interface Settings {
  [key: string]: any
  brand_name: string
  brand_plural: string
  campaign_name: string
  campaign_plural: string
  currency_symbol: string
  date_format: string
  hide_views: {
    [key: string]: boolean
  }
  high_level_impacts: { display_impacts: [] }
  kpi_groups: null
  media_name: string
  media_plural: string
  number_format: string
  region_name: string
  region_plural: string
  region_type: string
}

export interface InChannelSettings {
  [key: string]: InChannelSetting
}

export interface InChannelSetting {
  singular: string
  plural: string
}

export interface OktaSetting {
  id: number
  enabled: boolean
  label: string
  domain: string
  client_id: string
  idp_name: string
  custom_idps?: [
    {
      id: string
      text: string
      type: string
    }
  ]
}

export interface SettingsAttrs {
  date_format: string
}

export enum IdentityProvider {
  Azure = 'azure',
  Okta = 'okta',
  Ping = 'ping',
}
