import { useMemo } from 'react';
import classes from './classes';

export const useClassNames = () =>
  useMemo(() => {
    const classNames = {
      wrapper: classes.wrapper,
    };

    return classNames as Record<keyof typeof classNames, string>;
  }, []);
