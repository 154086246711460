import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import { ConfigOutput } from '../../../types/PlanTypes'

interface OwnProps {
  onSelect: (value: string) => void
  goal: string
}

type Props = OwnProps

const EditGoal: FC<Props> = (props) => {
  const { planItem, configOutputs } = useSelector(
    (state: AppState) => state.plans
  )
  const filteredROIGoals = configOutputs.filter(
    (x: ConfigOutput) =>
      planItem.config.options.optimisableKPIs.indexOf(x.key) !== -1
  )

  return (
    <div className="flex flex-col w-full">
      <div className="mb-5">Goals</div>
      <div>
        {filteredROIGoals.map((goal: ConfigOutput, index: number) => (
          <div key={goal.key} className="check-box">
            <label className="">
              <input
                type={'radio'}
                onChange={() => props.onSelect(goal.key)}
                checked={props.goal === goal.key}
                value={goal.key}
              />
              <div className="option">{goal.label}</div>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EditGoal
