import { Dispatch } from 'redux'
import {
  MarketingDiagnosticsActionTypes,
  MD_GET_DATA_ATTEMPT,
  MD_GET_DATA_FAIL,
  MD_GET_DATA_SUCCESS,
  MD_GET_HIGHLIGHTS_SUCCESS,
  MD_GET_INSIGHTS_ATTEMPT,
  MD_GET_INSIGHTS_FAIL,
  MD_GET_INSIGHTS_SUCCESS,
  MD_GET_MARKETING_DIAGNOSTICS_SUCCESS,
  MD_GET_PLAN_SUMMARY_LIST_SUCCESS,
  MD_GET_SYSTEM_LINK_SUCCESS,
} from './MarketingDiagnosticsActionTypes'
import api from '../../api'

export const GetLaunchpadData =
  () => async (dispatch: Dispatch<MarketingDiagnosticsActionTypes>) => {
    dispatch({ type: MD_GET_DATA_ATTEMPT })
    try {
      const systemLinksResponse =
        await api.MarketingDiagnostics.getSystemLinks()

      dispatch({
        type: MD_GET_SYSTEM_LINK_SUCCESS,
        payload: systemLinksResponse.data,
      })
      dispatch({ type: MD_GET_DATA_SUCCESS })
    } catch (e) {
      dispatch({ type: MD_GET_DATA_FAIL })
    }
  }

export const GetMarketingDiagnostics = () => async (
  dispatch: Dispatch<MarketingDiagnosticsActionTypes>
) => {
  dispatch({ type: MD_GET_DATA_ATTEMPT })
  try {
    const mdResponse = await api.MarketingDiagnostics.getMarketingDiagnostics()
    const highLightResponse = await api.MarketingDiagnostics.getHighlights()
    const systemLinksResponse = await api.MarketingDiagnostics.getSystemLinks()

    dispatch({
      type: MD_GET_SYSTEM_LINK_SUCCESS,
      payload: systemLinksResponse.data,
    })
    dispatch({
      type: MD_GET_MARKETING_DIAGNOSTICS_SUCCESS,
      payload: mdResponse.data,
    })
    dispatch({
      type: MD_GET_HIGHLIGHTS_SUCCESS,
      payload: highLightResponse.data,
    })

    dispatch({ type: MD_GET_DATA_SUCCESS })
  } catch (e) {
    dispatch({ type: MD_GET_DATA_FAIL })
  }
}

export const GetInsights = (brands: string[]) => async (
  dispatch: Dispatch<MarketingDiagnosticsActionTypes>
) => {
  dispatch({ type: MD_GET_INSIGHTS_ATTEMPT })

  try {
    if (brands.length === 0) {
      const mdResponse = await api.MarketingDiagnostics.getMarketingDiagnostics()
      brands.push(mdResponse.data.brands[0].brand)
    }

    const insightsResponse = await api.MarketingDiagnostics.getInsights(brands)
    dispatch({ type: MD_GET_INSIGHTS_SUCCESS, payload: insightsResponse.data })
    dispatch({ type: MD_GET_DATA_SUCCESS })
  } catch (e) {
    dispatch({ type: MD_GET_INSIGHTS_FAIL })
  }
}
