import React, { FC, useState, useEffect } from 'react'
import { MainLayout } from '../../../shared/MainLayout'
import { Link, useHistory } from 'react-router-dom'
import { Options, RouteComponentProps, SelectedOptions } from '../../../types/CreatePlanTypes'
import { RootStore } from '../../../redux/reducers/Store'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { getConfigById } from '../../../redux/reducers/ConfigReducer'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { Settings } from '../../../types/SettingTypes'
import { GetConfigById } from '../../../redux/actions/ConfigActions'
import { Observation, Transformation } from '../../../types/PlanTypes'
import _, { over } from 'lodash'
import TimeSlider from '../create-plan/TimeSlider'
import {
  selectCreateError,
  selectPlanCreatedComplete,
  selectPlanItem,
  selectLoading,
} from '../../../redux/reducers/PlanReducer'
import * as moment from 'moment'
import { CheckBoxItem } from '../../../types/CreatePlanTypes'
import { CSVLink } from 'react-csv'
import { ROUTES } from '../../../Routes'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { uploadInChannelSPOPlan } from '../../../socket.io'
import UrlAssembler from 'url-assembler'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { AppState } from '../../../redux/reducers/RootReducer'

interface OwnProps {}

interface MatchParams {
  id: string
}

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  config: getConfigById(state),
  planCreated: selectPlanCreatedComplete(state),
  optimiserError: selectCreateError(state),
  plan: selectPlanItem(state),
  loading: selectLoading(state),
})

const mapDispatchToProps = {
  onGetConfigById: GetConfigById,
  onCreateUploadPlan: uploadInChannelSPOPlan,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type uploadInChannelSelected = {
  [key: string]: string
}


const UploadInChannelPlan: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const [page, setPage] = useState(0)
  const [error, setError] = useState('')
  const [file, setFile] = useState(null)
  const [currency, setCurrency] = useState('')
  const [data, setData] = useState<string[][]>([[]])
  const [options, setOptions] = useState<Options>({})
  const [levelKeys, setLevelKeys] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<uploadInChannelSelected>({})
  const [transformationOptions, setTransformationOptions] = useState<
    Transformation[]
  >([])
  const [observations, setObservations] = useState<Observation[]>([])
  const [sliderPosition, setSliderPosition] = useState([0, 1])
  const [numberOfLevels, setNumberOfLevels] = useState(0)

  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)


  useEffect(() => {
    props.onGetConfigById(props.match.params.id)
  }, [])

  let history = useHistory()
  useEffect(() => {
    if (props.planCreated) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.IN_CHANNEL_PLAN_ITEM)
        .param('id', props.plan.id)
        .toString()

      history.push(url)
    }
  }, [props.planCreated])

  useEffect(() => {
    if(props.config){
    const level1Values = props.config.grouping_levels[0].values

    const alphabeticalLevel1Values = level1Values.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })

    const checkBoxLevel1 = alphabeticalLevel1Values.map((x, ind) => {
      const lev = {
        ...x,
        isChecked: ind !== 0 ? false : true,
      }
      return lev
    })

    const transOptions =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []

    const carryoverObs =
      props.config?.observations.slice(
        0,
        props.config?.observations.length - props.config?.options.carryoverWeeks
      ) || []

    let dateFormat = settings?.date_format

    carryoverObs.map(
      (obs) => (obs.label = moment.utc(obs.label).format(dateFormat))
    )

    const levKeys = props.config.grouping_levels.map(x => x.key)
    setLevelKeys(levKeys)

    let optionsVals: Options = {}
    let selectedVals: uploadInChannelSelected = {}
    for(let key of levKeys){
      optionsVals[key] = []
      selectedVals[key] = ""
    }
    checkBoxLevel1[0].isChecked = true
    selectedVals[levKeys[0]] = checkBoxLevel1[0].key
    optionsVals[levKeys[0]] = checkBoxLevel1

    setNumberOfLevels(levKeys.length)
    setSliderPosition([0, carryoverObs.length - 1])
    setObservations(carryoverObs)
    setOptions(optionsVals)
    setSelectedOptions(selectedVals)
    setTransformationOptions(transOptions)
    }
  }, [props.config])

  useEffect(() => {
    setError(props.optimiserError!)
  }, [props.optimiserError])

  const nextPage = () => {
    getNewOptions()
    setPage((prevPage) => prevPage + 1)
  }

  const previousPage = () => {
    unFilterTransformations()
    setPage((prevPage) => prevPage - 1)
    setError('')
  }

  const getNewOptions = () => {
    if(page < numberOfLevels  - 2){
      const nextLevelKey = levelKeys[page + 1]
      const nextLevels = calculateOptions(levelKeys[page], levelKeys[page + 1])
      if(nextLevels[0]) {
        nextLevels[0].isChecked = true
        setSelectedOptions({ ...selectedOptions, [levelKeys[page + 1]]: nextLevels[0].key })
      }
      setOptions({...options, [nextLevelKey]: nextLevels})
    }
  }

  const calculateOptions = (
    parent: string,
    groupingLevel: string
  ): CheckBoxItem[] => {
    const newTransformationOptions = transformationOptions.filter((x) => {
      if (selectedOptions[parent].length !== 0) {
        if (selectedOptions[parent].includes(x.identifiers[parent])) {
          return x
        }
      } else {
        return x
      }
    })
    const keys = newTransformationOptions.map(
      (x) => x.identifiers[groupingLevel]
    )
    const uniqueKeys = _.uniqBy(keys, function (e) {
      return e
    })
    const allLevels =
      props.config?.grouping_levels.find((x) => x.key === groupingLevel)
        ?.values || []

    const levels = uniqueKeys.map((x) => {
      const level = {
        key: x,
        label: allLevels.find((y) => y.key === x)?.label || '',
        isChecked: selectedOptions[groupingLevel].includes(x) ? true : false,
      }
      return level
    })
    setTransformationOptions(newTransformationOptions)
    return levels
  }

  const unFilterTransformations = () => {
    let newTransforms =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []
    //this stops the user from hitting 'Previous' but the transformation list still being filtered from calculateOptions()
    if (page <= numberOfLevels - 1) {
      for (let i = page - 1; i > 0; i--) {
        newTransforms = newTransforms.filter((x) => {
          if (selectedOptions[levelKeys[page - 1]].length !== 0) {
            if (
              selectedOptions[levelKeys[page - 1]].includes(
                x.identifiers[levelKeys[page - 1]]
              )
            ) {
              return x
            }
          } else {
            return x
          }
        })
      }
      setTransformationOptions(newTransforms)
    }
  }

  const getOptimisationText = (settings: any) => {
    if(inChannelSettings){
      if(page <= numberOfLevels - 2){
        const levelKey = levelKeys[page]
        const levelLabel = props.config?.grouping_levels.find(lev => lev.key === levelKey)
        //pineapple: NEED TO REFACTOR ONCE ADMIN IS HOOKED UP
        const levelName = levelLabel ? levelLabel.label : ''
        const levelNameup = levelKey + '_plural'
        let name = inChannelSettings[levelKey] && inChannelSettings[levelKey].plural ?  inChannelSettings[levelKey].plural : settings[levelNameup] ? settings[levelNameup].toLowerCase() : levelName.toLowerCase()
        return `What ${name} do you want to include?`
      }
      if (page === 3) {
        return 'What time period?'
      }
    }
  }

  const getOptimisationOptions = () => {
    if (page < numberOfLevels - 1) {
      return options[levelKeys[page]]
    }
    return []
  }

  const selectOption = (e: any) => {
    if (page < numberOfLevels - 1) {
      const updateBoxes = options[levelKeys[page]].map((x) => {
        if (x.key === e.target.value) {
          x.isChecked = true
        } else {
          x.isChecked = false
        }
        return x
      })
      setOptions({ ...options, [levelKeys[page]]: updateBoxes })
      setSelectedOptions({
        ...selectedOptions,
        [levelKeys[page]]: e.target.value,
      })
    }
  }

  const changeFile = (e: any) => {
    setFile(e.target.files[0])
  }

  const handleClickCurrency = (e: any) => {
    setCurrency(e.target.value)
  }

  const handleMoveSlider = (position: number[]) => {
    setSliderPosition(position)
  }

  const downloadCSV = () => {
    if(props.config){
      let headers = props.config.grouping_levels.map(x => x.label)
      headers = [...headers, 'Variable', 'Spend', 'Max']

      const noHaloTransformations =
        props.config.transformations
          .filter((trans) => !trans.is_halo)
          .filter(
            (trans) => {
              const levels = Object.keys(trans.identifiers).filter(x => x !== "region_key")
              let passFilter = true
              for(let i = 0; i < levels.length - 1; i++){
                passFilter = trans.identifiers[levels[i]] === selectedOptions[levels[i]]
                if(!passFilter){
                  break
                }
              }
              return passFilter
            }
              
          )

      let identifiers = noHaloTransformations.map(
        (transform) => transform.identifiers
      )

      const transformsToIdentifiers =
        props.config.transformations.map((x) => x.identifiers)
      let data = identifiers.map((x) => {
        
        const index = transformsToIdentifiers.findIndex((iden) =>
          _.isEqual(iden, x)
        )
        const maxConstraintValue =
          ((props.config?.max_constraints?.values[index] || 0) /
            (props.config?.observations.length || 1)) *
          (sliderPosition[1] + 1 - sliderPosition[0])


        const filteredKeys = Object.keys(x).filter(key => key !== "region_key")
        let ids: string[] = []
        for(let key of filteredKeys){
          const label = keysToLabels(key, x[key])
          ids = [...ids, label]
        }
        ids = [...ids, 'Spend', '0', maxConstraintValue.toString()]

        return ids
      })

      data.unshift(headers)
      setData(data)
    }
    
  }

  const keysToLabels = (level: string, key: string) => {
    const values =
      props.config?.grouping_levels.find((x) => x.key === level)?.values || []
    const foundKey = values.find((x) => x.key === key)?.label || ''
    return foundKey
  }

  const uploadPlan = () => {
    if (!file) {
      setError('Please select a file to upload.')
    } else if (props.config && props.user) {
      setError('')
      props.onCreateUploadPlan(
        props.config.id,
        file,
        props.user,
        sliderPosition
      )
    }
  }

  if (props.loading) {
    return <LoadingModal />
  }

  return (
    <>
      {settings && props.config ? (
        <div className="flex flex-col h-screen place-content-center align-middle">
          <h1 className="text-gray-500 my-6 content-center text-center text-xl">
            {getOptimisationText(settings)}
          </h1>
          <div>
            {page < numberOfLevels - 1 && (
              <div className="flex flex-col w-full space-y-5 justify-center">
                <div
                  className={`grid grid-flow-rows ${
                    getOptimisationOptions().length === 1 || page === numberOfLevels + 2
                      ? 'grid-cols-1'
                      : 'grid-cols-2'
                  } gap-4 p-4 max-h-96 overflow-auto justify-center`}
                >
                  {getOptimisationOptions().map((option: any, ind: number) => {
                    const checked =
                      option.isChecked || getOptimisationOptions().length === 1
                    return (
                      <div
                        key={ind}
                        className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white items-center shadow m-auto w-3/4 h-14"
                      >
                        <label className="flex flex-row items-center space-x-2 h-full w-full">
                          <input
                            type="checkbox"
                            onChange={selectOption}
                            checked={checked}
                            className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                            value={option.key}
                            key={option.key}
                          />
                          <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                            {option.label}
                          </div>
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {page === numberOfLevels - 1 && (
              <TimeSlider
                observations={observations}
                position={sliderPosition}
                handleMoveSlider={handleMoveSlider}
              />
            )}
            {page === numberOfLevels && (
              <div className="flex flex-col h-screen place-content-center align-middle">
                <h1 className="text-gray-500 my-6 content-center text-center text-xl">
                  Work with existing partner spends
                </h1>
                <div className={'flex flex-row'}>
                  <div className="flex flex-col w-1/2 h-60 rounded bg-white border p-4  m-5">
                    <div className={'text-gray mb-5'}>FILE UPLOAD</div>
                    <input type="file" onChange={changeFile} />
                    <div className={'h-24 flex flex-row mt-2'}>
                      {props.config?.options.exchangeRates.currencySymbols
                        .length > 1 && (
                        <div>
                          <select onChange={handleClickCurrency}>
                            {props.config?.options.exchangeRates.currencySymbols.map(
                              (currency) => {
                                return (
                                  <option key={currency} value={currency}>
                                    {currency}
                                  </option>
                                )
                              }
                            )}
                          </select>
                          <span className={'w-1/2 ml-4'}>Select Currency</span>
                        </div>
                      )}
                    </div>
                    {error !== '' && (
                      <div className={'text-gtBlueSecondary'}>{error}</div>
                    )}
                    <div>
                      <CSVLink
                        className={'text-gtPink mt- cursor-default opacity-75'}
                        data={data}
                        filename={'InChannelPlanTemplate.csv'}
                        onClick={downloadCSV}
                      >
                        Download a template
                      </CSVLink>
                    </div>
                  </div>
                </div>
                <div className={'flex flex-row justify-center mt-8'}>
                  <button
                    className="rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                    onClick={uploadPlan}
                  >
                    Generate Marketing Plan
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={'flex flex-row justify-center mt-8'}>
            {page > 0 && page !== numberOfLevels && (
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                onClick={previousPage}
              >
                Previous
              </button>
            )}
            {page < numberOfLevels && (
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 m-2"
                onClick={nextPage}
              >
                Next
              </button>
            )}
          </div>
          {page === numberOfLevels && (
            <Link
              className={'text-gtPink text-center m-2'}
              to={ROUTES.SPO.IN_CHANNEL_INDEX}
            >
              Or, cancel
            </Link>
          )}
          {error !== '' && (
            <div className={'text-center text-gtBlueSecondary'}>{error}</div>
          )}
        </div>
      ) : (
        <div />
      )}
    </>
  )
}

export default connector(MainLayout(UploadInChannelPlan))
