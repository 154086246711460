import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const ProfitIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="#666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M6.957 46.667v8M37.565 38.667H62.61v-8H37.565zM43.13 30.667v8M57.043 30.667v8M34.783 46.667h25.043v-8H34.783zM40.348 38.667v8M54.26 38.667v8M37.565 54.667H62.61v-8H37.565zM43.13 46.667v8M57.043 46.667v8M34.783 62.667h25.043v-8H34.783zM40.348 54.667v8M54.26 54.667v8M1.391 54.667h25.044v-8H1.39zM20.87 46.667v8M4.174 62.667h25.043v-8H4.174zM23.652 54.667v8M9.74 54.667v8M2.783 36l25.043-24L41.74 22.667 62.61 1.333" />
          <path d="M43.13 1.333H62.61v17.334" />
        </g>
      </g>
    </svg>
  )
}

export default ProfitIcon
