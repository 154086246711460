type TChannelLabel = {
  onClick: () => void;
  label: string;
  isOpen?: boolean;
}

const Toggle = ({ isOpen }: Pick<TChannelLabel, 'isOpen'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={`${isOpen ? "rotate-180" : "rotate-0"} transform duration-300`}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#FFF"
        d="M-362-2691H852v5809H-362z"
      />
      <path
        className="stroke-brand-primary-main"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 13l6 6 6-6"
      />
    </g>
  </svg>
);

export const ChannelLabel = ({ onClick, label, isOpen }: TChannelLabel) => (
  <span className="grid grid-flow-col grid-cols-4 justify-start items-center gap-2 ml-3 cursor-pointer" onClick={onClick}>
    <Toggle isOpen={Boolean(isOpen)} />
    <span className="col-span-3">{label}</span>
  </span>
)