import { Plan } from '../../types/PlanTypes'

export const GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET =
  'GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET'

export const PUT_PLAN_MEDIA_CHANNEL_ALLOCATION =
  'PUT_PLAN_MEDIA_CHANNEL_ALLOCATION'

  export const PLAN_BAR_MOVE_STARTED = "PLAN_BAR_MOVE_STARTED"
  export const PLAN_BAR_MOVE_ENDED = "PLAN_BAR_MOVE_ENDED"

interface GetAllBrandsMediaChannelAllocationBudget {
  type: typeof GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET
  payload: Plan
}

interface PutPlanMediaChannelAllocation {
  type: typeof PUT_PLAN_MEDIA_CHANNEL_ALLOCATION
}

interface PLAN_BAR_MOVE_STARTED {
  type: typeof PLAN_BAR_MOVE_STARTED
}

interface PLAN_BAR_MOVE_ENDED {
  type: typeof PLAN_BAR_MOVE_ENDED
}

export type MediaChannelActionTypes =
  | GetAllBrandsMediaChannelAllocationBudget
  | GetAllBrandsMediaChannelAllocationBudget
  | PLAN_BAR_MOVE_STARTED
  | PLAN_BAR_MOVE_ENDED
