import {
  IComparisonData,
  PlanComparisons,
} from '../../types/PlanComparisonTypes'
import { Plan } from '../../types/PlanTypes'

export const SOCKET_CREATE_PLAN_ATTEMPT = 'SOCKET_CREATE_SCENARIO_ATTEMPT'
export const SOCKET_CREATE_PLAN_SUCCESS = 'SOCKET_CREATE_SCENARIO_SUCCESS'
export const SOCKET_CREATE_PLAN_ERROR = 'SOCKET_CREATE_PLAN_ERROR'
export const SOCKET_CREATE_SCENARIO_ERROR = 'SOCKET_CREATE_SCENARIO_ERROR'
export const SOCKET_EDIT_PLAN_NAME = 'SOCKET_EDIT_PLAN_NAME'
export const SOCKET_EDIT_PLAN_NAME_SUCCESS = 'SOCKET_EDIT_PLAN_NAME_SUCCESS'
export const SOCKET_EDIT_PLAN_NAME_FAIL = 'SOCKET_EDIT_PLAN_NAME_FAIL'
export const SOCKET_UPLOAD_PLAN = 'SOCKET_UPLOAD_PLAN'
export const SOCKET_UPLOAD_IN_CHANNEL_PLAN = 'SOCKET_UPLOAD_IN_CHANNEL_PLAN'
export const SOCKET_EDIT_FILTERS = 'SOCKET_EDIT_FILTERS'
export const SOCKET_EDIT_FILTERS_SUCCESS = 'SOCKET_EDIT_FILTERS_SUCCESS'
export const SOCKET_UPLOAD_PLAN_SUCCESS = 'SOCKET_UPLOAD_PLAN_SUCCESS'
export const SOCKET_UPLOAD_PLAN_ERROR = 'SOCKET_UPLOAD_PLAN_ERROR'
export const SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS =
  'SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS'
export const SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR =
  'SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR'
export const SOCKET_EDIT_PLAN_BUDGET = 'SOCKET_EDIT_PLAN_BUDGET'
export const SOCKET_EDIT_PLAN_BUDGET_SUCCESS = 'SOCKET_EDIT_PLAN_BUDGET_SUCCESS'
export const SOCKET_EDIT_PLAN_BUDGET_FAIL = 'SOCKET_EDIT_PLAN_BUDGET_FAIL'
export const SOCKET_SET_CONSTRAINTS_FROM_TABLE =
  'SOCKET_SET_CONSTRAINTS_FROM_TABLE'
export const SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS =
  'SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS'
export const SOCKET_SET_CONSTRAINTS_FROM_TABLE_FAIL =
  'SOCKET_SET_CONSTRAINTS_FROM_TABLE_FAIL'

export const DELETE_CURVE_DATA = 'DELETE_CURVE_DATA'
export const DRAW_CURVES = 'DRAW_CURVES'
export const SOCKET_CALCULATE_CURVE_DATA = 'SOCKET_CALCULATE_CURVE_DATA'
export const SOCKET_CALCULATE_CURVE_DATA_SUCCESS =
  'SOCKET_CALCULATE_CURVE_DATA_SUCCESS'
export const SOCKET_CALCULATE_CURVE_DATA_FAIL =
  'SOCKET_CALCULATE_CURVE_DATA_FAIL'

export const SOCKET_CREATE_SCENARIO_FROM_EDITOR_SUCCESS =
  'SOCKET_CREATE_SCENARIO_FROM_EDITOR_SUCCESS'

export const SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR =
  'SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR'

export const SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT =
  'SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT'

export const SOCKET_SET_CONSTRAINTS_ERROR = 'SOCKET_SET_CONSTRAINTS_ERROR'
export const SOCKET_RESET_CONSTRAINTS_ERROR = 'SOCKET_RESET_CONSTRAINTS_ERROR'

export const SOCKET_EDIT_CPM = 'SOCKET_EDIT_CPM'
export const SOCKET_EDIT_CPM_SUCCESS = 'SOCKET_EDIT_CPM_SUCCESS'


export const SOCKET_EDIT_COSTS = 'SOCKET_EDIT_COSTS'
export const SOCKET_EDIT_COSTS_SUCCESS = 'SOCKET_EDIT_COSTS_SUCCESS'
export const SOCKET_EDIT_WEEKLY_COSTS = 'SOCKET_EDIT_WEEKLY_COSTS'
export const SOCKET_EDIT_WEEKLY_COSTS_SUCCESS = 'SOCKET_EDIT_WEEKLY_COSTS_SUCCESS'

export const SOCKET_CLOSED = 'SOCKET_CLOSED'

export const PLAN_LOAD_COMPLETE = 'PLAN_LOAD_COMPLETE'
export const SOCKET_CREATE_PLAN_FROM_EDITOR_SUCCESS =
  'SOCKET_CREATE_PLAN_FROM_EDITOR'

export const SOCKET_UPDATE_COMPARISON_NAME = 'SOCKET_UPDATE_COMPARISON_NAME'
export const SOCKET_UPDATE_COMPARISON_KPIS = "SOCKET_UPDATE_COMPARISON_KPIS"
export const SOCKET_UPDATE_COMPARISON_KPIS_SUCCESS =
  'SOCKET_UPDATE_COMPARISON_KPIS_SUCCESS'
export const SOCKET_UPDATE_COMPARISON_NAME_SUCCESS =
  'SOCKET_UPDATE_COMPARISON_NAME_SUCCESS'
export const SOCKET_GET_PLAN_COMPARISON_DATA = 'SOCKET_GET_PLAN_COMPARISON_DATA'
export const SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS =
  'SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS'
export const SOCKET_DELETE_PLAN_COMPARISON = 'SOCKET_DELETE_PLAN_COMPARISON'
export const SOCKET_DELETE_COMPARISON_SUCCESS =
  'SOCKET_DELETE_COMPARISON_SUCCESS'
export const SOCKET_UPDATE_BASE_COMPARISON = 'SOCKET_UPDATE_BASE_COMPARISON'
export const SOCKET_UPDATE_BASE_COMPARISON_SUCCESS =
  'SOCKET_UPDATE_BASE_COMPARISON_SUCCESS'
export const CALCULATE_CURVE_DATA_ATTEMPT = 'CALCULATE_CURVE_DATA_ATTEMPT'
export const CALCULATE_CURVE_DATA_SUCCESS =' CALCULATE_CURVE_DATA_SUCCESS'

interface SOCKET_CREATE_PLAN_ATTEMPT {
  type: typeof SOCKET_CREATE_PLAN_ATTEMPT
}

interface SocketCreatePlanFromEditorSuccess {
  type: typeof SOCKET_CREATE_PLAN_FROM_EDITOR_SUCCESS
}

interface SOCKET_CREATE_PLAN_SUCCESS {
  type: typeof SOCKET_CREATE_PLAN_SUCCESS
  payload: Plan
}

interface SOCKET_CREATE_PLAN_ERROR {
  type: typeof SOCKET_CREATE_PLAN_ERROR
}

interface SOCKET_CREATE_SCENARIO_ERROR {
  type: typeof SOCKET_CREATE_SCENARIO_ERROR
  payload: string
}

interface SOCKET_UPLOAD_PLAN {
  type: typeof SOCKET_UPLOAD_PLAN
}

interface SOCKET_UPLOAD_PLAN_SUCCESS {
  type: typeof SOCKET_UPLOAD_PLAN_SUCCESS
}

interface SOCKET_UPLOAD_PLAN_ERROR {
  type: typeof SOCKET_UPLOAD_PLAN_ERROR
}

interface SOCKET_UPLOAD_IN_CHANNEL_PLAN {
  type: typeof SOCKET_UPLOAD_IN_CHANNEL_PLAN
}

interface SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS {
  type: typeof SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS
}

interface SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR {
  type: typeof SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR
}

interface SOCKET_EDIT_PLAN_NAME {
  type: typeof SOCKET_EDIT_PLAN_NAME
}

interface SOCKET_EDIT_PLAN_NAME_SUCCESS {
  type: typeof SOCKET_EDIT_PLAN_NAME_SUCCESS
}

interface SOCKET_EDIT_PLAN_NAME_FAIL {
  type: typeof SOCKET_EDIT_PLAN_NAME_FAIL
}

interface SOCKET_EDIT_PLAN_BUDGET {
  type: typeof SOCKET_EDIT_PLAN_BUDGET
}

interface SOCKET_EDIT_PLAN_BUDGET_SUCCESS {
  type: typeof SOCKET_EDIT_PLAN_BUDGET_SUCCESS
}

interface SOCKET_EDIT_PLAN_BUDGET_FAIL {
  type: typeof SOCKET_EDIT_PLAN_BUDGET_FAIL
}

interface SOCKET_SET_CONSTRAINTS_FROM_TABLE {
  type: typeof SOCKET_SET_CONSTRAINTS_FROM_TABLE
}
interface SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS {
  type: typeof SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS
}
interface SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR {
  type: typeof SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR
}
interface SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT {
  type: typeof SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT
}

interface SOCKET_SET_CONSTRAINTS_ERROR {
  type: typeof SOCKET_SET_CONSTRAINTS_ERROR
  payload: string
}

interface SOCKET_RESET_CONSTRAINTS_ERROR {
  type: typeof SOCKET_RESET_CONSTRAINTS_ERROR
}

interface SOCKET_CALCULATE_CURVE_DATA {
  type: typeof SOCKET_CALCULATE_CURVE_DATA
}

interface SOCKET_CALCULATE_CURVE_DATA_SUCCESS {
  type: typeof SOCKET_CALCULATE_CURVE_DATA_SUCCESS
}

interface SOCKET_CALCULATE_CURVE_DATA_FAIL {
  type: typeof SOCKET_CALCULATE_CURVE_DATA_FAIL
}

interface DELETE_CURVE_DATA {
  type: typeof DELETE_CURVE_DATA
}

interface PLAN_LOAD_COMPLETE {
  type: typeof PLAN_LOAD_COMPLETE
}

interface DRAW_CURVES {
  type: typeof DRAW_CURVES
}

interface SOCKET_EDIT_CPM {
  type: typeof SOCKET_EDIT_CPM
}

interface SOCKET_EDIT_COSTS {
  type: typeof SOCKET_EDIT_COSTS
}

interface SOCKET_EDIT_WEEKLY_COSTS {
  type: typeof SOCKET_EDIT_WEEKLY_COSTS
}

interface SOCKET_GET_PLAN_COMPARISON_DATA {
  type: SOCKET_GET_PLAN_COMPARISON_DATA
}

interface SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS {
  type: SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS
  payload: IComparisonData
}

interface SOCKET_DELETE_PLAN_COMPARISON {
  type: SOCKET_DELETE_PLAN_COMPARISON
}

interface SOCKET_UPDATE_BASE_COMPARISON {
  type: SOCKET_UPDATE_BASE_COMPARISON
}

interface SOCKET_UPDATE_BASE_COMPARISON_SUCCESS {
  type: SOCKET_UPDATE_BASE_COMPARISON_SUCCESS
}

interface SOCKET_UPDATE_COMPARISON_NAME_SUCCESS {
  type: SOCKET_UPDATE_COMPARISON_NAME_SUCCESS
  payload: PlanComparisons
}

interface CALCULATE_CURVE_DATA_ATTEMPT {
  type: typeof CALCULATE_CURVE_DATA_ATTEMPT
}

interface CALCULATE_CURVE_DATA_SUCCESS {
  type: typeof CALCULATE_CURVE_DATA_SUCCESS
}



export type SocketActionTypes =
  | SOCKET_CREATE_PLAN_ATTEMPT
  | SOCKET_CREATE_PLAN_SUCCESS
  | SOCKET_CREATE_PLAN_ERROR
  | SOCKET_CREATE_SCENARIO_ERROR
  | SOCKET_UPLOAD_PLAN
  | SOCKET_UPLOAD_PLAN_SUCCESS
  | SOCKET_UPLOAD_PLAN_ERROR
  | SOCKET_UPLOAD_IN_CHANNEL_PLAN
  | SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS
  | SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR
  | SOCKET_EDIT_PLAN_NAME
  | SOCKET_EDIT_PLAN_NAME_SUCCESS
  | SOCKET_EDIT_PLAN_NAME_FAIL
  | SOCKET_EDIT_PLAN_BUDGET
  | SOCKET_EDIT_PLAN_BUDGET_SUCCESS
  | SOCKET_EDIT_PLAN_BUDGET_FAIL
  | SOCKET_SET_CONSTRAINTS_FROM_TABLE
  | SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS
  | SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR
  | SOCKET_CALCULATE_CURVE_DATA
  | SOCKET_CALCULATE_CURVE_DATA_SUCCESS
  | SOCKET_CALCULATE_CURVE_DATA_FAIL
  | DELETE_CURVE_DATA
  | DRAW_CURVES
  | SOCKET_SET_CONSTRAINTS_ERROR
  | SOCKET_RESET_CONSTRAINTS_ERROR
  | SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT
  | SOCKET_EDIT_CPM
  | SOCKET_EDIT_COSTS
  | SOCKET_EDIT_WEEKLY_COSTS
  | PLAN_LOAD_COMPLETE
  | SOCKET_DELETE_PLAN_COMPARISON
  | SOCKET_UPDATE_BASE_COMPARISON
  | SOCKET_UPDATE_BASE_COMPARISON_SUCCESS
  | SOCKET_UPDATE_COMPARISON_NAME_SUCCESS
  | CALCULATE_CURVE_DATA_ATTEMPT
  | CALCULATE_CURVE_DATA_SUCCESS
