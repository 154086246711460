import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const UpArrowIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          className="fill-brand-primary-main"
          d="M32 64c17.672 0 32-14.325 32-32C64 14.328 49.672 0 32 0S0 14.328 0 32c0 17.675 14.328 32 32 32z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M29.288 18.556c-.003 1.11.894 2.008 2.002 2.005l9.311-.002-23.455 23.457c-.781.78-.781 2.047 0 2.828l.174.156c.785.621 1.929.57 2.654-.156L43.428 23.39l.002 9.312c-.003 1.104.897 2.004 2.004 2.001 1.101 0 1.996-.895 1.996-1.998V18.56c.003-1.104-.897-2.004-2.004-2h-14.14c-1.019 0-1.86.761-1.983 1.747l-.015.248z"
        />
      </g>
    </svg>
  )
}

export default UpArrowIcon
