import React, { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../shared/MainLayout'
import ChevronRightDefaultIcon from '../../icons/ChevronRightDefaultIcon'
import { Link, useParams } from 'react-router-dom'
import { ROUTES } from '../../Routes'
import { useDispatch, useSelector } from 'react-redux'
import {
  GetPerformanceOverview,
  UpdateChannelPerformanceSelection,
} from '../../redux/actions/PerformanceOverviewActions'
import { AppState } from '../../redux/reducers/RootReducer'
import { Channel, ChannelKey, KPI } from '../../types/MarketingDiagnosticsTypes'
import MediaChannelIcon from '../../icons/media/MediaChannelIcon'
import KpiCell from '../../shared/KpiCell'
import appendUnit from '../../utils/appendUnit'
import MetricComparisonCell from '../../shared/MetricComparisonCell'
import UrlAssembler from 'url-assembler'
import SelectBrandsModal from './highlights/SelectBrandsModal'
import { GetMarketingDiagnostics } from '../../redux/actions/MarketingDiagnosticsActions'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { Breadcrumbs } from '@gain-theory/breadcrumbs'

interface OwnProps {}

type Props = OwnProps

const PerformanceOverview: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { brand }: { brand: string } = useParams()

  const { phases, brands, kpis, headers, channels, previous, loading } =
    useSelector((state: AppState) => state.performanceOverview)

  const { marketingDiagnostics } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const [selectedPhases, setSelectedPhases] = useState<string[]>([])

  const [selectedBrands, setSelectedBrands] = useState<string[]>([brand])

  const [selectedKpi, setSelectedKpi] = useState<KPI | undefined>(undefined)
  const [selectedKpiValue, setSelectedKpiValue] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    dispatch(GetMarketingDiagnostics())
  }, [])

  useEffect(() => {
    dispatch(GetPerformanceOverview(selectedBrands.join(','), selectedPhases))
  }, [selectedPhases[0], selectedPhases[1]])

  useEffect(() => {
    if (phases.length > 0 && selectedPhases.length === 0) {
      let selectedPhases = [phases[phases.length - 1].phase]

      if (phases.length > 1) {
        selectedPhases.push(phases[phases.length - 2].phase)
      }
      setSelectedPhases(selectedPhases)
    }
  }, [phases])

  useEffect(() => {
    setSelectedKpi(kpis[0])
    if (!selectedKpiValue) {
      setSelectedKpiValue(selectedKpi?.id)
    }
  }, [kpis])

  const updateChannelPerformanceSelection = () => {
    dispatch(
      UpdateChannelPerformanceSelection(
        { id: selectedKpiValue } as KPI,
        selectedPhases[0],
        selectedPhases[1],
        selectedBrands.join(',')
      )
    )
  }

  useEffect(() => {
    updateChannelPerformanceSelection()
  }, [selectedBrands])

  const selectPhase = (index: number, phase: string) => {
    let newPhases = [...selectedPhases]
    newPhases[index] = phase
    setSelectedPhases(newPhases)
  }

  const [showModal, setShowModal] = useState(false)
  const [sortBy, setSortBy] = useState('spend')
  const [sortDirection, setSortDirection] = useState('desc')

  const changeSort = (sorCol: ChannelKey) => {
    // let sort = e.target.value.toLowerCase().replace(/ /g, "_");
    let sort = sorCol

    if (sorCol === headers[3]) {
      // 3
      sort = 'kpi'
    }

    let ascOrDesc = ''
    if (sortDirection === 'desc') {
      if (sort === sortBy) {
        ascOrDesc = 'asc'
      } else {
        ascOrDesc = 'desc'
      }
    } else {
      ascOrDesc = 'desc'
    }

    setSortDirection(ascOrDesc)
    setSortBy(sort)
    // this.setState({
    //     sortBy: sort,
    //     ascOrDesc: ascOrDesc
    // });
  }

  const sortedCurrentData =
    sortDirection === 'desc'
      ? channels.sort(
          (a, b) =>
            // @ts-ignore
            parseFloat(b[sortBy]) - parseFloat(a[sortBy])
        )
      : channels.sort(
          (
            a,
            b // @ts-ignore
          ) => parseFloat(a[sortBy]) - parseFloat(b[sortBy])
        )

  const getPrevDifference = (current: Channel, prev: Channel) => {
    let effectiveness =
      prev['effectiveness'] !== 0
        ? Math.round(
            ((((current['effectiveness'] as number) -
              prev['effectiveness']) as number) /
              prev.effectiveness) *
              100
          )
        : 0
    let productivity =
      prev.productivity !== 0
        ? Math.round(
            ((current.productivity - prev.productivity) / prev.productivity) *
              100
          )
        : 0
    let kpi =
      prev.kpi !== 0
        ? Math.round(((current.kpi - prev.kpi) / prev.kpi) * 100)
        : 0
    let spend =
      prev.spend !== 0
        ? Math.round(((current.spend - prev.spend) / prev.spend) * 100)
        : 0
    let impressions =
      prev.impressions !== 0
        ? Math.round(
            ((current.impressions - prev.impressions) / prev.impressions) * 100
          )
        : 0

    return { effectiveness, productivity, kpi, spend, impressions }
  }

  const singlePhaseUrl = (data: Channel) => {
    return UrlAssembler()
      .template(ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE)
      .param('channel', data.channel)
      .param('kpiId', selectedKpi?.id.toString() || '')
      .param('phaseId', selectedPhases[0])
      .param('brand', selectedBrands.join(','))
      .param('kpiName', selectedKpi ? selectedKpi.label : headers[3])
      .toString()
  }

  const multiPhaseUrl = (data: Channel) => {
    return UrlAssembler()
      .template(ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE_PREV_PHASE)
      .param('channel', data.channel)
      .param('kpiId', selectedKpi?.id.toString() || '')
      .param('phaseId', selectedPhases[0])
      .param('prevPhase', selectedPhases[1])
      .param('brand', selectedBrands.join(','))
      .param('kpiName', selectedKpi ? selectedKpi.label : headers[3])
      .toString()
  }

  const getUrl = (data: Channel) => {
    return selectedPhases.length > 1
      ? multiPhaseUrl(data)
      : singlePhaseUrl(data)
  }

  if (loading && !showModal) {
    return <LoadingModal />
  }

  const channelColors = ['#e84605', '#df0030', '#175fae', '#5db42b']
  var colorMap = 0

  return (
    <>
      <div className="z-150 relative">
        {marketingDiagnostics && showModal ? (
          <SelectBrandsModal
            brands={marketingDiagnostics?.brands}
            setShowModal={setShowModal}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />
        ) : null}
      </div>
      <div className="mb-5">
        <Breadcrumbs
          breadcrumbsConfig={[
            {
              label: 'Reporting',
              href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
              divider: true,
            },
            { label: 'Performance Overview' },
          ]}
        />
      </div>

      <div className="flex flex-row text-lg text-gray-500">
        <div className="flex items-center ">Performance Overview</div>
        <div className="flex flex-row justify-end flex-1 space-x-4">
          {marketingDiagnostics ? (
            <button
              onClick={() => setShowModal(true)}
              className="p-2 text-xxs w-1/4 border rounded bg-white"
            >
              {selectedBrands.length === 1 ? (
                <span> {selectedBrands[0]}</span>
              ) : (
                <span>{`Brands ${selectedBrands.length} of ${marketingDiagnostics?.brands.length} selected`}</span>
              )}
            </button>
          ) : null}
          <select
            value={selectedKpiValue}
            onChange={(e) => setSelectedKpiValue(parseInt(e.target.value, 10))}
            className="text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none"
          >
            {kpis.map((kpi) => (
              <option value={kpi.id} key={kpi.id} className="ring-0">
                {kpi.label}
              </option>
            ))}
          </select>

          {/*Phases*/}
          <select
            value={selectedPhases[0]}
            onChange={(e) => selectPhase(0, e.target.value)}
            className="text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none"
          >
            {phases.map((phase) => (
              <option
                disabled={selectedPhases.includes(phase.phase)}
                value={phase.phase}
                key={phase.phase}
              >
                {phase.phase}
              </option>
            ))}
          </select>
          {selectedPhases.length === 2 ? (
            <>
              <div className="flex items-center text-xxs">Vs</div>
              <select
                onChange={(e) => selectPhase(1, e.target.value)}
                value={selectedPhases[1]}
                className="text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none"
              >
                {phases.map((phase) => (
                  <option
                    disabled={selectedPhases.includes(phase.phase)}
                    value={phase.phase}
                    key={phase.phase}
                  >
                    {phase.phase}
                  </option>
                ))}
              </select>
            </>
          ) : null}

          <button
            className="bg-gtPink text-white px-4 rounded text-xs"
            onClick={updateChannelPerformanceSelection}
          >
            UPDATE
          </button>
        </div>
      </div>

      {/*performance overview data*/}
      {sortedCurrentData.length === 0 ? (
        <h3 className="mt-2 text-lg">
          There are no results for the selected Brand and Phase. Please make
          another selection.
        </h3>
      ) : (
        <div className="w-full mt-5 rounded-md bg-gray-200 px-1.5 pb-1.5 space-x-1 bg-opacity-50">
          <div className="w-full  flex flex-col space-y-2">
            <div className="w-full flex flex-row justify-evenly">
              {headers.map((header, index) => (
                <div
                  key={header}
                  className={`${
                    index === 0 ? 'pl-2 pr-10' : 'px-6'
                  } pt-3 pb-0.5 text-left text-xxs font-medium w-full text-gray-500 uppercase`}
                >
                  {header}
                </div>
              ))}
              <div className="w-40 ">{''}</div>
            </div>
            {sortedCurrentData.map((data, index) => {
              const previousChannel = previous.find(
                (prev) => prev.channel === data.channel
              )

              const prevData = previousChannel
                ? getPrevDifference(data, previousChannel)
                : null

              const effecPerformance = prevData
                ? prevData.effectiveness < 0
                  ? 'is lower'
                  : prevData.effectiveness > 0
                  ? 'is higher'
                  : 'remained stable'
                : ''

              const productPerformance = prevData
                ? prevData.productivity < 0
                  ? 'is lower'
                  : prevData.productivity > 0
                  ? 'is higher'
                  : 'remained stable'
                : ''

              if (index % channelColors.length === 0) colorMap = 0
              colorMap = colorMap + 1

              return (
                <div
                  key={index}
                  className="w-full flex flex-row justify-evenly bg-white rounded border-2 border-gray-300 items-center"
                >
                  <div className="pl-4 pr-8 flex flex-row text-xxs items-center space-x-2 font-medium w-full text-gray-500">
                    <MediaChannelIcon
                      className="w-7 h-7"
                      type={data.channel.toLowerCase().replace(/ /g, '_')}
                      stroke={channelColors[colorMap - 1]}
                    />
                    <div className=" text-xxs">{data.channel}</div>
                  </div>

                  <div className="px-6 py-3 text-left font-medium w-full text-gray-500">
                    <KpiCell
                      previous={previousChannel}
                      prevValue={
                        previousChannel ? prevData?.spend.toString() : '0'
                      }
                      value={appendUnit(
                        data.spend,
                        'currency',
                        marketingDiagnostics?.currency[0].symbol || '',
                        ''
                      )}
                      phaseCount={phases?.length}
                    />
                  </div>

                  <div className="px-6 py-3 text-left text-xxs font-medium w-full text-gray-500">
                    <KpiCell
                      previous={previousChannel}
                      prevValue={
                        previousChannel ? prevData?.impressions.toString() : '0'
                      }
                      value={appendUnit(data.impressions, '', '', '')}
                      phaseCount={phases.length}
                    />
                  </div>

                  <div className="px-6 py-3 text-left text-xxs font-medium w-full text-gray-500">
                    <KpiCell
                      previous={previousChannel}
                      prevValue={
                        previousChannel ? prevData?.kpi.toString() : '0'
                      }
                      value={appendUnit(data.kpi, '', '', '')}
                      phaseCount={phases.length}
                    />
                  </div>

                  <div className="px-6 py-3 text-left text-xxs font-medium w-full text-gray-500">
                    <MetricComparisonCell
                      previous={previousChannel}
                      metric={effecPerformance ? effecPerformance : 'stable'}
                      channel={data}
                      type="effectiveness"
                    />
                  </div>

                  <div className="px-6 py-3 text-left text-xxs font-medium w-full text-gray-500">
                    <MetricComparisonCell
                      previous={previousChannel}
                      metric={
                        productPerformance ? productPerformance : 'stable'
                      }
                      channel={data}
                      type="productivity"
                    />
                  </div>
                  <div className=" w-52 flex border-l">
                    <Link
                      className="flex"
                      to={{
                        pathname: getUrl(data),
                        state: {
                          channel: data,
                          config: marketingDiagnostics,
                        },
                      }}
                    >
                      <ChevronRightDefaultIcon className="w-10 h-10 mx-auto my-auto " />
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default MainLayout(PerformanceOverview)
