import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const OutdoorIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M1.333 44h61.334V1.333H1.333z" />
          <path
            strokeLinecap="round"
            d="M9.333 44v18.667M9.333 30.667v4M30.667 30.667v4M54.667 30.667v4M54.667 44v18.667M1.333 36h61.334"
          />
        </g>
      </g>
    </svg>
  )
}

export default OutdoorIcon
