import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from '@gain-theory/modal'
import { Typography } from '@gain-theory/typography'
import { getCookieFormat } from '../utils/getCookieFormat'
import { ROUTES } from '../Routes'
import { MainLayout } from './MainLayout'
import { TC_ACKNOWLEDGE_COOKIE_NAME } from '../utils/constants'

const TermsAcknowledgmentModal: FC = () => {
  const history = useHistory()

  const [show, setShow] = useState<boolean>(false)

  const closeModalAndRedirect = () => {
    setShow(false)
    history.push(ROUTES.HOME)
  }

  useEffect(() => {
    const allCookie = document.cookie.split(';')
    const hasAcknowledged = allCookie.some((item) =>
      item.includes(`${TC_ACKNOWLEDGE_COOKIE_NAME}=true`)
    )

    if (hasAcknowledged) {
      closeModalAndRedirect()
    } else {
      setShow(true)
    }
  }, [])

  const handleAcknowledge = () => {
    const cookie = getCookieFormat({
      name: TC_ACKNOWLEDGE_COOKIE_NAME,
      value: 'true',
      maxAgeMonths: 6,
    })

    document.cookie = cookie

    closeModalAndRedirect()
  }

  return (
    <Modal
      header={{ icon: 'info', text: 'Our terms have been updated' }}
      open={show}
      footer={{
        primary: {
          label: 'Acknowledge',
          leadingIconName: 'CheckIcon',
          onClick: handleAcknowledge,
        },
      }}
    >
      <Typography.Body1>
        Please click the links in the footer to view our updated user terms and
        privacy policy.
      </Typography.Body1>
    </Modal>
  )
}

export default MainLayout(TermsAcknowledgmentModal)
