import { ToastOptions, toast as reactToast } from 'react-toastify'
import { NotificationContextType } from '../types'

export const useNotification = () => {
  const toastProps: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'light',
  }

  const toast: NotificationContextType['toast'] = (
    variant,
    header,
    description
  ) =>
    reactToast[variant](
      <div>
        <div className="font-bold">{header}</div>
        <div>{description}</div>
      </div>,
      toastProps
    )

  return {
    toast,
  }
}
