import { Children } from 'react'
import { ReactComponent as evaluate } from './assets/evaluate-table.svg'
import { ReactComponent as exploit } from './assets/exploit-table.svg'
import { ReactComponent as negotiate } from './assets/negotiate-table.svg'
import { ReactComponent as refine } from './assets/refine-table.svg'

interface OwnProps {
  activeERRN: string
}

type Props = OwnProps

const errnTableData = (activeERRN: string) => [
  [
    {
      BackgroundImage: refine,
      label: 'refine',
      className: `rounded-tl-3xl ${
        activeERRN ? 'bg-gtPink text-white' : 'bg-gtGray text-neutral-gray-800'
      }`,
    },
    {
      BackgroundImage: exploit,
      label: 'exploit',
      className: `rounded-tr-3xl ${
        activeERRN === 'exploit' ? 'bg-gtPink text-white' : 'bg-gtGray text-neutral-gray-800'
      }`,
    },
  ],
  [
    {
      BackgroundImage: evaluate,
      label: 'evaluate',
      className: `rounded-bl-3xl ${
        activeERRN === 'evaluate' ? 'bg-gtPink text-white' : 'bg-gtGray text-neutral-gray-800'
      }`,
    },
    {
      BackgroundImage: negotiate,
      label: 'negotiate',
      className: `rounded-br-3xl ${
        activeERRN === 'negotiate' ? 'bg-gtPink text-white' : 'bg-gtGray text-neutral-gray-800'
      }`,
    },
  ],
]

export const ERRNTable = (props: Props) => {
  return (
    <div className="grid bg-white border shadow rounded col-span-2 ">
      <h2 className="text-md pl-5 pt-3 text-neutral-gray-800">
        Recommendation
      </h2>
      <div className="relative bg-white p-5 pl-10 w-auto">
        <div className="w-auto transform -rotate-90 absolute text-neutral-gray-800 -left-8 inset-y-1/2">
          Productivity
        </div>
        <div className="grid gap-px w-72 text-white">
          {Children.toArray(
            errnTableData(props.activeERRN).map((data) => (
              <div className="grid grid-flow-col auto-cols-fr gap-px place-content-center">
                {Children.toArray(
                  data.map(({ BackgroundImage, label, className }) => (
                    <div
                      className={`w-full h-36 bg-no-repeat bg-center relative ${className} grid place-content-center`}
                    >
                      <BackgroundImage />
                      <div className="justify-center flex align-middle  absolute bottom-1 inset-x-1/2 uppercase">
                        {label}
                      </div>
                    </div>
                  ))
                )}
              </div>
            ))
          )}
        </div>
        <div className="text-center pt-1 text-neutral-gray-800">
          Effectiveness
        </div>
      </div>
    </div>
  )
}
