import { Dispatch } from 'react'
import {
  EDIT_PLAN_SUCCESS,
  EditPlanActionTypes,
} from './redux/actions/EditPlanActionTypes'
import {
  CURVE_OUTPUT,
  DELETE_CURVE_DATA,
  EDITED_COSTS,
  PLAN_CREATE_SUCCESS,
  PUT_PLAN_SUCCESS,
  PlanActionTypes,
} from './redux/actions/PlanActionTypes'
import {
  CHANGE_KPIS_COMPARISONS_ATTEMPT,
  CHANGE_KPIS_COMPARISONS_FAIL,
  CHANGE_KPIS_COMPARISONS_SUCCESS,
  PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT,
  PLAN_COMPARISONS_UPDATE_BASE_FAIL,
  PLAN_COMPARISONS_UPDATE_BASE_SUCCESS,
  PLAN_COMPARISON_DATA,
  PLAN_COMPARISON_DELETE_ATTEMPT,
  PLAN_COMPARISON_DELETE_FAIL,
  PLAN_COMPARISON_DELETE_SUCCESS,
  PLAN_GET_COMPARISONS_DATA_ATTEMPT,
  PLAN_GET_COMPARISONS_DATA_FAIL,
  PLAN_GET_COMPARISONS_DATA_SUCCESS,
  PLAN_NAME_COMPARISONS_ATTEMPT,
  PLAN_NAME_COMPARISONS_FAIL,
  PLAN_NAME_COMPARISONS_SUCCESS,
  PlanComparisonActionTypes,
  UPDATE_PLAN_COMPARISON,
} from './redux/actions/PlanComparisonActionTypes'
import {
  ADD_OR_CUT_BUDGET_ATTEMPT,
  ADD_OR_CUT_BUDGET_FAIL,
  AddOrCutActionTypes,
} from './redux/actions/SPOFlowActions/AddOrCutBudgetActionTypes'
import {
  PLAN_LOAD_COMPLETE,
  SOCKET_CALCULATE_CURVE_DATA,
  SOCKET_CALCULATE_CURVE_DATA_FAIL,
  SOCKET_CALCULATE_CURVE_DATA_SUCCESS,
  SOCKET_CREATE_PLAN_ATTEMPT,
  SOCKET_CREATE_PLAN_ERROR,
  SOCKET_CREATE_PLAN_FROM_EDITOR_SUCCESS,
  SOCKET_CREATE_PLAN_SUCCESS,
  SOCKET_CREATE_SCENARIO_ERROR,
  SOCKET_DELETE_COMPARISON_SUCCESS,
  SOCKET_DELETE_PLAN_COMPARISON,
  SOCKET_EDIT_COSTS,
  SOCKET_EDIT_COSTS_SUCCESS,
  SOCKET_EDIT_CPM,
  SOCKET_EDIT_CPM_SUCCESS,
  SOCKET_EDIT_PLAN_BUDGET,
  SOCKET_EDIT_PLAN_BUDGET_FAIL,
  SOCKET_EDIT_PLAN_BUDGET_SUCCESS,
  SOCKET_EDIT_PLAN_NAME,
  SOCKET_EDIT_PLAN_NAME_FAIL,
  SOCKET_EDIT_PLAN_NAME_SUCCESS,
  SOCKET_EDIT_WEEKLY_COSTS,
  SOCKET_EDIT_WEEKLY_COSTS_SUCCESS,
  SOCKET_GET_PLAN_COMPARISON_DATA,
  SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS,
  SOCKET_SET_CONSTRAINTS_ERROR,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS,
  SOCKET_UPDATE_BASE_COMPARISON,
  SOCKET_UPDATE_BASE_COMPARISON_SUCCESS,
  SOCKET_UPDATE_COMPARISON_KPIS,
  SOCKET_UPDATE_COMPARISON_KPIS_SUCCESS,
  SOCKET_UPDATE_COMPARISON_NAME,
  SOCKET_UPDATE_COMPARISON_NAME_SUCCESS,
  SOCKET_UPLOAD_IN_CHANNEL_PLAN,
  SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR,
  SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS,
  SOCKET_UPLOAD_PLAN,
  SOCKET_UPLOAD_PLAN_ERROR,
  SOCKET_UPLOAD_PLAN_SUCCESS,
  SocketActionTypes,
} from './redux/actions/SocketActionTypes'
import { IUser } from './types'
import { CreatePlan } from './types/CreatePlanTypes'
import {
  CPMs,
  Constraint,
  EditPlanRequest,
  Plan,
  PlanOutput,
} from './types/PlanTypes'
import {
  multiplyTransforms,
  prepareTransforms,
} from './utils/calculateCurveData'

const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

declare var MozWebSocket: {
  prototype: WebSocket
  new (url: string): WebSocket
  new (url: string, prototcol: string): WebSocket
  new (url: string, prototcol: string[]): WebSocket
  OPEN: number
  CLOSING: number
  CONNECTING: number
  CLOSED: number
}

var Socket: typeof WebSocket = WebSocket || MozWebSocket

const uri =
  process.env.NODE_ENV === 'development'
    ? `${protocol}//${window.location.hostname}:4000`
    : `${protocol}//${window.location.hostname}/crayon`

var connection = new WebSocket(uri)

export const configureSocket = (
  dispatch: Dispatch<
    | PlanActionTypes
    | SocketActionTypes
    | EditPlanActionTypes
    | PlanComparisonActionTypes
  >
) => {
  connection.onopen = function () {
    // connection is opened and ready to use
    const message = { type: 'CONNECTION_OPEN', data: 'From PORTAL' }
    // connection.send(JSON.stringify(message));
  }

  connection.onclose = function () {}

  connection.onmessage = (message) => {
    const result = JSON.parse(message.data)
    switch (result.type) {
      case SOCKET_EDIT_PLAN_NAME_SUCCESS:
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result.data })
        break
      case SOCKET_UPDATE_BASE_COMPARISON_SUCCESS:
        dispatch({ type: UPDATE_PLAN_COMPARISON })
        break
      case SOCKET_DELETE_COMPARISON_SUCCESS:
        dispatch({ type: UPDATE_PLAN_COMPARISON })
        break
      case SOCKET_UPDATE_COMPARISON_NAME_SUCCESS:
        dispatch({ type: UPDATE_PLAN_COMPARISON })
        break
      case SOCKET_UPDATE_COMPARISON_KPIS_SUCCESS:
        dispatch({ type: UPDATE_PLAN_COMPARISON })
        break
      case SOCKET_GET_PLAN_COMPARISON_DATA_SUCCESS:
        dispatch({ type: PLAN_COMPARISON_DATA, payload: result.data })
        break
      case SOCKET_CREATE_PLAN_SUCCESS:
        dispatch({ type: PLAN_CREATE_SUCCESS, payload: result.data })
        break
      case SOCKET_UPLOAD_PLAN_SUCCESS:
        dispatch({ type: PLAN_CREATE_SUCCESS, payload: result.data })
        break
      case SOCKET_EDIT_PLAN_BUDGET_SUCCESS:
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result.data })
        dispatch({ type: PLAN_LOAD_COMPLETE })
        break
      case SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR:
        dispatch({
          type: SOCKET_SET_CONSTRAINTS_ERROR,
          payload: result.data.error,
        })
        break
      case SOCKET_CALCULATE_CURVE_DATA_SUCCESS:
        dispatch({ type: CURVE_OUTPUT, result: result.data })
        break
      case SOCKET_EDIT_CPM_SUCCESS:
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result.data })
        break
      case SOCKET_EDIT_COSTS_SUCCESS:
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result.data })
        break
      case SOCKET_EDIT_WEEKLY_COSTS_SUCCESS:
        dispatch({ type: PUT_PLAN_SUCCESS, payload: result.data })
        dispatch({ type: EDITED_COSTS })
        break
      case SOCKET_CREATE_SCENARIO_ERROR:
        dispatch({ type: SOCKET_CREATE_SCENARIO_ERROR, payload: result.data })
        break
      case SOCKET_UPLOAD_PLAN_ERROR:
        dispatch({ type: SOCKET_CREATE_SCENARIO_ERROR, payload: result.data })
        break
      case SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS:
        dispatch({ type: PLAN_LOAD_COMPLETE })
        dispatch({ type: PLAN_CREATE_SUCCESS, payload: result.data })
        break
      case SOCKET_CREATE_PLAN_FROM_EDITOR_SUCCESS:
        dispatch({ type: EDIT_PLAN_SUCCESS, payload: result.data })
        break
    }
  }

  return connection
}

export const createSPOScenario =
  (attrs: CreatePlan, name: string) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const message = {
      type: SOCKET_CREATE_PLAN_ATTEMPT,
      user: attrs.user,
      configId: attrs.configId,
      attrs: attrs.attrs,
      name: name,
      returnAction: SOCKET_CREATE_PLAN_SUCCESS,
      constraints: attrs.constraints,
    }

    try {
      dispatch({
        type: SOCKET_CREATE_PLAN_ATTEMPT,
      })
      connection.send(JSON.stringify(message))
    } catch (error) {
      dispatch({
        type: SOCKET_CREATE_PLAN_ERROR,
      })
    }
  }

export const createPlanFromEditor =
  (configId: string, user: IUser, newScenarioAttrs: EditPlanRequest) =>
  async (dispatch: Dispatch<SocketActionTypes | EditPlanActionTypes>) => {
    const message = {
      type: SOCKET_CREATE_PLAN_ATTEMPT,
      user: user,
      configId: configId,
      attrs: newScenarioAttrs,
      returnAction: SOCKET_CREATE_PLAN_FROM_EDITOR_SUCCESS,
    }

    try {
      connection.send(JSON.stringify(message))
    } catch (error) {
      dispatch({
        type: SOCKET_CREATE_PLAN_ERROR,
      })
    }
  }

export const convertBlobTo = async (type: string, blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', function () {
      resolve(reader.result)
    })
    // @ts-ignore
    // reader[`readAs${type}`](blob)
    reader.readAsDataURL(blob)
  })
}

export const uploadSPOPlan =
  (
    id: number,
    file: any,
    user: IUser,
    currency: any,
    name: string,
    constraints?: Constraint[]
  ) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const base64 = convertBlobTo('DataURL', file)
      .then((data) => {
        const message = {
          type: SOCKET_UPLOAD_PLAN,
          id: id,
          file: data,
          user: user,
          currency: currency,
          name: name,
          ...(constraints && { constraints: constraints }),
        }

        try {
          connection.send(JSON.stringify(message))

          dispatch({
            type: SOCKET_UPLOAD_PLAN_SUCCESS,
          })
        } catch (error) {
          dispatch({
            type: SOCKET_UPLOAD_PLAN_ERROR,
          })
        }
      })
      .catch((e) => console.log('GOT ERROR ', e))
  }

export const uploadInChannelSPOPlan =
  (id: number, file: any, user: IUser, timeRange: number[]) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const base64 = convertBlobTo('DataURL', file).then((data) => {
      const message = {
        type: SOCKET_UPLOAD_IN_CHANNEL_PLAN,
        id: id,
        file: data,
        user: user,
        timeRange: timeRange,
      }

      try {
        connection.send(JSON.stringify(message))
        dispatch({
          type: SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS,
        })
      } catch (error) {
        dispatch({
          type: SOCKET_UPLOAD_IN_CHANNEL_PLAN_ERROR,
        })
      }
    })
  }

export const EditSPOPlanName =
  (id: number, user: IUser, name: string) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const message = {
      type: SOCKET_EDIT_PLAN_NAME,
      user: user,
      scenarioId: id,
      name: name,
    }
    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: SOCKET_EDIT_PLAN_NAME_SUCCESS })
    } catch (error) {
      dispatch({ type: SOCKET_EDIT_PLAN_NAME_FAIL })
    }
  }

export const EditSPOBudget =
  (plan: PlanOutput, user: IUser) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const message = {
      type: SOCKET_EDIT_PLAN_BUDGET,
      user: user,
      scenario: plan,
    }
    try {
      dispatch({ type: SOCKET_EDIT_PLAN_BUDGET })
      connection.send(JSON.stringify(message))
      dispatch({ type: SOCKET_EDIT_PLAN_BUDGET_SUCCESS })
    } catch (error) {
      dispatch({ type: SOCKET_EDIT_PLAN_BUDGET_FAIL })
    }
  }

export const AddOrCutBudget =
  (plan: Plan, user: IUser) =>
  async (dispatch: Dispatch<SocketActionTypes | AddOrCutActionTypes>) => {
    const message = {
      type: SOCKET_EDIT_PLAN_BUDGET,
      user: user,
      scenario: plan,
    }
    try {
      dispatch({ type: ADD_OR_CUT_BUDGET_ATTEMPT })
      connection.send(JSON.stringify(message))
    } catch (error) {
      dispatch({ type: ADD_OR_CUT_BUDGET_FAIL, error: '' })
    }
  }

export const EditSPOConstraints =
  (plan: Plan, planId: number, user: IUser) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const message = {
      type: SOCKET_SET_CONSTRAINTS_FROM_TABLE,
      user: user,
      scenarioId: planId,
      scenario: plan,
    }
    try {
      dispatch({ type: SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT })
      connection.send(JSON.stringify(message))
    } catch (error) {
      dispatch({ type: SOCKET_SET_CONSTRAINTS_FROM_TABLE_ERROR })
    }
  }

export const CalculateCurveData =
  (calcData: any, id: number, level: string) =>
  async (dispatch: Dispatch<SocketActionTypes>) => {
    const curveCalcData = prepareTransforms(calcData, level)

    if (Object.keys(curveCalcData).length !== 0) {
      let multiplier = 0.083333333
      for (let i = 0; i < 24; i++) {
        const putData = multiplyTransforms(curveCalcData, multiplier)
        const message = {
          type: SOCKET_CALCULATE_CURVE_DATA,
          scenarioId: id,
          calcData: putData,
        }
        connection.send(JSON.stringify(message))
        multiplier += 0.083333333
      }
      CalculateCurveData(Object.values(curveCalcData), id, level)
    }
  }

export const EditComparisonName =
  (name: string, id: string) =>
  async (dispatch: Dispatch<PlanComparisonActionTypes>) => {
    dispatch({ type: PLAN_NAME_COMPARISONS_ATTEMPT })

    const message = {
      type: SOCKET_UPDATE_COMPARISON_NAME,
      id: id,
      name: name,
    }

    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: PLAN_NAME_COMPARISONS_SUCCESS })
    } catch (error) {
      dispatch({ type: PLAN_NAME_COMPARISONS_FAIL })
    }
  }

export const ChangeComparisonKPIs =
  (kpis: string[], id: string) =>
  async (dispatch: Dispatch<PlanComparisonActionTypes>) => {
    dispatch({ type: CHANGE_KPIS_COMPARISONS_ATTEMPT })

    const message = {
      type: SOCKET_UPDATE_COMPARISON_KPIS,
      id: id,
      kpis: kpis,
    }

    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: CHANGE_KPIS_COMPARISONS_SUCCESS })
    } catch (error) {
      dispatch({ type: CHANGE_KPIS_COMPARISONS_FAIL })
    }
  }

export const ChangeBasePlanComparison =
  (id: string, plan_ids: number[]) =>
  async (dispatch: Dispatch<SocketActionTypes | PlanComparisonActionTypes>) => {
    dispatch({ type: PLAN_COMPARISONS_UPDATE_BASE_ATTEMPT })

    const message = {
      type: SOCKET_UPDATE_BASE_COMPARISON,
      id: id,
      plan_ids: plan_ids,
    }
    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: PLAN_COMPARISONS_UPDATE_BASE_SUCCESS })
    } catch (error) {
      dispatch({ type: PLAN_COMPARISONS_UPDATE_BASE_FAIL })
    }
  }

export const GetPlanComparisonData =
  (id: string) => async (dispatch: Dispatch<PlanComparisonActionTypes>) => {
    dispatch({ type: PLAN_GET_COMPARISONS_DATA_ATTEMPT })

    const message = {
      type: SOCKET_GET_PLAN_COMPARISON_DATA,
      id: id,
    }

    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: PLAN_GET_COMPARISONS_DATA_SUCCESS })
    } catch (error) {
      dispatch({ type: PLAN_GET_COMPARISONS_DATA_FAIL })
    }
  }

export const DeleteCurveData =
  () => async (dispatch: Dispatch<SocketActionTypes>) => {
    try {
      dispatch({ type: DELETE_CURVE_DATA })
    } catch (error) {
      dispatch({ type: SOCKET_CALCULATE_CURVE_DATA_FAIL })
    }
  }

export const DeletePlanComparison =
  (id: string) =>
  async (dispatch: Dispatch<SocketActionTypes | PlanComparisonActionTypes>) => {
    dispatch({ type: PLAN_COMPARISON_DELETE_ATTEMPT })
    const message = {
      type: SOCKET_DELETE_PLAN_COMPARISON,
      id: id,
    }

    try {
      connection.send(JSON.stringify(message))
      dispatch({ type: PLAN_COMPARISON_DELETE_SUCCESS })
    } catch (error) {
      dispatch({ type: PLAN_COMPARISON_DELETE_FAIL })
    }
  }

// export default configureSocket()d

export const EditCPM =
  (user: IUser, id: number, value: number, transform: string, cpms: CPMs) =>
  (dispatch: Dispatch<SocketActionTypes>) => {
    const message = {
      type: SOCKET_EDIT_CPM,
      user: user,
      scenarioId: id,
      value: value,
      identifier: transform,
      cpms: cpms,
    }
    connection.send(JSON.stringify(message))
    return
  }

export const EditCosts = (
  user: IUser,
  id: number,
  value: number,
  name: string,
  transforms: any[],
  cpms: CPMs
) => {
  const message = {
    type: SOCKET_EDIT_COSTS,
    user: user,
    scenarioId: id,
    value: value,
    name: name,
    transforms: transforms,
    cpms: cpms,
  }
  connection.send(JSON.stringify(message))
  return
}

export const EditWeeklyCosts = (
  user: IUser,
  id: number,
  gridData: number[][],
  transforms: any[],
  cpms: CPMs,
  date_format: any
) => {
  const message = {
    type: SOCKET_EDIT_WEEKLY_COSTS,
    user: user,
    scenarioId: id,
    gridData: gridData,
    transforms: transforms,
    cpms: cpms,
    date_format: date_format,
  }
  connection.send(JSON.stringify(message))
  return
}
