import { Plan } from '../../types/PlanTypes'
import { MediaChannelAllocationBudget } from '../../types/MediaChannelAllocationTypes'
import {
  GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
  MediaChannelActionTypes,
} from '../actions/MediaChannelActionTypes'
import {
  getMediaChannelAllocations,
  getScenarioBrands,
} from '../../utils/PlanUtils'
import _ from 'lodash'

interface IDefaultState {
  budgets: MediaChannelAllocationBudget[]
  scenario: Plan | null
}

const defaultState: IDefaultState = {
  budgets: [],
  scenario: null,
}

const mediaChannelAllocationReducer = (
  state: IDefaultState = defaultState,
  action: MediaChannelActionTypes
) => {
  switch (action.type) {
    case GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET:
      if (_.isEmpty(state.budgets)) {
        return {
          ...state,
          budgets: getAllBrandBudgets(action.payload),
          scenario: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const getAllBrandBudgets = (scenario: Plan) => {
  let budget: MediaChannelAllocationBudget = {} as MediaChannelAllocationBudget
  const scenarioBrands = getScenarioBrands(scenario)
  for (let brand_grouping of Object.values(scenarioBrands)) {
    const brand = { brand: [brand_grouping['key']] }
    let mediaChannelAllocations = getMediaChannelAllocations(scenario, brand)
    for (let allocation of mediaChannelAllocations) {
      let key = allocation.identifiers.media
      let mixedTotal = allocation.mainInput.mixedTotal
      let optimisedTotal = allocation.mainInput.optimisedTotal
      let outputTotal = allocation.mainInput.outputTotal
      if (key in budget) {
        budget[key].mixedTotal += mixedTotal

        budget[key].optimisedTotal += optimisedTotal
        budget[key].outputTotal += outputTotal
      } else {
        budget[key] = {
          name: allocation.identifiers.media,
          mixedTotal: mixedTotal,
          optimisedTotal: optimisedTotal,
          outputTotal: outputTotal,
        }
      }
    }
  }
  return budget
}

export default mediaChannelAllocationReducer
