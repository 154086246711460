import { Accordion } from '@gain-theory/accordion'
import { memo } from 'react'
import { FaqAccordionProps } from './types'

export const FaqAccordion = memo(
  ({ question, answer, disabled }: FaqAccordionProps) => {
    return (
      <Accordion title={question} disabled={disabled}>
        {answer}
      </Accordion>
    )
  }
)
