import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string
}

type Props = OwnProps

const MediaIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
        <path d="M34.5322 35.4185H2.88608C2.39692 35.4185 2 35.0215 2 34.5324V7.94955C2 7.4604 2.39693 7.06348 2.88608 7.06348H29.4689C29.9581 7.06348 30.355 7.46041 30.355 7.94955V28.5825H34.5323C35.0214 28.5825 35.4183 28.9795 35.4183 29.4686V34.5319C35.4183 35.0211 35.0214 35.418 34.5323 35.418L34.5322 35.4185ZM3.7726 33.6463H33.6457V30.3551H29.4684C28.9793 30.3551 28.5824 29.9582 28.5824 29.469V8.83607H3.77142L3.7726 33.6463Z"/>
<path d="M61.115 30.355H29.4688C28.9797 30.355 28.5828 29.9581 28.5828 29.4689V2.88608C28.5828 2.39692 28.9797 2 29.4688 2H61.115C61.6042 2 62.0011 2.39693 62.0011 2.88608V29.4689C62.0011 29.9581 61.6042 30.355 61.115 30.355ZM30.3554 28.5829H60.2285V3.77193H30.3554V28.5829Z" fill="#5E6A73"/>
<path d="M61.115 56.9368H34.5322C34.043 56.9368 33.6461 56.5399 33.6461 56.0507V35.4178H29.4688C28.9797 35.4178 28.5828 35.0208 28.5828 34.5317V29.4684C28.5828 28.9792 28.9797 28.5823 29.4688 28.5823H61.115C61.6042 28.5823 62.0011 28.9792 62.0011 29.4684V56.0512C62.0011 56.5403 61.6042 56.9373 61.115 56.9373L61.115 56.9368ZM35.4187 55.1647H60.2297V30.3538H30.3565V33.645H34.5338C35.023 33.645 35.4199 34.0419 35.4199 34.5311L35.4187 55.1647Z"/>
<path d="M34.5322 61.9998H2.88608C2.39692 61.9998 2 61.6028 2 61.1137V34.5309C2 34.0417 2.39693 33.6448 2.88608 33.6448H34.5322C35.0214 33.6448 35.4183 34.0417 35.4183 34.5309V61.1137C35.4183 61.6028 35.0214 61.9998 34.5322 61.9998ZM3.7726 60.2277H33.6457V35.4167H3.7726V60.2277Z" fill="#5E6A73"/>
<path d="M38.5621 25.2915C38.4089 25.2915 38.256 25.2517 38.1191 25.1726C37.8451 25.0144 37.676 24.7219 37.676 24.4054V8.59509C37.676 8.27863 37.8451 7.98613 38.1191 7.8279C38.393 7.66967 38.7312 7.66967 39.0051 7.8279L52.6964 15.7331C52.9704 15.8913 53.1395 16.1838 53.1395 16.5003C53.1395 16.8167 52.9704 17.1092 52.6964 17.2674L39.0051 25.1726C38.8677 25.2522 38.7149 25.2915 38.5621 25.2915ZM39.4482 10.1304V22.8715L50.4818 16.5012L39.4482 10.1304Z" />
<path d="M17.3644 58.2027C15.1306 58.2027 13.3137 56.3858 13.3137 54.152C13.3137 51.9182 15.1306 50.1013 17.3644 50.1013C17.6366 50.1013 17.9092 50.1284 18.1736 50.1822C20.0516 50.5638 21.4151 52.2329 21.4151 54.152C21.4151 56.3858 19.5977 58.2027 17.3644 58.2027L17.3644 58.2027ZM17.3644 51.8734C16.1081 51.8734 15.0858 52.8956 15.0858 54.152C15.0858 55.4084 16.108 56.4306 17.3644 56.4306C18.6207 56.4306 19.643 55.4084 19.643 54.152C19.643 53.0724 18.8767 52.1335 17.8211 51.9191C17.6719 51.8889 17.5182 51.8735 17.3645 51.8735L17.3644 51.8734Z"/>
<path d="M17.9974 51.937C17.5082 51.937 17.1113 51.5401 17.1113 51.0509V39.5952C17.1113 39.2972 17.2614 39.0188 17.5101 38.8551C17.7587 38.691 18.0738 38.6634 18.3473 38.781C18.615 38.8963 24.8926 41.6525 23.9265 46.112C23.823 46.5903 23.351 46.8941 22.8727 46.7901C22.3944 46.6866 22.0906 46.2146 22.1941 45.7368C22.6385 43.6865 20.3767 41.9614 18.8826 41.0667L18.8831 51.051C18.8835 51.5402 18.4866 51.9371 17.9974 51.9371L17.9974 51.937Z" />
</g>
      </g>
    </svg>
  )
}

export default MediaIcon
