import { memo } from 'react'
import { Typography } from '@gain-theory/typography'
import { SupportProps } from './types'
import { ContactInfoCard } from './contact-info-card'
import { ContactCentralShape, ContactRightShape } from '../assets'
import { useMoveXBg } from '../hooks'

const REFERENCE_WIDTH = 1280
const CONTACT_US_TITLE = 'Contact'
const CONTACT_US_DESCRIPTION =
  'If you have any questions or would like to learn more about additional products, please contact the support team.'

export const Support = memo(({ id, contact, onMailClick }: SupportProps) => {
  const { moveXBg } = useMoveXBg({ referenceWidth: REFERENCE_WIDTH })

  const RightShapeBg = (
    <div
      style={{ left: 'auto', right: moveXBg }}
      className="-mx-xxl absolute m-auto flex-grow-0 z-0 overflow-hidden top-0"
    >
      <ContactRightShape className="stroke-brand-primary-main" />
    </div>
  )

  const CentralShapeBg = (
    <div className="-mx-xxl absolute top-auto m-auto order-0 flex-grow-0 z-0 self-center justify-self-center min-w[860px] w-[860px] max-w-7xl xl:w-[67.5%]">
      <ContactCentralShape className="fill-brand-primary-main" />
    </div>
  )

  const ContactTitleAndDescription = (
    <div className="flex flex-col gap-xl items-start">
      <Typography.H4 className="text-text-white">
        {CONTACT_US_TITLE}
      </Typography.H4>
      <Typography.Subtitle1 className="flex text-start w-2/3 text-text-white 3xl:w-full">
        {CONTACT_US_DESCRIPTION}
      </Typography.Subtitle1>
    </div>
  )

  return (
    <div
      id={id}
      className="mx-xxl col-span-full grid grid-cols-6 xl:grid-cols-12 gap-y-xxl relative py-xxxxxl auto-rows-min overflow-hidden"
    >
      {RightShapeBg}
      {CentralShapeBg}
      <div className="flex flex-row gap-md z-20 col-span-full items-center justify-center">
        <ContactInfoCard {...contact} onMailClick={onMailClick} />
        {ContactTitleAndDescription}
      </div>
    </div>
  )
})
