import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import MediaChannelIcon from '../../../icons/media/MediaChannelIcon'
import PartnerIcon from '../../../icons/spo/PartnerIcon'
import Truncate from 'react-truncate'
import { GroupingLevel } from '../../../types/PlanTypes'

interface OwnProps {
  filtersText: string[]
  onEdit: (section: number) => void
}
type Props = OwnProps
const MediaSummary: FC<Props> = (props) => {
  const { planItem } = useSelector((state: AppState) => state.plans)
  const { settings } = useSelector((state: AppState) => state.settings)

  const editNumber = planItem.config.grouping_levels.findIndex((x: GroupingLevel) => x.key === "media")


  const icons = () => {
    return props.filtersText.slice(0, 3).map((channel, index) => {
      if (planItem.in_channel) {
        return (
          <div key={index}>
            <PartnerIcon className="w-7 h-7" />
          </div>
        )
      } else {
        return (
          <div key={index}>
            <MediaChannelIcon
              type={channel.toLowerCase()}
              className="w-7 h-7"
            />
          </div>
        )
      }
    })
  }
  return (
    <div className="text-neutral-gray-1100  text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold uppercase">
          {planItem.options.in_channel ? 'Partners' : settings?.media_plural}
        </span>
        {!planItem.locked_at && (
          <button
            onClick={(_) => props.onEdit(editNumber)}
            className="text-gtPink text-xxs"
          >
            edit
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-1">{icons()}</div>
        <Truncate className="text-xxs">{props.filtersText.join(', ')}</Truncate>
      </div>
    </div>
  )
}

export default MediaSummary
