import { Typography } from '@gain-theory/typography'
import { Children, memo } from 'react'
import { HelpfulLinksBg } from '../assets'
import { useFeatureContentHeight } from '../hooks'
import { LinkCard } from './link-card'
import { HelpfulLinksProps } from './types'

const HELPFUL_LINKS_TITLE = 'Helpful links'

export const HelpfulLinks = memo(
  ({ id, links, onCardClick }: HelpfulLinksProps) => {
    const { rootRef, bgRef, height } = useFeatureContentHeight()

    const Background = (
      <div
        ref={bgRef}
        className="-mx-xxl absolute top-0 m-auto order-0 flex-grow-0 z-0 self-center justify-self-center min-w-[1044px] w-[1044px] max-w-7xl xl:w-[67.5%]"
      >
        <HelpfulLinksBg className="fill-brand-primary-main" />
      </div>
    )

    const Title = (
      <div className="col-span-full items-center justify-center z-10">
        <Typography.H4 className="text-center text-text-white">
          {HELPFUL_LINKS_TITLE}
        </Typography.H4>
      </div>
    )

    const LinkCards = (
      <div className="col-span-full flex flex-row flex-wrap xl:col-span-10 xl:col-start-2 gap-md z-10 h-max items-center justify-center">
        {Children.toArray(
          links.map((link, index) => (
            <div key={index} className="min-w-fit">
              <LinkCard {...link} onCardClick={onCardClick} />
            </div>
          ))
        )}
      </div>
    )

    return (
      <div
        id={id}
        ref={rootRef}
        style={{ height: height }}
        className="mx-xxl col-span-full grid grid-cols-6 auto-rows-min xl:grid-cols-12 gap-y-xxl relative pt-xxxxxl"
      >
        {Background}
        {Title}
        {LinkCards}
      </div>
    )
  }
)
