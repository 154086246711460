import { Plan } from '../../../types/PlanTypes'

export const PLAN_CREATE_ATTEMPT = 'PLAN_CREATE_ATTEMPT'
export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS'
export const PLAN_CREATE_FAIL = 'PLAN_CREATE_FAIL'

interface CreatePlanAttempt {
  type: typeof PLAN_CREATE_ATTEMPT
}

interface CreatePlanSuccess {
  type: typeof PLAN_CREATE_SUCCESS
  scenario: Plan
}

interface CreatePlanFail {
  type: typeof PLAN_CREATE_FAIL
  error: string
}

export type CreatePlanActionTypes =
  | CreatePlanAttempt
  | CreatePlanSuccess
  | CreatePlanFail

