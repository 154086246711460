import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaqProps } from '../../faq'
import { AppState } from '../../../../redux/reducers/RootReducer'

export function useFaqs() {
  const { systemLinks } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const [faqs, setFaqs] = useState<FaqProps['faqs']>([])

  const mapFaqs = () => {
    const hasFaq =
      systemLinks !== null && !!systemLinks.faqs && systemLinks.faqs.length > 0

    let mappedFaqs: FaqProps['faqs'] = []

    if (hasFaq) {
      mappedFaqs = systemLinks.faqs.map((faq, index) => ({
        id: `faq-${index + 1}`,
        question: faq.faq,
        answer: faq.answer,
      }))
    }

    setFaqs(mappedFaqs)
  }

  useEffect(() => {
    mapFaqs()
  }, [systemLinks?.faqs])

  return { faqs }
}
