import { Dispatch } from 'redux'
import {
  COPY_PLAN_ATTEMPT,
  DELETE_PLAN_ATTEMPT,
  LOCK_PLAN_ATTEMPT,
  PLAN_CHANGE_KPI_DISPLAY,
  PLAN_CREATE_FILTERS,
  PLAN_GET_ITEM_ATTEMPT,
  PLAN_GET_ITEM_BY_ID_ATTEMPT,
  PLAN_GET_ITEM_FAIL,
  PLAN_GET_ITEM_SUCCESS,
  PLAN_GET_SUMMARIES_ATTEMPT,
  PLAN_GET_SUMMARIES_FAIL,
  PLAN_GET_SUMMARIES_SUCCESS,
  PLAN_SELECT_ALL_LEVELS,
  PLAN_SELECT_FILTER,
  PLAN_SELECT_LEVEL,
  PLAN_SELECT_KPI_OUTPUT,
  PLAN_SELECT_REGION,
  PlanActionTypes,
  PUT_PLAN,
  PUT_PLAN_FAIL,
  PUT_PLAN_SUCCESS,
  SHARE_PLAN,
  TOGGLE_CARRYOVER,
  TOGGLE_CARRYOVER_FAIL,
  TOGGLE_CARRYOVER_SUCCESS,
  TOGGLE_HALO,
  TOGGLE_HALO_FAIL,
  TOGGLE_HALO_SUCCESS,
  TOGGLE_SHARE_PLAN_MODAL,
  DISCARD_COST_CHANGES,
} from './PlanActionTypes'
import api from '../../api'
import {
  AllBrandsActionTypes,
  CREATE_ALL_BRANDS,
  SELECT_ALL_BRANDS,
} from './AllBrandsActionTypes'
import {
  GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
  MediaChannelActionTypes,
  PLAN_BAR_MOVE_ENDED,
  PLAN_BAR_MOVE_STARTED,
} from './MediaChannelActionTypes'
import {
  CPMs,
  GroupingLevel,
  Options,
  Plan,
  PlanGroupingLevel,
  STransformation,
} from '../../types/PlanTypes'
import { IUser } from '../../types'

export const GetPlanSummaryList = () => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: PLAN_GET_SUMMARIES_ATTEMPT })


  try {
    const response = await api.Plans.getSummaries()
    dispatch({ type: PLAN_GET_SUMMARIES_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PLAN_GET_SUMMARIES_FAIL })
  }
}

export const GetPlanItem = (planId: string) => async (
  dispatch: Dispatch<
    PlanActionTypes | AllBrandsActionTypes | MediaChannelActionTypes
  >
) => {
  dispatch({ type: PLAN_GET_ITEM_ATTEMPT })
  try {
    const response = await api.Plans.getPlan(planId)
    dispatch({ type: PLAN_GET_ITEM_SUCCESS, payload: response.data })
    // dispatch({ type: CREATE_ALL_BRANDS, payload: response.data })
    // dispatch({ type: PLAN_CREATE_FILTERS, payload: response.data })
    // dispatch({
    //   type: GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
    //   payload: response.data,
    // })
    // dispatch({ type: SELECT_ALL_BRANDS, payload: true })
  } catch (e) {
    dispatch({ type: PLAN_GET_ITEM_FAIL })
  }
}

export const GetPlanItemAllowAccess = (planId: string, admin: boolean, user: IUser) => async (
  dispatch: Dispatch<
    PlanActionTypes | AllBrandsActionTypes | MediaChannelActionTypes
  >
) => {
  dispatch({ type: PLAN_GET_ITEM_ATTEMPT })
  try {
    if(!admin){
      const res = await api.Session.updateUserAllowed({...user, admin: true}, user.id.toString())
    }
    const response = await api.Plans.getPlan(planId)
    dispatch({ type: PLAN_GET_ITEM_SUCCESS, payload: response.data })
    dispatch({ type: CREATE_ALL_BRANDS, payload: response.data })
    dispatch({ type: PLAN_CREATE_FILTERS, payload: response.data })
    dispatch({
      type: GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
      payload: response.data,
    })
    dispatch({ type: SELECT_ALL_BRANDS, payload: true })
    if(!admin){
      const res = await api.Session.updateUserAllowed({...user, admin: false}, user.id.toString())
    }
  } catch (e) {
    dispatch({ type: PLAN_GET_ITEM_FAIL })
  }
}

export const GetPlanById = (planId: string) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: PLAN_GET_ITEM_BY_ID_ATTEMPT })

  try {
    const response = await api.Plans.getPlan(planId)
    return response.data
  } catch (e) {}
}

export const UpdateAllBrandsMediaBar = (
  plan: Plan,
  transforms: STransformation[],
  scenarioFilters: Record<string, string[]>
) => async (
  dispatch: Dispatch<
    PlanActionTypes | AllBrandsActionTypes | MediaChannelActionTypes
  >
) => {
  const lowestFilterLevel =
    plan.config.grouping_levels[plan.config.grouping_levels.length - 1]

  try {
    dispatch({ type: PLAN_BAR_MOVE_STARTED })
    let newS: Plan = { ...plan, transformations: transforms }
    const response = await api.Plans.putPlan(newS)
    dispatch({ type: PUT_PLAN_SUCCESS, payload: response.data })
    const groupingLevels = plan.config.grouping_levels
    groupingLevels.forEach((groupingLevel) => {
      if (groupingLevel.key === 'media') {
        return groupingLevel
      }
    })
    dispatch({
      type: GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
      payload: response.data,
    })
    dispatch({
      type: PLAN_SELECT_FILTER,
      filterType: lowestFilterLevel.key,
      filterValues: scenarioFilters[lowestFilterLevel.key],
      filters: groupingLevels,
    })
    dispatch({ type: PLAN_BAR_MOVE_ENDED })
  } catch (e) {
    console.log('ERROR >> ', e)
  }
}

export const UpdateMediaBar = (
  plan: Plan,
  transforms: STransformation[]
) => async (
  dispatch: Dispatch<
    PlanActionTypes | AllBrandsActionTypes | MediaChannelActionTypes
  >
) => {
  let newS: Plan = { ...plan, transformations: transforms }
  try {
    const response = await api.Plans.putPlan(newS)
    dispatch({ type: PLAN_GET_ITEM_SUCCESS, payload: response.data })
    dispatch({ type: CREATE_ALL_BRANDS, payload: response.data })
    dispatch({ type: PLAN_CREATE_FILTERS, payload: response.data })
    // dispatch({
    //   type: GET_ALL_BRANDS_MEDIA_CHANNEL_ALLOCATION_BUDGET,
    //   payload: response.data,
    // })
  } catch (e) {}
}

export const ChangeKPIDisplay = (
  increment: number,
  currentVal: number,
  totalLength: number
) => (dispatch: Dispatch<PlanActionTypes>) => {
  dispatch({
    type: PLAN_CHANGE_KPI_DISPLAY,
    increment,
    currentVal,
    totalLength,
  })
}

export const CopyPlan = (id: string) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: COPY_PLAN_ATTEMPT })

  try {
    const response = await api.Plans.copyPlan(id)
  } catch (error) {}
}

export const LockPlan = (id: string) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: LOCK_PLAN_ATTEMPT })

  try {
    const response = await api.Plans.lockPlan(id)
  } catch (error) {}
}

export const DeletePlan = (id: string) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: DELETE_PLAN_ATTEMPT })

  try {
    const response = await api.Plans.deletePlan(id)
  } catch (error) {}
}

export const ToggleSharePlanModal = (id: string) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: TOGGLE_SHARE_PLAN_MODAL, id })
}

export const SharePlanWithUsers = (id: string, userIds: number[]) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: SHARE_PLAN })

  try {
    const response = await api.Plans.sharePlan(id, userIds)
  } catch (error) {}
}

export const ToggleCarryover = (id: string, plan: Plan) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: TOGGLE_CARRYOVER })

  try {
    const response = await api.Plans.toggleCarryover(id, plan)
    dispatch({ type: PUT_PLAN_SUCCESS, payload: response.data })
    dispatch({ type: TOGGLE_CARRYOVER_SUCCESS })
  } catch (error) {
    dispatch({ type: TOGGLE_CARRYOVER_FAIL })
  }
}

export const ToggleHalo = (id: string, plan: Plan) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: TOGGLE_HALO })

  try {
    const response = await api.Plans.toggleHalo(id, plan)
    dispatch({ type: PUT_PLAN_SUCCESS, payload: response.data })
    dispatch({ type: TOGGLE_HALO_SUCCESS })
  } catch (error) {
    dispatch({ type: TOGGLE_HALO_FAIL })
  }
}

export const PutPlan = (plan: any) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: PUT_PLAN })

  try {
    const response = await api.Plans.putPlan(plan)
    dispatch({ type: PUT_PLAN_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PUT_PLAN_FAIL })
  }
}

export const SelectCurrency = (id: string, options: Options) => async (
  dispatch: Dispatch<PlanActionTypes>
) => {

  try {
    const response = await api.Plans.putCurrency(id, options)
    dispatch({ type: PUT_PLAN_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PUT_PLAN_FAIL })
  }
}

export const SelectPlanKpiOutput = (key: string) => (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: PLAN_SELECT_KPI_OUTPUT, payload: key })
}

export const SelectOutputFilter = (levelName: string, key: string, groupingLevels: PlanGroupingLevel) => (
  dispatch: Dispatch<PlanActionTypes>
) => {
  dispatch({ type: PLAN_SELECT_LEVEL, levelName: levelName, key: key, groupingLevels: groupingLevels })
}