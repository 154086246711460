import { IconType } from '@gain-theory/icon'
import { OnetrustSdk } from '@gain-theory/onetrust-sdk'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/reducers/RootReducer'
import { DEFAULT_SUPPORT_CONTACT } from '../../../../utils/constants'

export function useActions() {
  const { systemLinks } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  const handleSupportClick = useCallback(() => {
    const hasSupportContact =
      systemLinks !== null &&
      !!systemLinks.contact &&
      systemLinks.contact.length > 0

    const email = hasSupportContact
      ? systemLinks.contact[0].email
      : DEFAULT_SUPPORT_CONTACT

    window.open(`mailto:${email}`, '_blank')
  }, [systemLinks])

  const handleCookieClick = useCallback(() => {
    OnetrustSdk.openPreferenceCenter()
  }, [])

  const actions = [
    {
      id: 'contact-support-team-btn',
      label: 'Contact our support team',
      icon: 'EnvelopeIcon' as IconType['name'],
      library: 'heroicon-outline' as IconType['library'],
      onClick: handleSupportClick,
    },
    {
      id: 'cookie-preferences-btn',
      label: 'Cookie preferences',
      icon: 'cookie' as IconType['name'],
      library: 'svg' as IconType['library'],
      onClick: handleCookieClick,
    },
  ]

  return actions
}
