import { useState } from 'react'
import { DatePickerProps } from '../types'

interface IUseDateRangeProps {
  oneDatePicker?: boolean
  onStartDateChangeCallback?: (date: Date | null) => void
  onEndDateChangeCallback?: (date: Date | null) => void
}

export function useDateRange({
  oneDatePicker = false,
  onStartDateChangeCallback,
  onEndDateChangeCallback,
}: IUseDateRangeProps) {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleChangeStartDate = (date: Date | null) => {
    onStartDateChangeCallback?.(date)
    setStartDate(date)
  }

  const handleChangeEndDate = (date: Date | null) => {
    onEndDateChangeCallback?.(date)
    setEndDate(date)
  }

  /* @ts-ignore */
  const startDatePickerProps: DatePickerProps<undefined> = {
    selected: startDate,
    selectsStart: true,
    startDate: startDate,
    endDate: endDate,
    maxDate: endDate,
    onChange: handleChangeStartDate,
  }

  /* @ts-ignore */
  const endDatePickerProps: DatePickerProps<undefined> = {
    selected: endDate,
    selectsEnd: true,
    startDate: startDate,
    endDate: endDate,
    minDate: startDate,
    onChange: handleChangeEndDate,
  }

  return [startDatePickerProps, endDatePickerProps] as const
}
