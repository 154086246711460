import { memo } from 'react'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { ModuleCardProps } from './types'

export const ModuleCard = memo(({ module, selected }: ModuleCardProps) => {
  const { id, icon, name } = module

  return (
    <div
      id={id}
      className={`flex flex-row gap-md whitespace-normal overflow-hidden items-center rounded-sm px-md py-xs shadow-main w-60 xl:w-96 ${
        selected ? 'bg-brand-primary-main' : 'bg-neutral-white'
      }`}
    >
      <Icon
        size="xxxxl"
        color={selected ? 'neutral-white' : 'brand-primary-main'}
        name={icon.name}
        library={icon.library}
        style={{ strokeWidth: 1 }}
      />
      <Typography.Button>
        <span
          className={`uppercase text-left ${
            selected ? 'text-neutral-white' : 'text-neutral-black'
          }`}
        >
          {name}
        </span>
      </Typography.Button>
    </div>
  )
})
