import {
  Constraint,
  GroupingLevel,
  Identifier,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { downloadXlsxFromCsv } from '../../../../../../utils/download-xlsx-from-csv'
import { TableColumnType } from '../../types'
import { CONSTRAINT_STATUS_OPTIONS, TABLE_COLUMNS } from '../../utils/constants'
import { useTimeConstraintsNotification } from '../use-time-constraints-notification'

type RowDataType = string | number | undefined

interface IUseDownloadConstraintsTemplateProps {
  constraints: Constraint[]
  groupingLevels: GroupingLevel[]
  hierarchyColumns: TableColumnType[]
  observationColumns: TableColumnType[]
  transformationOptions: Transformation[]
  typeColumn: TableColumnType
  statusColumn: TableColumnType
  carryoverWeeks: number
}

export const useDownloadConstraintsTemplate = ({
  constraints,
  groupingLevels,
  hierarchyColumns,
  observationColumns,
  transformationOptions,
  typeColumn,
  statusColumn,
  carryoverWeeks
}: IUseDownloadConstraintsTemplateProps) => {
  const notification = useTimeConstraintsNotification()

  const getFileHeaders = () => {
    const keys: string[] = []
    const labels: string[] = []

    const columns = [
      ...hierarchyColumns,
      typeColumn,
      statusColumn,
      ...observationColumns,
    ]
    columns.forEach((column) => {
      keys.push(column.field)
      labels.push(column.headerName)
    })

    return { headerKeys: keys, headerLabels: labels }
  }

  const createCsvRow = (constrIdentifier: Identifier, fn: string) => {
    const row: RowDataType[] = []

    // Push hierarchy values
    hierarchyColumns.forEach((column) => {
      const hierarchyKey = column.field

      const groupingLevelValues = groupingLevels.find(
        (level) => level.key === hierarchyKey
      )?.values

      const levelValueLabel = groupingLevelValues?.find(
        (value) => value.key === constrIdentifier[hierarchyKey]
      )?.label

      row.push(levelValueLabel)
    })

    const matchingConstraint = constraints.find((constraint) => {
      const { identifiers: constraintIdentifiers } = constraint
      const constraintIdentifier = constraintIdentifiers as any as Identifier
      const typeMatch = constraint.fn === fn
      if(typeMatch){
        return Object.keys(constrIdentifier)
        .filter((key) => key !== 'region_key')
        .every(
          (key) => constrIdentifier[key] === constraintIdentifier[key]
        )
      }
    })

    // Push type value
    const typeValue = matchingConstraint
      ? TABLE_COLUMNS.TYPE.options.find(
          (option) =>
            option.toLowerCase() === matchingConstraint.fn.toLowerCase()
        )
      : undefined

    row.push(typeValue)

    // Push status value
    row.push(CONSTRAINT_STATUS_OPTIONS.ENABLED)

    // Push observation values
    const observationValues = matchingConstraint
      ? matchingConstraint.values
      : Array(observationColumns.length).fill(undefined)

    let filteredCarryover = observationValues.filter((x, i) =>
        i + carryoverWeeks < observationValues.length)

    row.push(...filteredCarryover)

    return row
  }

  const getFileData = () => {
    const data: RowDataType[][] = constraints.map(
      (constr) => {
        const { identifiers, fn } = constr
        const constrIdentifier = identifiers as any as Identifier
        return createCsvRow(constrIdentifier, fn)
      }
    )

    return data
  }

  const createCsv = () => {
    const { headerLabels } = getFileHeaders()

    const data = getFileData()

    data.unshift(headerLabels)

    const csvData = data.map((row) => row.join(',')).join('\n')

    return csvData
  }

  const handleDownloadError = (error: any) => {
    notification.fileDownloadError(error)
  }

  const handleDownload = downloadXlsxFromCsv(
    createCsv(),
    'constraints-time-template',
    handleDownloadError
  )

  return handleDownload
}
