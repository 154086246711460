import { useAlertProps } from '@gain-theory/alert'
import { FileUploadProps } from '@gain-theory/file-upload'
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../Routes'
import { GetConfigById } from '../../../redux/actions/ConfigActions'
import { getConfigById } from '../../../redux/reducers/ConfigReducer'
import {
  selectCreateError,
  selectLoading,
  selectPlanCreatedComplete,
  selectPlanItem,
} from '../../../redux/reducers/PlanReducer'
import { AppState } from '../../../redux/reducers/RootReducer'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { RootStore } from '../../../redux/reducers/Store'
import { MainLayout } from '../../../shared/MainLayout'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { uploadSPOPlan } from '../../../socket.io'
import { RouteComponentProps } from '../../../types/CreatePlanTypes'
import { Constraint } from '../../../types/PlanTypes'
import { useBudgetConstaint } from '../shared/hooks/use-bugdet-constraints'
import { useFilterConstraintsAndObservations } from '../shared/hooks/use-filter-constraints-and-observations'
import TimePeriodBudgetConstraints from '../shared/time-period-budget-constraints'
import UploadPlanData from './UploadPlanData'
import UploadPlanHeader from './UploadPlanHeader'
import useTimePeriodBudgetConstraints from './hooks/use-time-period-budget-constaints'

interface OwnProps {}

type Props = OwnProps

interface MatchParams {
  id: string
}

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  settings: selectSettings(state),
  config: getConfigById(state),
  optimiserError: selectCreateError(state),
  planCreated: selectPlanCreatedComplete(state),
  plan: selectPlanItem(state),
  loading: selectLoading(state),
})

const mapDispatchToProps = {
  onGetConfigById: GetConfigById,
  onCreateUploadPlan: uploadSPOPlan,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const UploadMarketingPlan: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const history = useHistory()

  const { settings } = useSelector((state: AppState) => state.settings)

  const [step, setStep] = useState<number>(1)

  const [planName, setPlanName] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)

  const [currency, setCurrency] = useState<string>('')
  const [currencyError, setCurrencyError] = useState<boolean>(false)

  const [error, setError] = useState<string>('')
  const [editConstraints, setEditConstraints] = useState<boolean>(false)

  const { alertProps, showErrorAlert, closeAlert } = useAlertProps({})

  const {
    transformationOptions,
    selectedObservations,
    totalBudget,
    fileError,
  } = useTimePeriodBudgetConstraints({
    file,
    grouping_levels: props.config?.grouping_levels || [],
    observations: props.config?.observations || [],
    transformations: props.config?.transformations || [],
    carryoverWeeks: props.config?.options.carryoverWeeks || 0,
    settings: settings,
  })

  const { isBudgetConstraintEnabled } = useBudgetConstaint()

  const { filteredConstraints, filteredObservations } =
    useFilterConstraintsAndObservations({
      constraints: props.config?.constraints || [],
      transformationOptions,
      observations: props.config?.observations || [],
      selectedObservations,
    })

  useEffect(() => {
    setError(props.optimiserError || '')

    if (props.optimiserError !== null && props.optimiserError !== '') {
      setPlanName('')
      setCurrency('')
      setEditConstraints(false)
      setFile(null)
      closeAlert()
    }
  }, [props.optimiserError])

  useEffect(() => {
    props.onGetConfigById(props.match.params.id)
  }, [])

  useEffect(() => {
    if (error !== '') {
      showErrorAlert({ description: error })
    }
  }, [error])

  useEffect(() => {
    if (props.planCreated) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', props.plan.id)
        .toString()

      history.push(url)
    }
  }, [props.planCreated])

  useEffect(() => {
    const errorMessageFromFile = editConstraints ? fileError ?? '' : ''
    setError(errorMessageFromFile)
  }, [editConstraints, fileError])

  const onChangeName = (name: string) => {
    setPlanName(name)
  }

  const onChangeCurrency = (selectedValue: string) => {
    setCurrency(selectedValue)

    if (currencyError && selectedValue !== '') {
      setCurrencyError(false)
    }
  }

  const onChangeFile = (selectedFile: File | null) => {
    // TODO (PINEAPPLE): ONCE SPO IS FULLY WIRED UP
    // IF SPO CONFIG IS SCHEMA V1 THEN USE CURRENCY IN CONFIG
    // ELSE IF SCHEMA V2 THEN USE CURRENCY FROM ADMIN SETTINGS
    closeAlert()
    setFile(selectedFile)
  }

  const onInvalidFile: FileUploadProps['onInvalidFile'] = (invalidType) => {
    setFile(null)

    if (invalidType === 'size') {
      showErrorAlert({
        title: 'File size too large',
        description: 'The selected file exceeds the size limit of 10 MB.',
      })
      return
    }

    if (invalidType === 'fileType') {
      showErrorAlert({
        title: 'File type not supported',
        description:
          'The selected file type is not a supported xlsx or csv type.',
      })
      return
    }

    if (invalidType === 'nullFile') {
      showErrorAlert({
        title: 'No file selected',
        description: 'Please select a file to upload.',
      })
      return
    }
  }

  const createCsvRows = (data: string[][]) => {
    const csvRows = []

    for (const row of data) {
      csvRows.push(row.join(','))
    }

    return csvRows.join('\n')
  }

  const keysToLabels = (level: string, key: string) => {
    const values =
      props.config?.grouping_levels.find((x) => x.key === level)?.values || []
    const foundKey = values.find((x) => x.key === key)?.label || ''
    return foundKey
  }

  const createCsv = () => {
    if (!props.config) {
      return ''
    }

    const observations = props.config.observations.slice(
      0,
      props.config.observations.length - props.config.options.carryoverWeeks
    )

    let headers = props.config.grouping_levels.map((x) => x.label)
    headers = [...headers, 'Variable']
    headers = [
      ...headers,
      ...observations.map((observation) => observation.label),
    ]

    const noHaloTransformations =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []

    let identifiers = noHaloTransformations.map(
      (transform) => transform.identifiers
    )

    let data = identifiers.map((x) => {
      const filteredKeys = Object.keys(x).filter((key) => key !== 'region_key')
      let ids: string[] = []
      for (let key of filteredKeys) {
        const label = keysToLabels(key, x[key])
        ids = [...ids, label]
      }
      ids = [...ids, 'Spend']

      const spendZeros = new Array(observations.length).fill('0')
      const row = ids.concat(spendZeros)
      return row
    })

    data.unshift(headers)

    return createCsvRows(data)
  }

  const downloadCsv = () => {
    const csv = createCsv()

    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'MarketingTemplate.csv'
    link.click()
  }

  const handleCancelUpload = () => {
    setPlanName('')
    setCurrency('')
    setEditConstraints(false)
    setFile(null)
    setError('')
    closeAlert()

    history.push(ROUTES.SPO.INDEX)
  }

  const uploadPlan = (constraints?: Constraint[]) => {
    const noCurrencySelected = !currency || currency === ''
    const noFileSelected = !file || file === null

    setCurrencyError(noCurrencySelected)

    noFileSelected
      ? showErrorAlert({
          title: 'No file selected',
          description: 'Please select a file to upload.',
        })
      : closeAlert()

    if (noCurrencySelected || noFileSelected) {
      return
    }

    if (props.config && props.user) {
      setError('')
      props.onCreateUploadPlan(
        props.config.id,
        file,
        props.user,
        currency,
        planName,
        constraints
      )
    }
  }

  const handleConfirmHeaderButtonClick = (constraints?: Constraint[]) => {
    const hasFileLoadError = fileError !== null && fileError !== ''

    if (editConstraints && hasFileLoadError) {
      return
    }

    if (isBudgetConstraintEnabled && editConstraints && step === 1) {
      setStep(2)
      return
    }

    uploadPlan(constraints)
  }

  // TODO (TPBC): Uncomment this line when time period budget constraints is deployed to develop
  // getCurrencyLabelFromSymbol is used to get the currency name from the currency symbol
  const getCurrencyLabelFromSymbol = (symbol: string) => {
    const index = props.config
      ? props.config.options.exchangeRates.currencySymbols.indexOf(symbol)
      : 0
    const name = props.config
      ? props.config.options.exchangeRates.currencies[index]
      : 'USD'

    return `${symbol} (${name})`
  }

  const UploadPlan = (
    <div className="w-full h-full p-8 flex flex-col gap-xxl items-start justify-start">
      <UploadPlanHeader
        disableGeneratePlan={false}
        editConstraints={editConstraints}
        onCancel={handleCancelUpload}
        onGeneratePlan={() => handleConfirmHeaderButtonClick(undefined)}
      />
      <UploadPlanData
        planName={planName}
        selectedCurrency={currency}
        currencyError={currencyError}
        file={file}
        exchangeRates={props.config?.options.exchangeRates}
        fileUploadAlert={alertProps}
        editConstraints={editConstraints}
        isBudgetConstraintEnabled={isBudgetConstraintEnabled}
        onSwitchClick={() => setEditConstraints((prev) => !prev)}
        onChangeName={onChangeName}
        onChangeCurrency={onChangeCurrency}
        onChangeFile={onChangeFile}
        onInvalidFile={onInvalidFile}
        onDownloadTemplate={downloadCsv}
      />
    </div>
  )

  const TimePeriodConstraints = props.config && (
    <TimePeriodBudgetConstraints
      budget={totalBudget}
      constraints={filteredConstraints}
      currencyLabel={getCurrencyLabelFromSymbol(currency)}
      groupingLevels={props.config?.grouping_levels || []}
      handleBack={() => setStep(1)}
      handleGeneratePlan={uploadPlan}
      observations={filteredObservations}
      planGenerationType="create"
      transformationOptions={transformationOptions}
      settings={settings}
      generatePlanError={props.optimiserError}
      carryoverWeeks={props.config.options.carryoverWeeks}
    />
  )

  if (props.loading) {
    return <LoadingModal />
  }

  return step === 1 ? UploadPlan : TimePeriodConstraints
}

export default connector(MainLayout(UploadMarketingPlan, true))
