import {
  Highlights,
  MarketingDiagnostics,
  SystemLinks,
} from '../../types/MarketingDiagnosticsTypes'
import { IPlanSummary } from '../../types/PlanTypes'

export const MD_GET_DATA_ATTEMPT = 'MD_GET_DATA_ATTEMPT'
export const MD_GET_DATA_SUCCESS = 'MD_GET_DATA_SUCCESS'
export const MD_GET_MARKETING_DIAGNOSTICS_SUCCESS =
  'MD_GET_MARKETING_DIAGNOSTICS_SUCCESS'
export const MD_GET_DATA_FAIL = 'MD_GET_DATA_FAIL'
export const MD_GET_HIGHLIGHTS_ATTEMPT = 'MD_GET_HIGHLIGHTS_ATTEMPT'
export const MD_GET_HIGHLIGHTS_SUCCESS = 'MD_GET_HIGHLIGHTS_SUCCESS'
export const MD_GET_HIGHLIGHTS_FAIL = 'MD_GET_HIGHLIGHTS_FAIL'
export const MD_GET_PLAN_SUMMARY_LIST_SUCCESS =
  'MD_GET_PLAN_SUMMARY_LIST_SUCCESS'
export const MD_GET_SYSTEM_LINK_SUCCESS = 'MD_GET_SYSTEM_LINK_SUCCESS'
export const MD_GET_INSIGHTS_ATTEMPT = 'MD_GET_INSIGHTS_ATTEMPT'
export const MD_GET_INSIGHTS_SUCCESS = 'MD_GET_INSIGHTS_SUCCESS'
export const MD_GET_INSIGHTS_FAIL = 'MD_GET_INSIGHTS_FAIL'

interface MDGetInsightsAttempt {
  type: typeof MD_GET_INSIGHTS_ATTEMPT
}

interface MDGetInsightsSuccess {
  type: typeof MD_GET_INSIGHTS_SUCCESS
  payload: Highlights
}

interface MDGetInsightsFail {
  type: typeof MD_GET_INSIGHTS_FAIL
}

interface MDGetSystemLinksSuccess {
  type: typeof MD_GET_SYSTEM_LINK_SUCCESS
  payload: SystemLinks
}

interface MDGetDataAttempt {
  type: typeof MD_GET_DATA_ATTEMPT
}

interface MDGetDataSuccess {
  type: typeof MD_GET_DATA_SUCCESS
}

interface MDGetMarketingDiagnosticsSuccess {
  type: typeof MD_GET_MARKETING_DIAGNOSTICS_SUCCESS
  payload: MarketingDiagnostics
}

interface MDGetDataFail {
  type: typeof MD_GET_DATA_FAIL
}

interface MDGetHighlightsAttempt {
  type: typeof MD_GET_HIGHLIGHTS_ATTEMPT
}

interface MDGetHighlightsSuccess {
  type: typeof MD_GET_HIGHLIGHTS_SUCCESS
  payload: Highlights
}

interface MDGetHighlightsFail {
  type: typeof MD_GET_HIGHLIGHTS_FAIL
}

interface MDGetPlanSummaryListSuccess {
  type: typeof MD_GET_PLAN_SUMMARY_LIST_SUCCESS
  payload: IPlanSummary[]
}

export type MarketingDiagnosticsActionTypes =
  | MDGetDataAttempt
  | MDGetDataSuccess
  | MDGetDataFail
  | MDGetHighlightsAttempt
  | MDGetHighlightsSuccess
  | MDGetHighlightsFail
  | MDGetMarketingDiagnosticsSuccess
  | MDGetPlanSummaryListSuccess
  | MDGetSystemLinksSuccess
  | MDGetInsightsAttempt
  | MDGetInsightsSuccess
  | MDGetInsightsFail
