import { TimeConstraintErrorType, Errors } from '../types'
import {
  CONSTRAINT_FN_OPTIONS,
  CONSTRAINT_STATUS_OPTIONS,
  TABLE_COLUMNS,
} from './constants'

interface IValidateFileCurvesProps {
  identifierKey: string
  parsedRows: Record<string, string>[]
  hierarchyKeys: string[]
}

export function validateCurves({
  identifierKey,
  parsedRows,
  hierarchyKeys,
}: IValidateFileCurvesProps) {
  const errors: TimeConstraintErrorType[] = []

  const duplicates: string[] = []
  const invalidEqualCombos: string[] = []
  const invalidIndexCombos: string[] = []
  const valuesMap: Record<string, Record<string, string>[]> = {}

  const lowerCaseMinValue = CONSTRAINT_FN_OPTIONS.MIN.toLowerCase()
  const lowerCaseMaxValue = CONSTRAINT_FN_OPTIONS.MAX.toLowerCase()
  const lowerCaseEqualValue = CONSTRAINT_FN_OPTIONS.EQUAL.toLowerCase()
  const lowerCaseIndexValue = CONSTRAINT_FN_OPTIONS.INDEX.toLowerCase()

  parsedRows.forEach((row) => {
    const hierarchyValues = hierarchyKeys.map((key) =>
      row[key] ? row[key] : ''
    )

    if (hierarchyValues.length === 0 || hierarchyValues.includes('')) {
      return
    }

    const valuesKey = hierarchyValues.join(',')

    if (!valuesMap[valuesKey]) {
      valuesMap[valuesKey] = []
    }

    valuesMap[valuesKey].push(row)
  })

  for (const values in valuesMap) {
    const rows = valuesMap[values]
    const enabledRows = rows.filter(
      (row) =>
        row[TABLE_COLUMNS.STATUS.field] !== CONSTRAINT_STATUS_OPTIONS.DISABLED
    )

    if (enabledRows.length === 1) {
      continue
    }

    const types = enabledRows.map((row) => row[TABLE_COLUMNS.TYPE.field].toLowerCase())

    const hasEqual = types.includes(lowerCaseEqualValue)
    const hasIndex = types.includes(lowerCaseIndexValue)

    const hasDuplicates = types.some(
      (type, index, self) => self.indexOf(type) !== index
    )
    const hasInvalidEqualCombo =
      hasEqual &&
      types.some(
        (type) => type === lowerCaseMinValue || type === lowerCaseMaxValue
      )
    const hasInvalidIndexCombo =
      hasIndex &&
      types.some(
        (type) => type === lowerCaseMinValue || type === lowerCaseMaxValue
      )

    if (hasDuplicates) {
      types.forEach((type, index, self) => {
        if (self.indexOf(type) === index) {
          return
        }

        const duplicatesIds = enabledRows
          .filter((row) => row[TABLE_COLUMNS.TYPE.field].toLowerCase() === type)
          .map((row) => (identifierKey in row ? row[identifierKey] : ''))
          .sort()
          .join(', ')

        if (duplicatesIds.length === 0 || duplicates.includes(duplicatesIds)) {
          return
        }

        duplicates.push(duplicatesIds)
      })
    }

    if (hasInvalidEqualCombo) {
      const invalidRowsIds = enabledRows
        .filter((row) =>
          [lowerCaseMinValue, lowerCaseMaxValue, lowerCaseEqualValue].includes(
            row[TABLE_COLUMNS.TYPE.field].toLowerCase()
          )
        )
        .map((row) => (identifierKey in row ? row[identifierKey] : ''))
        .sort()
        .join(', ')

      invalidRowsIds.length > 0 && invalidEqualCombos.push(invalidRowsIds)
    }

    if (hasInvalidIndexCombo) {
      const invalidRowsIds = enabledRows
        .map((row) => (identifierKey in row ? row[identifierKey] : ''))
        .sort()
        .join(', ')

      invalidIndexCombos.push(invalidRowsIds)
    }
  }

  duplicates.forEach((ids) => {
    const error: TimeConstraintErrorType = {
      errorType: Errors.DUPLICATE_CURVES,
      replacers: [ids],
    }

    errors.push(error)
  })

  invalidEqualCombos.forEach((ids) => {
    const error: TimeConstraintErrorType = {
      errorType: Errors.INVALID_CURVE_COMBINATION,
      replacers: [
        ids,
        lowerCaseEqualValue,
        [lowerCaseMinValue, lowerCaseMaxValue].join('", "'),
      ],
    }

    errors.push(error)
  })

  invalidIndexCombos.forEach((ids) => {
    const error: TimeConstraintErrorType = {
      errorType: Errors.INVALID_CURVE_COMBINATION,
      replacers: [
        ids,
        lowerCaseIndexValue,
        [lowerCaseMinValue, lowerCaseMaxValue, lowerCaseEqualValue].join(
          '", "'
        ),
      ],
    }

    errors.push(error)
  })

  return errors
}
