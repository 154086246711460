import { AgGridProps, ColDef } from '@gain-theory/ag-grid'
import { Chip, ChipProps } from '@gain-theory/chip'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { GroupingLevel } from '../../../../../../types/PlanTypes'
import { formatNumberString } from '../../../../../../utils/format-number-string'
import { HierarchyOptionType, TableColumnType, TableRowType } from '../../types'
import { CONSTRAINT_STATUS_OPTIONS, TABLE_COLUMNS } from '../../utils/constants'

interface IUseColumnDefsProps {
  columns: TableColumnType[]
  hierarchyOptions: HierarchyOptionType[]
  groupingLevels: GroupingLevel[]
  numberFormat: string | undefined
}

const disabledRowStyle = {
  // TODO: Replace with theme color
  color: '#9aa4ac',
  backgroundColor: '#f4f5f6',
}

export const useColumnDefs = ({
  columns,
  hierarchyOptions,
  groupingLevels,
  numberFormat,
}: IUseColumnDefsProps) => {
  const dimensionLevelsKeys = groupingLevels.map((level) => level.key)

  const [columnDefs, setColumnDefs] = useState<ColDef<TableRowType>[]>([])

  const editableFn = (params: any) => {
    return params.data.status !== CONSTRAINT_STATUS_OPTIONS.DISABLED
  }

  const cellStyleFn = (params: any) => {
    if (params.data.status === CONSTRAINT_STATUS_OPTIONS.DISABLED) {
      return { ...disabledRowStyle }
    }
  }

  const getOptionsForLevelColumn = (params: any, levelKey: string) => {
    const previousLevels = dimensionLevelsKeys.slice(
      0,
      dimensionLevelsKeys.indexOf(levelKey)
    )

    const parentValues = previousLevels.map((level) => params.data[level])
    const parentKeys = parentValues.map(
      (value, index) =>
        groupingLevels[index].values.find((level) => level.label === value)
          ?.key || ''
    )

    const currentLevelOptions = hierarchyOptions.filter(
      (option) =>
        option.levelKey === levelKey &&
        (option.parents.length === 0 ||
          option.parents.some((parent) => _.isEqual(parent, parentKeys)))
    )

    return currentLevelOptions.map((option) => option.label).sort()
  }

  const constraintStatusChip = (status: string) => {
    const chipStatus: Record<string, ChipProps['variant']> = {
      [CONSTRAINT_STATUS_OPTIONS.ENABLED]: 'success',
      [CONSTRAINT_STATUS_OPTIONS.DISABLED]: 'destructive',
    }

    return (
      <div className="w-fit">
        <Chip variant={chipStatus[status]}>{status}</Chip>
      </div>
    )
  }

  const handleUpdateColumnDefs = () => {
    const newColumnDefs: NonNullable<AgGridProps['columnDefs']> = columns.map(
      (column) => {
        if (column.field === TABLE_COLUMNS.ID.field) {
          return {
            ...column,
            editable: false,
            sortable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            pinned: 'left',
            menuTabs: [],
            minWidth: 100,
            cellStyle: cellStyleFn,
          }
        }

        if (dimensionLevelsKeys.includes(column.field)) {
          return {
            ...column,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params: any) => ({
              values: getOptionsForLevelColumn(params, column.field),
            }),
            editable: editableFn,
            filterable: true,
            sortable: true,
            menuTabs: ['generalMenuTab', 'filterMenuTab'],
            minWidth: 150,
            cellStyle: cellStyleFn,
          }
        }

        if (column.field === TABLE_COLUMNS.TYPE.field) {
          return {
            ...column,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: TABLE_COLUMNS.TYPE.options,
            },
            editable: editableFn,
            filterable: true,
            sortable: true,
            menuTabs: ['generalMenuTab', 'filterMenuTab'],
            minWidth: 100,
            cellStyle: cellStyleFn,
          }
        }

        if (column.field === TABLE_COLUMNS.STATUS.field) {
          return {
            ...column,
            editable: false,
            filterable: true,
            sortable: true,
            menuTabs: ['generalMenuTab', 'filterMenuTab'],
            minWidth: 100,
            cellStyle: cellStyleFn,
            cellRenderer: (params: any) => constraintStatusChip(params.value),
          }
        }

        return {
          ...column,
          editable: editableFn,
          filterable: false,
          sortable: false,
          menuTabs: ['generalMenuTab'],
          minWidth: 200,
          cellStyle: cellStyleFn,
          valueParser: (params) => {
            const formattedValue =
              params.newValue !== ''
                ? formatNumberString({
                    value: params.newValue,
                    decimalSeparator: numberFormat === 'euro' ? ',' : '.',
                    thousandSeparator: numberFormat === 'euro' ? '.' : ',',
                  })
                : ''

            return formattedValue
          },
        }
      }
    )

    setColumnDefs(newColumnDefs)
  }

  useEffect(() => {
    handleUpdateColumnDefs()
  }, [columns, hierarchyOptions])

  return columnDefs
}
