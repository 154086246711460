import React, { FC } from 'react'
import { GroupingLevel } from '../../../types/PlanTypes'

interface OwnProps {
  selectedGroupingLevels: string[]
  groupingLevels: GroupingLevel[]
  label: string
  onSelect: (value: string, level: string) => void
  sectionName: string
}

type Props = OwnProps

const EditGroupingLevel: FC<Props> = (props) => {
  return (
    <div className="flex flex-col w-full space-y-5">
      <div>{props.label}</div>
      <div className="grid grid-flow-rows grid-cols-3 grid-rows-3 gap-4 p-4 ">
        {props.groupingLevels.map((level: GroupingLevel, index: number) => (
          <div className="check-box" key={index}>
            <label>
              <input
                type="checkbox"
                onChange={(_) => props.onSelect(level.key, props.sectionName)}
                checked={props.selectedGroupingLevels.includes(level.key)}
                className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                name="a"
                value={level.key}
              />
              <div className="text-xxs items-center flex text-gray-500 pt-1">
                {level.label}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EditGroupingLevel
