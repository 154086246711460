import { useAlertProps } from '@gain-theory/alert'
import { useEffect } from 'react'
import {
  Constraint,
  GroupingLevel,
  Identifier,
} from '../../../../../../types/PlanTypes'
import { getNumberFromFormat } from '../../../../../../utils/get-number-from-format'
import { Errors, RowDataType, TimeConstraintErrorType } from '../../types'
import {
  CONSTRAINT_FN_OPTIONS,
  CONSTRAINT_STATUS_OPTIONS,
  TABLE_COLUMNS,
  VALIDATION_ALERT_TILE,
} from '../../utils/constants'
import { getErrorList } from '../../utils/get-error-list'
import { validateCurves } from '../../utils/validate-curves'

interface IUseSubmitChangesProps {
  planGenerationType: 'create' | 'edit'
  groupingLevels: GroupingLevel[]
  hierarchyColumns: Record<string, string>[]
  localRowData: RowDataType[]
  observationColumns: Record<string, string>[]
  statusColumn: Record<string, string>
  typeColumn: Record<string, string>
  numberFormat: string | undefined
  generatePlanError: string | null
  handleGeneratePlan: (constraints?: Constraint[]) => void
}

export const useSubmitChanges = ({
  planGenerationType,
  groupingLevels,
  hierarchyColumns,
  localRowData,
  observationColumns,
  statusColumn,
  typeColumn,
  numberFormat,
  generatePlanError,
  handleGeneratePlan,
}: IUseSubmitChangesProps) => {
  const dimensionKeys = groupingLevels.map((level) => level.key)

  const { alertProps, closeAlert, showErrorAlert, setAlertProps } =
    useAlertProps({
      dismissable: false,
    })

  const validateForEmptyValues = () => {
    const errors: TimeConstraintErrorType[] = []

    localRowData.forEach((row) => {
      const {
        [TABLE_COLUMNS.ID.field]: id,
        [TABLE_COLUMNS.STATUS.field]: status,
        ...rest
      } = row

      const isEmptyRow = Object.keys(rest).every((key) => {
        const value = rest[key]
        return !value || (value as string).trim() === ''
      })

      if (isEmptyRow) {
        return
      }

      const hasBlankValues = Object.entries(rest).some(([key, value]) => {
        if (dimensionKeys.includes(key) || key === TABLE_COLUMNS.TYPE.field) {
          return !value || (value as string).trim() === ''
        }
      })

      if (!hasBlankValues) {
        return
      }

      const error: TimeConstraintErrorType = {
        errorType: Errors.EMPTY_ROW,
        replacers: [id],
      }

      errors.push(error)
    })

    return errors
  }

  const validateForZeroSumIndex = () => {
    const errors: TimeConstraintErrorType[] = []

    localRowData.forEach((row) => {
      const {
        [TABLE_COLUMNS.ID.field]: id,
        [TABLE_COLUMNS.TYPE.field]: type,
        [TABLE_COLUMNS.STATUS.field]: status,
      } = row

      const isIndexType =
        type.toLowerCase() === CONSTRAINT_FN_OPTIONS.INDEX.toLowerCase()

      if (!isIndexType) {
        return
      }

      const isDisabledStatus =
        status.toLowerCase() ===
        CONSTRAINT_STATUS_OPTIONS.DISABLED.toLowerCase()

      if (isDisabledStatus) {
        return
      }

      const sumOfObservationValues = observationColumns.reduce(
        (accumulator, observation) => {
          const value = row[observation.field]

          const parsedValue = getNumberFromFormat({
            formattedValue: value,
            decimalSeparator: numberFormat === 'euro' ? ',' : '.',
            thousandSeparator: numberFormat === 'euro' ? '.' : ',',
          })

          const returnValue = Number.isNaN(parsedValue) ? 0 : parsedValue

          return accumulator + returnValue
        },
        0
      )

      if (sumOfObservationValues > 0) {
        return
      }

      const error: TimeConstraintErrorType = {
        errorType: Errors.ZERO_INDEX_TOTALS,
        replacers: [id],
      }

      errors.push(error)
    })

    return errors
  }

  const getConstraintsFromRow = (row: any): Constraint => {
    const typeValue = row[typeColumn.field] as string

    const fn: Constraint['fn'] = typeValue.toLowerCase()

    const isIndexType = fn === CONSTRAINT_FN_OPTIONS.INDEX.toLowerCase()

    const identifiers: Identifier = hierarchyColumns.reduce((acc, level) => {
      const field = level.field

      const groupingLevelValues = groupingLevels.find(
        (groupingLevel) => groupingLevel.key === field
      )?.values

      const selectedValueKey = groupingLevelValues?.find(
        (value) => value.label === row[field]
      )?.key

      if (selectedValueKey) {
        acc[field] = selectedValueKey
      }

      return acc
    }, {} as Identifier)

    const values = observationColumns.map((observation) => {
      const key = observation.field
      const value = row[key]

      if (value === '') {
        return isIndexType ? 0 : null
      }

      const parsedValue = getNumberFromFormat({
        formattedValue: value,
        decimalSeparator: numberFormat === 'euro' ? ',' : '.',
        thousandSeparator: numberFormat === 'euro' ? '.' : ',',
      })

      const returnValue = Number.isNaN(parsedValue) ? 0 : parsedValue

      return returnValue
    })

    const variable = row.variable

    const constraint = {
      fn,
      identifiers,
      values: [null, ...values], // first value is not observation value
      variable,
      disabled: row[statusColumn.field] === CONSTRAINT_STATUS_OPTIONS.DISABLED,
    } as any as Constraint

    return constraint
  }

  const handleSubmit = () => {
    const errors: TimeConstraintErrorType[] = []

    const emptyValuesErrors = validateForEmptyValues()
    const zeroSumIndexErrors = validateForZeroSumIndex()
    const curvesErrors = validateCurves({
      identifierKey: TABLE_COLUMNS.ID.field,
      parsedRows: localRowData,
      hierarchyKeys: hierarchyColumns.map((column) => column.field),
    })

    errors.push(...emptyValuesErrors)
    errors.push(...curvesErrors)
    errors.push(...zeroSumIndexErrors)

    if (errors.length > 0) {
      const errorList = getErrorList(errors)

      const alertTile =
        planGenerationType === 'create'
          ? VALIDATION_ALERT_TILE.CREATE
          : VALIDATION_ALERT_TILE.REGENERATE

      showErrorAlert({
        title: alertTile,
        description: errorList,
      })

      return
    }

    const nonEmptyRows = localRowData.filter((row) => {
      const { id, [TABLE_COLUMNS.STATUS.field]: status, ...rest } = row

      const isEmptyRow = Object.keys(rest).every((key) => {
        const value = rest[key]
        return !value || (value as string).trim() === ''
      })

      return !isEmptyRow
    })

    const newConstraints = nonEmptyRows.map(getConstraintsFromRow)

    closeAlert()
    handleGeneratePlan(newConstraints)
  }

  useEffect(() => {
    closeAlert()
  }, [])

  useEffect(() => {
    if (!generatePlanError) {
      return
    }

    if (alertProps.show) {
      const previousDescription = alertProps.description || []
      const newDescription = Array.isArray(previousDescription)
        ? [...previousDescription.slice(1), generatePlanError]
        : [previousDescription, generatePlanError]

      const errorsFoundMessage = `${newDescription.length} error found:`

      setAlertProps((prev) => ({
        ...prev,
        description: [errorsFoundMessage, ...newDescription],
      }))

      return
    }

    const alertTile =
      planGenerationType === 'create'
        ? VALIDATION_ALERT_TILE.CREATE
        : VALIDATION_ALERT_TILE.REGENERATE

    showErrorAlert({
      title: alertTile,
      description: [generatePlanError],
    })
  }, [generatePlanError])

  return {
    validationAlertProps: alertProps,
    closeValidationAlert: closeAlert,
    handleSubmit,
  }
}
