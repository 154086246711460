import readXlsxFile from "read-excel-file";
import moment from "moment";
import * as _ from "lodash";
import { parseStringToNumbers } from "./parseStringToNumbers"
import { COST_NAME_IN_CHANNEL, DEFAULT_DATE_FORMAT } from "./constants";

export const uploadSPOConfigV2 = async (file) => {
    console.log("VERSION 2")

    const defaultSheets = ["curves", "options"];
    let config = {
        file_name: "unknown",
        name: "unknown",
        observations: [],
        transformations: [],
        grouping_levels: [],
        max_constraints: null,
        constraints: [],
        thresholds: null,
        options: {
            use_point_calculation: false,
            use_shuffle: false,
            in_channel: false,
            increment: 10000,
            floodAmount: 5000000,
            carryoverWeeks: 0,
            exchangeRates: { defaultCurrency: "GBP", defaultSymbol: "£", currencies: ["GBP"], currencySymbols: ["£"], rates: [1] },
            kpiRanges: [],
            optimisableKPIs: [],
            kpiDisplay: {
                kpi: [],
                function: [],
                parameters: []
            },
            imr: [],
            version: 2
        }
    };
    let error = "";
    let observations = [];
    let sheetValues = { spend: [] };

    await readXlsxFile(file, { getSheets: true }).then(sheets => {
        sheets.map(x => x.name).forEach(sheetName => {
            if (defaultSheets.indexOf(sheetName) === -1) {
                sheetValues[sheetName] = [];
                return readXlsxFile(file, { sheet: sheetName }).then(rows => {
                    return rows.forEach(row => {
                        sheetValues[sheetName].push(row);
                    });
                });
            }
        });
    });

    await readXlsxFile(file, { sheet: "options" }).then(rows => {
        console.log(`options sheet uploading`);

        const use_shuffleIndex = rows[0].findIndex(x => x.toLowerCase() === "s-shaped curves");
        if (use_shuffleIndex !== -1 && rows[1] && rows[1][use_shuffleIndex]) {
            config.options.use_shuffle = trueOrFalse(rows[1][use_shuffleIndex])
        }

        const in_channelIndex = rows[0].findIndex(x => x.toLowerCase() === "in channel config?");
        if (in_channelIndex !== -1 && rows[1] && rows[1][in_channelIndex]) {
            config.options.in_channel = trueOrFalse(rows[1][in_channelIndex])
        }

        const floodAmountIndex = rows[0].findIndex(x => x.toLowerCase() === "s-shaped max weekly spend");
        if (floodAmountIndex !== -1 && rows[1] && rows[1][floodAmountIndex]) {
            config.options.floodAmount = parseStringToNumbers(rows[1][floodAmountIndex]);
        }

        const incrementIndex = rows[0].findIndex(x => x.toLowerCase() === "optimisation increment");
        if (incrementIndex !== -1 && rows[1] && rows[1][incrementIndex]) {
            config.options.increment = parseStringToNumbers(rows[1][incrementIndex]);
        }

        const carryoverWeeksIndex = rows[0].findIndex(x => x.toLowerCase() === "carryover weeks")
        if (carryoverWeeksIndex !== -1 && rows[1] && rows[1][carryoverWeeksIndex]) {
            config.options.carryoverWeeks = rows[1][carryoverWeeksIndex];
        }

        const spendMinIndex = rows[0].findIndex(x => x.toLowerCase() === "spend min")
        const spendMaxIndex = rows[0].findIndex(x => x.toLowerCase() === "spend max")
        if (spendMinIndex !== -1 && rows[1] && rows[1][spendMinIndex] &&
            spendMaxIndex && rows[1][spendMaxIndex]) {
            const spend = {
                kpi: "spend",
                min: parseStringToNumbers(rows[1][spendMinIndex]),
                max: parseStringToNumbers(rows[1][spendMaxIndex])
            }
            config.options.kpiRanges = [...config.options.kpiRanges, spend]
        }
        
        console.log(`options sheet loaded successfully`);

        return;
    });

    if (Object.keys(sheetValues).indexOf("max_constraints") !== -1) {
        console.log(`max constraints sheet uploading`);

        const max_constraints = {
            ids: [],
            values: []
        };

        await readXlsxFile(file, { sheet: "max_constraints" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                max_constraints.ids = [...max_constraints.ids, row[0]];
                max_constraints.values = [...max_constraints.values, parseStringToNumbers(row[1])];
            }
            config.max_constraints = max_constraints;
            console.log(`max_constraints sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("thresholds") !== -1) {
        console.log(`thresholds sheet uploading`);

        const thresholds = {
            ids: [],
            weeklyValues: [],
            totalValues: []
        };

        await readXlsxFile(file, { sheet: "thresholds" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                thresholds.ids = [...thresholds.ids, row[0]];
                thresholds.weeklyValues = [...thresholds.weeklyValues, parseStringToNumbers(row[1])];
                thresholds.totalValues = [...thresholds.totalValues, parseStringToNumbers(row[2])];
            }
            config.thresholds = thresholds;
            console.log(`thresholds sheet loaded successfully`);
        });
    }

    await readXlsxFile(file, { sheet: "seasonality" }).then(rows => {
        console.log(`seasonality sheet uploading`);

        rows[0].shift();

        observations = rows[0].map(date => createObservation(date));
        config.observations = observations;
        console.log(`seasonality sheet loaded successfully`);
    });

    await readXlsxFile(file, {
        sheet: "curves"
    }).then(
        rows => {
            console.log(`curves sheet uploading`);
            const headingRow = rows.shift();
            const headerMap = XLSXMapperV2(headingRow);

            let { groupingLevels, identifiers } = createGroupingLevels(
                rows,
                headingRow,
                headerMap
            );

            config.grouping_levels = groupingLevels;
            rows.forEach(row => {
                let parsedRow = parseRowV2(
                    row,
                    identifiers,
                    headerMap,
                    sheetValues
                );
                config.transformations.push(parsedRow);
                console.log(
                    `Successfully added row with grouping ID ${row[0]}`
                );
            });
            console.log(`data_parent sheet loaded successfully`);
        }
    );

    if (Object.keys(sheetValues).indexOf("halo") !== -1) {
        await readXlsxFile(file, { sheet: "halo" }).then(rows => {
            console.log(`halo sheet uploading`);

            const headingRow = rows.shift();
            const headerMap = XLSXMapperV2(headingRow);

            let { _, identifiers } = createGroupingLevels(
                rows,
                headingRow,
                headerMap
            );
            console.log(_);

            rows.forEach(row => {
                let parsedRow = parseRowV2(
                    row,
                    identifiers,
                    headerMap,
                    sheetValues
                );

                parsedRow.is_halo = true;
                config.transformations.push(parsedRow);
            });
            console.log(`halo sheet loaded successfully`);
        });
    }

    if (Object.keys(sheetValues).indexOf("kpi") !== -1) {
        await readXlsxFile(file, { sheet: "kpi" }).then(rows => {
        console.log(`kpi sheet uploading`);
        for (let row of rows) {
            const kpiKey = row[0]
            const optimisable = row[5]
            if(trueOrFalse(optimisable)) {
                config.options.optimisableKPIs = [...config.options.optimisableKPIs, kpiKey]
                const kpiRange = {
                    kpi: kpiKey,
                    min: row[6],
                    max: row[7]
                }
                config.options.kpiRanges = [...config.options.kpiRanges, kpiRange]
            }
        }
        const outputKeyIndex = rows[0].findIndex(x => x.toLowerCase() === "kpi key")
        const outputLabelIndex = rows[0].findIndex(x => x.toLowerCase() === "kpi label")
        const outputUnitIndex = rows[0].findIndex(x => x.toLowerCase() === "kpi unit")
        const aggregationFunctionIndex = rows[0].findIndex(x => x.toLowerCase() === "aggregation function")
        const functionInput = rows[0].findIndex(x => x.toLowerCase() === "function inputs")

        const kpiRows = rows.slice(1)
        for(let row of kpiRows){
            config.transformations.forEach(trans => {
                const outputKPI = {
                    type: "output",
                    key: row[outputKeyIndex] ? row[outputKeyIndex] : "",
                    label: row[outputLabelIndex] ? row[outputLabelIndex] : "",
                    unit: row[outputUnitIndex] ? row[outputUnitIndex] : "",
                    output_type: "",
                    visible: true,
                    editable: false,
                    values: new Array(config.observations.length).fill(0)
                }
                trans.io = [...trans.io, outputKPI]
            })
        }
        config.transformations.forEach(trans => {
            const spendInput = {
                type: "input",
                key: "spend",
                label: "Spend",
                unit: "currency",
                output_type: "",
                visible: true,
                editable: true,
                values: new Array(config.observations.length).fill(0)
            }
            trans.io = [...trans.io, spendInput]
        })
        let kpiLabelsToLowerCase = []
        let kpiFunctions = []
        let kpiParameters = []

        kpiRows.map(kpiRow => {
            if(kpiRow[aggregationFunctionIndex] && kpiRow[functionInput]){
                const params = kpiRow[functionInput].split(",")
                kpiLabelsToLowerCase = [...kpiLabelsToLowerCase, kpiRow[outputLabelIndex].toLowerCase().replace(/ /g, "_")]
                kpiFunctions = [...kpiFunctions, kpiRow[aggregationFunctionIndex]]
                kpiParameters = [...kpiParameters, params]
            }
        })

        config.options.kpiDisplay.kpi = kpiLabelsToLowerCase
        config.options.kpiDisplay.function = kpiFunctions
        config.options.kpiDisplay.parameters = kpiParameters
        });
        console.log(`kpi sheet loaded successfully`);
    }

    await readXlsxFile(file, { sheet: "kpi_parameters" }).then(rows => {
        rows[0].shift();

        for(let transform of config.transformations){
            const transformKPIs = rows.filter(row => row[0] === transform.grouping_id);
            let output = []
            for(let row of transformKPIs){
                let parsArray = row.filter(x => {
                    if(x && x !== null){
                        return x
                    }
                })
                parsArray.splice(0,3)
                const param = {
                    output: row[1],
                    type: row[2],
                    parameters: parsArray
                }
                output = [...output, param]
            }
            transform.output_parameters = output
        }
    });

    if (Object.keys(sheetValues).indexOf("constraints_level1") !== -1) {
        const levelName = config.grouping_levels.filter(x => x.key !== "region_key")[0].key
        console.log("HUH")
        console.log(config.grouping_levels)
        console.log(levelName)
        console.log(`constraints_level1 sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_level1" }).then(rows => {
            rows.shift();
            for (let row of rows) {
                if(row[1].toLowerCase().replace(/ /g, "_") !== "range") {
                    const constraint = {
                        fn: row[1].toLowerCase().replace(/ /g, "_"),
                        identifiers: {
                            [levelName]:
                                row[0].toLowerCase().replace(/ /g, "_"),
                        },
                        values: [parseStringToNumbers(row[2])],
                        variable: "spend"
                    };
                    config.constraints = [...config.constraints, constraint];
                } else {
                    const minConstraint = {
                        fn: "min",
                        identifiers: {
                            [levelName]:
                                row[0].toLowerCase().replace(/ /g, "_"),
                        },
                        values: [parseStringToNumbers(row[2])],
                        variable: "spend"
                    };
                    const maxConstraint = {
                        ...minConstraint,
                        fn: "max",
                        values: [parseStringToNumbers(row[3])]
                    }
                    config.constraints = [...config.constraints, minConstraint, maxConstraint];
                }

            }
            console.log(`constraints_level1 sheet loaded successfully`);
        });
    }

    for(let i = 2; i < config.grouping_levels.length; i++){
        const constraintLevel = "constraints_level" + i
        const level = config.grouping_levels.filter(x => x.key !== "region_key")[i - 1].key
        const parentLevel = config.grouping_levels.filter(x => x.key !== "region_key")[i - 2].key
        if (Object.keys(sheetValues).indexOf(constraintLevel) !== -1) {
            console.log(`${constraintLevel} sheet uploading`);
    
            await readXlsxFile(file, { sheet: constraintLevel }).then(rows => {
                rows.shift();
                for (let row of rows) {
                    if(row[2].toLowerCase().replace(/ /g, "_") !== "range"){
                        const constraint = {
                            fn: row[2].toLowerCase().replace(/ /g, "_"),
                            identifiers: {
                                [parentLevel]:
                                    row[0].toLowerCase().replace(/ /g, "_"),
                                [level]: row[1].toLowerCase().replace(/ /g, "_")
                            },
                            values: [parseStringToNumbers(row[3])],
                            variable: "spend"
                        };
                        config.constraints = [...config.constraints, constraint];
                    } else {
                        const minConstraint = {
                            fn: "min",
                            identifiers: {
                                [parentLevel]:
                                    row[0].toLowerCase().replace(/ /g, "_"),
                                [level]:
                                    row[1].toLowerCase().replace(/ /g, "_"),
                            },
                            values: [parseStringToNumbers(row[3])],
                            variable: "spend"
                        }
                        const maxConstraint = {
                            ...minConstraint,
                            fn: "max",
                            values: [parseStringToNumbers(row[4])]
                        }
                        config.constraints = [...config.constraints, minConstraint, maxConstraint];
                    }
                }
                console.log(`${constraintLevel} sheet loaded successfully`);
            });
        }
    }

    if (Object.keys(sheetValues).indexOf("constraints_weekly") !== -1) {
        console.log(`constraints_weekly sheet uploading`);

        await readXlsxFile(file, { sheet: "constraints_weekly" }).then(rows => {
            const typeIndex = rows[0].indexOf("Type")
            rows.shift();
            for (let row of rows) {
                let vals = [null];
                for (let i = typeIndex + 1; i < row.length; i++) {
                    vals = [...vals, parseStringToNumbers(row[i])]
                }
                let identifiers = {}
                for(let i = 0; i < typeIndex; i ++){
                    const identifierName = "level" + (i + 1).toString()
                    identifiers[identifierName] = row[i].toLowerCase().replace(/ /g, "_")
                }
                const constraint = {
                    fn: row[typeIndex].toLowerCase().replace(/ /g, "_"),
                    identifiers: identifiers,
                    values: vals,
                    variable: "spend"
                };
                config.constraints = [...config.constraints, constraint];
            }
            console.log(`constraints_weekly sheet loaded successfully`);
        });
    }

    config.grouping_levels.shift();
    const output = {
        config: config,
        error: error
    };

    console.log(output)

    errorCheck(output);

    return output;
}

const createGroupingLevels = (rows, headingRow, headerMap) => {
    let identifiers = [];
    let groupingLevels = [];
    for (let i = headerMap.regionKeyIndex; i < headerMap.currencyIndex; i++) {
        let key = headingRow[i]
            .toLowerCase()
            .split(" ")
            .join("_");

        identifiers.push(key);

        let groupingLevel = {
            key: key,
            label: headingRow[i],
            values: []
        };

        let values = [];
        rows.forEach(row => {
            let value = {
                key: row[i]
                    .toLowerCase()
                    .split(" ")
                    .join("_"),
                label: row[i]
            };

            let found = values.map(val => val.label).indexOf(row[i]);

            if (found === -1) {
                values.push(value);
            }

            if (key === "region") {
                value.ISO_A2 = row[headerMap.regionKeyIndex].toLowerCase();
                value.region_group = row[headerMap.regionGroup];
            }
        });

        groupingLevel.values = values;
        groupingLevels.push(groupingLevel);
    }

    return { groupingLevels, identifiers };
};



function formatValue(value) {
    return value
        .toLowerCase()
        .split(" ")
        .join("_");
}

export function createObservation(date) {
    let excelDateToJsDate = new Date(Math.round((date - 25569) * 86400 * 1000));
    if (!isValidDate(excelDateToJsDate)) {
        excelDateToJsDate = date;
    }

    return {
        key: moment.utc(excelDateToJsDate).format(DEFAULT_DATE_FORMAT.KEY),
        label: moment.utc(excelDateToJsDate).format(DEFAULT_DATE_FORMAT.LABEL)
    };
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function errorCheck(output) {
    const identifiers = output.config.transformations.map(x =>
        JSON.stringify(Object.values(x.identifiers))
    );
    const uniqueIdentifiers = _.uniqBy(identifiers, function (e) {
        return e;
    });

    if (identifiers.length !== uniqueIdentifiers.length) {
        output.error =
            "In the data parent sheet, two rows cannot have the same identifiers. Please ensure each row has unique identifiers.";
    }

    console.log(output);
}

const parseRowV2 = (
    row,
    identifiers,
    headerMapper,
    sheetValues
) => {
    //MOVED THE HALO CURVE ID NOW ALSO
    console.log(`Attempting to parse row with curve ID ${row[0]}`);

    let transform = { io: [] };
    let identifier = {};
    identifiers.forEach((id, index) => {
        identifier[id] = formatValue(row[index + headerMapper.regionKeyIndex]);
    });

    let io = row[headerMapper.inputKeyIndex].split(",").map((input, index) => {
        const label = row[headerMapper.inputKeyIndex].split(",")[index];
        const unit = row[headerMapper.inputUnitIndex].split(",")[index];
        const dataRow = row[headerMapper.inputReferenceIndex].toString().split(",")[index]

        if (!sheetValues[input]) {
            console.log(
                `Please make sure there are no spaces after the commas in row ${row[0]
                }`
            );
        }

        let ioValues = sheetValues[input][Number(dataRow)];
        if (!ioValues) {
            console.log(
                `Please check that the ${input} Input Reference for row ${row[0]
                } exists in the ${input} sheet.`
            );
        }
        ioValues = ioValues.slice();
        ioValues.shift();

        return {
            type: "input",
            key: input,
            label: label,
            unit: unit,
            output_type: null,
            visible: input === COST_NAME_IN_CHANNEL ? true : false,
            editable: false,
            values: ioValues
        };
    });

    transform.io = [...io];
    transform.identifiers = identifier;
    transform.fn = formatValue(row[headerMapper.functionIndex]);
    transform.grouping_id = row[headerMapper.groupingID];
    transform.params = [];
    let paramIndex = headerMapper.functionIndex + 1;
    for (let i = paramIndex; i < row.length; i++) {
        if (row[i] || row[i] === 0) {
            transform.params.push(row[i]);
        }
    }

    console.log(`Successfully parsed row with grouping ID ${row[0]}`);

    return transform;
};

const XLSXMapperV2 = headingRow => {
    return {
        groupingID: headingRow.indexOf("Curve ID"),
        regionGroup: headingRow.indexOf("Region Group"),
        regionKeyIndex: headingRow.indexOf("Region Key"),
        level1Index: headingRow.indexOf("Level1"),
        currencyIndex: headingRow.indexOf("Currency"),
        inputKeyIndex: headingRow.indexOf("Function Input Key"),
        inputUnitIndex: headingRow.indexOf("Function Input Unit"),
        inputReferenceIndex: headingRow.indexOf("Function Input Reference"),
        functionIndex: headingRow.indexOf("Function"),
    };
};

const trueOrFalse = (value) => {
    if(!value || value === false){
        return false
    }

    if(value === true || value.toLowerCase() === "yes" || value.toLowerCase() === "1" || value.toLowerCase() === "true") {
        return true
    }

    return false
}


