import React, { FC } from 'react'
import { MEDIA_CHANNEL_ICON } from '../../types/MediaChannelAllocationTypes'
import TVIcon from './TVIcon'
import PrintIcon from './PrintIcon'
import DigitalIcon from './DigitalIcon'
import OutdoorIcon from './OutdoorIcon'
import SponsorshipIcon from './SponsorshipIcon'
import CouponsIcon from './CouponsIcon'
import AudioIcon from './AudioIcon'
import PRIcon from './PRIcon'  
import RadioIcon from './RadioIcon'
import VideoIcon from './VideoIcon'
import SearchIcon from './SearchIcon'
import AppIcon from './AppIcon'
import MessagesIcon from './MessagesIcon'
import DirectMailIcon from './DirectMailIcon'
import AffiliateIcon from './AffiliateIcon'
import MobileIcon from './MobileIcon'
import { Icon } from '@gain-theory/icon'
import MediaIcon from './MediaIcon'

interface OwnProps {
  className: string
  type: string
  stroke?: string
}

type Props = OwnProps

const GetIcon = (className: string, type: string, stroke?: string) => {

  switch (type) {
    case MEDIA_CHANNEL_ICON.television:
    case MEDIA_CHANNEL_ICON.tv_placement:
    case MEDIA_CHANNEL_ICON.adsmart:
    case MEDIA_CHANNEL_ICON.drtv:
    case MEDIA_CHANNEL_ICON.satellite:
    case MEDIA_CHANNEL_ICON.cable:
    case MEDIA_CHANNEL_ICON.tv:
    case MEDIA_CHANNEL_ICON.ctv:
    case MEDIA_CHANNEL_ICON.regional_tv:
    case MEDIA_CHANNEL_ICON.metro_tv:
      return <TVIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.print:
    case MEDIA_CHANNEL_ICON.press:
    case MEDIA_CHANNEL_ICON.magazines:
    case MEDIA_CHANNEL_ICON.newspapers:
      return <PrintIcon className={className} stroke={stroke} />

      
    case MEDIA_CHANNEL_ICON.digital:
    case MEDIA_CHANNEL_ICON.remarketing:
    case MEDIA_CHANNEL_ICON.display:
    case MEDIA_CHANNEL_ICON.web_display:
    case MEDIA_CHANNEL_ICON.ppc:
    case MEDIA_CHANNEL_ICON.google:
    case MEDIA_CHANNEL_ICON.sem:
    case MEDIA_CHANNEL_ICON.amobee:
    case MEDIA_CHANNEL_ICON.bing:
    case MEDIA_CHANNEL_ICON.dsp:
    case MEDIA_CHANNEL_ICON.programmatic:
    case MEDIA_CHANNEL_ICON.generic_ppc:
      return <DigitalIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.outdoor:
    case MEDIA_CHANNEL_ICON.ooh:
    case MEDIA_CHANNEL_ICON.out_of_home:
      return <OutdoorIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.sponsorship:
    case MEDIA_CHANNEL_ICON.sponsor:
    case MEDIA_CHANNEL_ICON.influencer:
    case MEDIA_CHANNEL_ICON.partnership:
    case MEDIA_CHANNEL_ICON.kol:
    case MEDIA_CHANNEL_ICON.key_opinion_leader:
      return <SponsorshipIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.coupons:
    case MEDIA_CHANNEL_ICON.target:
    case MEDIA_CHANNEL_ICON.sale:
    case MEDIA_CHANNEL_ICON.amazon:
    case MEDIA_CHANNEL_ICON.retail:
    case MEDIA_CHANNEL_ICON.shopping:
    case MEDIA_CHANNEL_ICON.alibaba:
    case MEDIA_CHANNEL_ICON.walmart:
    case MEDIA_CHANNEL_ICON.gaming:
      return <CouponsIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.audio:
    case MEDIA_CHANNEL_ICON.podcasts:
    case MEDIA_CHANNEL_ICON.spotify:
    case MEDIA_CHANNEL_ICON.pandora:
      return <AudioIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.pr:
    case MEDIA_CHANNEL_ICON.public_relations:
    case MEDIA_CHANNEL_ICON.event:
    case MEDIA_CHANNEL_ICON.roadshow:
    case MEDIA_CHANNEL_ICON.press_release:
      return <PRIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.radio:
    case MEDIA_CHANNEL_ICON.digital_audio:
    case MEDIA_CHANNEL_ICON.terrestrial:
      return <RadioIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.video:
    case MEDIA_CHANNEL_ICON.fep:
    case MEDIA_CHANNEL_ICON.digital_video:
    case MEDIA_CHANNEL_ICON.vod:
    case MEDIA_CHANNEL_ICON.tik_tok:
    case MEDIA_CHANNEL_ICON.tiktok:
    case MEDIA_CHANNEL_ICON.olv:
    case MEDIA_CHANNEL_ICON.ott:
    case MEDIA_CHANNEL_ICON.cinema:
    case MEDIA_CHANNEL_ICON.yt:
    case MEDIA_CHANNEL_ICON.youtube:
    case MEDIA_CHANNEL_ICON.movie:
      return <VideoIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.search:
    case MEDIA_CHANNEL_ICON.paid_search:
    case MEDIA_CHANNEL_ICON.apple_search:
      return <SearchIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.messages:
    case MEDIA_CHANNEL_ICON.social:
    case MEDIA_CHANNEL_ICON.facebook:
    case MEDIA_CHANNEL_ICON.twitter:
    case MEDIA_CHANNEL_ICON.snapchat:
    case MEDIA_CHANNEL_ICON.instagram:
    case MEDIA_CHANNEL_ICON.chat:
    case MEDIA_CHANNEL_ICON.whatsapp:
    case MEDIA_CHANNEL_ICON.imessage:
    case MEDIA_CHANNEL_ICON.groupme:
    case MEDIA_CHANNEL_ICON.ig:
    case MEDIA_CHANNEL_ICON.fb:
    case MEDIA_CHANNEL_ICON.pinterest:
      return <MessagesIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.direct_mail:
    case MEDIA_CHANNEL_ICON.mail:
      return <DirectMailIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.affiliate:
      return <AffiliateIcon className={className} stroke={stroke} />

    case MEDIA_CHANNEL_ICON.mobile:
    case MEDIA_CHANNEL_ICON.phone:
      return <MobileIcon className={className} stroke={stroke} />


    case MEDIA_CHANNEL_ICON.media:
        return <MediaIcon className={className} stroke={stroke} />

    default:
      return <MediaIcon className={className} stroke={stroke} />
  }
}

const MediaChannelIcon: FC<Props> = ({ className, type, stroke = 'current' }) => {
  const matchKeys = Object.values(MEDIA_CHANNEL_ICON).map(x => x.toLowerCase())
  const match = bestResult(matchKeys, type)

  return GetIcon(className, match, stroke)
}

 const bestResult = (candidates: string[], query: string) => {
  const stringSimilarity = require("string-similarity");

    const matches = stringSimilarity.findBestMatch(query, candidates);

    return matches.bestMatch.target;
}

export default MediaChannelIcon
