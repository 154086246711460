import { useEffect, useState } from "react";
import { pendoApi } from "@gain-theory/pendo-sdk";
import { OnetrustSdk } from '@gain-theory/onetrust-sdk'
import { useSelector } from "react-redux";
import { selectSessionUser } from "../redux/reducers/SessionReducer";
import { getVisitorMetadata } from "../utils/analytics";

type TConsent = {
  accepted: boolean;
  rejected: boolean;
  changed: boolean;
};

type TUseConsent = {
  disableTracking?: boolean;
}

export const useConsent = (props?: TUseConsent) => {
  const [consent, setConsent] = useState<TConsent>({
    accepted: false,
    rejected: false,
    changed: false,
  });

  const user = useSelector(selectSessionUser)
  const consentTracker = (newConsent: Partial<TConsent>) => () => {
    setConsent(prev => ({
      ...prev,
      ...newConsent
    }));
  }

  useEffect(() => {
    OnetrustSdk.init(props?.disableTracking ? undefined : {
      onConsentAccept: consentTracker({ accepted: true }),
      onConsentReject: consentTracker({ rejected: true }),
      onConsentChange: consentTracker({ changed: true }),
      setCookieIfNotProd: true,
    });

    OnetrustSdk.noShowWhenQueryStringExists();

  }, []);

  useEffect(() => {
    if (user && user.id && (consent.accepted || consent.rejected || consent.changed)) {
      pendoApi.track('UserConsent', {
        ...consent,
        ...getVisitorMetadata(user)
      })
    }
  }, [user, consent]);
}