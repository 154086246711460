import {
  Brand,
  Channel,
  ChannelKey,
  KPI,
  Phase,
} from '../../../types/MarketingDiagnosticsTypes'
import {
  PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT,
  PERFORMANCE_OVERVIEW_GET_DATA_FAIL,
  PERFORMANCE_OVERVIEW_GET_DATA_SUCCESS,
  PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS,
  PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS,
  PerformanceOverviewActionTypes,
} from '../../actions/PerformanceOverviewActionTypes'

interface IDefaultState {
  loading: boolean
  headers: string[]
  previous: Channel[]
  channels: Channel[]
  kpis: KPI[]
  phases: Phase[]
  brands: Record<'brand', string>[]
  currentPhase: string
}

const defaultState: IDefaultState = {
  loading: false,
  headers: ['Channel', 'Spend', 'Impressions', 'Effectiveness', 'Productivity'],
  previous: [],
  channels: [],
  kpis: [],
  phases: [],
  brands: [],
  currentPhase: '',
}

const performanceOverviewReducer = (
  state: IDefaultState = defaultState,
  action: PerformanceOverviewActionTypes
): IDefaultState => {

  switch (action.type) {
    case PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT:
      return { ...state, loading: true }
    case PERFORMANCE_OVERVIEW_GET_DATA_FAIL:
      return { ...state, loading: false }
    case PERFORMANCE_OVERVIEW_GET_DATA_SUCCESS:
      return { ...state, loading: false }
    case PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS:
      const headers = [...defaultState.headers]
      const kpiHeader = action.payload.kpis[0].label
      headers.splice(3, 0, kpiHeader)
      return { ...state, ...action.payload, headers: headers }
    case PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    default:
      return state
  }
}

export default performanceOverviewReducer
