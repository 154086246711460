import readXlsxFile from "read-excel-file";

const uploadMDConfig = async (file) => {
    let config = {
        brands: [],
        regions: [],
        currency: [],
        imr: {nodes: [], links: []},
        kpis: [],
        recommendations: [],
        scores: [],
        sensor_view_links: [],
        view_link: [],
        views: [],
        channels: []
    };

    console.log("In upload", file);
    // REGIONS
    await readXlsxFile(file, { sheet: 1 }).then(rows => {
        console.log("Rows ", rows)
        rows.shift();
        config.regions = rows.map(row => {
            return { key: row[0], region: row[1], label: row[2], sort: row[3] };
        });
        console.log("Read SHEET > ", 1)
    });

    // read brands
    await readXlsxFile(file, { sheet: 2 }).then(rows => {
        rows.shift();
        config.brands = rows.map(row => {
            return {
                brand: row[0],
                label: row[1],
                sort: row[2],
                logoUrl: row[3]
            };
        });
        console.log("Read SHEET > ", 2)
    });

    // CURRENCY
    await readXlsxFile(file, { sheet: 3 }).then(rows => {
        rows.shift();
        config.currency = rows.map(row => {
            return { key: row[0], symbol: row[1] };
        });
        console.log("Read SHEET > ", 3)
    });

    // VIEWS
    await readXlsxFile(file, { sheet: 4 }).then(rows => {
        rows.shift();
        config.views = rows.map(row => {
            return {
                key: row[0],
                label: row[1],
                sort: row[2],
                description: row[3]
            };
        });
        console.log("Read SHEET > ", 4)
    });

    // view links
    await readXlsxFile(file, { sheet: 5 }).then(rows => {
        rows.shift();
        config.view_link = rows.map(row => {
            return {
                brand: row[0],
                view: row[1],
                brand_url: row[0].replace(/\s+/g, "_").toLowerCase(),
                view_url: row[1].replace(/\s+/g, "_").toLowerCase(),
                root: row[2],
                site: row[3],
                name: row[4],
                filter: row[5]
            };
        });
        console.log("Read SHEET > ", 5)
    });

    // RECOMMENDATIONS
    await readXlsxFile(file, { sheet: 6 }).then(rows => {
        rows.shift();
        config.recommendations = rows.map(row => {
            return {
                market: row[0],
                brand: row[1],
                channel: row[2],
                headline: row[3],
                description: row[4],
                impact: row[5],
                root: row[6],
                site: row[7],
                name: row[8],
                filter: row[9]
            };
        });
        console.log("Read SHEET > ", 6)
    });

    // IMR
    await readXlsxFile(file, { sheet: 13 })
        .then(rows => {
            rows.shift();
            let imr = { nodes: [], links: [] };

            let models = [...new Set(rows.map(row => row[1]))];
            let contributions = [...new Set(rows.map(row => row[0]))];
            imr.nodes = [...new Set([...models, ...contributions])];
            imr.links = rows.map(row => {
                let source = imr.nodes.indexOf(row[1]);
                let target = imr.nodes.indexOf(row[0]);
                return { source, target, value: row[2] };
            });

            imr.nodes = imr.nodes.map(node => {
                return { name: node };
            });
            config.imr = imr;
        })
        .catch(err => {console.log("ERRRor loading sheet 13")});

    await readXlsxFile(file, { sheet: 7 }).then(rows => {
        rows.shift();
        config.channels = rows.map(row => {
            return { channel: row[0], db_column: row[1] };
        });


        console.log("Read SHEET > ", 7)
    });

    await readXlsxFile(file, { sheet: 9 }).then(rows => {
        rows.shift();
        config.kpis = rows.map(row => {
            return {
                metric: row[0].replace(/\s+/g, "_").toLowerCase(),
                label: row[1],
                parameters: row[2],
                calculation: row[3],
                change_calc: row[4],
                unit: row[5]
            };
        });

        console.log("Read SHEET > ", 9)
    });

    await readXlsxFile(file, { sheet: 10 }).then(rows => {
        console.log("UPLOAD METRICS", rows);
        rows.shift();
        let scores = [];

        rows.forEach(row => {
            // let score = scores.find(element => element.score === row[0]);
            // if (!score) {
            //     score = { score: row[0] };
            //     scores.push(score);
            // }

            let metric = {};
            metric.score = row[0];
            metric.metric = row[1] ? row[1].replace(/\s+/g, "_").toLowerCase() : "";
            metric.parameters = row[2] ? row[2].split(",") : "";
            metric.constant = row[3];
            metric.calculation = row[4];
            metric.unit = row[5];
            scores.push(metric);
        });

        config.scores = scores;
        console.log("Read SHEET > ", 10)
    }).catch(e => console.log("METRIC ERROR", e));

    //sensor view links
    await readXlsxFile(file, { sheet: 14 }).then(rows => {
        rows.shift();
        config.sensor_view_links = rows.map(row => {
            return {
                brand: row[0],
                view: row[1],
                brand_url: row[0].replace(/\s+/g, "_").toLowerCase(),
                view_url: row[1].replace(/\s+/g, "_").toLowerCase(),
                root: row[2],
                site: row[3],
                name: row[4],
                filter: row[5]
            };
        });
    });

    return config;
}

export default uploadMDConfig
