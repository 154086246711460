import { useState } from "react";
import DownArrowIcon from "../../../../../icons/DownArrowIcon";
import NoValueIcon from "../../../../../icons/NoValueIcon";
import StableIcon from "../../../../../icons/StableIcon";
import UpArrowIcon from "../../../../../icons/UpArrowIcon";
import { Channel } from "../../../../../types/MarketingDiagnosticsTypes";
import { Tooltip } from "./tooltip";

type TUpDownIndicator = {
  indicatorKey: string;
  previous: Channel;
  metric: string;
  channel: Channel;
  previousHolder: any;
}

const comparisonDisplay = (metric: string) => {
  if (metric === "is lower") {
    return <DownArrowIcon className='' />;
  }

  if (metric === "is higher") {
    return <UpArrowIcon className="" />;
  }

  return <StableIcon className="" />;
};

export const UpDownIndicator = ({
  indicatorKey,
  previous,
  channel,
  previousHolder,
  metric
}: TUpDownIndicator) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    setShowTooltip(prev => !prev);
  }
  return (
    <div className="grid grid-flow-col justify-around relative" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
      {previous ? comparisonDisplay(metric) :
        (channel as any)[indicatorKey] === 0 ? (
          <NoValueIcon className="" />
        ) : (
          <div className="w-8">
            {(channel as any)[indicatorKey].toFixed(2)}
          </div>
        )}
      {(showTooltip && previous) && <Tooltip
        title={indicatorKey}
        value={(channel as any)[indicatorKey]}
        indicator={comparisonDisplay(metric)}
        label={channel.label}
        metric={metric}
        previousValue={previousHolder ? previousHolder[indicatorKey] : ""}
      />}
    </div>
  );
};
