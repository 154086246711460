import readXlsxFile from "read-excel-file";


export const validateSheetName = async (file, sheet) => { 
    let valid = false
    await readXlsxFile(file, { getSheets: true }).then(sheets => {
        if(sheets.map(x => x.name).indexOf(sheet) === -1){
            valid = false
        } else {
            valid = true
        }
    });
    return valid
}