import { Button } from '@gain-theory/button';
import { Icon, IconType } from '@gain-theory/icon';
import { IconButton } from '@gain-theory/icon-button';
import { Tooltip } from '@gain-theory/tooltip';
import { memo } from 'react';

type LoginCardActionProps = {
  id: string
  showIconButton: boolean
  label: string
  icon: IconType['name']
  library: IconType['library']
  onClick: () => void
}

export const LoginCardAction = memo(
  ({
    id,
    showIconButton,
    label,
    icon,
    library,
    onClick,
  }: LoginCardActionProps) => {
    if (showIconButton) {
      return (
        <Tooltip content={label}>
          <IconButton
            variant="brand-primary"
            id={id}
            iconName={icon}
            iconLibrary={library}
            onClick={onClick}
            strokeWidth="1.5px"
          />
        </Tooltip>
      );
    }

    return (
      <Button
        variant="tertiary"
        id={id}
        leadingIcon={
          <Icon color="brand-primary-main" name={icon} library={library} />
        }
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }
);
