import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CreatePlanIcon from '../../icons/spo/CreatePlanIcon'
import ExistingPlanIcon from '../../icons/spo/ExistingPlanIcon'
import EditBudgetIcon from '../../icons/spo/EditBudgetIcon'
import SalesGoalIcon from '../../icons/spo/SalesGoalIcon'
import PlanSummaryTable from '../../shared/PlanSummaryTable'
import { IPlanSummary } from '../../types/PlanTypes'
import { Config } from '../../types/ConfigTypes'
import { Settings } from '../../types/SettingTypes'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import { RootStore } from '../../redux/reducers/Store'
import { connect, ConnectedProps } from 'react-redux'
import { selectShowSharePlanModal } from '../../redux/reducers/PlanReducer'
import {
  SharePlanWithUsers,
  ToggleSharePlanModal,
} from '../../redux/actions/PlanActions'
import { selectSessionAllUsers } from '../../redux/reducers/SessionReducer'
import { GetUsers } from '../../redux/actions/SessionActions'
import { IUser } from '../../types'
import { isPropertySignature } from 'typescript'
import { MAX_USERNAME_DISPLAY_LENGTH } from '../../utils/constants'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
  showModal: selectShowSharePlanModal(state),
})
const mapDispatchToProps = {
  onShowSharePlanModal: ToggleSharePlanModal,
  onGetUsers: GetUsers,
  onSharePlan: SharePlanWithUsers,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {}

const SharePlansModal: FC<OwnProps> = (props) => {
  const [displayUsers, setDisplayedUsers] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  useEffect(() => {
    props.onGetUsers()
  }, [])

  useEffect(() => {
    setSelectedUsers([])
    setDisplayedUsers([])
  }, [])

  const handleSearchChange = (e: any) => {
    console.log("jenea")
    if (e.target.value.length > 2 && props.users) {
      const allUsers = props.users
        .filter((user) => user.enabled)
        .map((user) => user.username)
      const foundUsers = allUsers.filter((x) =>
        x.toLowerCase().includes(e.target.value.toLowerCase())
      )
      setDisplayedUsers(foundUsers)
    } else {
      setDisplayedUsers([])
    }
  }
  const selectUser = (e: any) => {
    if (selectedUsers.indexOf(e.target.value) === -1) {
      setSelectedUsers([...selectedUsers, e.target.value])
    } else {
      setSelectedUsers(selectedUsers.filter((x) => x !== e.target.value))
    }
  }

  const sharePlan = () => {
    if (props.users && props.showModal.id) {
      const userIds = selectedUsers.map(
        (user) => props.users?.find((x) => x.username === user)?.id || 0
      )
      props.onSharePlan(props.showModal.id, userIds)
      props.onShowSharePlanModal(props.showModal.id)
    }
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 h-3/4 my-6 mx-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h1 className="text-gray-500 my-6 text-xl w-3/4">SHARE PLAN</h1>
              <button
                className="p-1 ml-auto border-0 text-gray-500 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.onShowSharePlanModal(props.showModal.id!)}
              >
                <span className="text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                  ×
                </span>
            </button>
            </div>
            <input
              className={'w-1/3 m-6 input-focus'}
              type="text"
              placeholder={'Search for users...'}
              onChange={handleSearchChange}
            />
            <div className="relative p-6 flex-auto overflow-y-auto">
              <div
                className={`grid grid-flow-rows grid-cols-2 gap-4 p-4 max-h-96 overflow-auto justify-center`}
              >
                {displayUsers &&
                  displayUsers.map((user, ind) => {
                    // Accommodate longer names
                    const displayUser =
                      user.length <= MAX_USERNAME_DISPLAY_LENGTH
                        ? user
                        : `${user.slice(0, 22)}...`

                    return (
                      <div key={ind} className="check-box">
                        <label className="">
                          <input
                            type="checkbox"
                            onChange={selectUser}
                            checked={selectedUsers.indexOf(user) !== -1}
                            value={user}
                          />
                          <div className="option">{displayUser}</div>
                        </label>
                      </div>
                    )
                  })}
              </div>
              <div
                className={`grid grid-flow-rows grid-cols-2 gap-4 p-4 max-h-96 overflow-auto justify-center`}
              >
                {selectedUsers &&
                  displayUsers.length === 0 &&
                  selectedUsers.map((user, ind) => {
                    return (
                      <div key={ind} className="check-box">
                        <label className="">
                          <input
                            type="checkbox"
                            onChange={selectUser}
                            checked={selectedUsers.indexOf(user) !== -1}
                            value={user}
                          />
                          <div className="option">{user}</div>
                        </label>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
                onClick={() => props.onShowSharePlanModal(props.showModal.id!)}
              >
                Close
              </button>
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 ml-5 mt-4"
                onClick={sharePlan}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default connector(SharePlansModal)
