import React from "react";
import { Rectangle, Layer } from "recharts";
import { CHART_BAR_COLOR } from "../../../utils/colours";

export default function Node({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth
}: any) {
  const isOut = x + width + 6 > containerWidth;
  const SANKY_COLORS = CHART_BAR_COLOR['fill'];
  const fill = SANKY_COLORS[payload.index % SANKY_COLORS.length];

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        className={fill}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
      >
        {payload.name}
      </text>
    </Layer>
  );
}
