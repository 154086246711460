import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string
}

type Props = OwnProps

const TVIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M6.391 51.727c0 2.818 2.696 5.637 14.826 5.637h8.087c12.13 0 14.826-2.819 14.826-5.637V29.182c0-2.818-2.695-5.637-14.826-5.637h-8.087c-12.13 0-14.826 2.819-14.826 5.637v22.545z" />
          <path
            strokeLinecap="round"
            d="M41.435 17.91V16.5c-1.984-4.174-6.022-7.045-10.783-7.045-4.766 0-8.798 2.871-10.782 7.045v1.41M35.055 10.076L44.13 1M24.285 10.807L14.478 1"
          />
          <path d="M63 54.55c0 4.648-3.64 8.45-8.087 8.45H9.087C4.639 63 1 59.198 1 54.55V26.359c0-4.647 3.64-8.449 8.087-8.449h45.826c4.448 0 8.087 3.802 8.087 8.45V54.55z" />
          <path d="M57.609 30.59c0 2.337-1.81 4.228-4.044 4.228-2.234 0-4.043-1.89-4.043-4.227 0-2.336 1.809-4.227 4.043-4.227 2.235 0 4.044 1.89 4.044 4.227zM57.609 53.136c0 2.337-1.81 4.228-4.044 4.228-2.234 0-4.043-1.891-4.043-4.228 0-2.336 1.809-4.227 4.043-4.227 2.235 0 4.044 1.891 4.044 4.227z" />
        </g>
      </g>
    </svg>
  )
}

export default TVIcon
