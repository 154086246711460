import React, { FC } from 'react'
import {
  KPI,
  Phase,
  ProductiveHighlight,
} from '../../../../types/MarketingDiagnosticsTypes'
import SearchIcon from '../../../../icons/media/SearchIcon'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../../Routes'
import HighlightCard from './HighlightCard'

interface OwnProps {
  highlight: ProductiveHighlight
  phase: Phase
  kpi: KPI
  color: string
}

type Props = OwnProps

const MostProductiveChannel: FC<Props> = (props) => {
  return (
    <HighlightCard
      kpi={props.kpi}
      brand={props.highlight.brand}
      channel={props.highlight.channel}
      phase={props.phase}
      title={'MOST PRODUCTIVE CHANNEL'}
      color={props.color}
    >
      <div className="text-gray-400 text-xs p-4 flex flex-1">
        {`${props.highlight.channel} has the highest
                                        productivity score 
                                         ${props.highlight.flex_value} was the
                                        most effective ${props.highlight.flex_name}
                                        for phase
                                        ${props.phase.phase}.`}
      </div>
    </HighlightCard>
  )
}

export default MostProductiveChannel
