import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import appendUnit from '../../../../../utils/appendUnit'

type TChart = {
  name: string
  data: number[]
  dates: string[]
  showLoading: boolean
  dateFormat: string
}
export const Chart = ({
  name,
  data,
  dates,
  showLoading,
  dateFormat,
}: TChart) => {
  if (showLoading) {
    return <div className="h-36 w-full rounded-md animate-pulse bg-gray-200" />
  }

  if (data.length === 0 || dates.length === 0) {
    return (
      <div className="h-full w-full text-center text-gtDarkGray opacity-50">
        Nothing to display
      </div>
    )
  }

  let curveData: any = []
  for (let d in data) {
    curveData = [
      ...curveData,
      {
        name: name,
        total: data[d],
        date: dates[d],
      },
    ]
  }
  return (
    <div className="max-h-36 max-w-36">
      <ResponsiveContainer width="100%" height="100%" aspect={3}>
        <LineChart data={curveData} width={500} height={500} className="text-gtPink">
          <XAxis hide dataKey={'date'} />
          <YAxis
            dataKey={'total'}
            axisLine={false}
            tickLine={false}
            interval="preserveStartEnd"
            tickFormatter={(tickItem: number) => {
              return tickItem === 0 ? '0' : appendUnit(tickItem, '', '', '')
            }}
          />
          <Tooltip
            labelFormatter={(t) => moment(t).format(dateFormat)}
            formatter={(x: any) => appendUnit(x, '', '', '')}
          />
          <Line
            stroke='currentColor'
            name={name}
            dataKey={'total'}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
