import React, { FC, useState } from 'react'
import { Brand } from '../../../types/MarketingDiagnosticsTypes'

interface OwnProps {
  brands: Brand[]
  setShowModal: (modal: boolean) => void
  selectedBrands: string[]
  setSelectedBrands: (brands: string[]) => void
}

type Props = OwnProps

const SelectBrandsModal: FC<Props> = (props) => {
  const [newSelectedBrands, setNewSelectedBrands] = useState<string[]>([
    ...props.selectedBrands,
  ])
  const onBrandClick = (brand: string) => {
    const newSelectedBrandsCopy = [...newSelectedBrands]

    const index = newSelectedBrandsCopy.indexOf(brand)
    if (index > -1) {
      newSelectedBrandsCopy.splice(index, 1)
    } else {
      newSelectedBrandsCopy.push(brand)
    }

    setNewSelectedBrands(newSelectedBrandsCopy)
  }

  return (
    <div className="justify-center bg-gray-900 bg-opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-2 items-center border-b border-solid bg-gray-100 border-blueGray-200 rounded-t">
            <h3 className="text-gray-500 text-xs font-semibold">
              CHOOSE BRANDS
            </h3>
            <button
              onClick={(_) => props.setShowModal(false)}
              className=" bg-transparent border-0 text-black text-xl pb-1  font-semibold outline-none focus:outline-none"
            >
              <span className="bg-transparent text-black h-6 w-6 text-lg block mb-1 outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {/*body*/}
          <div className="grid grid-flow-col grid-cols-3 grid-rows-3 gap-4 p-4 ">
            {props.brands.map((brand, index) => (
              <div className="border rounded px-3 py-2 " key={index}>
                <button
                  onClick={(_) => onBrandClick(brand.brand)}
                  className="w-full flex flex-row space-x-2 items-center"
                >
                  <span
                    className={`${
                      newSelectedBrands.includes(brand.brand)
                        ? 'bg-gtPink '
                        : ''
                    } w-5 h-5 rounded border-2`}
                  ></span>
                  <span className="text-xxs text-gray-500">{brand.brand}</span>
                </button>
              </div>
            ))}
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              onClick={(_) => props.setShowModal(false)}
              className="  bg-gray-300 background-transparent font-bold uppercase px-6 py-3 rounded
              text-xxs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                props.setSelectedBrands(newSelectedBrands)
                props.setShowModal(false)
              }}
              className="bg-gtPink text-white active:bg-emerald-600 font-bold uppercase text-xxs px-6 py-3
              rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectBrandsModal
