import { Breadcrumbs } from '@gain-theory/breadcrumbs'
import React, { FC, useState, useEffect } from 'react'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'

type ViewLinkType = {
  brand: string
  view: string
}

type ViewType = {
  key: string
  label: string
}

export interface TableauConfig {
  baseUrl: string
  auth: string
  client: string
  site: string
  tabChannel: string
  loadOrderID: string
  showVizHome: string
  refresh: string
  toolbar: string
  tabs: string
  filter: string
}

type TableauType = {
  site: string
  root: string
}
interface OwnProps {
  match: any
}
type Props = OwnProps

const UnifiedAdvancedView: FC<Props> = (props) => {
  const [tableau, setTableau] = useState<TableauConfig | undefined>(undefined)

  useEffect(() => {
    const containerElem = document.querySelector('.container')
    if (containerElem) containerElem.classList.remove('max-w-6xl')
    init()
  }, [])

  const init = async () => {
    const config = await getMarketingDiagnostics()

    const view = config.views.find(
      (view: ViewType) => view.label === props.match.params.view
    )
    const view_link = config.view_link.find(
      (link: ViewLinkType) =>
        link.brand === props.match.params.brand && link.view === view.key
    )

    const site = await getTableauSite(view_link)

    setTableau({
      baseUrl: view_link.root,
      auth: `trusted/${site.body}`,
      site: view_link.site,
      client: view_link.brand_url,
      tabChannel: view_link.name,
      loadOrderID: '1',
      filter: view_link.filter,
      showVizHome: 'no',
      refresh: 'yes',
      toolbar: 'no',
      tabs: 'no',
    })
  }

  const getMarketingDiagnostics = async () => {
    const response = await fetch('/crayon/marketing-diagnostics/', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return await response.json()
  }
  const getTableauSite = async (view_link: TableauType) => {
    const response = await fetch('/crayon/tableau/target-site', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        target_site: view_link.site,
        root: view_link.root,
      }),
      method: 'POST',
    })
    return await response.json()
  }

  const tableauUrl = React.useMemo(() => {
    if (tableau) {
      const pageUrl = `${tableau.site}/views/${tableau.tabChannel}`
      const url = `${tableau.baseUrl}${tableau.auth}/t/${pageUrl}?:embed=y&:showVizHome=${tableau.showVizHome}&:refresh=${tableau.refresh}&:toolbar=${tableau.toolbar}&:tabs=${tableau.tabs}&${tableau.filter}`
      // &:tabs=${tableau.tabs}`

      return url
    }
  }, [tableau])

  const ReportingBreadcrumbs = (
    <div className="mb-5">
      <Breadcrumbs
        breadcrumbsConfig={[
          {
            href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
            label: 'Reporting',
            divider: true,
          },
          { label: props.match.params.view },
        ]}
      />
    </div>
  )

  return (
    <>
      {ReportingBreadcrumbs}
      {tableau && (
        <div className="iframe-wrapper">
          <iframe
            title="graph"
            src={tableauUrl}
            frameBorder="0"
            className="tableauViz data-view"
          />
        </div>
      )}
    </>
  )
}

export default MainLayout(UnifiedAdvancedView)
