import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CreatePlanIcon from '../../icons/spo/CreatePlanIcon'
import ExistingPlanIcon from '../../icons/spo/ExistingPlanIcon'
import EditBudgetIcon from '../../icons/spo/EditBudgetIcon'
import SalesGoalIcon from '../../icons/spo/SalesGoalIcon'
import PlanSummaryTable from '../../shared/PlanSummaryTable'
import { IPlanSummary } from '../../types/PlanTypes'
import { Config } from '../../types/ConfigTypes'
import { Settings } from '../../types/SettingTypes'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import { RootStore } from '../../redux/reducers/Store'
import { connect, ConnectedProps } from 'react-redux'
import { selectShowSharePlanModal } from '../../redux/reducers/PlanReducer'
import {
  SharePlanWithUsers,
  ToggleSharePlanModal,
} from '../../redux/actions/PlanActions'
import { selectSessionAllUsers } from '../../redux/reducers/SessionReducer'
import { GetUsers } from '../../redux/actions/SessionActions'
import { IUser } from '../../types'
import { isPropertySignature } from 'typescript'
import SharePlanModal from './SharePlanModal'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
  showModal: selectShowSharePlanModal(state),
})
const mapDispatchToProps = {
  onShowSharePlanModal: ToggleSharePlanModal,
  onGetUsers: GetUsers,
  onSharePlan: SharePlanWithUsers,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {
  inChannel: boolean
  plans: IPlanSummary[]
  settings: Settings
  latestConfig: Config
}

type Props = OwnProps

const PlansLaunchpad: FC<Props> = ({
  inChannel,
  plans,
  settings,
  latestConfig,
  showModal,
  onGetUsers,
}) => {
  useEffect(() => {
    onGetUsers()
  }, [])

  useEffect(() => {
    if (showModal.show) {
      document.body.style.overflow = 'hidden'
    }
    if (!showModal.show) {
      document.body.style.overflow = 'auto'
    }
  }, [showModal.show])

  const createCrossChannelPlanURL = UrlAssembler()
    .template(ROUTES.SPO.CREATE_PLAN)
    .param('id', latestConfig?.id.toString())
    .param('optimisationType', 'budget')
    .toString()
  const createInChannelPlanURL = UrlAssembler()
    .template(ROUTES.SPO.CREATE_IN_CHANNEL_PLAN)
    .param('id', latestConfig?.id.toString())
    .toString()

  const goalSeekURL = UrlAssembler()
    .template(ROUTES.SPO.CREATE_PLAN)
    .param('id', latestConfig?.id.toString())
    .param('optimisationType', 'goal')
    .toString()

  const uploadCrossChannelPlanUrl = UrlAssembler()
    .template(ROUTES.SPO.PLAN_UPLOAD)
    .param('id', latestConfig?.id.toString())
    .toString()

  const uploadInChannelPlanUrl = UrlAssembler()
    .template(ROUTES.SPO.IN_CHANNEL_PLAN_UPLOAD)
    .param('id', latestConfig?.id.toString())
    .toString()

  const editPlanBudgetURL = UrlAssembler()
    .template(ROUTES.SPO.EDIT_BUDGET)
    .toString()

  return (
    <>
      {showModal.show && <SharePlanModal />}
      <div className="text-gray-500 text-md my-6">
        {!inChannel ? 'Cross-Channel Plans' : 'In-Channel SPO Plans'}
      </div>
      <div className="w-full flex gap-5 text-gtPink">
        <Link
          className={'w-1/4'}
          to={!inChannel ? createCrossChannelPlanURL : createInChannelPlanURL}
        >
          <div className="h-40 rounded bg-white border p-4 mx-auto my-auto flex">
            <div className="w-full pt-5">
              <CreatePlanIcon
                className="w-10 h-10 mx-auto"
                inChannel={inChannel}
              />
              <div className="text-xs text-center text-gtPink">
                {!inChannel
                  ? 'Create a new marketing plan'
                  : 'Assign channel budget to partners'}
              </div>
            </div>
          </div>
        </Link>
        <Link
          className={'w-1/4'}
          to={!inChannel ? uploadCrossChannelPlanUrl : uploadInChannelPlanUrl}
        >
          <div className="h-40 rounded bg-white border p-4 flex">
            <div className="w-full pt-5">
              <ExistingPlanIcon
                className="w-10 h-10 mx-auto"
                inChannel={inChannel}
              />
              <div className="text-xs  text-gtPink text-center">
                {!inChannel
                  ? 'Estimate business impact from an existing plan'
                  : 'Estimate business impact from existing partner spends'}
              </div>
            </div>
          </div>
        </Link>
        {!inChannel && (
          <Link className={'w-1/4'} to={editPlanBudgetURL}>
            <div className=" h-40 rounded bg-white border p-4 flex">
              <div className="w-full pt-5">
                <EditBudgetIcon className="w-10 h-10 mx-auto " />
                <div className="text-xs text-gtPink text-center">
                  Change marketing budget
                </div>
              </div>
            </div>
          </Link>
        )}
        {!inChannel && (
          <Link className={'w-1/4'} to={goalSeekURL}>
            <div className="h-40 rounded bg-white border p-4 flex">
              <div className="w-full pt-5">
                <SalesGoalIcon className="w-10 h-10 mx-auto" />
                <div className="text-xs text-gtPink text-center">
                  Achieve a KPI goal
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>

      <div className="text-gray-500 text-md my-6">
        Latest Plans
        <Link
          to={{
            pathname: inChannel
              ? ROUTES.SPO.IN_CHANNEL_SCENARIOS
              : ROUTES.SPO.CROSS_CHANNEL_SCENARIOS,
            state: { planType: inChannel ? 'inChannel' : 'crossChannel' },
          }}
          className="text-gtPink ml-4 text-xs font-primary"
        >
          View all plans
        </Link>
      </div>

      {settings ? (
        <PlanSummaryTable
          addOrCutBudget={false}
          planSummary={plans}
          settings={settings}
          planType={inChannel ? 'inChannel' : 'crossChannel'}
        />
      ) : null}
    </>
  )
}

export default connector(PlansLaunchpad)
