import {
  KpiResponse,
  PerformanceOverview,
} from '../../types/MarketingDiagnosticsTypes'

export const PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT =
  'PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT'
export const PERFORMANCE_OVERVIEW_GET_DATA_SUCCESS =
  'PERFORMANCE_OVERVIEW_GET_DATA_SUCCESS'
export const PERFORMANCE_OVERVIEW_GET_DATA_FAIL =
  'PERFORMANCE_OVERVIEW_GET_DATA_FAIL'
export const PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS =
  'PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS'
export const PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS =
  'PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS'

interface PerformanceOverviewGetDataAttempt {
  type: typeof PERFORMANCE_OVERVIEW_GET_DATA_ATTEMPT
}

interface PerformanceOverviewGetDataSuccess {
  type: typeof PERFORMANCE_OVERVIEW_GET_DATA_SUCCESS
}

interface PerformanceOverviewGetDataFail {
  type: typeof PERFORMANCE_OVERVIEW_GET_DATA_FAIL
}

interface PerformanceOverviewGetKpisSuccess {
  type: typeof PERFORMANCE_OVERVIEW_GET_KPIS_SUCCESS
  payload: KpiResponse
}

interface PerformanceOverviewGetPerformanceSuccess {
  type: typeof PERFORMANCE_OVERVIEW_GET_PERFORMANCE_SUCCESS
  payload: PerformanceOverview
}

export type PerformanceOverviewActionTypes =
  | PerformanceOverviewGetDataAttempt
  | PerformanceOverviewGetDataSuccess
  | PerformanceOverviewGetDataFail
  | PerformanceOverviewGetKpisSuccess
  | PerformanceOverviewGetPerformanceSuccess
