import appendUnit from "../../../../../utils/appendUnit";

type TTooltip = {
  title: string;
  value: number;
  indicator: any; 
  label: string;
  metric: any;
  previousValue: any;
}
export const Tooltip = ({
  title,
  value,
  indicator,
  label,
  metric,
  previousValue,
}: TTooltip) => {
  return (
    <div className="grid gap-1 absolute z-40 w-60 -left-60 -top-10 bg-gray-50 rounded border-gray-300 p-4 shadow-lg">
      <div className="uppercase text-gray-500">{title}</div>
      <div className="grid grid-flow-col gap-5">
        <div className="grid gap-4">
          <div>
            {appendUnit(value, "", "", "")}
          </div>
          {indicator}
        </div>
        <div>
            {`Your ${label} ${title} score ${metric} compared to ${appendUnit(previousValue, "", "", "")} last period.`}
        </div>
      </div>
    </div>
  );
};
