import { useDateRange } from '../../../../components/DatePicker'
import { useEffect, useState } from 'react'
import { Observation } from '../../../../types/PlanTypes'
import { datesAreEqual } from '../../../../utils/dates-are-equal'

interface IUseDateSelectionProps {
  availableDates: Date[]
  observations: Observation[]
  selectedObservations: number[]
  handleResetStartDateError: (date: Date | null) => void
  handleResetEndDateError: (date: Date | null) => void
}

export function useDateSelection({
  availableDates,
  observations,
  selectedObservations,
  handleResetEndDateError,
  handleResetStartDateError,
}: IUseDateSelectionProps) {
  const [startDatePickerProps, endDatePickerProps] = useDateRange({
    onStartDateChangeCallback: handleResetStartDateError,
    onEndDateChangeCallback: handleResetEndDateError,
  })

  const [sliderPosition, setSliderPosition] = useState([
    0,
    observations.length - 1,
  ])

  const handleMoveSlider = (position: number[]) => {
    setSliderPosition(position)
  }

  const handeUpdatePickers = (position: number[]) => {
    const startDate = availableDates[position[0]]
    const endDate = availableDates[position[1]]

    startDatePickerProps.onChange(startDate, undefined)
    endDatePickerProps?.onChange(endDate, undefined)
  }

  useEffect(() => {
    let startDateIndex: number = 0
    let endDateIndex: number = availableDates.length - 1

    if (selectedObservations.length !== 0) {
      startDateIndex = selectedObservations[0]
      endDateIndex = selectedObservations[1]
    }

    const startDate = availableDates[startDateIndex]
    const endDate = availableDates[endDateIndex]

    startDatePickerProps.onChange(startDate, undefined)
    endDatePickerProps?.onChange(endDate, undefined)
  }, [selectedObservations, availableDates])

  useEffect(() => {
    const startDate = startDatePickerProps.selected

    if (!startDate) return

    const startDateIndex = availableDates.findIndex((x) =>
      datesAreEqual(x, startDate)
    )

    if (startDateIndex === -1 || startDateIndex === sliderPosition[0]) {
      return
    }

    setSliderPosition((prev) => [startDateIndex, prev[1]])
  }, [startDatePickerProps.selected])

  useEffect(() => {
    const endDate = endDatePickerProps?.selected

    if (!endDate) return

    const endDateIndex = availableDates.findIndex((x) =>
      datesAreEqual(x, endDate)
    )

    if (endDateIndex === -1 || endDateIndex === sliderPosition[1]) {
      return
    }

    setSliderPosition((prev) => [prev[0], endDateIndex])
  }, [endDatePickerProps?.selected])

  return {
    sliderPosition,
    handleMoveSlider,
    handeUpdatePickers,
    startDatePickerProps,
    endDatePickerProps,
  }
}
