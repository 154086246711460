import { ComponentProps, FC, useState } from 'react'
import { CostName } from '../../../../types/PlanTypes'
import { Modal } from '@gain-theory/modal'
import { Typography } from '@gain-theory/typography'
import { Input } from '@gain-theory/input'

interface OwnProps {
  showCosts: () => void
  scaleCosts: (value: number, media: string) => void
  costData: CostName
  open: boolean
}

type Props = OwnProps

const ShowCostsModal: FC<Props> = (props) => {
  const [value, setValue] = useState('')

  const applyCosts = () => {
    if (parseFloat(value)) {
      props.scaleCosts(parseFloat(value), props.costData.name)
      props.showCosts()
    }
  }

  const modalFooter: ComponentProps<typeof Modal>['footer'] = {
    primary: {
      label: 'Apply',
      onClick: () => applyCosts(),
    },
    secondary: {
      label: 'Cancel',
      onClick: () => props.showCosts(),
    },
  }

  return (
    <Modal
      open={props.open}
      onClose={props.showCosts}
      header={{ text: 'Scale Costs' }}
      footer={modalFooter}
    >
      <Typography.Body1 className="mb-2">{`Scale costs for ${props.costData.label} by percentage`}</Typography.Body1>
      <Typography.Body2 className="mb-16">
        Negative and positive values are allowed.
      </Typography.Body2>
      <div className="flex flex-row w-4/12">
        <Input.Base
          label="Scale value"
          onChange={(e: any) => setValue(e.target.value)}
          value={value}
        />
        <div className="text-lg ml-2 mt-8">%</div>
      </div>
    </Modal>
  )
}

export default ShowCostsModal
