import { useEffect, useState } from 'react'
import {
  Constraint,
  GroupingLevel,
  Identifier,
} from '../../../../../../types/PlanTypes'
import { formatNumberString } from '../../../../../../utils/format-number-string'
import { TableColumnType, TableRowType } from '../../types'
import {
  CONSTRAINT_FN_OPTIONS,
  CONSTRAINT_STATUS_OPTIONS,
  TABLE_COLUMNS,
} from '../../utils/constants'

interface IUseTableDataProps {
  columnIndexing: Record<string, number>
  columns: TableColumnType[]
  constraints: Constraint[]
  groupingLevels: GroupingLevel[]
  numberFormat: string | undefined
}

export const useTableData = ({
  columnIndexing,
  columns,
  constraints,
  groupingLevels,
  numberFormat,
}: IUseTableDataProps) => {
  const [rowData, setRowData] = useState<TableRowType[]>([])

  const levelKeys = groupingLevels.map((level) => level.key)

  const populateRowForDimension = (
    constraint: Constraint,
    columnField: string
  ) => {
    const { identifiers } = constraint
    const identfierObj = identifiers as any as Identifier

    const identifierLevelValue = identfierObj[columnField]

    const levelValues = groupingLevels.find(
      (level) => level.key === columnField
    )?.values

    const selectedLevelValue = levelValues
      ? levelValues.find((value) => value.key === identifierLevelValue)?.label
      : ''

    return selectedLevelValue || ''
  }

  const populateObservationColumns = (
    constraint: Constraint,
    columnIndex: number
  ) => {
    const observationConstraints = constraint.values

    const observationValue = observationConstraints[
      columnIndex - columnIndexing.observations
    ] as any

    if (
      observationValue === undefined ||
      observationValue === null ||
      observationValue === ''
    ) {
      const isIndexType =
        constraint.fn.toLowerCase() ===
        CONSTRAINT_FN_OPTIONS.INDEX.toLowerCase()

      return isIndexType ? '0' : ''
    }

    return formatNumberString({
      value: observationValue.toString(),
      decimalSeparator: numberFormat === 'euro' ? ',' : '.',
      thousandSeparator: numberFormat === 'euro' ? '.' : ',',
    })
  }

  const populateRow = (constraint: Constraint, constraintIndex: number) => {
    const row = columns.reduce((accumulator, column, columnIndex) => {
      const { field } = column

      if (field === TABLE_COLUMNS.ID.field) {
        return { ...accumulator, [field]: (constraintIndex + 1).toString() }
      }

      if (levelKeys.includes(field)) {
        const levelValue = populateRowForDimension(constraint, field)

        return { ...accumulator, [field]: levelValue }
      }

      if (field === TABLE_COLUMNS.TYPE.field) {
        return {
          ...accumulator,
          [field]:
            constraint.fn.charAt(0).toUpperCase() + constraint.fn.slice(1),
        }
      }

      if (field === TABLE_COLUMNS.STATUS.field) {
        const status = constraint.disabled
          ? CONSTRAINT_STATUS_OPTIONS.DISABLED
          : CONSTRAINT_STATUS_OPTIONS.ENABLED

        return { ...accumulator, [field]: status }
      }

      if (field === TABLE_COLUMNS.STATUS.field) {
        return { ...accumulator, [field]: CONSTRAINT_STATUS_OPTIONS.ENABLED }
      }

      const observationValue = populateObservationColumns(
        constraint,
        columnIndex
      )

      return { ...accumulator, [field]: observationValue }
    }, {} as typeof rowData[number])

    // Also storing variables in the row data for the time being
    // For some reason constraint.variable is a string and is typed as number[]
    row.variable = constraint.variable as any as string

    return row
  }

  useEffect(() => {
    const newData = constraints.map(populateRow)
    setRowData(newData)
  }, [constraints, columns, groupingLevels])

  return rowData
}
