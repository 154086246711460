import React, { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import UrlAssembler from 'url-assembler'
import { useHistory } from 'react-router-dom'
import {
    CopyPlan,
    DeletePlan,
    GetPlanItem,
    GetPlanSummaryList,
    LockPlan,
    PutPlan,
    ToggleSharePlanModal,
} from '../redux/actions/PlanActions'
import { selectSettings } from '../redux/reducers/SettingsReducer'
import { selectSessionUser } from '../redux/reducers/SessionReducer'
import { RootStore } from '../redux/reducers/Store'
import { Plan, IPlanSummary, PlanOutput } from '../types/PlanTypes'
import { downloadPlanCSVData } from '../utils/downloadPlanCSVData.js'
import { downloadFlowChart } from '../utils/downloadXlsxFlowChart'
import { ROUTES } from '../Routes'
import { downloadPlanAsImage } from '../utils/downloadPlanAsImage'
import { EditSPOBudget } from '../socket.io'
import { AppState } from '../redux/reducers/RootReducer'
import { COST_NAME, COST_NAME_IN_CHANNEL } from '../utils/constants'

const mapState = (state: RootStore) => ({
    user: selectSessionUser(state),
})
const mapDispatchToProps = {
    onCopyPlan: CopyPlan,
    onLockPlan: LockPlan,
    onDeletePlan: DeletePlan,
    onGetPlanSummaryList: GetPlanSummaryList,
    onShowSharePlanModal: ToggleSharePlanModal,
    onGetPlan: GetPlanItem,
    putPlan: PutPlan,
    editSPOBudget: EditSPOBudget,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface OwnProps extends PropsFromRedux {
    planItem: PlanOutput
    planSummaryList: IPlanSummary[]
    fromPlan: boolean
}

type Props = OwnProps

const PlanItemActionsMenu: FC<Props> = (props) => {
    const [downloadData, setDownloadData] = useState<string[][]>([[]])
    const [doCopyPlan, setDoCopyPlan] = useState<boolean>(false)
    const [doDeletePlan, setDoDeletePlan] = useState<boolean>(false)     

    const history = useHistory()

    const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)

    useEffect(() => {
        if (doCopyPlan) {
            const url = UrlAssembler()
                .template(ROUTES.SPO.PLAN_ITEM)
                .param('id', props.planItem?.id.toString())
                .toString()

            setDoCopyPlan(false)
            history.push(url)
        }
    }, [props.planItem])

    useEffect(() => {
        if (doCopyPlan) {
            props.onGetPlan(props.planSummaryList[0]?.id)
        }
    }, [props.planSummaryList[0]])

    useEffect(() => {
        if (doDeletePlan) {
            const url = UrlAssembler().template(ROUTES.SPO.INDEX).toString()
            setDoDeletePlan(false)
            history.push(url)
        }
    }, [props.planSummaryList])

    const copyPlan = () => {
        if(props.fromPlan){
            props.onCopyPlan(props.planItem.id.toString()).then(() => {
                const url = UrlAssembler().template(ROUTES.SPO.INDEX).toString()
                history.push(url)
            })
        } else {
            props
            .onCopyPlan(props.planItem.id.toString())
            .then(props.onGetPlanSummaryList)
        setDoCopyPlan(true)
        }
        
    }

    const reoptimisePlan = () => {
        if(props.planItem && props.user){
            props.editSPOBudget(props.planItem, props.user)
        }
    }

    const comparePlan = () => {
        const url = UrlAssembler()
            .template(ROUTES.SPO.COMPARISON_CREATE_FLOW)
            .param('baseId', props.planItem.id.toString())
            .toString()
        history.push(url)
    }

    const lockPlan = () => {
        if (
            window.confirm(
                'Locking a plan is permanent and cannot be undone. Are you sure you want to prevent further changes to this scenario?',
            )
        ) {
            props.onLockPlan(props.planItem.id.toString()).then(() => {
                props.onGetPlanSummaryList()
                props.onGetPlan(props.planItem.id.toString())
            })
        }
    }
    const deletePlan = () => {
        if(props.fromPlan){
            props.onDeletePlan(props.planItem.id.toString()).then(() => {
                const url = UrlAssembler().template(ROUTES.SPO.INDEX).toString()
                history.push(url)
            })
        } else {
            props
            .onDeletePlan(props.planItem.id.toString())
            .then(props.onGetPlanSummaryList)
        setDoDeletePlan(true)
        }
    }

    const sharePlan = () => {
        props.onShowSharePlanModal(props.planItem.id.toString())
    }

    const downloadPlan = async () => {
        if(props.planItem){
            //pineapple
            const headerNames = Object.keys(props.planItem.grouping_levels)
        setDownloadData(downloadPlanCSVData(props.planItem, headerNames, settings, inChannelSettings))
        }
        
    }

    const downloadChart = () => {
        downloadFlowChart(props.planItem, settings?.date_format)
    }

    const downloadAsImage = () => {
        downloadPlanAsImage(props.planItem)
    }

    const revertToOptimal = () => {
        if (props.planItem && props.user) {
            let putData = {
                ...props.planItem,
                transformations: props.planItem.transformations.map(
                    (transformation) => ({
                        ...transformation,
                        inputs: [],
                    }),
                ),
            }

            //turn carryover and halos back on
            putData.carryover = true
            putData.parentChild = true

            //revert the CPMs back to the original config CPMs
            if (
                putData.user_cpms &&
                putData.user_cpms.values &&
                putData.user_cpms.values.length > 0
            ) {
                alert("Please note that all costs and other changes will be reset to their original values")

                //get the scenario transforms from the config as they have the input data (and the CPMs)
                const scenarioTransforms = putData.config.transformations.filter(
                    (x) => {
                        for (let tran of putData.transformations) {
                            if (_.isEqual(tran.identifiers, x.identifiers)) {
                                return x
                            }
                        }
                    },
                )

                const costs = props.planItem.in_channel ? COST_NAME_IN_CHANNEL : COST_NAME
                //gets the original CPMs from the config and calculates the average of each result

                const originalCPMs = props.planItem.in_channel ? scenarioTransforms?.map(
                    x => {
                       const currentCosts: any = x.io
                            .find((y) => y.key === costs)
                            ?.values?.slice(
                            putData.observations_min,
                            putData.observations_max + 1,
                        )
                            ?.reduce((acc, val) => acc + val, 0) ||
                        0 

                        const timeLength = (putData.observations_max + 1 - putData.observations_min)
                        
                        const divided = currentCosts / timeLength
                        return divided
                    }

                ) : scenarioTransforms?.map(
                    (x) =>
                        x.io
                            .find((y) => y.key === costs)
                            ?.values?.slice(
                            putData.observations_min,
                            putData.observations_max + 1,
                        )
                )
                putData.user_cpms.values = originalCPMs
            }
            const currencyIndex = props.planItem.options.exchangeRates.currencies.indexOf(props.planItem.options.exchangeRates.defaultCurrency)
            if(currencyIndex !== 0){
                putData.user_input.defaultCurrency = {
                rate: props.planItem.options.exchangeRates.rates[currencyIndex],
                currency: props.planItem.options.exchangeRates.defaultCurrency,
                symbol: props.planItem.options.exchangeRates.defaultSymbol,
              }
            }
            // props.putPlan(putData)
            props.editSPOBudget(putData, props.user)
        }
    }

    const isPlanLocked = !!props.planItem.locked_at

    // Declare CSS class vars
    const baseClass = 'rounded-sm px-3 py-1 hover:bg-gray-100'
    const disabledClass = 'opacity-75 cursor-not-allowed'
    const actionItemClass = isPlanLocked
        ? `${baseClass} ${disabledClass}`
        : baseClass
    const actionItemButtonClass = isPlanLocked
        ? disabledClass
        : 'w-full text-left'


    return (
        <div className="group inline-block ">
            <button
                className="outline-none focus:outline-none border px-3 py-1 bg-white rounded-sm flex items-center min-w-32">
        <span className="pr-1 font-semibold text-gray-400 flex-1">
          Actions
        </span>
                <span>
          <svg
              className="fill-current text-gtPink h-4 w-4 transform group-hover:-rotate-180
        transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </span>
            </button>
            <ul
                className="bg-white border text-xxs text-gray-500 rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
  transition duration-150 ease-in-out origin-top min-w-32"
            >
            {props.fromPlan && (
                    <li className={actionItemClass}>
                        <button
                            disabled={isPlanLocked}
                            onClick={reoptimisePlan}
                            className={actionItemButtonClass}
                        >
                            Reoptimise Plan
                        </button>
                    </li>
                )} 
                <li className={`${actionItemClass} text-xxs`}>
                    <button
                        disabled={isPlanLocked}
                        onClick={copyPlan}
                        className={actionItemButtonClass}
                    >
                        Copy
                    </button>
                </li>
                <li className={actionItemClass}>
                    <button
                        disabled={isPlanLocked}
                        onClick={lockPlan}
                        className={actionItemButtonClass}
                    >
                        {isPlanLocked ? 'Locked' : 'Lock'}
                    </button>
                </li>
                <li className={`${actionItemClass} text-xxs`}>
                    <button
                        disabled={isPlanLocked}
                        onClick={comparePlan}
                        className={actionItemButtonClass}
                    >
                        Compare
                    </button>
                </li>
                <li className={`${baseClass} dropdown relative`}>

                    {' '}
                    <button>Downloads</button>
                    <ul className="dropdown-content min-w-32 absolute bg-white hidden text-gray-500 border rounded -left-3 ml-1 top-0">
                        <li className={`${baseClass} dropdown relative`}>
                            <CSVLink
                                data={downloadData}
                                filename={
                                    props.planItem?.name
                                      ? props.planItem.name + '.csv'
                                      : `Plan${props.planItem.id}.csv`
                                  }
                                onClick={downloadPlan}
                            >
                                <button
                                >
                                    Data
                                </button>
                            </CSVLink>
                        </li>
                        <li className={`${baseClass} dropdown relative`}>
                            <button onClick={downloadChart}>Flowchart</button>
                        </li>
                        <li className={`${baseClass} dropdown relative`}>
                            <button
                                onClick={downloadAsImage}
                            >
                                Image
                            </button>
                        </li>
                    </ul>
                </li>
                <li className={actionItemClass}>
                    <button
                        disabled={isPlanLocked}
                        onClick={deletePlan}
                        className={actionItemButtonClass}
                    >
                        Delete
                    </button>
                </li>                
                <li className={actionItemClass}>
                  <button
                    disabled={isPlanLocked}
                    onClick={sharePlan}
                    className={actionItemButtonClass}
                  >
                    Share
                  </button>
                </li>      
                {props.fromPlan && (
                    <li className={actionItemClass}>
                        <button
                            disabled={isPlanLocked}
                            onClick={revertToOptimal}
                            className={actionItemButtonClass}
                        >
                            Revert to Optimal
                        </button>
                    </li>
                )}          
            </ul>
        </div>
    )
}

export default connector(PlanItemActionsMenu)
