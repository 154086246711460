import React, { FC } from 'react'
import {
  HighlightSpend,
  KPI,
  Phase,
} from '../../../../types/MarketingDiagnosticsTypes'
import HighlightCard from './HighlightCard'

interface OwnProps {
  highlight: HighlightSpend
  phase: Phase
  kpi: KPI
  color: string
}

type Props = OwnProps

const TopKpiCard: FC<Props> = (props) => {
  return (
    <HighlightCard
      kpi={props.kpi}
      brand={props.highlight.brand}
      channel={props.highlight.channel}
      phase={props.phase}
      title={'TOP KPI CONTRIBUTOR'}
      color={props.color}
    >
      <div className="text-gray-400 text-xs p-4 flex flex-1">
        {`${props.highlight.channel}  contributed the most to ${props.kpi.label} for ${props.phase.phase}.`}
      </div>
    </HighlightCard>
  )
}

export default TopKpiCard
