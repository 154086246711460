import { Dispatch } from 'redux'
import {
  SettingActionTypes,
  SETTINGS_GET_ATTEMPT,
  SETTINGS_GET_FAIL,
  SETTINGS_GET_OKTA_SETTINGS_ATTEMPT,
  SETTINGS_GET_OKTA_SETTINGS_FAIL,
  SETTINGS_GET_OKTA_SETTINGS_SUCCESS,
  SETTINGS_GET_SUCCESS,
  SETTINGS_SELECT_OKTA_SETTING,
  SETTINGS_UPDATE_ATTEMPT,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_SUCCESS,
} from './SettingActionTypes'
import api from '../../api'
import { OktaSetting, SettingsAttrs } from '../../types/SettingTypes'

export const GetSettings =
  () => async (dispatch: Dispatch<SettingActionTypes>) => {
    dispatch({ type: SETTINGS_GET_ATTEMPT })

    try {
      const settings = await api.Settings.getSettings()
      const inChannelSettings = await api.Settings.getInChannelSettings()
      dispatch({
        type: SETTINGS_GET_SUCCESS,
        settings: settings.data,
        inChannelSettings:
          inChannelSettings.data.length > 0
            ? inChannelSettings.data[0].settings
            : [],
      })
    } catch (error) {
      dispatch({ type: SETTINGS_GET_FAIL })
    }
  }

export const UpdateSettings =
  (attrs: SettingsAttrs) => async (dispatch: Dispatch<SettingActionTypes>) => {
    dispatch({ type: SETTINGS_UPDATE_ATTEMPT })

    try {
      const res = await api.Settings.updateSettings(attrs)
      dispatch({ type: SETTINGS_UPDATE_SUCCESS, payload: res.data })
    } catch (error) {
      dispatch({ type: SETTINGS_UPDATE_FAIL })
    }
  }

export const GetOktaSettings =
  () => async (dispatch: Dispatch<SettingActionTypes>) => {
    dispatch({ type: SETTINGS_GET_OKTA_SETTINGS_ATTEMPT })

    try {
      const res = await api.Settings.getOktaSettings()
      dispatch({ type: SETTINGS_GET_OKTA_SETTINGS_SUCCESS, payload: res.data })
    } catch (error) {
      dispatch({ type: SETTINGS_GET_OKTA_SETTINGS_FAIL })
    }
  }

export const SelectOktaSetting =
  (setting: OktaSetting) => (dispatch: Dispatch<SettingActionTypes>) => {
    dispatch({ type: SETTINGS_SELECT_OKTA_SETTING, payload: setting })
  }
