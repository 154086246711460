import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { UpdateUser } from '../../redux/actions/SessionActions'
import { RootStore } from '../../redux/reducers/Store'
import { CreateUserAttrs } from '../../types/AdminTypes'
import CreateUser from './CreateUser'

const mapState = (state: RootStore) => ({})
const mapDispatchToProps = {
  updateUser: UpdateUser,
}

interface MatchParams {
  id: string
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManageOneUser: FC<PropsFromRedux & RouteComponentProps<MatchParams>> = (
  props
) => {
  const editUser = (attrs: any) => {
    props.updateUser(attrs, props.match.params.id)
  }
  return (
    <CreateUser userId={props.match.params.id} submit={editUser}></CreateUser>
  )
}

export default connector(ManageOneUser)
