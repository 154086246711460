import React, { Component } from 'react'

import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps'

let mapData = require('./world-50m-simplified')

const mapFill = {
  hover: 'hover:fill-brand-primary-dark',
  main: 'fill-brand-primary-main',
  container: 'fill-brand-primary-container',
  light: 'fill-brand-primary-light',
}

const wrapperStyles = {
  fontFamily: 'Roboto, sans-serif',
  width: '100%',
  maxWidth: 980,
  margin: '0 auto',
}
class WorldMap extends Component {
  constructor() {
    super()
    this.geos = []
    this.state = {
      selected: [],
      geos: [],
    }
  }

  componentWillMount = () => {
    let selected = []
    if (this.props.filterableRegions.length === 1) {
      selected.push(this.props.filterableRegions[0])
    }
    this.setState({
      selected: selected,
    })
  }

  handleClick = (geo, e) => {
    e.stopPropagation()
    const regionKey = geo.properties.ISO_A2.toLowerCase()
    const canSelect = this.props.filterableRegions.indexOf(regionKey) !== -1

    if (!canSelect) {
      this.clearFilters()
      return
    }

    if (this.state.selected[0] === regionKey) {
      this.clearFilters()
      return
    }
    this.setState({ selected: [regionKey] })
    // this.props.selectRegions([regionKey]);
    this.props.handleRegionSelect(regionKey)
  }

  handleMapClick = () => {
    this.clearFilters()
  }

  clearFilters = () => {
    this.setState({ selected: [] })
    this.props.handleRegionSelect('all')
  }

  getFillColorForGeo = (geo, isSelected) => {
    const regionKey = geo.properties.ISO_A2.toLowerCase()
    const inScenario = this.props.filterableRegions.indexOf(regionKey) !== -1

    if (isSelected) return mapFill.main
    if (inScenario) {
      // this.buildMarker(geo);

      let found = this.geos.find(
        (element) => element.name === geo.properties.NAME
      )

      if (!found) {
        let marker = this.buildMarker(geo)
        this.geos.push(marker)
      }

      return mapFill.light
    }
    return mapFill.container
  }

  buildMarker = (geo) => {
    return {
      markerOffset: 0,
      name: geo.properties.NAME,
      coordinates: this.getCoordinates(geo.geometry.coordinates),
    }
  }

  getCoordinates = (arr) => {
    if (Array.isArray(arr[0])) {
      return this.getCoordinates(arr[0])
    } else {
      return arr
    }
  }

  componentDidMount = () => {
    this.setState({ geos: this.geos })
  }

  render() {
    return (
      <div style={wrapperStyles} onClick={this.handleMapClick}>
        <ComposableMap
          onClick={this.handleMapClick}
          projectionConfig={{ scale: 205 }}
          width={980}
          height={551}
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <ZoomableGroup onClick={this.handleMapClick}>
            <Geographies geography={mapData} disableOptimization>
              {(geos, proj) =>
                geos.map((geo, i) => {
                  const isSelected =
                    geo.properties.ISO_A2.toLowerCase() === this.props.selected

                  const canHover =
                    this.props.filterableRegions.indexOf(
                      geo.properties.ISO_A2.toLowerCase()
                    ) !== -1
                  if (canHover) {
                    return (
                      <Geography
                        data-testid="region-allocation"
                        key={geo.properties.ISO_A3 + i}
                        cacheId={geo.properties.ISO_A3 + i}
                        geography={geo}
                        projection={proj}
                        onClick={this.handleClick}
                        className={`outline-none stroke-white cursor-pointer ${
                          mapFill.hover
                        } ${this.getFillColorForGeo(geo, isSelected)}`}
                      />
                    )
                  }
                  return (
                    <Geography
                      key={geo.properties.ISO_A3 + i}
                      cacheId={geo.properties.ISO_A3 + i}
                      geography={geo}
                      projection={proj}
                      onClick={this.handleClick}
                      className={`outline-none stroke-white fill-neutral-gray-500 ${this.getFillColorForGeo(
                        geo
                      )}`}
                    />
                  )
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    )
  }
}

export default WorldMap
