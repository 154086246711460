import { format as d3format, formatPrefix } from 'd3-format'
import { formatDefaultLocale as d3formatDefaultLocale } from 'd3-format'

export default function appendUnit(
  quantity: number,
  unit: string,
  symbol: string,
  format: string
) {
  if (symbol && symbol !== '') {
    d3formatDefaultLocale({
      decimal: '.',
      thousands: ',',
      grouping: [3],
      currency: [symbol, ''],
    })
  }

  if (format && symbol && symbol !== '') {
    d3formatDefaultLocale({
      decimal: format === 'euro' ? ',' : '.',
      thousands: format === 'euro' ? '.' : ',',
      grouping: [3],
      currency: [symbol, ''],
    })
  }

  if (!isFinite(quantity)) {
    return 'N/A'
  }

  if (unit === 'currency' || unit === 'currency_div') {
    if (-1 < quantity && quantity < 1) {
      // avoid d3-format using £0.10m
      return d3format('$.2f')(quantity)
    }
    return d3format('$.3s')(quantity).replace(/G/, 'B')
  }

  if (unit === 'difference') {
    if (quantity > 1000 || quantity < -1000) {
      return d3format('$.3s')(quantity)
    }
    return d3format('$.3s')(0)
  }

  if (unit === 'percent') {
    return Math.round(quantity) + '%'
  }

  if (-1 < quantity && quantity < 1) {
    // avoid d3-format using £0.10m (millipounds!)
    return `${d3format('.2f')(quantity).replace(/G/, 'B')}${
      unit ? ` ${unit}` : ''
    }`
  }
  return `${d3format('.3s')(quantity).replace(/G/, 'B')}${
    unit ? ` ${unit}` : ''
  }`
}
