import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'
import AdminHeader from './AdminHeader'
import { Button } from '@gain-theory/button'
import { useReleaseVersion } from '../../hooks/use-release-version'

interface OwnProps {}

type Props = OwnProps

const Admin: FC<Props> = (props) => {
  const { Component, toggleShow } = useReleaseVersion()

  return (
    <>
      <div>
        <div>
          <AdminHeader />
        </div>
        <div className={'flex flex-col mt-4'}>
          <Link className={'text-gtPink'} to={ROUTES.ADMIN.SETTINGS}>
            Manage Settings
          </Link>
          <Link className={'text-gtPink'} to={ROUTES.ADMIN.MANAGE_USERS}>
            Manage Users
          </Link>
          <Link className={'text-gtPink'} to={ROUTES.ADMIN.MANAGE_CONFIGS}>
            Manage SPO Configs
          </Link>
          <Link className={'text-gtPink'} to={ROUTES.ADMIN.MANAGE_GROUPS}>
            Manage Groups
          </Link>
          <div>
            <Button onClick={toggleShow} variant="linkPrimary">
              Version
            </Button>
            {Component}
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLayout(Admin)
