import React, { FC } from 'react'
import { uuid } from 'uuidv4'
import { ReactComponent as GenericProductIcon } from '../../../../../src/icons/svg/icon-generic-product-m.svg'
import appendUnit from '../../../../utils/appendUnit'

interface OwnProps {
  isUploadedPlan: boolean
  isSelected: boolean
  calculatedKpi: string
  optimisedKpi: string
  level: { key: string; label: string }
  handleSelectLevel: (key: string) => void
  selectedOutput: string
  spend: string
}

type Props = OwnProps

const GroupingLevelAllocationItem: FC<Props> = (props) => {
  return (
    <button
      key={uuid()}
      onClick={(_) => props.handleSelectLevel(props.level.key)}
      className={`${
        props.isSelected ? ' bg-white border-gtPink ' : 'border-gray-100'
      } group rounded  w-44 border   hover:border-gtPink  hover:shadow-lg hover:bg-white  ring-0`}
    >
      <div className="my-4 flex flex-col  ">
        <div className="flex flex-col content-center items-center justify-center">
          <div className="mx-auto border rounded-full w-16 h-16 flex">
            <GenericProductIcon className="w-14 h-14 bg-gray-300 mx-auto my-auto rounded-full" />
          </div>
          <div className=" text-xs font-light   "> {props.level.label}</div>
          <div className="text-xxs text-gray-400 mx-auto uppercase">Spend</div>
          <h6 className="text-lg">{props.spend}</h6>
        </div>

        <hr
          className={`${
            props.isSelected ? 'border-gtPink' : ''
          } border-t group-hover:border-gtPink my-5 mx-4`}
        />
        <div className="flex flex-col content-center items-center justify-center">
          <div className="text-xxs text-gray-400 mx-auto uppercase">
            {props.selectedOutput.replace(/_/g, ' ')}
          </div>
          <div
            className={
              !props.isUploadedPlan
                ? 'text-xxs text-gray-400 mx-auto uppercase'
                : 'displayNone'
            }
          >
            {' '}
            Optimal
          </div>

          <div
            className={
              !props.isUploadedPlan ? 'text-lg text-success-main' : 'displayNone'
            }
            data-testid="all-brands-optimised-total"
          >
            {props.optimisedKpi}
          </div>
          <span
            className={
              !props.isUploadedPlan
                ? 'text-xxs text-gray-400 mx-auto uppercase'
                : 'hidden'
            }
          >
            This Plan
          </span>
          <div className="text-lg" data-testid="all-brands-difference-total">
            {props.calculatedKpi}
          </div>
        </div>
      </div>
    </button>
  )
}

export default GroupingLevelAllocationItem
