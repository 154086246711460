import React, { FC, useState, useEffect } from 'react'
import { MainLayout } from '../../../shared/MainLayout'
import { RootStore } from '../../../redux/reducers/Store'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { connect, ConnectedProps } from 'react-redux'
import { selectOptimiserError } from '../../../redux/reducers/CreatePlanReducer'
import { selectPlanSummaryList } from '../../../redux/reducers/PlanReducer'
import PlanSummaryTable from '../../../shared/PlanSummaryTable'
import { GetPlanSummaryList } from '../../../redux/actions/PlanActions'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
  planSummaryList: selectPlanSummaryList(state),
  optimiserError: selectOptimiserError(state),
})

const mapDispatchToProps = {
  onGetPlanSummaryList: GetPlanSummaryList,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const EditPlanBudget: FC<PropsFromRedux> = (props) => {
  useEffect(() => {
    props.onGetPlanSummaryList()
  }, [])
  return (
    <>
      {
        <div className={'container'}>
          <h1 className="text-gray-500 my-6 content-center text-center text-xl m-8">
            Which plan would you like to change?
          </h1>
          {props.settings && (
            <PlanSummaryTable
              addOrCutBudget={true}
              planSummary={props.planSummaryList}
              settings={props.settings}
            />
          )}
        </div>
      }
    </>
  )
}

export default connector(MainLayout(EditPlanBudget))
