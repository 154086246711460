import { Typography } from '@gain-theory/typography'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CreateUserAdmin, GetUsers } from '../../redux/actions/SessionActions'
import { selectSessionAllUsers } from '../../redux/reducers/SessionReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import { CreateUserAttrs } from '../../types/AdminTypes'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
})
const mapDispatchToProps = {
  createUser: CreateUserAdmin,
  onGetUsers: GetUsers,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type PropsFunction = (e: any) => void

interface OwnProps extends PropsFromRedux {
  userId: string
  submit: PropsFunction
}

const CreateUser: FC<OwnProps> = (props) => {
  const [enabled, setEnabled] = useState(true)
  const [locked, setLocked] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [returnMessage, setReturnMessage] = useState('')

  useEffect(() => {
    props.onGetUsers()
    if (props.userId) {
      setEnabled(
        props.users?.find((x) => x.id === parseInt(props.userId))?.enabled ||
          true
      )
      setLocked(
        props.users?.find((x) => x.id === parseInt(props.userId))?.is_locked ||
          false
      )
      setAdmin(
        props.users?.find((x) => x.id === parseInt(props.userId))?.admin ||
          false
      )
      setUsername(
        props.users?.find((x) => x.id === parseInt(props.userId))?.username ||
          ''
      )
    }
  }, [])

  const toggleEnabled = (e: any) => {
    setEnabled(e.target.checked)
  }
  const toggleLock = (e: any) => {
    setLocked(e.target.checked)
  }
  const toggleAdmin = (e: any) => {
    setAdmin(e.target.checked)
  }
  const createUser = () => {
    let attrs = {}
    if (props.userId && password === '') {
      attrs = {
        enabled: enabled,
        is_locked: locked,
        admin: admin,
      }
      props.submit(attrs)
      setReturnMessage('Success!')
    } else {
      const validationMessage = validateUser()
      if (validationMessage === 'Success!') {
        attrs = {
          enabled: enabled,
          is_locked: locked,
          admin: admin,
          username: username,
          password: password,
        }
        if (props.userId) {
          props.submit(attrs)
        } else {
          props.createUser(attrs)
        }
        setReturnMessage(validationMessage)
      } else {
        setReturnMessage(validationMessage)
      }
    }
  }

  const validateUser = () => {
    if (password === '') {
      return 'Your password is blank'
    } else if (password !== passwordConfirm) {
      return 'Your passwords do not match'
    } else if (username.length < 4) {
      return 'Your username must be at least 4 characters'
    } else if (password.length <= 5) {
      return 'New password must be more than 5 characters'
    } else {
      return 'Success!'
    }
  }

  return (
    <div className={'container'}>
      <AdminHeader></AdminHeader>
      <Typography.H4 className="my-6">
        {props.userId ? 'Manage User' : 'Create User'}
      </Typography.H4>
      <div className={'flex flex-col'}>
        <div className={'mt-4'}>Username</div>
        <input
          className={'w-1/4'}
          type="text"
          defaultValue={username}
          onChange={(e) => setUsername(e.target.value.toLowerCase())}
        />
        <div className={'mt-4'}>
          <input type="checkbox" onChange={toggleEnabled} checked={enabled} />
          <span className={'ml-2'}>Enabled</span>
        </div>
        <div className={'mt-4'}>
          <input type="checkbox" onChange={toggleLock} checked={locked} />
          <span className={'ml-2'}>Locked</span>
        </div>
        <div className={'mt-4'}>
          <input type="checkbox" onChange={toggleAdmin} checked={admin} />
          <span className={'ml-2'}>Admin</span>
        </div>
        <div className={'mt-4'}>Password</div>
        <input
          className={'w-1/4'}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className={'mt-4'}>Confirm Password</div>
        <input
          className={'w-1/4'}
          type="password"
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        {returnMessage !== '' && (
          <div
            className={
              returnMessage === 'Success!'
                ? 'mt-4 w-full p-5 bg-gtLightGreen'
                : 'mt-4 text-gtBluePrimary'
            }
          >
            {returnMessage}
          </div>
        )}
      </div>
      <button
        className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
        onClick={createUser}
      >
        SAVE
      </button>
    </div>
  )
}

export default connector(MainLayout(CreateUser))
