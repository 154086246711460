export const enum SectionId {
  WELCOME = 'welcome',
  MODULES = 'modules',
  CAN_WE_HELP = 'can-we-help',
  FAQS = 'faqs',
  HELPFUL_LINKS = 'helpful-links',
  CONTACT_US = 'contact-us',
}

export const enum EServiceAreas {
  ALL = 'all',
  WHAT_HAPPENED = 'what-happened',
  WHY_DID_IT_HAPPEN = 'why-did-it-happen',
  WHAT_NEXT = 'what-next',
}

export const enum EProducts {
  BUSINESS_PERFORMANCE_METRICS = 'business-performance-metrics',
  BUSINESS_DRIVERS_METRICS = 'business-drivers-metrics',
  MARKETING_MEDIA_ACTIVITY = 'marketing-media-activity',
  MEASURED_BUSINESS_DRIVERS = 'measured-business-drivers',
  SCENARIO_PLANNING_OPTIMISATION = 'scenario-planning-optimisation',
  IN_CHANNEL_SPO = 'in-channel-spo',
}

export const enum EModules {
  REPORTING = 'reporting',
  SCENARIO_PLANNING_OPTIMISATION = 'scenario-planning-optimisation',
  IN_CHANNEL_SPO = 'in-channel-spo',
}

export const MAIL_TO_SUBJECT = '[GTi Inquiry]'
