import { Button } from '@gain-theory/button'
import { Chip } from '@gain-theory/chip'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { FC } from 'react'
import { useIsBetaFeature } from '../../../../shared/hooks/use-is-beta-feature'
import { FEATURE_FLAGS } from '../../../../utils/constants'

interface OwnProps {
  planGenerationType: 'create' | 'edit'
  onBackBtnClick: () => void
  onGeneratePlanBtnClick: () => void
}

type Props = OwnProps

const PlanTimePeriodBudgetConstraintsHeader: FC<Props> = ({
  planGenerationType,
  onBackBtnClick,
  onGeneratePlanBtnClick,
}) => {
  const isBeta = useIsBetaFeature({
    flagKey: FEATURE_FLAGS.PROPORTIONAL_FLIGHTING,
  })

  const BetaChip = (
    <div className="w-fit">
      <Chip>Beta</Chip>
    </div>
  )

  const BackButton = (
    <Button
      variant="secondary"
      leadingIcon={<Icon name="ArrowLongLeftIcon" color="brand-primary-main" />}
      onClick={onBackBtnClick}
    >
      Back
    </Button>
  )

  const GeneratePlanButton = (
    <Button
      variant="primary"
      leadingIcon={<Icon name="PlayIcon" color="neutral-white" />}
      onClick={onGeneratePlanBtnClick}
    >
      {planGenerationType === 'create' ? 'Generate Plan' : 'Regenerate Plan'}
    </Button>
  )

  return (
    <div className="flex flex-row w-full gap-xxl items-start justify-between mb-8">
      <div className="flex flex-row items-center gap-2">
        {isBeta && BetaChip}
        <Typography.H4>Time period budget constraints</Typography.H4>
      </div>
      <div className="flex flex-row flex-grow-0 flex-shrink-0 justify-center items-center gap-md">
        {BackButton}
        {GeneratePlanButton}
      </div>
    </div>
  )
}

export default PlanTimePeriodBudgetConstraintsHeader
