import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimelineIcon from '../../../icons/TimelineIcon'
import { AppState } from '../../../redux/reducers/RootReducer'

interface OwnProps {
  onEdit: (section: number) => void
}

type Props = OwnProps

const TimeSummary: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { planItem } = useSelector((state: AppState) => state.plans)
  const { settings } = useSelector((state: AppState) => state.settings)

  const getMinObservation = () => {
    return planItem.config.observations[planItem.observations_min].label
  }

  const getMaxObservation = () => {
    // {moment
    //     .utc(this.props.item["config_observation_min_label"])
    //     .format(this.props.dateFormat)}
    return planItem.config.observations[
      planItem.observations_max - planItem.options.carryoverWeeks
    ].label
  }

  const editNumber = Object.keys(planItem.grouping_levels).length


  return (
    <div className="text-neutral-gray-1100  text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold">TIME</span>
        {!planItem.locked_at && (
          <button
            onClick={(_) => props.onEdit(editNumber)}
            className="text-gtPink text-xxs"
          >
            edit
          </button>
        )}
      </div>
      <div className="w-full flex">
        <div>
          <TimelineIcon />
        </div>
        <div className="flex flex-1 flex-col ml-1 space-y-2">
          <div>{getMinObservation()}</div>
          <div>{getMaxObservation()}</div>
        </div>
      </div>
    </div>
  )
}

export default TimeSummary
