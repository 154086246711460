import React, { Component, FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import appendUnit from '../../../utils/appendUnit'
// import { BudgetSlider } from "../../containers/ScenarioPlanner/NewScene/ScenarioViewer/BudgetSlider";
import EditableAttribute from '../../../utils/EditableAttribute'
import * as _ from 'lodash'
import { CSVLink } from 'react-csv'
import { RootStore } from '../../../redux/reducers/Store'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import {
  ConstraintInput,
  ConstraintModal,
  GroupingLevel,
  PlanOutput,
  Transformation,
} from '../../../types/PlanTypes'
import { CreateCurrency } from '../../../types/CreatePlanTypes'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { Range } from 'rc-slider'
import {
  selectPlanItem,
  selectPlanCreatedComplete,
} from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { EditSPOConstraints } from '../../../socket.io'
import BudgetSlider from './media-channel-allocation/BudgetSlider'
import Slider from 'rc-slider/lib/Slider'
import SelectBrandsModal from '../../md/highlights/SelectBrandsModal'
import { GetPlanItem } from '../../../redux/actions/PlanActions'
import {
  getConstraintsError,
  getConstraintsLoading,
  getConstraintsSuccess,
} from '../../../redux/reducers/ConstraintsReducer'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { AppState } from '../../../redux/reducers/RootReducer'

type setModalFunction = (a: boolean) => void

interface OwnProps {
  name: string
  setModal: setModalFunction
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  plan: selectPlanItem(state),
  error: getConstraintsError(state),
  loading: getConstraintsLoading(state),
  success: getConstraintsSuccess(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  changeRegionSpend: EditSPOConstraints,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ChangeRegionBudgetModal: FC<PropsFromRedux & Props> = (props) => {
  const [error, setError] = useState<string | null>(null)
  const [regionBudgets, setRegionBudgets] = useState<ConstraintModal[]>([])
  const [data, setData] = useState<any[][]>([[]])
  const [file, setFile] = useState([])
  useEffect(() => {
    setRegionBudgets(createBudgets(props.plan))
  }, [])

  const { settings } = useSelector((state: AppState) => state.settings)

  useEffect(() => {
    if (props.success) {
      props.setModal(false)
      props.onGetPlan(props.plan.id)
    }
  }, [props.success])

  useEffect(() => {
    setError(props.error)
  }, [props.error])

  const uploadConstraints = () => {
    if (!file) {
      return
    }
    let constraints = regionBudgets
    for (let i in file) {
      const newConstraintBar = constraints.find((x) => x.label === file[i][0])
      if (newConstraintBar) {
        newConstraintBar.input.constraintType = file[i][1]
        if (file[i][1] !== 'Range') {
          newConstraintBar.input.value = file[i][2]
        } else {
          newConstraintBar.input.rangeValues = [file[i][2], file[i][3]]
        }
      }
    }
    setRegionBudgets([...constraints])
  }

  const onChange = (e: any) => {
    const reader = new FileReader()
    reader.onload = onReaderLoad
    reader.readAsText(e.target.files[0])
  }

  const onReaderLoad = (e: any) => {
    const data = e.target.result.split('\n')
    const check = data
      .map((x: string) => x.split(','))[0]
      .map((y: string) => y.replace(/"/g, ''))

    if (check[1] && check[1] === 'Function') {
      const removeHeader = data
        .slice(1, data.length)
        .map((x: string) => x.split(','))
      const formatArray = removeHeader.map((x: string[]) =>
        x.map((y: string) => y.replace(/"/g, ''))
      )
      const parsed = formatArray.map((x: any[]) => {
        x[2] = isNaN(parseInt(x[2])) ? null : parseInt(x[2])
        x[3] = isNaN(parseInt(x[2])) ? null : parseInt(x[3])
        return x
      })

      const filteredZeroConstraints = parsed.filter((x: any) => {
        if (x[2] !== null || x[1] === 'None') {
          return x
        }
      })
      setFile(filteredZeroConstraints)
    }
  }

  const downLoadCSV = () => {
    if (!props.plan.config) {
      return false
    }
    let headers = [settings?.region_name, 'Function', 'Value']

    let regions =
      props.plan.config.grouping_levels
        .find((x: GroupingLevel) => x.key === 'region')
        ?.values.map((x: GroupingLevel) => x.label) || []

    let data: any = [headers]

    for (let i in regions) {
      data = [...data, [regions[i], 'Equal', null]]
    }

    for (let i in data) {
      for (let r in regionBudgets) {
        if (
          data[i][0] === regionBudgets[r].label &&
          regionBudgets[r].input.constraintType !== 'None'
        ) {
          data[i][1] = regionBudgets[r].input.constraintType
          if (regionBudgets[r].input.constraintType === 'Range') {
            data[i][2] = Math.round(regionBudgets[r].input.rangeValues[0])
            data[i][3] = Math.round(regionBudgets[r].input.rangeValues[1])
          } else {
            data[i][2] = regionBudgets[r].input.value
          }
        }
      }
    }

    setData(data)

    return true
  }

  const closeModal = () => {
    if (error) {
      const defaultCurrency = getSelectedCurrency()
      submitConstraints([], defaultCurrency)
    }
    props.setModal(false)
    setError(null)
  }

  const createBudgets = (plan: PlanOutput) => {
    let result: ConstraintModal[] = []
    if (!plan) {
      return []
    }

    const regions = plan.config.grouping_levels.find((regions) => {
      return regions.key === 'region'
    })

    let filteredRegions = []
    if (props.plan.grouping_levels.region.length !== 0 && regions) {
      filteredRegions = regions.values.filter(
        (level) => props.plan.grouping_levels.region.indexOf(level.key) !== -1
      )

      regions.values = filteredRegions
    }

    regions?.values.forEach((groupingLevel) => {
      let regionBudgetTransform = {
        label: groupingLevel.label,
        budget: getBudgetForRegion(groupingLevel.key),
        color: '#E10D6D',
        input: {
          value: getBudgetForRegion(groupingLevel.key),
          key: groupingLevel.key,
          constraintType: 'None',
          rangeValues: [],
        },
      }
      result.push(regionBudgetTransform)
    })

    //check to see if any current constraints and adjust the types
    for (let region of result) {
      let howManyMatches = 0
      for (let con of plan.constraints) {
        if (
          Object.keys(con.identifiers).length === 1 &&
          Object.keys(con.identifiers)[0] === 'region' &&
          region.input.key === con.identifiers.region
        ) {
          howManyMatches = howManyMatches + 1
          if (howManyMatches === 1) {
            region.input.value = con.values[0]
            region.input.constraintType =
              con.fn.charAt(0).toUpperCase() + con.fn.slice(1)
          }
          //if there are two matches, that means there is a range constraint
          if (howManyMatches === 2) {
            region.input.constraintType = 'Range'
            region.input.rangeValues = [region.input.value, con.values[0]]
          }
        }
      }
    }

    return result
  }

  const getBudgetForRegion = (region: string) => {
    // return 0;
    return props.plan.transformations
      .filter((transformation: any) => {
        return transformation.identifiers.region === region
      })
      .reduce((prev: any, next: any) => {
        return (prev += next.calculated_spend)
      }, 0)
  }

  const handleChangeConstraintType = (transform: ConstraintModal, e: any) => {
    const stateBudgets = _.cloneDeep(regionBudgets)
    stateBudgets.map((reg) => {
      if (reg.label === transform.label) {
        reg.input.constraintType = e.target.value
      }
      return reg
    })


    // if the constraint type was range, need to change value back from an array
    for (let bud of stateBudgets) {
      if (
        bud.input.constraintType !== 'Range' &&
        Array.isArray(bud.input.value)
      ) {
        bud.input.value = bud.input.value[1]
      }
      if (bud.input.constraintType === 'Range' && bud.label === transform.label) {
        bud.input.rangeValues = [0, bud.input.value]
      }
    }

    setRegionBudgets(stateBudgets)
  }

  const submit = () => {
    const defaultCurrency = getSelectedCurrency()

    const submitBudgets = regionBudgets.map((region) => region.input)

    submitConstraints(submitBudgets, defaultCurrency)
  }

  const getSelectedCurrency = () => {
    const index = props.plan.options.exchangeRates.currencySymbols.indexOf(
      props.plan.options.exchangeRates.defaultSymbol
    )

    const overallConstraintCurrencyDivisor =
      props.plan.options.exchangeRates.rates[index]

    const defaultCurrency = props.plan.options.exchangeRates.currencies[index]

    const defaultCurrencySymbol =
      props.plan.options.exchangeRates.currencySymbols[index]

    const selectedCurrency = {
      rate: overallConstraintCurrencyDivisor,
      currency: defaultCurrency,
      symbol: defaultCurrencySymbol,
    }

    return selectedCurrency
  }

  const submitConstraints = (
    regions: ConstraintInput[],
    currency: CreateCurrency
  ) => {
    //create new constraints in an array
    let newRegionConstraints = []
    for (let i in regions) {
      if (
        regions[i].constraintType !== 'None' &&
        regions[i].constraintType !== 'Range'
      ) {
        let valueArray = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray[0] = regions[i].value
        const constraint = {
          identifiers: { region: regions[i].key },
          values: valueArray,
          variable: 'spend',
          fn: regions[i].constraintType.toLowerCase(),
        }
        newRegionConstraints.push(constraint)
      }

      //if it's a range, push a min and a max constraint
      if (regions[i].constraintType === 'Range') {
        let valueArray = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray[0] = regions[i].rangeValues[0]
        const constraint = {
          identifiers: { region: regions[i].key },
          values: valueArray,
          variable: 'spend',
          fn: 'min',
        }
        newRegionConstraints.push(constraint)

        let valueArray2 = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray2[0] = regions[i].rangeValues[1]
        const constraintMax = {
          identifiers: { region: regions[i].key },
          values: valueArray2,
          variable: 'spend',
          fn: 'max',
        }
        newRegionConstraints.push(constraintMax)
      }
    }

    const putData = {
      id: props.plan.id,
      scenario: {
        ...props.plan,
      },
    }

    //wipe all region constraints- have to decrement otherwise splice affects the order
    for (let i = putData.scenario.constraints.length - 1; i >= 0; i--) {
      const keys = Object.keys(putData.scenario.constraints[i].identifiers)
      if (keys.length === 1 && keys[0] === 'region') {
        putData.scenario.constraints.splice(i, 1)
      }
    }

    //add the new constraints to putData
    for (let i in newRegionConstraints) {
      putData.scenario.constraints.push(newRegionConstraints[i])
    }

    putData.scenario.user_input.defaultCurrency = currency

    //turn carryover and halos back on
    putData.scenario.carryover = true
    putData.scenario.parentChild = true
    if (props.user) {
      props.changeRegionSpend(putData.scenario, putData.scenario.id, props.user)
    }
  }

  const revertBudgetsToOptimal = () => {
    setRegionBudgets([])
    const defaultCurrency = getSelectedCurrency()
    submitConstraints([], defaultCurrency)
  }

  const onSliderChange = (newValue: number, input: ConstraintInput) => {
    let newRegionBudgets = _.cloneDeep(regionBudgets)

    newRegionBudgets.forEach((budget) => {
      if (budget.input.key === input.key) {
        budget.input.value = newValue
      }
    })
    setRegionBudgets(newRegionBudgets)
  }

  const onRangeChange = (
    newValue: number[],
    input: ConstraintInput,
    max: number
  ) => {
    let newRegionBudgets = _.cloneDeep(regionBudgets)

    if (newValue.length !== 0) {
      newRegionBudgets.forEach((budget: ConstraintModal) => {
        if (budget.input.key === input.key) {
          budget.input.rangeValues = newValue
        }
      })

      setRegionBudgets(newRegionBudgets)
    }
  }

  const editConstraintValue = (e: any, transform: ConstraintModal) => {
    if (transform.input.constraintType === 'Range') {
      let newValue = e.split(':')
      if (
        newValue.length !== 2 ||
        isNaN(parseInt(newValue[0])) ||
        isNaN(parseInt(newValue[1])) ||
        parseInt(newValue[0]) < 0 ||
        parseInt(newValue[1]) < 0 ||
        parseInt(newValue[1]) < parseInt(newValue[0])
      ) {
        return
      }
      newValue[0] = parseInt(newValue[0])
      newValue[1] = parseInt(newValue[1])
      let newRegionBudgets = _.cloneDeep(regionBudgets)
      newRegionBudgets.forEach((budget) => {
        if (budget.input.key === transform.input.key) {
          budget.input.rangeValues = newValue
        }
      })
      setRegionBudgets(newRegionBudgets)
    } else {
      if (isNaN(parseInt(e)) || parseInt(e) < 0) {
        return
      }
      let newValue = parseInt(e)
      onSliderChange(newValue, transform.input)
    }
  }
  const constraintTypes = ['Min', 'Max', 'Equal', 'Range', 'None']

  if (props.loading) {
    return <LoadingModal />
  }

  const downloadName = settings?.region_name ? `${settings.region_name}ConstraintsTemplate` : "RegionConstraintsTemplate.csv"


  const levelKey = props.name + "_name"
  const levelName = settings![levelKey] ? settings![levelKey] : props.name

  return (
    <div>
      <div className="justify-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none max-w-screen-2xl m-auto">
        {settings && (
          <div className="mx-auto my-auto w-10/12 bg-white">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-5/6 bg-white outline-none focus:outline-none">
              <div className="flex flex-col items-start justify-between border-b border-solid border-blueGray-200 rounded-t h-full overflow-y-auto">
                <div className="w-full border">
                  <h1 className="text-gray-500 text-md p-5 uppercase">
                    {'Set ' + levelName + ' Budgets'}
                  </h1>
                </div>
                <div className={'p-5 flex flex-row w-full'}>
                  <div className={'flex flex-col w-4/12'}>
                    <h3 className="text-gray-500 uppercase font-bold">
                      File Upload
                    </h3>
                    <div>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={onChange}
                        className={'w-1/2'}
                      />
                      <button
                        onClick={uploadConstraints}
                        className="clear-left rounded bg-gtPink text-white text-xs py-2 w-24"
                      >
                        Upload
                      </button>
                    </div>
                    <div>
                      <CSVLink
                        data={data}
                        filename={downloadName}
                        onClick={downLoadCSV}
                        className={'text-gtPink text-xs'}
                      >
                        Download constraints
                      </CSVLink>
                    </div>
                  </div>
                  <div>
                    <div className={'italic text-xs normal-case'}>
                      To set constraints, first select a constraint type then
                      either shift the bar, or click the constraint value on the
                      right.
                    </div>
                    <div className={'italic text-xs normal-case'}>
                      To set a range constraint, use a colon between the min and
                      max values.
                    </div>
                  </div>
                </div>
                <div className="flex flex-row px-5">
                  {error && (
                    <div className={'mt-4 text-gtBlueSecondary'}>{error}</div>
                  )}
                  <div className={'text-xs'}>
                    Current Plan Budget is:{' '}
                    <span className={'font-bold'}>
                      {appendUnit(
                        props.plan.optimised_spend,
                        'currency',
                        props.plan.options.exchangeRates.defaultSymbol,
                        settings.number_format
                      )}
                    </span>
                  </div>
                </div>
                <div className={'flex flex-col px-10 w-full flex-1'}>
                  <div
                    className={'flex flex-row pt-4 pb-2 w-full justify-around'}
                  >
                    <div className="w-1/12" />
                    <h5 className="w-2/12">CONSTRAINT TYPE</h5>
                    <h5 className="w-5/12">SPEND CONSTRAINT</h5>
                    <h5 className="w-2/12 text-right">CONSTRAINT VALUE</h5>
                    <h5 className="w-2/12 text-right mr-1">CURRENT SPEND</h5>
                  </div>
                  {regionBudgets.map((transform, index) => {
                    return (
                      <div
                        key={index}
                        className="bg-white border-solid border-2 border-#e0e0e0 flex flex-row justify-around"
                      >
                        <div className="w-1/12 my-auto text-center">
                          {transform.label}
                        </div>
                        <div className={'w-2/12'}>
                          <select
                            onChange={(event) =>
                              handleChangeConstraintType(transform, event)
                            }
                            value={transform.input.constraintType}
                            style={{
                              height: '60%',
                              margin: '5% 5%',
                            }}
                            className="text-xs"
                          >
                            {constraintTypes.map((output, index) => {
                              return (
                                <option key={index} value={output}>
                                  {output}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className={'w-7/12'}>
                          <div className={'h-full'}>
                            {transform.input.constraintType === 'Range' && (
                              <div className="media-alloc-channel-budget z-40 items-center flex h-full">
                                <Range
                                  min={0}
                                  max={props.plan.optimised_spend}
                                  trackStyle={[
                                    {
                                      background: '#eb4560',
                                    },
                                  ]}
                                  value={transform.input.rangeValues}
                                  onChange={(e: any) =>
                                    onRangeChange(
                                      e,
                                      transform.input,
                                      props.plan.optimised_spend
                                    )
                                  }
                                />
                              </div>
                            )}
                            {transform.input.constraintType !== 'None' &&
                              transform.input.constraintType !== 'Range' && (
                                <div className="media-alloc-channel-budget z-40 items-center flex h-full">
                                  <Slider
                                    min={0}
                                    value={transform.input.value}
                                    onChange={(e: any) =>
                                      onSliderChange(e, transform.input)
                                    }
                                    max={props.plan.optimised_spend}
                                    trackStyle={{
                                      background: '#eb4560',
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={'w-1/12  text-center m-auto'}>
                          <EditableAttribute
                            height="h-full"
                            defaultValue={
                              transform.input.constraintType === 'Range'
                                ? Math.round(transform.input.rangeValues[0]) +
                                  ': ' +
                                  Math.round(transform.input.rangeValues[1])
                                : transform.input.value
                            }
                            onSave={(e: any) =>
                              editConstraintValue(e, transform)
                            }
                          >
                            <div className="d-flex font-bold h-full">
                              {settings &&
                              transform.input.constraintType === 'Range'
                                ? appendUnit(
                                    Math.round(transform.input.rangeValues[0]),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  ) +
                                  ': ' +
                                  appendUnit(
                                    Math.round(transform.input.rangeValues[1]),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  )
                                : settings &&
                                  transform.input.constraintType !== 'None'
                                ? appendUnit(
                                    Math.round(transform.input.value),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  )
                                : null}
                            </div>
                          </EditableAttribute>
                        </div>
                        {settings && (
                          <div className="d-flex w-1/12 font-bold m-auto text-center">
                            {appendUnit(
                              transform.budget,
                              'currency',
                              props.plan.options.exchangeRates.defaultSymbol,
                              settings.number_format
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className="flex flex-row py-6 justify-center align-middle w-full">
                  <button
                    className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 mr-4 uppercase"
                    onClick={revertBudgetsToOptimal}
                  >
                    Delete Constraints
                  </button>
                  <button
                    className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 uppercase"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>

                  <button
                    className="clear-left rounded bg-gtPink text-white text-xs py-4 ml-4 px-4 uppercase"
                    onClick={submit}
                  >
                    Regenerate Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-hidden"></div>
    </div>
  )
}

export default connector(ChangeRegionBudgetModal)
