import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SalesIcon from '../../../icons/spo/SalesIcon'
import ProfitIcon from '../../../icons/spo/ProfitIcon'
import VolumeIcon from '../../../icons/spo/VolumeIcon'
import { AppState } from '../../../redux/reducers/RootReducer'

// import bestResult from '../../../utils/bestResults'

interface OwnProps {
  onEdit: (section: number) => void
}
type Props = OwnProps

const GoalSummary: FC<Props> = (props) => {
  const { planItem } = useSelector((state: AppState) => state.plans)

  const goals = planItem
    ? planItem?.config.transformations[0].io.filter(
        (x: { type: string }) => x.type === 'output'
      )
    : []

  const getGoal = () => {
    goals.map(
      (goal: { match: string; label: string }) =>
        (goal.match = goal.label.toLowerCase().replace(/ /g, '_'))
    )
    return goals.find(
      (goal: string) => goal.match === planItem.user_input.optimisationMetric
    )
  }

  const getGoalLabel = () => {
    return (getGoal() || {}).label
  }

  const getGoalIcon = (optimisationMetric: string) => {
    const className = 'w-6 h-6'
    switch (optimisationMetric) {
      case 'sales':
        return <SalesIcon className={className} />
      case 'volume':
        return <VolumeIcon className={className} />
      default:
        return <ProfitIcon className={className} />
    }
    // const result = bestResult(['profit', 'sales', 'volume'], optimisationMetric)
  }

  const editNumber = Object.keys(planItem.grouping_levels).length + 1

  return (
    <div className="text-neutral-gray-1100  text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold">GOAL</span>
        {!planItem.locked_at && (
          <button
            className="text-gtPink text-xxs"
            onClick={(_) => props.onEdit(editNumber)}
          >
            edit
          </button>
        )}
      </div>
      {getGoalIcon(planItem.user_input.optimisationMetric || 'profit')}
      <span>{getGoalLabel()}</span>
    </div>
  )
}

export default GoalSummary
