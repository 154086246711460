import { useEffect, useState } from 'react'
import { RowDataType } from '../../types'
import { AgGridProps } from '@gain-theory/ag-grid'

interface IUseHasDataProps {
  localRowData: RowDataType[]
}

export const useHasData = ({ localRowData }: IUseHasDataProps) => {
  const [hasData, setHasData] = useState<boolean>(false)

  const handleOnFilterChanged: AgGridProps['onFilterChanged'] = (event) => {
    let anyRowsDisplayed = false

    event.api.forEachNodeAfterFilter((node) => {
      if (node.data) {
        anyRowsDisplayed = true
      }
    })

    if (anyRowsDisplayed !== hasData) {
      setHasData(anyRowsDisplayed)
    }
  }

  useEffect(() => {
    if (localRowData.length === 0 && hasData) {
      setHasData(false)
      return
    }

    if (localRowData.length > 0 && !hasData) {
      setHasData(true)
    }
  }, [localRowData])

  return {
    hasData,
    handleOnFilterChanged,
  }
}
