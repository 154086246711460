import { Typography } from '@gain-theory/typography'
import { divide } from 'lodash'
import React, { FC, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { UpdateSettings } from '../../redux/actions/SettingsActions'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'
import AdminHeader from './AdminHeader'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
})

const mapDispatchToProps = {
  updateSettings: UpdateSettings,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ChangeSettings: FC<PropsFromRedux> = (props) => {
  const [dateFormat, setDateFormat] = useState('')
  const [error, setError] = useState('')
  const [changeSettingsStatus, setChangeSettingsStatus] = useState<string>('')
  const saveSettings = () => {
    if (
      dateFormat === 'DD.MM.YYYY' ||
      dateFormat === 'MM.DD.YYYY' ||
      dateFormat === 'DD.MMM.YYYY' ||
      dateFormat === 'DD-MM-YYYY' ||
      dateFormat === 'MM-DD-YYYY' ||
      dateFormat === 'DD-MMM-YYYY'
    ) {
      const attrs = { date_format: dateFormat }
      props.updateSettings(attrs)
      setChangeSettingsStatus('Success!')
      setError('')
    } else {
      setError(
        'Please enter either DD.MM.YYYY, MM.DD.YYYY, DD.MMM.YYYY, DD-MM-YYYY, MM-DD-YYYY or DD-MMM-YYYY'
      )
    }
  }

  const updateDateSettings = (e: any) => {
    setDateFormat(e.target.value)
  }

  return (
    <div className={'container'}>
      <AdminHeader />
      <div className={'flex flex-row justify-between'}>
        <Typography.H4 className="my-6">Manage Settings</Typography.H4>
        <Link
          className={'text-gtPink text-center my-6'}
          to={ROUTES.ADMIN.INDEX}
        >
          Back to admin home
        </Link>
      </div>
      <div>Date Format</div>
      <input
        className={'my-2'}
        type={'text'}
        placeholder={props.settings?.date_format}
        onChange={updateDateSettings}
      />
      <div className={'flex flex-col w-32'}>
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
          onClick={saveSettings}
        >
          SAVE
        </button>
      </div>
      {error !== '' && (
        <div className={'text-gtBlueSecondary mt-4'}>{error}</div>
      )}
      {changeSettingsStatus !== '' && (
        <div className={'mt-4 w-full p-5 bg-gtLightGreen'}>
          {changeSettingsStatus}
        </div>
      )}
    </div>
  )
}

export default connector(MainLayout(ChangeSettings))
