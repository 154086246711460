import React, { FC, useState } from 'react'
import { RootStore } from '../../../redux/reducers/Store'
import {
  selectPlanConfigOutputs,
  selectPlanGrandTotals,
  selectPlanItem,
} from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { connect, ConnectedProps } from 'react-redux'
import _ from 'lodash'
import { GrandTotal, Plan } from '../../../types/PlanTypes'
import appendUnit from '../../../utils/appendUnit'
import { Settings } from '../../../types/SettingTypes'
import { uuid } from 'uuidv4'
import ChevronRightDefaultIcon from '../../../icons/ChevronRightDefaultIcon'
import ChevronLeftDefaultIcon from '../../../icons/ChevronLeftDefaultIcon'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  plan: selectPlanItem(state),
  settings: selectSettings(state),
  goals: selectPlanConfigOutputs(state),
  grandTotals: selectPlanGrandTotals(state),
})

const mapDispatchToProps = {}
const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const OptimalImpact = (
  item: GrandTotal,
  settings: Settings | null,
  plan: any
) => {
  return (
    <div>
      <h6>Optimal</h6>
      <div className="bg-success-main flex py-2 px-2 items-center text-md rounded text-white">
        {appendUnit(
          item.optimisedTotal,
          item.unit,
          plan.options.exchangeRates.defaultSymbol || '',
          settings?.number_format || ''
        )}
      </div>
    </div>
  )
}

const DifferenceImpact = (
  item: GrandTotal,
  settings: Settings | null,
  plan: any
) => {
  const getDifference = (a: number, b: number) => {
    try {
      return parseFloat((a - b).toFixed(2))
    } catch (error) {
      return 0
    }
  }

  return (
    <div>
      <h6>Difference</h6>
      <div className="lead text-md px-2 py-2">
        {getDifference(item.mixedTotal, item.optimisedTotal) > 0 ? '+' : ''}
        {appendUnit(
          getDifference(item.mixedTotal, item.optimisedTotal),
          item.unit,
          plan.options.exchangeRates.defaultSymbol || '',
          settings?.number_format || ''
        )}
      </div>
    </div>
  )
}

const HighLevelImpact: FC<PropsFromRedux> = (props) => {
  const [kpiDisplay, setKPIDisplay] = useState(0)

  const previousKPI = () => {
    if(kpiDisplay === 0){
      setKPIDisplay(props.goals.length - 1)
    } else {
      setKPIDisplay(kpiDisplay - 1)
    }
  }
  const nextKPI = () => {
    if(kpiDisplay === props.goals.length -1){
      setKPIDisplay(0)
    } else {
      setKPIDisplay(kpiDisplay + 1)
    }
  }

  const outputOrder = props.goals.map((goal: { label: string }) => goal.label)
  let orderedOutputs = new Array(props.grandTotals.length)
  let totals = props.grandTotals
  totals.forEach((output: GrandTotal) => {
    const index = outputOrder.indexOf(output.label)
    orderedOutputs[index] = output
  })

  if (orderedOutputs.length > 4) {
    orderedOutputs = [
      orderedOutputs[kpiDisplay % props.goals.length],
      orderedOutputs[(kpiDisplay + 1) % props.goals.length],
      orderedOutputs[(kpiDisplay + 2) % props.goals.length],
      orderedOutputs[(kpiDisplay + 3) % props.goals.length],
    ]
  }

  orderedOutputs.forEach((x) => {
    x.optimisedTotal = x.match
      ? props.plan.optimised_totals[x.match]
      : props.plan.optimised_totals[x.key]
    x.mixedTotal = x.match
      ? props.plan.calculated_totals[x.match]
      : props.plan.calculated_totals[x.key]
    return x
  })
  return (
    <>
      <div className="text-gray-500 text-xs">HIGH LEVEL IMPACT</div>
      <div className="w-full flex flex-row justify-evenly">
        {props.goals.length > 4 &&  <button onClick={previousKPI} className="cursor-pointer my-auto h-48px w-48px focus:outline-none"><ChevronLeftDefaultIcon className=""/></button>}
        {orderedOutputs.map((item: GrandTotal) => (
          <div
            key={uuid()}
            className="bg-pink-700 text-white flex flex-col rounded w-72 m-2"
          >
            <div className="p-4">{item.label}</div>
            <div className="w-full bg-gtPink rounded-b flex flex-col space-y-2 p-4">
              <div className="text-xxs  ">THIS PLAN</div>
              <span data-testid="high-level-impact-value" className="text-xl">
                {' '}
                {appendUnit(
                  item.mixedTotal,
                  item.unit,
                  props.plan.options.exchangeRates.defaultSymbol || '',
                  props.settings?.number_format || ''
                )}
              </span>
              <div className="w-full flex flex-row justify-between">
                {OptimalImpact(item, props.settings, props.plan)}
                {DifferenceImpact(item, props.settings, props.plan)}
              </div>
            </div>
          </div>
        ))}
        {props.goals.length > 4 &&  <button onClick={nextKPI} className="cursor-pointer my-auto h-48px w-48px focus:outline-none"><ChevronRightDefaultIcon className=""/></button>}
      </div>
    </>
  )
}

export default connector(HighLevelImpact)
