import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Combobox, ComboboxProps } from '@gain-theory/combobox'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'
import { IO } from '../../../types/PlanTypes'

interface OwnProps {
  options: IO[]
  optimisationType: string
  selectedGoal: string
  setSelectedGoal: Dispatch<SetStateAction<string>>
  setStep: Dispatch<SetStateAction<number>>
}

type Props = OwnProps

const PlanGoalStep: FC<Props> = ({
  options,
  optimisationType,
  selectedGoal,
  setSelectedGoal,
  setStep,
}) => {
  const [goal, setGoal] = useState<ComboboxProps['selectedValues']>([])
  const [goalOptions, setGoalOptions] = useState<ComboboxProps['options']>([])
  const [goalError, setGoalError] = useState<boolean>(false)

  const PLAN_GOAL_TEXTS = {
    PAGE_TITLE: 'What is your goal?',
    COMBOBOX_LABEL:
      optimisationType === 'budget' ? 'To maximise' : 'To achieve a specific',
    COMBOBOX_PLACEHOLDER: 'Select a goal',
    COMBOBOX_ERROR_MESSAGE: 'This field is required.',
  }

  useEffect(() => {
    if (!selectedGoal || selectedGoal === '') return

    const option = options.find((option) => option.key === selectedGoal)

    if (!option) return

    const mappedValue: ComboboxProps['selectedValues'][number] = {
      id: selectedGoal,
      label: option?.label ?? '',
    }

    setGoal([mappedValue])
  }, [options, selectedGoal])

  useEffect(() => {
    const mappedOptions: ComboboxProps['options'] = options.map((option) => ({
      id: option.key,
      label: option.label,
    }))

    setGoalOptions(mappedOptions)
  }, [options])

  const handleBackButton = () => {
    setGoalError(false)
    setStep((prev) => prev - 1)
  }

  const handleNextButton = () => {
    if (!goal[0] || !goal[0].label || goal[0].label.trim().length === 0) {
      setGoalError(true)
      return
    }

    setGoalError(false)
    setSelectedGoal(goal[0].id?.toString() ?? goal[0].label)
    setStep((prev) => prev + 1)
  }

  return (
    <CreateMarketingPlanLayout
      title={PLAN_GOAL_TEXTS.PAGE_TITLE}
      component={
        <div className="w-fit min-w-64 2xl:min-w-128">
          <Combobox
            disallowTextManipulation
            multiple={false}
            allowNewValues={false}
            label={PLAN_GOAL_TEXTS.COMBOBOX_LABEL}
            placeholder={PLAN_GOAL_TEXTS.COMBOBOX_PLACEHOLDER}
            options={goalOptions}
            selectedValues={goal}
            setSelectedValues={setGoal}
            state={goalError ? 'error' : 'default'}
            helperText={
              goalError ? PLAN_GOAL_TEXTS.COMBOBOX_ERROR_MESSAGE : undefined
            }
          />
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanGoalStep
