import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import { NavbarMenuItem } from './types'

const modellingSubmenuBase = [
  {
    label: 'Data Pipeline',
  },
  {
    label: 'Model manager',
  },
  {
    label: 'Changepoint',
  },
  {
    label: 'Covercalc',
  },
] as NavbarMenuItem['submenu']

const modellingMenuBase = {
  label: 'Modelling',
  iconName: 'ChartBarSquareIcon',
  showBadge: false,
} as any as NavbarMenuItem

function useModellingMenu() {
  const { modules } = useSelector((state: AppState) => state.session)

  const [modellingMenu, setModellingMenu] = useState({ ...modellingMenuBase })
  const [active, setActive] = useState(false)

  useEffect(() => {
    let moduleActive = false

    if (modules && modules.length > 0) {
      const gtModule = modules.find((gtModule) => gtModule.key === 'modelling')

      moduleActive = !!gtModule && gtModule.active
    }

    setActive(moduleActive)
  }, [modules])

  useEffect(() => {
    const newModellingMenu = {
      ...modellingMenuBase,
      disabled: !active,
      ...(active && { submenu: [...modellingSubmenuBase] }),
    } as any as NavbarMenuItem

    setModellingMenu(newModellingMenu)
  }, [active])

  return modellingMenu
}

export default useModellingMenu
