import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { useCallback, useEffect, useState } from 'react'
import { initializeAzureAd } from './azure.config'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CreateOktaSession } from '../redux/actions/SessionActions'
import { RootStore } from '../redux/reducers/Store'

const AZURE_IDP_NAME = 'azure'

const mapState = (state: RootStore) => ({})
const connector = connect(mapState)

type AzureProviderProps = {
  children: React.ReactNode
}

function AzureProviderImp({ children }: AzureProviderProps) {
  const [azureClient, setAzureClient] =
    useState<IPublicClientApplication | null>(null)

  const dispatch = useDispatch()

  const authSettings = useSelector(
    (state: RootStore) => state.settings.oktaSettings
  )

  const azureIdp = authSettings.find((item) => item.idp_name === AZURE_IDP_NAME)

  const onSignIn = useCallback(
    (accessToken: string) => {
      dispatch(CreateOktaSession(accessToken))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!azureIdp) return
    if (azureClient !== null) return

    initializeAzureAd(azureIdp.client_id, azureIdp.domain, onSignIn).then(
      (client) => setAzureClient(client)
    )
  }, [azureClient, azureIdp, onSignIn])

  if (!azureClient) return <>{children}</>

  return <MsalProvider instance={azureClient}>{children}</MsalProvider>
}

export const AzureProvider = connector(AzureProviderImp)
