import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const NoValueIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#Fff"
          d="M0 32c0 17.672 14.325 32 32 32 17.672 0 32-14.328 32-32S49.672 0 32 0C14.325 0 0 14.328 0 32z"
        />
        <rect width="24" height="4" x="20" y="30" fill="#999" rx="2" />
      </g>
    </svg>
  )
}

export default NoValueIcon
