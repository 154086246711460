import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../Routes'

interface OwnProps {}

type Props = OwnProps

const AdminHeader: FC<Props> = (props) => {
  return (
    <>
      <div className={'flex flex-row mt-4 p-2 border-b-2'}>
        <div className={'mr-8'}>
          <Link
            className={'text-gtPink font-semibold'}
            to={ROUTES.ADMIN.SETTINGS}
          >
            SETTINGS
          </Link>
        </div>
        <div className="group inline-block  mx-8">
          <button className="outline-none focus:outline-none rounded-sm flex items-center min-w-28">
            <span className="pr-1 font-semibold flex-1 text-gtPink">USERS</span>
            <span>
              <svg
                className="fill-current h-4 w-4 transform group-hover:-rotate-180
            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            className="bg-white border rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
      transition duration-150 ease-in-out origin-top min-w-32"
          >
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.MANAGE_USERS}>Manage Users</Link>
            </li>
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.CREATE_USER}>Create User</Link>
            </li>
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.UPLOAD_USERS}>Upload Users</Link>
            </li>
          </ul>
        </div>
        <div className="group inline-block mx-8">
          <button className="outline-none focus:outline-none rounded-sm flex items-center min-w-28">
            <span className="pr-1 font-semibold flex-1 text-gtPink">
              CONFIGS
            </span>
            <span>
              <svg
                className="fill-current h-4 w-4 transform group-hover:-rotate-180
            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            className="bg-white border rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
      transition duration-150 ease-in-out origin-top min-w-32"
          >
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.MANAGE_CONFIGS}>Manage Configs</Link>
            </li>
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.UPLOAD_CONFIG}>Upload Config</Link>
            </li>
          </ul>
        </div>
        <div className="group inline-block mx-8">
          <button className="outline-none focus:outline-none rounded-sm flex items-center min-w-28">
            <span className="pr-1 font-semibold flex-1 text-gtPink">
              GROUPS
            </span>
            <span>
              <svg
                className="fill-current h-4 w-4 transform group-hover:-rotate-180
            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            className="bg-white border rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
      transition duration-150 ease-in-out origin-top min-w-32"
          >
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.MANAGE_GROUPS}>Manage Groups</Link>
            </li>
            <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gtPink">
              <Link to={ROUTES.ADMIN.CREATE_GROUPS}>Create Group</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AdminHeader
