import XLSX from 'xlsx-populate/browser/xlsx-populate'

export function downloadXlsxFromCsv(
  csv,
  filename,
  onError) {
  const handleDownload = async () => {
    try {
      // Convert CSV string to 2D array
      const data = csv.split('\n').map((row) => row.split(','))

      // Generate workbook
      XLSX.fromBlankAsync().then((workbook) => {
        const worksheet = workbook.sheet(0)
        data.forEach((row, rowIndex) => {
          row.forEach((cellValue, columnIndex) => {
            if(rowIndex !== 0){
              const setAsNumber = parseFloat(cellValue)
              if(isNaN(setAsNumber)){
                worksheet.cell(rowIndex + 1, columnIndex + 1).value(cellValue)
              } else {
                worksheet.cell(rowIndex + 1, columnIndex + 1).value(setAsNumber)
              }
            }
            else {
              worksheet.cell(rowIndex + 1, columnIndex + 1).value(cellValue)
            }
          })
        })
        // Create download link
        workbook.outputAsync({ base64: false }).then((binaryData) => {
          const blob = new Blob([binaryData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = `${filename}.xlsx`

          // Trigger download
          link.click()
        })
      })
    } catch (error) {
      onError && onError(error)
    }
  }

  return handleDownload
}
