import { combineReducers } from 'redux'
import sessionReducer from './SessionReducer'
import marketingDiagnosticsReducer from './MDReducers/MarketingDiagnosticsReducer'
import plansReducer from './PlanReducer'
import settingsReducer from './SettingsReducer'
import configReducer from './ConfigReducer'

import filtersReducer from './PlanFilterReducer'
import allBrandsReducer from './AllBrandsReducer'
import mediaChannelAllocationReducer from './MediaChannelAllocationReducer'
import createPlanReducer from './CreatePlanReducer'
import performanceOverviewReducer from './MDReducers/PerformanceOverviewReducer'
import { viewCurvesReducer } from './ViewCurvesReducer'
import editPlanReducer from './EditPlanReducer'
import constraintsReducer from './ConstraintsReducer'
import addOrCutReducer from './AddOrCutReducer'
import planComparisonsReducer from './PlanComparisonsReducer'

const RootReducer = combineReducers({
  planComparisons: planComparisonsReducer,
  session: sessionReducer,
  marketingDiagnostics: marketingDiagnosticsReducer,
  plans: plansReducer,
  settings: settingsReducer,
  configs: configReducer,
  planFilters: filtersReducer,
  allBrands: allBrandsReducer,
  mediaChannelAllocation: mediaChannelAllocationReducer,
  createPlan: createPlanReducer,
  performanceOverview: performanceOverviewReducer,
  viewCurves: viewCurvesReducer,
  editPlan: editPlanReducer,
  constraints: constraintsReducer,
  addOrCut: addOrCutReducer,
})

export type AppState = ReturnType<typeof RootReducer>
export default RootReducer
