import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import RootReducer from './RootReducer'
import { configureSocket } from '../../socket.io'

const Store = createStore(RootReducer, applyMiddleware(thunk))
const socket = configureSocket(Store.dispatch)

export type RootStore = ReturnType<typeof RootReducer>

export default Store
