// import React, { FC } from 'react'
// import { KPI, Phase } from '../../../../types/MarketingDiagnosticsTypes'
// import SearchIcon from '../../../../icons/media/SearchIcon'
// import { Link } from 'react-router-dom'
// import UrlAssembler from 'url-assembler'
// import { ROUTES } from '../../../../Routes'
//
// interface OwnProps {}
//
// type Props = OwnProps
//
// export const HighlightCard = <P extends object>(
//   Component: React.ComponentType<P>,
//   kpi: KPI,
//   brand: string,
//   channel: string,
//   phase: Phase
// ): React.FC<P> => ({ ...props }) => (
//   <div className="relative w-1/4 bg-white border rounded content-center flex flex-col justify-center items-center pt-4 space-y-2">
//     <div className="text-gray-500 text-xs">MOST EFFECTIVE CHANNEL</div>
//     <SearchIcon className="W-10 H-10" />
//     <Component {...(props as P)} />
//     <Link
//       to={UrlAssembler()
//         .template(ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE)
//         .param('channel', channel)
//         .param('kpiId', kpi.id.toString())
//         .param('phaseId', phase.phase)
//         .param('brand', brand)
//         .param('kpiName', kpi.label)
//         .toString()}
//       className="w-full bg-gtPink p-4 text-white text-xs flex items-center justify-center"
//     >
//       VIEW
//     </Link>
//   </div>
// )
//
// export default HighlightCard
import React, { FC } from 'react'
import SearchIcon from '../../../../icons/media/SearchIcon'
import { KPI, Phase } from '../../../../types/MarketingDiagnosticsTypes'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../../Routes'
import MediaChannelIcon from '../../../../icons/media/MediaChannelIcon'

interface OwnProps {
  kpi: KPI
  brand: string
  channel: string
  phase: Phase
  title: string
  color: string
}

type Props = OwnProps

const HighlightCard: FC<Props> = (props) => {
  if (!props.phase || !props.kpi) {
    return <div />
  }

  return (
    <div className="relative w-1/4 bg-white border rounded content-center flex flex-col justify-center items-center pt-4 space-y-2">
      <div className="text-gray-500 text-xs uppercase content-center">
        {props.title}
      </div>
      <MediaChannelIcon
        type={props.channel !== "Search" ? 'search' : 'affiliate'}
        className="w-10 h-10"
        stroke={props.color}
      />
      {props.children}
      <Link
        to={UrlAssembler()
          .template(ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE)
          .param('channel', props.channel)
          .param('kpiId', props.kpi.id.toString())
          .param('phaseId', props.phase.phase)
          .param('brand', props.brand)
          .param('kpiName', props.kpi.label)
          .toString()}
        className="w-full bg-gtPink p-4 text-white text-xs flex items-center justify-center input-focus rounded-t-none"
      >
        VIEW
      </Link>
    </div>
  )
}

export default HighlightCard
