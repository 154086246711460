import { Card } from '@gain-theory/card'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useIsBetaFeature } from '../../../shared/hooks/use-is-beta-feature'
import { FEATURE_FLAGS } from '../../../utils/constants'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'

interface OwnProps {
  modifyConstraints: boolean
  setModifyConstraints: Dispatch<SetStateAction<boolean>>
  setStep: Dispatch<SetStateAction<number>>
  handleGeneratePlan: () => void
}

type Props = OwnProps

const PlanModifyTimeConstraints: FC<Props> = ({
  modifyConstraints,
  setModifyConstraints,
  setStep,
  handleGeneratePlan,
}) => {
  const isBeta = useIsBetaFeature({
    flagKey: FEATURE_FLAGS.PROPORTIONAL_FLIGHTING,
  })
  
  const [modify, setModify] = useState<boolean>(false)

  const PLAN_MODIFY_CONSTRAINTS_TEXTS = {
    PAGE_TITLE: 'Do you want to modify time period budget constraints now?',
    PAGE_TITLE_TOOLTIP_HEADER: 'Time period budget constraints',
    PAGE_TITLE_TOOLTIP_BODY:
      'View, add, remove, and modify time period budget constraints for this plan. Default constraints are pre-populated and modifying them will impact only this plan.',
    PAGE_SUBTITLE: 'This is optional, and you can always change them later.',
  }

  useEffect(() => {
    setModify(modifyConstraints)
  }, [modifyConstraints])

  const handleBackButton = () => {
    setStep((prev) => prev - 1)
  }

  const handleNextButton = () => {
    setModifyConstraints(modify)

    if (!modify) {
      handleGeneratePlan()
      return
    }

    setStep((prev) => prev + 1)
  }

  return (
    <CreateMarketingPlanLayout
      isLastStep={!modify}
      title={PLAN_MODIFY_CONSTRAINTS_TEXTS.PAGE_TITLE}
      titleChip={isBeta ? 'Beta' : undefined}
      titleTooltip={{
        title: PLAN_MODIFY_CONSTRAINTS_TEXTS.PAGE_TITLE_TOOLTIP_HEADER,
        description: PLAN_MODIFY_CONSTRAINTS_TEXTS.PAGE_TITLE_TOOLTIP_BODY,
      }}
      subtitle={PLAN_MODIFY_CONSTRAINTS_TEXTS.PAGE_SUBTITLE}
      component={
        <div className="flex flex-row h-full justify-center items-center gap-xl">
          <Card selected={modify} onClick={() => setModify(true)}>
            <div className="flex flex-row gap-4">
              <Icon name="HandThumbUpIcon" color="brand-primary-main" />
              <Typography.Subtitle1>YES</Typography.Subtitle1>
            </div>
          </Card>
          <Card selected={!modify} onClick={() => setModify(false)}>
            <div className="flex flex-row gap-4">
              <Icon name="HandThumbDownIcon" color="brand-primary-main" />
              <Typography.Subtitle1>NO</Typography.Subtitle1>
            </div>
          </Card>
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanModifyTimeConstraints
