import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ROUTES } from '../Routes'

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean
}

export class UnAuthedRoute extends Route<ProtectedRouteProps> {
  public render() {
    if (this.props.isAuthenticated) {
      const renderComponent = () => <Redirect to={ROUTES.HOME} />

      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      )
    } else {
      return <Route {...this.props} />
    }
  }
}
