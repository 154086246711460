import { useWindowSize } from '@gain-theory/hooks';
import { Typography } from '@gain-theory/typography';
import { useMemo } from 'react';

export function useInfoTypography() {
  const { width } = useWindowSize();

  const TitleTypography = useMemo(() => {
    if (width === undefined || width <= 1024) {
      return Typography.H6;
    }

    if (width <= 1280) {
      return Typography.H5;
    }

    return Typography.H4;
  }, [width]);

  const SubtitleTypography = useMemo(() => {
    if (width === undefined || width <= 1024) {
      return Typography.Body1;
    }

    return Typography.H6;
  }, [width]);

  return { TitleTypography, SubtitleTypography };
}
