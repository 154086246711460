import OktaSignIn, { WidgetOptions } from '@okta/okta-signin-widget'
import { useEffect, useRef } from 'react'

const defaultConfig: WidgetOptions = {
  useClassicEngine: true,
  useInteractionCodeFlow: true,
  idpDisplay: 'SECONDARY',
}

type OktaSignInWidgetProps = {
  clientId: string
  redirectUri: string
  issuer: string
  customIdps?: [
    {
      id: string
      text: string
      type: string
    }
  ]
  onSuccess: (res: any) => void
  onError: (err: any) => void
}

export const OktaWidget = ({
  clientId,
  redirectUri,
  issuer,
  onSuccess,
  onError,
  customIdps,
}: OktaSignInWidgetProps) => {
  const widgetRef = useRef()

  useEffect(() => {
    if (!widgetRef.current) {
      return
    }

    const widget = new OktaSignIn({
      ...defaultConfig,
      idps: customIdps ?? [],
      clientId,
      redirectUri,
      issuer,
    })

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError)

    return () => widget.remove()
  }, [onSuccess, onError, clientId, redirectUri, issuer, customIdps])

  return <div id="okta-widget" ref={widgetRef} />
}
