import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'
import { IGroupUpdate } from './types'
import {
  CreateGroupAttrs,
  CreateUserAttrs,
  CreateUserGroupAttrs,
  UpdatePasswordAttrs,
} from './types/AdminTypes'
import {
  AppConfig,
  CreateMarketingDiagnosticsConfig,
  ViewSettings,
} from './types/ConfigTypes'
import { CreateComparisonAttrs } from './types/PlanComparisonTypes'
import { Plan } from './types/PlanTypes'
import { SettingsAttrs } from './types/SettingTypes'

const prefix = '/crayon'
const defaults = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
}

const checkIfSessionUpdateIs401 = ({ method, url }: AxiosResponse['config']) => {
  return ['delete', 'get'].includes((method || '').toLowerCase()) && (url || '').includes('/session')
}

axios.interceptors.response.use(undefined, (err) => {
    console.error(`${err.response.status}: ${err.response.statusText}`);
    if (err.response.status === 401 && !checkIfSessionUpdateIs401(err.response.config)) {
    window.location.href = `${window.location.origin}${process.env.NODE_ENV === 'development' ? '/admin' : ''}/login`;
  }
});

const Session = {
  createSession: async (email: string, password: string) => {
    return await axios.post(`${prefix}/session`, {
      username: email,
      password: password,
    })
  },

  endSession: async () => {
    return await axios.delete(`${prefix}/session`)
  },

  getUsers: async () => {
    return await axios.get(`${prefix}/users`)
  },

  deleteUser: async (id: string) => {
    return await axios.delete(`${prefix}/users/${id}`)
  },

  createUser: async (attrs: CreateUserAttrs) => {
    return await axios.post(`${prefix}/users`, {
      enabled: attrs.enabled,
      admin: attrs.admin,
      username: attrs.username,
      is_locked: attrs.is_locked,
      password: attrs.password,
    })
  },

  createUsersBulk: async (users: CreateUserAttrs[]) => {
      const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    _.each(users, (user) => {
      return axios.post(`${prefix}/users`, {
        username: user.username,
        enabled: true,
        admin: false,
        okta: true,
        is_locked: false,
        password: generatePassword(),
      })
    })
  },

  updateUser: async (attrs: CreateUserAttrs, id: string) => {
    return await axios.patch(`${prefix}/users/${id}`, {
      enabled: attrs.enabled,
      admin: attrs.admin,
      username: attrs.username,
      is_locked: attrs.is_locked,
      password: attrs.password,
    })
  },

  updateUserAllowed: async (attrs: CreateUserAttrs, id: string) => {
    return await axios.patch(`${prefix}/users/${id}/allowed`, {
      enabled: attrs.enabled,
      admin: attrs.admin,
      username: attrs.username,
      is_locked: attrs.is_locked,
      password: attrs.password,
    })
  },

  createGroup: async (attrs: CreateGroupAttrs) => {
    return await axios.post(`${prefix}/scene-replacement/groups`, {
      name: attrs.name,
      regions: attrs.regions,
      modules: attrs.modules,
    })
  },

  createUserGroup: async (attrs: CreateUserGroupAttrs) => {
    return await axios.post(`${prefix}/scene-replacement/user_groups`, {
      user_id: attrs.user_id,
      group_id: attrs.group_id,
    })
  },

  deleteUserGroup: async (id: number) => {
    return await axios.delete(`${prefix}/scene-replacement/user_groups/${id}`)
  },

  updateGroup: async (id: number, attrs: IGroupUpdate) => {
    return await axios.patch(`${prefix}/scene-replacement/groups/${id}`, {
      name: attrs.name,
      regions: attrs.regions,
      modules: attrs.modules,
    })
  },

  getGroups: async () => {
    return await axios.get(`${prefix}/scene-replacement/groups`)
  },

  updatePassword: async (attrs: UpdatePasswordAttrs) => {
    return await axios.patch(`${prefix}/users/${attrs.id}/reset`, {
      current_password: attrs.current_password,
      password: attrs.password,
    })
  },

  recoverSession: async () => {
    return await axios.get(`${prefix}/session`, defaults)
  },

  createOktaSession: async (session: any) => {
    return await axios.post(`${prefix}/session/okta`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + session.token
      }
    })
  },
}

const MarketingDiagnostics = {
  getMarketingDiagnostics: async () => {
    return await axios.get(`${prefix}/marketing-diagnostics`, defaults)
  },

  getHighlights: async () => {
    return await axios.get(`${prefix}/sensor-reports/highlights`)
  },

  getSystemLinks: async () => {
    return await axios.get(`${prefix}/system-links`)
  },

  getInsights: async (brands: string[]) => {
    let url = `${prefix}/sensor-reports/insights?brands=${brands.join(',')}`
    return await axios.get(url)
  },
}

const PerformanceOverview = {
  getChannelDefault: async (
    kpiId: string,
    currentPhase: string,
    prevPhase: string,
    brandsQuery: string
  ) => {
    let url = `${prefix}/sensor-reports/channels-defaults?kpi=${kpiId}&currentPhase=${currentPhase}&brands=${brandsQuery}&prevPhase=${prevPhase}`
    // brands ? (url += `&brand=${brands[0].brand}`) : "";
    return await axios.get(url)
  },

  getKpis: async () => {
    let url = `${prefix}/sensor-reports/kpis`
    return await axios.get(url)
  },
  getDrilldownPerformance: async (
    channel: string,
    kpiId: string,
    phaseId: string,
    brand: string,
    flexName: string,
    flexValue: string
  ) => {
    let url = `${prefix}/sensor-reports/drilldown/${channel}?kpi=${kpiId}&phase=${phaseId}&brand=${brand}&flexName=${flexName}&flexValue=${flexValue}`
    return await axios.get(url)
  },

  getDrilldownPerformanceBrand: async (
    channel: string,
    kpiId: string,
    phaseId: string,
    prevPhase: string,
    brands: string,
  ) => {
    let url = `${prefix}/sensor-reports/channels/${channel}?kpi=${kpiId}&phase=${phaseId}&brands=${brands}&prevPhase=${prevPhase}`
    return await axios.get(url)
  },
}

const Configs = {
  getConfigs: async () => {
    return await axios.get(`${prefix}/scene-replacement/configs`)
  },
  getConfigById: async (id: string) => {
    return await axios.get(`${prefix}/scene-replacement/configs/${id}`)
  },
  createAppConfig: async (config: AppConfig) => {
    return await axios.post(`${prefix}/modules`, {
      modules: config.modules,
      okta_settings: config.okta_settings,
      systemLinks: config.systemLinks,
      featureFlags: config.featureFlags,
    })
  },
  createMDConfig: async (config: CreateMarketingDiagnosticsConfig) => {
    return await axios.post(`${prefix}/marketing-diagnostics/`, {
      ...config,
    })
  },
  createSPOConfig: async (config: any) => {
    return await axios.post(`${prefix}/scene-replacement/configs`, {
      ...config,
    })
  },
}

const Plans = {
  getSummaries: async () => {
    return await axios.get(
      `${prefix}/scene-replacement/scenarios-summary-homepage`
    )
  },

  copyPlan: async (id: string) => {
    return await axios.post(
      `${prefix}/scene-replacement/scenarios/${id}/duplicate`
    )
  },

  sharePlan: async (id: string, userIds: number[]) => {
    return await axios.post(
      `${prefix}/scene-replacement/scenarios/${id}/share`,
      {
        users: userIds,
      }
    )
  },

  lockPlan: async (id: string) => {
    return await axios.patch(`${prefix}/scene-replacement/scenarios/${id}`, {
      locked: true,
    })
  },

  deletePlan: async (id: string) => {
    return await axios.patch(`${prefix}/scene-replacement/scenarios/${id}`, {
      enabled: false,
    })
  },

  toggleCarryover: async (id: string, plan: Plan) => {
    return await axios.patch(
      `${prefix}/scene-replacement/scenarios/${id}/toggleCarryover`,
      {
        scenario: plan,
      }
    )
  },

  toggleHalo: async (id: string, plan: Plan) => {
    return await axios.patch(
      `${prefix}/scene-replacement/scenarios/${id}/toggleHalo`,
      {
        scenario: plan,
      }
    )
  },

  getPlan: async (id: string) => {
    return await axios.get(`${prefix}/scene-replacement/scenarios/${id}`)
  },

  putPlan: async (plan: Plan) => {
    return await axios.patch(
      `${prefix}/scene-replacement/scenarios/${plan.id}`,
      {
        ...plan,
      }
    )
  },
  putCurrency: async (id: string, options: any) => {
    return await axios.patch(
      `${prefix}/scene-replacement/scenarios/${id}/options`,
      {
        options: {
          ...options,
        },
      }
    )
  },
  getAllPlanComparisons: async () => {
    return await axios.get(`${prefix}/scene-replacement/comparison`)
  },
  getPlanComparisonById: async (id: string) => {
    return await axios.get(`${prefix}/scene-replacement/comparison/${id}`)
  },
  savePlanComparison: async (attrs: CreateComparisonAttrs) => {
    return await axios.post(`${prefix}/scene-replacement/comparison`, {
      ...attrs,
    })
  },
}

const Settings = {
  getSettings: async () => {
    return await axios.get(`${prefix}/settings`)
  },
  getInChannelSettings: async () => {
    return await axios.get(`${prefix}/in_channel_settings`)
  },
  updateSettings: async (attrs: SettingsAttrs) => {
    return await axios.patch(`${prefix}/settings`, {
      date_format: attrs.date_format,
    })
  },
  updateViewSettings: async (attrs: ViewSettings) => {
    return await axios.patch(`${prefix}/settings`, {
      ...attrs,
    })
  },
  updateInChannelViewSettings: async (attrs: any) => {
    return await axios.patch(`${prefix}/in_channel_settings`, {
      ...attrs,
    })
  },
  getOktaSettings: async () => {
    return await axios.get(`${prefix}/okta-settings`)
  },
}

const api = {
  Session,
  MarketingDiagnostics,
  Configs,
  Plans,
  Settings,
  PerformanceOverview,
}

export default api
