import React, { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../shared/MainLayout'
import { RootStore } from '../../redux/reducers/Store'
import {
  selectIsLoading,
  selectPlanItem,
  selectPlanSummaryList,
  selectShowSharePlanModal,
} from '../../redux/reducers/PlanReducer'
import {
  GetPlanItem,
  ToggleCarryover,
  ToggleHalo,
} from '../../redux/actions/PlanActions'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import PlanSummary from '../../shared/plan-view/PlanSummary'
import HighLevelImpact from './plan-output/HighLevelImpact'
import ObservationAllocation from './plan-output/ObservationAllocation'
import { AppState } from '../../redux/reducers/RootReducer'
import EditableAttribute from '../../utils/EditableAttribute'
import { selectSessionUser } from '../../redux/reducers/SessionReducer'
import { EditSPOPlanName } from '../../socket.io'
import { IPlanSummary } from '../../types/PlanTypes'
import RegionAllocation from './plan-output/RegionAllocation'
import { AllocationComponent } from './plan-output/AllocationComponent'
import GroupingLevelAllocation from './plan-output/GroupingLevelAllocation'
import { GtiToggleButton } from '../../shared/GTIToggleButton'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import PlanItemActionsMenu from '../../shared/PlanItemActionsMenu'
import PlanOutlookInChannel from './PlanOutlookInChannel'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import SharePlanModal from './SharePlanModal'
import MediaChannelAllocation from './plan-output/MediaChannelAllocation'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  planItem: selectPlanItem(state),
  user: selectSessionUser(state),
  settings: selectSettings(state),
  planSummaryList: selectPlanSummaryList(state),
  isLoading: selectIsLoading(state),
  showModal: selectShowSharePlanModal(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  editSPOPlanName: EditSPOPlanName,
  toggleCarryover: ToggleCarryover,
  toggleHalo: ToggleHalo,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanOutlook: FC<PropsFromRedux> = (props) => {
  const { id }: { id: string } = useParams()
  const { comparisonId }: { comparisonId: string } = useParams()
  const { settings } = useSelector((state: AppState) => state.settings)

  const groupingLevelKeys = props.planItem
    ? Object.keys(props.planItem.grouping_levels)
    : []
  const bottomLevel =
    groupingLevelKeys.length > 0
      ? groupingLevelKeys[groupingLevelKeys.length - 1]
      : ''
  useEffect(() => {
    props.onGetPlan(id)
  }, [])

  const editPlanName = (name: string) => {
    if (props.planItem && props.user) {
      props.editSPOPlanName(props.planItem.id, props.user, name)
    }
  }

  const toggleCarryover = () => {
    if (props.planItem) {
      const plan = {
        ...props.planItem,
        carryover: !props.planItem.carryover,
      }
      props.toggleCarryover(props.planItem.id, plan)
    }
  }

  const toggleHalo = () => {
    if (props.planItem) {
      const plan = {
        ...props.planItem,
        parentChild: !props.planItem?.parentChild,
      }
      props.toggleHalo(props.planItem.id, plan)
    }
  }

  if (props.planItem && props.planItem.options.in_channel) {
    return <PlanOutlookInChannel />
  }

  const showHaloToggle = props.planItem?.config.transformations.some(
    (t: any) => t.is_halo === true
  )

  const comparisonUrl = comparisonId
    ? UrlAssembler()
        .template(ROUTES.SPO.COMPARISONS_OUTPUT)
        .param('id', comparisonId)
        .toString()
    : ''

    const planId = props.planItem?.id ?? 'plan-id-key'

    return (
      <div key={planId}>
        {props.showModal.show && <SharePlanModal />}
        <div className="w-full flex flex-col space-y-8">
          {(!props.planItem || props.isLoading) && <LoadingModal></LoadingModal>}
          <div className="flex flex-row items-center">
            {comparisonId && (
              <a href={comparisonUrl} className="mb-4 text-gtPink">
                {'> Back To Comparison'}
              </a>
            )}
            <EditableAttribute
              showEditButton={true}
              isLocked={!!props.planItem?.locked_at}
              onSave={editPlanName}
              defaultValue={props.planItem?.name || ''}
              className="text-xl text-gray-500 mb-6"
            >
              <div className="flex flex-1 flex-row justify-start">
                <span className="text-xl">
                  {props.planItem?.name || `Plan  ${id}`}
                </span>
                <span className="mt-2">{props.planItem?.locked_at && '🔒'}</span>
              </div>
            </EditableAttribute>
            <div className="flex flex-1 flex-row gap-xs items-center justify-end">
              {props.planItem && props.planItem?.options.carryoverWeeks > 0 && (
                <div className="flex flex-row h-auto ">
                  <GtiToggleButton
                    value={props.planItem?.carryover}
                    onToggle={toggleCarryover}
                    label="Carryover impact"
                  />
                </div>
              )}
              {showHaloToggle && (
                <div className="flex flex-row h-auto">
                  <GtiToggleButton
                    value={props.planItem?.parentChild}
                    onToggle={toggleHalo}
                    label="Halo impact"
                  />
                </div>
              )}
              {props.planItem && (
                <PlanItemActionsMenu
                  fromPlan={true}
                  planItem={props.planItem}
                  planSummaryList={props.planSummaryList}
                />
              )}
            </div>
          </div>
          {props.planItem && props.settings ? (
            <>
              <PlanSummary plan={props.planItem} />
              <div className="w-full flex flex-col space-y-10">
                <HighLevelImpact />
                {Object.keys(props.planItem.grouping_levels).map((key, index) => {
                  if (key === 'region' && settings?.region_type !== 'cards') {
                    return (
                      <AllocationComponent
                        key={'region'}
                        name={'region'}
                        title={'region'}
                        children={<RegionAllocation />}
                        isViewVisible={!settings?.hide_views.region}
                      />
                    )
                  }
                  if (key === bottomLevel) {
                    return (
                      <AllocationComponent
                        name={bottomLevel}
                        key={bottomLevel}
                        title={settings?.media_name || "'"}
                        children={
                          <MediaChannelAllocation
                            inChannel={false}
                            name={bottomLevel}
                          />
                        }
                        isViewVisible={!settings?.hide_views[bottomLevel]}
                      />
                    )
                  }
                  return (
                    <AllocationComponent
                      key={index}
                      name={key}
                      title={key}
                      children={<GroupingLevelAllocation name={key} />}
                      isViewVisible={!settings?.hide_views[key]}
                    />
                  )
                })}
                <AllocationComponent
                  name={'time'}
                  title="Time"
                  children={<ObservationAllocation />}
                  isViewVisible={!settings?.hide_views.time}
                />
                <div className="text-gtDarkGray text-xxs text-right">{`Config ID: ${props.planItem.config.id}`}</div>
              </div>
            </>
          ) : null}
        </div>
    </div>
  )
}

export default connector(MainLayout(PlanOutlook))
