import * as _ from 'lodash'
import { CURVE_OUTPUT, DELETE_CURVE_DATA } from '../actions/PlanActionTypes'
import {
  DRAW_CURVES,
  CALCULATE_CURVE_DATA_ATTEMPT,
  CALCULATE_CURVE_DATA_SUCCESS,
} from '../actions/SocketActionTypes'
import { RootStore } from './Store'

const initialState = {
  curveData: [],
}

let results: any = []

export const viewCurvesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DRAW_CURVES:
      return state
    case CURVE_OUTPUT:
      //resets once the 24 data points have been reached
      results.push(action.result)

      if (results.length > 24) {
        results = []
        results.push(action.result)
        return { ...state, curveData: results }
      }
      if (results.length === 24) {
        return { ...state, curveData: results }
      }
      return state
    case DELETE_CURVE_DATA:
      return { ...state, curveData: [] }
    default:
      return state
  }
}

export const getCurveData = (state: RootStore) => {
  return state.viewCurves.curveData.length > 23
    ? state.viewCurves.curveData
    : []
}
