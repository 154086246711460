import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootStore } from '../redux/reducers/Store'

const AZURE_IDP_NAME = 'azure'

const mapState = (state: RootStore) => ({})
const connector = connect(mapState)

type OktaProviderProps = ConnectedProps<typeof connector> & {
  children: React.ReactNode
}

function OktaProviderImpl({ children }: OktaProviderProps) {
  const [oktaAuth, setOktaAuth] = useState<OktaAuth | null>(null)
  const history = useHistory()
  const { selectedOktaSetting } = useSelector(
    (state: RootStore) => state.settings
  )

  useEffect(() => {
    if (oktaAuth !== null) return

    if (!selectedOktaSetting) return

    if (selectedOktaSetting.idp_name === AZURE_IDP_NAME) return

    const config = new OktaAuth({
      issuer: selectedOktaSetting.domain,
      clientId: selectedOktaSetting.client_id,
    })

    setOktaAuth(config)
  }, [oktaAuth, selectedOktaSetting])

  const onAuthRequired = () => {
    history.push('/login')
  }

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', 'gerr'))
  }

  if (!oktaAuth) return <>{children}</>

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  )
}

const OktaProvider = connector(OktaProviderImpl)
export { OktaProvider }
