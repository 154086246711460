import {
  AddOrCutActionTypes,
  SET_ORIGINAL_CONSTRAINT_SUCCESS,
} from '../actions/SPOFlowActions/AddOrCutBudgetActionTypes'
import { RootStore } from './Store'

interface DefaultState {
  error: string
  loading: boolean
  originalConstraint: number | null
}

const initState: DefaultState = {
  error: '',
  loading: false,
  originalConstraint: null,
}

const addOrCutReducer = (
  state: DefaultState = initState,
  action: AddOrCutActionTypes
) => {
  switch (action.type) {
    case SET_ORIGINAL_CONSTRAINT_SUCCESS:
      return { ...state, originalConstraint: action.payload }
    default:
      return state
  }
}

export default addOrCutReducer
