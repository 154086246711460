import { memo } from 'react'
import background from '../../img/login-background.svg'

export const Background = memo(() => {
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      className="absolute inset-0 z-0"
    />
  )
})
