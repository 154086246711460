import moment from 'moment'
import { ComponentProps, useEffect, useState } from 'react'
import { Constraint, Plan } from '../../../../../types/PlanTypes'
import { Settings } from '../../../../../types/SettingTypes'
import { DEFAULT_DATE_FORMAT } from '../../../../../utils/constants'
import TimePeriodBudgetConstraints from '../../time-period-budget-constraints'

type TimePeriodBudgetConstraintsProps = ComponentProps<
  typeof TimePeriodBudgetConstraints
>

interface IUseConstraintsTimeForPlanProps {
  plan: any
  settings: Settings | null
  error: string | null
  handleBackCallback: () => void
  handleRegenerateCallback: (constraints?: Constraint[] | undefined) => void
}

export function useConstraintsTimeForPlan({
  plan,
  settings,
  error,
  handleBackCallback,
  handleRegenerateCallback,
}: IUseConstraintsTimeForPlanProps) {
  const [timeConstraintsProps, setTimeConstraintsProps] = useState<
    TimePeriodBudgetConstraintsProps | undefined
  >(undefined)

  const getCurrencyLabel = () => {
    const parsedPlan = { ...plan } as Plan

    if (!parsedPlan?.config?.options?.exchangeRates) {
      return ''
    }

    const defaultSymbol = parsedPlan?.config.options.exchangeRates.defaultSymbol
    const defaultCurrency =
      parsedPlan?.config.options.exchangeRates.defaultCurrency

    const defaultLabel = `${defaultSymbol} (${defaultCurrency})`

    if (!parsedPlan?.user_input?.defaultCurrency) {
      return defaultLabel
    }

    const symbol = plan?.user_input?.defaultCurrency?.symbol
    const currency = plan?.user_input?.defaultCurrency?.currency

    const userInputLabel = `${symbol} (${currency})`

    return userInputLabel || defaultLabel
  }

  const getConstraints = () => {
    if (!plan?.constraints || !plan?.grouping_levels) {
      return []
    }

    const hierarchyLength = Object.keys(plan?.grouping_levels).length

    const weeklyConstraints = plan?.constraints
      ?.filter(
        (constraint: Constraint) =>
          Object.keys(constraint.identifiers).length === hierarchyLength
      )
      ?.map((constraint: Constraint) => {
        const { values } = constraint
        const slicedValues = values.slice(1) // Removing first element because the first element of the values array is not used for time constraints

        return {
          ...constraint,
          values: slicedValues,
        }
      })

    return weeklyConstraints || []
  }

  const getObservations = () => {
    const parsedPlan = { ...plan } as Plan

    if (!parsedPlan?.config?.observations) {
      return []
    }

    const carryoverWeeks = plan?.options?.carryoverWeeks || 0

    const dateFormat = settings?.date_format || DEFAULT_DATE_FORMAT.LABEL

    const observationsForSelectedRange = parsedPlan.config.observations
      .slice(
        parsedPlan.observations_min,
        parsedPlan.observations_max + 1 - carryoverWeeks
      )
      ?.map((observation) => ({
        ...observation,
        // Plan observations come formatted from the backend, so we need to format them again
        label: moment(observation.label, dateFormat).format(
          DEFAULT_DATE_FORMAT.LABEL
        ),
      }))

    return observationsForSelectedRange || []
  }

  useEffect(() => {
    if (!plan) {
      setTimeConstraintsProps(undefined)
      return
    }

    const parsedPlan = { ...plan } as Plan

    const budget =
      parsedPlan.user_input?.optimisationType !== 'goal'
        ? parsedPlan.user_input?.overallConstraint
        : parsedPlan.optimised_spend

    const newProps: TimePeriodBudgetConstraintsProps = {
      budget: budget || 0,
      constraints: getConstraints(),
      currencyLabel: getCurrencyLabel(),
      groupingLevels: parsedPlan.config?.grouping_levels || [],
      handleBack: handleBackCallback,
      handleGeneratePlan: handleRegenerateCallback,
      observations: getObservations(),
      planGenerationType: 'edit',
      settings: settings,
      transformationOptions: plan?.transformations || [],
      generatePlanError: error,
      carryoverWeeks: plan?.options.carryoverWeeks
    }

    setTimeConstraintsProps(newProps)
  }, [plan, settings, error])

  return timeConstraintsProps
}
