import { FC } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Navbar, NavbarProps } from '@gain-theory/navbar'
import { ROUTES } from '../../Routes'
import { selectSessionUser } from '../../redux/reducers/SessionReducer'
import { RootStore } from '../../redux/reducers/Store'
import { EndSession } from '../../redux/actions/SessionActions'
import useModellingMenu from './use-modelling-menu'
import useSpoMenu from './use-spo-menu'
import useReportingMenu from './use-reporting-menu'
import useBfMenu from './use-bf-menu'
import useLaunchpadMenu from './use-launchpad-menu'
import { NavbarBottomActionItem, NavbarMenuItem } from './types'

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
})

const mapDispatchToProps = {
  onEndSession: EndSession,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const SideNavbar: FC<PropsFromRedux> = (props) => {
  const history = useHistory()

  const launchpadMenu = useLaunchpadMenu()
  const modellingMenu = useModellingMenu()
  const reportingMenu = useReportingMenu()
  const { spoMenu, inChannelSpoMenu } = useSpoMenu()
  const bfMenu = useBfMenu()

  const logOut = () => {
    props.onEndSession()
    window.location.pathname = ROUTES.LOGIN
  }

  const menu: NavbarProps['menu'] = [
    launchpadMenu as NavbarMenuItem,
    // modellingMenu as NavbarMenuItem, TODO: uncomment when modelling is ready
    reportingMenu as NavbarMenuItem,
    spoMenu as NavbarMenuItem,
    inChannelSpoMenu as NavbarMenuItem,
    // bfMenu as NavbarProps['menu'][number], TODO: uncomment when modelling is ready
  ]

  const adminMenu = {
    label: 'Admin',
    iconName: 'AdjustmentsVerticalIcon',
    route: ROUTES.ADMIN.INDEX,
  } as NavbarBottomActionItem

  const myAccountMenu = {
    label: 'My Account',
    iconName: 'IdentificationIcon',
  } as NavbarBottomActionItem

  const logoutMenu = {
    label: 'Logout',
    iconName: 'ArrowRightOnRectangleIcon',
    handleAction: logOut,
  } as NavbarBottomActionItem

  const bottomActions: NavbarProps['bottomActions'] = [
    ...(props.user && props.user.admin ? [adminMenu] : []),
    // myAccountMenu, TODO: uncomment when myAccount option is ready
    logoutMenu,
  ]

  return (
    <div className="h-screen sticky top-0 left-0 z-20">
      <Navbar
        persistExpandedState
        menu={menu}
        bottomActions={bottomActions}
        paidBadgeLabel="paid"
        changeRoute={(route: string) => history.push(route)}
        currentRoute={window.location.pathname}
      />
    </div>
  )
}

export default connector(SideNavbar)
