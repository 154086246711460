import { AgGrid, AgGridProps } from '@gain-theory/ag-grid'
import { Alert } from '@gain-theory/alert'
import { Button } from '@gain-theory/button'
import { FileUpload } from '@gain-theory/file-upload'
import { Icon } from '@gain-theory/icon'
import { IconButton } from '@gain-theory/icon-button'
import { Modal } from '@gain-theory/modal'
import { Typography } from '@gain-theory/typography'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import readXlsxFile from 'read-excel-file'
import {
  COST_EDIT_MODE,
  DISCARD_COST_CHANGES,
  EDITED_COSTS,
} from '../../../../redux/actions/PlanActionTypes'
import { AppState } from '../../../../redux/reducers/RootReducer'
import { useNotificationContext } from '../../../../shared/notification-provider'
import { EditWeeklyCosts } from '../../../../socket.io'
import { CostName, Observation } from '../../../../types/PlanTypes'
import { translations } from '../../../../utils/constants'
import { downloadToXLSX } from '../../../../utils/jsonToXLSX'
import { validateSheetName } from '../../../../utils/validateXLSX'
import ShowCostsModal from './ShowCostsModal'

interface OwnProps {
  planItem: any
  mediaAllocations: any
}

type Props = OwnProps

const WeeklyView: FC<Props> = ({ planItem, mediaAllocations }) => {
  const { toast } = useNotificationContext()

  const { user } = useSelector((state: AppState) => state.session)
  const { costGridData, costEditMode } = useSelector(
    (state: AppState) => state.planFilters
  )

  const { editedCosts } = useSelector((state: AppState) => state.plans)

  const { settings } = useSelector((state: AppState) => state.settings)

  const dispatch = useDispatch()
  const [showCostsModal, setShowCostsModal] = useState(false)
  const [showDiscardModal, setShowDiscardModal] = useState(false)
  const [showAlertText, setShowAlertText] = useState(true)
  const [file, setFile] = useState(null)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [selectedName, setSelectedName] = useState<CostName>({
    name: '',
    label: '',
  })

  const observations = planItem.config.observations.slice(
    planItem.observations_min,
    planItem.observations_max + 1
  )

  const currencyRateIndex =
    planItem!.options.exchangeRates.currencies.findIndex(
      (x: any) => x === planItem!.options.exchangeRates.defaultCurrency
    )
  const currencyRate: number =
    planItem!.options.exchangeRates.rates[currencyRateIndex]

  const showCosts = () => {
    setShowCostsModal(!showCostsModal)
  }

  const labelRender = (props: any) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value

    return (
      <div>
        <div className="float-left">{cellValue}</div>
        <Icon
          className="cursor-pointer float-right"
          onClick={() => setShowCostsModal(!showCostsModal)}
          color="neutral-gray-1000"
          name="EllipsisVerticalIcon"
        />
      </div>
    )
  }

  const columnDefs: AgGridProps['columnDefs'] = [
    {
      field: 'label',
      editable: false,
      lockPosition: 'left',
      filter: true,
      minWidth: 130,
      cellStyle: () => ({
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.04em',
        textAlign: 'right',
      }),
      cellRenderer: labelRender,
      autoSizeAllColumns: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
    },
    ...observations.map((obs: any) => ({
      valueGetter: (params: any) => {
        return params.data[obs.key] * currencyRate
      },
      valueSetter: (params: any) => {
        const newValInt = parseFloat(params.newValue)
        const valueChanged = params.data[obs.key] !== newValInt
        if (valueChanged) {
          params.data[obs.key] = newValInt / currencyRate
        }
        return valueChanged
      },
      headerName: obs.label,
      editable: costEditMode,
      sortable: false,
      type: 'numericColumn',
      autoSizeAllColumns: true,
      cellStyle: () => ({
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.04em',
        textAlign: 'right',
      }),
      suppressMenu: true,
    })),
  ]

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (editedCosts) {
      toast(
        'success',
        'Costs changed successfully.',
        'Your costs have been changed.'
      )
      dispatch({ type: EDITED_COSTS })
    }
  }, [editedCosts])

  useEffect(() => {
    if (file) {
      uploadCostsFn()
    }
  }, [file])

  useEffect(() => {
    if (uploading) {
      setUploading(false)
    }
  }, [uploading])

  const uploadCostsFn = async () => {
    let validated: Promise<boolean>
    let validDetail = true
    if (file) {
      validated = validateSheetName(file, 'Sheet1')
      if ((await validated) === false) {
        toast(
          'warning',
          'Upload failed.',
          'The uploaded file does not contain the required “Sheet 1”.'
        )
        validDetail = false
        return
      }
      await readXlsxFile(file, { sheet: 'Sheet1' }).then((rows) => {
        const headerRows: any = rows.filter((x, ind) => {
          if (ind !== 0) {
            return x
          }
        })
        if (rows[0].length !== observations.length + 1) {
          toast(
            'warning',
            'Upload failed.',
            'The number of weeks in the selected file do not match with the number of weeks in the plan.'
          )
          validDetail = false
          return
        }
        for (let i = 1; i < rows[0].length; i++) {
          if (
            observations
              .map((x: Observation) => x.label)
              .indexOf(rows[0][i]) === -1
          ) {
            toast(
              'warning',
              'Upload failed.',
              'The dates in the selected file do not match with the dates in the plan.'
            )
            validDetail = false
            return
          }
        }
        if (headerRows.length !== costGridData.length) {
          toast(
            'warning',
            'Upload failed.',
            'The number of items in column A of the selected file do not match with the number of items in the spend tab.'
          )
          validDetail = false
          return
        }
        for (let row of headerRows) {
          if (costGridData.map((x: any) => x.label).indexOf(row[0]) === -1) {
            toast(
              'warning',
              'Upload failed.',
              'The names of the items in column A of the selected file do not match with the items in the spend tab.'
            )
            validDetail = false
            return
          }
        }
        for (let row of headerRows) {
          for (let i = 1; i < row.length; i++) {
            if (!parseFloat(row[i])) {
              toast(
                'warning',
                'Upload failed.',
                'Invalid characters have been found in the values section of the selected file. Values should only contain “.” and numbers.'
              )
              validDetail = false
              return
            }
          }
        }
        costGridData.map((gridRow) => {
          for (let row of headerRows) {
            if (row[0] === gridRow.label) {
              for (let i = 1; i < row.length; i++) {
                gridRow[observations[i - 1].key] = row[i]
              }
            }
          }
        })
      })
      setUploadingFile(false)
      setFile(null)
      if ((await validated) && validDetail) {
        dispatch({ type: COST_EDIT_MODE, payload: true })
        setUploading(true)
        toast(
          'success',
          'Costs uploaded successfully.',
          'All file was uploaded.'
        )
      } else {
        toast(
          'warning',
          'Upload failed.',
          'The uploaded file is invalid.'
        )
      }
    }
  }

  const toggleEditState = () => {
    if (costEditMode) {
      setShowDiscardModal(true)
    }
    dispatch({ type: COST_EDIT_MODE, payload: !costEditMode })

    setUploadingFile(false)
  }

  const saveChanges = () => {
    if (user) {
      let costs: number[][] = []
      for (let row of costGridData) {
        const { label, name, ...costsVals } = row
        const costValues: number[] = Object.values(costsVals)
        costs = [...costs, costValues]
      }

      let newTransforms = mediaAllocations.map((x: any) => x.transformations)

      EditWeeklyCosts(
        user,
        planItem.id,
        costs,
        newTransforms,
        planItem.user_cpms,
        settings?.date_format
      )
    }
    dispatch({ type: COST_EDIT_MODE, payload: !costEditMode })
  }

  const downloadGridToExcel = () => {
    let columns = [
      {
        label: 'Media',
        value: 'label',
      },
    ]
    observations.map((obs: any) => {
      const newCol = {
        label: obs.label,
        value: obs.key,
      }
      columns = [...columns, newCol]
    })

    let downloadData = [
      {
        sheet: 'Sheet1',
        columns: columns,
        content: costGridData,
      },
    ]

    let currencyGridData: any[] = []
    costGridData.forEach((row) => {
      const keys = Object.keys(row)
      for (let key of keys) {
        if (typeof row[key] === 'number') {
          row[key] = row[key] * currencyRate
        }
      }
      currencyGridData = [...currencyGridData, row]
    })

    downloadData[0].content = currencyGridData

    const name = 'costs'
    downloadToXLSX(downloadData, planItem.id, name)
    toast(
      'success',
      'Downloaded successfully.',
      'The file has downloaded.'
    )
  }

  const scaleCostsFn = (value: number, media: string) => {
    const multiplier = 1 + value / 100
    let newGridData = costGridData
    let affectedRowIndex = newGridData.findIndex((x) => x.name === media)
    for (let obs of observations) {
      newGridData[affectedRowIndex][obs.key] =
        newGridData[affectedRowIndex][obs.key] * multiplier
    }
    dispatch({ type: COST_EDIT_MODE, payload: true })
    toast(
      'success',
      'Costs scaled successfully.',
      'All changes were applied.'
    )
  }

  const clickedCell = (e: any) => {
    if (e.column.colId === 'label') {
      setSelectedName({ name: e.data.name, label: e.data.label })
    }
  }

  const uploadingFileFn = () => {
    setUploadingFile(!uploadingFile)
    dispatch({ type: COST_EDIT_MODE, payload: false })
  }

  const toggleDiscardModal = () => {
    dispatch({ type: COST_EDIT_MODE, payload: true })
    setShowDiscardModal(!showDiscardModal)
  }

  const agGridProps: AgGridProps = {
    pagination: true,
    resizable: true,
    sortable: true,
    suppressDragLeaveHidesColumns: true,
    defaultColDef: {
      minWidth: 100,
    },
    onCellClicked: clickedCell,
    paginationPageSize: 15,
    rowData: costGridData,
    columnDefs: columnDefs,
    generalMenuItems: ['autoSizeThis', 'autoSizeAll', 'resetColumns'],
    paginationProps: {
      perPage: 10,
      pageSizeOptions: [10, 20, 50, 100],
      translations: {
        OF: 'of',
        TO: 'to',
        PAGE: 'Page',
        ROWS_PER_PAGE: 'Rows per page',
        ROWS: 'Rows',
      },
    },
  }

  const modalFooter: ComponentProps<typeof Modal>['footer'] = {
    primary: {
      label: 'Discard Changes',
      leadingIconLibrary: 'heroicon-outline',
      leadingIconName: 'TrashIcon',
      onClick: () => {
        setShowDiscardModal(!showDiscardModal)
        dispatch({ type: DISCARD_COST_CHANGES })
      },
      variant: 'destructive',
    },
    secondary: {
      label: 'Go Back',
      onClick: () => toggleDiscardModal(),
      leadingIconLibrary: 'heroicon-outline',
      leadingIconName: 'ArrowLeftIcon',
    },
  }

  return (
    <div className="relative">
      <section className="bg-white rounded-lg" style={{ padding: '32px' }}>
        <div className="flex flex-row mb-4">
          <Typography.H6 className="w-10/12 p-2">Average costs</Typography.H6>
          <IconButton
            style={{ marginBottom: 'auto' }}
            title="Download"
            iconName="ArrowDownTrayIcon"
            onClick={downloadGridToExcel}
            className="mx-2"
          />
          <IconButton
            style={{ marginBottom: 'auto' }}
            title="Upload"
            iconName="CloudArrowUpIcon"
            variant={uploadingFile ? 'brand-primary' : 'primary'}
            onClick={uploadingFileFn}
            className="mx-2"
          />
          <IconButton
            style={{ marginBottom: 'auto' }}
            title="Edit"
            iconName="PencilSquareIcon"
            variant={costEditMode ? 'brand-primary' : 'primary'}
            onClick={toggleEditState}
            className="mx-2"
          />
        </div>
        <div className="mb-2">
          <Alert
            title="Filters impact costs"
            description={`All displayed costs are averages, 
        and making modifications to a single ${planItem.config.grouping_levels[
          planItem.config.grouping_levels.length - 1
        ].label.toLowerCase()} (or named dimension) 
        may impact other levels of the plan. Click on the corresponding ${planItem.config.grouping_levels[0].label.toLowerCase()} and lower level cards to 
        filter to a specific combination for which you can edit costs more granularly.`}
            variant="info"
            show={showAlertText}
            onClose={() => setShowAlertText(false)}
          />
        </div>
        {costEditMode && (
          <div className="flex flex-row my-4">
            <div className="w-5/10">
              <Alert title="Edit mode on" variant="info" show={true} />
            </div>
            <div style={{ width: '570px' }} />
            <Button
              variant="secondary"
              onClick={toggleEditState}
              leadingIcon={<Icon color="brand-primary-main" name="XMarkIcon" />}
              className={'mr-2'}
            >
              Discard
            </Button>
            <Button
              onClick={saveChanges}
              leadingIcon={
                <Icon color="neutral-white" name="save" library="svg" />
              }
            >
              Save Changes
            </Button>
          </div>
        )}
        {uploadingFile && (
          <div className="mb-4">
            <FileUpload
              fileType={'xlsx'}
              selectedFile={file}
              translations={translations}
              onFileDrop={(file: any) => setFile(file)}
              onDeleteFile={() => setFile(null)}
            />
          </div>
        )}
        <ShowCostsModal
          open={showCostsModal}
          showCosts={showCosts}
          scaleCosts={scaleCostsFn}
          costData={selectedName}
        />
        {showDiscardModal && (
          <Modal
            open={showDiscardModal}
            onClose={toggleDiscardModal}
            header={{ text: 'Discard changes?', icon: 'warning' }}
            footer={modalFooter}
          >
            <Typography.Body1 className="mb-2">{`The changes will not be applied. This action can't be undone.`}</Typography.Body1>
          </Modal>
        )}
        <AgGrid {...agGridProps} />
      </section>
    </div>
  )
}

export default WeeklyView
