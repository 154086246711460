import React, { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../../shared/MainLayout'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { Sankey, Tooltip } from 'recharts'
import Node from './SankeyNode'
import SankeyLink from './SankeyLink'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import { IMR } from '../../../types/MarketingDiagnosticsTypes'
import { GetMarketingDiagnostics } from '../../../redux/actions/MarketingDiagnosticsActions'
import { ROUTES } from '../../../Routes'
import { Breadcrumbs } from '@gain-theory/breadcrumbs'

interface OwnProps {}

type Props = OwnProps

const SankeyView: FC<Props> = (props) => {
  const [imrData, setIMRData] = useState<IMR>()

  const dispatch = useDispatch()
  const { marketingDiagnostics } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  useEffect(() => {
    dispatch(GetMarketingDiagnostics())
  }, [])

  useEffect(() => {
    if (marketingDiagnostics) {
      let links = marketingDiagnostics.imr.links.map((link) => {
        return { ...link, value: link.value }
      })
      const imrData = { ...marketingDiagnostics.imr, links }
      setIMRData(imrData)
    }
  }, [marketingDiagnostics])

  if (!marketingDiagnostics || marketingDiagnostics.id === undefined) {
    return <LoadingModal />
  }

  return (
    <>
      {/* Breadcrumb */}
      <div className="mb-5">
        <Breadcrumbs
          breadcrumbsConfig={[
            {
              href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
              label: 'Reporting',
              divider: true,
            },
            { label: 'IMR' },
          ]}
        />
      </div>
      <div className="flex flex-row text-lg text-gray-500">
        <h2>IMR</h2>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col mb-10 mt-4">
          {imrData && (
            <Sankey
              width={1100}
              height={1100}
              margin={{ top: 20, bottom: 20 }}
              data={imrData}
              nodeWidth={10}
              nodePadding={40}
              linkCurvature={0.61}
              iterations={64}
              link={<SankeyLink />}
              node={<Node containerWidth={960} />}
            >
              <defs>
                <linearGradient id={'linkGradient'}>
                  <stop offset="0%" stopColor="rgba(0, 136, 254, 0.5)" />
                  <stop offset="100%" stopColor="rgba(0, 197, 159, 0.3)" />
                </linearGradient>
              </defs>
              <Tooltip
                /* @ts-ignore */
                formatter={(value: number | bigint) =>
                  new Intl.NumberFormat('en').format(value)
                }
              />
            </Sankey>
          )}
        </div>
      </div>
    </>
  )
}

export default MainLayout(SankeyView)
