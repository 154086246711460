import React, { FC, useEffect, useState } from 'react'
import { RootStore } from '../../redux/reducers/Store'
import {
  selectPlanItem,
  selectPlanSummaryList,
  selectShowSharePlanModal,
  selectIsLoading
} from '../../redux/reducers/PlanReducer'
import {
  GetPlanItem,
  ToggleCarryover,
  ToggleHalo,
} from '../../redux/actions/PlanActions'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import PlanSummary from '../../shared/plan-view/PlanSummary'
import HighLevelImpact from './plan-output/HighLevelImpact'
import MediaChannelAllocation from './plan-output/MediaChannelAllocation'
import { AppState } from '../../redux/reducers/RootReducer'
import EditableAttribute from '../../utils/EditableAttribute'
import { selectSessionUser } from '../../redux/reducers/SessionReducer'
import { EditSPOPlanName } from '../../socket.io'
import PlanSummaryListItemActionsMenu from '../../shared/PlanSummaryListItemActionsMenu'
import {
  GroupingLevel,
  GroupingLevelValue,
  Identifier,
  IPlanSummary,
  Transformation,
} from '../../types/PlanTypes'
import { AllocationComponent } from './plan-output/AllocationComponent'
import { GtiToggleButton } from '../../shared/GTIToggleButton'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import _ from 'lodash'
import SharePlanModal from './SharePlanModal'
import PlanItemActionsMenu from '../../shared/PlanItemActionsMenu'

const mapState = (state: RootStore) => ({
  plan: selectPlanItem(state),
  user: selectSessionUser(state),
  planSummaryList: selectPlanSummaryList(state),
  showModal: selectShowSharePlanModal(state),
  isLoading: selectIsLoading(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  editSPOPlanName: EditSPOPlanName,
  toggleCarryover: ToggleCarryover,
  toggleHalo: ToggleHalo,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanOutlookInChannel: FC<PropsFromRedux> = (props) => {
  const { id }: { id: string } = useParams()

  const { planItem } = useSelector((state: AppState) => state.plans)
  const { settings } = useSelector((state: AppState) => state.settings)
  useEffect(() => {
    props.onGetPlan(id)
  }, [])

  const groupingLevelKeys = planItem ? Object.keys(planItem.grouping_levels): []
  const bottomLevel = groupingLevelKeys.length > 0 ? groupingLevelKeys[groupingLevelKeys.length - 1] : ""

  const editPlanName = (name: string) => {
    if (props.plan && props.user) {
      props.editSPOPlanName(props.plan.id, props.user, name)
    }
  }

  const toggleCarryover = (e: boolean) => {
    if (props.plan) {
      const plan = {
        ...props.plan,
        carryover: !props.plan.carryover,
      }
      props.toggleCarryover(props.plan.id, plan)
    }
  }

  const toggleHalo = (e: boolean) => {
    if (props.plan) {
      const plan = {
        ...props.plan,
        parentChild: !props.plan.parentChild,
      }
      props.toggleHalo(props.plan.id, plan)
    }
  }

  const showHaloToggle = props.plan.config.transformations.some(
    (t: any) => t.is_halo === true
  )

  return (
    <>
      {props.showModal.show && <SharePlanModal />}
      <div className="w-full flex flex-col space-y-8 ">
      {(!planItem || props.isLoading) &&
          <LoadingModal></LoadingModal>
        }

        <div className="flex flex-row">
          <div className="flex flex-1 flex-col">
            <EditableAttribute
              showEditButton={true}
              isLocked={!!props.plan.locked_at}
              onSave={editPlanName}
              defaultValue={props.plan?.name || ''}
              className="text-xl text-gray-500 mb-6"
            >
              <div className="flex flex-1 flex-row justify-start">
                <span className="text-xl">
                  {props.plan?.name || `Plan  ${id}`}
                </span>
                <span className="mt-2">{props.plan.locked_at && '🔒'}</span>
              </div>
            </EditableAttribute>
          </div>
          <div className="flex flex-1 flex-row space-x-2 justify-end">
            {props.plan && props.plan.options.carryoverWeeks > 0 && (
              <div className="flex flex-row h-auto ">
                <GtiToggleButton
                  value={planItem.carryover}
                  onToggle={toggleCarryover}
                  label="Carryover impact"
                />
              </div>
            )}
            {showHaloToggle && (
              <div className="flex flex-row h-auto">
                <GtiToggleButton
                  value={planItem.parentChild}
                  onToggle={toggleHalo}
                  label="Halo impact"
                />
              </div>
            )}
            {props.plan && (
              <PlanItemActionsMenu
                fromPlan={true}
                planItem={props.plan}
                planSummaryList={props.planSummaryList}
              />
            )}
          </div>
        </div>
        {props.plan && settings ? (
          <>
            <PlanSummary plan={props.plan} />
            <div className="w-full flex flex-col space-y-10">
              <HighLevelImpact />
              <AllocationComponent
                name={'media'}
                title={settings?.media_name || "'"}
                children={<MediaChannelAllocation name={bottomLevel} inChannel={true} />}
                isViewVisible={!settings?.hide_views.media}
              />
              <div className="text-gtDarkGray text-xxs text-right">{`Config ID: ${props.plan.config.id}`}</div>
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

export default connector(PlanOutlookInChannel)
