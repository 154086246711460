import readXlsxFile from 'read-excel-file'
import api from '../api'

export const readUsersFile = (file) => {
    const reader = new FileReader();
    reader.onload = async e => {
        let users = [];
        await readXlsxFile(file).then(rows => {
            rows.forEach(row => {
                users.push({
                    username: row[0]
                });
            });
        });
        api.Session.createUsersBulk(users);
    };
    reader.readAsText(file);
}