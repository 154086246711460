import React, { FC } from 'react'

interface OwnProps {}

type Props = OwnProps

const TimelineIcon: FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="37"
      viewBox="0 0 8 37"
      className='text-brand-primary-main'
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <circle cx="3" cy="3" r="3" />
        <path strokeLinecap="square" d="M3 7v22" />
        <circle cx="3" cy="32" r="3" />
      </g>
    </svg>
  )
}

export default TimelineIcon
