import { AgGridProps } from '@gain-theory/ag-grid'

export enum Errors {
  FILE_READER_ERROR,
  NO_DATA,
  XLSX_PARSE,
  DATA_TYPE,
  HEADER_EMPTY_COLUMN,
  HEADER_MATCHING_HIERARCHY,
  HEADER_MATCHING_TYPE_COLUMN,
  HEADER_MATCHING_STATUS_COLUMN,
  HEADER_MATCHING_OBSERVATION_COLUMN,
  DIMENSION_NOT_FOUND,
  INVALID_CURVE,
  CURVE_NOT_FOUND,
  DUPLICATE_CURVES,
  INVALID_CURVE_COMBINATION,
  TYPE_NOT_FOUND,
  STATUS_NOT_FOUND,
  GENERIC_ERROR,
  EMPTY_ROW,
  ZERO_INDEX_TOTALS,
}

export type TableColumnType = {
  field: string
  headerName: string
}

export type TableRowType = {
  [key: string]: string | number
}

export type HierarchyOptionType = {
  levelKey: string
  key: string
  label: string
  parents: string[][]
}

export type RowDataType = NonNullable<AgGridProps['rowData']>[number]

export type TimeConstraintErrorType = {
  errorType: Errors
  replacers: string[]
}
