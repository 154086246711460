import { Allocation, Total } from './PlanTypes'

export interface MediaChannelAllocationBudget {
  [key: string]: {
    name: string
    mixedTotal: number
    optimisedTotal: number
    outputTotal: number
  }
}

export interface MediaBarBudget {
  label: string
  sTransformation: {
    calculated_spend: number
    optimised_spend: number
    calculated_totals: Total
    optimised_totals: Total
  }
  transformations: Allocation[]
}

export const MEDIA_CHANNEL_ICON = <const>{
  television: "television",
  tv_placement: "tv_placement",
  adsmart: "adsmart",
  drtv: "drtv",
  satellite: "satellite",
  cable: "cable",
  tv: "tv",
  ctv: "ctv",
  regional_tv: "regional_tv",
  metro_tv: "metro_tv",
  print: "print",
  press: "press",
  magazines: "magazines",
  newspapers: "newspapers",
  digital: "digital",
  remarketing: "remarketing",
  display: "display",
  web_display: "web_display",
  ppc: "ppc",
  google: "google",
  sem: "sem",
  amobee: "amobee",
  bing: "bing",
  dsp: "dsp",
  programmatic: "programmatic",
  generic_ppc: "generic_ppc",
  outdoor: "outdoor",
  ooh: "ooh",
  out_of_home: "out_of_home",
  sponsorship: "sponsorship",
  sponsor: "sponsor",
  influencer: "influencer",
  partnership: "partnership",
  kol: "kol",
  key_opinion_leader: "key_opinion_leader",
  coupons: "coupons",
  target: "target",
  sale: "sale",
  amazon: "amazon",
  retail: "retail",
  shopping: "shopping",
  alibaba: "alibaba",
  walmart: "walmart",
  gaming: "gaming",
  audio: "audio",
  podcasts: "podcasts",
  spotify: "spotify",
  pandora: "pandora",
  pr: "pr",
  public_relations: "public_relations",
  event: "event",
  roadshow: "roadshow",
  press_release: "press_release",
  radio: "radio",
  digital_audio: "digital_audio",
  terrestrial: "terrestrial",
  video: "video",
  fep: "fep",
  digital_video: "digital_video",
  vod: "vod",
  tik_tok: "tik_tok",
  tiktok: "tiktok",
  olv: "olv",
  ott: "ott",
  cinema: "cinema",
  yt: "yt",
  youtube: "youtube",
  movie: "movie",
  search: "search",
  paid_search: "paid_search",
  apple_search: "apple_search",
  messages: "messages",
  social: "social",
  facebook: "facebook",
  twitter: "twitter",
  snapchat: "snapchat",
  instagram: "instagram",
  chat: "chat",
  whatsapp: "whatsapp",
  imessage: "imessage",
  groupme: "groupme",
  ig: "ig",
  fb: "fb",
  pinterest: "pinterest",
  direct_mail: "direct_mail",
  mail: "mail",
  affiliate: "affiliate",
  mobile: "mobile",
  phone: "phone",
  media: "media"
}
