import React, { FC, useEffect, useState } from 'react'
import { ComparisonFilters, ComparisonLevelOption } from '../../../types/PlanComparisonTypes'


interface OwnProps {
  showFilterModal: () => void
  allLevelOptions: ComparisonLevelOption[]
  level: string
  filters: ComparisonFilters
  updateFilters : (selected: string[], level: string) => void
  headers: string[]
}

type Props = OwnProps
    
const FilterComparisonModal: FC<Props> = (props) => {
    const [selected, setSelected] = useState<string[]>([])
    const [options, setOptions] = useState<ComparisonLevelOption[]>([])
    const [headerTitle, setHeaderTitle] = useState("")

    useEffect(() => {
        if(props.filters[props.level].length > 0){
            setSelected(props.filters[props.level])
        }
        const levels = props.headers
        const index = levels.indexOf(props.level)
        setHeaderTitle(props.headers[index])
        setOptions(props.allLevelOptions.filter(x => x.level === props.level))
    }, [])

    const selectOption = (e: any) => {
        let newSelectedKpis: string[] = []
        if (selected.indexOf(e.target.value) === -1) {
          newSelectedKpis = [...selected, e.target.value]
        } else {
          newSelectedKpis = selected.filter((x) => x !== e.target.value)
        }
        setSelected(newSelectedKpis)
      }

    const updateFilters = () => {
        props.updateFilters(selected, props.level)
    }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 h-3/4 my-6 mx-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h1 className="text-gray-500 my-6 text-xl w-3/4 uppercase">{headerTitle}</h1>
              <button
                className="p-1key ml-auto border-0 text-gray-500 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.showFilterModal()}
              >
                <span className="text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto overflow-y-auto">
              <div
                className={`grid grid-flow-rows grid-cols-2 gap-4 p-4 max-h-96 overflow-auto justify-center`}
              >
                {options.map((option, index) => {
                  let checked = selected.indexOf(option.key) !== -1
                  return (
                    <div key={index} className="check-box">
                      <label className="">
                        <input
                          type={'checkbox'}
                          onChange={selectOption}
                          checked={checked}
                          value={option.key}
                        />
                        <div className="option">{option.label}</div>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
                onClick={() => props.showFilterModal()}
              >
                Close
              </button>
              <button
                className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 ml-5 mt-4"
                onClick={updateFilters}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default FilterComparisonModal
