import React, { FC } from 'react'

interface OwnProps {
  className: string
  type: 'button'
  onClickHandler: () => void
}

type Props = OwnProps

const GenericButton: FC<Props> = ({
  className,
  type,
  onClickHandler,
  children,
}) => {
  return (
    <button className={className} type={type} onClick={onClickHandler}>
      {children}
    </button>
  )
}

export default GenericButton
