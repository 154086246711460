import { Typography } from '@gain-theory/typography'
import { Children, memo } from 'react'
import { WelcomeBackground } from '../assets'
import { FilterChip } from './filter-chip'
import { useWelcomeBg } from './hooks'
import { ProductCard } from './product-card'
import { WelcomeProps } from './types'

const LAUNCHPAD_WELCOME_MESSAGE = 'Hello! Welcome.'
const LAUNCHPAD_WELCOME_MESSAGE_DESCRIPTION =
  "Explore GTi's solutions by clicking on a card below"

export const Welcome = memo(
  ({
    id,
    chips,
    selectedChip,
    selectedCard,
    cards,
    onChipClick,
    onCardClick,
  }: WelcomeProps) => {
    const { bgStyle, rootRef } = useWelcomeBg()

    const Background = (
      <div
        style={bgStyle}
        className="block absolute min-w-[1280px] min-h-[782px] m-auto order-0 z-0 bottom-0 overflow-hidden bg-contain transition-all duration-100 ease-linear"
      >
        <WelcomeBackground className="fill-brand-primary-main" />
      </div>
    )

    const SectionTitle = (
      <div className="col-span-full flex flex-col gap-sm items-center justify-center z-10">
        <Typography.H4 className="text-text-white">
          {LAUNCHPAD_WELCOME_MESSAGE}
        </Typography.H4>
        <Typography.Subtitle1 className="text-text-white">
          {LAUNCHPAD_WELCOME_MESSAGE_DESCRIPTION}
        </Typography.Subtitle1>
      </div>
    )

    const FilterChips = (
      <div className="col-span-full xl:col-start-2 xl:col-end-10 flex flex-row flex-wrap gap-md items-stretch justify-center">
        {Children.toArray(
          chips.map((chip) => (
            <FilterChip
              key={chip.id}
              selected={chip.id === selectedChip}
              chip={chip}
              onClick={onChipClick}
            />
          ))
        )}
      </div>
    )

    const ProductCards = (
      <div className="col-span-full xl:col-start-2 xl:col-end-10 flex flex-row flex-wrap gap-md items-stretch justify-center transition-all duration-300 ease-in-out">
        {Children.toArray(
          cards.map((card) => (
            <ProductCard
              key={card.id}
              card={card}
              filterChips={chips}
              show={selectedChip === 'all' || card.chips.includes(selectedChip)}
              selected={card.id === selectedCard}
              onClick={onCardClick}
            />
          ))
        )}
      </div>
    )

    return (
      <div
        id={id}
        ref={rootRef}
        className="col-span-full grid grid-cols-6 xl:grid-cols-12 gap-md relative py-xxxxxl h-max auto-row-min"
      >
        {Background}
        {SectionTitle}
        <div className="h-fit mx-xxl col-span-full xl:col-span-10 xl:col-start-2 grid grid-cols-6 xl:grid-cols-10 auto-row-min gap-x-md gap-y-xxl px-xl py-xxxxl rounded-md bg-neutral-gray-1100 bg-opacity-50 shadow-main z-10 transition-all duration-300 ease-in-out">
          {FilterChips}
          {ProductCards}
        </div>
      </div>
    )
  }
)
