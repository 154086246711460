import {
  IComparisonData,
  PlanComparisons,
} from '../../types/PlanComparisonTypes'
import {
  PlanComparisonActionTypes,
  PLAN_COMPARISON_DATA,
  PLAN_GET_COMPARISONS_ATTEMPT,
  PLAN_GET_COMPARISONS_BY_ID_SUCCESS,
  PLAN_GET_COMPARISONS_SUCCESS,
  PLAN_SAVE_COMPARISONS_SUCCESS,
  UPDATE_PLAN_COMPARISON,
} from '../actions/PlanComparisonActionTypes'
import { RootStore } from './Store'

interface IDefaultState {
  loadingComparisons: boolean
  planComparisons: PlanComparisons[] | null
  createdId: string | null
  comparisonData: IComparisonData | null
  updateComparison: boolean
  dataLoaded: boolean
}

const defaultState: IDefaultState = {
  loadingComparisons: false,
  planComparisons: null,
  createdId: null,
  comparisonData: null,
  updateComparison: true,
  dataLoaded: false
}

const planComparisonsReducer = (
  state: IDefaultState = defaultState,
  action: PlanComparisonActionTypes
) => {
  switch (action.type) {
    case PLAN_GET_COMPARISONS_ATTEMPT:
      return {
        ...state,
        loadingComparisons: true,
        dataLoaded: false
      }
    case PLAN_GET_COMPARISONS_SUCCESS:
      return {
        ...state,
        loadingComparisons: false,
        planComparisons: action.payload,
        createdId: null,
        updateComparison: false,
      }
    case PLAN_SAVE_COMPARISONS_SUCCESS:
      return {
        ...state,
        createdId: action.payload.id,
        updateComparison: false,
      }
    case PLAN_COMPARISON_DATA:
      return {
        ...state,
        comparisonData: action.payload,
        updateComparison: false,
        dataLoaded: true
      }
    case UPDATE_PLAN_COMPARISON:
      return {
        ...state,
        updateComparison: true,
      }
    default:
      return state
  }
}

export const selectPlanComparisons = (state: RootStore) => {
  return state.planComparisons.planComparisons
}

export const selectCreatedId = (state: RootStore) => {
  return state.planComparisons.createdId
}

export const selectComparisonData = (state: RootStore) => {
  return state.planComparisons.comparisonData
}

export const selectUpdateComparison = (state: RootStore) => {
  return state.planComparisons.updateComparison
}

export const selectComparisonLoaded = (state: RootStore) => {
  return state.planComparisons.dataLoaded
}

export const selectLoadingComparisons = (state: RootStore) => {
  return state.planComparisons.loadingComparisons
}

export default planComparisonsReducer
