import { AgGridProps, AgGridReact } from '@gain-theory/ag-grid'
import _ from 'lodash'
import { RefObject, useEffect, useState } from 'react'
import {
  GroupingLevel,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { RowDataType, TableColumnType, TableRowType } from '../../types'
import { useColumnDefs } from './use-column-defs'
import { useHierarchyOptions } from './use-hierarchy-options'

interface IUseTableEditProps {
  gridRef: RefObject<AgGridReact>
  rowData: TableRowType[]
  columns: TableColumnType[]
  groupingLevels: GroupingLevel[]
  transformationOptions: Transformation[]
  numberFormat: string | undefined
}

export const useTableEdit = ({
  gridRef,
  rowData,
  columns,
  groupingLevels,
  transformationOptions,
  numberFormat,
}: IUseTableEditProps) => {
  const dimensionLevelsKeys = groupingLevels.map((level) => level.key)

  const [localRowData, setLocalRowData] = useState<RowDataType[]>([])

  const hierarchyOptions = useHierarchyOptions({
    groupingLevels,
    transformationOptions,
  })

  const columnDefs = useColumnDefs({
    columns,
    groupingLevels,
    hierarchyOptions,
    numberFormat,
  })

  const updateLocalRowData = (newData: RowDataType[]) => {
    setLocalRowData(newData)
  }

  const handleEditCell: AgGridProps['onCellValueChanged'] = (event) => {
    const { rowIndex, colDef, newValue } = event
    const colField = colDef.field || ''

    if (rowIndex === null || !colDef) return

    const isDimensionLevelColumn = dimensionLevelsKeys.includes(colField)

    const newLocalRowData = [...localRowData]
    newLocalRowData[rowIndex][colField] = newValue

    const rowNode = event.node

    if (isDimensionLevelColumn) {
      const nextLevels = dimensionLevelsKeys.slice(
        dimensionLevelsKeys.indexOf(colField) + 1
      )

      nextLevels.forEach((level) => {
        newLocalRowData[rowIndex][level] = ''
      })

      rowNode.data = newLocalRowData[rowIndex]
    }

    setLocalRowData(newLocalRowData)

    gridRef.current?.api?.refreshCells({
      rowNodes: [rowNode],
      force: true,
    })
  }

  useEffect(() => {
    const newData = _.cloneDeep(rowData)
    setLocalRowData(newData)
  }, [rowData])

  return {
    columnDefs,
    localRowData,
    updateLocalRowData,
    handleEditCell,
  }
}
