interface IFormatNumberStringProps {
  value: string
  decimalPlaces?: number
  decimalSeparator?: string
  thousandSeparator?: string
}

export function formatNumberString({
  value,
  decimalPlaces,
  decimalSeparator = '.',
  thousandSeparator = ',',
}: IFormatNumberStringProps) {
  const parts = value ? value.split(decimalSeparator) : ['']

  const integerPart = parts[0].replace(thousandSeparator, '')

  const parsedValue =
    parts.length === 1
      ? Number(integerPart)
      : Number(integerPart + '.' + parts[1])

  if (isNaN(parsedValue)) return ''

  const formattedValue = decimalPlaces
    ? parsedValue.toFixed(decimalPlaces)
    : parsedValue.toString()

  const formattedParts = formattedValue.split('.')

  const integerFormattedPart = formattedParts[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  )

  const formattedNumber =
    formattedParts.length === 1
      ? integerFormattedPart
      : integerFormattedPart + decimalSeparator + formattedParts[1]

  return formattedNumber
}
