import { Dispatch } from 'redux'
import {
  CONFIG_GET_ALL_ATTEMPT,
  CONFIG_GET_ALL_FAIL,
  CONFIG_GET_ALL_SUCCESS,
  ConfigActionTypes,
  CONFIG_GET_BY_ID_ATTEMPT,
  CONFIG_GET_BY_ID_SUCCESS,
  CONFIG_GET_BY_ID_FAIL,
  CREATE_APP_CONFIG_ATTEMPT,
  CREATE_APP_CONFIG_SUCCESS,
  CREATE_APP_CONFIG_FAIL,
  CREATE_MD_CONFIG_ATTEMPT,
  CREATE_MD_CONFIG_SUCCESS,
  CREATE_MD_CONFIG_FAIL,
  CREATE_SPO_CONFIG_ATTEMPT,
  CREATE_SPO_CONFIG_SUCCESS,
  CREATE_SPO_CONFIG_FAIL,
} from './ConfigActionTypes'
import api from '../../api'
import {
  AppConfig,
  CreateMarketingDiagnosticsConfig,
  ICreateSPOConfig,
  ICreateSPOConfigV2,
} from '../../types/ConfigTypes'
import { MarketingDiagnostics } from '../../types/MarketingDiagnosticsTypes'

export const GetConfigList =
  () => async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CONFIG_GET_ALL_ATTEMPT })
    try {
      const response = await api.Configs.getConfigs()
      dispatch({ type: CONFIG_GET_ALL_SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: CONFIG_GET_ALL_FAIL })
    }
  }

export const GetConfigById =
  (id: string) => async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CONFIG_GET_BY_ID_ATTEMPT })

    try {
      const response = await api.Configs.getConfigById(id)
      dispatch({
        type: CONFIG_GET_BY_ID_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({ type: CONFIG_GET_BY_ID_FAIL })
    }
  }

export const CreateAppConfig =
  (config: AppConfig) => async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CREATE_APP_CONFIG_ATTEMPT })

    try {
      const response = await api.Configs.createAppConfig(config)

      if (response.status === 201) {
        dispatch({
          type: CREATE_APP_CONFIG_SUCCESS,
        })
      } else {
        dispatch({ type: CREATE_APP_CONFIG_FAIL })
      }
    } catch (error) {
      dispatch({
        type: CREATE_APP_CONFIG_FAIL,
      })
    }
  }

export const CreateMDConfig =
  (config: CreateMarketingDiagnosticsConfig) =>
  async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CREATE_MD_CONFIG_ATTEMPT })

    try {
      const response = await api.Configs.createMDConfig(config)
      dispatch({
        type: CREATE_MD_CONFIG_SUCCESS,
      })
    } catch (error) {
      dispatch({ type: CREATE_MD_CONFIG_FAIL })
    }
  }

export const CreateSPOConfig =
  (attrs: ICreateSPOConfig) =>
  async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CREATE_SPO_CONFIG_ATTEMPT })
    try {
      const response = await api.Configs.createSPOConfig(attrs.config)
      if(!attrs.config.options.in_channel){
        const updateSettings = await api.Settings.updateViewSettings(
          attrs.view_settings
        )
      } else {
        const updateInChannelSettings = await api.Settings.updateInChannelViewSettings(
          attrs.view_settings
        )
      }

      dispatch({
        type: CREATE_SPO_CONFIG_SUCCESS,
      })
    } catch (error) {
      dispatch({ type: CREATE_SPO_CONFIG_FAIL })
    }
  }

export const CreateSPOConfigV2 =
  (attrs: ICreateSPOConfigV2) =>
  async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch({ type: CREATE_SPO_CONFIG_ATTEMPT })
    try {
      const response = await api.Configs.createSPOConfig(attrs.config)
      dispatch({
        type: CREATE_SPO_CONFIG_SUCCESS,
      })
    } catch (error) {
      dispatch({ type: CREATE_SPO_CONFIG_FAIL })
    }
  }
