import { Typography } from '@gain-theory/typography'
import { group } from 'd3-array'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { GetConfigList } from '../../redux/actions/ConfigActions'
import {
  GetGroups,
  GetUsers,
  CreateUserGroup,
  DeleteUserGroup,
  UpdateGroup,
} from '../../redux/actions/SessionActions'
import { selectConfigs } from '../../redux/reducers/ConfigReducer'
import {
  selectSessionAllGroups,
  selectSessionAllUsers,
  selectSessionModule,
} from '../../redux/reducers/SessionReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import { IGroup, IUser } from '../../types'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
  groups: selectSessionAllGroups(state),
  modules: selectSessionModule(state),
  configs: selectConfigs(state),
})
const mapDispatchToProps = {
  onGetGroups: GetGroups,
  onGetConfigs: GetConfigList,
  onGetUsers: GetUsers,
  onCreateUserGroup: CreateUserGroup,
  onDeleteUserGroup: DeleteUserGroup,
  onUpdateGroup: UpdateGroup,
}

interface MatchParams {
  id: string
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManageOneGroup: FC<PropsFromRedux & RouteComponentProps<MatchParams>> = (
  props
) => {
  const [userGroup, setUserGroup] = useState<IGroup>()
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([])
  const [unselectedUsers, setUnselectedUsers] = useState<IUser[]>([])
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])

  useEffect(() => {
    props.onGetConfigs()
    props.onGetUsers()
    props.onGetGroups()
  }, [])

  const selectModule = (e: any) => {
    if (selectedModules.includes(e.target.value)) {
      setSelectedModules((prevModules) =>
        prevModules.filter((x) => x !== e.target.value)
      )
    } else {
      setSelectedModules((prevModules) => [...prevModules, e.target.value])
    }
  }
  const selectRegion = (e: any) => {
    if (selectedRegions.includes(e.target.value)) {
      setSelectedRegions((prevRegions) =>
        prevRegions.filter((x) => x !== e.target.value)
      )
    } else {
      setSelectedRegions((prevRegions) => [...prevRegions, e.target.value])
    }
  }

  useEffect(() => {
    const group = props.groups?.find(
      (group) => group.id.toString() === props.match.params.id
    )
    if (group) {
      setUserGroup(group)
      setSelectedModules(group.modules)
      setSelectedRegions(group.regions)
    }
  }, [props.groups])

  useEffect(() => {
    const selectedUsers =
      props.users
        ?.filter((user) => user.enabled)
        .filter(
          (user) =>
            userGroup?.user_groups.map((x) => x.user_id).indexOf(user.id) !== -1
        ) || []
    setSelectedUsers(selectedUsers)
    const unSelectedUsers =
      props.users
        ?.filter((user) => user.enabled)
        .filter(
          (user) =>
            userGroup?.user_groups.map((x) => x.user_id).indexOf(user.id) === -1
        ) || []
    setUnselectedUsers(unSelectedUsers)
  }, [props.users, userGroup])

  useEffect(() => {
    if (userGroup) {
      props.onUpdateGroup(userGroup.id, {
        name: userGroup.name,
        regions: selectedRegions,
        modules: selectedModules,
      })
    }
  }, [selectedModules, selectedRegions])

  const selectUser = (e: any) => {
    if (userGroup) {
      props
        .onCreateUserGroup({ user_id: e.target.value, group_id: userGroup.id })
        .then(() => props.onGetGroups())
    }
  }
  const removeUser = (e: any) => {
    const groupId = userGroup?.user_groups.find(
      (group) => group.user_id.toString() === e.target.value
    )?.id
    if (groupId) {
      props.onDeleteUserGroup(groupId).then(() => props.onGetGroups())
    }
  }

  return (
    <div className={'container h-full'}>
      <AdminHeader></AdminHeader>
      {userGroup && (
        <Typography.H4 className="my-6">{`Manage Group: ${userGroup.name}`}</Typography.H4>
      )}
      <h1 className="text-gray-500 my-6 text-md w-3/4">Modules</h1>
      <div className="flex flex-col">
        {props.modules?.map((module) => {
          return (
            <div
              key={module.key}
              className="border rounded px-3 py-2 flex flex-row max-w-xs my-4 bg-white shadow w-3/4 h-14"
            >
              <label className="flex flex-row items-center space-x-2 h-full w-full">
                <input
                  type="checkbox"
                  onChange={selectModule}
                  checked={selectedModules.includes(module.key)}
                  className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                  value={module.key}
                />
                <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                  {module.label}
                </div>
              </label>
            </div>
          )
        })}
      </div>
      <h1 className="text-gray-500 my-6 text-md w-3/4">Regions</h1>

      {props.configs && props.configs[0]
        ? (
            props.configs[0].grouping_levels.find((x) => x.key === 'region')
              ?.values || []
          ).map((region) => {
            return (
              <div
                key={region.key}
                className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white shadow my-4 w-3/4 h-14"
              >
                <label className="flex flex-row items-center space-x-2 h-full w-full">
                  <input
                    type="checkbox"
                    onChange={selectRegion}
                    checked={selectedRegions.includes(region.key)}
                    className="rounded outline-none text-gtPink ring-0 focus:ring-0 focus:outline-none"
                    value={region.key}
                  />
                  <div className="text-sm items-center flex text-gray-500 pt-1 h-full">
                    {region.label}
                  </div>
                </label>
              </div>
            )
          })
        : null}
      <div className={'flex flex-col'}></div>
      <hr />
      <h1 className="text-gray-500 my-6 text-sm w-3/4">{`${userGroup?.user_groups.length} users in this group`}</h1>
      <hr />
      <div className={'flex flex-row'}>
        <h1 className="text-gray-500 my-2 text-sm w-1/2">Add Users</h1>
        <h1 className="text-gray-500 my-2 text-sm w-1/2">{`Users in ${userGroup?.name}`}</h1>
      </div>
      <div className={'flex flex-row overflow-auto mb-80'}>
        <div className={'w-1/2'}>
          {unselectedUsers.map((user) => {
            return (
              <div
                className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white shadow my-1 w-3/4 h-14"
                key={user.id}
              >
                <button
                  className={'w-full'}
                  value={user.id}
                  onClick={selectUser}
                >
                  {user.username}
                </button>
              </div>
            )
          })}
        </div>
        <div className={'w-1/2'}>
          {selectedUsers.map((user) => {
            return (
              <div
                className="border rounded px-3 py-2 flex flex-row max-w-xs bg-white shadow my-1 w-3/4 h-14"
                key={user.id}
              >
                <button
                  className={'w-full'}
                  value={user.id}
                  onClick={removeUser}
                >
                  {user.username}
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default connector(MainLayout(ManageOneGroup))
