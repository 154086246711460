import { useMsal } from '@azure/msal-react'
import { Button } from '@gain-theory/button'
import { Combobox, ComboboxProps } from '@gain-theory/combobox'
import { useWindowSize } from '@gain-theory/hooks'
import { Typography } from '@gain-theory/typography'
import { memo, useCallback, useMemo } from 'react'
import { azureLoginRequest } from '../../../authentication/azure.config'
import { IdentityProvider } from '../../../types/SettingTypes'
import { useActions, useAuthSelector, useLoginCallbacks } from './hooks'
import { LoginCardAction } from './login-card-action'
import { OktaWidget } from './okta-widget'

export const LoginCard = memo(() => {
  const { instance } = useMsal()
  const { width } = useWindowSize()
  const showIconButton = useMemo(() => !width || width < 1280, [width])

  const actions = useActions()
  const {
    selectedValues,
    handleSelectProvider,
    authOptions,
    selectedProvider,
  } = useAuthSelector()
  const { redirectUrl, onSignInSuccess, onSignInError } = useLoginCallbacks()

  const authProviderComboboxProps: ComboboxProps = {
    label: 'Provider',
    placeholder: 'Select provider',
    multiple: false,
    allowUnselect: false,
    disallowTextManipulation: true,
    options: authOptions,
    setSelectedValues: handleSelectProvider,
    selectedValues,
  }

  const handleAzureLogin = useCallback(() => {
    instance.loginPopup(azureLoginRequest)
  }, [instance])

  const renderAuthWidget = useCallback(() => {
    if (!selectedProvider) return null

    if (selectedProvider.idp_name === IdentityProvider.Azure) {
      return (
        <Button onClick={handleAzureLogin} variant="secondary">
          Sign in with Azure AD
        </Button>
      )
    }

    if (selectedProvider.idp_name === IdentityProvider.Okta) {
      return (
        <OktaWidget
          clientId={selectedProvider.client_id}
          issuer={selectedProvider.domain}
          redirectUri={redirectUrl}
          customIdps={selectedProvider.custom_idps}
          onError={onSignInError}
          onSuccess={onSignInSuccess}
        />
      )
    }

    return null
  }, [
    selectedProvider,
    redirectUrl,
    onSignInError,
    onSignInSuccess,
    handleAzureLogin,
  ])

  return (
    <div className="z-20 max-h-[calc(100%-16px)] overflow-auto flex flex-col gap-md p-xxl bg-neutral-white shadow-lg rounded-md w-full">
      <Typography.H5 className="text-center">
        <span className="text-brand-primary-main">Sign In</span>
      </Typography.H5>

      <div className="flex flex-col gap-md">
        <Combobox {...authProviderComboboxProps} />
        {renderAuthWidget()}
      </div>

      <div
        className={`flex gap-xs ${
          showIconButton ? 'flex-row items-center' : 'flex-col items-start'
        }`}
      >
        {actions.map(({ id, icon, label, library, onClick }) => (
          <LoginCardAction
            key={id}
            id={id}
            icon={icon}
            label={label}
            library={library}
            onClick={onClick}
            showIconButton={showIconButton}
          />
        ))}
      </div>
    </div>
  )
})
