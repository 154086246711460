import { Button, ButtonProps } from '@gain-theory/button'
import { Icon } from '@gain-theory/icon'
import { memo } from 'react'
import { WEBSITE_LINK } from '../../../utils/constants'
import { useInfoTypography } from './hooks'

export const LoginInfo = memo(() => {
  const { TitleTypography, SubtitleTypography } = useInfoTypography()

  const findOutButtonProps: ButtonProps = {
    id: 'find-out-more-button',
    variant: 'tertiary',
    leadingIcon: <Icon name="BookOpenIcon" color="brand-primary-main" />,
    onClick: () => window.open(WEBSITE_LINK.link, '_self'),
  }

  return (
    <div className="flex flex-col items-start gap-xxl lg:gap-xxxl px-xxl lg:px-xxxxl xl:px-xxxxxl pt-xxxxl lg:pt-20 xl:pt-xxxxxl 2xl:pt-40">
      <div className="flex items-start justify-start w-fit">
        <Icon
          name={'gtiLogoTransparency' as any}
          library="svg"
          style={{ height: 64, width: 'auto' }}
        />
      </div>

      <TitleTypography>
        <span className="text-text-white">
          Gain Theory Interactive is our intuitive and easy-to-use
          decision-making platform that provides insights and actionable
          recommendations at speed.
        </span>
      </TitleTypography>

      <SubtitleTypography>
        <span className="text-text-white">
          Gain Theory Interactive works alongside our advanced analytics and
          data platforms to help you make better decisions that accelerate
          growth.
        </span>
      </SubtitleTypography>

      <div className="w-fit bg-neutral-white rounded-sm">
        <Button {...findOutButtonProps}>Find out more</Button>
      </div>
    </div>
  )
})
