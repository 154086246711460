import { Children, memo } from 'react'
import { Typography } from '@gain-theory/typography'
import { FaqProps } from './types'
import { FaqAccordion } from './faq-accordion'
import { FaqLeftShape, FaqRightShape } from '../assets'
import { useFeatureContentHeight, useMoveXBg } from '../hooks'

const REFERENCE_WIDTH = 1280
const FAQ_TITLE = 'Frequently asked questions'

export const Faq = memo(({ id, faqs }: FaqProps) => {
  const { rootRef, bgRef, height } = useFeatureContentHeight()
  const { moveXBg } = useMoveXBg({ referenceWidth: REFERENCE_WIDTH })

  const LeftRectangleBackground = (
    <div
      ref={bgRef}
      style={{
        left: moveXBg,
        right: 'auto',
      }}
      className="-mx-xxl absolute m-auto flex-grow-0 z-0 overflow-hidden top-7"
    >
      <FaqLeftShape className="stroke-brand-primary-main" />
    </div>
  )

  const RightRectangleBackground = (
    <div
      style={{
        left: 'auto',
        right: moveXBg,
      }}
      className="-mx-xxl absolute m-auto flex-grow-0 z-0 overflow-hidden top-32"
    >
      <FaqRightShape className="stroke-brand-primary-main" />
    </div>
  )

  const Title = (
    <div className="col-span-full items-center justify-center z-10">
      <Typography.H4 className="text-center">{FAQ_TITLE}</Typography.H4>
    </div>
  )

  const Faqs = (
    <div className="col-span-full grid grid-cols-6 xl:grid-cols-12 grid-flow-row gap-y-xl z-10">
      {Children.toArray(
        faqs.map((faq) => (
          <div className="col-span-6 xl:col-span-8 xl:col-start-3">
            <FaqAccordion {...faq} />
          </div>
        ))
      )}
    </div>
  )

  return (
    <div
      id={id}
      ref={rootRef}
      style={{ height }}
      className="mx-xxl col-span-full grid grid-cols-6 xl:grid-cols-12 gap-y-xxl relative pt-xxxxxl auto-rows-min"
    >
      {LeftRectangleBackground}
      {RightRectangleBackground}
      {Title}
      {Faqs}
    </div>
  )
})
