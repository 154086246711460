import React, { FC } from 'react'
import { ReactComponent as InChannelSvg } from '../svg/partner_upload.svg'
import { ReactComponent as CrossChannelSvg } from '../svg/icon-existing-plan-default-xl.svg'
interface OwnProps {
  className: string
  inChannel: boolean
}

type Props = OwnProps

const ExistingPlanIcon: FC<Props> = ({ className, inChannel }) =>
  inChannel ? (
    <InChannelSvg className={className} />
  ) : (
    <CrossChannelSvg className={className} />
  )

export default ExistingPlanIcon
