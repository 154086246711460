import 'react-toastify/dist/ReactToastify.min.css'

import { memo } from 'react'
import { ToastContainer } from 'react-toastify'
import { useNotification } from './hooks'
import { NotificationContext } from './notification-context'
import { NotificationProviderProps } from './types'

export const NotificationProvider = memo(
  ({ children }: NotificationProviderProps) => {
    const notification = useNotification()

    return (
      <NotificationContext.Provider value={notification}>
        {children}
        <ToastContainer limit={1} />
      </NotificationContext.Provider>
    )
  }
)
