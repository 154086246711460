import { ComboboxProps } from '@gain-theory/combobox'
import { OptionType } from '@gain-theory/combobox/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../../../api'
import { OktaSetting } from '../../../../types/SettingTypes'

async function getAuthSettings() {
  const settings = await api.Settings.getOktaSettings()
  return settings.data as OktaSetting[]
}

function useAuthOptions() {
  const [authSettings, setAuthSettings] = useState<OktaSetting[]>([])
  const [isFetched, setIsFetched] = useState(false)

  const fetchAuthSettings = useCallback(async () => {
    const settings = await getAuthSettings()
    setAuthSettings(settings)
    setIsFetched(true)
  }, [])

  const authenticationOptions: OptionType[] = useMemo(() => {
    if (!authSettings.length) return []

    return authSettings.map((provider) => ({
      id: provider.id.toString(),
      label: provider.label,
      disabled: !provider.enabled,
    }))
  }, [authSettings])

  useEffect(() => {
    fetchAuthSettings()
  }, [fetchAuthSettings])

  return { authenticationOptions, isFetched, authSettings }
}

export function useAuthSelector() {
  const { authenticationOptions, isFetched, authSettings } = useAuthOptions()

  const [selected, setSelected] = useState<ComboboxProps['selectedValues']>([])

  const [selectedProvider, setSelectedProvider] = useState<OktaSetting | null>(
    null
  )

  const getProviderById = useCallback(
    (id?: string) => {
      if (!id) {
        return null
      }
      const provider = authSettings.find(
        (provider) => provider.id.toString() === id
      )

      return provider ?? null
    },
    [authSettings]
  )

  const handleSelectProvider: ComboboxProps['setSelectedValues'] = (values) => {
    if (!values.length) {
      return null
    }

    const id = values[0].id?.toString()
    const provider = getProviderById(id)

    if (!provider) {
      return null
    }

    setSelected(values)
    setSelectedProvider(provider)
  }

  useEffect(() => {
    if (isFetched && authenticationOptions.length) {
      const initialSelected = [authenticationOptions[0]] ?? []
      const provider = getProviderById(initialSelected[0]?.id.toString())
      setSelectedProvider(provider)
      setSelected(initialSelected)
    }
  }, [isFetched, authenticationOptions, getProviderById])

  return {
    isFetched,
    selectedProvider,
    selectedValues: selected,
    handleSelectProvider,
    authOptions: authenticationOptions,
  }
}
