import { useEffect, useState } from 'react'
import { IUseCardChipLabelsProps } from './types'

export function useCardChipLabels({
  cardChips,
  filterChips,
}: IUseCardChipLabelsProps) {
  const [chipLabels, setChipLabels] = useState<(string | null)[]>([])

  useEffect(() => {
    const labels = cardChips.map((chip) => {
      const chipLabel = filterChips.find(
        (filterChip) => filterChip.id === chip
      )?.label

      return chipLabel ?? null
    })

    setChipLabels(labels)
  }, [cardChips, filterChips])

  return { chipLabels }
}
