import React, { FC, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { RootStore } from '../../../redux/reducers/Store'
import { connect, ConnectedProps } from 'react-redux'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { selectPlanItem } from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { EditSPOConstraints } from '../../../socket.io'
import {
  GroupingLevel,
  Observation,
  Transformation,
} from '../../../types/PlanTypes'

interface OwnProps {
  constraint: (string | number | undefined)[]
  groupingLevels: GroupingLevel[]
  rowNumber: number
  changeGroupingInput: any
  resetConstraint: any
  addNewRow: any
  deleteRow: any
  changeObservationInput: any
  uploaded: boolean
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  plan: selectPlanItem(state),
})

const mapDispatchToProps = {
  editSPOTimeConstraints: EditSPOConstraints,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ConstraintsRow: FC<PropsFromRedux & Props> = (props) => {
  const levelKeys = props.groupingLevels.map(x => x.key)
  const numberOfLevels = levelKeys.length

  const [options, setOptions] = useState<{ [key: string]: string[] }>({
  })
  const [selected, setSelected] = useState<{ [key: string]: string }>({
  })
  const [transformations, setTransformations] = useState<Transformation[]>([])
  const [selectedConstraint, setSelectedConstraint] = useState('')
  const [removeRow, setRemoveRow] = useState(false)
  const [display, setDisplay] = useState(true)

  useEffect(() => {
    setTransformations(props.plan.transformations)
    let initialOptions = {
      [levelKeys[0]]: props.groupingLevels[0].values.map((val) => val.label),
    }
    for(let i = 1; i < levelKeys.length; i++){
      initialOptions = {
        ...initialOptions,
        [levelKeys[i]]: []
      }
    }

    setOptions(initialOptions)

    let allLevelsPopulated = true
    for(let i = 0; i < numberOfLevels; i++){
      allLevelsPopulated = typeof props.constraint[i] === 'string'
      if(!allLevelsPopulated){
        break
      }
    }

    if(allLevelsPopulated){
      //if constraint already exists, update the selection boxes
      for(let i = 0; i < numberOfLevels; i++){
        if(props.constraint[i] && typeof props.constraint[i] === 'string'){
          if(levelKeys[i + 1]){
            selectGroupingLevel(props.constraint[i], levelKeys[i], levelKeys[i + 1], i)
          } else {
            selectGroupingLevel(props.constraint[i], levelKeys[i], '', i)
          }
            setOptions({
              ...options,
              [levelKeys[i]]: [props.constraint[i]!.toString()],
            })
        }
      }
      setRemoveRow(true)
    }

  }, [])

  useEffect(() => {
    let allLevelsPopulated = true
    for(let i = 0; i < numberOfLevels; i++){
      allLevelsPopulated = typeof props.constraint[i] === 'string'
      if(!allLevelsPopulated){
        break
      }
    }
    if(allLevelsPopulated){
      let newOptions = _.cloneDeep(options)
      let newSelected = _.cloneDeep(selected)
      for(let i = 0; i < numberOfLevels; i++){
        newOptions[levelKeys[i]] = [props.constraint[i]!.toString()]
        newSelected[levelKeys[i]] = props.constraint[i]!.toString()
    }
      setOptions(newOptions)
      setSelected(newSelected)
      if(props.constraint[numberOfLevels] && typeof props.constraint[numberOfLevels] === 'string'){
        setSelectedConstraint(props.constraint[numberOfLevels]!.toString())
      }
      setRemoveRow(true)
  }

  let allLevelsUnpopulated = true
  for(let i = 0; i < numberOfLevels; i++){
    allLevelsUnpopulated = props.constraint[i] === null
    if(!allLevelsUnpopulated){
      break
    }
  }
    if (
      allLevelsUnpopulated
    ) {
      let newSelected: any = {}
      let newOptions: any = {}
      for(let i = 0; i < numberOfLevels; i++){
        newSelected[levelKeys[i]] = ''
        newOptions[levelKeys[i]] = []
      }
      newOptions[levelKeys[0]] = props.groupingLevels[0].values.map((val) => val.label)
      setSelected(newSelected)
      setOptions(newOptions)
      setRemoveRow(false)
    }

  }, [props.constraint])

  useEffect(() => {
    if (props.uploaded) {
      //if constraint already exists, update the selection boxes
      let allLevelsPopulated = true
      for(let i = 0; i < numberOfLevels; i++){
        allLevelsPopulated = typeof props.constraint[i] === 'string'
        if(!allLevelsPopulated){
          break
        }
      }
      if(allLevelsPopulated){
        for(let i = 0; i < numberOfLevels; i++){
            if(levelKeys[i + 1]){
              selectGroupingLevel(props.constraint[i], levelKeys[i], levelKeys[i + 1], i)
            } else {
              selectGroupingLevel(props.constraint[i], levelKeys[i], '', i)
            }
              setOptions({
                ...options,
                [levelKeys[i]]: [props.constraint[i]!.toString()],
              })
          }
          setRemoveRow(true)
      }
    }
  }, [props.uploaded])

  const selectGroupingLevel = (
    constraintValue: any,
    groupingLevel: string,
    childLevel: string,
    position: number
  ) => {
    const value = constraintValue.toString()
    props.changeGroupingInput(props.rowNumber, value, position)

    if (!childLevel) {
      setSelected({ ...selected, [groupingLevel]: value })
    } else {
      const valueKey = value.toLowerCase().replace(/ /g, '_')

      for(let i = 0; i < levelKeys.length - 1; i++){
        if (selected[groupingLevel] !== '' && groupingLevel === levelKeys[i]) {
          props.resetConstraint(value, props.rowNumber, i)
          let transforms = props.plan.transformations
          for(let key of levelKeys){
            if(selected[key] !== ''){
              transforms = transformations.filter(
                // eslint-disable-next-line
                (trans: Transformation) => {
                  if (trans.identifiers[key] === selected[key]) {
                    return trans
                  }
                }
              )
            }
          }
          setTransformations(transforms)
          setSelectedConstraint('')
          const levelIndex = levelKeys.indexOf(groupingLevel)
          let newSelected = _.cloneDeep(selected)
          let newOptions = _.cloneDeep(options)
          for(let i = levelIndex + 1; i < levelKeys.length; i ++){
            newSelected[levelKeys[i]] = ''
          }
          for(let i = levelIndex + 2; i < levelKeys.length; i ++){
            newOptions[levelKeys[i]] = []
          }
          setSelected(newSelected)
          setOptions(newOptions)
        }
      }

      const filteredTransforms =
        selected[groupingLevel] === ''
          ? transformations.filter(
              (trans: Transformation) =>
                // @ts-ignore
                trans.identifiers[groupingLevel] === valueKey
            )
          : props.plan.transformations.filter(
              (trans: Transformation) =>
                // @ts-ignore
                trans.identifiers[groupingLevel] === valueKey
            )

      const childOptions = _.uniqBy(
        filteredTransforms.map(
          (
            trans: Transformation // @ts-ignore
          ) => trans.identifiers[childLevel]
        ),
        function (e) {
          return e
        }
      )

      if (props.groupingLevels) {
        let childOptionsLabels: string[] = []
        const values = props.groupingLevels.find(
          (level) => level.key === childLevel
        )?.values
        for (let opt of childOptions) {
          const label = values?.find((val) => val.key === opt)?.label
          if (label) {
            childOptionsLabels.push(label)
          }
        }
        setSelected({ ...selected, [groupingLevel]: value })
        setOptions({ ...options, [childLevel]: childOptionsLabels })
        setTransformations(filteredTransforms)
      }
    }
  }

  const changeConstraintType = (value: string, position: number) => {
    props.changeGroupingInput(props.rowNumber, value, position)
    setSelectedConstraint(value)
  }

  const saveRow = () => {
    if (removeRow) {
      selectGroupingLevel('', levelKeys[0], levelKeys[1], 0)
      props.deleteRow(props.rowNumber)
    } else {
      props.addNewRow()
    }
    setRemoveRow(true)
  }

  const changeObservationInput = (value: string, position: number) => {
    let parseValue: number | null = parseInt(value, 10)

    if (isNaN(parseValue)) {
      parseValue = null
    }

    props.changeObservationInput(props.rowNumber, parseValue, position)
  }
  if (transformations.length === 0) {
    setTransformations(props.plan.transformations)
    let newOptions = _.cloneDeep(options)
    for(let i = 1; i < levelKeys.length; i++){
      newOptions[levelKeys[i]] = []
    }
    setOptions(newOptions)
  }

  const constraintTypes = ['Min', 'Max', 'Equal']

  return (
    <tr>
      {display ? (
        <th className="px-2">
          <button
            onClick={saveRow}
            className="clear-left rounded bg-gtPink text-white text-xs py-4 px-4 mr-4"
          >
            {removeRow ? '-' : '+'}
          </button>
        </th>
      ) : null}
      {display && levelKeys.map((key, index) => {
        const nextKey = levelKeys[index + 1] ? levelKeys[index + 1] : ''
        return (<th key={index} className="px-2">
        {options[key] && options[key].length > 0 && (
          <select
            value={props.constraint[index] ? props.constraint[index] : selected[key]}
            onChange={(event) => {
              return selectGroupingLevel(event.target.value, key, nextKey, index)
            }
            }
          >
            <option />
            {options[key].map((con) => {
              return <option key={con}>{con}</option>
            })}
          </select>
        )}
      </th>)
      })}
      {display ? (
        <th className="px-2">
          {selected[levelKeys[levelKeys.length - 1]] !== '' ? (
            <select
              value={
                props.constraint[numberOfLevels] ? props.constraint[numberOfLevels] : selectedConstraint
              }
              onChange={(event) => changeConstraintType(event.target.value, numberOfLevels)}
            >
              <option />
              {constraintTypes.map((con) => {
                return <option key={con}>{con}</option>
              })}
            </select>
          ) : null}
        </th>
      ) : null}
      {display
        ? props.plan.config.observations
            .slice(
              props.plan.observations_min,
              props.plan.observations_max +
                2 -
                props.plan.options.carryoverWeeks
            )
            .map((_: Observation, index: number) => {
              return (
                <th
                  key={index}
                  style={index === 0 ? { display: 'none' } : {}}
                  className="px-2"
                >
                  <input
                    className={
                      'h-10 w-40 px-1 border border-solid border-black text-sm'
                    }
                    value={
                      props.constraint[index + numberOfLevels + 1] ||
                      props.constraint[index + numberOfLevels + 1] === 0
                        ? props.constraint[index + numberOfLevels + 1]?.toString()
                        : ""
                    }
                    onChange={(event) =>
                      changeObservationInput(event.target.value, index)
                    }
                  />
                </th>
              )
            })
        : null}
    </tr>
  )
}

export default ConstraintsRow
