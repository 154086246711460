import { Component } from 'react'
import DraggableCore from 'react-draggable'
import _ from 'lodash'
import { connect } from 'react-redux'
import DimensionsHOC from '../../../../shared/DimensionsHOC'
import appendUnit from '../../../../utils/appendUnit'
import { CHART_BAR_COLORS, CHART_BAR_COLOR } from '../../../../utils/colours'

class ObservationAllocationMultiRangeSlider extends Component {
  constructor() {
    super()
    this.state = {
      display: false,
    }
  }

  getObservationWidthInPixels = () => {
    return (
      this.props.containerWidth /
      ((this.props.observations ? this.props.observations.length : 0) -
        (this.props.min ? this.props.min : 0))
    )
  }

  getObservationLabel(key) {
    if (this.props.observations[key] !== undefined) {
      return this.props.observations[key].label
    }
  }

  getMaxValue = (values) => {
    return _(values).flatten().max()
  }

  getSegmentOpacity = (max, val) => {
    if (!val) {
      val = 0
    }
    return Math.max(val / max, 0.2)
  }

  getRangeColor = (attr) => {
    let index = 0
    if (this.props && this.props.rangeColor && !isNaN(this.props.rangeColor)) {
      index = this.props.rangeColor
    }

    return CHART_BAR_COLOR[attr][index]
  }

  render() {
    const obsWidthPixels = this.getObservationWidthInPixels()
    const max = this.getMaxValue(this.props.details)

    if (!Array.isArray(this.props.optimalInputPositions)) {
      return
    }

    return this.props.optimalInputPositions.map((positions, key) => (
      <div key={key}>
        <DraggableCore
          position={{
            x: obsWidthPixels * positions[0],
            y: 0,
          }}
          disabled={true}
        >
          <div
            className={`obs-alloc-channel-item`}
            style={{
              width: `${obsWidthPixels * positions[1]}px`,
            }}
          >
            {Array.from(Array(positions[1])).map((_, position) => {
              let value = 0

              if (
                this.props.details[key * 2 + 1] &&
                this.props.details[key * 2 + 1].length > 0
              ) {
                value = this.props.details[key * 2 + 1][position]
              }

              return (
                <div
                  key={position}
                  className={`obs-alloc-channel-item-segment`}
                  style={{
                    left: `${obsWidthPixels * position}px`,
                    width: `${obsWidthPixels}px`,
                  }}
                >
                  <div
                    style={{ color: CHART_BAR_COLORS[key] }}
                    className={
                      'flex whitespace-nowrap justify-center h-full max-w-full text-xs overflow-visible group'
                    }
                  >
                    <div
                      className={`obs-alloc-channel-item-segment-background ${this.getRangeColor(
                        'bg'
                      )}`}
                      style={{
                        position: 'absolute',
                        opacity: this.getSegmentOpacity(max, value),
                      }}
                    />
                    <div
                      data-label={
                        this.getObservationLabel(positions[0] + position) +
                        ': ' +
                        appendUnit(
                          value,
                          this.props.unit,
                          this.props.plans.planItem.options.exchangeRates
                            .defaultSymbol,
                          this.props.settings.number_format
                        )
                      }
                      className={`custom-bar w-full h-full max-w-full text-xs overflow-visible opacity-0 group-hover:opacity-100 group-hover:bg-gtGray ${this.getRangeColor(
                        'bg'
                      )} ${this.getRangeColor('text')}`}
                    ></div>
                  </div>
                </div>
              )
            })}
          </div>
        </DraggableCore>
      </div>
    ))
  }
}

const mapStateToProps = (state) => ({
  plans: state.plans,
})

export default connect(mapStateToProps)(
  DimensionsHOC(ObservationAllocationMultiRangeSlider)
)
