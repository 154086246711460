import { useEffect, useState } from 'react'
import {
  GroupingLevel,
  Identifier,
  Observation,
  Transformation,
} from '../../../../types/PlanTypes'
import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from '../../../../utils/constants'
import { Settings } from '../../../../types/SettingTypes'

interface IUseTimePeriodBudgetConstraintsProps {
  file: File | null
  grouping_levels: GroupingLevel[]
  transformations: Transformation[]
  observations: Observation[]
  carryoverWeeks: number
  settings: Settings | null
}

const DIMENSIONS_START_INDEX = 0

const useTimePeriodBudgetConstraints = ({
  file,
  grouping_levels,
  transformations,
  observations,
  carryoverWeeks,
  settings
}: IUseTimePeriodBudgetConstraintsProps) => {
  const numberOfLevels = grouping_levels.length || 0
  const lastDimensionIndex = DIMENSIONS_START_INDEX + numberOfLevels - 1
  const observationsStartIndex = lastDimensionIndex + 2

  const [transformationOptions, setTransformationOptions] = useState<
    Transformation[]
  >([])
  const [selectedObservations, setSelectedObservations] = useState<number[]>([])
  const [fileError, setFileError] = useState<string | null>(null)
  const [totalBudget, setTotalBudget] = useState<number>(0)

  const READ_FILE_ERRORS = {
    NO_DATA: 'No data in file',
    DATA_TYPE: 'File data is not a string',
    ALL_OBSERVATIONS_ZERO: 'All observations are zero',
    GENERIC_ERROR:
      'There is a problem with the file. Please check if the columns match your current plan.',
  }

  const checkIfCarryoverWeeksAreInFile = (headers: string[]) => {
    const observationHeaders = headers.slice(observationsStartIndex)

    const carryoverObservations = observations.slice(
      observations.length - carryoverWeeks
    )

    const dateFormat = settings?.date_format || DEFAULT_DATE_FORMAT.LABEL

    const carryoverObservationsInFile = observationHeaders
      .map((header) => {
        const formattedHeader = moment(header, dateFormat)
          .format(DEFAULT_DATE_FORMAT.LABEL)

        const observation = carryoverObservations.find(
          (observation) => observation.label === formattedHeader
        )

        return observation || false
      })
      .filter(Boolean)

    return carryoverObservationsInFile.length
  }

  const getLevelHeaderKeys = (levelHeaders: string[]) => {
    const levelHeaderKeys = levelHeaders.map((levelHeader) => {
      const levelKey = grouping_levels.find(
        (level) => level.label === levelHeader
      )?.key

      return levelKey || ''
    })

    return levelHeaderKeys
  }

  const getDimensionValuesKeys = (
    dimensionHeaderKeys: string[],
    dimensionValues: string[]
  ) => {
    const mappedDimension = new Map<string, string>()

    dimensionValues.forEach((dimensionValue, index) => {
      const levelValues = grouping_levels[index].values
      const levelValueKeys = levelValues.map((levelValue) => levelValue.key)

      const valueIndex = levelValues.findIndex(
        (levelValue) => levelValue.label === dimensionValue
      )

      mappedDimension.set(
        dimensionHeaderKeys[index],
        levelValueKeys[valueIndex]
      )
    })

    return Array.from(mappedDimension).reduce((dimension, [key, value]) => {
      dimension[key] = value
      return dimension
    }, {} as Record<string, string>)
  }

  const parseDataForFile = (data: string[]) => {
    const emptyReturn = {
      headers: [],
      dimensionValues: new Set<Record<string, string>>(),
      variables: [],
      observationsValues: new Set<string[]>(),
      spendValues: new Set<string[]>()
    }

    if (!data || data.length < 2) {
      return emptyReturn
    }

    const splittedData = data
      .map((row: string) => row.split(','))
      .map((row) => row.map((element) => element.replace(/"/g, '')))

    const headers = splittedData[0]
    const dimensionHeaders = headers.slice(
      DIMENSIONS_START_INDEX,
      lastDimensionIndex + 1
    )

    const rows = splittedData.slice(1)

    if (headers[lastDimensionIndex + 1] !== 'Variable') {
      return emptyReturn
    }

    const numberOfCarryoverWeeks = checkIfCarryoverWeeksAreInFile(headers)

    const noCarryoverHeaders = headers.slice(
      0,
      headers.length - numberOfCarryoverWeeks
    )

    const dimensionHeaderKeys = getLevelHeaderKeys(dimensionHeaders)
    const dimensionValues = new Set<Record<string, string>>()
    const variables: string[] = []
    const observationsValues = new Set<string[]>()
    const spendValues = new Set<string[]>()

    rows.forEach((row) => {
      const dimensionsInRow = row.slice(
        DIMENSIONS_START_INDEX,
        lastDimensionIndex + 1
      )
      const variableInRow = row[lastDimensionIndex + 1]
      const observationsInRow = row.slice(
        observationsStartIndex,
        row.length - numberOfCarryoverWeeks
      )

      const dimensionKeys = getDimensionValuesKeys(
        dimensionHeaderKeys,
        dimensionsInRow
      )

      dimensionValues.add(dimensionKeys)
      variables.push(variableInRow)
      observationsValues.add(observationsInRow)
      if(variableInRow === "Spend"){
        spendValues.add(observationsInRow)
      }
    })

    return {
      headers: noCarryoverHeaders,
      dimensionValues,
      variables,
      observationsValues,
      spendValues
    }
  }

  const getTransformationOptionsForFile = (
    dimensionValues: Set<Record<string, string>>
  ): Transformation[] => {
    const filteredTransformations = transformations.filter(
      (trans) => !trans.is_halo
    )

    const rowsSize = dimensionValues.size
    const dimensionSetValues = dimensionValues.values()

    const transformationOptions = new Set<Transformation>()

    for (let i = 0; i < rowsSize; i++) {
      const dimensionValues = dimensionSetValues.next().value as Record<
        string,
        string
      >

      const transformation = filteredTransformations.find((transformation) => {
        const { identifiers } = transformation
        const identfierObj = identifiers as any as Identifier

        return Object.keys(identfierObj)
          .filter((key) => key !== 'region_key')
          .every((key) => {
            return identfierObj[key] === dimensionValues[key]
          })
      })

      if (transformation) {
        transformationOptions.add(transformation)
      }
    }

    return Array.from(transformationOptions)
  }

  const getTotalBudgetFromObservationValues = (
    observationValues: Set<string[]>
  ): number => {
    const values = Array.from(observationValues.values())

    let budget = 0

    values.forEach((row) => {
      row.forEach((value) => {
        const numberValue = Number(value)

        if (isNaN(numberValue)) {
          return
        }

        budget += Number(value)
      })
    })

    return budget
  }

  const getNonZeroColumns = (observationValues: Set<string[]>): number[] => {
    const values = Array.from(observationValues.values())

    const numberOfColumns = values[0].length

    let firstNonZeroCol = null
    let lastNonZeroCol = null

    for (let i = 0; i < numberOfColumns; i++) {
      if (values.every((row) => row[i] === '0')) {
        continue
      }

      if (firstNonZeroCol === null) {
        firstNonZeroCol = i
      } else {
        lastNonZeroCol = i
      }
    }

    if (firstNonZeroCol === null || lastNonZeroCol === null) {
      return []
    }

    return [firstNonZeroCol, lastNonZeroCol]
  }

  const getSelectedObservations = (
    observationHeaders: string[],
    nonZeroColumns: number[]
  ): number[] => {
    const [firstNonZeroCol, lastNonZeroCol] = nonZeroColumns

    const dateFormat = settings?.date_format || DEFAULT_DATE_FORMAT.LABEL

    const firstNonZeroObservation = moment(observationHeaders[firstNonZeroCol], dateFormat)
      .format(DEFAULT_DATE_FORMAT.LABEL)
    const lastNonZeroObservation = moment(observationHeaders[lastNonZeroCol], dateFormat)
      .format(DEFAULT_DATE_FORMAT.LABEL)

    const firstNonZeroObservationIndex = observations.findIndex(
      (observation) => observation.label === firstNonZeroObservation
    )

    const lastNonZeroObservationIndex = observations.findIndex(
      (observation) => observation.label === lastNonZeroObservation
    )

    if (
      firstNonZeroObservationIndex === -1 ||
      lastNonZeroObservationIndex === -1
    ) {
      return []
    }

    return [firstNonZeroObservationIndex, lastNonZeroObservationIndex]
  }

  const parseCSVFile = (
    file: File
  ): Promise<{
    parsedTransformations: Transformation[]
    parsedSelectedObservations: number[]
    parsedBudget: number
  }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function () {
        const text = reader.result

        if (!text) {
          reject(READ_FILE_ERRORS.NO_DATA)
          return
        }

        if (typeof text !== 'string') {
          reject(READ_FILE_ERRORS.DATA_TYPE)
          return
        }

        const data = text
          .split('\n')
          .filter((row) => row !== '')
          .map((row) => row.replace('\r', ''))

        const { headers, dimensionValues, observationsValues, spendValues } =
          parseDataForFile(data)

        const hasHeaders = headers.length > 0
        const hasDimensionValues = dimensionValues.size > 0
        const hasObservationsValues = observationsValues.size > 0

        if (!hasHeaders || !hasDimensionValues || !hasObservationsValues) {
          reject(READ_FILE_ERRORS.GENERIC_ERROR)
          return
        }

        const parsedTransformations =
          getTransformationOptionsForFile(dimensionValues)

        const parsedBudget =
          getTotalBudgetFromObservationValues(spendValues)

        const nonZeroColumns = getNonZeroColumns(observationsValues)

        if (nonZeroColumns.length === 0) {
          reject(READ_FILE_ERRORS.ALL_OBSERVATIONS_ZERO)
          return
        }

        const parsedSelectedObservations = getSelectedObservations(
          headers.slice(observationsStartIndex),
          nonZeroColumns
        )

        if (parsedSelectedObservations.length === 0) {
          reject(READ_FILE_ERRORS.GENERIC_ERROR)
          return
        }

        resolve({
          parsedTransformations,
          parsedSelectedObservations,
          parsedBudget,
        })
      }
      reader.onerror = function () {
        reject('Failed to read file')
      }
      reader.readAsText(file)
    })
  }

  const handleFileUpload = async (file: File) => {
    await parseCSVFile(file)
      .then((res) => {
        setTransformationOptions(res.parsedTransformations)
        setSelectedObservations(res.parsedSelectedObservations)
        setTotalBudget(res.parsedBudget)
        setFileError(null)
      })
      .catch((err) => {
        setFileError(err)
      })
  }

  useEffect(() => {
    if (file) {
      handleFileUpload(file)
      return
    }

    setTransformationOptions([])
    setSelectedObservations([])
    setFileError(null)
    setTotalBudget(0)
  }, [file])

  return {
    transformationOptions,
    selectedObservations,
    totalBudget,
    fileError,
  }
}

export default useTimePeriodBudgetConstraints
