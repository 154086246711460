import { TimeConstraintErrorType } from '../types'
import { ErrorMessages } from './constants'

export const getErrorList = (
  error: TimeConstraintErrorType | TimeConstraintErrorType[],
  includeFoundErrorsMessage = true
) => {
  const errors: TimeConstraintErrorType[] = Array.isArray(error)
    ? error
    : [error]

  const multipleErrors = errors.length > 1

  const foundErrorsMessage = multipleErrors
    ? `${errors.length} errors found:`
    : '1 error found:'

  const errorsWithReplacedMessage: string[] = errors.map((err) => {
    const errorMessage = ErrorMessages[err.errorType]

    return err.replacers.reduce(
      (acc, replacer, index) => acc.replace(`{${index}}`, replacer),
      errorMessage
    )
  })

  const errorList: string[] = includeFoundErrorsMessage
    ? [foundErrorsMessage, ...errorsWithReplacedMessage]
    : errorsWithReplacedMessage

  return errorList
}
