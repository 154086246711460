import React, { FC } from 'react'
import { Highlights } from '../types/MarketingDiagnosticsTypes'
import MostEffectiveCard from '../pages/md/launchpad/highlight-cards/MostEffectiveCard'
import MostProductiveChannel from '../pages/md/launchpad/highlight-cards/MostProductiveChannel'
import TopKpiCard from '../pages/md/launchpad/highlight-cards/TopKpiCard'
import MostChangedCard from '../pages/md/launchpad/highlight-cards/MostChangedCard'
import { Link } from 'react-router-dom'
import { ROUTES } from '../Routes'
import SelectBrandsModal from '../pages/md/highlights/SelectBrandsModal'
import { CHART_BAR_COLORS } from '../utils/colours'

interface OwnProps {
  highlights: Highlights
  showTitle?: boolean
}

type Props = OwnProps

const MDHighlights: FC<Props> = ({ highlights, showTitle = true }) => {
  const col1 = CHART_BAR_COLORS[0]
  const col2 = CHART_BAR_COLORS[1]
  const col3 = CHART_BAR_COLORS[6]
  const col4 = CHART_BAR_COLORS[9]
  return (
    <div className='flex flex-col gap-md'>
      {showTitle ? (
        <div className="text-gray-500 text-xs">
          HIGHLIGHTS{' '}
          <Link
            to={ROUTES.MARKETING_DIAGNOSTICS.HIGHLIGHTS}
            className="ml-5 text-xxs text-gtPink"
          >
            view all
          </Link>
        </div>
      ) : null}
      <div className="w-full flex flex-row space-x-4">
        <MostEffectiveCard
          highlight={highlights?.mostEffective}
          phase={highlights?.phase}
          kpi={highlights?.KPI}
          color={col1}
        />

        <MostProductiveChannel
          highlight={highlights?.mostProductive}
          phase={highlights.phase}
          kpi={highlights.KPI}
          color={col2}
        />
        <TopKpiCard
          highlight={highlights?.spend}
          phase={highlights.phase}
          kpi={highlights.KPI}
          color={col3}
        />

        <MostChangedCard
          phase={highlights.phase}
          kpi={highlights.KPI}
          highlight={highlights?.mostChanged}
          color={col4}
        />
      </div>
    </div>
  )
}

export default MDHighlights
