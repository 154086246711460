import {
    Highlights,
    MarketingDiagnostics,
    SystemLinks,
} from '../../../types/MarketingDiagnosticsTypes'
import {
    MarketingDiagnosticsActionTypes,
    MD_GET_DATA_ATTEMPT,
    MD_GET_DATA_FAIL,
    MD_GET_DATA_SUCCESS,
    MD_GET_HIGHLIGHTS_SUCCESS,
    MD_GET_INSIGHTS_ATTEMPT, MD_GET_INSIGHTS_FAIL,
    MD_GET_INSIGHTS_SUCCESS,
    MD_GET_MARKETING_DIAGNOSTICS_SUCCESS,
    MD_GET_PLAN_SUMMARY_LIST_SUCCESS,
    MD_GET_SYSTEM_LINK_SUCCESS,
} from '../../actions/MarketingDiagnosticsActionTypes'
import { RootStore } from '../Store'
import { IPlanSummary } from '../../../types/PlanTypes'

interface IDefaultState {
    loading: boolean
    headers: string[]
    marketingDiagnostics: MarketingDiagnostics | null
    planSummaryList: IPlanSummary[]
    highlights: Highlights | null
    systemLinks: SystemLinks | null
}

const defaultState: IDefaultState = {
    loading: false,
    headers: ['Channel', 'Spend', 'Impressions', 'Effectiveness', 'Productivity'],
    marketingDiagnostics: null,
    planSummaryList: [],
    highlights: null,
    systemLinks: null,
}

const marketingDiagnosticsReducer = (
    state: IDefaultState = defaultState,
    action: MarketingDiagnosticsActionTypes,
): IDefaultState => {
    switch (action.type) {
        case MD_GET_DATA_ATTEMPT:
            return { ...state, loading: true }
        case MD_GET_DATA_FAIL:
            return { ...state, loading: false }
        case MD_GET_DATA_SUCCESS:
            return { ...state, loading: false }
        case MD_GET_MARKETING_DIAGNOSTICS_SUCCESS:
            return { ...state, marketingDiagnostics: action.payload }
        case MD_GET_PLAN_SUMMARY_LIST_SUCCESS:
            return { ...state, planSummaryList: action.payload }
        case MD_GET_HIGHLIGHTS_SUCCESS:
            return { ...state, highlights: action.payload }
        case MD_GET_SYSTEM_LINK_SUCCESS:
            return { ...state, systemLinks: action.payload }
        case MD_GET_INSIGHTS_ATTEMPT:
            return { ...state, loading: true }
        case MD_GET_INSIGHTS_SUCCESS:
            return { ...state, highlights: action.payload }
        case MD_GET_INSIGHTS_FAIL:
            return { ...state, loading: false }

        default:
            return state
    }
}

export const selectMarketingDiagnosticsLoading = (state: RootStore) => {
    return state.marketingDiagnostics.loading
}

export const selectMarketingDiagnostics = (state: RootStore) => {
    return state.marketingDiagnostics.marketingDiagnostics
}

export default marketingDiagnosticsReducer
