import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const AudioIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M48.487 33.61c0 8.91-7.228 16.131-16.133 16.131-8.91 0-16.132-7.221-16.132-16.132V17.477c0-8.908 7.222-16.133 16.132-16.133 8.905 0 16.133 7.225 16.133 16.133v16.132z" />
          <path
            strokeLinecap="round"
            d="M10.844 28.232v5.377c0 12.03 9.478 21.51 21.51 21.51 12.027 0 21.51-9.48 21.51-21.51v-5.377M32.354 55.119v8.066M32.354 1.344v8.067M37.734 2.263l-.002 7.147M26.977 2.263V9.41"
          />
          <path d="M10.844 33.61h43.02" />
          <path
            strokeLinecap="round"
            d="M16.222 17.477h10.755M16.222 22.854h10.755M48.487 17.477H37.732M48.487 22.854H37.732M16.222 28.232h10.755M48.487 28.232H37.732"
          />
        </g>
      </g>
    </svg>
  )
}

export default AudioIcon
