export const sumOptimisedSpend = (transforms: any[]) => {
    return transforms.reduce((acc, transform) => {
      return acc + transform.optimised_spend
    }, 0)
  }

 export const sumCalculatedSpend = (transforms: any[]) => {
    return transforms.reduce(
      (acc: any, transform) => {
        return acc + transform.calculated_spend
      },
      0
    )
  }

  export const sumOptimisedTotals = (transforms: any[]) => {
    let init = { roi: 0, sales: 0 }
    Object.keys(transforms[0].optimised_totals).forEach(
      (key) => {
        // @ts-ignore
        init[key] = 0
      }
    )

    let totals = Object.keys(transforms[0].optimised_totals)

    return transforms.reduce((acc, transform) => {
      // @ts-ignore
      // @ts-ignore
      totals.forEach(
        (key) =>
          // @ts-ignore
          (acc[key] +=
            // @ts-ignore
            transform.optimised_totals[key])
      )
      return acc
    }, init)
  }

  export const sumCalculatedTotals = (transforms: any[]) => {
    let init = { roi: 0, sales: 0 }
    Object.keys(transforms[0].calculated_totals).forEach(
      (key) => {
        // @ts-ignore
        init[key] = 0
      }
    )

    let totals = Object.keys(transforms[0].calculated_totals)

    return transforms.reduce((acc, transform) => {
      totals.forEach(
        (key) =>
          // @ts-ignore
          (acc[key] +=
            // @ts-ignore
            transform.calculated_totals[key])
      )
      return acc
    }, init)
  }

  