import { IComparisonData } from '../types/PlanComparisonTypes'
import _ from 'lodash'
import { Settings } from '../types/SettingTypes'

export const downloadPlanComparisonCSVData = (
  planComparison: IComparisonData,
  headers: string[],
  settings: Settings
) => {

  let newHeadersLabels = headers.map((x: string) => {
    const pluralName = x + "_name"
    if(settings![pluralName]){
      return settings![pluralName]
    }
    return x
  })
  const identifiers = Object.keys(planComparison.plans[0].transformations[0].identifiers).filter(x => x !== "region_key")

  let rows: string[][] = []

  //get the row for the header
  let headerRow = ['Totals']
  planComparison.plans.forEach((x) => {
    const name = x.name ? x.name : 'Plan ' + x.id.toString()
    headerRow = [...headerRow, name + ' Spend']
  })
  planComparison.kpis.forEach((kpi) => {
    planComparison.plans.forEach((x) => {
      const name = x.name ? x.name : 'Plan ' + x.id.toString()
      headerRow = [...headerRow, name + ' ' + kpi.label]
    })
  })
  rows = [headerRow]

  //get the rows for each header
  newHeadersLabels.forEach((header, index) => {
    rows = [...rows, [header]]
    let allHeaders: string[] = []
    planComparison.plans.forEach((x) => {
      const keys = Object.keys(x[identifiers[index]])
      keys.forEach((key) => {
        allHeaders = [...allHeaders, x[identifiers[index]][key].label]
      })
    })
    const uniqueHeaders = _.uniqBy(allHeaders, function (e) {
      return e
    })
    uniqueHeaders.map((header) => {
      let newRow = [header]
      planComparison.plans.forEach((plan) => {
        newRow = [
          ...newRow,
          plan[identifiers[index]][
            header.toLowerCase().replace(/ /g, '_')
          ]?.spend.toString() || '',
        ]
      })
      planComparison.kpis.forEach((kpi) => {
        planComparison.plans.forEach((plan) => {
          newRow = [
            ...newRow,
            plan[identifiers[index]][
              header.toLowerCase().replace(/ /g, '_')
            ]?.kpis[kpi.label.toLowerCase().replace(/ /g, '_')]?.toString() || '',
          ]
        })
      })
      rows = [...rows, newRow]
    })

    //add blank row at the bottom of each section
    rows = [...rows, []]
  })

  return rows
}
