import { Children } from "react";
import { Channel } from "../../../../../types/MarketingDiagnosticsTypes";
import KpiCell from "../../../../../shared/KpiCell";
import appendUnit from "../../../../../utils/appendUnit";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/RootReducer";

type PrevData = {
  effectiveness: number;
  productivity: number;
  kpi: number;
  spend: number;
  impressions: number;
} | null
type TKpiCells = {
  current: Channel;
  prevData: PrevData;
  previous: Channel;
  cells: string[];
}
export const KpiCells = ({
  previous,
  prevData,
  current,
  cells
}: TKpiCells) => {

  const { phases } = useSelector(
    (state: AppState) => state.performanceOverview
  )
  return (
    <>
      {Children.toArray(cells.map(key => (
        <span className="grid grid-flow-col justify-around">
        <KpiCell
          previous={previous}
          prevValue={previous ? (prevData ? (prevData as any)[key].toString() : "") : "0"}
          value={appendUnit((current as any)[key], key.toLowerCase() === 'spend' ? "currency" : "", "", "")}
          phaseCount={phases.length}
        />
        </span>
      )))}

    </>
  );
};
