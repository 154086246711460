import { useAlertProps } from '@gain-theory/alert'
import { FileUploadProps } from '@gain-theory/file-upload'
import { useEffect, useState } from 'react'
import {
  GroupingLevel,
  Observation,
  Transformation,
} from '../../../../../../types/PlanTypes'
import { TimeConstraintErrorType } from '../../types'
import { getErrorList } from '../../utils/get-error-list'
import { useTimeConstraintsNotification } from '../use-time-constraints-notification'
import { parseFile } from './parse-file'
import { Settings } from '../../../../../../types/SettingTypes'

interface IFileUploadProps {
  groupingLevels: GroupingLevel[]
  observations: Observation[]
  transformationOptions: Transformation[]
  numberFormat: string | undefined
  onUploadSuccess: (data: Record<string, string>[]) => void
  settings: Settings | null
}

const DIMENSIONS_START_INDEX = 0

export const useFileUpload = ({
  groupingLevels,
  observations,
  transformationOptions,
  numberFormat,
  onUploadSuccess,
  settings
}: IFileUploadProps) => {
  const numberOfLevels = groupingLevels.length || 0
  const lastHierarchyLevelIndex = DIMENSIONS_START_INDEX + numberOfLevels - 1
  const observationsStartIndex = lastHierarchyLevelIndex + 3 // +1 for type, +1 for status

  const nonHaloTransformations = transformationOptions.filter(
    (trans) => !trans.is_halo
  )

  const { alertProps, closeAlert, showSuccessAlert, showErrorAlert } =
    useAlertProps({})

  const notification = useTimeConstraintsNotification()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<
    FileUploadProps['uploadProgress']
  >({
    progress: 0,
    status: undefined,
  })

  const handleToggleFileUpload = (show: boolean) => {
    closeAlert()
    setSelectedFile(null)
    setShowFileUpload(show)
  }

  const handleChangeFile: FileUploadProps['onFileDrop'] = (file) => {
    closeAlert()
    setSelectedFile(file)
  }

  const handleDeleteFile: FileUploadProps['onDeleteFile'] = () => {
    closeAlert()
    setSelectedFile(null)
  }

  const handleInvalidFile: FileUploadProps['onInvalidFile'] = (invalidType) => {
    if (invalidType !== 'fileType') {
      return
    }

    showErrorAlert({
      title: 'Error',
      description: 'The selected file type is not a supported type.',
    })
  }

  const handleCatchErrorFileUpload = (
    error: TimeConstraintErrorType | TimeConstraintErrorType[]
  ) => {
    setUploadProgress((prev) => ({
      progress: prev?.progress || 50,
      status: 'error',
    }))

    const errors = getErrorList(error)

    showErrorAlert({
      title: 'Error',
      description: errors,
    })
  }

  const handleSuccessFileUpload = (
    res: Awaited<ReturnType<typeof parseFile>>
  ) => {
    onUploadSuccess(res.rows)

    setTimeout(() => {
      showSuccessAlert({
        title: 'Success',
        description: 'File uploaded successfully',
      })

      setUploadProgress({
        progress: 100,
        status: 'success',
      })
    }, 1000)

    setTimeout(() => {
      handleToggleFileUpload(false)
      notification.planFileUploadedSuccessfully()
    }, 2000)
  }

  const handleFileUpload = async (file: File) => {
    const parseFileProps: Parameters<typeof parseFile>[0] = {
      file,
      observations,
      groupingLevels,
      lastHierarchyLevelIndex,
      observationsStartIndex,
      numberFormat,
      hierarchyStartIndex: DIMENSIONS_START_INDEX,
      transformationOptions: nonHaloTransformations,
      updateProgress: (number) => {
        setUploadProgress((prev) => ({
          progress: number,
          status: prev?.status || 'inProgress',
        }))
      },
      settings: settings
    }

    await parseFile(parseFileProps)
      .then(handleSuccessFileUpload)
      .catch(handleCatchErrorFileUpload)
  }

  const fileUploadProps: FileUploadProps = {
    selectedFile,
    alertProps,
    uploadProgress,
    fileType: ['csv', 'xlsx'],
    translations: {
      DRAG_TO_UPLOAD_CTA: 'Drag your file here to upload',
      OR: 'or',
      UP_TO: 'up to',
      SELECT_FILE: 'select file',
      DROP_FILE_TO_UPLOAD_CTA: 'Drop your file here to upload.',
      DROP_FILE_TO_REPLACE_CTA: 'Drop your file here to replace the current.',
      FILE_UPLOAD: 'File upload',
      REMOVE_FILE_TOOLTIP: 'Remove file',
    },
    onFileDrop: handleChangeFile,
    onDeleteFile: handleDeleteFile,
    onInvalidFile: handleInvalidFile,
  }

  useEffect(() => {
    if (selectedFile === null) {
      setUploadProgress({ progress: 0, status: undefined })
      return
    }

    setUploadProgress({ progress: 0, status: 'inProgress' })
    handleFileUpload(selectedFile)
  }, [selectedFile])

  return { fileUploadProps, showFileUpload, handleToggleFileUpload }
}
