import _ from 'lodash'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../../Routes'
import { GetConfigById } from '../../../redux/actions/ConfigActions'
import { getConfigById } from '../../../redux/reducers/ConfigReducer'
import {
  selectCreateError,
  selectLoading,
  selectPlanCreatedComplete,
  selectPlanItem,
} from '../../../redux/reducers/PlanReducer'
import { AppState } from '../../../redux/reducers/RootReducer'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { RootStore } from '../../../redux/reducers/Store'
import { MainLayout } from '../../../shared/MainLayout'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { createSPOScenario } from '../../../socket.io'
import {
  CheckBoxItem,
  Options,
  RouteComponentProps,
  SelectedOptions,
} from '../../../types/CreatePlanTypes'
import {
  Constraint,
  IO,
  Observation,
  Transformation,
} from '../../../types/PlanTypes'
import appendUnit from '../../../utils/appendUnit'
import { useBudgetConstaint } from '../shared/hooks/use-bugdet-constraints'
import { useFilterConstraintsAndObservations } from '../shared/hooks/use-filter-constraints-and-observations'
import TimePeriodBudgetConstraints from '../shared/time-period-budget-constraints'
import PlanCurrencyAndBudgetStep from './PlanCurrencyAndBudgetStep'
import PlanDimensionLevelStep from './PlanDimensionLevelStep'
import PlanGoalStep from './PlanGoalStep'
import PlanModifyTimeConstraints from './PlanModifyTimeConstraintsStep'
import PlanNameStep from './PlanNameStep'
import PlanTimePeriodStep from './PlanTimePeriodStep'
import { DEFAULT_DATE_FORMAT } from '../../../utils/constants'

interface MatchParams {
  id: string
  optimisationType: string
}

const mapState = (state: RootStore) => ({
  config: getConfigById(state),
  user: selectSessionUser(state),
  optimiserError: selectCreateError(state),
  planCreated: selectPlanCreatedComplete(state),
  plan: selectPlanItem(state),
  loading: selectLoading(state),
})

const mapDispatchToProps = {
  onGetConfigById: GetConfigById,
  createSPOPlan: createSPOScenario,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const CreateMarketingPlan: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const optimisationType = props.match?.params.optimisationType

  const history = useHistory()
  const { settings } = useSelector((state: AppState) => state.settings)
  const { loadingSession, user } = useSelector(
    (state: AppState) => state.session
  )

  const [step, setStep] = useState<number>(0)
  const [hasPermission, setHasPermission] = useState<boolean>(false)

  const [planName, setPlanName] = useState<string>('')
  const [options, setOptions] = useState<Options>({})
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const [optimisationGoal, setOptimisationGoal] = useState<string>('')
  const [currencySymbol, setCurrencySymbol] = useState<string>('')
  const [transformationOptions, setTransformationOptions] = useState<
    Transformation[]
  >([])
  const [overallConstraint, setOverallConstraint] = useState<number>(0)
  const [observations, setObservations] = useState<Observation[]>([])
  const [selectedObservations, setSelectedObservations] = useState<number[]>([])

  const [goalOptions, setGoalOptions] = useState<IO[]>([])
  const [modifyTimeConstraints, setModifyTimeConstraints] =
    useState<boolean>(false)

  const [dimensionKeys, setDimensionKeys] = useState<string[]>([])
  const [lastDimensionStep, setLastDimensionStep] = useState<number>(1)
  const [error, setError] = useState('')

  const { isBudgetConstraintEnabled } = useBudgetConstaint()

  const { filteredConstraints, filteredObservations } =
    useFilterConstraintsAndObservations({
      constraints: props.config?.constraints || [],
      transformationOptions,
      observations: observations.map((observation) => ({
        ...observation,
        // Plan observations come formatted from the backend, so we need to format them again
        label: moment(
          observation.label,
          settings?.date_format ?? DEFAULT_DATE_FORMAT.LABEL
        ).format(DEFAULT_DATE_FORMAT.LABEL),
      })),
      selectedObservations,
    })

  useEffect(() => {
    setError(props.optimiserError!)
  }, [props.optimiserError])

  useEffect(() => {
    if (props.planCreated) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', props.plan.id)
        .toString()

      history.push(url)
    }
  }, [props.planCreated])

  useEffect(() => {
    if (!user || !user.userGroups || user.userGroups.length === 0) return

    const userHasPermission =
      user?.userGroups.find((userGroup) =>
        userGroup.group.modules?.includes('spo')
      ) !== undefined

    setHasPermission(userHasPermission)
    if (!userHasPermission) return

    props.onGetConfigById(props.match.params.id)
  }, [user])

  useEffect(() => {
    const optimisableKPIs = props.config
      ? props.config.options.optimisableKPIs
      : ''

    const outputs =
      props.config && props.config.transformations[0]
        ? props.config.transformations[0].io
            .filter((x) => x.type === 'output')
            .filter((x) => {
              if (optimisableKPIs.indexOf(x.key) !== -1) {
                return x
              }
            })
            .sort(function (a, b) {
              return (
                optimisableKPIs.indexOf(a.key) - optimisableKPIs.indexOf(b.key)
              )
            })
        : []

    setGoalOptions(outputs)
  }, [props.config])

  useEffect(() => {
    if (!props.config) return

    const dateFormat = settings?.date_format

    const initialOptimisationGoal = props.config.options.optimisableKPIs[0]
    const defaultCurrencySymbol =
      props.config.options.exchangeRates.defaultSymbol
    const transOptions = props.config.transformations.filter(
      (trans) => !trans.is_halo
    )

    const carryoverObs = props.config.observations.slice(
      0,
      props.config.observations.length - props.config.options.carryoverWeeks
    )

    carryoverObs.forEach(
      (obs) => (obs.label = moment.utc(obs.label).format(dateFormat))
    )

    const firstLevelValues = props.config.grouping_levels[0].values.sort(
      (a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      }
    )

    const filteredFirstLevelValues: CheckBoxItem[] = firstLevelValues
      .map((x) => {
        const level = {
          ...x,
          isChecked: false,
        }
        return level
      })
      .filter((x) => {
        if (props.user?.permissions.indexOf(x.key) !== -1) {
          return x
        }
      })

    const levelKeys = props.config.grouping_levels.map((x) => x.key)
    setDimensionKeys(levelKeys)

    const optionsVals: Options = {}
    const selectedVals: SelectedOptions = {}
    for (let key of levelKeys) {
      optionsVals[key] = []
      selectedVals[key] = []
    }
    optionsVals[levelKeys[0]] = filteredFirstLevelValues

    setObservations(carryoverObs)
    setSelectedOptions(selectedVals)
    setOptimisationGoal(initialOptimisationGoal)
    setCurrencySymbol(defaultCurrencySymbol)
    setTransformationOptions(transOptions)

    if (optionsVals[levelKeys[0]].length === 0) {
      setHasPermission(false)
    } else {
      setOptions(optionsVals)
    }

    setLastDimensionStep(props.config.grouping_levels.length)
  }, [props.config])

  const getDimensionPlural = (): string => {
    const levelKey = dimensionKeys[step - 1]

    const levelLabel = props.config?.grouping_levels.find(
      (lev) => lev.key === levelKey
    )

    const levelName = levelLabel ? levelLabel.label : ''
    const levelNameup = levelKey + '_plural'

    const name =
      settings && settings[levelNameup]
        ? settings[levelNameup].toLowerCase()
        : levelName.toLowerCase()

    return name
  }

  const calculateOptions = (
    parent: string,
    groupingLevel: string,
    values: string[]
  ): CheckBoxItem[] => {
    const newTransformationOptions = transformationOptions.filter((x) => {
      if (values.length !== 0) {
        if (values.includes(x.identifiers[parent])) {
          return x
        }
      } else {
        return x
      }
    })

    const keys = newTransformationOptions.map(
      (x) => x.identifiers[groupingLevel]
    )

    const uniqueKeys = _.uniqBy(keys, function (e) {
      return e
    })

    const allLevels =
      props.config?.grouping_levels.find((x) => x.key === groupingLevel)
        ?.values || []

    const levels = uniqueKeys.map((x) => {
      const level = {
        key: x,
        label: allLevels.find((y) => y.key === x)?.label || '',
        isChecked: selectedOptions[groupingLevel].includes(x) ? true : false,
      }
      return level
    })

    setTransformationOptions(newTransformationOptions)

    return levels
  }

  const calculateTransformationsForLastDimension = (
    groupingLevel: string,
    values: string[]
  ) => {
    const newTransformationOptions = transformationOptions.filter((x) => {
      if (values.length !== 0) {
        if (values.includes(x.identifiers[groupingLevel])) {
          return x
        }
      } else {
        return x
      }
    })

    setTransformationOptions(newTransformationOptions)
  }

  const unFilterTransformations = () => {
    let newTransforms =
      props.config?.transformations.filter((trans) => !trans.is_halo) || []
    //this stops the user from hitting 'Previous' but the transformation list still being filtered from calculateOptions()
    if (step <= lastDimensionStep && step !== 1) {
      for (let i = step - 1; i > 1; i--) {
        newTransforms = newTransforms.filter((x) => {
          if (selectedOptions[dimensionKeys[step - 2]].length !== 0) {
            if (
              selectedOptions[dimensionKeys[step - 2]].includes(
                x.identifiers[dimensionKeys[step - 2]]
              )
            ) {
              return x
            }
          } else {
            return x
          }
        })
      }

      setTransformationOptions(newTransforms)
    }
  }

  const getNewOptions = (values: string[]) => {
    if (step !== 0 && step < lastDimensionStep) {
      const currentLevelKey = dimensionKeys[step - 1]
      const nextLevelKey = dimensionKeys[step]

      const nextLevels = calculateOptions(currentLevelKey, nextLevelKey, values)

      setOptions({ ...options, [nextLevelKey]: nextLevels })

      return
    }

    if (step === lastDimensionStep) {
      calculateTransformationsForLastDimension(dimensionKeys[step - 1], values)
    }
  }

  const handlePreviousStep = () => {
    unFilterTransformations()
    setError('')
    setStep((prev) => prev - 1)
  }

  const handleNextDimensionStep = (values: string[]) => {
    getNewOptions(values)
    setSelectedOptions({
      ...selectedOptions,
      [dimensionKeys[step - 1]]: values,
    })
    setStep((prev) => prev + 1)
  }

  const getSelectedCurrency = (symbol: string) => {
    const index = props.config
      ? props.config.options.exchangeRates.currencySymbols.indexOf(symbol)
      : 0
    const overallConstraintCurrencyDivisor = props.config
      ? props.config.options.exchangeRates.rates[index]
      : 0

    const defaultCurrency = props.config
      ? props.config.options.exchangeRates.currencies[index]
      : ''

    const defaultCurrencySymbol = props.config
      ? props.config.options.exchangeRates.currencySymbols[index]
      : ''

    const selectedCurrency = {
      rate: overallConstraintCurrencyDivisor,
      currency: defaultCurrency,
      symbol: defaultCurrencySymbol,
    }

    return selectedCurrency
  }

  const getGoalUnit = () => {
    let unit: string | undefined = 'currency'

    if (
      props.config &&
      props.config.transformations[0].io.find((x) => x.key === optimisationGoal)
    ) {
      unit = props.config.transformations[0].io.find(
        (x) => x.key === optimisationGoal
      )?.unit
    }

    return unit
  }

  const validateOverallConstraint = (): boolean => {
    const spendRange = props.config?.options.kpiRanges.find(
      (range) => range.kpi === 'spend'
    )
    const minOptimisationMetric = spendRange?.min || 1000
    const maxOptimisationMetric = spendRange?.max || 100000000

    const numberFormat = settings?.number_format || ''

    if (isNaN(overallConstraint)) {
      setError('Please enter a valid number')
      return false
    } else if (
      overallConstraint > maxOptimisationMetric ||
      overallConstraint < minOptimisationMetric
    ) {
      setError(`Please enter a valid number between ${appendUnit(
        minOptimisationMetric,
        'currency',
        currencySymbol,
        numberFormat
      )}
      and ${appendUnit(
        maxOptimisationMetric,
        'currency',
        currencySymbol,
        numberFormat
      )}.`)
      return false
    }
    return true
  }

  const submit = (constraints?: Constraint[]) => {
    const validated = validateOverallConstraint()

    if (validated) {
      const currency = getSelectedCurrency(currencySymbol)
      const attrs = {
        optimisationType: props.match?.params.optimisationType,
        grouping_levels: selectedOptions,
        observations: selectedObservations,
        optimisationMetric: optimisationGoal,
        overallConstraint: overallConstraint,
        defaultCurrency: currency,
      }

      if (
        props.config &&
        attrs.observations[1] !== props.config.observations.length - 1
      ) {
        attrs.observations[1] =
          attrs.observations[1] + props.config.options.carryoverWeeks
      }

      //pineapple: NEED TO REFACTOR ONCE ADMIN IS HOOKED UP

      // if (
      //   attrs.grouping_levels.region.length === 0 &&
      //   props.config &&
      //   props.user
      // ) {
      //   const regionsInConfig = props.config.grouping_levels.find(
      //     (x) => x.key === 'region'
      //   )
      //   const keys = regionsInConfig?.values.map((x) => x.key) || []
      //   const userRegions = props.user.permissions.filter(
      //     (x) => keys.indexOf(x) !== -1
      //   )

      //   attrs.grouping_levels.region = userRegions
      // }
      if (props.user) {
        props.createSPOPlan(
          {
            user: props.user,
            configId: props.match.params.id,
            attrs: attrs,
            constraints: constraints,
          },
          planName
        )
      }
    }
  }

  // TODO (TPBC): Uncomment this line when time period budget constraints is deployed to develop
  // getCurrencyLabelFromSymbol is used to get the currency name from the currency symbol
  const getCurrencyLabelFromSymbol = (symbol: string) => {
    const index = props.config
      ? props.config.options.exchangeRates.currencySymbols.indexOf(symbol)
      : 0
    const name = props.config
      ? props.config.options.exchangeRates.currencies[index]
      : 'USD'

    return `${symbol} (${name})`
  }

  if (loadingSession || props.loading) {
    return <LoadingModal />
  }

  if (!hasPermission) {
    return (
      <div className="mx-auto my-auto ">
        <div>
          You do not have the correct permissions. Please contact your admin...
        </div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col gap-md justify-center items-center">
      {step === 0 && (
        <PlanNameStep
          selectedName={planName}
          setSelectedName={setPlanName}
          setStep={setStep}
        />
      )}
      {step > 0 && step <= lastDimensionStep && (
        <PlanDimensionLevelStep
          dimensionPlural={getDimensionPlural()}
          dimensionKey={dimensionKeys[step - 1]}
          selectedValues={selectedOptions[dimensionKeys[step - 1]]}
          options={options[dimensionKeys[step - 1]]}
          handlePreviousDimensionStep={handlePreviousStep}
          handleNextDimensionStep={handleNextDimensionStep}
        />
      )}
      {step === lastDimensionStep + 1 && (
        <PlanTimePeriodStep
          dateFormat={settings?.date_format}
          observations={observations}
          selectedObservations={selectedObservations}
          setSelectedObservations={setSelectedObservations}
          setStep={setStep}
        />
      )}
      {step === lastDimensionStep + 2 && (
        <PlanGoalStep
          options={goalOptions}
          optimisationType={optimisationType}
          selectedGoal={optimisationGoal}
          setSelectedGoal={setOptimisationGoal}
          setStep={setStep}
        />
      )}
      {step === lastDimensionStep + 3 && (
        <PlanCurrencyAndBudgetStep
          isEuroFormat={settings?.number_format === 'euro'}
          selectedCurrency={currencySymbol}
          selectedBudget={overallConstraint}
          exchangeRates={props.config?.options.exchangeRates}
          setSelectedCurrency={setCurrencySymbol}
          setSelectedBudget={setOverallConstraint}
          setStep={setStep}
          optimisationType={optimisationType}
          hasCurrencySelector={
            getGoalUnit() === 'currency' || optimisationType === 'budget'
          }
          goalLabelForKPI={
            props.config?.transformations[0].io.find(
              (x) => x.key === optimisationGoal
            )?.label
          }
          isBudgetConstraintEnabled={isBudgetConstraintEnabled}
          handleGeneratePlan={submit}
        />
      )}
      {isBudgetConstraintEnabled && step === lastDimensionStep + 4 && (
        <PlanModifyTimeConstraints
          modifyConstraints={modifyTimeConstraints}
          setModifyConstraints={setModifyTimeConstraints}
          setStep={setStep}
          handleGeneratePlan={submit}
        />
      )}
      {isBudgetConstraintEnabled && step === lastDimensionStep + 5 && props.config &&(
        <TimePeriodBudgetConstraints
          budget={overallConstraint}
          constraints={filteredConstraints}
          currencyLabel={getCurrencyLabelFromSymbol(currencySymbol)}
          groupingLevels={props.config?.grouping_levels || []}
          handleBack={() => setStep((prev) => prev - 1)}
          handleGeneratePlan={submit}
          observations={filteredObservations}
          planGenerationType="create"
          settings={settings}
          transformationOptions={transformationOptions}
          generatePlanError={props.optimiserError}
          carryoverWeeks={props.config.options.carryoverWeeks}
        />
      )}
      {error !== '' && step !== lastDimensionStep + 5 && (
        <div className={'text-center text-destructive-main'}>{error}</div>
      )}
    </div>
  )
}

export default connector(MainLayout(CreateMarketingPlan, true))
