import XLSX from 'xlsx-populate/browser/xlsx-populate'
// import { FLOW_CHART_BAR_COLORS } from "../constants";
import moment from 'moment'
import { FLOW_CHART_BAR_COLORS } from './colours'

export const downloadFlowChart = (scenario, dateFormat) => {
  XLSX.fromBlankAsync().then((workbook) => {
    updateWorkSheet(workbook, scenario, dateFormat)

    const groupingLevelCellWidth = 12
    const cellWidth = 15
    const cellHeight = 25

    const filename = scenario.name
      ? `${scenario.name}.xlsx`
      : `Plan${scenario.id}.xlsx`

    const rowLength = workbook.sheet('Sheet1')._rows.length
    // const colLength =

    const columnsLength =
      scenario.config.grouping_levels.length +
      (scenario.config.observations.length - scenario.options.carryoverWeeks) +
      1
    for (let i = 0; i < columnsLength; i++) {
      workbook
        .sheet('Sheet1')
        .column(getColumnAddress(i))
        .width(cellWidth)
        .style('borderColor', 'ffffff')
    }
    for (let i = 1; i < rowLength; i++) {
      workbook.sheet('Sheet1').row(i).height(cellHeight)
    }

    scenario.config.grouping_levels.forEach((level, index) => {
      workbook
        .sheet('Sheet1')
        .column(colums[index])
        .width(groupingLevelCellWidth)
    })

    workbook.outputAsync().then(function (blob) {
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = filename
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })

    // Write to file.
    // return workbook.toFileAsync("./out.xlsx");
  })
}

const colums = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const updateWorkSheet = (workbook, scenario, dateFormat) => {
  let data = createFlowChartData(scenario, dateFormat)

  for (let i = 0; i < data.length; i++) {
    let row = data[i]
    for (let j = 0; j < row.length; j++) {
      let columnAddress = getCellAddress(j, i + 1)

      let cellData = row[j]

      if (cellData.type === CellTypes.SPEND) {
        workbook
          .sheet('Sheet1')
          .cell(columnAddress)
          .style({
            fill: cellData.fill,
            bold: true,
            fontColor: cellData.fontColor,
            verticalAlignment: 'center',
            horizontalAlignment: 'center',
            numberFormat: '#,##0.00',
          })
          .value(cellData.label)
      }

      if (cellData.type === CellTypes.DEFAULT) {
        workbook.sheet('Sheet1').cell(columnAddress).value(cellData.label)
      }
    }
  }
}

const createFlowChartData = (scenario, df) => {
  const transformations = scenario.transformations
  const groupingLevels = scenario.config.grouping_levels
  const observations = scenario.config.observations.slice(
    0,
    scenario.config.observations.length - scenario.options.carryoverWeeks
  )

  let data = []
  let currentLevel = ''

  const levelKeys = Object.keys(scenario.grouping_levels)

  let monthRow = []
  let weekNumberRow = []

  for (let i = 0; i < groupingLevels.length - 1; i++) {
    monthRow.push(createEmptyCellItem())
    weekNumberRow.push(createEmptyCellItem())
  }

  monthRow.push({ label: 'Date', fill: 'ffffff', fontColor: '000000' })
  weekNumberRow.push({
    label: 'Week Number',
    fill: 'ffffff',
    fontColor: '000000',
  })

  observations.forEach((observation, index) => {
    monthRow.push(createMonthCellItem(observation.label, df))
    weekNumberRow.push(createWeekNumberCellItem(index + 1))
  })

  data.push(monthRow)
  data.push(weekNumberRow)

  const lastGroupingLevel = levelKeys[levelKeys.length - 1]


  transformations.forEach((transformation) => {
    let row = []

    for(let lev of levelKeys){

      const level = groupingLevels.find((groupinglevel) => {
        return groupinglevel.key === lev
      })
      if(lev !== lastGroupingLevel){
        let cellItem
        if (currentLevel !== transformation.identifiers[lev]) {
          currentLevel = transformation.identifiers[lev]
          cellItem = createDefaultCellItem(currentLevel, level.values)
          row.push(cellItem)
        } else {
          cellItem = createEmptyCellItem()
          row.push(cellItem)
        }
      } else {
        row.push(
          createDefaultCellItem(transformation.identifiers[lev], level.values)
        )
      }

    }



    for (let j = 0; j < scenario.observations_min; j++) {
      row.push(createEmptyCellItem())
    }

    transformation.inputs.spend.forEach((spend) => {
      const lastLevelValues = groupingLevels.find((groupinglevel) => {
        return groupinglevel.key === lastGroupingLevel
      })
      if (spend === 0) {
        row.push(createEmptyCellItem())
      } else {
        row.push(
          createSpendCellItem(
            spend,
            transformation.identifiers[lastGroupingLevel],
            lastLevelValues.values,
            scenario.options.exchangeRates.defaultSymbol
          )
        )
      }
    })
    data.push(row)
  })

  //
  return data
}

export const getCellAddress = (columnNumber, rowNumber) => {
  return `${getColumnAddress(columnNumber)}${rowNumber}`
}

const getColumnAddress = (columnNumber) => {
  if (columnNumber < 26) {
    return `${colums[columnNumber]}`
  } else {
    return `${colums[Math.floor(columnNumber / colums.length) - 1]}${
      colums[columnNumber % colums.length]
    }`
  }
}

const createDefaultCellItem = (item, collection) => {
  let found = collection.find((collectionItem) => {
    return collectionItem.key === item
  })
  return {
    label: found ? found.label : '',
    fill: 'ffffff',
    fontColor: '000000',
    type: CellTypes.DEFAULT,
  }
}

const createEmptyCellItem = () => {
  return {
    label: '',
    fill: 'ffffff',
    fontColor: '000000',
    type: CellTypes.DEFAULT,
  }
}

const createSpendCellItem = (spend, mediaKey, mediaLevels, currencySymbol) => {
  let colourKey = 0

  mediaLevels.forEach((mediaLevel, index) => {
    if (mediaLevel.key === mediaKey) {
      colourKey = index
    }
  })
  // appendUnit()
  return {
    label: spend,
    fill: FLOW_CHART_BAR_COLORS[colourKey % FLOW_CHART_BAR_COLORS.length],
    fontColor: 'ffffff',
    type: CellTypes.SPEND,
  }
}

const createWeekNumberCellItem = (observationNumber) => {
  return {
    label: observationNumber,
    fill: 'ffffff',
    fontColor: '000000',
    type: CellTypes.DEFAULT,
  }
}

const createMonthCellItem = (date, format) => {
  return {
    label: moment(date, format).format('MMMM DD'),
    fill: 'ffffff',
    fontColor: '000000',
    type: CellTypes.DEFAULT,
  }
}

const CellTypes = {
  MONTH: 'MONTH',
  DEFAULT: 'DEFAULT',
  SPEND: 'SPEND',
  style: {},
}
