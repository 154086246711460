import { Config } from '../../types/ConfigTypes'

export const CONFIG_GET_ALL_ATTEMPT = 'CONFIG_GET_ALL_ATTEMPT'
export const CONFIG_GET_ALL_SUCCESS = 'CONFIG_GET_ALL_SUCCESS'
export const CONFIG_GET_ALL_FAIL = 'CONFIG_GET_ALL_FAIL'
export const CONFIG_GET_BY_ID_ATTEMPT = 'CONFIG_GET_BY_ID_ATTEMPT'
export const CONFIG_GET_BY_ID_SUCCESS = 'CONFIG_GET_BY_ID_SUCCESS'
export const CONFIG_GET_BY_ID_FAIL = 'CONFIG_GET_BY_ID_FAIL'
export const CREATE_APP_CONFIG_ATTEMPT = 'CREATE_APP_CONFIG_ATTEMPT'
export const CREATE_APP_CONFIG_SUCCESS = 'CREATE_APP_CONFIG_SUCCESS'
export const CREATE_APP_CONFIG_FAIL = 'CREATE_APP_CONFIG_FAIL'
export const CREATE_MD_CONFIG_ATTEMPT = 'CREATE_MD_CONFIG_ATTEMPT'
export const CREATE_MD_CONFIG_SUCCESS = 'CREATE_MD_CONFIG_SUCCESS'
export const CREATE_MD_CONFIG_FAIL = 'CREATE_MD_CONFIG_FAIL'
export const CREATE_SPO_CONFIG_ATTEMPT = 'CREATE_SPO_CONFIG_ATTEMPT'
export const CREATE_SPO_CONFIG_SUCCESS = 'CREATE_SPO_CONFIG_SUCCESS'
export const CREATE_SPO_CONFIG_FAIL = 'CREATE_SPO_CONFIG_FAIL'

interface ConfigGetAllAttempt {
  type: typeof CONFIG_GET_ALL_ATTEMPT
}

interface ConfigGetByIdAttempt {
  type: typeof CONFIG_GET_BY_ID_ATTEMPT
}

interface ConfigGetByIdFail {
  type: typeof CONFIG_GET_BY_ID_FAIL
}

interface ConfigGetByIdSuccess {
  type: typeof CONFIG_GET_BY_ID_SUCCESS
  payload: Config
}

interface ConfigGetAllSuccess {
  type: typeof CONFIG_GET_ALL_SUCCESS
  payload: Config[]
}

interface ConfigGetAllFail {
  type: typeof CONFIG_GET_ALL_FAIL
}

interface CreateAppConfigAttempt {
  type: typeof CREATE_APP_CONFIG_ATTEMPT
}

interface CreateAppConfigSuccess {
  type: typeof CREATE_APP_CONFIG_SUCCESS
}

interface CreateAppConfigFail {
  type: typeof CREATE_APP_CONFIG_FAIL
}

interface CreateMDConfigAttempt {
  type: typeof CREATE_MD_CONFIG_ATTEMPT
}

interface CreateMDConfigSuccess {
  type: typeof CREATE_MD_CONFIG_SUCCESS
}

interface CreateMDConfigFail {
  type: typeof CREATE_MD_CONFIG_FAIL
}

interface CreateSPOConfigAttempt {
  type: typeof CREATE_SPO_CONFIG_ATTEMPT
}

interface CreateSPOConfigSuccess {
  type: typeof CREATE_SPO_CONFIG_SUCCESS
}

interface CreateSPOConfigFail {
  type: typeof CREATE_SPO_CONFIG_FAIL
}

export type ConfigActionTypes =
  | ConfigGetByIdAttempt
  | ConfigGetByIdFail
  | ConfigGetByIdSuccess
  | ConfigGetAllAttempt
  | ConfigGetAllSuccess
  | ConfigGetAllFail
  | CreateAppConfigAttempt
  | CreateAppConfigSuccess
  | CreateAppConfigFail
  | CreateMDConfigAttempt
  | CreateMDConfigSuccess
  | CreateMDConfigFail
  | CreateSPOConfigAttempt
  | CreateSPOConfigSuccess
  | CreateSPOConfigFail
