import { Plan, PlanOutput } from '../../types/PlanTypes'

export const CREATE_ALL_BRANDS = 'CREATE_ALL_BRANDS'
export const SELECT_ALL_BRANDS = 'SELECT_ALL_BRANDS'

interface CreateAllBrands {
  type: typeof CREATE_ALL_BRANDS
  payload: PlanOutput
}

interface SelectAllBrands {
  type: typeof SELECT_ALL_BRANDS
  payload: boolean
}

export type AllBrandsActionTypes = CreateAllBrands | SelectAllBrands
