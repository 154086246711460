export const MAX_USERNAME_DISPLAY_LENGTH = 25

// Legal constants
export const TERMS_AND_CONDITIONS = {
  label: 'Terms & Conditions',
  link: 'https://www.gaintheory.com/platform-terms/',
}
export const PRIVACY_POLICY = {
  label: 'Privacy Policy',
  link: 'https://www.gaintheory.com/platform-privacy-policy/',
}

export const WEBSITE_LINK = {
  label: 'Gain Theory',
  link: 'https://www.gaintheory.com/',
}

export const COST_NAME = 'cost_per_grp'
export const COST_NAME_IN_CHANNEL = 'cpm'

export const translations = {
  DRAG_TO_UPLOAD_CTA: 'Drag your file here to upload',

  OR: 'or',

  UP_TO: 'up to',

  SELECT_FILE: 'select file',

  DROP_FILE_TO_UPLOAD_CTA: 'Drop your file here to upload.',

  DROP_FILE_TO_REPLACE_CTA: 'Drop your file here to replace the current.',

  FILE_UPLOAD: 'File upload',

  REMOVE_FILE_TOOLTIP: 'Remove file',
}

export const TC_ACKNOWLEDGE_COOKIE_NAME = 't&c_acknowledged'

export const FEATURE_FLAGS = {
  PROPORTIONAL_FLIGHTING: 'proportional_flighting',
}

export const DEFAULT_SUPPORT_CONTACT = 'gti_support@gaintheory.com'

export const DEFAULT_DATE_FORMAT = {
  KEY: 'YYYY_MM_DD',
  LABEL: 'YYYY-MM-DD',
}
