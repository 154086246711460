import {
  PLAN_LOAD_COMPLETE,
  SocketActionTypes,
  SOCKET_SET_CONSTRAINTS_ERROR,
  SOCKET_RESET_CONSTRAINTS_ERROR,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_FAIL,
  SOCKET_SET_CONSTRAINTS_FROM_TABLE_SUCCESS,
} from '../actions/SocketActionTypes'
import { RootStore } from './Store'

interface IDefaultState {
  error: null | string
  loading: boolean
  success: boolean
}

const defaultState: IDefaultState = {
  error: null,
  loading: false,
  success: false,
}

const constraintsReducer = (
  state: IDefaultState = defaultState,
  action: SocketActionTypes
) => {
  switch (action.type) {
    case SOCKET_SET_CONSTRAINTS_FROM_TABLE_ATTEMPT:
      return {
        ...state,
        loading: true,
      }
    case PLAN_LOAD_COMPLETE:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case SOCKET_SET_CONSTRAINTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SOCKET_RESET_CONSTRAINTS_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
      return defaultState
  }
}

export const getConstraintsError = (state: RootStore) => {
  return state.constraints.error
}

export const getConstraintsLoading = (state: RootStore) => {
  return state.constraints.loading
}

export const getConstraintsSuccess = (state: RootStore) => {
  return state.constraints.success
}

export default constraintsReducer
