import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const SearchIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={stroke || '#666'} strokeLinejoin="round" strokeWidth="2.0">
          <path d="M43.313 22.313c0 11.597-9.403 21-21 21-11.598 0-21-9.403-21-21 0-11.598 9.402-21 21-21 11.597 0 21 9.402 21 21z" />
          <path strokeLinecap="round" d="M37.16 37.16l24.528 24.528" />
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
