import { Typography } from '@gain-theory/typography'
import { divide } from 'lodash'
import React, { FC, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { UpdateSettings } from '../../redux/actions/SettingsActions'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import { readUsersFile } from '../../utils/readXlsx'
import AdminHeader from './AdminHeader'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
})

const mapDispatchToProps = {
  updateSettings: UpdateSettings,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const UploadUsers: FC<PropsFromRedux> = (props) => {
  const [file, setFile] = useState(null)
  const changeFile = (e: any) => {
    setFile(e.target.files[0])
  }
  const uploadUsers = () => {
    readUsersFile(file)
  }
  return (
    <div className={'container'}>
      <AdminHeader />
      <Typography.H4 className="my-6">Upload Users</Typography.H4>
      <div className={'flex flex-col'}>
        <input type="file" onChange={changeFile} />
        <button
          className="rounded bg-gtPink text-white text-xs px-8 py-2 mt-4 w-32"
          onClick={uploadUsers}
        >
          SUBMIT
        </button>
      </div>
    </div>
  )
}

export default connector(MainLayout(UploadUsers))
