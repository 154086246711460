import {
  GroupingLevel,
  Plan,
  IPlanSummary,
  ScenarioRegion,
  Transformation,
  GroupingLevelValue,
  PlanGroupingLevel,
} from '../../types/PlanTypes'

export const PLAN_GET_SUMMARIES_ATTEMPT = 'PLAN_GET_SUMMARIES_ATTEMPT'
export const PLAN_GET_SUMMARIES_SUCCESS = 'PLAN_GET_SUMMARIES_SUCCESS'
export const PLAN_GET_SUMMARIES_FAIL = 'PLAN_GET_SUMMARIES_FAIL'
export const PLAN_CHANGE_KPI_DISPLAY = 'PLAN_CHANGE_KPI_DISPLAY'

export const PLAN_GET_ITEM_ATTEMPT = 'PLAN_GET_ITEM_ATTEMPT'
export const PLAN_GET_ITEM_SUCCESS = 'PLAN_GET_ITEM_SUCCESS'
export const PLAN_GET_ITEM_FAIL = 'PLAN_GET_ITEM_FAIL'
export const PLAN_GET_ITEM_BY_ID_ATTEMPT = 'PLAN_GET_ITEM_BY_ID_ATTEMPT'
export const PLAN_GET_ITEM_BY_ID_SUCCESS = 'PLAN_GET_ITEM_BY_ID_SUCCESS'

// filters
export const PLAN_CREATE_FILTERS = 'PLAN_CREATE_FILTERS'
export const PLAN_SELECT_FILTER = 'PLAN_SELECT_FILTER'
export const PLAN_SET_LEVEL_TRANSFORMS = 'PLAN_SET_LEVEL_TRANSFORMS'

export const PLAN_SELECT_REGION = 'PLAN_SELECT_REGION'

export const COPY_PLAN_ATTEMPT = 'COPY_PLAN_ATTEMPT'
export const LOCK_PLAN_ATTEMPT = 'LOCK_PLAN_ATTEMPT'
export const DELETE_PLAN_ATTEMPT = 'DELETE_PLAN_ATTEMPT'
export const TOGGLE_SHARE_PLAN_MODAL = 'TOGGLE_SHARE_PLAN_MODAL'
export const SHARE_PLAN = 'SHARE_PLAN'

export const TOGGLE_CARRYOVER = 'TOGGLE_CARRYOVER'
export const TOGGLE_CARRYOVER_SUCCESS = 'TOGGLE_CARRYOVER_SUCCESS'
export const TOGGLE_CARRYOVER_FAIL = 'TOGGLE_CARRYOVER_FAIL'
export const TOGGLE_HALO = 'TOGGLE_HALO'
export const TOGGLE_HALO_SUCCESS = 'TOGGLE_HALO_SUCCESS'
export const TOGGLE_HALO_FAIL = 'TOGGLE_HALO_FAIL'

export const PUT_PLAN = 'PUT_PLAN'
export const PUT_PLAN_SUCCESS = 'PUT_PLAN_SUCCESS'
export const PUT_PLAN_FAIL = 'PUT_PLAN_FAIL'

export const PLAN_SELECT_KPI_OUTPUT = 'PLAN_SELECT_KPI_OUTPUT'

export const CURVE_OUTPUT = 'CURVE_OUTPUT'
export const DELETE_CURVE_DATA = 'DELETE_CURVE_DATA'

export const PLAN_SELECT_ALL_LEVELS = 'PLAN_SELECT_ALL_LEVELS'
export const PLAN_SELECT_LEVEL = 'PLAN_SELECT_LEVEL'

export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS'
export const DISCARD_COST_CHANGES = 'DISCARD_COST_CHANGES'
export const COST_EDIT_MODE = 'COST_EDIT_MODE'
export const EDITED_COSTS = 'EDITED_COSTS'

interface PlanGetSummariesAttempt {
  type: typeof PLAN_GET_SUMMARIES_ATTEMPT
}

interface PlanGetSummariesSuccess {
  type: typeof PLAN_GET_SUMMARIES_SUCCESS
  payload: IPlanSummary[]
}

interface PlanGetSummariesFail {
  type: typeof PLAN_GET_SUMMARIES_FAIL
}

interface PlanGetItemAttempt {
  type: typeof PLAN_GET_ITEM_ATTEMPT
}

interface PlanGetItemSuccess {
  type: typeof PLAN_GET_ITEM_SUCCESS
  payload: Plan
}

interface PlanGetItemByIdAttempt {
  type: typeof PLAN_GET_ITEM_BY_ID_ATTEMPT
}

interface PlanGetItemByIdSuccess {
  type: typeof PLAN_GET_ITEM_BY_ID_SUCCESS
  payload: Plan
}

interface PlanGetItemFail {
  type: typeof PLAN_GET_ITEM_FAIL
}

interface PlanChangeKPIDisplay {
  type: typeof PLAN_CHANGE_KPI_DISPLAY
  increment: number
  currentVal: number
  totalLength: number
}

// filter
export interface PlanCreateFilters {
  type: typeof PLAN_CREATE_FILTERS
  payload: Plan
}

export interface PlanSelectFilter {
  type: typeof PLAN_SELECT_FILTER
  filterType: string
  filterValues: string[]
  filters: GroupingLevelValue[] | GroupingLevel[]
}

export interface PlanSetLevelTransform {
  type: typeof PLAN_SET_LEVEL_TRANSFORMS
  payload: Transformation[]
}

export interface CopyPlanAttempt {
  type: typeof COPY_PLAN_ATTEMPT
}

export interface LockPlanAttempt {
  type: typeof LOCK_PLAN_ATTEMPT
}

export interface DeletePlanAttempt {
  type: typeof DELETE_PLAN_ATTEMPT
}

export interface ToggleSharePlanModal {
  id: string
  type: typeof TOGGLE_SHARE_PLAN_MODAL
}

export interface SharePlan {
  type: typeof SHARE_PLAN
}

export interface ToggleCarryover {
  type: typeof TOGGLE_CARRYOVER
}
export interface ToggleCarryoverSuccess {
  type: typeof TOGGLE_CARRYOVER_SUCCESS
}
export interface ToggleCarryoverFail {
  type: typeof TOGGLE_CARRYOVER_FAIL
}

export interface ToggleHalo {
  type: typeof TOGGLE_HALO
}
export interface ToggleHaloSuccess {
  type: typeof TOGGLE_HALO_SUCCESS
}
export interface ToggleHaloFail {
  type: typeof TOGGLE_HALO_FAIL
}

export interface PutPlan {
  type: typeof PUT_PLAN
}

export interface PutPlanSuccess {
  type: typeof PUT_PLAN_SUCCESS
  payload: Plan
}

export interface PutPlanFail {
  type: typeof PUT_PLAN_FAIL
}

export interface PlanSelectKPIOutput {
  type: typeof PLAN_SELECT_KPI_OUTPUT
  payload: string
}

export interface CurveOutput {
  type: typeof CURVE_OUTPUT
  result: any
}

export interface DeleteCurveData {
  type: typeof DELETE_CURVE_DATA
  payload: string
}
export interface PlanSelectRegion {
  type: typeof PLAN_SELECT_REGION
  payload: string[]
}

export interface PlanSelectAllLevels {
  type: typeof PLAN_SELECT_ALL_LEVELS
  payload: boolean
}

export interface PlanSelectLevel {
  type: typeof PLAN_SELECT_LEVEL
  levelName: string
  key: string
  groupingLevels: PlanGroupingLevel
}

export interface PlanCreateSuccess {
  type: typeof PLAN_CREATE_SUCCESS
  payload: Plan
}

export interface DiscardCostChanges {
  type: typeof DISCARD_COST_CHANGES
}

export interface CostEditMode {
  type: typeof COST_EDIT_MODE
  payload: boolean
}

export interface EditedCosts {
  type: typeof EDITED_COSTS
}

export type PlanActionTypes =
  | PlanGetSummariesAttempt
  | PlanGetSummariesSuccess
  | PlanGetSummariesFail
  | PlanGetItemAttempt
  | PlanGetItemSuccess
  | PlanGetItemFail
  | PlanChangeKPIDisplay
  | PlanCreateFilters
  | PlanSelectFilter
  | PlanSetLevelTransform
  | CopyPlanAttempt
  | LockPlanAttempt
  | DeletePlanAttempt
  | ToggleSharePlanModal
  | SharePlan
  | PlanGetItemByIdAttempt
  | PlanGetItemByIdSuccess
  | ToggleCarryover
  | ToggleCarryoverSuccess
  | ToggleCarryoverFail
  | ToggleHalo
  | ToggleHaloSuccess
  | ToggleHaloFail
  | PutPlan
  | PutPlanSuccess
  | PutPlanFail
  | PlanSelectKPIOutput
  | DeleteCurveData
  | CurveOutput
  | PlanSelectRegion
  | PlanSelectAllLevels
  | PlanSelectLevel
  | PlanCreateSuccess
  | DiscardCostChanges
  | CostEditMode
  | EditedCosts