import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const MessagesIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={stroke || '#666'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.0"
        >
          <path d="M39.27 4.354H12.188c-5.982 0-10.833 4.85-10.833 10.834V26.02c0 5.985 4.85 10.833 10.834 10.833h2.708v10.834l12.187-10.834h12.188c5.983 0 10.833-4.848 10.833-10.833V15.187c0-5.982-4.85-10.833-10.833-10.833zM31.146 46.333a6.77 6.77 0 0 0 6.77 6.771h6.772l8.124 8.125v-8.125h4.063a6.77 6.77 0 0 0 6.77-6.77v-5.417a6.77 6.77 0 0 0-6.77-6.771" />
        </g>
      </g>
    </svg>
  )
}

export default MessagesIcon
