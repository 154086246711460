import 'rc-slider/assets/index.css'
import { DateFormat, DatePicker } from '../../../components/DatePicker'
import { ticks as d3ticks } from 'd3-array'
import moment from 'moment'
import { Range } from 'rc-slider'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Observation } from '../../../types/PlanTypes'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'
import { useDateSelection } from './hooks/use-date-selection'
import { datesAreEqual } from '../../../utils/dates-are-equal'

interface OwnProps {
  dateFormat: string | undefined
  observations: Observation[]
  selectedObservations: number[]
  setSelectedObservations: Dispatch<SetStateAction<number[]>>
  setStep: Dispatch<SetStateAction<number>>
}

type Props = OwnProps

const PlanTimePeriodStep: FC<Props> = ({
  dateFormat,
  observations,
  selectedObservations,
  setSelectedObservations,
  setStep,
}) => {
  const PLAN_TIME_PERIOD_TEXTS = {
    PAGE_TITLE: 'What time period?',
    START_PICKER_LABEL: 'Start date',
    START_PICKER_PLACEHOLDER: 'dd/mm/yyyy',
    END_PICKER_LABEL: 'End date',
    END_PICKER_PLACEHOLDER: 'dd/mm/yyyy',
    ERROR_MESSAGE: 'This field is required.',
  }

  const [availableDates, setAvailableDates] = useState<Date[]>([])
  const [startDateError, setStartDateEror] = useState<boolean>(false)
  const [endDateError, setEndDateEror] = useState<boolean>(false)

  const getObservationTicks = () => {
    const keys =
      observations.length > 10
        ? d3ticks(1, observations.length, 5)
        : d3ticks(1, observations.length, observations.length - 1)

    let obsTicks: any = {}
    keys.map((x) => {
      obsTicks[x - 1] = observations[x - 1].label
    })

    return obsTicks
  }

  const handleResetStartDateError = (date: Date | null) => {
    if (date !== null && startDateError) {
      setStartDateEror(false)
    }
  }

  const handleResetEndDateError = (date: Date | null) => {
    if (date !== null && endDateError) {
      setEndDateEror(false)
    }
  }

  const handleBackButton = () => {
    setStep((prev) => prev - 1)
  }

  const handleNextButton = () => {
    const startDate = startDatePickerProps.startDate ?? null
    const endDate = endDatePickerProps?.endDate ?? null

    const startDateIsNull = startDate === null
    const endDateIsNull = endDate === null

    if (startDateIsNull || endDateIsNull) {
      startDateIsNull && setStartDateEror(true)
      endDateIsNull && setEndDateEror(true)
      return
    }

    setStartDateEror(false)
    setEndDateEror(false)

    const startDateIndex = availableDates.findIndex((x) =>
      datesAreEqual(x, startDate)
    )
    const endDateIndex = availableDates.findIndex((x) =>
      datesAreEqual(x, endDate)
    )

    setSelectedObservations([startDateIndex, endDateIndex])

    setStep((prev) => prev + 1)
  }

  const {
    sliderPosition,
    handleMoveSlider,
    handeUpdatePickers,
    startDatePickerProps,
    endDatePickerProps,
  } = useDateSelection({
    observations,
    availableDates,
    selectedObservations,
    handleResetStartDateError,
    handleResetEndDateError,
  })

  useEffect(() => {
    const dates = observations.map((x) => {
      const utcMoment = moment.utc(x.label, dateFormat)

      const date = utcMoment.date()
      const monthIndex = utcMoment.month()
      const year = utcMoment.year()
      const hour = 12

      const parsedDate = new Date(year, monthIndex, date, hour)

      return parsedDate
    })

    setAvailableDates(dates)
  }, [observations, dateFormat])

  useEffect(() => {
    if (selectedObservations.length === 0) return

    const startDate = availableDates[selectedObservations[0]]
    const endDate = availableDates[selectedObservations[1]]

    startDatePickerProps.onChange(startDate, undefined)
    endDatePickerProps?.onChange(endDate, undefined)
  }, [selectedObservations, availableDates])

  const StartDatePicker = (
    <DatePicker
      {...startDatePickerProps}
      maxDate={endDatePickerProps?.endDate}
      includeDates={availableDates}
      dateFormat={DateFormat[dateFormat as keyof typeof DateFormat]}
      label={PLAN_TIME_PERIOD_TEXTS.START_PICKER_LABEL}
      placeholderText={PLAN_TIME_PERIOD_TEXTS.START_PICKER_PLACEHOLDER}
      state={startDateError ? 'error' : 'default'}
      openToDate={startDatePickerProps.selected || availableDates[0]}
      errorMessage={
        startDateError ? PLAN_TIME_PERIOD_TEXTS.ERROR_MESSAGE : undefined
      }
    />
  )

  const EndDatePicker = endDatePickerProps && (
    <DatePicker
      {...endDatePickerProps}
      includeDates={availableDates}
      dateFormat={DateFormat[dateFormat as keyof typeof DateFormat]}
      label={PLAN_TIME_PERIOD_TEXTS.END_PICKER_LABEL}
      placeholderText={PLAN_TIME_PERIOD_TEXTS.END_PICKER_PLACEHOLDER}
      state={endDateError ? 'error' : 'default'}
      openToDate={
        endDatePickerProps.selected || availableDates[availableDates.length - 1]
      }
      errorMessage={
        endDateError ? PLAN_TIME_PERIOD_TEXTS.ERROR_MESSAGE : undefined
      }
    />
  )

  const DateSlider = (
    <div className="min-w-64 w-2/3 max-w-4xl">
      <Range
        allowCross={false}
        min={0}
        max={observations.length - 1}
        value={sliderPosition}
        onChange={handleMoveSlider}
        marks={getObservationTicks()}
        onAfterChange={handeUpdatePickers}
      />
    </div>
  )

  return (
    <CreateMarketingPlanLayout
      title={PLAN_TIME_PERIOD_TEXTS.PAGE_TITLE}
      component={
        <div className="flex flex-col gap-xxl w-full items-center justify-center">
          <div className="flex flex-row items-start justify-center gap-md w-fit min-w-64">
            {StartDatePicker}
            {EndDatePicker}
          </div>
          {DateSlider}
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanTimePeriodStep
