import { Plan } from '../../../types/PlanTypes'

export const ADD_OR_CUT_BUDGET_ATTEMPT = 'ADD_OR_CUT_BUDGET_ATTEMPT'
export const ADD_OR_CUT_BUDGET_SUCCESS = 'ADD_OR_CUT_BUDGET_SUCCESS'
export const ADD_OR_CUT_BUDGET_FAIL = 'ADD_OR_CUT_BUDGET_FAIL'
export const SET_ORIGINAL_CONSTRAINT_ATTEMPT = 'SET_ORIGINAL_CONSTRAINT_ATTEMPT'
export const SET_ORIGINAL_CONSTRAINT_SUCCESS = 'SET_ORIGINAL_CONSTRAINT_SUCCESS'
export const SET_ORIGINAL_CONSTRAINT_ERROR = 'SET_ORIGINAL_CONSTRAINT_ERROR'

interface AddOrCutAttempt {
  type: typeof ADD_OR_CUT_BUDGET_ATTEMPT
}

interface AddOrCutSuccess {
  type: typeof ADD_OR_CUT_BUDGET_SUCCESS
  scenario: Plan
}

interface AddOrCutFail {
  type: typeof ADD_OR_CUT_BUDGET_FAIL
  error: string
}

interface SET_ORIGINAL_CONSTRAINT_ATTEMPT {
  type: typeof SET_ORIGINAL_CONSTRAINT_ATTEMPT
}

interface SET_ORIGINAL_CONSTRAINT_SUCCESS {
  type: typeof SET_ORIGINAL_CONSTRAINT_SUCCESS
  payload: number
}
interface SET_ORIGINAL_CONSTRAINT_ERROR {
  type: typeof SET_ORIGINAL_CONSTRAINT_ERROR
}

export type AddOrCutActionTypes =
  | AddOrCutAttempt
  | AddOrCutSuccess
  | AddOrCutFail
  | SET_ORIGINAL_CONSTRAINT_ATTEMPT
  | SET_ORIGINAL_CONSTRAINT_SUCCESS
  | SET_ORIGINAL_CONSTRAINT_ERROR
