import { FC, useEffect, useState } from 'react'
import { Combobox, ComboboxProps } from '@gain-theory/combobox'
import CreateMarketingPlanLayout from './CreateMarketingPlanLayout'
import { GroupingLevelValue } from '../../../types/PlanTypes'

interface OwnProps {
  dimensionPlural: string
  dimensionKey: string
  selectedValues: string[] | undefined
  options: GroupingLevelValue[]
  handlePreviousDimensionStep: () => void
  handleNextDimensionStep: (values: string[]) => void
}

type Props = OwnProps

const PlanDimensionLevelStep: FC<Props> = ({
  dimensionPlural,
  dimensionKey,
  selectedValues,
  options,
  handlePreviousDimensionStep,
  handleNextDimensionStep,
}) => {
  const [levelValues, setLevelValues] = useState<
    ComboboxProps['selectedValues']
  >([])
  const [levelOptions, setLevelOptions] = useState<ComboboxProps['options']>([])
  const [levelError, setLevelError] = useState<boolean>(false)

  const PLAN_REGIONS_TEXTS = {
    PAGE_TITLE: `What ${dimensionPlural} do you want to include?`,
    COMBOBOX_LABEL:
      dimensionPlural.charAt(0).toUpperCase() + dimensionPlural.slice(1),
    COMBOBOX_PLACEHOLDER: `Type or select ${dimensionPlural} to include`,
    COMBOBOX_ERROR_MESSAGE: 'This field is required.',
    COMBOBOX_SELECT_ALL: 'Select all',
    COMBOBOX_UNSELECT_ALL: 'Unselect all',
  }

  const mapValueForCombobox = (value: string) => {
    const option = options
      ? options.find((option) => option.key === value)
      : undefined

    const mappedValue: ComboboxProps['selectedValues'][number] = {
      id: value,
      label: option?.label ?? '',
    }

    return option ? mappedValue : undefined
  }

  const sortOptions = (
    a: ComboboxProps['options'][number],
    b: ComboboxProps['options'][number]
  ) => {
    if (a.label < b.label) {
      return -1
    }

    if (a.label > b.label) {
      return 1
    }

    return 0
  }

  useEffect(() => {
    const mappedValues = selectedValues?.reduce((acc, value) => {
      const mappedValue = mapValueForCombobox(value)

      if (mappedValue) {
        acc.push(mappedValue)
      }

      return acc
    }, [] as ComboboxProps['selectedValues'])

    setLevelValues(mappedValues ?? [])
  }, [dimensionKey])

  useEffect(() => {
    const mappedOptions: ComboboxProps['options'] = options
      ? options
          .map((option) => ({
            id: option.key,
            label: option.label,
          }))
          .sort(sortOptions)
      : []

    setLevelOptions(mappedOptions)
  }, [dimensionKey, options])

  const handleBackButton = () => {
    setLevelError(false)
    handlePreviousDimensionStep()
  }

  const handleNextButton = () => {
    if (levelValues.length === 0) {
      setLevelError(true)
      return
    }

    setLevelError(false)
    handleNextDimensionStep(
      levelValues.map((value) => value.id?.toString() ?? '')
    )
  }

  return (
    <CreateMarketingPlanLayout
      title={PLAN_REGIONS_TEXTS.PAGE_TITLE}
      component={
        <div className="w-fit min-w-128 max-w-3xl xl:min-w-128">
          <Combobox
            multiple
            label={PLAN_REGIONS_TEXTS.COMBOBOX_LABEL}
            placeholder={
              levelValues.length === 0
                ? PLAN_REGIONS_TEXTS.COMBOBOX_PLACEHOLDER
                : undefined
            }
            options={levelOptions}
            selectedValues={levelValues}
            setSelectedValues={setLevelValues}
            state={levelError ? 'error' : 'default'}
            allowNewValues={false}
            helperText={
              levelError ? PLAN_REGIONS_TEXTS.COMBOBOX_ERROR_MESSAGE : undefined
            }
            translations={{
              SELECT_ALL: PLAN_REGIONS_TEXTS.COMBOBOX_SELECT_ALL,
              UNSELECT_ALL: PLAN_REGIONS_TEXTS.COMBOBOX_UNSELECT_ALL,
            }}
          />
        </div>
      }
      onBack={handleBackButton}
      onNext={handleNextButton}
    />
  )
}

export default PlanDimensionLevelStep
