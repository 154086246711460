import { FC, useEffect, useState, Children } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Channel, ChannelPerformanceTab, ChartDataMD, DateOptions, KPI, Phase } from '../../../types/MarketingDiagnosticsTypes'
import { AppState } from '../../../redux/reducers/RootReducer'
import { MainLayout } from '../../../shared/MainLayout'
import api from '../../../api'
import { GetMarketingDiagnostics } from '../../../redux/actions/MarketingDiagnosticsActions'
import { GetPerformanceOverview } from '../../../redux/actions/PerformanceOverviewActions'
import { Breadcrumbs } from './components/breadcrumbs'
import { Select } from './components/select'
import { VisualPerformance } from './components/visual-performance'
import { Drilldown } from './components/drilldown'
import { getNavs } from './components/breadcrumbs/util'

interface MatchParams {
    brand: string
    channel: string
    kpiId: string
    phaseId: string
    kpiName: string
    prevPhase: string | undefined
}


const ChannelPerformance: FC<RouteComponentProps<MatchParams>> = (props) => {
    const [activeTab, setActiveTab] = useState(0)
    const [brandDropdown, setBrandDropdown] = useState(props.match.params.brand)
    const [channelDropdown, setChannelDropdown] = useState(props.match.params.channel)
    const [channelDropdownDisplay, setChannelDropdownDisplay] = useState(props.match.params.channel)
    const [kpiDropdown, setKpiDropdown] = useState(props.match.params.kpiId)
    const [kpiDropdownDisplay, setKpiDropdownDisplay] = useState(props.match.params.kpiId)
    const [phaseId, setPhaseId] = useState(props.match.params.phaseId)
    const [tabs, setTabs] = useState<ChannelPerformanceTab>({})
    const [tabData, setTabData] = useState<ChannelPerformanceTab[]>([])
    const [defaultHeaders, setDefaultHeaders] = useState([
        "Spend",
        "Impressions",
        props.match.params.kpiName,
        "Effectiveness",
        "Productivity"
    ])
    const [rows, setRows] = useState<Channel[]>([])
    const [previous, setPrevious] = useState<Channel[]>([])
    const [channels, setChannels] = useState([])
    const [kpis, setKpis] = useState<KPI[]>([])
    const [viewHeaders, setViewHeaders] = useState<string[]>([])
    const [chartDataChannel, setChartDataChannel] = useState<ChartDataMD[]>([])
    const [brands, setBrands] = useState([])
    const [selectedBrand, setSelectedBrand] = useState("All Brands")
    const [selectedPhases, setSelectedPhases] = useState<string[]>([])
    const [selectedBrands, setSelectedBrands] = useState<string[]>([])
    const [selectedKpi, setSelectedKpi] = useState(props.match.params.kpiName)

    const { marketingDiagnostics } = useSelector(
        (state: AppState) => state.marketingDiagnostics
    )

    const { phases } = useSelector(
        (state: AppState) => state.performanceOverview
    )

    const dispatch = useDispatch()

    const { settings } = useSelector((state: AppState) => state.settings)

    useEffect(() => {
        if (!marketingDiagnostics) {
            dispatch(GetMarketingDiagnostics())
        }
        dispatch(GetPerformanceOverview(props.match.params.brand, selectedPhases))
    }, [])

    useEffect(() => {
        const selectedPhasesHolder = [props.match.params.phaseId];

        let prevPhase =
            props.match.params.prevPhase ||
            props.match.params.phaseId;

        if (phases.length > 1 && !props.match.params.prevPhase) {
            const selectedPhaseIndex = phases.findIndex(
                (phase: Phase) => phase.phase === props.match.params.phaseId
            );

            prevPhase = phases[selectedPhaseIndex - 1]
                ? phases[selectedPhaseIndex - 1].phase
                : props.match.params.phaseId;
        }

        selectedPhasesHolder.push(prevPhase);

        const selectedBrandsHolder = props.match.params.brand.split(",")
        getChannelData(
            props.match.params.channel,
            props.match.params.kpiId,
            props.match.params.phaseId,
            prevPhase, selectedBrands.join(",")
        );
        setSelectedPhases(selectedPhasesHolder)
        setSelectedBrands(selectedBrandsHolder)
    }, [phases])

    useEffect(() => {
        setSelectedKpi(defaultHeaders[2])
    }, [defaultHeaders])

    const getChannelData = (chan: string, kpiId: string, phaseId: string, prevPhase: string, brand: string) => {
        if (chan && kpiId) {
            api.PerformanceOverview.getDrilldownPerformanceBrand(chan, kpiId, phaseId, prevPhase, brand).then(
                (returnData: any) => {
                    const result = returnData.data
                    // add open state to each item
                    let tabData = result.tabData;

                    tabData?.forEach((tab: any) => {
                        tab.data.forEach((row: any) => {
                            row.isOpen = false;
                        });
                    });

                    let dateOptions: DateOptions[] = [];
                    const minMaxDates = [0, result?.allDates?.length - 1];

                    if (phases.length > 1) {
                        for (let i = 0; i < 2; i++) {
                            dateOptions.push({
                                showTimeSliders: false,
                                minMaxTimes: minMaxDates,
                                index: i
                            });
                        }
                    } else {
                        for (let i = 0; i < 1; i++) {
                            dateOptions.push({
                                showTimeSliders: false,
                                minMaxTimes: minMaxDates,
                                index: i
                            });
                        }
                    }

                    setTabs(result.tabs)
                    setTabData(result.tabData)
                    setKpis(result.kpis)
                    setBrands(result.brands)
                    setChannels(result.channels)
                    setChartDataChannel(result.chartData)
                    setPrevious(result.previousTabData)
                    setSelectedKpi(kpiId)
                    setSelectedBrand(brand || selectedBrand)
                    toggle(0);
                }
            );
        }
    };

    const changeChannel = (e: any) => {
        setChannelDropdown(e.target.value)
    }

    const changeKPI = (e: any) => {
        setKpiDropdownDisplay(e.target.value);
        setKpiDropdown(kpis.find(k => k.id.toString() === e.target.value)?.id.toString() || '')
    }

    const selectPhase = (index: number, phase: string) => {
        let selectedPhasesHolder = [...selectedPhases];
        selectedPhases[index] = phase;
        setSelectedPhases(selectedPhasesHolder)
    }

    const updateSelectors = (e: any) => {
        const channel = channelDropdown;
        const kpi = kpiDropdownDisplay;
        const phase = phaseId;
        const currentKPI = kpis.find(
            x => JSON.stringify(x.id) === kpi
        );

        const kpiLabel = currentKPI ? currentKPI.label : "";
        const defaultHeaders = [
            "Spend",
            "Impressions",
            kpiLabel,
            "Effectiveness",
            "Productivity"
        ];


        getChannelData(
            channel,
            kpi,
            selectedPhases[0],
            selectedPhases[1],
            selectedBrands.join(",")
        );

        setChannelDropdownDisplay(channel)
        setKpiDropdownDisplay(kpi)
        setPhaseId(phase)
        setDefaultHeaders(defaultHeaders)
    }

    const toggle = (tab: number) => {
        const flexTab: any = Object.values(tabs)[tab];
        const viewHeadersHolder: string[] = [flexTab, ...defaultHeaders]
        const rowsHolder: any = tabData[tab]
            ? tabData[tab].data
            : [];
        setActiveTab(tab)
        setViewHeaders(viewHeadersHolder)
        setRows(rowsHolder)
    };

    const getDrilldownPerformanceArgs = (props: Record<string, string>) => ({
        channelDropdown,
        kpiDropdown,
        phaseId,
        brandDropdown,
        ...props
    });

    return (
        <div className='w-full grid gap-5'>
            <Breadcrumbs bread={brandDropdown} />
            <div className="grid grid-flow-col place-content-between">
                <div className="grid grid-flow-col gap-3 place-content-between auto-cols-fr">
                    <Select onChange={changeChannel} value={channelDropdown} options={channels.map(({ channel }: Channel) => ({ value: channel, label: channel }))} />
                    <Select onChange={changeKPI} value={kpiDropdown} options={kpis.map(({ id, label }: KPI) => ({ value: id, label }))} />
                    {Children.toArray(selectedPhases.map((_: string, index: number) => (
                        <span className='grid grid-flow-col align-middle'>
                            <Select
                                classNames='w-40'
                                value={selectedPhases[index]}
                                onChange={(e: any) =>
                                    selectPhase(index, e.target.value)
                                }
                                options={phases.map(({ phase }: Phase) => ({
                                    disabled: selectedPhases.includes(phase),
                                    value: phase,
                                    label: phase
                                }))} />
                            {selectedPhases[index + 1] && (
                                <span className='pl-3 leading-loose'>vs</span>
                            )}
                        </span>
                    )))}
                </div>
                <button className='bg-gtPink text-white rounded px-5 text-xs' onClick={updateSelectors}>
                    UPDATE
                </button>
            </div>
            <VisualPerformance
                heading={`${channelDropdownDisplay} Performance`}
                chartDataChannel={chartDataChannel}
                defaultHeaders={defaultHeaders}
                marketingDiagnostics={marketingDiagnostics}
                selectedKpi={kpis.find(k => k.id.toString() === selectedKpi)?.label || ''}
                settings={settings}
            />
            <Drilldown
                tabs={tabs}
                tabData={tabData}
                defaultHeaders={defaultHeaders}
                getDrilldownPerformanceArgs={getDrilldownPerformanceArgs}
                previous={previous}
                marketingDiagnostics={marketingDiagnostics}
                selectedKpi={kpis.find(k => k.id.toString() === selectedKpi)?.label || ''}
                settings={settings}
                kpis={kpis}
            />
        </div>
    );
}

export default MainLayout(ChannelPerformance)
