import {
  Allocation,
  ConfigOutput,
  Level,
  GrandTotal,
  GroupingLevel,
  IO,
  IPlanSummary,
  PlanOutput,
  ScenarioBudgetForBrand,
  ScenarioOutputTotal,
  ScenarioRegion,
  ScenarioTableMetaData,
} from '../../types/PlanTypes'
import {
  PLAN_CREATE_SUCCESS,
  PLAN_GET_ITEM_ATTEMPT,
  PLAN_GET_ITEM_SUCCESS,
  PLAN_GET_SUMMARIES_ATTEMPT,
  PLAN_GET_SUMMARIES_SUCCESS,
  PLAN_SELECT_ALL_LEVELS,
  PLAN_SELECT_KPI_OUTPUT,
  PlanActionTypes,
  PUT_PLAN,
  PUT_PLAN_SUCCESS,
  TOGGLE_SHARE_PLAN_MODAL,
  EDITED_COSTS,
} from '../actions/PlanActionTypes'
import { RootStore } from './Store'
import {
  getConfigOutputs,
  getIOTotals,
  getScenarioGroupingLevelsWithFilteredValues,
  getScenarioTable,
  getVariableNames,
} from '../../utils/PlanUtils'
import {
  PLAN_LOAD_COMPLETE,
  SOCKET_CREATE_PLAN_ATTEMPT,
  SOCKET_CREATE_SCENARIO_ERROR,
  SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS,
  SOCKET_UPLOAD_PLAN_SUCCESS,
  SocketActionTypes,
  SOCKET_EDIT_PLAN_BUDGET,
} from '../actions/SocketActionTypes'
import {
  ADD_OR_CUT_BUDGET_ATTEMPT,
  AddOrCutActionTypes,
} from '../actions/SPOFlowActions/AddOrCutBudgetActionTypes'
import { CONFIG_GET_BY_ID_ATTEMPT } from '../actions/ConfigActionTypes'
import { PLAN_BAR_MOVE_ENDED, PLAN_BAR_MOVE_STARTED } from '../actions/MediaChannelActionTypes'

type showSharePlanModal = {
  show: boolean
  id: string | null
}

interface IDefaultState {
  loadingSummaries: boolean
  loading: boolean
  planItem: any
  planSummaryList: IPlanSummary[]
  configOutputs: ConfigOutput[]
  carryover: boolean
  editPlanError: string | null
  halos: boolean
  scenarioCreateError: string | null
  scenarioGrandTotals: GrandTotal[]
  scenarioGroupingLevels: GroupingLevel[]
  scenarioOutputSelected: string
  scenarioVariableNames: string[]
  scenarioTableDisplayData: any[]
  scenarioTableMetaData: ScenarioTableMetaData | null
  showSharePlanModal: showSharePlanModal
  outputSelectOptions: IO[]
  planCreatedComplete: boolean
  planLoadingComplete: boolean
  editedCosts: boolean
}

const defaultState: IDefaultState = {
  loadingSummaries: false,
  loading: false,
  planItem: null,
  planSummaryList: [],
  configOutputs: [],
  carryover: false,
  editPlanError: null,
  halos: false,
  scenarioCreateError: null,
  scenarioGrandTotals: [],
  scenarioGroupingLevels: [],
  scenarioOutputSelected: 'spend',
  scenarioVariableNames: [],
  scenarioTableDisplayData: [],
  scenarioTableMetaData: null,
  showSharePlanModal: { show: false, id: null },
  outputSelectOptions: [],
  planCreatedComplete: false,
  planLoadingComplete: false,
  editedCosts: false
}

const plansReducer = (
  state: IDefaultState = defaultState,
  action: PlanActionTypes | SocketActionTypes | AddOrCutActionTypes
): IDefaultState => {
  switch (action.type) {
    case TOGGLE_SHARE_PLAN_MODAL:
      return {
        ...state,
        showSharePlanModal: {
          show: !state.showSharePlanModal.show,
          id: action.id.toString(),
        },
      }
    case PLAN_GET_SUMMARIES_ATTEMPT:
      return { ...state, loadingSummaries: true, planCreatedComplete: false }
    case PLAN_GET_SUMMARIES_SUCCESS:
      return {
        ...state,
        loadingSummaries: false,
        planSummaryList: action.payload,
      }
    case PLAN_GET_ITEM_ATTEMPT:
      return {
        ...state,
        scenarioOutputSelected: 'spend',
        // loading: true,
        planCreatedComplete: false,
      }
    case PLAN_SELECT_KPI_OUTPUT:
      return { ...state, scenarioOutputSelected: action.payload }
    case PLAN_GET_ITEM_SUCCESS:
        const plan = action.payload
        const table = getScenarioTable(plan);    
        const outputSelectOptions = plan.config.transformations[0].io.filter(
            io => io.type === "output" || io.key === "spend"
        );
    
        let result = {
            ...state,
            halos: plan.parentChild,
            carryover: plan.carryover,
            planItem: plan,
            outputSelectOptions: outputSelectOptions,
            configOutputs: getConfigOutputs(plan.config),
            scenarioTableDisplayData: table.map((row: any) => row.slice(1)), // without metadata
            scenarioTableMetaData: {
                headers: [
                    ...Object.values(
                        plan.config.grouping_levels
                    ).map(level => level.label),
                    "Variable"
                ],
                rows: table.map((row: any) => row[0]) // only metadata
            },
            scenarioVariableNames: getVariableNames(plan.config),
            scenarioGroupingLevels: getScenarioGroupingLevelsWithFilteredValues(
                plan
            ),
            scenarioOutputSelected: 'spend',
            scenarioGrandTotals: getIOTotals(plan),
            loading: false,
            planLoadingComplete: true
        };
      return result
    case PLAN_BAR_MOVE_STARTED: 
        return {
          ...state,
          loading: true
        }
    case PLAN_BAR_MOVE_ENDED: 
        return {
          ...state,
          loading: false
        }
    case PLAN_SELECT_ALL_LEVELS:
      return {
        ...state,
      }
    case PUT_PLAN:
      return { ...state, loading: true, editedCosts: false }
    case PUT_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        planItem: action.payload,
        editedCosts: false
      }
    
    case SOCKET_UPLOAD_PLAN_SUCCESS:
    case SOCKET_UPLOAD_IN_CHANNEL_PLAN_SUCCESS:
    case SOCKET_CREATE_PLAN_ATTEMPT:
    case ADD_OR_CUT_BUDGET_ATTEMPT:
      case SOCKET_EDIT_PLAN_BUDGET:
      return { ...state, loading: true }
    case PLAN_LOAD_COMPLETE:
      return { ...state, planCreatedComplete: true, loading: false }
    case PLAN_CREATE_SUCCESS:
      return {
        ...state,
        planCreatedComplete: true,
        planItem: action.payload,
        loading: false,
        editedCosts: false
      }
    case EDITED_COSTS:
      return {
        ...state,
        editedCosts: !state.editedCosts
      }
    default:
      return state
    case SOCKET_CREATE_SCENARIO_ERROR:
      return { ...state, scenarioCreateError: action.payload, loading: false }
  }
}

export const selectPlanGrandTotals = (state: RootStore) => {
  return state.plans.scenarioGrandTotals
}

export const selectPlanSummaryList = (state: RootStore) => {
  return state.plans.planSummaryList
}

export const selectPlanItem = (state: RootStore) => {
  return state.plans.planItem
}

export const selectLoading = (state: RootStore) => {
  return state.plans.loading
}

export const selectLoadingSummaries = (state: RootStore) => {
  return state.plans.loadingSummaries
}

export const selectPlanConfigOutputs = (state: RootStore) => {
  return state.plans.configOutputs
}

export const selectPlanIsInChannel = (state: RootStore) => {
  return state.plans.planItem.in_channel
}

// export const selectPlanBrands = (state: RootStore) => {
//   return state.plans.scenarioBrands
// }


export const selectShowSharePlanModal = (state: RootStore) => {
  return state.plans.showSharePlanModal
}

export const selectPlanCreatedComplete = (state: RootStore) => {
  return state.plans.planCreatedComplete
}

export const selectCreateError = (state: RootStore) => {
  return state.plans.scenarioCreateError
}

export const selectIsLoading = (state: RootStore) => {
  return state.plans.loading
}

export const selectPlanBrands = (state: RootStore) => {}

export default plansReducer
