import { pendoApi } from '@gain-theory/pendo-sdk'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ROUTES } from '../Routes'
import { AnalyticsType } from '../types'

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean
  analytics?: AnalyticsType
  isPermitted: boolean
}

export class AuthRoute extends Route<ProtectedRouteProps> {
  componentDidMount(): void {
    if (this.props.isAuthenticated && this.props.analytics) {
      pendoApi.initialize(this.props.analytics)
    }
  }
  public render() {
    if(!this.props.isPermitted){
      const renderComponent = () => <Redirect to={ROUTES.NOPERMISSION} />

      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      )
    }

    if (!this.props.isAuthenticated) {
      const renderComponent = () => <Redirect to={ROUTES.LOGIN} />

      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      )
    } else {
      return <Route {...this.props} />
    }
  }
}
