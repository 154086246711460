import { useEffect, useState } from 'react'
import { useElementSize } from '../use-element-size'

const MINIMUM_HEIGHT = 350

export function useFeatureContentHeight() {
  const [rootRef, rootDimensions] = useElementSize()
  const [bgRef, bgDimensions] = useElementSize()

  const [height, setHeight] = useState<number | string>('auto')

  useEffect(() => {
    const { height: bgHeight } = bgDimensions
    const { height: rootHeight } = rootDimensions

    const newHeight = bgHeight >= rootHeight ? bgHeight : rootHeight

    setHeight(newHeight < MINIMUM_HEIGHT ? MINIMUM_HEIGHT : newHeight)
  }, [rootDimensions, bgDimensions])

  return { rootRef, bgRef, height }
}
