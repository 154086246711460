import { FC } from 'react'
import { Footer as GtFooter } from '@gain-theory/footer'
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../utils/constants'
import { OnetrustSdk } from '@gain-theory/onetrust-sdk'

const openInNewTab = (url: string) => () => window.open(url)

const Footer: FC<{}> = () => {
  return (
    <GtFooter
      buttons={{
        privacy: {
          label: PRIVACY_POLICY.label,
          onClick: openInNewTab(PRIVACY_POLICY.link),
        },
        tnc: {
          label: TERMS_AND_CONDITIONS.label,
          onClick: openInNewTab(TERMS_AND_CONDITIONS.link),
        },
        cookiePref: {
          label: 'Cookie Preferences',
          onClick: OnetrustSdk.openPreferenceCenter,
        },
      }}
      translations={{
        COPYRIGHT: `GAIN THEORY INTERACTIVE • ${new Date().getFullYear()}`,
      }}
    />
  )
}

export default Footer
