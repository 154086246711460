import * as _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import EditGoal from '../../../pages/spo/EditPlan/EditGoal'
import EditGroupingLevel from '../../../pages/spo/EditPlan/EditGroupingLevel'
import EditTime from '../../../pages/spo/EditPlan/EditTime'
import { HideEditPlanModal } from '../../../redux/actions/EditPlanActions'
import {
  selectEditPlanId,
  selectPlanCreatedFromEditor,
} from '../../../redux/reducers/EditPlanReducer'
import { AppState } from '../../../redux/reducers/RootReducer'
import { RootStore } from '../../../redux/reducers/Store'
import { createPlanFromEditor } from '../../../socket.io'
import {
  EditPlanRequest,
  GroupingLevel,
  GroupingLevelValue,
  PlanGroupingLevel,
  Threshold,
  Transformation,
  optionsGroupingLevels,
  selectedGroupingLevels,
} from '../../../types/PlanTypes'
import {
  editPlanSelectionLogic,
  getFilteredTransforms,
} from '../../../utils/editPlanSelectionLogic'

interface OwnProps {}

const mapState = (state: RootStore) => ({
  planCreatedFromEditor: selectPlanCreatedFromEditor(state),
  editPlanId: selectEditPlanId(state),
})

const mapDispatchToProps = {}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const EditPlanModal: FC<PropsFromRedux> = (props) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state: AppState) => state.session)
  const {
    configOutputs,
    planItem: {
      config,
      grouping_levels,
      observations_max,
      observations_min,
      options,
      user_input,
    },
  } = useSelector((state: AppState) => state.plans)
  const { settings, inChannelSettings } = useSelector(
    (state: AppState) => state.settings
  )
  const { section } = useSelector((state: AppState) => state.editPlan)

  const [observations, setObservations] = useState([
    observations_min,
    observations_max - options.carryoverWeeks,
  ])

  const [goal, setGoal] = useState('')

  const [selectedSection, setSelectedSection] = useState<number>(section)

  const [groupingLevelOptions, setGroupingLevelOptions] =
    useState<optionsGroupingLevels>({})

  const [selectedGroupingLevels, setSelectedGroupingLevels] =
    useState<selectedGroupingLevels>({})

  const levels = config.grouping_levels.map((x: any) => x.key)

  useEffect(() => {
    createGroupingLevels()
  }, [])

  const createGroupingLevels = () => {
    let selLevels: selectedGroupingLevels = {}
    let optionLevels: optionsGroupingLevels = {}

    let allLevels = []

    for (let i = 0; i < levels.length; i++) {
      if (i === 0) {
        allLevels = config.grouping_levels
          .find((lev: GroupingLevel) => lev.key === levels[i])
          .values.filter((val: GroupingLevelValue) => {
            if (user?.permissions.includes(val.key)) {
              return val
            }
          })
      } else {
        allLevels = getGroupingLevelValues(
          levels[i],
          config.transformations,
          grouping_levels
        )
      }
      const selected =
        grouping_levels[levels[i]].length > 0
          ? grouping_levels[levels[i]]
          : allLevels.map((x: GroupingLevelValue) => x.key)
      optionLevels[levels[i]] = allLevels
      selLevels[levels[i]] = selected
    }

    const selectedGoal = configOutputs.find(
      (configOutput) =>
        configOutput.label.toLowerCase().replace(/ /g, '_') ===
        user_input.optimisationMetric.toLowerCase().replace(/ /g, '_')
    )?.key

    setGoal(selectedGoal || '')
    setGroupingLevelOptions(optionLevels)
    setSelectedGroupingLevels(selLevels)
  }

  const getGroupingLevelValues = (
    level: string,
    transformations: Transformation[],
    grouping_levels: PlanGroupingLevel
  ) => {
    const filteredTransforms = getFilteredTransforms(
      level,
      transformations,
      grouping_levels
    )
    const allValueKeys = _.uniqBy(
      filteredTransforms.map(
        (trans: Transformation) => trans.identifiers[level]
      ),
      function (e) {
        return e
      }
    )
    const stringKey: string = level.toString()
    const allValues: GroupingLevel[] = config.grouping_levels
      .find((level: GroupingLevel) => level.key === stringKey)
      .values.filter((val: GroupingLevelValue) => {
        if (allValueKeys.indexOf(val.key) !== -1) {
          return val
        }
      })
    return allValues
  }

  const onHide = () => {
    dispatch(HideEditPlanModal())
  }

  const onSelect = (value: string, level: string) => {
    let newSelected = [...selectedGroupingLevels[level]]

    if (selectedGroupingLevels[level].includes(value)) {
      newSelected = newSelected.filter((val) => val !== value)
    } else {
      newSelected.push(value)
    }

    setSelectedGroupingLevels({
      ...selectedGroupingLevels,
      [level]: newSelected,
    })
    setGroupingLevelOptions(
      editPlanSelectionLogic(
        level,
        config,
        newSelected,
        groupingLevelOptions,
        selectedGroupingLevels
      )
    )
  }

  const onRegenerate = () => {
    let newObservations: number[] = observations
    if (
      observations[1] !== observations_max ||
      observations[0] !== observations_min
    ) {
      newObservations = [
        observations[0],
        observations[1] + options.carryoverWeeks,
      ]
    }

    const r = window.confirm(
      'If you edit the plan parameters, a new plan will be generated with all constraints removed. Your old plan with entered constraints will still be available from the Home Page. Do you wish to continue?'
    )

    if (r === true) {
      const defaultCurrency = {
        rate: 1,
        symbol: config.options.exchangeRates.defaultSymbol,
        currency: config.options.exchangeRates.defaultCurrency,
      }

      let newScenarioAttrs: EditPlanRequest = {
        defaultCurrency: defaultCurrency,
        grouping_levels: selectedGroupingLevels,
        observations: newObservations,
        optimisationMetric: goal,
        optimisationType: user_input.optimisationType,
        overallConstraint: user_input.overallConstraint,
        inChannel: options.in_channel,
        max_constraints: config.max_constraints ? config.max_constraints : null,
        thresholds: null,
      }

      if (newScenarioAttrs.max_constraints) {
        let maxConstraints = {
          ...config.max_constraints,
          values: [],
        }

        for (let val of config.max_constraints.values) {
          const constVal =
            (val / config.observations.length) *
            (observations[1] + 1 - observations[0])
          maxConstraints.values = [...maxConstraints.values, constVal]
        }

        newScenarioAttrs.max_constraints = maxConstraints
      }

      if (config.thresholds) {
        let thresholds: Threshold = {
          ids: [],
          weeklyValues: [],
          totalValues: [],
        }

        for (let i in config.thresholds.ids) {
          thresholds.ids = [...thresholds.ids, config.thresholds?.ids[i]]
          thresholds.weeklyValues = [
            ...thresholds.weeklyValues,
            config.thresholds.weeklyValues[i],
          ]
          thresholds.totalValues = [
            ...thresholds.totalValues,
            config.thresholds.totalValues[i],
          ]
        }

        newScenarioAttrs.thresholds = thresholds
      }

      const configId = config.id

      if (user) {
        dispatch(createPlanFromEditor(configId, user, newScenarioAttrs))
      }
    }
  }

  const changeGoal = (value: string) => {
    setGoal(value)
  }

  const changeTime = (value: number[]) => {
    setObservations(value)
  }

  const levelPlurals = levels.map((x: string) => {
    const plural_name = x + '_plural'
    if (options.in_channel) {
      return inChannelSettings![x].plural
    }
    if (settings![plural_name]) {
      return settings![plural_name]
    }
    return x
  })

  const levelNames = levels.map((x: string) => {
    const name = x + '_name'
    if (options.in_channel) {
      return inChannelSettings![x].singular
    }
    if (settings![name]) {
      return settings![name]
    }
    return x
  })

  const renderViews = () => {
    if (selectedSection === levels.length) {
      return <EditTime changeTime={changeTime} timeRange={observations} />
    }
    if (selectedSection === levels.length + 1) {
      return <EditGoal goal={goal} onSelect={changeGoal} />
    }
    return (
      <EditGroupingLevel
        selectedGroupingLevels={selectedGroupingLevels[levels[selectedSection]]}
        groupingLevels={
          groupingLevelOptions[levels[selectedSection]]
            ? groupingLevelOptions[levels[selectedSection]]
            : []
        }
        onSelect={onSelect}
        //PINEAPPLE
        label={levelNames[selectedSection]}
        sectionName={levels[selectedSection]}
      />
    )
  }

  return (
    <div className="justify-center bg-gray-900 bg-opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative  my-6 mx-auto w-9/12">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  bg-gray-100 outline-none focus:outline-none">
          {/*header*/}
          <div className="flex justify-between p-2 items-center border-b border-solid bg-gray-100 border-blueGray-200 rounded-t">
            <h3 className="text-gray-500 text-xxs font-semibold">Edit Plan</h3>
            <button
              onClick={(_) => onHide()}
              className=" bg-transparent border-0 text-black text-xl pb-1  font-semibold outline-none focus:outline-none"
            >
              <span className="bg-transparent text-black h-6 w-6 text-lg block mb-1 outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <div className="flex flex-row p-3 space-x-6">
            <div className="flex flex-col space-y-4">
              {levelPlurals.map((x: string, ind: number) => {
                return (
                  <button
                    key={ind}
                    onClick={(_) => setSelectedSection(ind)}
                    className={`${
                      selectedSection == ind ? 'border-l-4 border-gtPink ' : ''
                    } text-gtPink text-xxs  p-2 justify-start flex focus:outline-none`}
                  >
                    {/* {"pineapple fix the below"} */}
                    {x}
                  </button>
                )
              })}
              <button
                onClick={(_) => setSelectedSection(levels.length)}
                className={`${
                  selectedSection == levels.length
                    ? 'border-l-4 border-gtPink '
                    : ''
                } text-gtPink text-xxs  p-2 justify-start flex focus:outline-none`}
              >
                Time
              </button>
              <button
                onClick={(_) => setSelectedSection(levels.length + 1)}
                className={`${
                  selectedSection == levels.length + 1
                    ? 'border-l-4 border-gtPink '
                    : ''
                } text-gtPink text-xxs  p-2 justify-start flex outline-none focus:ring-0 focus:outline-none`}
              >
                Goals
              </button>
            </div>
            <div className="w-full max-h-96 overflow-auto">{renderViews()}</div>
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              onClick={(_) => onHide()}
              className="  bg-gray-300 background-transparent font-bold uppercase px-6 py-3 rounded
              text-xxs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Cancel
            </button>
            <button
              className="bg-gtPink text-white active:bg-emerald-600 font-bold uppercase text-xxs px-6 py-3
              rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={onRegenerate}
            >
              Regenerate Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(EditPlanModal)
