import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const MobileIcon: FC<Props> = ({ className, stroke }) => {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={stroke || '#666'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.0"
        >
    <path d="M10.5 1.5H8.25C7.00736 1.5 6 2.50736 6 3.75V20.25C6 21.4926 7.00736 22.5 8.25 22.5H15.75C16.9926 22.5 18 21.4926 18 20.25V3.75C18 2.50736 16.9926 1.5 15.75 1.5H13.5M10.5 1.5V3H13.5V1.5M10.5 1.5H13.5M10.5 20.25H13.5"/>
        </g>
      </g>
    </svg>
  )
}

export default MobileIcon
