import { Config } from "../types/ConfigTypes"
import { GroupingLevel, GroupingLevelValue, optionsGroupingLevels, PlanGroupingLevel, Transformation, selectedGroupingLevels } from "../types/PlanTypes"
import * as _ from 'lodash'


  const getUpdatedLevelValues = (level: string, transformations: Transformation[], config: Config) => {
    const availableChildLevels = _.uniqBy(transformations.map(trans => trans.identifiers[level]), function(e) {return e})
    const stringKey: string = level.toString()
    const newValues: any = config.grouping_levels.find(
      (level: GroupingLevel) => level.key === stringKey
    )?.values.filter((val: GroupingLevelValue) => {
      if(availableChildLevels.indexOf(val.key) !== -1){
        return val
      }
    })
    return newValues
  }

export const editPlanSelectionLogic = (level: string, config: Config, newSelected: string[], groupingLevelOptions: optionsGroupingLevels, selectedGroupingLevels: selectedGroupingLevels) => {
    let levels = config.grouping_levels.map(x => x.key)
    let returnOptions: optionsGroupingLevels = {
    }
    const levelIndex = levels.indexOf(level)

    //calculates the new options for the current level and all levels before
    let filteredTransformations = config.transformations.filter(x => {
      if(newSelected.indexOf(x.identifiers[levels[levelIndex]]) !== -1){
        return x
      }
    })



    const newLevels = getUpdatedLevelValues(level, config.transformations, config)
    for(let i = levelIndex; i >= 0; i--){
      returnOptions[levels[i]] = groupingLevelOptions[levels[i]]
    }
    returnOptions[level] = newLevels
    //calculates new options for levels after
    let transformsBelow = filteredTransformations
    for(let i = levelIndex + 1; i < levels.length; i++){
      let newLevels = getUpdatedLevelValues(levels[i], transformsBelow, config)
      transformsBelow.filter((trans: Transformation) => {
        const keys: string[] = newLevels.map((x: GroupingLevelValue) => x.key)
        if(keys.indexOf(trans.identifiers[levels[i]]) !== -1) {
          return trans
        }
      })
      returnOptions[levels[i]] = newLevels
    }
      return returnOptions
}

export const getFilteredTransforms = (level: string, transformations: Transformation[], grouping_levels: PlanGroupingLevel) => {
  let filteredTransforms = transformations
  let levels = Object.keys(grouping_levels)
  const levelIndex = levels.indexOf(level)
  if(levelIndex === 0){
    return filteredTransforms
  }

  for(let i = levelIndex - 1; i >= 0; i--){
    filteredTransforms.filter(x => {
      if(grouping_levels[levels[i]].length > 0){
        if(grouping_levels[levels[i]].indexOf(x.identifiers[levels[i]]) !== -1){
          return x
        }
      } else {
        return x
      }
    })
  }

  return filteredTransforms
}