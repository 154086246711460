import { FC, ReactNode } from 'react'
import { Button } from '@gain-theory/button'
import { Typography } from '@gain-theory/typography'
import { Icon } from '@gain-theory/icon'
import { Tooltip } from '@gain-theory/tooltip'
import { Chip } from '@gain-theory/chip'

interface ICreateMarketingPlanLayoutProps {
  title: string
  titleChip?: string
  titleTooltip?: {
    title: string
    description: string
  }
  subtitle?: string
  component: ReactNode
  isLastStep?: boolean
  onBack?: () => void
  onNext: () => void
}

const CreateMarketingPlanLayout: FC<ICreateMarketingPlanLayoutProps> = ({
  title,
  titleChip,
  titleTooltip,
  subtitle,
  component,
  isLastStep = false,
  onBack,
  onNext,
}) => {
  const BackButton = (
    <Button
      variant="secondary"
      leadingIcon={<Icon name="ArrowLeftIcon" color="brand-primary-main" />}
      onClick={onBack}
    >
      Back
    </Button>
  )

  const NextButton = (
    <Button
      variant="primary"
      leadingIcon={
        <Icon
          name={isLastStep ? 'PlayIcon' : 'ArrowRightIcon'}
          color="neutral-white"
        />
      }
      onClick={onNext}
    >
      {isLastStep ? 'Generate Plan' : 'Next'}
    </Button>
  )

  const TitleChip = titleChip && (
    <div className="w-fit pt-2">
      <Chip>{titleChip}</Chip>
    </div>
  )

  const TooltipContent = (
    <div className="flex flex-col gap-md">
      <Typography.Body1>
        <span className="text-info-dark">{titleTooltip?.title}</span>
      </Typography.Body1>
      <Typography.Body2>{titleTooltip?.description}</Typography.Body2>
    </div>
  )

  const HeaderTooltip = titleTooltip && (
    <Tooltip interactive position="bottom" content={TooltipContent}>
      <Icon name="InformationCircleIcon" color="info-main" />
    </Tooltip>
  )

  const Header = (
    <div className="flex flex-row gap-2 items-start justify-center">
      {TitleChip}
      <div className="flex flex-col gap-2 items-start justify-center">
        <div className="flex flex-row gap-2 items-center justify-start">
          <Typography.H4>{title}</Typography.H4>
          {HeaderTooltip}
        </div>
        <Typography.Body1 className="text-text-medium-emphasis">
          {subtitle}
        </Typography.Body1>
      </div>
    </div>
  )

  return (
    <div
      className="flex flex-col gap-md w-full items-center justify-between p-8"
      style={{ minHeight: '400px' }}
    >
      {Header}
      <div className="flex w-full h-full justify-center items-center">
        {component}
      </div>
      <div className="flex flex-row-reverse flex-grow-0 flex-shrink-0 justify-center items-center gap-md">
        {NextButton}
        {BackButton}
      </div>
    </div>
  )
}

export default CreateMarketingPlanLayout
