import { FC, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { CSVLink } from 'react-csv'

// Redux
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { selectComparisonData } from '../../../redux/reducers/PlanComparisonsReducer'
// Components

// Constants

import { RootStore } from '../../../redux/reducers/Store'
import { DeletePlanComparison, GetPlanComparisonData } from '../../../socket.io'
import { downloadPlanComparisonCSVData } from '../../../utils/downloadPlanComparisonCSVData'
import { AppState } from '../../../redux/reducers/RootReducer'

const mapState = (state: RootStore) => ({
  comparisonData: selectComparisonData(state),
})

const mapDispatchToProps = {
  onDeletePlanComparison: DeletePlanComparison,
  getPlanComparisonData: GetPlanComparisonData,
}

interface OwnProps {
  id: string
  name: string
  detailedData: string[][][]
  fromOutputPage: boolean
}

type Props = OwnProps

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanComparisonsActionsMenu: FC<Props & PropsFromRedux> = (props) => {
  const [downloadData, setDownloadData] = useState<string[][]>([[]])
  const [downloadDetailedData, setDownloadDetailedData] = useState<string[][]>([
    [],
  ])

  const { settings } = useSelector((state: AppState) => state.settings)

  useEffect(() => {
    if (props.id && props.fromOutputPage) {
      props.getPlanComparisonData(props.id)
    }
  }, [])

  useEffect(() => {
    if (props.detailedData.length > 0 && props.fromOutputPage) {
      let detailedData: string[][] = []
      const firstRow = ['Plan'].concat(props.detailedData[0][0])
      for (let i = 0; i < props.detailedData.length; i++) {
        for (let j = 1; j < props.detailedData[i].length; j++) {
          const newRow = [(i + 1).toString()].concat(props.detailedData[i][j])
          detailedData = [...detailedData, newRow]
        }
      }
      detailedData.unshift(firstRow)
      setDownloadDetailedData(detailedData)
    }
  }, [props.detailedData])



  useEffect(() => {
    if (props.comparisonData && props.fromOutputPage && settings) {
              //pineapple
      const headers = Object.keys(props.comparisonData.plans[0].transformations[0].identifiers).filter(x => x !== "region_key")
      setDownloadData(
        downloadPlanComparisonCSVData(props.comparisonData, headers, settings)
      )

    }
  }, [props.comparisonData])

  const deletePlanComparison = () => {
    props.onDeletePlanComparison(props.id)
  }

  return (
    <div className="group inline-block ">
      <button className="outline-none focus:outline-none border px-3 py-1 bg-white rounded-sm flex items-center min-w-32">
        <span className=" pr-1 font-semibold text-gray-400 flex-1">
          Actions
        </span>
        <span>
          <svg
            className="fill-current text-gtPink h-4 w-4 transform group-hover:-rotate-180
        transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </button>
      <ul
        className="bg-white border text-xxs text-gray-500 rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
  transition duration-150 ease-in-out origin-top min-w-32"
      >
        <li className={'rounded-sm px-3 py-1 hover:bg-gray-100'}>
          <button onClick={deletePlanComparison} className={'w-full text-left'}>
            Delete
          </button>
        </li>
        {props.fromOutputPage && (
          <li
            className={`rounded-sm px-3 py-1 hover:bg-gray-100 dropdown relative`}
          >
            {' '}
            <button>Downloads</button>
            <ul className="dropdown-content min-w-32 absolute bg-white hidden text-gray-500 border rounded -left-3 ml-1 top-0">
              <li
                className={`rounded-sm px-3 py-1 hover:bg-gray-100 dropdown relative`}
              >
                <CSVLink
                  data={downloadData}
                  filename={
                    props?.comparisonData?.name
                      ? props?.comparisonData?.name + '.csv'
                      : 'Comparison.csv'
                  }
                >
                  Summary
                </CSVLink>
              </li>
              <li
                className={`rounded-sm px-3 py-1 hover:bg-gray-100 dropdown relative`}
              >
                <CSVLink
                  data={downloadDetailedData}
                  filename={
                    props.name
                      ? props.name + '.csv'
                      : props.id + 'Comparison.csv'
                  }
                >
                  {' '}
                  Detailed
                </CSVLink>
              </li>
            </ul>
          </li>
        )}
        <li className={'rounded-sm px-3 py-1 hover:bg-gray-100'}></li>
      </ul>
    </div>
  )
}

export default connector(PlanComparisonsActionsMenu)
