import { Plan } from '../../types/PlanTypes'
import { Interface } from 'readline'

export const SHOW_EDIT_PLAN_MODAL = 'SHOW_EDIT_PLAN_MODAL'
export const HIDE_EDIT_PLAN_MODAL = 'HIDE_EDIT_PLAN_MODAL'
export const EDIT_PLAN_ATTEMPT = 'EDIT_PLAN_ATTEMPT'
export const EDIT_PLAN_FAIL = 'EDIT_PLAN_FAIL'
export const EDIT_PLAN_SUCCESS = 'EDIT_PLAN_SUCCESS'
export const EDIT_PLAN_CREATED_STATUS = 'EDIT_PLAN_CREATED_STATUS'

interface ShowEditPlanModal {
  type: typeof SHOW_EDIT_PLAN_MODAL
  payload: number
}

interface HideEditPlanModal {
  type: typeof HIDE_EDIT_PLAN_MODAL
}

interface EditPlanAttempt {
  type: typeof EDIT_PLAN_ATTEMPT
}

interface EditPlanFail {
  type: typeof EDIT_PLAN_FAIL
}

interface EditPlanSuccess {
  type: typeof EDIT_PLAN_SUCCESS
  payload: Plan
}

interface EditPlanCreatedStatus {
  type: typeof EDIT_PLAN_CREATED_STATUS
  payload: boolean
}

export type EditPlanActionTypes =
  | ShowEditPlanModal
  | HideEditPlanModal
  | EditPlanAttempt
  | EditPlanCreatedStatus
  | EditPlanFail
  | EditPlanSuccess
