import React, { FC } from 'react'

interface OwnProps {
  className: string
  stroke?: string | null
}

type Props = OwnProps

const PrintIcon: FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke={stroke || '#666'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.0"
        >
          <path d="M9.087 1h29.652M9.087 25.26h13.478V9.088H9.087zM30.652 14.478h8.087M27.957 19.87h10.782M27.957 25.26h10.782M9.087 30.652h29.652M9.087 36.043h29.652M9.087 41.435h29.652M52.217 13.372h5.392v41.472c0 1.526-1.205 2.765-2.696 2.765-1.488 0-2.696-1.239-2.696-2.765V3.696h-5.39v5.53" />
          <path d="M54.913 63H9.087A8.088 8.088 0 0 1 1 54.913V1h45.826v53.913a8.088 8.088 0 0 0 16.174 0V9.087H52.217" />
        </g>
      </g>
    </svg>
  )
}

export default PrintIcon
