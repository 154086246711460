export const CHART_BAR_COLORS = [
  '#e84605', // same as gtChartBarColor1 - TODO: remove this hardcoded value once dependencies are refactored.
  '#df0030', // same as gtChartBarColor2 - TODO: remove this hardcoded value once dependencies are refactored.
  '#e10d6d', // same as gtChartBarColor3 - TODO: remove this hardcoded value once dependencies are refactored.
  '#ba1375', // same as gtChartBarColor4 - TODO: remove this hardcoded value once dependencies are refactored.
  '#89187f', // same as gtChartBarColor5 - TODO: remove this hardcoded value once dependencies are refactored.
  '#3f2580', // same as gtChartBarColor6 - TODO: remove this hardcoded value once dependencies are refactored.
  '#175fae', // same as gtChartBarColor7 - TODO: remove this hardcoded value once dependencies are refactored.
  '#009ddb', // same as gtChartBarColor8 - TODO: remove this hardcoded value once dependencies are refactored.
  '#139c6b', // same as gtChartBarColor9 - TODO: remove this hardcoded value once dependencies are refactored.
  '#5db42b', // same as gtChartBarColor10 - TODO: remove this hardcoded value once dependencies are refactored.
  '#fab200', // same as gtChartBarColor11 - TODO: remove this hardcoded value once dependencies are refactored.
  '#f9a500', // same as gtChartBarColor12 - TODO: remove this hardcoded value once dependencies are refactored.
]

// we need the tailwind classnames to be explicit for the chart
// Dynamic classes are not supported in tailwind
export const CHART_BAR_COLOR = {
  bg: [
    'bg-gtChartBarColor1',
    'bg-gtChartBarColor2',
    'bg-gtChartBarColor3',
    'bg-gtChartBarColor4',
    'bg-gtChartBarColor5',
    'bg-gtChartBarColor6',
    'bg-gtChartBarColor7',
    'bg-gtChartBarColor8',
    'bg-gtChartBarColor9',
    'bg-gtChartBarColor10',
    'bg-gtChartBarColor11',
    'bg-gtChartBarColor12',
  ],
  text: [
    'text-gtChartBarColor1',
    'text-gtChartBarColor2',
    'text-gtChartBarColor3',
    'text-gtChartBarColor4',
    'text-gtChartBarColor5',
    'text-gtChartBarColor6',
    'text-gtChartBarColor7',
    'text-gtChartBarColor8',
    'text-gtChartBarColor9',
    'text-gtChartBarColor10',
    'text-gtChartBarColor11',
    'text-gtChartBarColor12',
  ],
  stroke: [
    'stroke-gtChartBarColor1',
    'stroke-gtChartBarColor2',
    'stroke-gtChartBarColor3',
    'stroke-gtChartBarColor4',
    'stroke-gtChartBarColor5',
    'stroke-gtChartBarColor6',
    'stroke-gtChartBarColor7',
    'stroke-gtChartBarColor8',
    'stroke-gtChartBarColor9',
    'stroke-gtChartBarColor10',
    'stroke-gtChartBarColor11',
    'stroke-gtChartBarColor12',
  ],
  fill: [
    'fill-gtChartBarColor1',
    'fill-gtChartBarColor2',
    'fill-gtChartBarColor3',
    'fill-gtChartBarColor4',
    'fill-gtChartBarColor5',
    'fill-gtChartBarColor6',
    'fill-gtChartBarColor7',
    'fill-gtChartBarColor8',
    'fill-gtChartBarColor9',
    'fill-gtChartBarColor10',
    'fill-gtChartBarColor11',
    'fill-gtChartBarColor12',
  ],
  sanky: [
    'fill-gtSankyChartBarColor1',
    'fill-gtSankyChartBarColor2',
    'fill-gtSankyChartBarColor3',
    'fill-gtSankyChartBarColor4',
    'fill-gtSankyChartBarColor5',
    'fill-gtSankyChartBarColor6',
    'fill-gtSankyChartBarColor7',
    'fill-gtSankyChartBarColor8',
    'fill-gtSankyChartBarColor9',
    'fill-gtSankyChartBarColor10',
    'fill-gtSankyChartBarColor11',
    'fill-gtSankyChartBarColor12',
  ]
}

export const FLOW_CHART_BAR_COLORS = [
  '0e84605',
  '0df0030',
  '0e10d6d',
  '0ba1375',
  '089187f',
  '03f2580',
  '0175fae',
  '0009ddb',
  '0139c6b',
  '05db42b',
  '0fab200',
  '0f9a500',
  '0f9a510',
  '0f9a520',
  '0f9a530',
]

export const CHART_BAR_COLOR_GREY = '#b8b8b8'
