import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import { GetPlanItem } from '../../redux/actions/PlanActions'
import {
  getConstraintsError,
  getConstraintsLoading,
  getConstraintsSuccess,
} from '../../redux/reducers/ConstraintsReducer'
import {
  selectIsLoading,
  selectPlanItem,
} from '../../redux/reducers/PlanReducer'
import { selectSessionUser } from '../../redux/reducers/SessionReducer'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { EditSPOConstraints } from '../../socket.io'
import { Constraint } from '../../types/PlanTypes'
import { useConstraintsTimeForPlan } from './shared/hooks/use-constraints-time-for-plan'
import TimePeriodBudgetConstraints from './shared/time-period-budget-constraints'

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  planItem: selectPlanItem(state),
  settings: selectSettings(state),
  isLoading: selectIsLoading(state),
  loading: getConstraintsLoading(state),
  success: getConstraintsSuccess(state),
  error: getConstraintsError(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  editSPOTimeConstraints: EditSPOConstraints,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const PlanConstraintsTime: FC<PropsFromRedux> = (props) => {
  const history = useHistory()
  const { id }: { id: string } = useParams()

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

  useEffect(() => {
    props.onGetPlan(id)
  }, [])

  useEffect(() => {
    if (props.success) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', props.planItem.id)
        .toString()

      history.push(url)
    }
  }, [props.success])

  const handleBackClick = () => {
    const url = UrlAssembler()
      .template(ROUTES.SPO.PLAN_ITEM)
      .param('id', id)
      .toString()

    history.push(url)
  }

  const handleRegeneratePlan = (constraints?: Constraint[] | undefined) => {
    if (!props.user) return

    //add carryover weeks at the end of the constraints
    const carryoverConstraints: Constraint[] = constraints
      ? [...constraints]
      : []

    if (
      props.planItem.options.carryoverWeeks &&
      props.planItem.options.carryoverWeeks > 1
    ) {
      carryoverConstraints.forEach((constraint: Constraint) => {
        const values = constraint.values
        const carryoverArray = new Array(
          props.planItem.options.carryoverWeeks
        ).fill(0)

        values.push(...carryoverArray)

        constraint.values = values
      })
    }

    const numberOfLevels = Object.keys(props.planItem.grouping_levels).length

    const existingNonTimeConstraints = props.planItem.constraints.filter(
      (x: Constraint) => {
        if (Object.keys(x.identifiers).length !== numberOfLevels) {
          return x
        }
      }
    )

    const newPlan = {
      ...props.planItem,
      constraints: existingNonTimeConstraints.concat(carryoverConstraints),
    }

    props.editSPOTimeConstraints(newPlan, newPlan.id, props.user)
  }

  const timePeriodBudgetConstraintsProps = useConstraintsTimeForPlan({
    plan: props.planItem,
    settings: props.settings,
    error: props.error,
    handleBackCallback: handleBackClick,
    handleRegenerateCallback: handleRegeneratePlan,
  })

  useEffect(() => {
    const isLoading = props.isLoading || props.loading
    const hasNotLoadedPlan = !props.planItem

    setShowLoadingModal(isLoading || hasNotLoadedPlan)
  }, [props.planItem, props.isLoading, props.loading])

  if (showLoadingModal || !timePeriodBudgetConstraintsProps)
    return <LoadingModal />

  return <TimePeriodBudgetConstraints {...timePeriodBudgetConstraintsProps} />
}

export default connector(MainLayout(PlanConstraintsTime, true))
