import { Children } from 'react'
import { Breadcrumbs as GTBreadcrumba } from '@gain-theory/breadcrumbs'
import { Link } from 'react-router-dom'
import ChevronRightDefaultIcon from '../../../../../icons/ChevronRightDefaultIcon'
import { getNavs } from './util'

type TCrumb = {
  href?: string
  label: string
  devider?: boolean
  target?: string
}
type TNavs = {
  navs: TCrumb[]
}

const Navs = ({ navs }: TNavs) => {
  return (
    <nav className="rounded-md w-full">
      <ol className="list-reset flex">
        {Children.toArray(
          navs.map(({ label, href, devider, target }) => (
            <>
              <li>
                {href ? (
                  <Link
                    to={href}
                    className="text-gtPink no-underline hover:underline"
                    target={target}
                  >
                    {label}
                  </Link>
                ) : (
                  <span className="text-gtDarkGray">{label}</span>
                )}
              </li>
              {devider && <ChevronRightDefaultIcon className="w-6 h-6 mx-2" />}
            </>
          ))
        )}
      </ol>
    </nav>
  )
}

export const Breadcrumbs = ({ bread }: { bread: string }) => (
  <div className="grid grid-flow-col place-content-between">
    <GTBreadcrumba breadcrumbsConfig={getNavs(bread)} />
    <Navs navs={getNavs(bread, true)} />
  </div>
)
