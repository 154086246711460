import { Configuration, PopupRequest } from '@azure/msal-browser'
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser'

export function createAzureConfig(clientId: string, authority: string) {
  return {
    auth: {
      clientId,
      authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    system: {
      allowNativeBroker: false,
    },
  } satisfies Configuration
}

export const azureLoginRequest: PopupRequest = {
  scopes: ['User.Read'],
}

export async function initializeAzureAd(
  clientId: string,
  authority: string,
  callback: (token: string) => void
) {
  const msalConfig = createAzureConfig(clientId, authority)

  const msalInstance = new PublicClientApplication(msalConfig)

  await msalInstance.initialize().then(() => {
    msalInstance.addEventCallback((event: EventMessage) => {
      const isSuccessCallback =
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS

      if (isSuccessCallback && event.payload) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)

        callback(payload.accessToken)
      }
    })
  })

  return msalInstance
}
