import { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const LogoWhiteIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 48 48"
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M23.928.85C10.837.85.224 11.462.224 24.553c0 10.893 7.348 20.068 17.358 22.842l1.33-6.21c-7.147-2.151-12.354-8.783-12.354-16.632 0-9.593 7.777-17.37 17.37-17.37s17.37 7.777 17.37 17.37a17.41 17.41 0 0 1-.472 4.03l6.197 1.328c.398-1.722.608-3.515.608-5.358C47.631 11.463 37.02.85 23.928.85" />
        <path
          fillOpacity=".3"
          d="M23.895 7.187c-2.193 0-4.29.408-6.222 1.149l2.025 5.916a11.09 11.09 0 0 1 4.197-.82c6.144 0 11.124 4.98 11.124 11.124S30.039 35.68 23.895 35.68a11.122 11.122 0 0 1-9.78-5.82l-5.963 2.041c2.767 5.921 8.775 10.024 15.743 10.024 9.593 0 17.37-7.776 17.37-17.369 0-9.592-7.777-17.369-17.37-17.369"
        />
        <path d="M23.754 24.33h6.554l.001-.034a6.555 6.555 0 1 0-6.555 6.555v-6.522z" />
      </g>
    </svg>
  )
}

export default LogoWhiteIcon
