import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../Routes'
import { CreateOktaSession } from '../../../../redux/actions/SessionActions'
import { RootStore } from '../../../../redux/reducers/Store'
import { useAuthLoginNotification } from '../../hooks/use-auth-login-notification'

export const useLoginCallbacks = () => {
    const REDIRECT_URL = `${window.location.origin}/crayon/session/callback`

    const notification = useAuthLoginNotification()

    const authed = useSelector((state: RootStore) => state.session.isAuthed)

    const history = useHistory()
    const dispatch = useDispatch()

    const [hasError, setHasError] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')

    const onSignInError = (error: any) => {
        notification.getValidateAuthenticationError()
        setHasError(true)
    }

    const onSignInSuccess = async (response: any) => {
        if (response.accessToken) {
            const accessToken = response.accessToken.accessToken

            if (!accessToken) {
                return
            }

            dispatch(CreateOktaSession(accessToken))

            setUsername(response.username)
        } else {
            setHasError(true)
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    }

    useEffect(() => {
        if (!authed) {
            return
        }

        history.push({
            pathname: ROUTES.HOME,
            state: { oktaUser: username },
        })
    }, [username, authed])

    return {
        onSignInError,
        onSignInSuccess,
        redirectUrl: REDIRECT_URL,
        hasError,
    }
}
