import '@gain-theory/ag-grid/index.css'
import '@gain-theory/onetrust-sdk/branding/style.css'
import { APP_ENV_PREFIX, useInjectPendo } from '@gain-theory/pendo-sdk'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-dark.css'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import './App.css'
import { ROUTES } from './Routes'
import { FeatureProvider } from './features'
import features from './features/flags.json'
import { useConsent } from './hooks/use-consent'
import Admin from './pages/Admin/Admin'
import ChangePassword from './pages/Admin/ChangePassword'
import ChangeSettings from './pages/Admin/ChangeSetting'
import CreateGroup from './pages/Admin/CreateGroup'
import CreateUser from './pages/Admin/CreateUser'
import ManageConfigs from './pages/Admin/ManageConfigs'
import ManageGroups from './pages/Admin/ManageGroups'
import ManageOneGroup from './pages/Admin/ManageOneGroup'
import ManageOneUser from './pages/Admin/ManageOneUser'
import ManageUsers from './pages/Admin/ManageUsers'
import UploadConfig from './pages/Admin/UploadConfig'
import UploadUsers from './pages/Admin/UploadUsers'
import AdminLogin from './pages/AdminLogin'
import NoPermissions from './pages/NoPermissionsPage'
import NotFoundPage from './pages/NotFoundPage'
import LaunchpadHome from './pages/launchpad/home/Home'
import Login from './pages/login/login'
import Highlights from './pages/md/Highlights'
import MarketingDiagnostics from './pages/md/MarketingDiagnostics'
import PerformanceOverview from './pages/md/PerformanceOverview'
import UnifiedAdvancedView from './pages/md/UnifiedAdvancedView'
import ChannelPerformance from './pages/md/channel-performance/ChannelPerformance'
import SankeyView from './pages/md/imr/SankeyView'
import InChannel from './pages/spo/InChannel'
import PlanConstraintsTime from './pages/spo/PlanConstraintsTime'
import PlanList from './pages/spo/PlanList'
import PlanItem from './pages/spo/PlanOutlook'
import SPO from './pages/spo/SPO'
import AddOrCutBudgetAmount from './pages/spo/SPOFlowPages/AddOrCutBudgetAmount'
import CreateInChannelPlan from './pages/spo/SPOFlowPages/CreateInChannelPlan'
import EditPlanBudget from './pages/spo/SPOFlowPages/EditPlanBudget'
import UploadInChannelPlan from './pages/spo/SPOFlowPages/UploadInChannelPlan'
import CreateMarketingPlan from './pages/spo/create-marketing-plan/CreateMarketingPlan'
import ComparisonOutput from './pages/spo/plan-comparisons/ComparisonOutput'
import CreateComparison from './pages/spo/plan-comparisons/CreateComparison'
import PlanComparisonsSummary from './pages/spo/plan-comparisons/PlanComparisons'
import UploadMarketingPlan from './pages/spo/upload-marketing-plan/UploadMarketingPlan'
import { RecoverSession } from './redux/actions/SessionActions'
import { GetOktaSettings, GetSettings } from './redux/actions/SettingsActions'
import { AppState } from './redux/reducers/RootReducer'
import { RootStore } from './redux/reducers/Store'
import { AuthRoute } from './shared/AuthRoute'
import TermsAcknowledgmentModal from './shared/TermsAcknowledgmentModal'
import { UnAuthedRoute } from './shared/UnAuthedRoute'
import { NotificationProvider } from './shared/notification-provider'
import { AnalyticsType } from './types'
import { getAccountsMetadata, getVisitorMetadata } from './utils/analytics'
import { TC_ACKNOWLEDGE_COOKIE_NAME } from './utils/constants'
import { getReleaseVersion } from './utils/releaseVersion'

const mapState = (state: RootStore) => ({})

const mapDispatchToProps = {
  onRecoverSession: RecoverSession,
  onGetSettings: GetSettings,
  onGetOktaSettings: GetOktaSettings,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {}

const App: FC<OwnProps> = (props) => {
  const { user, loadingSession } = useSelector(
    (state: AppState) => state.session
  )

  const history = useHistory()
  const { selectedOktaSetting } = useSelector(
    (state: RootStore) => state.settings
  )

  const [releaseVersion, setReleaseVersion] = useState<string>('')

  const [permissionLoad, setPermissionLoad] = useState(false)

  const authed = useSelector((state: RootStore) => state.session.isAuthed)
  const authedAndAdmin = !user ? false : user.admin && authed

  useInjectPendo(APP_ENV_PREFIX.REACT)
  useConsent()
  useEffect(() => {
    getReleaseVersion().then((version) => setReleaseVersion(version))
  }, [])

  useEffect(() => {
    props.onGetOktaSettings()
    props.onRecoverSession()
    props.onGetSettings()
  }, [])

  useEffect(() => {
    if (!authed) return

    const allCookie = document.cookie.split(';')
    const hasAcknowledged = allCookie.some((item) =>
      item.includes(`${TC_ACKNOWLEDGE_COOKIE_NAME}=true`)
    )

    if (!hasAcknowledged) {
      history.push(ROUTES.TERMS_ACKNOWLEDGMENT)
    }
  }, [authed])

  const analytics: AnalyticsType = {
    account: getAccountsMetadata(),
    visitor: getVisitorMetadata(user),
  }

  type Permissions = {
    spo: boolean
    reporting: boolean
  }
  const [permissions, setPermissions] = useState<Permissions>({
    spo: false,
    reporting: false,
  })

  useEffect(() => {
    if (user && user.userGroups && user.userGroups.length !== 0) {
      const reportingPermission =
        user.userGroups.find((userGroup: any) =>
          userGroup.group.modules.includes('md')
        ) !== undefined
      const spoPermission =
        user.userGroups.find((userGroup: any) =>
          userGroup.group.modules.includes('spo')
        ) !== undefined
      setPermissions({ spo: spoPermission, reporting: reportingPermission })
    }
    if (user) {
      setPermissionLoad(true)
    }
    if (!authed) {
      setPermissionLoad(false)
    }
  }, [user])

  if (!selectedOktaSetting) {
    return null
  }

  if (!permissionLoad || loadingSession) {
    return (
      <>
        <Helmet>
          <meta name="gti-version" content={releaseVersion} />
        </Helmet>
        <NotificationProvider>
          <FeatureProvider features={features}>
            <Switch>
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.HOME}
                component={LaunchpadHome}
              />

              <Route exact path={ROUTES.LOGIN} component={Login} />

              <UnAuthedRoute
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.LOGIN}
                component={AdminLogin}
              />
              {!loadingSession && !authed && (
                <Route
                  exact
                  render={(match) => {
                    if (match.location.pathname !== ROUTES.ADMIN.LOGIN) {
                      return <Redirect to={ROUTES.LOGIN} />
                    }
                  }}
                />
              )}
            </Switch>
          </FeatureProvider>
        </NotificationProvider>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <meta name="gti-version" content={releaseVersion} />
      </Helmet>
      <NotificationProvider>
        <FeatureProvider features={features}>
          <Switch>
            <UnAuthedRoute
              isAuthenticated={authedAndAdmin}
              exact
              path={ROUTES.ADMIN.LOGIN}
              component={AdminLogin}
            />

            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Switch>
              {/*PP Acknowledgement Modal*/}
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.TERMS_ACKNOWLEDGMENT}
                component={TermsAcknowledgmentModal}
              />
              {/*end of PP Acknowledgement Modal*/}
              {/*Launchpad*/}
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.HOME}
                component={LaunchpadHome}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.NOPERMISSION}
                component={NoPermissions}
              />
              {/*end of Launchpad*/}
              {/*MD*/}
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.MARKETING_DIAGNOSTICS.INDEX}
                component={MarketingDiagnostics}
              />
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.MARKETING_DIAGNOSTICS.HIGHLIGHTS}
                component={Highlights}
              />
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.MARKETING_DIAGNOSTICS.PERFORMANCE_OVERVIEW}
                component={PerformanceOverview}
              />

              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                path={
                  ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE_PREV_PHASE
                }
                component={ChannelPerformance}
              />
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.MARKETING_DIAGNOSTICS.CHANNEL_PERFORMANCE}
                component={ChannelPerformance}
              />
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.MARKETING_DIAGNOSTICS.UNIFIED_ADVANCED_VIEW}
                component={UnifiedAdvancedView}
              />
              <AuthRoute
                isPermitted={permissions.reporting}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.MARKETING_DIAGNOSTICS.IMR_VIEW_LINK}
                component={SankeyView}
              />
              {/*end of MD*/}
              {/*SPO*/}
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.INDEX}
                component={SPO}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                key="in-channel"
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.IN_CHANNEL_INDEX}
                component={InChannel}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                key="cross-channel"
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.CROSS_CHANNEL_SCENARIOS}
                component={PlanList}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                key="in-channel"
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.IN_CHANNEL_SCENARIOS}
                component={PlanList}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.PLAN_ITEM_CONTRAINTS_TIME} // This needs to be before the /:id/:comparisonId route
                component={PlanConstraintsTime}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.COMPARISONS}
                component={PlanComparisonsSummary}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.COMPARISON_CREATE_FLOW}
                component={CreateComparison}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.COMPARISONS_OUTPUT}
                component={ComparisonOutput}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.CREATE_PLAN}
                component={CreateMarketingPlan}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.CREATE_IN_CHANNEL_PLAN}
                component={CreateInChannelPlan}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.PLAN_UPLOAD}
                component={UploadMarketingPlan}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.IN_CHANNEL_PLAN_UPLOAD}
                component={UploadInChannelPlan}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.SPO.EDIT_BUDGET}
                component={EditPlanBudget}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.EDIT_BUDGET_SELECTION}
                component={AddOrCutBudgetAmount}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.PLAN_ITEM_FROM_COMPARISON}
                component={PlanItem}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.PLAN_ITEM}
                component={PlanItem}
              />
              <AuthRoute
                isPermitted={permissions.spo}
                analytics={analytics}
                isAuthenticated={authed}
                path={ROUTES.SPO.IN_CHANNEL_PLAN_ITEM}
                component={PlanItem}
              />
              {/*end of SPO*/}
              {/*ADMIN routes*/}
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.INDEX}
                component={Admin}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.SETTINGS}
                component={ChangeSettings}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authed}
                exact
                path={ROUTES.CHANGE_PASSWORD}
                component={ChangePassword}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.MANAGE_USERS}
                component={ManageUsers}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.CREATE_USER}
                component={CreateUser}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.UPLOAD_USERS}
                component={UploadUsers}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.MANAGE_ONE_USER}
                component={ManageOneUser}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.CREATE_GROUPS}
                component={CreateGroup}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.MANAGE_GROUPS}
                component={ManageGroups}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.MANAGE_ONE_GROUP}
                component={ManageOneGroup}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.UPLOAD_CONFIG}
                component={UploadConfig}
              />
              <AuthRoute
                isPermitted={true}
                analytics={analytics}
                isAuthenticated={authedAndAdmin}
                exact
                path={ROUTES.ADMIN.MANAGE_CONFIGS}
                component={ManageConfigs}
              />

              {/* Last route should be NotFoundPage in case the current route do not match any of previous*/}
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Switch>
        </FeatureProvider>
      </NotificationProvider>
    </>
  )
}

export default connector(App)
