import React, { Component } from "react";

export const DimensionsHOC = function(WrappedComponent) {
    // https://github.com/digidem/react-dimensions is not maintained
    // and it doesnt grow, despite overflow:visible, because it sets width+height to zero
    return class Dimensions extends Component {
        constructor() {
            super();

            this.updateDimensions = this.updateDimensions.bind(this);
        }

        componentDidMount() {
            this.updateDimensions();

            // don't use this because it doesnt work when styles change as a result of CSS rules
            // just use a timer, job done.
            // window.addEventListener('resize', this.updateDimensions, false)
            this.interval = setInterval(this.updateDimensions, 100);
        }

        updateDimensions() {
            const width = this.refs.container.clientWidth;
            if (this.state.width !== width) {
                this.setState({
                    width: this.refs.container.clientWidth
                });
            }
        }

        componentWillUnmount() {
            // window.removeEventListener('resize', this.updateDimensions, false)
            clearInterval(this.interval);
        }

        state = {};

        render() {
            // todo: do height aswell
            return (
                <div ref="container">
                    {typeof this.state.width === "number" && (
                        <WrappedComponent
                            {...this.props}
                            containerWidth={this.state.width}
                        />
                    )}
                </div>
            );
        }
    };
};

export default DimensionsHOC;
