import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EModules, EProducts, EServiceAreas } from '../utils'
import { ModuleType, ProductType, ServiceAreaType } from '../types'
import jsonProducts from '../utils/products.json'
import jsonServiceAreas from '../utils/service-areas.json'
import jsonModules from '../utils/modules.json'
import { ROUTES } from '../../../../Routes'
import { AppState } from '../../../../redux/reducers/RootReducer'

type TServiceAreaItem = {
  id: string
  name: string
}

type TProductItem = {
  id: string
  name: string
  description: string
  show: boolean
}

type TModuleItem = {
  id: string
  name: string
}

export function useSolutions() {
  const { modules: gtiModules } = useSelector(
    (state: AppState) => state.session
  )

  const modules: TModuleItem[] = JSON.parse(JSON.stringify(jsonModules))
  const products: TProductItem[] = JSON.parse(JSON.stringify(jsonProducts))
  const serviceAreas: TServiceAreaItem[] = JSON.parse(
    JSON.stringify(jsonServiceAreas)
  )

  const [translatedServiceAreas, setTranslatedServiceAreas] = useState<
    ServiceAreaType[]
  >([])
  const [translatedProducts, setTranslatedProducts] = useState<ProductType[]>(
    []
  )
  const [translatedModules, setTranslatedModules] = useState<ModuleType[]>([])

  const productsProps: Record<
    EProducts,
    Pick<ProductType, 'icon' | 'serviceAreas' | 'module'>
  > = {
    [EProducts.BUSINESS_PERFORMANCE_METRICS]: {
      icon: { name: 'barChartSquareUp', library: 'svg' },
      serviceAreas: [EServiceAreas.WHAT_HAPPENED],
      module: EModules.REPORTING,
    },
    [EProducts.BUSINESS_DRIVERS_METRICS]: {
      icon: { name: 'barLineChart', library: 'svg' },
      serviceAreas: [EServiceAreas.WHAT_HAPPENED],
      module: EModules.REPORTING,
    },
    [EProducts.MARKETING_MEDIA_ACTIVITY]: {
      icon: { name: 'messageNotificationSquare', library: 'svg' },
      serviceAreas: [EServiceAreas.WHAT_HAPPENED],
      module: EModules.REPORTING,
    },
    [EProducts.MEASURED_BUSINESS_DRIVERS]: {
      icon: { name: 'file07', library: 'svg' },
      serviceAreas: [EServiceAreas.WHY_DID_IT_HAPPEN],
      module: EModules.REPORTING,
    },
    [EProducts.SCENARIO_PLANNING_OPTIMISATION]: {
      icon: { name: 'ClipboardDocumentListIcon' },
      serviceAreas: [EServiceAreas.WHAT_NEXT],
      module: EModules.SCENARIO_PLANNING_OPTIMISATION,
    },
    [EProducts.IN_CHANNEL_SPO]: {
      icon: { name: 'ArrowPathRoundedSquareIcon' },
      serviceAreas: [EServiceAreas.WHAT_NEXT],
      module: EModules.IN_CHANNEL_SPO,
    },
  }

  const modulesProps: Record<EModules, Pick<ModuleType, 'icon' | 'href'>> = {
    [EModules.REPORTING]: {
      icon: { name: 'MegaphoneIcon' },
      href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
    },
    [EModules.SCENARIO_PLANNING_OPTIMISATION]: {
      icon: { name: 'ClipboardDocumentListIcon' },
      href: ROUTES.SPO.INDEX,
    },
    [EModules.IN_CHANNEL_SPO]: {
      icon: { name: 'ArrowPathRoundedSquareIcon' },
      href: ROUTES.SPO.IN_CHANNEL_SCENARIOS,
    },
  }

  const handleMapServiceArea = (
    serviceArea: typeof serviceAreas[number]
  ): ServiceAreaType => {
    return {
      id: serviceArea.id,
      name: serviceArea.name,
    }
  }

  const handleMapProducts = (
    product: typeof products[number]
  ): ProductType | null => {
    const id = product.id as EProducts
    const productProp = productsProps[id]

    if (!product.show || !productProp) return null

    return {
      id: product.id,
      name: product.name,
      description: product.description,
      icon: productProp.icon,
      serviceAreas: productProp.serviceAreas,
      module: productProp.module,
    }
  }

  const handleMapModules = (
    module: typeof modules[number],
    active: boolean
  ): ModuleType | null => {
    const id = module.id as EModules
    const moduleProp = modulesProps[id]

    if (!moduleProp) return null

    return {
      id: id,
      name: module.name,
      enabled: active,
      icon: moduleProp.icon,
      href: moduleProp.href,
    }
  }

  useEffect(() => {
    let tServiceAreas: ServiceAreaType[] = []

    if (serviceAreas.length && serviceAreas.length > 0) {
      tServiceAreas = serviceAreas.reduce<ServiceAreaType[]>(
        (acc, serviceArea) => {
          const tServiceArea = handleMapServiceArea(serviceArea)

          acc.push(tServiceArea)

          return acc
        },
        []
      )
    }

    setTranslatedServiceAreas(tServiceAreas)
  }, [])

  useEffect(() => {
    let tProducts: ProductType[] = []

    if (products.length && products.length > 0) {
      tProducts = products.reduce<ProductType[]>((acc, product) => {
        const tProduct = handleMapProducts(product)

        if (tProduct !== null) {
          acc.push(tProduct)
        }

        return acc
      }, [])
    }

    setTranslatedProducts(tProducts)
  }, [])

  useEffect(() => {
    let tModules: ModuleType[] = []

    let reportingActive = false
    let spoActive = false
    let inChannelSpoActive = false

    if (gtiModules && gtiModules.length > 0) {
      const gtReporting = gtiModules.find((gtModule) => gtModule.key === 'md')
      const gtSpo = gtiModules.find((gtModule) => gtModule.key === 'spo')

      reportingActive = gtReporting !== undefined && gtReporting.active
      spoActive = gtSpo !== undefined && gtSpo.active
      inChannelSpoActive =
        gtSpo !== undefined &&
        gtSpo.active &&
        gtSpo.submodules !== null &&
        gtSpo.submodules.includes('sensor')
    }

    const reporting = modules.find((module) => module.id === EModules.REPORTING)
    const spo = modules.find(
      (module) => module.id === EModules.SCENARIO_PLANNING_OPTIMISATION
    )
    const inChannelSpo = modules.find(
      (module) => module.id === EModules.IN_CHANNEL_SPO
    )

    if (reporting !== undefined) {
      const tReporting = handleMapModules(reporting, reportingActive)
      tReporting && tModules.push(tReporting)
    }

    if (spo !== undefined) {
      const tSpo = handleMapModules(spo, spoActive)
      tSpo && tModules.push(tSpo)
    }

    if (inChannelSpo !== undefined) {
      const tInChannelSpo = handleMapModules(inChannelSpo, inChannelSpoActive)
      tInChannelSpo && tModules.push(tInChannelSpo)
    }

    setTranslatedModules(tModules)
  }, [gtiModules])

  return {
    translatedServiceAreas,
    translatedProducts,
    translatedModules,
  }
}
