import React, { FC } from 'react'
import {
  ChangedHighlight,
  KPI,
  Phase,
} from '../../../../types/MarketingDiagnosticsTypes'
import HighlightCard from './HighlightCard'

interface OwnProps {
  phase: Phase
  kpi: KPI
  highlight: ChangedHighlight
  color: string
}

type Props = OwnProps

const MostChangedCard: FC<Props> = (props) => {
  return (
    <HighlightCard
      kpi={props.kpi}
      brand={props.highlight.brand}
      channel={props.highlight.channel}
      phase={props.phase}
      title={'MOST PRODUCTIVE CHANNEL'}
      color={props.color}
    >
      <div className="text-gray-400 text-xs p-4 flex flex-1">
        {`${props.highlight.label}  within ${props.highlight.channel} had the largest change in effectiveness for
                                ${props.phase.phase}.`}
      </div>
    </HighlightCard>
  )
}

export default MostChangedCard
