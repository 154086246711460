import { useEffect, useState } from 'react'
import { Modal } from '@gain-theory/modal'
import { Typography } from '@gain-theory/typography'
import { getReleaseVersion } from '../utils/releaseVersion'

export const useReleaseVersion = () => {
  const [show, setShow] = useState<boolean>(false)
  const toggleShow = () => setShow((prev) => !prev)
  const [releaseVersion, setReleaseVersion] = useState<string>('')
  useEffect(() => {
    getReleaseVersion().then((version) => setReleaseVersion(version))
  }, [])
  const Component = (
    <Modal
      header={{ icon: 'info', text: 'Version information' }}
      open={show}
      footer={{
        primary: {
          label: 'OK',
          leadingIconName: 'CheckIcon',
          onClick: toggleShow,
        },
      }}
    >
      <Typography.Body1>{`${document.getElementsByTagName('title')[0].innerHTML} is running on `}<i>{`v${releaseVersion}`}</i></Typography.Body1>
    </Modal>
  )

  return { Component, toggleShow }
}

