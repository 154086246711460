import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../Routes'
import { NavbarMenuItem } from './types'

const launchpadBaseMenu = {
  label: 'Launchpad',
  iconName: 'RocketLaunchIcon',
} as NavbarMenuItem

function useLaunchpadMenu() {
  const history = useHistory()
  const [launchpadMenu, setLaunchpadMenu] = useState({ ...launchpadBaseMenu })

  useEffect(() => {
    const isAtLaunchpad = window.location.pathname === ROUTES.HOME

    const newLaunchpadMenu = {
      ...launchpadBaseMenu,
      ...(isAtLaunchpad
        ? { route: ROUTES.HOME }
        : { handleAction: () => history.push(ROUTES.HOME) }),
    }

    setLaunchpadMenu(newLaunchpadMenu)
  }, [])

  return launchpadMenu
}

export default useLaunchpadMenu
