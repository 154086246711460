import React, { FC, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { ROUTES } from '../../../Routes'
import { ProtectedRouteProps } from '../../../shared/AuthRoute'
import AllocationHeader from '../../../shared/plan-view/AllocationHeader'

export interface OwnProps {
  title: string
  name: string
  isViewVisible: boolean
}

type Props = OwnProps

// export class AllocationComponent = <P extends Props>(
//     Component: React.ComponentType<P>
// )=>{}

export const AllocationComponent: FC<Props> = (props) => {
  const [viewVisibility, setViewVisibility] = useState(props.isViewVisible)
  // hideView = () => {
  //
  // }

  // public render() {
  return (
    <>
      <AllocationHeader
        title={props.title}
        name={props.name}
        isSubViewVisible={viewVisibility}
        onSetViewVisibility={() => setViewVisibility(!viewVisibility)}
      />

      {viewVisibility ? props.children : null}
    </>
  )
  // }
}
