import React, { FC, useEffect } from 'react'
import BudgetSummary from './summary/BudgetSummary'
import {
  GroupingLevel,
  Identifier,
  Plan,
  STransformation,
} from '../../types/PlanTypes'
import { Settings } from '../../types/SettingTypes'
import GoalSummary from './summary/GoalSummary'
import TimeSummary from './summary/TimeSummary'
import RegionSummary from './summary/RegionSummary'
import DimensionSummary from './summary/DimensionSummary'
import BrandSummary from './summary/BrandSummary'
import MediaSummary from './summary/MediaSummary'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import EditPlanModal from './summary/EditPlanModal'
import { ShowEditPlanModal } from '../../redux/actions/EditPlanActions'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import { useHistory } from 'react-router-dom'
import { GetPlanItem } from '../../redux/actions/PlanActions'

interface OwnProps {
  plan: Plan
}

type Props = OwnProps

const PlanSummary: FC<Props> = (props) => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { planCreatedFromEditor, editPlanId } = useSelector(
    (state: AppState) => state.editPlan
  )
  const { showModal } = useSelector((state: AppState) => state.editPlan)

  const planLevels = Object.keys(props.plan.grouping_levels)
  const dimensions = planLevels.filter(x => {
    if(x !== "region" && x !== "brand" && x !== "media"){
      return x
    }
  })

  useEffect(() => {
    if (planCreatedFromEditor && editPlanId) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', editPlanId.toString())
        .toString()

      history.push(url)
      dispatch(GetPlanItem(editPlanId.toString()))
    }
  }, [planCreatedFromEditor])

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    }
    if (!showModal) {
      document.body.style.overflow = 'auto'
    }
  }, [showModal])

  const getFilters = (groupingLevel: string) => {
    const filteredTransforms = props.plan.transformations.filter(
      (trans) => !trans.is_halo
    )

    return Object.keys(
      filteredTransforms
        .reduce((memo: any[], transformations) => {
          // @ts-ignore
          return [...memo, transformations.identifiers[groupingLevel]]
        }, [])
        .reduce(
          (memo, key) => ({
            ...memo,
            // @ts-ignore
            [key]: true,
          }),
          {}
        )
    ).map((key) => {
      // @ts-ignore

      return (
        // @ts-ignore
        props.plan?.config.grouping_levels
          // @ts-ignore
          ?.find((level: GroupingLevel) => level.key === groupingLevel)
          // @ts-ignore
          .values.find((val: GroupingLevel) => val.key === key)
      )
    })
  }

  const getFiltersText = (groupingLevel: string): string[] => {
    const filters = getFilters(groupingLevel).map((x) => x?.label || '')
    return filters
  }

  const onEdit = (section: number) => {
    dispatch(ShowEditPlanModal(section))
  }
  return (
    <>
      {showModal ? <EditPlanModal/> : null}
      <div className="bg-white border border-gray-300 rounded p-4 w-full flex flex-row justify-between">
        <BudgetSummary />
        <div className="mx-2 border-l" />
        <GoalSummary onEdit={onEdit} />
        <div className="mx-2 border-l" />
        <TimeSummary onEdit={onEdit} />
        {planLevels.indexOf('region') !== -1 && <><div className="mx-2 border-l" />
        <RegionSummary filtersText={getFiltersText('region')} onEdit={onEdit} /></>}
        {planLevels.indexOf('brand') !== -1 && <><div className="mx-2 border-l" />
        <BrandSummary filtersText={getFiltersText('brand')} onEdit={onEdit} /></>}
        {planLevels.indexOf('media') !== -1 && <><div className="mx-2 border-l" />
        <MediaSummary filtersText={getFiltersText('media')} onEdit={onEdit} /></>}
        <div className="mx-2 border-l" />
        <DimensionSummary filtersText={dimensions} onEdit={onEdit} />
      </div>
    </>
  )
}

export default PlanSummary
