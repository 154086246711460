import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import {
  GroupingLevel,
  GroupingLevelValue,
  IO,
  Transformation,
} from '../../../types/PlanTypes'
import Map from './region-allocation/Map'
import appendUnit from '../../../utils/appendUnit'
import {
  getSummedKPICalculatedTotals,
  getSummedKPIOptimisedTotals,
} from '../../../utils/kpiDisplay'

import _ from 'lodash'
import { SelectOutputFilter } from '../../../redux/actions/PlanActions'

interface OwnProps {}

type Props = OwnProps

const RegionAllocation: FC<Props> = (props) => {
  const {
    planItem,
    scenarioOutputSelected,
    outputSelectOptions,
  } = useSelector((state: AppState) => state.plans)
  const dispatch = useDispatch()

  const levelName = "region"

  const { levelTransforms, appliedFilters, allTransforms } = useSelector((state: AppState) => state.planFilters)

  const { settings } = useSelector((state: AppState) => state.settings)
  const levelKeys = Object.keys(appliedFilters)
  const levelIndex = levelKeys.indexOf(levelName)
  let filteredTransforms = allTransforms
  if(levelIndex !== 0){
    for(let i = 0; i < levelIndex; i++){
      filteredTransforms = filteredTransforms.filter(x => {
        if(appliedFilters[levelKeys[i]].length === 0){
          return x
        } else {
          if(appliedFilters[levelKeys[i]].indexOf(x.identifiers[levelKeys[i]]) !== -1){
            return x
          }
        }
      })
    }
  }

  const getLevelKeys = () => {


    const keys = _.uniqBy(filteredTransforms.map(x => x.identifiers[levelName]), function(e){return e})
    return keys
  }

  const currentSelectedISO = appliedFilters[levelName] && appliedFilters[levelName].length === 0 ? "" : allTransforms.find(x => x.identifiers[levelName] === appliedFilters[levelName][0])?.identifiers.region_key

  const getLabelForRegion = (key: string) => {
    // const region_grouping_level = this.props.scenario.config.g;
    const regionGroupingLevels = planItem.config.grouping_levels.filter(
      (grouping_level: GroupingLevel) => grouping_level.key === levelName
    )

    return regionGroupingLevels[0].values
      .filter((value: GroupingLevelValue) => value.key === key)
      .map((item: GroupingLevelValue) => item.label)
      .reduce((prev: GroupingLevelValue[], next: GroupingLevelValue) => {
        return [...prev, next]
      }, [])
  }

  const getOutputTotal = () => {
    return outputSelectOptions.find(
      (output: IO) =>
        output.label.toLowerCase().replace(/ /g, '_') ===
        scenarioOutputSelected.toLowerCase()
    )
  }

  const getOptimisedForRegion = (level: string) => {
    const outputSelected = scenarioOutputSelected.toLowerCase()
    const unit = getOutputTotal() ? getOutputTotal()!.unit : 'currency'
    const regionTransforms = filteredTransforms.filter(
      (trans: any) => trans.identifiers[levelName] === level
    )
    const roiNumerator = outputSelectOptions.filter(
      (x: IO) => x.type === 'output'
    )[0]
      ? outputSelectOptions
          .filter((x: IO) => x.type === 'output')[0]
          .label.toLowerCase()
          .replace(/ /g, '_')
      : ''
    const returnTrans = getSummedKPIOptimisedTotals(
      regionTransforms,
      outputSelected,
      roiNumerator,
      planItem.config.options.kpiDisplay
    )
    return appendUnit(
      returnTrans,
      unit,
      planItem.options.exchangeRates.defaultSymbol || '',
      settings?.number_format || ''
    )
  }

  const getCalculatedForRegion = (level: string) => {
    const outputSelected = scenarioOutputSelected.toLowerCase()
    const unit = getOutputTotal() ? getOutputTotal()!.unit : 'currency'
    const regionTransforms = filteredTransforms.filter(
      (trans: any) => trans.identifiers[levelName] === level
    )
    const roiNumerator = outputSelectOptions.filter(
      (x: IO) => x.type === 'output'
    )[0]
      ? outputSelectOptions
          .filter((x: IO) => x.type === 'output')[0]
          .label.toLowerCase()
          .replace(/ /g, '_')
      : ''
    const returnTrans = getSummedKPICalculatedTotals(
      regionTransforms,
      outputSelected,
      roiNumerator,
      planItem.config.options.kpiDisplay
    )
    return appendUnit(
      returnTrans,
      unit,
      planItem.options.exchangeRates.defaultSymbol || '',
      settings?.number_format || ''
    )
  }

  const getScenarioRegions = (): string[] => {
    let regionKeys = levelTransforms.map((transform: Transformation) =>
      transform.identifiers[levelName].toLowerCase()
    )

    regionKeys = [...new Set(regionKeys)]

    for (let region of regionKeys) {
      getLabelForRegion(region)
    }

    let keys: string[] = planItem.transformations.map(
      (transform: Transformation) => transform.identifiers.region_key
    )
    return [...new Set(keys)]
  }

  const handleRegionSelect = (isoKey: string) => {
    if(isoKey === "all"){
      dispatch(SelectOutputFilter(levelName, isoKey, planItem.grouping_levels))
    }
    const keyTransform = planItem.transformations.find((x: Transformation) => x.identifiers.region_key === isoKey)
    if(!keyTransform){
      return
    } else {
      const key = keyTransform.identifiers[levelName]
      dispatch(SelectOutputFilter(levelName, key, planItem.grouping_levels))
    }
  }

  return (
    <>
        <div className="w-full flex flex-row">
          <div className="flex w-8/12">
            <Map
              handleRegionSelect={handleRegionSelect}
              filterableRegions={getScenarioRegions()}
              selected={currentSelectedISO}
            />
          </div>
          <div className="flex w-4/12 flex-col space-y-1">
            {getLevelKeys().map((level: string, index: number) => (
              <div
                key={index}
                className="flex flex-row bg-white border rounded p-4 text-gray-500 space-x-2"
              >
                <div className="w-8/12 text-xs">{getLabelForRegion(level)}</div>
                <div className="w-2/12 text-success-main text-xs">
                  {getOptimisedForRegion(level)}
                </div>
                <div className="w-2/12 text-xs">{getCalculatedForRegion(level)}</div>
              </div>
            ))}
          </div>
        </div>
    </>
  )
}

export default RegionAllocation
