import * as _ from "lodash";

export function scaleObservationBudgets(oldValues, newTotal) {
    if (newTotal === -10000) {
        return oldValues;
    }
    const oldTotal = oldValues.reduce((memo, value) => memo + value, 0);
    
    if (oldTotal === 0) {
        return oldValues.map(() => Math.round(newTotal / oldValues.length));
    }

    const scale = newTotal / oldTotal;

    return oldValues.map(value => Math.round(value * scale));
}


export const createSumTransforms = (
    transforms,
    needRanges,
    fourthLevelName
) => {
    let budgets = {};
    for (let transform of transforms) {
        const KPIKeys = Object.keys(
            transform.sTransformation.calculated_totals
        );
        let media = transform.identifiers.media;

        if (budgets[media]) {
            let budget = budgets[media];
            budgets[media].calculated_spend +=
                transform.sTransformation.calculated_spend;
            budgets[media].optimised_spend +=
                transform.sTransformation.optimised_spend;

            for (let key of KPIKeys) {
                budgets[media].optimised_totals[key] +=
                    transform.sTransformation.optimised_totals[key];
                budgets[media].calculated_totals[key] +=
                    transform.sTransformation.calculated_totals[key];
            }
            budget.transforms.push(transform);
            budget.regions.push(transform.identifiers.region);
            budget.brands.push(transform.identifiers.brand);
            budget.campaigns.push(transform.identifiers.campaign);

            if (needRanges) {
                budgets[media].rangePositions = [
                    ...budgets[media].rangePositions,
                    ...transform.mainInput.rangePositions
                ];
                const ranges = transform.mainInput.ranges;

                for (let index in ranges) {
                    if (ranges[index].length > 0 && ranges[index][0] > 0) {
                        if (!budgets[media].ranges[index]) {
                            budgets[media].ranges.push(ranges[index]);
                        } else {
                            for (let i = 0; i < ranges[index].length; i++) {
                                if (budgets[media].ranges[index][i]) {
                                    budgets[media].ranges[index][i] +=
                                        ranges[index][i];
                                } else {
                                    budgets[media].ranges[index][i] =
                                        ranges[index][i];
                                }
                            }
                        }
                    }
                }

                budget.values = budget.values.map((num, index) => {
                    return num + transform.mainInput.values[index];
                });
            }
        } else {
            let optimised_totals = {};
            let calculated_totals = {};
            for (let key of KPIKeys) {
                optimised_totals[key] =
                    transform.sTransformation.optimised_totals[key];
                calculated_totals[key] =
                    transform.sTransformation.calculated_totals[key];
            }

            budgets[media] = {
                id: transform.id,
                media: transform.identifiers.media,
                brand: transform.identifiers.brand,
                region: transform.identifiers.region,
                regions: [transform.identifiers.region],
                brands: [transform.identifiers.brand],
                campaigns: [transform.identifiers.campaign],
                calculated_spend: transform.sTransformation.calculated_spend,
                optimised_spend: transform.sTransformation.optimised_spend,
                ranges: transform.mainInput.ranges,
                unit: transform.mainInput.unit,
                optimised_totals: optimised_totals,
                calculated_totals: calculated_totals,
                rangePositions: transform.mainInput.rangePositions,
                values: transform.mainInput.values,
                transforms: [transform]
            };
            budgets[media][fourthLevelName] = transform.identifiers[
                fourthLevelName
                ]
                ? transform.identifiers[fourthLevelName]
                : [];
        }
    }

    Object.keys(budgets).forEach((key, index) => {
        let budget = budgets[key];
        budget.ranges = calculateRanges(budget.values);
        budget.rangePositions = rangePositionCalculate(budget.ranges);
    });

    calculateTimeAllocationRIOKPI(budgets);

    let matchedBudget = {};
    for (let transform of transforms) {
        let media = transform.identifiers.media;
        matchedBudget[media] = {
            id: budgets[media].id,
            sTransformation: {
                calculated_totals: budgets[media].calculated_totals,
                calculated_spend: budgets[media].calculated_spend,
                optimised_totals: budgets[media].optimised_totals,
                optimised_spend: budgets[media].optimised_spend
            },
            regions: [...new Set(budgets[media].regions)],
            brands: [...new Set(budgets[media].brands)],
            campaigns: [...new Set(budgets[media].campaigns)],
            identifiers: {
                region: budgets[media].region,
                media: budgets[media].media,
                brand: budgets[media].brand
            },
            mainInput: {
                ranges: budgets[media].ranges,
                unit: budgets[media].unit,
                rangePositions: budgets[media].rangePositions
            },
            transformations: budgets[media].transforms
        };
        matchedBudget[media].identifiers[fourthLevelName] =
            budgets[media][fourthLevelName];
    }
    return Object.values(matchedBudget);
};


const calculateTimeAllocationRIOKPI = budgets => {
    for (let key in budgets) {
        budgets[key].optimised_totals.roi =
            budgets[key].optimised_spend === 0
                ? 0
                : budgets[key].optimised_totals.profit /
                budgets[key].optimised_spend;
        budgets[key].calculated_totals.roi =
            budgets[key].calculated_spend === 0
                ? 0
                : budgets[key].calculated_totals.profit /
                budgets[key].calculated_spend;
    }
};

const rangePositionCalculate = values => {
    return values.reduce((memo, value, key) => {
        const isNonZero = key % 2;

        if (isNonZero) {
            memo[memo.length - 1].push(value.length);
        } else if (key !== values.length - 1) {
            // is not last empty range
            const previousPosition = key === 0 ? 0 : memo[memo.length - 1][0];
            const previousLength = key === 0 ? 0 : memo[memo.length - 1][1];
            const absolutePosition =
                value.length + previousPosition + previousLength;
            memo.push([absolutePosition]);
        }

        return memo;
    }, []);
};


const calculateRanges = ranges => {
    const obsAllocation = ranges.reduce(
        (memo, value) => {
            const currentRangeIsNonZero = !(memo.length % 2); // odd is zero
            const valueIsNonZero = (value ? value : 0) > 0;

            if (currentRangeIsNonZero !== valueIsNonZero) {
                memo.push([]);
            }

            memo[memo.length - 1].push(value);

            return memo;
        },
        [
            [] // first item is always zero (so that odd items are always zero)
        ]
    );

    return obsAllocation;
};

