import { FC, memo, useEffect } from 'react'
import { ConnectedProps, connect, useDispatch } from 'react-redux'
import { GetLaunchpadData } from '../../redux/actions/MarketingDiagnosticsActions'
import { CreateSession } from '../../redux/actions/SessionActions'
import { selectSessionErrorMessage } from '../../redux/reducers/SessionReducer'
import { RootStore } from '../../redux/reducers/Store'
import { Background } from './background'
import { LoginInfo } from './login-info'
import { ShapeMain } from './shape-main'
import { LoginCard } from './login-card/login-card'

const mapState = (state: RootStore) => ({
  errors: selectSessionErrorMessage,
})

const mapDispatch = {
  onLogin: CreateSession,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const Login: FC<PropsFromRedux> = memo((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetLaunchpadData())
  }, [])

  return (
    <div className="relative w-full h-screen overflow-hidden grid bg-brand-primary-dark">
      <Background />

      <div className="z-10 justify-self-center w-full h-full max-w-[1296px] overflow-hidden px-xl lg:px-xxl">
        <div className="grid grid-cols-12 gap-md xl:gap-xl relative w-full h-full">
          <ShapeMain />

          <section className="z-20 flex items-start col-start-1 col-span-7">
            <LoginInfo />
          </section>

          <section className="z-20 flex items-center h-full overflow-hidden col-start-8 col-span-5">
            <LoginCard />
          </section>
        </div>
      </div>
    </div>
  )
})

export default connector(Login)
