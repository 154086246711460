import { FC, useState } from 'react'
import { ComparisonFilters, ComparisonLevelOption } from '../../../types/PlanComparisonTypes'

interface OwnProps {
  resetFilters: () => void
  filters: ComparisonFilters
  headers: string[]
  allLevelOptions: ComparisonLevelOption[]
  showFilterLevel: (level?: string) => void
  }
  
  type Props = OwnProps
  
  const FilterComponent: FC<Props> = (props) => {

    const findLabel = (key: string, level: string) => {
      const match = props.allLevelOptions.find(x => x.level === level && x.key === key)
      if(match){ 
        return match.label
      }
      return ""
    }

    return (
      <div className="bg-gtFilterBlue h-20 w-full mb-4 rounded">
          <div className="col w-full px-4 py-2">
              <div className="float-left text-white uppercase font-primary text-xs tracking-wider pl-2">Filters</div>
              <button onClick={props.resetFilters} className="float-right text-white uppercase font-primary text-xs tracking-wider">Reset Filters</button>
          </div>
          <div className="flex flex-row w-full px-4 py-2">
            {Object.keys(props.filters).map((key, index) => {
              return (<div className="text-white w-1/4 inline-flex" key={index}>
              <div  onClick={() => props.showFilterLevel(key)} className="flex hover:bg-gtFilterBlueHover rounded cursor-pointer p-2 mb-2">
                {<span className="border-dotted border-white border-b-2">{`${props.headers[index]}:`}</span>}
                {props.filters[key].length === 0 && <span className="pl-2 border-dotted border-white border-b-2">{"None"}</span>}
                {props.filters[key].length === 1 && <span className="pl-2 border-dotted border-white border-b-2">{findLabel(props.filters[key][0], key)}</span>}
                {props.filters[key].length > 1 && <span className="pl-2 border-dotted border-white border-b-2">{props.filters[key].length + " Selected"}</span>}
              </div>
            </div>)
            })}
          </div>
      </div>
    )
}

export default FilterComponent
