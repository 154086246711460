import { AzureProvider } from './AzureProvider'
import { OktaProvider } from './OktaProvider'

type AuthenticationProviderProviders = {
  children: React.ReactNode
}

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProviders) {
  return (
    <AzureProvider>
      <OktaProvider>{children}</OktaProvider>
    </AzureProvider>
  )
}
