import React, { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../shared/MainLayout'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import { GetInsights } from '../../redux/actions/MarketingDiagnosticsActions'
import MDHighlights from '../../shared/MDHighlights'
import SelectBrandsModal from './highlights/SelectBrandsModal'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { ROUTES } from '../../Routes'
import { Breadcrumbs } from '@gain-theory/breadcrumbs'

interface OwnProps {}

type Props = OwnProps

const Highlights: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { marketingDiagnostics, highlights, loading } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )
  const [mdEnabled, setMdEnabled] = useState(false)
  const [selectedBrands, setSelectedBrands] = useState<string[]>([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(GetInsights(selectedBrands))
  }, [selectedBrands])

  useEffect(() => {
    if (marketingDiagnostics) {
      setSelectedBrands([marketingDiagnostics?.brands[0].brand])
    }
  }, [marketingDiagnostics])

  if (loading) {
    return <LoadingModal />
  }

  return (
    <>
      {marketingDiagnostics && showModal ? (
        <SelectBrandsModal
          brands={marketingDiagnostics?.brands}
          setShowModal={setShowModal}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
        />
      ) : null}
      <div className="mb-5">
        <Breadcrumbs
          breadcrumbsConfig={[
            {
              href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
              label: 'Reporting',
              divider: true,
            },
            { label: 'Highlights' },
          ]}
        />
      </div>

      <div className="text-gray-500 text-lg">Highlights</div>
      {!marketingDiagnostics && (
        <div>
          We're sorry, you do not have access to this module. Please contact
          your Gain Theory rep for further assistance.
        </div>
      )}
      {highlights ? (
        <div className="w-full mt-6 flex flex-row space-x-4">
          <button
            onClick={() => setShowModal(true)}
            className="p-2 text-xxs w-1/4 border rounded bg-white"
          >
            {selectedBrands.length === 1 ? (
              <span> {selectedBrands[0]}</span>
            ) : (
              <span>{`Brands ${selectedBrands.length} of ${marketingDiagnostics?.brands.length} selected`}</span>
            )}
          </button>
          <div className="w-1/4">{''}</div>
          <div className="w-1/4">{''}</div>
          <div className="w-1/4">{''}</div>
        </div>
      ) : null}

      <div className="w-full mt-6">
        {highlights ? (
          <MDHighlights highlights={highlights} showTitle={false} />
        ) : null}
      </div>
    </>
  )
}

export default MainLayout(Highlights)
