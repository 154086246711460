import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const VolumeIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="#666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M1.333 63h61.334M12 63V49.667H4V63M28 63V36.333h-8V63M44 63V41.667h-8V63M60 63V23h-8v40M57.333 4.333L40 23l-16-5.333L8 31" />
          <path d="M46.667 4.333h10.666V15" />
        </g>
      </g>
    </svg>
  )
}

export default VolumeIcon
