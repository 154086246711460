import React, { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../shared/MainLayout'
import { AppState } from '../../redux/reducers/RootReducer'
import { GetMarketingDiagnostics } from '../../redux/actions/MarketingDiagnosticsActions'
import { useDispatch, useSelector } from 'react-redux'
import MDHighlights from '../../shared/MDHighlights'
import MDInsights from '../../shared/MDInsights'
import LetsGetStartedCard from './launchpad/LetsGetStartedCard'
import RecentPlansList from './launchpad/RecentPlansList'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { ROUTES } from '../../Routes'
import MDBrandsGrid from '../../shared/MDBrandsGrid'
import { Typography } from '@gain-theory/typography'

interface OwnProps {}

type Props = OwnProps

const MarketingDiagnostics: FC<Props> = (props) => {
  const dispatch = useDispatch()

  const { modules } = useSelector((state: AppState) => state.session)
  const [mdEnabled, setMdEnabled] = useState(false)

  const [sensorEnabled, setSensorEnabled] = useState(false)
  const { marketingDiagnostics, highlights, loading, systemLinks } =
    useSelector((state: AppState) => state.marketingDiagnostics)
  useEffect(() => {
    dispatch(GetMarketingDiagnostics())
  }, [])

  const checkSensorEnabled = () => {
    const mdModule = modules.find((module) => module.key === 'md')

    if (mdModule && mdModule.submodules) {
      const isEnabled =
        mdModule.submodules.find(
          (submodule) => submodule.toLowerCase() === 'sensor'
        ) !== undefined

      setSensorEnabled(isEnabled)
    }
  }

  useEffect(() => {
    checkSensorEnabled()
  }, [modules])

  useEffect(() => {
    if (modules.length > 0) {
      const module = modules.find((module) => module.key === 'md')

      if (module && module.active) {
        setMdEnabled(true)
      }
    }
  }, [modules])

  if (loading) {
    return <LoadingModal />
  }

  const MDcontent = () => {
    if (!mdEnabled) {
      return (
        <div>
          We're sorry, you do not have access to this module. Please contact
          your Gain Theory rep for further assistance.
        </div>
      )
    } else {
      if (marketingDiagnostics && highlights) {
        const showHighlights =
          sensorEnabled && (!highlights.error || highlights.error === '')
        return (
          <div className="flex flex-col gap-xl">
            <MDBrandsGrid
              brands={marketingDiagnostics?.brands}
              views={marketingDiagnostics?.views}
              imr={marketingDiagnostics?.imr}
              sensorEnabled={sensorEnabled}
            />
            {showHighlights && <MDHighlights highlights={highlights} />}
          </div>
        )
      }
    }

    return <></>
  }

  return (
    <div className="flex flex-col gap-xl">
      <Typography.H4>Reporting</Typography.H4>
      {MDcontent()}
    </div>
  )
}

export default MainLayout(MarketingDiagnostics)
