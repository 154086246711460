import { useEffect, useState } from 'react'
import { ModulesProps } from '../../modules/types'
import { WelcomeProps } from '../../welcome'
import { SectionId, scrollToId } from '../utils'
import { useSolutions } from './use-solutions'

const LAUNCHPAD_GENERIC_PRODUCT_DESCRIPTION =
  'Click on a card above to learn more about the various ways that GTi could help you achieve your goals. Each card describes a particular use case and describes how one of GTi’s modules will suit the need.'

export function useProductsAndModules() {
  const { translatedServiceAreas, translatedProducts, translatedModules } =
    useSolutions()

  const [serviceAreaChips, setServiceAreaChips] = useState<
    WelcomeProps['chips']
  >([])
  const [productCards, setProductCards] = useState<WelcomeProps['cards']>([])
  const [moduleCards, setModuleCards] = useState<ModulesProps['modules']>([])

  const [selectedServiceArea, setSelectedServiceArea] = useState<string>('all')
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
  const [highlightedModule, setHighlightedModule] = useState<string | null>(
    null
  )
  const [productDescription, setProductDescription] = useState<string>(
    LAUNCHPAD_GENERIC_PRODUCT_DESCRIPTION
  )

  const onServiceAreaClick = (id: string) => {
    setSelectedServiceArea(id)
  }

  const onProductCardClick = (id: string) => {
    const targetProduct = translatedProducts.find(
      (product) => product.id === id
    )
    const isSameProduct = selectedProduct === id

    let description = LAUNCHPAD_GENERIC_PRODUCT_DESCRIPTION
    let product: string | null = null
    let gtiModule: string | null = null

    if (!isSameProduct && targetProduct) {
      description = targetProduct.description
      product = targetProduct.id
      gtiModule = targetProduct.module
      scrollToId(SectionId.MODULES)
    }

    setSelectedProduct(product)
    setProductDescription(description)
    setHighlightedModule(gtiModule)
  }

  const getSelectedModuleHref = () => {
    const selectedModule = translatedModules.find(
      (module) => module.id === highlightedModule
    )

    if (!selectedModule || !selectedModule.enabled) return null

    return selectedModule.href
  }

  useEffect(() => {
    const chips = translatedServiceAreas.map((serviceArea) => ({
      id: serviceArea.id,
      label: serviceArea.name,
    }))

    setServiceAreaChips(chips)
  }, [translatedServiceAreas])

  useEffect(() => {
    const cards = translatedProducts.map((product) => ({
      id: product.id,
      label: product.name,
      icon: product.icon,
      chips: product.serviceAreas,
    }))

    setProductCards(cards)
  }, [translatedProducts])

  useEffect(() => {
    const cards = translatedModules.map((gtiModule) => ({
      id: gtiModule.id,
      name: gtiModule.name,
      enabled: gtiModule.enabled,
      href: gtiModule.href,
      icon: gtiModule.icon,
    }))

    setModuleCards(cards)
  }, [translatedModules])

  return {
    serviceAreaChips,
    selectedServiceArea,
    productCards,
    selectedProduct,
    moduleCards,
    highlightedModule,
    productDescription,
    onServiceAreaClick,
    onProductCardClick,
    getSelectedModuleHref,
  }
}
