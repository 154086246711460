import { Button } from '@gain-theory/button'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { Children, memo } from 'react'
import { ModulesShape } from '../assets'
import { useFeatureContentHeight } from '../hooks'
import { ModuleCard } from './module-card'
import { ModulesProps } from './types'

const LAUNCHPAD_MODULES_TITLE = 'Which GTi modules are right for you?'
const LAUNCHPAD_MODULE_LAUNCH_BUTTON_LABEL = 'Launch'
const LAUNCHPAD_MODULE_LEARN_MORE_BUTTON_LABEL = 'Learn more'

export const Modules = memo(
  ({
    id,
    modules,
    selectedModule,
    description,
    onModuleActionClick,
  }: ModulesProps) => {
    const selectedModuleIsEnabled =
      modules.find((m) => m.id === selectedModule)?.enabled ?? false

    const { bgRef, rootRef, height } = useFeatureContentHeight()

    const Background = (
      <div
        ref={bgRef}
        className="-mx-xxl absolute m-auto order-0 flex-grow-0 z-0 self-center justify-self-center top-0 min-w-[1020px] w-[1020px] max-w-5xl xl:w-11/12"
      >
        <ModulesShape className="stroke-brand-primary-main" />
      </div>
    )

    const Title = (
      <div className="col-span-full flex items-center justify-center z-10 text-center">
        <Typography.H4>{LAUNCHPAD_MODULES_TITLE} </Typography.H4>
      </div>
    )

    const ActionButton = (
      <div className="w-fit">
        <Button
          data-testid={id ? `${id}-action-button` : `action-button`}
          onClick={onModuleActionClick}
          leadingIcon={
            <Icon
              name={
                selectedModuleIsEnabled ? 'PlayCircleIcon' : 'PlusCircleIcon'
              }
              color="neutral-white"
            />
          }
        >
          {selectedModuleIsEnabled
            ? LAUNCHPAD_MODULE_LAUNCH_BUTTON_LABEL
            : LAUNCHPAD_MODULE_LEARN_MORE_BUTTON_LABEL}
        </Button>
      </div>
    )

    const ProductDescription = (
      <div className="flex flex-start flex-col gap-xl pl-md py-md w-4/12 max-w-2xl">
        <Typography.Body1>{description}</Typography.Body1>
        {selectedModule !== null && ActionButton}
      </div>
    )

    const Modules = (
      <div className="col-span-full xl:col-span-10 xl:col-start-2 2xl:col-span-8 2xl:col-start-3 flex flex-row z-10 justify-center">
        <div className="pr-md py-md flex flex-col border-r border-neutral-gray-200 gap-xl">
          {Children.toArray(
            modules.map((gtiModule) => (
              <ModuleCard
                module={gtiModule}
                selected={gtiModule.id === selectedModule}
              />
            ))
          )}
        </div>
        {ProductDescription}
      </div>
    )

    return (
      <div
        id={id}
        ref={rootRef}
        style={{ height }}
        className="mx-xxl relative col-span-full grid grid-cols-6 xl:grid-cols-12 gap-x-md gap-y-xl pt-32 auto-rows-min"
      >
        {Background}
        {Title}
        {Modules}
      </div>
    )
  }
)
