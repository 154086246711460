import { Typography } from '@gain-theory/typography'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import {
  DeleteUser,
  GetGroups,
  GetUsers,
} from '../../redux/actions/SessionActions'
import {
  selectSessionAllGroups,
  selectSessionAllUsers,
} from '../../redux/reducers/SessionReducer'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
  groups: selectSessionAllGroups(state),
})
const mapDispatchToProps = {
  onGetGroups: GetGroups,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {}

const ManageGroups: FC<OwnProps> = (props) => {
  useEffect(() => {
    props.onGetGroups()
  }, [])

  const getGroupURL = (id: string) => {
    return UrlAssembler()
      .template(ROUTES.ADMIN.MANAGE_ONE_GROUP)
      .param('id', id)
      .toString()
  }

  return (
    <div className={'container'}>
      <AdminHeader></AdminHeader>
      <Typography.H4 className="my-6">Manage Groups</Typography.H4>
      {props.groups && (
        <table className="min-w-full table-auto mb-96 divide-y divide-gray-300 border rounded text-text-medium-emphasis">
          <thead>
            <tr className="p-2">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Group
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Number of Regions
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Number of Users
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Edit
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {props.groups.map((group) => (
              <tr key={group.id}>
                <td>{group.id}</td>
                <td>{group.name}</td>
                <td>{group.regions.length}</td>
                <td>{group.user_groups.length}</td>
                <td>
                  <Link
                    className={'text-gtPink'}
                    to={getGroupURL(group.id.toString())}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default connector(MainLayout(ManageGroups))
