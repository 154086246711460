import UrlAssembler from 'url-assembler';
import { ROUTES } from '../../../../../Routes';

export const brandUrlAssembler = (url: string, brand: string): string => UrlAssembler().template(url).param("brand", brand).toString()

const advanced = {
  label: "Advanced View",
  target: "_blank"
};

export const getNavs = (param: string, isAdvanced = false) => isAdvanced ? [{
  href: brandUrlAssembler(ROUTES.MARKETING_DIAGNOSTICS.UNIFIED_ADVANCED_VIEW, param),
  ...advanced
}] : [{
  href: ROUTES.MARKETING_DIAGNOSTICS.INDEX,
  label: 'Reporting',
  divider: true,
}, {
  href: brandUrlAssembler(ROUTES.MARKETING_DIAGNOSTICS.PERFORMANCE_OVERVIEW, param),
  label: 'Performance Overview',
  divider: true,
}, {
  label: 'Channel Performance'
}]