import React, { FC, useState } from 'react'
import { RootStore } from '../../../redux/reducers/Store'
import { selectPlanItem } from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import appendUnit from '../../../utils/appendUnit'
import EditableAttribute from '../../../utils/EditableAttribute'
import { KpiRange } from '../../../types/PlanTypes'
import { EditSPOBudget } from '../../../socket.io'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { GetPlanItem } from '../../../redux/actions/PlanActions'
import { AppState } from '../../../redux/reducers/RootReducer'

const mapState = (state: RootStore) => ({
  plan: selectPlanItem(state),
  settings: selectSettings(state),
  user: selectSessionUser(state),
})

const mapDispatchToProps = {
  editSPOBudget: EditSPOBudget,
  onGetPlan: GetPlanItem,
}
const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const BudgetSummary: FC<PropsFromRedux> = (props) => {
  // const { originalConstraint } = useSelector((state: AppState) => state.addOrCut)
  const [error, setError] = useState('')
  const [editableAttributeRef, setEditableAttributeRef] =
    useState<EditableAttribute>()

  const editBudget = (amount: string) => {
    const parseAmount = parseFloat(amount)
    if (!isNaN(parseAmount)) {
      const spendRanges = props.plan.options.kpiRanges.find(
        (range: KpiRange) => range.kpi === 'spend'
      )
      if (amount < spendRanges.min || amount > spendRanges.max) {
        setError(
          `Please enter a number between ${appendUnit(
            spendRanges.min,
            'currency',
            props.plan.options.exchangeRates.defaultSymbol || '',
            props.settings?.number_format || ''
          )} and ${appendUnit(
            spendRanges.max,
            'currency',
            props.plan.options.exchangeRates.defaultSymbol || '',
            props.settings?.number_format || ''
          )}`
        )
      } else {
        if (props.plan && props.user) {
          setError('')
          const plan = {
            ...props.plan,
            user_input: {
              ...props.plan.user_input,
              overallConstraint: parseAmount,
            },
          }
          const currencyIndex =
            props.plan.options.exchangeRates.currencies.indexOf(
              props.plan.options.exchangeRates.defaultCurrency
            )
          if (currencyIndex !== 0) {
            plan.user_input.defaultCurrency = {
              rate: props.plan.options.exchangeRates.rates[currencyIndex],
              currency: props.plan.options.exchangeRates.defaultCurrency,
              symbol: props.plan.options.exchangeRates.defaultSymbol,
            }
          }
          props.editSPOBudget(plan, props.user)
        }
      }
    } else {
      setError('Please enter a valid number')
    }
  }

  return (
    <div className="text-neutral-gray-1100 text-xxs flex flex-col space-y-2  w-28">
      {props.plan?.user_input.optimisationType !== 'goal' ? (
        <div>
          <div className="flex flex-col">
            <div className="flex justify-between w-full">
              <span className="font-bold uppercase">BUDGET</span>
              {!props.plan.locked_at && (
                <button
                  className="text-gtPink text-xxs"
                  onClick={editableAttributeRef?.handleClick}
                >
                  edit{' '}
                </button>
              )}
            </div>
            <EditableAttribute
              ref={(ref) => {
                ref && setEditableAttributeRef(ref)
              }}
              onSave={editBudget}
              defaultValue={props.plan?.user_input.overallConstraint || 0}
              className="w-20"
            >
              <span className="font-bold text-black text-md">
                {appendUnit(
                  props.plan?.user_input.overallConstraint,
                  'currency',
                  props.plan.options.exchangeRates.defaultSymbol || '',
                  props.settings?.number_format || ''
                )}{' '}
              </span>
            </EditableAttribute>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span>BUDGET</span>
          <span className="font-bold text-black text-md">
            {appendUnit(
              props.plan?.optimised_spend,
              'currency',
              props.plan.options.exchangeRates.defaultSymbol || '',
              props.settings?.number_format || ''
            )}{' '}
          </span>
        </div>
      )}
      {error !== '' && <div>{error}</div>}
    </div>
  )
}

export default connector(BudgetSummary)
