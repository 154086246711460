import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import appendUnit from '../../../utils/appendUnit'
import { GroupingLevel, GroupingLevelValue, IO } from '../../../types/PlanTypes'
import { uuid } from 'uuidv4'
import MediaChannelIcon from '../../../icons/media/MediaChannelIcon'
import { getKPITotalsByArray } from '../../../utils/kpiDisplay'
import { CHART_BAR_COLOR } from '../../../utils/colours'
import ObservationAllocationMultiRangeSlider from './observation-allocation/ObservationAllocationMultiRangeSlider'
import { getBrandAllocations } from '../../../utils/PlanUtils'
import { createSumTransforms } from '../../../utils/PlanFilterUtils'

interface OwnProps {}

type Props = OwnProps

const ObservationAllocation: FC<Props> = (props) => {
  let { planItem, scenarioOutputSelected, configOutputs } = useSelector(
    (state: AppState) => state.plans
  )
  const { isSelected } = useSelector((state: AppState) => state.allBrands)
  const { settings } = useSelector((state: AppState) => state.settings)

  const { scenarioFilters, appliedFilters } = useSelector(
    (state: AppState) => state.planFilters
  )

  const groupingLevels = planItem.config.grouping_levels.map(
    (x: GroupingLevel) => x.key
  )

  const bottomLevel = groupingLevels[groupingLevels.length - 1]

  if (!scenarioOutputSelected || scenarioOutputSelected === '') {
    scenarioOutputSelected = 'spend'
  }

  const observations = planItem.config.observations.slice(
    planItem.observations_min,
    planItem.observations_max + 1 - planItem.options.carryoverWeeks
  )

  const canDrag = () => {
    if (scenarioFilters) {
      const canDrag = Object.keys(scenarioFilters).reduce((prev, key) => {
        if (key === 'media') {
          return prev && true
        }
        // @ts-ignore
        return prev && scenarioFilters[key].length === 1
      }, true)

      return canDrag
    }
    return false
  }

  const transformsWithInputs = getBrandAllocations(planItem, appliedFilters)
  const mainInputTransforms = createSumTransforms(
    transformsWithInputs,
    true,
    bottomLevel
  ).map((x) => {
    x.name = planItem.config.grouping_levels
      .find((x: GroupingLevel) => x.key === bottomLevel)
      .values.find(
        (val: GroupingLevelValue) => val.key === x.identifiers[bottomLevel]
      ).label
    return x
  })

  const optimalInputPositions =
    mainInputTransforms.map((trans: any) => trans.mainInput?.rangePositions) ||
    []

  const strokeColor = CHART_BAR_COLOR['stroke']
  return (
    <>
      <div className="mt-2 border-2 bg-white border-gray-300 rounded flex flex-col  divide-y divide-gray-300">
        {settings &&
          mainInputTransforms.map((barBudget: any, index: number) => {
            return (
              <div key={uuid()} className="w-full flex flex-row space-x-1 h-16">
                <div className="w-2/12 flex flex-row items-center space-x-3 pl-3">
                  <MediaChannelIcon
                    className={` w-6 h-6 ${
                      strokeColor[index % strokeColor.length]
                    }`}
                    type={barBudget.identifiers[bottomLevel]}
                  />
                  <span className="text-xs">{barBudget.name}</span>
                </div>
                {/*bars here*/}
                {barBudget.mainInput && (
                  <div className="obs-alloc-channel-timeline-container w-8/12">
                    <div>
                      <div className="obs-alloc-channel-timeline-track" />
                      <div className="obs-alloc-channel-timeline">
                        <ObservationAllocationMultiRangeSlider
                          /* @ts-ignore */
                          canDrag={canDrag}
                          rangeColor={index % strokeColor.length}
                          details={barBudget.mainInput.ranges}
                          unit={'currency'}
                          observations={observations}
                          optimalInputPositions={optimalInputPositions[index]}
                          settings={settings}
                          transformation={barBudget}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-1/12 text-success-main flex items-center text-xs">
                  {appendUnit(
                    getKPITotalsByArray(
                      barBudget.transformations,
                      scenarioOutputSelected,
                      planItem.config.options.kpiDisplay,
                      true
                    ),
                    scenarioOutputSelected === 'spend'
                      ? 'currency'
                      : configOutputs.find(
                          (x: any) =>
                            x.label.toLowerCase().replace(/ /g, '_') ===
                            scenarioOutputSelected
                        )!.unit,
                    planItem.options.exchangeRates.defaultSymbol || '',
                    settings?.number_format || ''
                  )}
                </div>
                <div className="w-1/12 flex items-center text-gray-600 text-xs">
                  {appendUnit(
                    getKPITotalsByArray(
                      barBudget.transformations,
                      scenarioOutputSelected,
                      planItem.config.options.kpiDisplay,
                      false
                    ),
                    scenarioOutputSelected === 'spend'
                      ? 'currency'
                      : configOutputs.find(
                          (x: any) =>
                            x.label.toLowerCase().replace(/ /g, '_') ===
                            scenarioOutputSelected
                        )!.unit,
                    planItem.options.exchangeRates.defaultSymbol || '',
                    settings?.number_format || ''
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default ObservationAllocation
