import { ErrorDisplay } from '@gain-theory/error-display'
import { FC, useEffect } from 'react'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootStore } from '../redux/reducers/Store'
import { MainLayout } from '../shared/MainLayout'
import { ROUTES } from '../Routes'
import { AppState } from '../redux/reducers/RootReducer'
import { GetLaunchpadData } from '../redux/actions/MarketingDiagnosticsActions'

const mapState = (state: RootStore) => ({})

const mapDispatchToProps = {
  onGetLaunchpadData: GetLaunchpadData,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const NotFoundPage: FC<PropsFromRedux> = (props) => {
  const history = useHistory()
  const { systemLinks } = useSelector(
    (state: AppState) => state.marketingDiagnostics
  )

  useEffect(() => {
    props.onGetLaunchpadData()
  }, [])

  const notFoundDescription: string[] = [
    'A 404 error happens when the provided link address does not exist or cannot be found.',
    "To fix this, try checking the link address for errors. It's also possible that the item may have been deleted. If you're unable to locate what you seek, contact support for assistance.",
  ]

  const handleLaunchpadClick = () => {
    history.push(ROUTES.HOME)
  }

  const handleContactSupportClick = () => {
    const email =
      systemLinks !== null &&
      !!systemLinks.contact &&
      systemLinks.contact.length > 0
        ? systemLinks.contact[0].email
        : ''

    window.open(`mailto:${email}`, '_blank')
  }

  return (
    <ErrorDisplay
      title="Error 404: page not found"
      description={notFoundDescription}
      translations={{
        NEED_HELP: 'Need help?',
        CONTACT_SUPPORT: 'Contact Support',
      }}
      button={{
        label: 'Go to Launchpad',
        leadingIconName: 'RocketLaunchIcon',
        onClick: handleLaunchpadClick,
      }}
      onContactSupportClick={handleContactSupportClick}
    />
  )
}

export default connector(MainLayout(NotFoundPage, true))
