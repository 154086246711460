import { useNotificationContext } from '../../../shared/notification-provider'

export const useAuthLoginNotification = () => {
  const { toast } = useNotificationContext()

  const notification = {
    genericError: () =>
      toast(
        'error',
        'Something went wrong',
        'We cannot complete the requested action. Try again in a few minutes.'
      ),

    getProvidersError: () =>
      toast(
        'error',
        'Something went wrong',
        'No authentication provider was found. Please try again later.'
      ),

    getValidateAuthenticationError: () =>
      toast(
        'error',
        'Authentication error',
        'We ask for this information so you can collaborate seamlessly with others and so that we can provide you with a personalized experience when using GTi.'
      ),
  }

  return notification
}
