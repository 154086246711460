import { ComparisonKPIs, ComparisonPlan } from "../types/PlanComparisonTypes"

export const alterPlanByRate = (plan: ComparisonPlan, rate: number, kpis: ComparisonKPIs[], type: string) => {
      const currencyKpis = kpis.filter(x => x.unit === "currency").map(x => x.label.toLowerCase().replace(/ /g, "_"))
     //dividing totals
      plan.total.spend = type === "divide" ? plan.total.spend / rate : plan.total.spend * rate
      let kpisTotal = Object.keys(plan.total.kpis)
      for(let kpi of kpisTotal){
        if(currencyKpis.indexOf(kpi) !== -1){
            plan.total.kpis[kpi] = type === "divide" ? plan.total.kpis[kpi] / rate : plan.total.kpis[kpi] * rate
        }
      }


      //dividing by grouping level
      const levels = Object.keys(plan.transformations[0].identifiers).filter((x: string) => x !== "region_key")
      for(let level of levels){
          const allLevels = Object.keys(plan[level])
          for(let lev of allLevels){
              plan[level][lev].spend = type === "divide" ? plan[level][lev].spend / rate : plan[level][lev].spend * rate
              let kpisLower = Object.keys(plan[level][lev].kpis)
              for(let kpi of kpisLower){
                if(currencyKpis.indexOf(kpi) !== -1){
                    plan[level][lev].kpis[kpi] = type === "divide" ? plan[level][lev].kpis[kpi] / rate : plan[level][lev].kpis[kpi] * rate
                }
              }
          }
      }

      //divide transformations
      plan.transformations.forEach(trans => {
        const calcSpend = type === "divide" ? trans.calculated_spend / rate : trans.calculated_spend * rate
        trans.calculated_spend = type === "divide" ? trans.calculated_spend / rate : trans.calculated_spend * rate
        const totalsKeys = Object.keys(trans.calculated_totals)
        for(let key of totalsKeys){
          trans.calculated_totals[key] = type === "divide" ? trans.calculated_totals[key] / rate : trans.calculated_totals[key] * rate
        }
        return trans
      })
    return plan
  }