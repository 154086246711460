import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'

import { Combobox } from '@gain-theory/combobox'
import { Icon } from '@gain-theory/icon'
import { Tooltip } from '@gain-theory/tooltip'
import { Typography } from '@gain-theory/typography'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../../Routes'
import ChangeGroupingLevelsModal from '../../pages/spo/plan-output/ChangeGroupingLevelsModal'
import ChangeRegionBudgetModal from '../../pages/spo/plan-output/ChangeRegionBudgetModal'
import ConstraintsTable from '../../pages/spo/plan-output/ConstraintsTable'
import ViewCurvesModal from '../../pages/spo/plan-output/ViewCurvesModal'
import { useBudgetConstaint } from '../../pages/spo/shared/hooks/use-bugdet-constraints'
import {
  SelectCurrency,
  SelectPlanKpiOutput,
} from '../../redux/actions/PlanActions'
import { selectPlanItem } from '../../redux/reducers/PlanReducer'
import { AppState } from '../../redux/reducers/RootReducer'
import { RootStore } from '../../redux/reducers/Store'
import { getCurveData } from '../../redux/reducers/ViewCurvesReducer'
import LoadingModal from '../../shared/plan-view/LoadingModal'
import { CalculateCurveData } from '../../socket.io'
import { ExchangeRate, IO, Options } from '../../types/PlanTypes'

interface OwnProps {
  title: string
  name: string
  isSubViewVisible: boolean
  onSetViewVisibility: () => void
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  curveData: getCurveData(state),
  plan: selectPlanItem(state),
})

const mapDispatchToProps = {
  calculateCurveData: CalculateCurveData,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const AllocationHeader: FC<PropsFromRedux & Props> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { planItem } = useSelector((state: AppState) => state.plans)
  const [displayModal, setDisplayModal] = useState(false)
  const [displayCurve, setDisplayCurve] = useState(false)
  const [kpiSelected, setSelectedKPI] = useState('spend')

  const planLevels = Object.keys(planItem.grouping_levels)
  const bottomLevel = planLevels[planLevels.length - 1]

  const { outputSelectOptions, scenarioOutputSelected } = useSelector(
    (state: AppState) => state.plans
  )

  const { levelTransforms } = useSelector(
    (state: AppState) => state.planFilters
  )

  const { settings, inChannelSettings } = useSelector(
    (state: AppState) => state.settings
  )

  const { isBudgetConstraintEnabled } = useBudgetConstaint()

  useEffect(() => {
    if (displayModal) {
      document.body.style.overflow = 'hidden'
    }
    if (!displayModal) {
      document.body.style.overflow = 'auto'
    }
  }, [displayModal])

  useEffect(() => {
    if (props.curveData.length > 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [props.curveData])

  useEffect(() => {
    setSelectedKPI(scenarioOutputSelected)
  }, [scenarioOutputSelected])

  const handleKpiSelect = (label: string) => {
    const key = label.toLowerCase().replace(/ /g, '_')
    dispatch(SelectPlanKpiOutput(key))
  }

  const handleChangeCurrency = (currency: string) => {
    if (currency === planItem.options.exchangeRates.defaultSymbol) {
      return
    }
    const options: Options = _.cloneDeep(planItem.config.options)
    const exRates: ExchangeRate = _.cloneDeep(options.exchangeRates)
    const index = exRates.currencySymbols.indexOf(currency)

    exRates.defaultCurrency = exRates.currencies[index]
    exRates.defaultSymbol = exRates.currencySymbols[index]

    options.exchangeRates = exRates

    dispatch(SelectCurrency(planItem.id, options))
  }

  const curvesModal = () => {
    if (
      displayCurve &&
      props.curveData.length > 0 &&
      props.name === bottomLevel
    ) {
      const outputs = planItem?.config.transformations?.[0].io.filter(
        (item: any) => item.type === 'output'
      )
      return (
        <ViewCurvesModal
          setDisplayCurve={setDisplayCurve}
          outputs={outputs}
          outputSelected={kpiSelected}
          name={props.name}
        />
      )
    }
  }

  const showCurvesModal = () => {
    props.calculateCurveData(levelTransforms, props.plan.id, bottomLevel)
    setDisplayCurve(true)
  }

  const handleSetAllocationButtonClick = () => {
    const isTimeAllocation = props.name === 'time'

    if (isTimeAllocation && isBudgetConstraintEnabled) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM_CONTRAINTS_TIME)
        .param('id', props.plan.id)
        .toString()

      history.push(url)

      return
    }

    setDisplayModal(!displayModal)
  }

  if (displayCurve && props.curveData.length === 0) {
    return <LoadingModal />
  }

  const levelKey = props.title + '_name'
  const levelName = planItem.options.in_channel
    ? 'Partner'
    : settings![levelKey]
    ? settings![levelKey]
    : props.title

  let kpiOptions = outputSelectOptions
    .filter((output: IO) => output.key !== 'spend')
    .map((x) => {
      return {
        label: x.label,
      }
    })
  kpiOptions = [{ label: 'Spend' }, ...kpiOptions]

  return (
    <div>
      <div className="text-gray-500 flex flex-row pt-6">
        <div className="items-center w-8/12">
          <Typography.H5>{`${levelName} Allocation`}</Typography.H5>
        </div>
        <div className="w-4/12 flex flex-row">
          <div className="w-5/12 pr-2">
            <Typography.Body1>{'Currency'}</Typography.Body1>
          </div>
          <div className="pr-2">
            <Typography.Body1>{'Metric'}</Typography.Body1>
          </div>
          <div />
        </div>
      </div>
      {curvesModal()}
      <div className="flex flex-row">
        <div className="w-5/12">
          {props.name === bottomLevel && (
            <button onClick={showCurvesModal} className="h-full">
              <Typography.Body2
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                className=" text-brand-primary-main my-auto"
              >
                {'View Curves'}
              </Typography.Body2>
            </button>
          )}
        </div>
        <div className="w-3/12">
          {!planItem.locked_at && (
            <button onClick={handleSetAllocationButtonClick} className="h-full">
              <div className="flex flex-row">
                <Icon
                  className="pr-2"
                  color="brand-primary-main"
                  name="BanknotesIcon"
                  size="xxl"
                />
                <Typography.Body2
                  style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  className=" text-brand-primary-main my-auto"
                >
                  {props.name === 'time'
                    ? 'Set Budget By Time Period'
                    : `Set ${levelName} Budget`}
                </Typography.Body2>
              </div>
            </button>
          )}
          {displayModal && props.name === 'region' && (
            <ChangeRegionBudgetModal
              name={'region'}
              setModal={(modal: boolean) => setDisplayModal(modal)}
            ></ChangeRegionBudgetModal>
          )}
          {displayModal && props.name !== 'region' && props.name !== 'time' && (
            <ChangeGroupingLevelsModal
              inChannel={planItem.options.in_channel}
              name={props.name}
              setModal={(modal: boolean) => setDisplayModal(modal)}
            ></ChangeGroupingLevelsModal>
          )}
          {displayModal && props.name === 'time' && (
            <ConstraintsTable
              showModal={() => setDisplayModal(!displayModal)}
            ></ConstraintsTable>
          )}
        </div>
        <div className="w-4/12 flex flex-row">
          <div className="w-5/12 pr-2">
            <Combobox
              disallowTextManipulation
              multiple={false}
              options={planItem.config.options.exchangeRates.currencySymbols.map(
                (x: string) => {
                  return {
                    label: x,
                  }
                }
              )}
              selectedValues={[
                { label: planItem.options.exchangeRates.defaultSymbol },
              ]}
              setSelectedValues={(values: any) => {
                if (values.length > 0) {
                  handleChangeCurrency(values[0].label)
                }
              }}
            />
          </div>
          <div className="w-6/12 pr-2">
            <Combobox
              disallowTextManipulation
              multiple={false}
              options={kpiOptions}
              selectedValues={[
                kpiOptions.find(
                  (x) =>
                    x.label.toLowerCase().replace(/ /g, '_') === kpiSelected
                )!,
              ]}
              setSelectedValues={(values: any) => {
                if (values.length > 0) {
                  handleKpiSelect(values[0].label)
                }
              }}
            />
          </div>
          <div className="">
            <button
              onClick={(_) => props.onSetViewVisibility()}
              className="my-auto h-full"
            >
              <Tooltip
                content={
                  props.isSubViewVisible ? 'Hide section' : 'Show section'
                }
              >
                {props.isSubViewVisible ? (
                  <Icon name="EyeSlashIcon" />
                ) : (
                  <Icon name="EyeIcon" />
                )}
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(AllocationHeader)
