import '@gain-theory/internals/brands/font-loader.css'
import { LicenseManager } from 'ag-grid-enterprise'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AuthenticationProvider } from './authentication/AuthenticationProvider'
import './index.css'
import Store from './redux/reducers/Store'
import reportWebVitals from './reportWebVitals'
import './workaround-dev-error.css'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY ?? '')

ReactDOM.render(
  <BrowserRouter>
    <Provider store={Store}>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
