import { Channel, ERRN } from "../../../../../types/MarketingDiagnosticsTypes";

export const determineERRN = (active: ERRN, average: ERRN) => {
  let ERRN = "refine";

  if (
    active.effectiveness >= average.effectiveness &&
    active.productivity >= average.productivity
  ) {
    ERRN = "exploit";
  } else if (
    active.effectiveness >= average.effectiveness &&
    active.productivity < average.productivity
  ) {
    ERRN = "negotiate";
  } else if (
    active.effectiveness < average.effectiveness &&
    active.productivity < average.productivity
  ) {
    ERRN = "evaluate";
  } else if (
    active.effectiveness < average.effectiveness &&
    active.productivity >= average.productivity
  ) {
    ERRN = "refine";
  }

  return ERRN;
};

export const calculateERRN = (rows: Channel[]) => {
  const totalImpressions =
    rows.length > 0
      ? rows
        .map((x: Channel) => x.impressions)
        .reduce((a, b) => a + b, 0)
      : 0;

  const totalSpend =
    rows.length > 0
      ? rows.map(x => x.spend).reduce((a, b) => a + b, 0)
      : 0;

  const totalKPI =
    rows.length > 0
      ? rows.map(x => x.kpi).reduce((a, b) => a + b, 0)
      : 0;
  const averageEffec =
    totalImpressions !== 0
      ? totalKPI / (totalImpressions / 1000000)
      : 0;
  const averageProd =
    totalSpend !== 0 ? totalKPI / (totalSpend / 1000) : 0;

  const average = {
    effectiveness: averageEffec,
    productivity: averageProd
  };
  return average;
};