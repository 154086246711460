interface IGetNumberFromFormatProps {
  formattedValue: string
  decimalSeparator?: string
  thousandSeparator?: string
}

export function getNumberFromFormat({
  formattedValue,
  decimalSeparator = '.',
  thousandSeparator = ',',
}: IGetNumberFromFormatProps) {
  const stringifiedValue = String(formattedValue)

  const parts = stringifiedValue
    ? stringifiedValue.split(decimalSeparator)
    : ['']

  const integerPart = parts[0].replaceAll(thousandSeparator, '')

  const unformattedValue =
    parts.length === 1 ? integerPart : integerPart + '.' + parts[1]

  return Number(unformattedValue)
}
