export const downloadPlanCSVData = (scenario, headerNames, settings, inChannelSettings) => {

  const headerLabels = headerNames.map(name => {
    const labelKey = name + "_name"
    let label = settings[labelKey] ? settings[labelKey] : name
    if(scenario.options.in_channel){
      label = inChannelSettings[name].singular
    }
    return label
  })
  let header = [...headerLabels, 'Variable']

  const kpis = Object.keys(scenario.optimised_totals)
  const observationNames = scenario.config.observations
    .slice(scenario.observations_min, scenario.observations_max + 1)
    .map((x) => x.label)
  header = header.concat(scenario.config.observations.map(x => x.label))
  header = [...header, "Total"]
  let rows = []
  scenario.transformations.map((trans) => {
  let baseIdentifiers = []
    for(let level of headerNames){
      const gLevel = scenario.config.grouping_levels
      .find((x) => x.key === level)
      .values.find((val) => val.key === trans.identifiers[level]).label
      baseIdentifiers = [...baseIdentifiers, gLevel]
    }
    const spendRowIdentifiers = [...baseIdentifiers, 'Spend']
    let spendValue = trans.calculated['spend']
    if(scenario.observations_min !== 0){
      let emptyRowValues = Array(scenario.observations_min).fill(0)
      spendValue = [...emptyRowValues, ...spendValue]
    }
    if(scenario.observations_max + 1 !== scenario.config.observations.length){
      const difference = scenario.config.observations.length - scenario.observations_max - 1
      if(difference > 0){
        let emptyRowValues = Array(difference).fill(0)
        spendValue = [...spendValue, ...emptyRowValues]
      }
    }
    const totalSpend = spendValue.reduce((sum, a) => sum + a, 0)
    let row = spendRowIdentifiers.concat(spendValue)
    row = [...row, totalSpend]
    rows = [...rows, row]
    kpis.forEach((kpi) => {
      const kpiLabel = scenario.config.transformations[0].io.find(
        (x) => x.label.toLowerCase().replace(/ /g, '_') === kpi
      ).label
      const identifiers = [...baseIdentifiers, kpiLabel]
      let values = trans.calculated[kpi]
      let total = values.reduce((sum, a) => sum + a, 0)
      const totallingIndex = scenario.options.kpiDisplay.kpi.indexOf(kpi)
      if(totallingIndex !== -1 && scenario.options.kpiDisplay.function[totallingIndex] === "divide"){
        let numeratorTotal = trans.calculated[scenario.options.kpiDisplay.parameters[totallingIndex][0]].reduce((sum, a) => sum + a, 0)
        let denominatorTotal = trans.calculated[scenario.options.kpiDisplay.parameters[totallingIndex][1]].reduce((sum, a) => sum + a, 0)
        total = denominatorTotal !== 0 ? numeratorTotal / denominatorTotal : 0
      }
      if(scenario.observations_min !== 0){
        let emptyRowValues = Array(scenario.observations_min).fill(0)
        values = [...emptyRowValues, ...values]
      }
      if(scenario.observations_max + 1 !== scenario.config.observations.length){
        const difference = scenario.config.observations.length - scenario.observations_max - 1
        let emptyRowValues = Array(difference).fill(0)
        values = [...values, ...emptyRowValues]
      }
      let row = identifiers.concat(values)
      row = [...row, total]
      rows = [...rows, row]
    })
  })
  const data = [header, ...rows]
  return data
}
