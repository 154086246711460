import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/reducers/RootReducer'
import { ROUTES } from '../../Routes'
import { NavbarMenuItem, NavbarSubmenuItem } from './types'

const planComparisonSubMenu = {
  label: 'Plan Comparison',
  route: ROUTES.SPO.COMPARISONS,
} as NavbarSubmenuItem

const spoMenuBase = {
  label: 'Scenario Planning & Optimisation',
  iconName: 'ClipboardDocumentListIcon',
  showBadge: false,
  route: ROUTES.SPO.INDEX,
} as any as NavbarMenuItem

const inChannelSpoMenuBase = {
  label: 'In-Channel SPO',
  iconName: 'ArrowPathRoundedSquareIcon',
  route: ROUTES.SPO.IN_CHANNEL_INDEX,
  showBadge: false,
} as any as NavbarMenuItem

function useSpoMenu() {
  const { modules } = useSelector((state: AppState) => state.session)

  const [spoMenu, setSpoMenu] = useState({ ...spoMenuBase })
  const [inChannelSpoMenu, setInChannelSpoMenu] = useState({
    ...inChannelSpoMenuBase,
  })

  const [spoActive, setSpoActive] = useState(false)
  const [inChannelSpoActive, setInChannelSpoActive] = useState(false)

  useEffect(() => {
    let spoModuleActive = false
    let inChannelModuleActive = false

    if (modules && modules.length > 0) {
      const gtModule = modules.find((gtModule) => gtModule.key === 'spo')

      spoModuleActive = !!gtModule && gtModule.active

      inChannelModuleActive =
        !!gtModule &&
        gtModule.active &&
        gtModule.submodules !== null &&
        gtModule.submodules.includes('sensor')
    }

    setSpoActive(spoModuleActive)
    setInChannelSpoActive(inChannelModuleActive)
  }, [modules])

  useEffect(() => {
    const newSpoMenu = {
      ...spoMenuBase,
      disabled: !spoActive,
      ...(spoActive && { submenu: [{ ...planComparisonSubMenu }] }),
    }

    setSpoMenu(newSpoMenu)
  }, [spoActive])

  useEffect(() => {
    const newInChannelSpoMenu = {
      ...inChannelSpoMenuBase,
      disabled: !inChannelSpoActive,
    }

    setInChannelSpoMenu(newInChannelSpoMenu)
  }, [inChannelSpoActive])

  return { spoMenu, inChannelSpoMenu }
}

export default useSpoMenu
