import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import {
  CreateAppConfig,
  CreateMDConfig,
  CreateSPOConfig,
  CreateSPOConfigV2,
} from '../../redux/actions/ConfigActions'
import { UpdateSettings } from '../../redux/actions/SettingsActions'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { MainLayout } from '../../shared/MainLayout'
import uploadAppConfig from '../../utils/uploadAppConfig'
import uploadMDConfig from '../../utils/uploadMDConfig'
import { getVersion, uploadSPOConfig } from '../../utils/uploadSPOConfig'
import { uploadSPOConfigV2 } from '../../utils/uploadSPOConfigV2'
import AdminHeader from './AdminHeader'
import { RouteComponentProps } from 'react-router-dom'
import { ROUTES } from '../../Routes'
import { AppState } from '../../redux/reducers/RootReducer'

interface OwnProps {}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
})

const mapDispatchToProps = {
  updateSettings: UpdateSettings,
  onCreateAppConfig: CreateAppConfig,
  onCreateMDConfig: CreateMDConfig,
  onCreateSPOConfig: CreateSPOConfig,
  onCreateSPOConfigV2: CreateSPOConfigV2,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const UploadConfig: FC<PropsFromRedux & RouteComponentProps> = (props) => {
  const { adminSuccess } = useSelector((state: AppState) => state.settings)

  const [configName, setConfigName] = useState<any>('unknown')
  const [fileSPO, setFileSPO] = useState<any>(null)
  const [fileMD, setFileMD] = useState(null)
  const [fileApp, setFileApp] = useState(null)
  const [error, setError] = useState('')
  const [uploadConfigStatus, setUploadConfigStatus] = useState<string>('')

  useEffect(() => {
    setUploadConfigStatus(adminSuccess || '')
  }, [adminSuccess])

  const changeConfigName = (e: any) => {
    setConfigName(e.target.value)
  }

  const changeFileSPO = (e: any) => {
    setFileSPO(e.target.files[0])
  }

  const changeFileApp = (e: any) => {
    setFileApp(e.target.files[0])
  }

  const changeFileMD = (e: any) => {
    setFileMD(e.target.files[0])
  }

  const submitAppConfig = () => {
    if (fileApp) {
      uploadAppConfig(fileApp).then((config) => {
        props.onCreateAppConfig(config)
        setUploadConfigStatus('Success!')
      })
    }
  }

  const submitSPOConfig = () => {
    if (fileSPO) {
      console.log(fileSPO)
      getVersion(fileSPO).then((version) => {
        if (version > 1) {
          uploadSPOConfigV2(fileSPO).then((config) => {
            if (config.error === '') {
              config.config.file_name = fileSPO.name
              config.config.name = configName
              props
                .onCreateSPOConfigV2(config)
                .then(() => props.history.push(ROUTES.ADMIN.MANAGE_CONFIGS))
            } else {
              setError(config.error)
            }
          })
        } else {
          uploadSPOConfig(fileSPO).then((config) => {
            if (config.error === '') {
              config.config.file_name = fileSPO.name
              config.config.name = configName
              setUploadConfigStatus('Success!')

              props
                .onCreateSPOConfig(config)
                .then(() => props.history.push(ROUTES.ADMIN.MANAGE_CONFIGS))
            } else {
              setError(config.error)
            }
          })
        }
      })
    }
  }
  const submitMDConfig = () => {
    if (fileMD) {
      uploadMDConfig(fileMD).then((config) => {
        props.onCreateMDConfig(config)
        setUploadConfigStatus('Success!')
      })
    }
  }
  const templateFilePath = '/downloads/demo_config.xlsx'
  return (
    <div className={'container'}>
      <AdminHeader />
      {uploadConfigStatus !== '' && (
        <div className={'mt-4 w-full p-5 bg-gtLightGreen'}>
          {uploadConfigStatus}
        </div>
      )}
      <div className={'flex flex-col'}>
        <h1 className="text-gray-500 my-6 text-md w-3/4">Upload App Config</h1>
        <input type="file" onChange={changeFileApp} />
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 my-4 w-32"
          onClick={submitAppConfig}
        >
          SUBMIT
        </button>
      </div>
      <hr />
      <div className={'flex flex-col'}>
        <h1 className="text-gray-500 my-6 text-md w-3/4">
          Upload SPO or In-Channel SPO Config
        </h1>
        <input
          name="configName"
          type="input"
          onChange={changeConfigName}
          placeholder="Enter config name"
          className="mb-6 p-0.5 w-40 border border-gray-500 rounded placeholder-gray-400"
        />
        <input type="file" onChange={changeFileSPO} />
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 my-4 w-32"
          onClick={submitSPOConfig}
        >
          SUBMIT
        </button>
        <a
          className={'text-gtPink'}
          href={templateFilePath}
          download="demo_config.xlsx"
        >
          Download a template
        </a>
      </div>
      <hr />
      <div className={'flex flex-col'}>
        <h1 className="text-gray-500 my-6 text-md w-3/4">
          Upload Reporting Config
        </h1>
        <input type="file" onChange={changeFileMD} />
        <button
          className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 my-4 w-32"
          onClick={submitMDConfig}
        >
          SUBMIT
        </button>
      </div>
      <hr />
      {error !== '' && <div>{error}</div>}
    </div>
  )
}

export default connector(MainLayout(UploadConfig))
