import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GenericButton from './GenericButton'

class EditableAttribute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: '',
    }

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.save = this.save.bind(this)
  }

  static defaultProps = {
    isLocked: false,
    showEditButton: false,
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue,
    })
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        value: this.props.defaultValue,
      })
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.stopEditing()

      if (this.props.onClick) {
        this.props.onClick(false)
      }
    }
  }

  handleClick = (e) => {
    if (this.props.isLocked) {
      return
    }

    if (this.props.onClick) {
      this.props.onClick(true)
    }

    this.setState({
      editing: true,
    })

    setTimeout(() => {
      const input = this.refs.textInput
      input.focus()
      if (input.selectAll) {
        input.selectAll() // BudgetField workaround
      } else {
        input.setSelectionRange(0, input.value.length)
      }
    })
  }

  handleBlur = (e) => {
    this.save()
  }

  handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      this.save()
    }
    if (
      e.key === 'Escape' ||
      (this.wrapperRef && !this.wrapperRef.contains(e.target))
    ) {
      this.stopEditing()
    }
  }

  save = () => {
    this.stopEditing()
    this.props.onSave && this.props.onSave(this.state.value)
    this.props.onClick && this.props.onClick(false)
  }

  stopEditing = () => {
    this.setState({
      editing: false,
      value: this.props.defaultValue,
    })
  }

  handleChange = (valueOrEvent) => {
    const value =
      ['string', 'number', 'undefined'].indexOf(typeof valueOrEvent) !== -1
        ? valueOrEvent
        : valueOrEvent.target.value

    this.setState({
      value,
    })
  }

  render() {
    const {
      className,
      children,
      component,
      defaultValue,
      onSave, // prevent this from being in restProps
      onCancel, // prevent this from being in restProps
      ...restProps
    } = this.props

    const { editing } = this.state

    if (editing) {
      const Component = component || 'input'

      return (
        <div
          className="w-72 flex flex-1 flex-row justify-start"
          ref={this.setWrapperRef}
        >
          <Component
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            onBlur={this.handleBlur}
            {...restProps}
            ref="textInput"
            className={`${className} rounded-md focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none`}
            size="20"
          />
          {this.props.showEditButton && (
            <GenericButton
              className={`text-xxs text-gtPink hover:underline ${
                editing ? 'mt-7 ml-2' : 'mt-3 ml-1'
              }`}
              type="button"
              onClickHandler={editing ? this.save : this.handleClick}
            >
              {editing ? 'save' : !this.props.isLocked && 'edit'}
            </GenericButton>
          )}
        </div>
      )
    }

    return (
      <div
        className="w-72 flex flex-1 flex-row justify-start"
        ref={this.setWrapperRef}
      >
        <div onClick={this.handleClick}>{children}</div>

        {this.props.showEditButton && (
          <GenericButton
            className={`text-xxs text-gtPink hover:underline ${
              editing ? 'mt-7 ml-2' : 'mt-3 ml-1'
            }`}
            type="button"
            onClickHandler={editing ? this.save : this.handleClick}
          >
            {editing ? 'save' : !this.props.isLocked && 'edit'}
          </GenericButton>
        )}
      </div>
    )
  }
}

EditableAttribute.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  onClick: PropTypes.func,
  isLocked: PropTypes.bool,
  showEditButton: PropTypes.bool,
}

export default EditableAttribute
