import _ from 'lodash'

export const prepareTransforms = (transforms: any, level: string) => {
  const clonedTransforms = _.cloneDeep(transforms)
  let mappedTransforms: any = {}

  for (let trans of clonedTransforms) {
    const bottomLevel = trans.identifiers[level]
    mappedTransforms[bottomLevel] = { optimised: [], totalOpt: 0 }

    mappedTransforms[bottomLevel].optimised = trans.optimised.spend
    mappedTransforms[bottomLevel].totalOpt = mappedTransforms[
      bottomLevel
    ].optimised.reduce((acc: number, val: number) => {
      return acc + val
    }, 0)

    mappedTransforms[bottomLevel].identifiers = trans.identifiers
  }
  return mappedTransforms
}

export const multiplyTransforms = (mediaData: any, multiplier: number) => {
  const clonedMediaData = _.cloneDeep(mediaData)
  const keys = Object.keys(mediaData)
  for (let key of keys) {
    const data = clonedMediaData[key].optimised.map(
      (a: number) => a * multiplier
    )

    clonedMediaData[key].optimised = data
    clonedMediaData[key].totalOpt = clonedMediaData[key].optimised.reduce(
      (acc: number, val: number) => {
        return acc + val
      },
      0
    )
  }
  return Object.values(clonedMediaData)
}
