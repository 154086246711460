import { FC } from 'react'
import { Breadcrumbs } from '@gain-theory/breadcrumbs'
import { Button } from '@gain-theory/button'
import { Typography } from '@gain-theory/typography'
import { Icon } from '@gain-theory/icon'
import { ROUTES } from '../../../Routes'

interface IUploadPlanHeaderProps {
  disableGeneratePlan: boolean
  editConstraints: boolean
  onCancel: () => void
  onGeneratePlan: () => void
}

const UploadPlanHeader: FC<IUploadPlanHeaderProps> = ({
  disableGeneratePlan,
  editConstraints,
  onCancel,
  onGeneratePlan,
}) => {
  const HeaderBreadcrumbs = (
    <Breadcrumbs
      breadcrumbsConfig={[
        {
          href: ROUTES.SPO.INDEX,
          label: 'Scenario Planning & Optimization',
          divider: true,
        },
        { label: 'Upload Plan' },
      ]}
    />
  )

  const HeaderTitle = (
    <Typography.H4>Upload an existing marketing plan</Typography.H4>
  )

  const CancelButton = (
    <Button
      variant="secondary"
      leadingIcon={<Icon name="ArrowLeftIcon" color="brand-primary-main" />}
      onClick={onCancel}
    >
      Cancel
    </Button>
  )

  const GeneratePlanButton = (
    <Button
      variant="primary"
      leadingIcon={
        <Icon
          name={editConstraints ? 'ArrowRightIcon' : 'PlayIcon'}
          color={disableGeneratePlan ? 'neutral-gray-500' : 'neutral-white'}
        />
      }
      disabled={disableGeneratePlan}
      onClick={onGeneratePlan}
    >
      {editConstraints ? 'Next' : 'Generate Plan'}
    </Button>
  )

  return (
    <div className="w-full flex flex-col gap-md items-start justify-start">
      {HeaderBreadcrumbs}
      <div className="w-full flex flex-row gap-1 items-center justify-between">
        {HeaderTitle}
        <div className="flex flex-row-reverse gap-md items-center justify-end">
          {GeneratePlanButton}
          {CancelButton}
        </div>
      </div>
    </div>
  )
}

export default UploadPlanHeader
