import { Card } from '@gain-theory/card'
import { Icon } from '@gain-theory/icon'
import { Typography } from '@gain-theory/typography'
import { CSSProperties, Children, memo } from 'react'
import { ProductCardProps } from './types'
import { useCardChipLabels } from './use-card-chip-labels'

export const ProductCard = memo(
  ({ card, filterChips, selected, show, onClick }: ProductCardProps) => {
    const { label, icon, chips } = card
    const { chipLabels } = useCardChipLabels({
      cardChips: chips,
      filterChips,
    })

    const rootStyle: CSSProperties = {
      ...(!show && { padding: 0, border: 'none' }),
      overflow: 'hidden',
      visibility: show ? 'visible' : 'hidden',
      opacity: show ? 1 : 0,
      transform: show ? 'translateY(0)' : 'translateY(20px)',
      height: show ? 'auto' : 0,
      width: show ? 'auto' : 0,
    }

    const CardLabel = (
      <Typography.Body1>
        <span className="text-text-white">{label}</span>
      </Typography.Body1>
    )

    const CardIcon = (
      <Icon
        size="xxxxxl"
        color="neutral-white"
        library={icon.library}
        name={icon.name}
        style={{ strokeWidth: 1 }}
      />
    )

    const CardChips = (
      <div className="flex flex-row flex-wrap gap-xs items-center justify-center whitespace-nowrap">
        {Children.toArray(
          chipLabels.map((chipLabel, index) => (
            <div
              key={`chip-${index}`}
              className={`text-center rounded-pill px-xs py-xxxs ${
                selected ? 'bg-neutral-white' : 'bg-brand-primary-main'
              }`}
            >
              <Typography.Caption>
                <span
                  className={
                    selected ? 'text-brand-primary-main' : 'text-text-white'
                  }
                >
                  {chipLabel}
                </span>
              </Typography.Caption>
            </div>
          ))
        )}
      </div>
    )

    return (
      <Card
        data-testid={card.id ? `${card.id}-product-card` : `product-card`}
        onClick={() => onClick(card.id)}
        style={rootStyle}
        className={`flex-shrink-0 shadow-sm text-center z-30 transition-all duration-300 ease-out justify-center hover:cursor-pointer hover:shadow-color-primary-lg ${
          selected ? 'bg-brand-primary-main' : 'bg-neutral-gray-1100'
        }`}
      >
        <div className="flex flex-col gap-xs w-huge items-center justify-evenly text-center select-none">
          {show && CardLabel}
          {show && CardIcon}
          {show && CardChips}
        </div>
      </Card>
    )
  }
)
