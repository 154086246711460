import { Typography } from '@gain-theory/typography'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { GetConfigList } from '../../redux/actions/ConfigActions'
import { selectConfigs } from '../../redux/reducers/ConfigReducer'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'
import { Config } from '../../types/ConfigTypes'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  settings: selectSettings(state),
  configs: selectConfigs(state),
})
const mapDispatchToProps = {
  onGetConfigs: GetConfigList,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {}

const ManageConfigs: FC<OwnProps> = (props) => {
  useEffect(() => {
    props.onGetConfigs()
  }, [])
  const [displayType, setDisplayType] = useState('crossChannel')
  const [displayConfigs, setDisplayConfigs] = useState<Config[]>([])

  useEffect(() => {
    if (props.configs) {
      setDisplayConfigs(
        props.configs.filter((config) => config.options.in_channel === false)
      )
    }
  }, [props.configs])

  useEffect(() => {
    if (props.configs && displayType === 'crossChannel') {
      setDisplayConfigs(
        props.configs.filter((config) => config.options.in_channel === false)
      )
    }
    if (props.configs && displayType === 'inChannel') {
      setDisplayConfigs(
        props.configs.filter((config) => config.options.in_channel === true)
      )
    }
  }, [displayType])

  const getCreateURL = (id: string) => {
    if (displayType === 'inChannel') {
      return UrlAssembler()
        .template(ROUTES.SPO.CREATE_IN_CHANNEL_PLAN)
        .param('id', id)
        .toString()
    }
    return UrlAssembler()
      .template(ROUTES.SPO.CREATE_PLAN)
      .param('id', id)
      .param('optimisationType', 'budget')
      .toString()
  }

  const changeConfigType = (e: any) => {
    setDisplayType(e.target.value)
  }

  return (
    <div className={'container'}>
      <AdminHeader></AdminHeader>
      <Typography.H4 className="my-6">Manage SPO Configs</Typography.H4>
      {
        <select className={'my-4'} onChange={changeConfigType}>
          <option value={'crossChannel'}>
            Scenario Planning & Optimisation
          </option>
          <option value={'inChannel'}>In-Channel SPO</option>
        </select>
      }
      {props.configs && (
        <table className="min-w-full table-auto mb-96 divide-y divide-gray-300 border rounded text-text-medium-emphasis">
          <thead>
            <tr className="p-2">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Create
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                File Name
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {displayConfigs.map((config) => (
              <tr key={config.id}>
                <td>{config.id}</td>
                <td>{config.name}</td>
                <td>
                  <Link
                    className="text-gtPink"
                    to={getCreateURL(config.id.toString())}
                  >
                    Create a marketing plan with this config
                  </Link>
                </td>
                <td>{config.file_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default connector(MainLayout(ManageConfigs))
