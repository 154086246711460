import React, { Component, FC, useState, useEffect } from 'react'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { selectPlanItem } from '../../../redux/reducers/PlanReducer'
import * as _ from 'lodash'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LegendType,
} from 'recharts'
import appendUnit from '../../../utils/appendUnit'
import { RootStore } from '../../../redux/reducers/Store'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { getCurveData } from '../../../redux/reducers/ViewCurvesReducer'
import { DeleteCurveData } from '../../../socket.io'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { CHART_BAR_COLORS } from '../../../utils/colours'
import { downloadToXLSX } from '../../../utils/jsonToXLSX'
import { GroupingLevel, GroupingLevelValue } from '../../../types/PlanTypes'
import { AppState } from '../../../redux/reducers/RootReducer'
import { Alert } from '@gain-theory/alert'

interface OwnProps {
  outputs: any
  outputSelected: string
  setDisplayCurve: Function
  name: string
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  plan: selectPlanItem(state),
  curveData: getCurveData(state),
})

const mapDispatchToProps = {
  deleteCurveData: DeleteCurveData,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const CurveModal: FC<PropsFromRedux & Props> = (props) => {
  const filteredOutputs = props.outputs
  const groupingLevels = props.plan.config.grouping_levels.map(
    (x: any) => x.key
  )

  const firstOutput =
    props.outputSelected.toLowerCase() === 'spend'
      ? filteredOutputs[0].label.toLowerCase().replace(/ /g, '_')
      : filteredOutputs
          .find(
            (x: any) =>
              x.label.toLowerCase().replace(/ /g, '_') === props.outputSelected
          )
          .label.toLowerCase()
          .replace(/ /g, '_')

  const [outputSelected, setOutputSelected] = useState(firstOutput)
  const [showAlertText, setShowAlertText] = useState(true)

  const { settings, inChannelSettings } = useSelector(
    (state: AppState) => state.settings
  )

  const getColours = (number: number) => {
    return CHART_BAR_COLORS[number]
  }

  const close = () => {
    props.setDisplayCurve(false)
    props.deleteCurveData()
  }

  const changeOutput = (e: any) => {
    setOutputSelected(e.target.value.toLowerCase().replace(/ /g, '_'))
  }

  const arrangeCurveData = (curveData: any) => {
    let data = []
    for (let i in curveData) {
      for (let j in curveData[i]) {
        const identifiers = curveData[i][j].identifiers
        const totals = curveData[i][j].totals
        const spend = curveData[i][j].optimised_spend
        const levelName = identifiers[props.name]
          .toUpperCase()
          .replace(/_/g, ' ')
        const dataObj: any = {
          [levelName]: totals[outputSelected],
          spendTotal: spend,
          name: levelName,
          kpiTotal: totals[outputSelected],
          currency: props.plan.options.exchangeRates.defaultSymbol,
        }
        const kpis = Object.keys(totals)
        for (let vals of kpis) {
          dataObj[vals] = totals[vals]
        }
        for (let lev of groupingLevels) {
          const levelKey = props.plan.config.grouping_levels
            .find((x: GroupingLevel) => x.key === lev)
            ?.values.find(
              (x: GroupingLevelValue) =>
                x.key === props.curveData[i][j].identifiers[lev]
            )?.label
          dataObj[lev] = levelKey
        }
        data.push(dataObj)
      }
    }

    let levels: string[] = []
    for (let point of data) {
      if (levels.indexOf(point.name) === -1) {
        levels = [...levels, point.name]
      }
    }

    //push an origin point for each level
    for (let lev of levels) {
      const dataObj = {
        [lev]: 0,
        spendTotal: 0,
        name: lev,
        kpiTotal: 0,
      }
      data.push(dataObj)
    }

    let sortedData = _.sortBy(data, 'name', 'spendTotal')

    // const finalData = sortedData.filter((x) => x.kpiTotal !== 0)

    return sortedData
  }

  const getLegendShapes = (levels: any) => {
    const shapeArray: LegendType[] = [
      'square',
      'circle',
      'cross',
      'diamond',
      'star',
      'triangle',
    ]
    let legendArray = []
    for (let i = 0; i < levels.length; i++) {
      let shape: LegendType = 'square'
      if (!shapeArray[i]) {
        const modIndex = i % shapeArray.length
        shape = shapeArray[modIndex]
      } else {
        shape = shapeArray[i]
      }
      const legendObj: Payload = {
        id: levels[i],
        value: levels[i],
        type: shape,
        color: getColours(i),
      }
      legendArray.push(legendObj)
    }
    return legendArray
  }

  const curveData = arrangeCurveData(props.curveData)
  const planLevels = Object.keys(props.plan.grouping_levels)

  let largestSpend = 0
  let largestKPI = 0
  for (let point of curveData) {
    if (point.spendTotal > largestSpend) {
      largestSpend = Math.ceil(point.spendTotal)
    }
    if (point.kpiTotal > largestKPI) {
      largestKPI = Math.ceil(point.kpiTotal)
    }
  }

  let levelValues: string[] = []
  for (let point of curveData) {
    if (levelValues.indexOf(point.name) === -1) {
      levelValues = [...levelValues, point.name]
    }
  }

  useEffect(() => {}, [outputSelected])

  const legendShapes = getLegendShapes(levelValues)
  const outputSel = props.outputs.find(
    (o: any) =>
      o.label.toUpperCase().replace(/ /g, '_') ===
      outputSelected.toUpperCase().replace(/ /g, '_')
  ).label

  const currency = props.outputs.find(
    (o: any) =>
      o.label.toUpperCase().replace(/ /g, '_') ===
      outputSelected.toUpperCase().replace(/ /g, '_')
  ).unit

  const download = () => {
    if (inChannelSettings) {
      const kpiNames = props.curveData[0][0].outputLabels
      let columns: any = []
      for (let lev of groupingLevels) {
        const levName = lev + '_name'
        const levLabel =
          props.plan.options.in_channel && inChannelSettings[lev].singular
            ? inChannelSettings[lev].singular
            : settings![levName]
            ? settings![levName]
            : lev
        columns = [...columns, { label: levLabel, value: lev }]
      }
      columns = [...columns, { label: 'Currency', value: 'currency' }]
      columns = [...columns, { label: 'Spend', value: 'spendTotal' }]
      for (let kpi of kpiNames) {
        const kpiLabel = props.outputs.find(
          (x: any) => x.label.toLowerCase().replace(/ /g, '_') === kpi
        )!.label
        columns = [
          ...columns,
          { label: kpiLabel, value: kpi.replace('.', '_') },
        ]
      }

      function replaceDotWithUnderscore(obj: any) {
        _.forOwn(obj, (value, key) => {
          // if key has a period, replace all occurences with an underscore
          if (_.includes(key, '.')) {
            const cleanKey = _.replace(key, /\./g, '_')
            obj[cleanKey] = value
            delete obj[key]
          }

          // continue recursively looping through if we have an object or array
          if (_.isObject(value)) {
            return replaceDotWithUnderscore(value)
          }
        })
        return obj
      }

      const newCurveData = replaceDotWithUnderscore(curveData).filter(
        (x: any) => x.currency
      )

      const downloadData = [
        {
          sheet: 'Sheet1',
          columns: columns,
          content: newCurveData,
        },
      ]
      downloadToXLSX(downloadData, props.plan.id, props.plan.name)
    }
  }

  return (
    <div>
      <div className="justify-center flex overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        {settings && (
          <div className="my-6 mx-auto w-10/12 bg-white">
            <div className="border-0 rounded-lg shadow-lg flex flex-col w-full h-full bg-white outline-none focus:outline-none">
              <div className="flex flex-col items-start justify-between border-b border-solid border-blueGray-200 rounded-t h-full overflow-auto">
                <div className="w-full border">
                  <div className="flex flex-row">
                    <h1 className="text-gray-500 text-md p-5 uppercase w-11/12">
                      {`Optimal ${props.name} Curves`}
                    </h1>
                  </div>
                  <div className="mx-2">
                    <Alert
                      title="Filters impact results"
                      description="By default, all values are selected in the filter 
                      and the chart displays blended curves. 
                      To access specific curves, 
                      select the relevant card in the plan to filter for that data. 
                      The download file contains the precise optimised curve data."
                      variant="info"
                      show={showAlertText}
                      onClose={() => setShowAlertText(false)}
                    />
                  </div>
                  {
                    //pineapple
                  }
                  <div className="flex flex-row pt-2">
                    <div className="flex flex-col px-5 w-3/12" />
                    <div>Metric:</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-col px-5 w-3/12">
                      {props.curveData &&
                        groupingLevels
                          .filter((x: string) => x !== 'media')
                          .map((level: string) => {
                            const levName = level + '_name'
                            const levLabel =
                              props.plan.options.in_channel &&
                              inChannelSettings &&
                              inChannelSettings[level].singular
                                ? inChannelSettings![level].singular
                                : settings![levName]
                                ? settings![levName]
                                : level

                            const uniqueNames = _.uniqBy(
                              props.curveData[0].map(
                                (x: any) => x.identifiers[level]
                              ),
                              function (e) {
                                return e
                              }
                            )
                            const returnName =
                              uniqueNames.length < 2
                                ? `${levLabel.toUpperCase()}: ${props.curveData[0][0].identifiers[
                                    level
                                  ]
                                    .toUpperCase()
                                    .replace(/_/g, ' ')}`
                                : `${levLabel.toUpperCase()}: ALL`

                            return <div key={level}>{returnName}</div>
                          })}
                    </div>
                    <select
                      onChange={changeOutput}
                      value={outputSel}
                      className="w-40 my-auto h-12"
                    >
                      {filteredOutputs.map((output: any) => {
                        return (
                          <option key={output.label} value={output.label}>
                            {output.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                {/*body*/}
                <div className="w-full h-full">
                  <ResponsiveContainer>
                    <LineChart
                      data={curveData}
                      margin={{
                        top: 5,
                        right: 50,
                        left: 20,
                        bottom: 50,
                      }}
                    >
                      <CartesianGrid strokeDasharray="10 10" />
                      <XAxis
                        label={{
                          value: 'Spend',
                          offset: 0,
                          position: 'insideBottomRight',
                          dy: 5,
                        }}
                        dataKey="spendTotal"
                        type="number"
                        domain={[0, 'dataMax']}
                        tickFormatter={(val) => {
                          if (settings) {
                            return appendUnit(
                              val,
                              'currency',
                              props.plan.options.exchangeRates.defaultSymbol,
                              settings.number_format
                            )
                          } else {
                            return ''
                          }
                        }}
                        ticks={[
                          0,
                          largestSpend / 4,
                          largestSpend / 2,
                          (largestSpend * 3) / 4,
                          largestSpend,
                        ]}
                      />
                      <YAxis
                        label={{
                          value: outputSel,
                          position: 'left',
                          angle: -90,
                        }}
                        dataKey="kpiTotal"
                        type="number"
                        domain={[0, 'dataMax']}
                        tickFormatter={(val) => {
                          if (settings) {
                            return appendUnit(
                              val,
                              currency,
                              props.plan.options.exchangeRates.defaultSymbol,
                              settings.number_format
                            )
                          } else {
                            return ''
                          }
                        }}
                        ticks={[
                          0,
                          largestKPI / 4,
                          largestKPI / 2,
                          (largestKPI * 3) / 4,
                          largestKPI,
                        ]}
                        dx={5}
                      />
                      <Legend
                        wrapperStyle={{ position: 'relative' }}
                        payload={legendShapes}
                      />
                      {levelValues.map((lev, index) => {
                        return (
                          <Line
                            key={lev}
                            dataKey={lev}
                            stroke={getColours(index)}
                            isAnimationActive={false}
                            dot={
                              <CharacterDot
                                payload={legendShapes}
                                shapes={legendShapes}
                                curveData={curveData}
                                media={lev}
                                cx={0}
                                cy={0}
                              />
                            }
                            name={lev}
                          />
                        )
                      })}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="flex flex-row py-2 justify-center align-middle w-full">
                <button
                  className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 uppercase"
                  onClick={close}
                >
                  Cancel
                </button>

                <button
                  className="clear-left rounded bg-gtPink text-white text-xs py-4 ml-4 px-4 uppercase"
                  onClick={download}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default connector(CurveModal)

interface OwnPropsDot {
  curveData: any
  media: any
  payload: any
  cy: number
  cx: number
  shapes: any
  // stroke: any
}

type PropsDot = OwnPropsDot
const CharacterDot: FC<PropsDot> = (props) => {
  const paths: any = {
    triangle: 'M150 0 L75 200 L225 200 Z',
    cross:
      'M130 70 L130 150 L190 150 L190 210 L270 210 L270 150 L330 150 L330 70 L270 70 L270 0 L190 0 L190 70',
    square: 'M150 0 L150 150 L300 150 L 300 0 Z',
    rect: 'M75 0 L75 200 L225 200 L 225 0 Z',
    circle: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
    diamond: 'M240 0 L360 120 L240 280 L120 120 Z',
    star: 'M 270.000 180.000 L 330.000 223.923 L 321.962 150.000 L 390.000 120.000 L 321.962 90.000 L 330.000 16.077 L 270.000 60.000 L 210.000 16.077 L 218.038 90.000 L 150.000 120.000 L 218.038 150.000 L 210.000 223.923 Z',
  }

  let allData = props.curveData.filter((d: any) => d.name === props.media)

  let middleIndex = Math.round((allData.length - 1) / 2) - 1

  const filteredData = allData[middleIndex]

  if (filteredData) {
    if (props.payload.spendTotal === filteredData.spendTotal && props.cy) {
      const shape = props.shapes.find(
        (media: any) => media.id === props.payload.name
      )
      return (
        <svg
          x={props.cx - 5}
          y={
            shape === 'diamond' || shape === 'star' || shape === 'cross'
              ? props.cy - 6
              : props.cy - 5
          }
          width={40}
          height={40}
          fill={shape.color}
          viewBox="0 0 1024 1024"
        >
          <path d={paths[shape.type]} />
        </svg>
      )
    } else {
      return null
    }
  }
  return null
}
