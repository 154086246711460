import { Channel } from "../../../../../types/MarketingDiagnosticsTypes";

export const getPrevDifference = (current: Channel, prev: Channel) => {
  let effectiveness =
    prev.effectiveness !== 0
      ? Math.round(
        ((current.effectiveness - prev.effectiveness) /
          prev.effectiveness) *
        100
      )
      : 0;
  let productivity =
    prev.productivity !== 0
      ? Math.round(
        ((current.productivity - prev.productivity) /
          prev.productivity) *
        100
      )
      : 0;
  let kpi =
    prev.kpi !== 0
      ? Math.round(((current.kpi - prev.kpi) / prev.kpi) * 100)
      : 0;
  let spend =
    prev.spend !== 0
      ? Math.round(((current.spend - prev.spend) / prev.spend) * 100)
      : 0;
  let impressions =
    prev.impressions !== 0
      ? Math.round(
        ((current.impressions - prev.impressions) /
          prev.impressions) *
        100
      )
      : 0;

  return { effectiveness, productivity, kpi, spend, impressions };
};