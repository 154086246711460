export const ROUTES = {
  SPO: {
    INDEX: '/spo',
    IN_CHANNEL_INDEX: '/in-channel-spo',
    SCENARIOS: '/spo/scenarios',
    CROSS_CHANNEL_SCENARIOS: '/spo/scenarios',
    IN_CHANNEL_SCENARIOS: '/in-channel-spo/scenarios',
    CREATE_PLAN: '/spo/config/:id/create-scenario/:optimisationType',
    CREATE_IN_CHANNEL_PLAN:
      '/in-channel-spo/config/:id/create-in-channel-scenario/',
    PLAN_UPLOAD: '/spo/scenario/upload/:id',
    IN_CHANNEL_PLAN_UPLOAD: '/in-channel-spo/scenario/upload-in-channel/:id',
    EDIT_BUDGET: '/spo/scenario/edit-budget',
    EDIT_BUDGET_SELECTION: '/spo/scenario/edit-budget/:id/:currency',
    PLAN_ITEM: '/spo/scenario/:id',
    PLAN_ITEM_CONTRAINTS_TIME: '/spo/scenario/:id/constraints-time',
    IN_CHANNEL_PLAN_ITEM: '/in-channel-spo/scenario/:id',
    PLAN_ITEM_FROM_COMPARISON: '/spo/scenario/:id/:comparisonId',
    COMPARISONS: '/spo/comparisons',
    COMPARISONS_OUTPUT: '/spo/comparison/:id',
    COMPARISON_CREATE_FLOW: '/spo/comparison/create/:baseId',
  },
  LOGIN: '/login',
  MARKETING_DIAGNOSTICS: {
    INDEX: '/reporting',
    HIGHLIGHTS: '/reporting/highlights',
    PERFORMANCE_OVERVIEW: '/reporting/performance-overview/:brand',
    CHANNEL_PERFORMANCE:
      '/reporting/channels/:channel/:kpiId/:phaseId/:brand/:kpiName',
    CHANNEL_PERFORMANCE_PREV_PHASE:
      '/reporting/channels/:channel/:kpiId/:phaseId/:prevPhase/:brand/:kpiName',
    UNIFIED_ADVANCED_VIEW: '/reporting/brand/:brand/:view',
    IMR_VIEW_LINK: '/reporting/imr',
  },
  HOME: '/',
  NOPERMISSION: '/noPermission',
  ADMIN: {
    INDEX: '/admin',
    SETTINGS: '/admin/settings',
    MANAGE_USERS: '/admin/users',
    MANAGE_ONE_USER: '/admin/users/:id',
    CREATE_USER: '/admin/users/create',
    UPLOAD_USERS: '/admin/users/upload',
    MANAGE_CONFIGS: '/admin/configs',
    UPLOAD_CONFIG: '/admin/configs/upload',
    MANAGE_GROUPS: '/admin/groups',
    MANAGE_ONE_GROUP: '/admin/groups/:id',
    CREATE_GROUPS: '/admin/groups/create',
    LOGIN: '/admin/login',
  },
  CHANGE_PASSWORD: '/change-password',
  TERMS_ACKNOWLEDGMENT: '/acknowledge',
}
