import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import GenericBrandIcon from '../../../icons/spo/GenericBrandIcon'
import { ReactComponent as GenericProductIcon } from '../../../../src/icons/svg/icon-generic-product-m.svg'
import Truncate from 'react-truncate'
import { GroupingLevel } from '../../../types/PlanTypes'

interface OwnProps {
  filtersText: string[]
  onEdit: (section: number) => void
}
type Props = OwnProps
const DimensionSummary: FC<Props> = (props) => {
  const { planItem } = useSelector((state: AppState) => state.plans)
  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)

  //PINEAPPLE sort these labels

  const icons = () => {
    return props.filtersText.slice(0, 3).map((x, index) => (
      <div key={index} className="rounded-full bg-gray-100 w-7 h-7">
        <GenericProductIcon className="w-7 h-7" key={x} />
      </div>
    ))
    }

  const names = props.filtersText.map(x => {
    const level = planItem.config.grouping_levels.find((lev: GroupingLevel) => lev.key === x)
    if(!level || !settings){
      return x
    }
    if(planItem.options.in_channel && inChannelSettings){
      return inChannelSettings[level.key].plural
    } else {
      return settings[level.key + "_plural"]
    }
  })

  return (
    <div className="text-neutral-gray-1100  text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold">
          {"DIMENSIONS"}
        </span>
        {!planItem.locked_at && (
          <button
            onClick={(_) => props.onEdit(0)}
            className="text-gtPink text-xxs"
          >
            edit
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-1">{icons()}</div>
        <Truncate className="text-xxs">{names.join(', ')}</Truncate>
      </div>
    </div>
  )
}

export default DimensionSummary