import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

// Redux
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { RootStore } from '../../../redux/reducers/Store'
import { MainLayout } from '../../../shared/MainLayout'
import { ROUTES } from '../../../Routes'
import EditableAttribute from '../../../utils/EditableAttribute'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import planComparisonsReducer, {
  selectComparisonData,
  selectUpdateComparison,
  selectComparisonLoaded,
} from '../../../redux/reducers/PlanComparisonsReducer'
import {
  ChangeBasePlanComparison,
  EditComparisonName,
  GetPlanComparisonData,
} from '../../../socket.io'
import {
  ComparisonFilters,
  ComparisonKPIs,
  ComparisonPlan,
} from '../../../types/PlanComparisonTypes'
import { GtiToggleButton } from '../../../shared/GTIToggleButton'
import { CSVLink } from 'react-csv'
import UrlAssembler from 'url-assembler'
import { GetPlanItemAllowAccess } from '../../../redux/actions/PlanActions'
import appendUnit from '../../../utils/appendUnit'
import ChangeComparisonKPIModal from './ChangeComparisonKPIModal'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import Tooltip from 'rc-tooltip'
import ChevronRightDefaultIcon from '../../../icons/ChevronRightDefaultIcon'
import ChevronLeftDefaultIcon from '../../../icons/ChevronLeftDefaultIcon'
import DownArrowIcon from '../../../icons/DownArrowIcon'
import UpArrowIcon from '../../../icons/UpArrowIcon'
import PlanComparisonsActionsMenu from './PlanComparisonsActionsMenu'
import { alterPlanByRate } from '../../../utils/alterPlanComparisonByRate'
import FilterComponent from './FilterComponent'
import FilterComparisonModal from './FilterComparisonModal'
import { AppState } from '../../../redux/reducers/RootReducer'

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  comparisonData: selectComparisonData(state),
  updateComparison: selectUpdateComparison(state),
  comparisonLoaded: selectComparisonLoaded(state),
})

const mapDispatchToProps = {
  onGetPlanAllowAccess: GetPlanItemAllowAccess,
  editComparisonName: EditComparisonName,
  getPlanComparisonData: GetPlanComparisonData,
  onChangeBasePlan: ChangeBasePlanComparison,
}

interface MatchParams {
  id: string
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ComparisonOutput: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const [percentageChange, setPercentageChange] = useState(true)
  const [kpiSelected, setKPISelected] = useState('spend')
  const [visibleKPIs, setVisibleKPIs] = useState<ComparisonKPIs[]>([])
  const [kpiIndex, setKPIIndex] = useState([0, 1])
  const [showKPIModal, setShowKPIModal] = useState(false)
  const [showFilterLevel, setShowFilterLevel] = useState<string | undefined>()
  const [shownCurrency, setShownCurrency] = useState('')
  const [displayedPlanData, setDisplayedPlanData] = useState<ComparisonPlan[]>(
    []
  )
  const [currencyRates, setCurrencyRates] = useState<number[]>([])
  const [rateIndex, setRateIndex] = useState(0)
  const [filters, setFilters] = useState<ComparisonFilters>({})
  const [detailedDownloadData, setDetailedDownloadData] = useState<
    string[][][]
  >([])
  const [headers, setHeaders] = useState<string[]>([])
  const [groupingLevels, setGroupingLevels] = useState<string[]>([])

  const { settings } = useSelector((state: AppState) => state.settings)

  const togglePercentageChange = () => {
    setPercentageChange(!percentageChange)
  }

  const resetFilters = () => {
    if (props.comparisonData) {
      let newFilters: any = {}
      for (let lev of groupingLevels) {
        newFilters[lev] = []
      }
      setFilters(newFilters)
    }
  }

  useEffect(() => {
    if (props.comparisonData) {
      const newVisibleKPIs = [
        props.comparisonData.kpis[kpiIndex[0]],
        props.comparisonData.kpis[kpiIndex[1]],
      ]
      setVisibleKPIs(newVisibleKPIs)
    }
  }, [kpiIndex])

  useEffect(() => {
    if (props.match.params.id) {
      props.getPlanComparisonData(props.match.params.id)
    }
  }, [])

  useEffect(() => {
    if (props.match.params.id && props.updateComparison) {
      props.getPlanComparisonData(props.match.params.id)
    }
  }, [props.updateComparison])

  useEffect(() => {
    if (props.comparisonData) {
      let newFilters: any = {}
      for (let lev of groupingLevels) {
        newFilters[lev] = []
      }
      setFilters(newFilters)
      //pineapple
      let newHeadersKeys = Object.keys(
        props.comparisonData.plans[0].transformations[0].identifiers
      ).filter((x) => x !== 'region_key')
      let newHeadersLabels = newHeadersKeys.map((x: string) => {
        const pluralName = x + '_name'
        if (settings![pluralName]) {
          return settings![pluralName]
        }
        return x
      })
      setHeaders(newHeadersLabels)
      setGroupingLevels(newHeadersKeys)
      if (props.comparisonData.kpis.length === 1) {
        setVisibleKPIs([props.comparisonData.kpis[kpiIndex[0]]])
      } else {
        setVisibleKPIs([
          props.comparisonData.kpis[kpiIndex[0]],
          props.comparisonData.kpis[kpiIndex[1]],
        ])
      }
      setShowKPIModal(false)
      setShownCurrency(
        props.comparisonData.plans[0].options.exchangeRates.defaultSymbol
      )
      let plans = props.comparisonData.plans
      for (let i = 1; i < plans.length; i++) {
        if (
          plans[i].options.exchangeRates.defaultCurrency !==
          plans[0].options.exchangeRates.defaultCurrency
        ) {
          const newCurrencyRateIndex =
            plans[0].options.exchangeRates.currencies.findIndex(
              (x) => x === plans[i].options.exchangeRates.defaultCurrency
            )
          const newCurrencyRate =
            plans[0].options.exchangeRates.rates[newCurrencyRateIndex]
          plans[i] = alterPlanByRate(
            plans[i],
            newCurrencyRate,
            props.comparisonData.kpis,
            'divide'
          )
        }
      }
      setDisplayedPlanData(plans)
      setCurrencyRates(plans[0].options.exchangeRates.rates)
      let detailedDownloadData = props.comparisonData.plans.map(
        (x) => x.downloadData
      )
      for (let i = 0; i < newHeadersLabels.length; i++) {
        detailedDownloadData[0][0][i] = newHeadersLabels[i]
      }
      setDetailedDownloadData(detailedDownloadData)
    }
  }, [props.comparisonData])

  useEffect(() => {
    if (props.comparisonData) {
      let plans = displayedPlanData
      for (let i = 0; i < plans.length; i++) {
        plans[i] = alterPlanByRate(
          plans[i],
          currencyRates[rateIndex],
          props.comparisonData.kpis,
          'multiply'
        )
      }
      setDisplayedPlanData(plans)
    }
    const newCurrencyRate = currencyRates[rateIndex]
    const newRates = currencyRates.map((x) => x / newCurrencyRate)
    setCurrencyRates(newRates)
  }, [rateIndex])

  useEffect(() => {
    if (showKPIModal) {
      document.body.style.overflow = 'hidden'
    }
    if (!showKPIModal) {
      document.body.style.overflow = 'auto'
    }
  }, [showKPIModal])

  const editComparisonName = (e: any) => {
    const name = e.toString()
    props.editComparisonName(name, props.match.params.id)
  }

  const getAllGroupingLabels = () => {
    if (props.comparisonData) {
      let levels: any = []
      props.comparisonData.plans.map((plan: ComparisonPlan) => {
        for (let level of groupingLevels) {
          const keys = Object.keys(plan[level])
          const labels = Object.values(plan[level]).map((x: any) => x.label)
          keys.map((key, index) => {
            const newLevel = {
              key: key,
              label: labels[index],
              level: level,
            }
            const filtered = levels.filter(
              (x: { key: string; label: string }) =>
                x.key === newLevel.key && x.label === newLevel.label
            )
            if (filtered.length === 0) {
              levels = [...levels, newLevel]
            }
          })
        }
      })
      return levels
    }
    return []
  }

  const getGroupingLabels = (level: string) => {
    if (props.comparisonData) {
      let levels: any = []
      props.comparisonData.plans.map((plan: ComparisonPlan) => {
        const keys = Object.keys(plan[level])
        const labels = Object.values(plan[level]).map((x: any) => x.label)
        keys.map((key, index) => {
          const newLevel = {
            key: key,
            label: labels[index],
          }
          const filtered = levels.filter(
            (x: { key: string; label: string }) =>
              x.key === newLevel.key && x.label === newLevel.label
          )
          if (filtered.length === 0) {
            levels = [...levels, newLevel]
          }
        })
      })
      return levels
    }
    return []
  }

  const calculatePercentageChange = (
    baseNumber: number,
    level: string,
    levKey: string
  ) => {
    if (props.comparisonData) {
      let filteredTransforms = props.comparisonData.plans[0].transformations
      const filterKeys = Object.keys(filters)
      for (let key of filterKeys) {
        if (filters[key].length > 0) {
          filteredTransforms = filteredTransforms.filter((trans) => {
            if (filters[key].indexOf(trans.identifiers[key]) !== -1) {
              return trans
            }
          })
        }
      }
      const levelTransforms = filteredTransforms.filter(
        (trans) => trans.identifiers[level] === levKey
      )
      const levelTotal = getLevelTotal(levelTransforms)

      let change = baseNumber - levelTotal
      let percentageChange = levelTotal !== 0 ? (change / levelTotal) * 100 : 0
      return Math.round(percentageChange)
    }
    return 0
  }

  const changeBasePlan = (index: number) => {
    if (props.comparisonData) {
      const indexes = props.comparisonData.plans.map((x) => x.id)
      const indexOrder = indexes.findIndex((x) => x === index)
      indexes.splice(indexOrder, 1)
      indexes.unshift(index)
      props.onChangeBasePlan(props.match.params.id, indexes)
    }
  }

  const nextKPI = () => {
    if (props.comparisonData) {
      if (kpiIndex[1] === props.comparisonData.kpis.length - 1) {
        setKPIIndex([props.comparisonData.kpis.length - 1, 0])
      } else if (kpiIndex[0] === props.comparisonData.kpis.length - 1) {
        setKPIIndex([0, 1])
      } else {
        setKPIIndex([kpiIndex[0] + 1, kpiIndex[1] + 1])
      }
    }
  }

  const handleChangeCurrency = (currency: string) => {
    setShownCurrency(currency)
    if (props.comparisonData) {
      const newCurrencyRateIndex =
        props.comparisonData.plans[0].options.exchangeRates.currencySymbols.findIndex(
          (x) => x === currency
        )
      setRateIndex(newCurrencyRateIndex)
    }
  }

  const prevKPI = () => {
    if (props.comparisonData) {
      if (kpiIndex[0] === 0) {
        setKPIIndex([props.comparisonData.kpis.length - 1, 0])
      } else if (kpiIndex[1] === 0) {
        setKPIIndex([
          props.comparisonData.kpis.length - 2,
          props.comparisonData.kpis.length - 1,
        ])
      } else {
        setKPIIndex([kpiIndex[0] - 1, kpiIndex[1] - 1])
      }
    }
  }

  const addFilter = (level: string, key: string) => {
    if (filters[level].indexOf(key) === -1) {
      const newFilters = { ...filters, [level]: [...filters[level], key] }
      setFilters(newFilters)
    } else {
      const index = filters[level].indexOf(key)
      let removeIndexCopy = [...filters[level]]
      removeIndexCopy.splice(index, 1)
      const newFilters = { ...filters, [level]: removeIndexCopy }
      setFilters(newFilters)
    }
  }

  const kpiModalToggle = () => {
    setShowKPIModal(!showKPIModal)
  }

  const filterModalToggle = (level?: string) => {
    if (level) {
      setShowFilterLevel(level)
    } else {
      setShowFilterLevel(undefined)
    }
  }

  const updateFilters = (selectedOptions: string[], level: string) => {
    setFilters({
      ...filters,
      [level]: selectedOptions,
    })
    setShowFilterLevel(undefined)
  }

  const getLevelTotal = (transforms: any[]) => {
    let levelTotal = 0
    if (props.comparisonData) {
      const kpiIndex =
        props.comparisonData.plans[0].options.kpiDisplay.kpi.indexOf(
          kpiSelected
        )
      if (kpiSelected === 'spend') {
        levelTotal = transforms.reduce((acc, transform) => {
          return acc + transform.calculated_spend
        }, 0)
      } else if (kpiIndex !== -1) {
        const kpiFunction =
          props.comparisonData.plans[0].options.kpiDisplay.function[kpiIndex]
        const kpiParams =
          props.comparisonData.plans[0].options.kpiDisplay.parameters[kpiIndex]
        if (kpiFunction === 'divide') {
          const numeratorKey = kpiParams[0]
          const denominatorKey = kpiParams[1]
          let numerator =
            numeratorKey === 'spend'
              ? transforms.reduce((acc, transform) => {
                  return acc + transform.calculated_spend
                }, 0)
              : transforms.reduce((acc, transform) => {
                  return acc + transform.calculated_totals[numeratorKey]
                }, 0)
          let denominator =
            denominatorKey === 'spend'
              ? transforms.reduce((acc, transform) => {
                  return acc + transform.calculated_spend
                }, 0)
              : transforms.reduce((acc, transform) => {
                  return acc + transform.calculated_totals[denominatorKey]
                }, 0)
          levelTotal = denominator === 0 ? 0 : numerator / denominator
        }
      } else {
        levelTotal = transforms.reduce((acc, transform) => {
          return acc + transform.calculated_totals[kpiSelected]
        }, 0)
      }
    }

    return levelTotal
  }

  if (!props.comparisonLoaded) {
    return <LoadingModal />
  }

  const tooltipOverlayInnerStyle = {
    backgroundColor: '#000000',
    color: '#FCFCFC',
    border: '2px',
    fontSize: '14px',
    borderColor: '#000000',
  }

  return (
    <div>
      {showKPIModal && props.comparisonData && (
        <ChangeComparisonKPIModal
          kpiModalToggle={kpiModalToggle}
          kpis={props.comparisonData.kpis}
          allKPIs={props.comparisonData.allKPIs}
          planId={props.comparisonData.plans[0].id}
          comparisonId={props.match.params.id}
        />
      )}
      {showFilterLevel && props.comparisonData && (
        <FilterComparisonModal
          updateFilters={updateFilters}
          filters={filters}
          level={showFilterLevel}
          allLevelOptions={getAllGroupingLabels()}
          showFilterModal={filterModalToggle}
          headers={headers}
        />
      )}
      {props.comparisonData && (
        <div className="w-full flex flex-col space-y-8 mb-10">
          <div className="flex flex-row mb-4">
            <EditableAttribute
              showEditButton={true}
              onSave={editComparisonName}
              defaultValue={props.comparisonData?.name || ''}
              className="text-xl text-gray-500 mb-6"
            >
              <div className="flex flex-1 flex-row justify-start">
                <span className="text-xl">{props.comparisonData?.name}</span>
              </div>
            </EditableAttribute>
            <div className="flex flex-1 flex-row space-x-4 justify-end">
              <button
                onClick={kpiModalToggle}
                className="rounded bg-gtPink text-white text-xs h-7 px-8"
              >
                Edit KPIs
              </button>
              <PlanComparisonsActionsMenu
                fromOutputPage={true}
                id={props.match.params.id}
                name={props.comparisonData.name}
                detailedData={detailedDownloadData}
              />
            </div>
          </div>
          {props.comparisonData && (
            <FilterComponent
              filters={filters}
              resetFilters={resetFilters}
              headers={headers}
              allLevelOptions={getAllGroupingLabels()}
              showFilterLevel={filterModalToggle}
            />
          )}
          <div className=" flex flex-row w-full">
            <div className="text-gray-500 text-md w-7/12 mt-4 cursor-default caret-transparent">
              High Level Impact
            </div>
            <div className="mt-3 mr-4">
              <GtiToggleButton
                value={percentageChange}
                onToggle={togglePercentageChange}
                label="Percent Change"
              />
            </div>
            {props.comparisonData &&
              props.comparisonData.plans[0].options.exchangeRates
                .currencySymbols.length > 1 && (
                <select
                  onChange={(e) => handleChangeCurrency(e.target.value)}
                  value={shownCurrency}
                  className="mr-4 text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none"
                >
                  {props.comparisonData.plans[0].options.exchangeRates.currencySymbols.map(
                    (output: string) => (
                      <option key={output} value={output}>
                        {output}
                      </option>
                    )
                  )}
                </select>
              )}
            <select
              onChange={(e) => {
                setKPISelected(e.target.value)
              }}
              className="float-right text-xxs border border-gray-300 rounded focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none"
              value={kpiSelected}
            >
              <option value={'spend'}>Spend</option>
              {props.comparisonData.kpis.map((kpi: ComparisonKPIs) => {
                return (
                  <option
                    key={kpi.key}
                    value={kpi.label.toLowerCase().replace(/ /g, '_')}
                  >
                    {kpi.label}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      )}
      <div className="flex flex-row w-full">
        <div className="w-1/4" />
        {props.comparisonData &&
          displayedPlanData.map((plan, index: number) => {
            const allowAccess = props.user
              ? plan.created_by === props.user.id
              : false
            const SPOroute = UrlAssembler()
              .template(ROUTES.SPO.PLAN_ITEM_FROM_COMPARISON)
              .param('id', plan.id.toString())
              .param('comparisonId', props.match.params.id)
              .toString()
            return !allowAccess ? (
              <Tooltip
                key={index}
                placement="top"
                trigger={['hover']}
                overlay={'You are not the owner of this plan'}
                overlayInnerStyle={tooltipOverlayInnerStyle}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
              >
                <a
                  href={allowAccess ? SPOroute : '#'}
                  className="text-gtPink text-center px-5 w-1/4"
                >
                  {plan.name ? plan.name : 'Comparison ' + plan.id}
                </a>
              </Tooltip>
            ) : (
              <a
                key={index}
                href={allowAccess ? SPOroute : '#'}
                className="text-gtPink text-center px-5 w-1/4"
              >
                {plan.name ? plan.name : 'Comparison ' + plan.id}
              </a>
            )
          })}
        {props.comparisonData && props.comparisonData.kpis.length > 2 && (
          <div className="cursor-pointer my-auto h-48px w-48px focus:outline-none opacity-0">
            <ChevronRightDefaultIcon className="" />
          </div>
        )}
      </div>
      <div className="flex flex-row w-full mb-4">
        <div className="w-1/4 flex flex-row">
          <div className="w-10/12 opacity-0">x</div>
          {props.comparisonData && props.comparisonData.kpis.length > 2 && (
            <div
              className="cursor-pointer my-auto h-48px w-48px focus:outline-none"
              onClick={prevKPI}
            >
              <ChevronLeftDefaultIcon className="" />
            </div>
          )}
        </div>
        {props.comparisonData &&
          displayedPlanData.map((plan, index: number) => {
            return (
              <div className="flex flex-col w-1/4 px-5" key={index}>
                <div className="bg-pink-700 text-white flex flex-col rounded mb-1">
                  <div className="w-full flex flex-row text-white">
                    <div className="w-9/12 p-2">Total Spend</div>
                    <div className="group inline-block">
                      <button className="outline-none focus:outline-none rounded-sm flex px-2 pt-2 transform rotate-90">
                        <span className="pr-1 flex-1 text-white text-lg font-bold text-center w-full h-full">
                          ...
                        </span>
                      </button>
                      <ul
                        className="bg-white border text-xs text-gray-500 rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
                  transition duration-150 ease-in-out origin-top min-w-32 py-2"
                      >
                        {index !== 0 && (
                          <li>
                            <button
                              onClick={() => changeBasePlan(plan.id)}
                              className="m-2"
                            >
                              Make Base Plan
                            </button>
                          </li>
                        )}
                        <li>
                          <CSVLink
                            data={plan.downloadData}
                            filename={
                              plan.name
                                ? plan.name + '.csv'
                                : plan.id + 'Comparison.csv'
                            }
                          >
                            {' '}
                            <button className="m-2">Download Plan</button>
                          </CSVLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    style={{
                      color: '#ffffff',
                      padding: '5px',
                      fontWeight: 'bold',
                      fontSize: 'x-large',
                    }}
                  >
                    {appendUnit(
                      Math.round(plan.total.spend * 100) / 100,
                      'currency',
                      shownCurrency,
                      ''
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full">
                  {props.comparisonData &&
                    visibleKPIs.map((kpi: ComparisonKPIs, index: number) => {
                      return (
                        <div
                          className={`${
                            index === 0 && 'mr-1'
                          } flex flex-col w-1/2 bg-gtPink rounded space-y-2 px-4"`}
                          key={kpi.key}
                        >
                          <div className="text-white p-2">{kpi.label}</div>
                          <div className="text-white p-2 text-sm">
                            {appendUnit(
                              Math.round(
                                plan.total.kpis[
                                  kpi.label.toLowerCase().replace(/ /g, '_')
                                ] * 100
                              ) / 100,
                              kpi.unit,
                              shownCurrency,
                              ''
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
        {props.comparisonData && props.comparisonData.kpis.length > 2 && (
          <div
            className="cursor-pointer my-auto h-48px w-48px focus:outline-none"
            onClick={nextKPI}
          >
            <ChevronRightDefaultIcon className="" />
          </div>
        )}
      </div>
      <hr className="mb-2" />
      {props.comparisonData && (
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/4">
            {groupingLevels.map((level: string, index: number) => {
              return (
                <div key={index} className="mb-6">
                  <div className="text-gray-500 text-md h-10 caret-transparent cursor-default">
                    {headers[index] ? headers[index] + ' Allocation' : ''}
                  </div>
                  {filters[level] &&
                    getGroupingLabels(level).map(
                      (lev: { key: string; label: string }) => {
                        return (
                          <div
                            onClick={() => addFilter(level, lev.key)}
                            className={`h-10 p-2 ${
                              filters[level].indexOf(lev.key) === -1
                                ? 'text-gray-500'
                                : 'text-gtPink font-bold'
                            }  cursor-pointer caret-transparent`}
                            key={lev.key}
                          >
                            {lev.label}
                          </div>
                        )
                      }
                    )}
                </div>
              )
            })}
          </div>
          {displayedPlanData.map((plan, index: number) => {
            let filteredTransforms = plan.transformations
            const filterKeys = Object.keys(filters)
            for (let key of filterKeys) {
              if (filters[key].length > 0) {
                filteredTransforms = filteredTransforms.filter((trans) => {
                  if (filters[key].indexOf(trans.identifiers[key]) !== -1) {
                    return trans
                  }
                })
              }
            }
            return (
              <div key={index} className="flex flex-col w-1/4">
                {groupingLevels.map((level) => {
                  return (
                    <div key={level} className="flex flex-col mb-6">
                      <h3 className="h-10" style={{ opacity: '0' }}>
                        Value
                      </h3>
                      {getGroupingLabels(level).map(
                        (lev: { key: string; label: string }) => {
                          const levelTransforms = filteredTransforms.filter(
                            (trans) => trans.identifiers[level] === lev.key
                          )
                          const levelTotal = getLevelTotal(levelTransforms)

                          const percentChangeValue: number =
                            calculatePercentageChange(
                              levelTotal,
                              level,
                              lev.key
                            )
                          const displayAmount =
                            levelTotal > 100
                              ? Math.round(levelTotal).toLocaleString()
                              : (
                                  Math.round(levelTotal * 100) / 100
                                ).toLocaleString()
                          const symbol =
                            kpiSelected === 'spend'
                              ? shownCurrency
                              : visibleKPIs.find(
                                  (x) =>
                                    x.label.toLowerCase().replace(/ /g, '_') ===
                                    kpiSelected
                                )?.unit === 'currency'
                              ? shownCurrency
                              : ''
                          return (
                            <div
                              className="flex flex-row h-10 bg-white border-gtGray border-2 mx-2"
                              key={lev.key}
                            >
                              <div className="w-full p-2">
                                <div className="text-gray-500 font-bold">
                                  {filteredTransforms.length > 0
                                    ? symbol + displayAmount
                                    : symbol + '0'}
                                </div>
                              </div>
                              <div>
                                {index !== 0 && percentageChange ? (
                                  <div
                                    className={
                                      percentChangeValue >= 0
                                        ? 'text-green-400 p-2'
                                        : 'text-gtPink p-2'
                                    }
                                  >
                                    {percentChangeValue > 0
                                      ? '+' + percentChangeValue + '%'
                                      : percentChangeValue + '%'}
                                  </div>
                                ) : index !== 0 && percentChangeValue > 0 ? (
                                  <UpArrowIcon className="w-5 m-auto mr-2" />
                                ) : index !== 0 && percentChangeValue < 0 ? (
                                  <DownArrowIcon className="w-5 m-auto mr-2" />
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default connector(MainLayout(ComparisonOutput))
