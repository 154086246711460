import { IModule, IUser, IGroup, IFeatureFlag } from '../../types'
import {
  GET_GROUPS_SUCCESS,
  GET_USERS_SUCCESS,
  SESSION_CREATE_ATTEMPT,
  SESSION_CREATE_FAIL,
  SESSION_CREATE_SUCCESS,
  SESSION_DESTROY_ATTEMPT,
  SESSION_DESTROY_SUCCESS,
  SESSION_RECOVER_ATTEMPT,
  SESSION_RECOVER_FAIL,
  SESSION_RECOVER_SUCCESS,
  SessionActionTypes,
  UPDATE_PASSWORD_FAIL,
} from '../actions/SessionActionTypes'
import { RootStore } from './Store'

interface IDefaultState {
  loadingSession: boolean
  isAuthed: boolean
  user: IUser | null
  users: IUser[] | null
  modules: IModule[]
  hasErrors: boolean
  errorMessage: string
  groups: IGroup[] | null
  updatePasswordError: string
  featureFlags: IFeatureFlag[]
}

const defaultState: IDefaultState = {
  loadingSession: true,
  isAuthed: false,
  user: null,
  users: null,
  modules: [],
  hasErrors: false,
  errorMessage: '',
  groups: [],
  updatePasswordError: '',
  featureFlags: [],
}

const sessionReducer = (
  state: IDefaultState = defaultState,
  action: SessionActionTypes
): IDefaultState => {
  switch (action.type) {
    case SESSION_CREATE_ATTEMPT:
      return { ...state, hasErrors: false }
    case SESSION_RECOVER_SUCCESS:
    case SESSION_CREATE_SUCCESS:
      return {
        ...state,
        isAuthed: true,
        user: action.payload.user,
        modules: action.payload.modules,
        loadingSession: false,
        featureFlags: action.payload.featureFlags,
      }
    case SESSION_CREATE_FAIL:
      return {
        ...state,
        hasErrors: true,
        errorMessage: action.payload,
        loadingSession: false,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }
    case SESSION_CREATE_FAIL:
      return { ...state, hasErrors: true, errorMessage: action.payload }
    case SESSION_DESTROY_ATTEMPT:
      return { ...state }
    case SESSION_DESTROY_SUCCESS:
      return defaultState
    case SESSION_RECOVER_ATTEMPT:
      return { ...state, loadingSession: true }
    case SESSION_RECOVER_FAIL:
      return { ...state, loadingSession: false }
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updatePasswordError: 'Password is incorrect. Please try again',
      }
    default:
      return state
  }
}

export const selectSessionIsAuthed = (state: RootStore) => {
  return state.session.isAuthed
}
export const selectSessionHasError = (state: RootStore) => {
  return state.session.hasErrors
}

export const selectSessionErrorMessage = (state: RootStore) => {
  return state.session.errorMessage
}

export const selectSessionUser = (state: RootStore) => {
  return state.session.user
}

export const selectSessionAllUsers = (state: RootStore) => {
  return state.session.users
}

export const selectSessionAllGroups = (state: RootStore) => {
  return state.session.groups
}

export const selectSessionModule = (state: RootStore) => {
  return state.session.modules
}

export const selectUpdatePasswordError = (state: RootStore) => {
  return state.session.updatePasswordError
}

export const selectSessionFeatureFlags = (state: RootStore) => {
  return state.session.featureFlags
}

export default sessionReducer
