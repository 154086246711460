import React, { Component, FC, useEffect, useState } from 'react'
import appendUnit from '../../../utils/appendUnit'
// import { BudgetSlider } from "../../containers/ScenarioPlanner/NewScene/ScenarioViewer/BudgetSlider";
import EditableAttribute from '../../../utils/EditableAttribute'
import * as _ from 'lodash'
import { CSVLink } from 'react-csv'
import { RootStore } from '../../../redux/reducers/Store'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import {
  Constraint,
  GroupingConstraintInput,
  GroupingConstraintModal,
  GroupingLevel,
  GroupingLevelValue,
} from '../../../types/PlanTypes'
import { CreateCurrency } from '../../../types/CreatePlanTypes'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { Range } from 'rc-slider'
import { selectPlanItem } from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { EditSPOConstraints } from '../../../socket.io'
import { getGroupingLevels } from '../../../utils/getPlanGroupingLevels'
import {
  getConstraintsError,
  getConstraintsLoading,
  getConstraintsSuccess,
} from '../../../redux/reducers/ConstraintsReducer'
import Slider from 'rc-slider/lib/Slider'
import { GetPlanItem } from '../../../redux/actions/PlanActions'
import LoadingModal from '../../../shared/plan-view/LoadingModal'
import { AppState } from '../../../redux/reducers/RootReducer'

type setModalFunction = (a: boolean) => void
interface OwnProps {
  name: string
  setModal: setModalFunction
  inChannel: boolean
}

type Props = OwnProps

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  plan: selectPlanItem(state),
  error: getConstraintsError(state),
  loading: getConstraintsLoading(state),
  success: getConstraintsSuccess(state),
})

const mapDispatchToProps = {
  onGetPlan: GetPlanItem,
  editSPOConstraints: EditSPOConstraints,
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ChangeGroupingLevelsModal: FC<PropsFromRedux & Props> = (props) => {
  const [groupingLevelBudgets, setGroupingLevelBudgets] = useState<
    GroupingConstraintModal[]
  >([])
  const [data, setData] = useState<any[][]>([[]])
  const [file, setFile] = useState<any[]>([])
  const [levelSpend, setLevelSpend] = useState(0)
  const [selectedLevelAbove, setSelectedLevelAbove] = useState('')
  const [error, setError] = useState<string | null>(null)

  const groupingLevels: GroupingLevel[] = getGroupingLevels(props.plan)
  const allLevelKeys = groupingLevels.map((x) => x.key)
  const currentLevelIndex = allLevelKeys.indexOf(props.name.toLowerCase())

  const { settings } = useSelector((state: AppState) => state.settings)

  let levelAbove = ''
  if (currentLevelIndex !== 0) {
    levelAbove = allLevelKeys[currentLevelIndex - 1]
  }

  useEffect(() => {
    setError(props.error)
  }, [props.error])

  useEffect(() => {
    if (props.success) {
      props.setModal(false)
      props.onGetPlan(props.plan.id)
    }
  }, [props.success])

  useEffect(() => {
    const levelsAbove: string[] = getInputOptions()

    const budgets = createBudgets(levelsAbove)

    const levelSpend = budgets.reduce((acc: any, val: any) => {
      return acc + val.budget
    }, 0)

    setGroupingLevelBudgets(budgets)
    setSelectedLevelAbove(levelsAbove[0])
    setLevelSpend(levelSpend)
  }, [])

  const getInputOptions = () => {
    let inputOptions: string[] = []

    let selections = _.uniqBy(
      props.plan.transformations.map((x: any) => x.identifiers[levelAbove]),
      function (e) {
        return e
      }
    )

    inputOptions = selections.map((y) => {
      return groupingLevels
        .find((x: GroupingLevel) => x.key === levelAbove)
        ?.values.find((v: GroupingLevelValue) => v.key === y)?.label!
    })
    return inputOptions
  }

  const uploadConstraints = () => {
    if (!file) {
      return
    }
    let constraints = groupingLevelBudgets
    for (let i in file) {
      const newConstraintBar = constraints.find((x) => {
        if (
          x.label === file[i][1] &&
          x.input.selectedLevelAbove ===
            file[i][0].toLowerCase().replace(/ /g, '_')
        ) {
          return x
        }
      })
      if (newConstraintBar) {
        newConstraintBar.input.constraintType = file[i][2]
        if (file[i][2] !== 'Range') {
          newConstraintBar.input.value = file[i][3]
        } else {
          newConstraintBar.input.rangeValues = [file[i][3], file[i][4]]
        }
      }
    }
    setGroupingLevelBudgets([...constraints])
  }

  const onChange = (e: any) => {
    const reader = new FileReader()
    reader.onload = onReaderLoad
    reader.readAsText(e.target.files[0])
  }

  const onReaderLoad = (e: any) => {
    const data = e.target.result.split('\n')
    const check = data
      .map((x: string) => x.split(','))[0]
      .map((y: string) => y.replace(/"/g, ''))

    if (check[2] && check[2] === 'Function') {
      const removeHeader = data
        .slice(1, data.length)
        .map((x: string) => x.split(','))
      const formatArray = removeHeader.map((x: string[]) =>
        x.map((y) => y.replace(/"/g, ''))
      )
      const parsed = formatArray.map((x: any) => {
        x[3] = isNaN(parseInt(x[3])) ? null : parseInt(x[3])
        x[4] = isNaN(parseInt(x[4])) ? null : parseInt(x[4])
        return x
      })

      const filteredZeroConstraints = parsed.filter((x: any) => {
        if (x[3] !== null || x[2] === 'None') {
          return x
        }
      })
      setFile(filteredZeroConstraints)
    }
  }

  const downLoadCSV = () => {
    if (!props.plan.config) {
      return false
    }
    const allLevelLabels = allLevelKeys.map((x) => {
      const name = x + '_name'
      const label = settings![name] ? settings![name] : x
      return label
    })

    //pineapple
    let headers = [
      allLevelLabels[currentLevelIndex - 1],
      allLevelLabels[currentLevelIndex],
      'Function',
      'Value',
    ]

    if (currentLevelIndex !== 0) {
      const levelAboveName = levelAbove + '_name'
      const levelAboveLabel = settings![levelAboveName]
        ? settings![levelAboveName]
        : levelAboveName

      const allLevelsName = allLevelKeys[currentLevelIndex] + '_name'
      const allLevelsLabel = settings![allLevelsName]
        ? settings![allLevelsName]
        : allLevelsName
      headers = [levelAboveLabel, allLevelsLabel, 'Function', 'Value']
    }

    if (props.inChannel) {
      headers = [
        allLevelKeys[currentLevelIndex - 1],
        'Partner',
        'Function',
        'Value',
      ]
    }

    const parentLevels: string[] = _.uniqBy(
      props.plan.transformations.map((x: any) => x.identifiers[levelAbove]),
      function (e) {
        return e
      }
    )
    let data: any = [headers]

    for (let i in parentLevels) {
      const filteredTransforms = props.plan.transformations.filter(
        (x: any) =>
          x.identifiers[levelAbove] ===
          parentLevels[i].toLowerCase().replace(/ /g, '_')
      )
      const levels = _.uniqBy(
        filteredTransforms.map(
          (x: any) => x.identifiers[props.name.toLowerCase().replace(/ /g, '_')]
        ),
        function (e) {
          return e
        }
      )
      const levelLabels = levels.map((x) => {
        return groupingLevels
          .find(
            (y: GroupingLevel) =>
              y.key === props.name.toLowerCase().replace(/ /g, '_')
          )
          ?.values.find((b: GroupingLevelValue) => b.key === x)?.label
      })

      for (let l in levelLabels) {
        const parentValues = groupingLevels.find(
          (x: GroupingLevel) => x.key === levelAbove
        )?.values
        const parentLabel = parentValues?.find(
          (x: GroupingLevelValue) => x.key === parentLevels[i]
        )?.label

        data = [...data, [parentLabel, levelLabels[l], 'Equal', null]]
      }
    }

    for (let i in data) {
      for (let r in groupingLevelBudgets) {
        if (
          data[i][1] === groupingLevelBudgets[r].label &&
          data[i][0].toLowerCase().replace(/ /g, '_') ===
            groupingLevelBudgets[r].input.selectedLevelAbove &&
          groupingLevelBudgets[r].input.constraintType !== 'None'
        ) {
          data[i][2] = groupingLevelBudgets[r].input.constraintType
          if (groupingLevelBudgets[r].input.constraintType === 'Range') {
            data[i][3] = Math.round(
              groupingLevelBudgets[r].input.rangeValues[0]
            )
            data[i][4] = Math.round(
              groupingLevelBudgets[r].input.rangeValues[1]
            )
          } else {
            data[i][3] = groupingLevelBudgets[r].input.value
          }
        }
      }
    }

    setData(data)

    return true
  }

  const changeGroupingLevelSpend = (
    groupingLevels: GroupingConstraintInput[],
    currency: CreateCurrency,
    name: string
  ) => {
    const levelKey = name.toLowerCase()

    //create new constraints in an array
    let newConstraints: any = []
    for (let i in groupingLevels) {
      if (groupingLevels[i].constraintType !== 'Range') {
        let valueArray = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray[0] = groupingLevels[i].value
        const constraint = {
          identifiers: {
            [levelAbove]: groupingLevels[i].selectedLevelAbove,
            [levelKey]: groupingLevels[i].key,
          },
          values: valueArray,
          variable: 'spend',
          fn: groupingLevels[i].constraintType.toLowerCase(),
        }
        newConstraints.push(constraint)
      }
      //if it's a range, push a min and a max constraint
      if (groupingLevels[i].constraintType === 'Range') {
        let valueArray = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray[0] = groupingLevels[i].rangeValues[0]
        const constraint = {
          identifiers: {
            [levelAbove]: groupingLevels[i].selectedLevelAbove,
            [levelKey]: groupingLevels[i].key,
          },
          values: valueArray,
          variable: 'spend',
          fn: 'min',
        }
        newConstraints.push(constraint)

        let valueArray2 = Array(
          props.plan.transformations[0].inputs.spend.length + 1
        ).fill(null)
        valueArray2[0] = groupingLevels[i].rangeValues[1]
        const constraintMax = {
          identifiers: {
            [levelAbove]: groupingLevels[i].selectedLevelAbove,
            [levelKey]: groupingLevels[i].key,
          },
          values: valueArray2,
          variable: 'spend',
          fn: 'max',
        }
        newConstraints.push(constraintMax)
      }
    }

    const putData = {
      id: props.plan.id,
      scenario: {
        ...props.plan,
      },
    }

    newConstraints = newConstraints.filter((x: any) => x.fn !== 'none')

    //wipe all grouping level constraints that have same identifiers

    let filteredConstraints = putData.scenario.constraints.filter(
      (constr: any) => {
        const keys = Object.keys(constr.identifiers)
        if (
          keys.length === 2 &&
          (keys.indexOf(levelKey) === -1 || keys.indexOf(levelAbove) === -1)
        ) {
          return constr
        } else if (keys.length === allLevelKeys.length || keys.length === 1) {
          return constr
        }
      }
    )

    for (let constr of newConstraints) {
      filteredConstraints = [...filteredConstraints, constr]
    }

    putData.scenario.constraints = filteredConstraints

    putData.scenario.user_input.defaultCurrency = currency

    //turn carryover and halos back on
    putData.scenario.carryover = true
    putData.scenario.parentChild = true

    if (props.user) {
      props.editSPOConstraints(
        putData.scenario,
        putData.scenario.id,
        props.user
      )
    }
  }

  const closeModal = () => {
    if (error) {
      const defaultCurrency = getSelectedCurrency()
      changeGroupingLevelSpend([], defaultCurrency, props.name)
    }
    setError(null)
    props.setModal(false)
  }

  const submit = () => {
    const defaultCurrency = getSelectedCurrency()

    const submitBudgets = groupingLevelBudgets.map((group) => group.input)

    changeGroupingLevelSpend(submitBudgets, defaultCurrency, props.name)
  }

  const getSelectedCurrency = () => {
    const index = props.plan.options.exchangeRates.currencySymbols.indexOf(
      props.plan.options.exchangeRates.defaultSymbol
    )

    const overallConstraintCurrencyDivisor =
      props.plan.options.exchangeRates.rates[index]

    const defaultCurrency = props.plan.options.exchangeRates.currencies[index]

    const defaultCurrencySymbol =
      props.plan.options.exchangeRates.currencySymbols[index]

    const selectedCurrency = {
      rate: overallConstraintCurrencyDivisor,
      currency: defaultCurrency,
      symbol: defaultCurrencySymbol,
    }

    return selectedCurrency
  }

  const revertBudgetsToOptimal = () => {
    setGroupingLevelBudgets([])
    const defaultCurrency = getSelectedCurrency()
    changeGroupingLevelSpend([], defaultCurrency, props.name)
  }

  const handleChangeConstraintType = (
    transform: GroupingConstraintModal,
    e: any
  ) => {
    const stateBudgets = _.cloneDeep(groupingLevelBudgets)
    stateBudgets.map((budget) => {
      if (
        budget.label === transform.label &&
        budget.input.selectedLevelAbove ===
          selectedLevelAbove.toLowerCase().replace(/ /g, '_')
      ) {
        budget.input.constraintType = e.target.value
      }
      if (
        budget.input.constraintType === 'Range' &&
        budget.label === transform.label &&
        budget.input.selectedLevelAbove ===
          selectedLevelAbove.toLowerCase().replace(/ /g, '_')
      ) {
        budget.input.rangeValues = [0, budget.input.value]
      }
      return budget
    })

    //if the constraint type was range, need to change value back from an array
    for (let bud of stateBudgets) {
      if (
        bud.input.constraintType !== 'Range' &&
        Array.isArray(bud.input.value)
      ) {
        bud.input.value = bud.input.value[1]
      }
    }

    setGroupingLevelBudgets(stateBudgets)
  }

  const createBudgets = (selectedLevelAbove: string[]) => {
    let result: GroupingConstraintModal[] = []
    if (!props.plan) {
      return []
    }

    for (let i in selectedLevelAbove) {
      const gLevel = props.plan.transformations.filter(
        (transf: any) =>
          transf.identifiers[levelAbove] ===
          selectedLevelAbove[i].toLowerCase().replace(/ /g, '_')
      )

      const uniqueLevels: string[] = _.uniqBy(
        gLevel.map((level: any) => level.identifiers[props.name.toLowerCase()]),
        function (e) {
          return e
        }
      )
      uniqueLevels.forEach((uniqueLevel) => {
        let groupingLevelBudgetTransform = {
          label: groupingLevels
            ?.find((group: GroupingLevel) => group.key === props.name)
            ?.values.find((val: GroupingLevelValue) => val.key === uniqueLevel)
            ?.label!,
          budget: getBudgetForGroupingLevel(uniqueLevel, selectedLevelAbove[i]),
          color: '#E10D6D',
          input: {
            value: getBudgetForGroupingLevel(
              uniqueLevel,
              selectedLevelAbove[i]
            ),
            rangeValues: [],
            key: uniqueLevel,
            constraintType: 'None',
            selectedLevelAbove: selectedLevelAbove[i]
              .toLowerCase()
              .replace(/ /g, '_'),
          },
        }
        result = [...result, groupingLevelBudgetTransform]
      })
    }

    //check to see if any current constraints and adjust the types
    for (let level of result) {
      let howManyMatches = 0
      for (let con of props.plan.constraints) {
        if (
          Object.keys(con.identifiers).length === 2 &&
          Object.keys(con.identifiers).indexOf(levelAbove) !== -1 &&
          Object.keys(con.identifiers).indexOf(props.name.toLowerCase()) !==
            -1 &&
          level.input.key === con.identifiers[props.name.toLowerCase()] &&
          level.input.selectedLevelAbove === con.identifiers[levelAbove]
        ) {
          howManyMatches = howManyMatches + 1

          if (howManyMatches === 1) {
            level.input.value = con.values[0]
            level.input.constraintType =
              con.fn.charAt(0).toUpperCase() + con.fn.slice(1)
          }
          //if there are two matches, that means there is a range constraint
          if (howManyMatches === 2) {
            level.input.constraintType = 'Range'
            level.input.rangeValues = [level.input.value, con.values[0]]
          }
        }
      }
    }

    return result
  }

  const getBudgetForGroupingLevel = (
    groupingLevel: string,
    selected: string
  ) => {
    const matchSelected = selected.toLowerCase().replace(/ /g, '_')

    const budgets = props.plan.transformations
      .filter((transformation: any) => {
        return (
          transformation.identifiers[props.name.toLowerCase()] === groupingLevel
        )
      })
      .filter((transformation: any) => {
        return transformation.identifiers[levelAbove] === matchSelected
      })
      .reduce((prev: any, next: any) => {
        return (prev += next.calculated_spend)
      }, 0)

    return budgets
  }

  const onSliderChange = (newValue: number, input: GroupingConstraintInput) => {
    let newGroupingLevelBudgets = _.cloneDeep(groupingLevelBudgets)

    // isValidRegionSpend(newValue, input.key, this.props.scenario);

    newGroupingLevelBudgets.forEach((budget) => {
      if (
        budget.input.key === input.key &&
        budget.input.selectedLevelAbove ===
          selectedLevelAbove.toLowerCase().replace(/ /g, '_')
      ) {
        budget.input.value = newValue
      }
    })

    setGroupingLevelBudgets(newGroupingLevelBudgets)
  }

  const onRangeChange = (
    newValue: number[],
    input: GroupingConstraintInput,
    max: number
  ) => {
    let newGroupingLevelBudgets = _.cloneDeep(groupingLevelBudgets)

    if (newValue.length !== 0) {
      newGroupingLevelBudgets.forEach((budget) => {
        if (
          budget.input.key === input.key &&
          budget.input.selectedLevelAbove ===
            selectedLevelAbove.toLowerCase().replace(/ /g, '_')
        ) {
          budget.input.rangeValues = newValue
        }
      })

      setGroupingLevelBudgets(newGroupingLevelBudgets)
    }
  }

  const handleChangeOutput = (e: any) => {
    const levelSpend = groupingLevelBudgets
      .filter(
        (x) =>
          x.input.selectedLevelAbove ===
          e.target.value.toLowerCase().replace(/ /g, '_')
      )
      .reduce((acc, val) => {
        return acc + val.budget
      }, 0)

    setSelectedLevelAbove(e.target.value)
    setLevelSpend(levelSpend)
  }

  const editConstraintValue = (e: any, transform: GroupingConstraintModal) => {
    if (transform.input.constraintType === 'Range') {
      let newValue = e.split(':')
      if (
        newValue.length !== 2 ||
        isNaN(parseInt(newValue[0])) ||
        isNaN(parseInt(newValue[1])) ||
        parseInt(newValue[0]) < 0 ||
        parseInt(newValue[1]) < 0 ||
        parseInt(newValue[1]) < parseInt(newValue[0])
      ) {
        return
      }
      newValue[0] = parseInt(newValue[0])
      newValue[1] = parseInt(newValue[1])
      let newGroupingLevelBudgets = _.cloneDeep(groupingLevelBudgets)
      newGroupingLevelBudgets.forEach((budget) => {
        if (
          budget.input.key === transform.input.key &&
          budget.input.selectedLevelAbove ===
            selectedLevelAbove.toLowerCase().replace(/ /g, '_')
        ) {
          budget.input.rangeValues = newValue
        }
      })
      setGroupingLevelBudgets(newGroupingLevelBudgets)
    } else {
      if (isNaN(parseInt(e)) || parseInt(e) < 0) {
        return
      }
      let newValue = parseInt(e)
      onSliderChange(newValue, transform.input)
    }
  }

  const currencyIndex = props.plan.options.exchangeRates.currencies.indexOf(
    props.plan.options.exchangeRates.defaultCurrency
  )
  let currencyIncrement =
    props.plan.options.increment /
    props.plan.options.exchangeRates.rates[currencyIndex]

  if (isNaN(currencyIncrement)) {
    currencyIncrement = props.plan.options.increment
  }

  const inputOptions = getInputOptions()

  const constraintTypes = ['Min', 'Max', 'Equal', 'Range', 'None']

  const templateName = settings?.[props.name + '_name']
    ? settings?.[props.name + '_name'] + 'ConstraintsTemplate.csv'
    : 'ConstraintsTemplate.csv'

  const shownGroupingLevels = groupingLevelBudgets.filter(
    (x) =>
      x.input.selectedLevelAbove ===
      selectedLevelAbove.toLowerCase().replace(/ /g, '_')
  )

  if (props.loading) {
    return <LoadingModal />
  }

  const levelKey = props.name + '_name'
  const levelName = props.inChannel
    ? 'Partner'
    : settings![levelKey]
    ? settings![levelKey]
    : props.name

  return (
    <div>
      <div className="justify-center flex overflow-x-hidden fixed inset-0 z-150 outline-none focus:outline-none max-w-screen-2xl m-auto h-full">
        {settings && (
          <div className="my-auto mx-auto w-10/12 bg-white">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
              <div className="flex flex-col items-start justify-between border-b border-solid border-blueGray-200 rounded-t h-full overflow-y-auto">
                <div className="w-full border">
                  <h1 className="text-gray-500 text-md p-5 uppercase">
                    {'Set ' + levelName + ' Budgets'}
                  </h1>
                </div>
                <div className="p-5 flex flex-row w-full">
                  <div className="flex flex-col w-4/12">
                    <h3 className="text-gray-500 uppercase font-bold">
                      File Upload
                    </h3>
                    <div>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={onChange}
                        className={'w-1/2'}
                      />
                      <button
                        onClick={uploadConstraints}
                        className="clear-left rounded bg-gtPink text-white text-xs py-2 w-24"
                      >
                        Upload
                      </button>
                    </div>
                    <div>
                      <CSVLink
                        data={data}
                        filename={templateName}
                        onClick={downLoadCSV}
                        className={'text-gtPink text-xs'}
                      >
                        Download constraints
                      </CSVLink>
                    </div>
                  </div>
                  <div>
                    <div className={'italic text-xs normal-case'}>
                      To set constraints, first select a constraint type then
                      either shift the bar, or click the constraint value on the
                      right.
                    </div>
                    <div className={'italic text-xs normal-case'}>
                      To set a range constraint, use a colon between the min and
                      max values.
                    </div>
                  </div>
                </div>
                <div className="px-5">
                  <select
                    onChange={handleChangeOutput}
                    value={selectedLevelAbove}
                  >
                    {inputOptions.map((output) => {
                      return (
                        <option key={output} value={output}>
                          {output}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="flex flex-row px-5 pt-4">
                  {error && (
                    <div className={'mt-4 text-gtBlueSecondary'}>{error}</div>
                  )}
                  <div className={'text-xs'}>
                    Current Plan Budget is:{' '}
                    <span className={'font-bold'}>
                      {appendUnit(
                        levelSpend,
                        'currency',
                        props.plan.options.exchangeRates.defaultSymbol,
                        settings.number_format
                      )}
                    </span>
                  </div>
                </div>
                <div className={'flex flex-col px-10 w-full flex-1'}>
                  <div
                    className={'flex flex-row pt-4 pb-2 w-full justify-around'}
                  >
                    <div className="w-1/12" />
                    <h5 className="w-2/12">CONSTRAINT TYPE</h5>
                    <h5 className="w-5/12">SPEND CONSTRAINT</h5>
                    <h5 className="w-2/12 text-right">CONSTRAINT VALUE</h5>
                    <h5 className="w-2/12 text-right mr-1">CURRENT SPEND</h5>
                  </div>
                  {shownGroupingLevels.map((transform, index) => {
                    //if range constraint populate the values as an array
                    return (
                      <div
                        key={index}
                        className="bg-white border-solid border-2 border-#e0e0e0 flex flex-row justify-around"
                      >
                        <div className="w-1/12 my-auto text-center">
                          {transform.label}
                        </div>
                        <div className={'w-2/12'}>
                          <select
                            onChange={(event) =>
                              handleChangeConstraintType(transform, event)
                            }
                            value={transform.input.constraintType}
                            style={{
                              height: '60%',
                              margin: '5% 5%',
                            }}
                            className="text-xs"
                          >
                            {constraintTypes.map((output, index) => {
                              return (
                                <option key={index} value={output}>
                                  {output}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className={'w-7/12'}>
                          <div className={'h-full'}>
                            {transform.input.constraintType === 'Range' && (
                              <div className="media-alloc-channel-budget z-40 items-center flex h-full">
                                <Range
                                  min={0}
                                  max={props.plan.optimised_spend}
                                  trackStyle={[
                                    {
                                      background: '#eb4560',
                                    },
                                  ]}
                                  value={transform.input.rangeValues}
                                  onChange={(e: any) =>
                                    onRangeChange(
                                      e,
                                      transform.input,
                                      props.plan.optimised_spend
                                    )
                                  }
                                />
                              </div>
                            )}
                            {transform.input.constraintType !== 'None' &&
                              transform.input.constraintType !== 'Range' && (
                                <div className="media-alloc-channel-budget z-40 items-center flex h-full">
                                  <Slider
                                    min={0}
                                    value={transform.input.value}
                                    onChange={(e: any) =>
                                      onSliderChange(e, transform.input)
                                    }
                                    max={props.plan.optimised_spend}
                                    trackStyle={{
                                      background: '#eb4560',
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={'w-1/12  text-center m-auto'}>
                          <EditableAttribute
                            height="h-full"
                            defaultValue={
                              transform.input.constraintType === 'Range'
                                ? Math.round(transform.input.rangeValues[0]) +
                                  ': ' +
                                  Math.round(transform.input.rangeValues[1])
                                : transform.input.value
                            }
                            onSave={(e: any) =>
                              editConstraintValue(e, transform)
                            }
                          >
                            <div className="d-flex font-bold h-full">
                              {settings &&
                              transform.input.constraintType === 'Range'
                                ? appendUnit(
                                    Math.round(transform.input.rangeValues[0]),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  ) +
                                  ': ' +
                                  appendUnit(
                                    Math.round(transform.input.rangeValues[1]),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  )
                                : settings &&
                                  transform.input.constraintType !== 'None'
                                ? appendUnit(
                                    Math.round(transform.input.value),
                                    'currency',
                                    props.plan.options.exchangeRates
                                      .defaultSymbol,
                                    settings.number_format
                                  )
                                : null}
                            </div>
                          </EditableAttribute>
                        </div>
                        {settings && (
                          <div className="d-flex w-1/12 font-bold m-auto text-center">
                            {appendUnit(
                              transform.budget,
                              'currency',
                              props.plan.options.exchangeRates.defaultSymbol,
                              settings.number_format
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className="flex flex-row py-5 justify-center align-middle w-full">
                  <button
                    className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 mr-4 uppercase"
                    onClick={revertBudgetsToOptimal}
                  >
                    Delete Constraints
                  </button>
                  <button
                    className="clear-left rounded bg-gtGray text-gtDarkGray text-xs py-4 px-4 uppercase"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>

                  <button
                    className="clear-left rounded bg-gtPink text-white text-xs py-4 ml-4 px-4 uppercase"
                    onClick={submit}
                  >
                    Regenerate Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default connector(ChangeGroupingLevelsModal)
