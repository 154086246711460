import React from 'react'
import Footer from './Footer'
import SideNavbar from './side-navbar/SideNavbar'

export const MainLayout =
  <P extends object>(
    Component: React.ComponentType<P>,
    withoutPaddings?: boolean
  ): React.FC<P> =>
  ({ ...props }) =>
    (
      <div className="bg-gray-100 min-h-screen flex flex-row relative">
        <SideNavbar />
        <div className="w-full flex flex-col flex-1 overflow-hidden">
          {/*content*/}
          <div
            className={`flex-1 h-full ${
              withoutPaddings
                ? 'overflow-x-hidden overflow-y-auto w-full'
                : 'container max-w-6xl mx-auto pt-5 pb-20 overflow-auto'
            }`}
          >
            <Component {...(props as P)} />
          </div>
          {/*end of content*/}
          {/*footer*/}
          <div
            className={`z-20 w-full bg-gray-100 ${
              withoutPaddings ? '' : 'mt-6'
            }`}
          >
            <Footer />
          </div>
          {/*end of footer*/}
        </div>
      </div>
    )
