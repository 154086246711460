import { Typography } from '@gain-theory/typography'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import UrlAssembler from 'url-assembler'
import { DeleteUser, GetUsers } from '../../redux/actions/SessionActions'
import { selectSessionAllUsers } from '../../redux/reducers/SessionReducer'
import { selectSettings } from '../../redux/reducers/SettingsReducer'
import { RootStore } from '../../redux/reducers/Store'
import { ROUTES } from '../../Routes'
import { MainLayout } from '../../shared/MainLayout'
import AdminHeader from './AdminHeader'

const mapState = (state: RootStore) => ({
  users: selectSessionAllUsers(state),
  settings: selectSettings(state),
})
const mapDispatchToProps = {
  onGetUsers: GetUsers,
  deleteUser: DeleteUser,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface OwnProps extends PropsFromRedux {}

const ManageUsers: FC<OwnProps> = (props) => {
  useEffect(() => {
    props.onGetUsers()
  }, [])

  const getUserURL = (id: string) => {
    return UrlAssembler()
      .template(ROUTES.ADMIN.MANAGE_ONE_USER)
      .param('id', id)
      .toString()
  }

  const deleteUser = (e: any) => {
    const isAdmin = props.users?.find(
      (x) => x.id === parseInt(e.target.value)
    )?.admin
    if (isAdmin) {
      alert("Please remove user's admin rights before trying to delete them")
    } else {
      if (window.confirm('Are you sure you want to delete this user?')) {
        props.deleteUser(e.target.value).then(() => props.onGetUsers())
      }
    }
  }
  return (
    <div className={'container'}>
      <AdminHeader></AdminHeader>
      <Typography.H4 className="my-6">Manage Users</Typography.H4>
      {props.users && (
        <table className="min-w-full table-auto mb-96 divide-y divide-gray-300 border rounded text-text-medium-emphasis">
          <thead>
            <tr className="p-2">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Created At
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Okta
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Enabled
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Locked
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Admin
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Edit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {props.users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>
                  {moment
                    .utc(user.created_at)
                    .format(props.settings?.date_format)}
                </td>
                <td>{JSON.stringify(user.okta)}</td>
                <td>{JSON.stringify(user.enabled)}</td>
                <td>{JSON.stringify(user.is_locked)}</td>
                <td>{JSON.stringify(user.admin)}</td>
                <td>
                  <Link
                    className={'text-gtPink'}
                    to={getUserURL(user.id.toString())}
                  >
                    Edit
                  </Link>
                </td>
                <td>
                  <button
                    className="rounded bg-gtPink text-white text-xs px-8 py-2"
                    value={user.id.toString()}
                    onClick={deleteUser}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default connector(MainLayout(ManageUsers))
