import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'

interface IUseIsBetaFeature {
  flagKey: string
}

export function useIsBetaFeature({ flagKey }: IUseIsBetaFeature) {
  const { featureFlags } = useSelector((state: AppState) => state.session)

  const isBetaFeature = useMemo(() => {
    let isBeta: boolean = false

    if (featureFlags && featureFlags.length > 0) {
      const timeConstraintsFeature = featureFlags.find(
        (feature) => feature.key === flagKey
      )

      isBeta = !!timeConstraintsFeature && timeConstraintsFeature.beta
    }

    return isBeta
  }, [featureFlags, flagKey])

  return isBetaFeature
}
