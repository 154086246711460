import React, { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import {
  CopyPlan,
  DeletePlan,
  GetPlanById,
  GetPlanItem,
  GetPlanSummaryList,
  LockPlan,
  PutPlan,
  ToggleSharePlanModal,
} from '../redux/actions/PlanActions'
import { selectSettings } from '../redux/reducers/SettingsReducer'
import { RootStore } from '../redux/reducers/Store'
import { Plan, IPlanSummary } from '../types/PlanTypes'
import { downloadPlanCSVData } from '../utils/downloadPlanCSVData.js'
import { selectPlanBrands, selectPlanItem } from '../redux/reducers/PlanReducer'
import UrlAssembler from 'url-assembler'
import { ROUTES } from '../Routes'
import { useHistory } from 'react-router-dom'
import { AppState } from '../redux/reducers/RootReducer'
import { COST_NAME_IN_CHANNEL } from '../utils/constants'

const mapState = (state: RootStore) => ({
  planItem: selectPlanItem(state),
})
const mapDispatchToProps = {
  onCopyPlan: CopyPlan,
  onLockPlan: LockPlan,
  onDeletePlan: DeletePlan,
  onGetPlanSummaryList: GetPlanSummaryList,
  onShowSharePlanModal: ToggleSharePlanModal,
  onGetPlanById: GetPlanById,
  onGetPlan: GetPlanItem,
  putPlan: PutPlan,
}

const connector = connect(mapState, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface OwnProps extends PropsFromRedux {
  item: IPlanSummary
  fromPlan: boolean
}

type Props = OwnProps

const PlanSummaryListItemActionsMenu: FC<Props> = (props) => {
  const [downloadData, setDownloadData] = useState<string[][]>([[]])
  const [plan, setPlan] = useState<Plan>()
  const [isPlanLocked, setIsPlanLocked] = useState<boolean>(false)

  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)

  const history = useHistory()

  useEffect(() => {
    if (isPlanLocked) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.PLAN_ITEM)
        .param('id', props.item?.id.toString())
        .toString()

      setIsPlanLocked(false)
      history.push(url)
    }
  }, [props.planItem])

  const copyPlan = () => {
    props.onCopyPlan(props.item.id).then(props.onGetPlanSummaryList)
  }

  const lockPlan = () => {
    if (
      window.confirm(
        'Locking a plan is permanent and cannot be undone. Are you sure you want to prevent further changes to this scenario?'
      )
    ) {
      props.onLockPlan(props.item.id).then(() => {
        props.onGetPlanSummaryList()
        props.onGetPlan(props.item.id.toString())
        setIsPlanLocked(true)
      })
    }
  }

  const deletePlan = () => {
    props.onDeletePlan(props.item.id).then(props.onGetPlanSummaryList)
  }

  const sharePlan = () => {
    props.onShowSharePlanModal(props.item.id)
  }

  const revertToOptimal = () => {
    if (plan) {
      const putData = {
        ...plan,
        transformations: plan.transformations.map((transformation) => ({
          ...transformation,
          inputs: {
            spend: [],
          },
        })),
      }

      //turn carryover and halos back on
      putData.carryover = true
      putData.parentChild = true

      //revert the CPMs back to the original config CPMs
      if (
        putData.user_cpms &&
        putData.user_cpms.values &&
        putData.user_cpms.values.length > 0
      ) {
        //get the scenario transforms from the config as they have the input data (and the CPMs)
        const scenarioTransforms = putData.config.transformations.filter(
          (x) => {
            for (let tran of putData.transformations) {
              if (_.isEqual(tran.identifiers, x.identifiers)) {
                return x
              }
            }
          }
        )

        //gets the original CPMs from the config and calculates the average of each result
        const originalCPMs = scenarioTransforms?.map(
          (x) =>
            x.io
              .find((y) => y.key === COST_NAME_IN_CHANNEL)
              ?.values?.slice(
                putData.observations_min,
                putData.observations_max
              )
              ?.reduce((acc, val) => acc + val, 0) ||
            0 / (putData.observations_max - putData.observations_min)
        )
        putData.user_cpms.values = originalCPMs
      }
      // props.putPlan(putData);
    }
  }

  const getDownloadData = () => {
    props.onGetPlanById(props.item.id).then((plan) => {
      setPlan(plan)
      if (plan) {
        const headerNames = Object.keys(plan.grouping_levels)
        setDownloadData(downloadPlanCSVData(plan, headerNames, settings, inChannelSettings))
      }
    })
  }

  // Declare CSS class vars
  const baseClass = 'rounded-sm px-3 py-1 hover:bg-gray-100'
  const disabledClass = 'opacity-75 cursor-not-allowed'
  const actionItemClass = !!props.item.locked_at
    ? `${baseClass} ${disabledClass}`
    : baseClass
  const actionItemButtonClass = !!props.item.locked_at
    ? disabledClass
    : 'w-full text-left'

    const comparePlan = () => {
      const url = UrlAssembler()
          .template(ROUTES.SPO.COMPARISON_CREATE_FLOW)
          .param('baseId', props.item.id.toString())
          .toString()
      history.push(url)
  }

  return (
    <div className="group inline-block ">
      <button
        onMouseEnter={getDownloadData}
        className="outline-none focus:outline-none border px-3 py-1 bg-white rounded-sm flex items-center min-w-32"
      >
        <span className="pr-1 font-semibold text-gray-400 flex-1">Actions</span>
        <span>
          <svg
            className="fill-current h-4 w-4 transform group-hover:-rotate-180
        transition duration-150 ease-in-out text-gtPink"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              className="fill-current"
            />
          </svg>
        </span>
      </button>
      <ul
        className="bg-white border text-xxs text-gray-500 rounded-sm transform scale-0 z-40 group-hover:scale-100 absolute
  transition duration-150 ease-in-out origin-top min-w-32"
      >
        <li className={`${actionItemClass} text-xxs`}>
          <button
            disabled={!!props.item.locked_at}
            onClick={copyPlan}
            className={actionItemButtonClass}
          >
            Copy
          </button>
        </li>
        <li className={actionItemClass}>
          <button
            disabled={!!props.item.locked_at}
            onClick={lockPlan}
            className={actionItemButtonClass}
          >
            {!!props.item.locked_at ? 'Locked' : 'Lock'}
          </button>
        </li>
        <li className={`${actionItemClass} text-xxs`}>
          <button
            disabled={!!props.item.locked_at}
            onClick={comparePlan}
            className={actionItemButtonClass}
          >
            Compare
          </button>
        </li>
        <li className={downloadData[0] && downloadData[0][0] ? `${baseClass} dropdown relative` : `${baseClass} dropdown relative pointer-events-none`}>
          <CSVLink
            data={downloadData}
            filename={plan && plan.name ? plan.name + '.csv' : 'Plan.csv'}
          >
            {' '}
            <button>Download</button>
          </CSVLink>
        </li>
        <li className={actionItemClass}>
          <button
            disabled={!!props.item.locked_at}
            onClick={deletePlan}
            className={actionItemButtonClass}
          >
            Delete
          </button>
        </li>        
        <li className={actionItemClass}>
          <button
            disabled={!!props.item.locked_at}
            onClick={sharePlan}
            className={actionItemButtonClass}
          >
            Share
          </button>
        </li>        
        {props.fromPlan && (
          <li className={actionItemClass}>
            <button disabled={!!props.item.locked_at} onClick={revertToOptimal}>
              Revert to Optimal
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default connector(PlanSummaryListItemActionsMenu)
