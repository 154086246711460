import {
  withRouter,
  Link,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom'
import * as _ from 'lodash'
import UrlAssembler from 'url-assembler'
import { selectSessionUser } from '../../../redux/reducers/SessionReducer'
import { selectPlanSummaryList } from '../../../redux/reducers/PlanReducer'
import { selectSettings } from '../../../redux/reducers/SettingsReducer'
import { connect, ConnectedProps } from 'react-redux'
import { RootStore } from '../../../redux/reducers/Store'
import { FC, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { MainLayout } from '../../../shared/MainLayout'
import appendUnit from '../../../utils/appendUnit'
import { GetPlanSummaryList } from '../../../redux/actions/PlanActions'
import { IPlanSummary, Key } from '../../../types/PlanTypes'
import {
  ComparisonKPIs,
  CreateComparisonAttrs,
} from '../../../types/PlanComparisonTypes'
import { ROUTES } from '../../../Routes'
import { SavePlanComparison } from '../../../redux/actions/PlanComparisonActions'
import { selectCreatedId } from '../../../redux/reducers/PlanComparisonsReducer'

const mapState = (state: RootStore) => ({
  user: selectSessionUser(state),
  plans: selectPlanSummaryList(state),
  settings: selectSettings(state),
  createdId: selectCreatedId(state),
})

const mapDispatchToProps = {
  onGetPlanSummaryList: GetPlanSummaryList,
  savePlanComparison: SavePlanComparison,
}

interface MatchParams {
  baseId: string
}

const connector = connect(mapState, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const CreateComparison: FC<
  PropsFromRedux & RouteComponentProps<MatchParams>
> = (props) => {
  const [page, setPage] = useState(0)
  const [basePlanId, setBasePlanId] = useState<number>(
    0
  )
  const [comparedPlanIds, setComparedPlanIds] = useState<number[]>([])
  const [basePlanWeeks, setBasePlanWeeks] = useState(0)
  const [KPIs, setKPIs] = useState<any[]>([])
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>([])
  const [error, setError] = useState('')
  const [comparisonName, setComparisonName] = useState("New Comparison")
  const history = useHistory()

  useEffect(() => {
    props.onGetPlanSummaryList()
  }, [])

  useEffect(() => {
    if(props.match.params.baseId !== "-1" && props.plans.length > 0){
      const basePlan = props.plans.find((plan: IPlanSummary) => plan.id.toString() === props.match.params.baseId)
      const basePlanWeeks = basePlan!.observations_max + 1 - basePlan!.observations_min - basePlan!.options.carryoverWeeks
      const baseKPIs = basePlan!.output_keys.map((x: Key) => {
        const kpi = {
          key: Object.keys(x)[0],
          label: x[Object.keys(x)[0]],
        }
        return kpi
      })
      setPage(2)
      setKPIs(baseKPIs)
      setBasePlanId(parseInt(props.match.params.baseId))
      setBasePlanWeeks(basePlanWeeks)
    }
  }, [props.plans])

  useEffect(() => {
    if (props.createdId && page !== 0) {
      const url = UrlAssembler()
        .template(ROUTES.SPO.COMPARISONS_OUTPUT)
        .param('id', props.createdId)
        .toString()
      history.push(url)
    }
  }, [props.createdId])

  const nextPage = () => {
    if (page === 1 && basePlanId === 0) {
      setError('Please select a base plan')
      return
    }
    if (page === 2 && comparedPlanIds.length === 0) {
      setError('Please select at least one plan to compare')
      return
    }
    setPage(page + 1)
    setError('')
  }

  const previousPage = () => {
    setPage(page - 1)
    setError('')
  }

  const getOptimisationText = () => {
    if (page === 1) {
      return 'Select your base plan'
    }
    if (page === 2) {
      return 'Select up to two plans to compare'
    }
    if (page === 3) {
      return 'Select KPIs to compare'
    }
    if (page === 0) {
      return "Name your comparison"
    }
  }

  const getBudgetTotal = (plan: IPlanSummary) => {
    const optimisationType = plan['input_optimisation_type']

    let total = 0

    if (optimisationType === 'goal') {
      total = plan['transformations_spend_value']
    } else {
      if (plan['input_overall_constraint'] !== null) {
        total = plan['input_overall_constraint']
      } else {
        total = 0
      }
    }
    if (props.settings) {
      return appendUnit(
        total,
        'currency',
        plan.options.exchangeRates.defaultSymbol,
        props.settings.number_format
      )
    }
  }

  const getKPI = (plan: IPlanSummary) => {
      const key = plan.options.optimisableKPIs ? plan.output_keys.find(
        (x) => Object.keys(x)[0] === plan.options.optimisableKPIs[0]) : plan.output_keys[0]
      
      const firstKPI = key ? Object.values(key)[0].toLowerCase() : ''
  
      const firstKPIUnit = plan.unit_keys.find((x) => x.label === firstKPI)?.unit
  
      const firstKPIValue =
        props.settings &&
        firstKPI &&
        firstKPIUnit &&
        plan.calculated_totals &&
        plan.calculated_totals[firstKPI.replace(/ /g, '_')]
          ? appendUnit(
              plan.calculated_totals[firstKPI.replace(/ /g, '_')],
              firstKPIUnit,
              plan.options.exchangeRates.defaultSymbol,
              props.settings.number_format
            )
          : 'N/A'
      return firstKPIValue
  }

  const selectPlan = (id: string) => {
    if (page === 1) {
      const basePlan = props.plans.find((x: IPlanSummary) => x.id === id)
      const baseKPIs = basePlan!.output_keys.map((x: Key) => {
        const kpi = {
          key: Object.keys(x)[0],
          label: x[Object.keys(x)[0]],
        }
        return kpi
      })
      const basePlanWeeks = basePlan!.observations_max + 1 - basePlan!.observations_min - basePlan!.options.carryoverWeeks
      setBasePlanId(parseInt(id))
      setBasePlanWeeks(basePlanWeeks)
      setKPIs(baseKPIs)
      setSelectedKPIs([baseKPIs[0].key])
    } else {
      let plans: number[] = []
      if (comparedPlanIds.indexOf(parseInt(id)) === -1) {
        plans = [...comparedPlanIds, parseInt(id)]
      } else {
        plans = comparedPlanIds.filter((x) => x !== parseInt(id))
      }
      if (plans.length > 2) {
        plans.shift()
      }
      setComparedPlanIds(plans)
    }
  }

  const selectKPI = (e: any) => {
    let newSelectedKpis: string[] = []
    if (selectedKPIs.indexOf(e.target.value) === -1) {
      newSelectedKpis = [...selectedKPIs, e.target.value]
    } else {
      newSelectedKpis = selectedKPIs.filter((x) => x !== e.target.value)
    }
    setSelectedKPIs(newSelectedKpis)
  }

  const comparePlans = () => {
    if (selectedKPIs.length === 0) {
      setError('Please select at least one KPI to compare')
      return
    }
    if(props.user){
      const attrs: CreateComparisonAttrs = {
        name: comparisonName,
        plan_ids: [basePlanId].concat(comparedPlanIds),
        kpis: selectedKPIs,
        enabled: true,
        created_by: props.user.username
      }
      props.savePlanComparison(attrs)
    }
  }
  const getTableOutput = () => {
    if(page === 1 && props.plans){
      return props.plans.filter((x: IPlanSummary) => !x.options.in_channel)
    }
    if(page === 2 && props.plans){
      const allCrossChannel = props.plans.filter((x: IPlanSummary) => !x.options.in_channel)
      const filteredBase = allCrossChannel.filter((x: IPlanSummary) => x.id.toString() !== basePlanId.toString())
      const filteredByWeeks = filteredBase.filter((x: IPlanSummary) => {
        if(x.observations_max + 1 - x.observations_min - x.options.carryoverWeeks === basePlanWeeks){
          return x
        } 
      })

      const basePlan = props.plans.find((x: IPlanSummary) => x.id.toString() === basePlanId.toString())
      const filterByBasePlanCurrency = filteredByWeeks.filter((x: IPlanSummary) => {
        if(basePlan!.options.exchangeRates.currencies.indexOf(x.options.exchangeRates.defaultCurrency) !== -1){
          return x
        }
      })

      return filterByBasePlanCurrency
    }
    return []
  }

  const getUnavailablePlans = () => {
    if(props.plans){
      const allCrossChannel = props.plans.filter((x: IPlanSummary) => !x.options.in_channel)
      const filteredBase = allCrossChannel.filter((x: IPlanSummary) => x.id.toString() !== basePlanId.toString())
      const filteredByWeeks = filteredBase.filter((x: IPlanSummary) => {
        if(x.observations_max + 1 - x.observations_min - x.options.carryoverWeeks !== basePlanWeeks){
          return x
        } 
      })
      return filteredByWeeks
    }
    return []
  }

  return (
    <div className={page === 0 ? "flex flex-col h-screen place-content-center align-middle": ""}>
      <div>
        <h1 className="text-gray-500 my-6 content-center text-center text-xl">{getOptimisationText()}</h1>
      </div>
      {page === 3 &&
        KPIs.map((kpi, index) => {
          let checked = selectedKPIs.indexOf(kpi.key) !== -1
          return (
            <div key={index} className="check-box">
              <label className="">
                <input
                  type={'checkbox'}
                  onChange={selectKPI}
                  checked={checked}
                  value={kpi.key}
                />
                <div className="option">{kpi.label}</div>
              </label>
            </div>
          )
        })}
      {page === 0 && 
            <div className={'flex justify-center'}>
              <input placeholder={comparisonName === "New Comparison" || comparisonName === "" ? "Enter comparison name" : undefined} 
              value= {comparisonName === "New Comparison" || comparisonName === "" ? undefined : comparisonName} 
              className="p-2 w-72 rounded-md focus:border-gtPink focus:border focus:ring-4 focus:ring-opacity-20 focus:ring-gtPink focus:outline-none" 
              onChange={(e) => setComparisonName(e.target.value.replace(/[^a-zA-Z0-9\s]/gi, ''))}></input>
              
            </div>
          }
      {page !== 3 && (
        <div className={page !== 0 && page !== 3 ? "flex place-content-center mb-8" : "flex place-content-center mt-8"}>
          {page !== 0 && (
            <button
              onClick={previousPage}
              className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4 mr-4"
            >
              Previous
            </button>
          )}
          <button
            onClick={nextPage}
            className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
          >
            Next
          </button>
        </div>
      )}
      {error !== '' && (
        <div className="text-center mb-2 text-gtBluePrimary">{error}</div>
      )}
      {(page === 1 || page === 2) && props.plans && (
        <table className="min-w-full table-auto mb-32 divide-y divide-gray-300 border rounded text-gray-400">
          <thead>
            <tr className="p-2">
              <th></th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Plan Name</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Budget</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">KPIs</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Date Created</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Timeframe</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Number Of Weeks</th>
              <th />
            </tr>
          </thead>
          <tbody  className="bg-white divide-y divide-gray-200">
            {getTableOutput().map((plan: IPlanSummary) => {
              return (
                <tr className="p-2" key={plan.id} onClick={() => selectPlan(plan.id)}>
                  <td  className="px-6 py-4">
                  <div className="border-0 shadow-none">
                      <label className="">
                    <input
                      type="checkbox"
                      onChange={() => selectPlan(plan.id)}
                      checked={
                        page === 1
                          ? basePlanId.toString() === plan.id.toString()
                          : comparedPlanIds.indexOf(parseInt(plan.id)) !== -1
                      }
                    ></input>
                    </label>
                    </div>
                  </td>
                  <td className="px-6 py-4">{plan.name || 'Plan ' + plan.id}</td>
                  <td className="px-6 py-4">{getBudgetTotal(plan)}</td>
                  <td className="px-6 py-4">{getKPI(plan)}</td>
                  <td className="px-6 py-4">{plan.created_at}</td>
                  <td className="px-6 py-4">
                    {plan['config_observation_min_label']}
                    {' - '}
                    {plan['config_observation_max_label']}
                  </td>
                  <td className="px-6 py-4">{plan.observations_max + 1 - plan.observations_min - plan.options.carryoverWeeks}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page === 2 && (
        <table className="min-w-full table-auto mb-20 divide-y divide-gray-300 border rounded text-gray-400">
          <thead>
            <tr  className="p-2">
              <th></th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Unavaliable Plans</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Budget</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">KPIs</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Date Created</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Timeframe</th>
              <th scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500">Number Of Weeks</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {getUnavailablePlans().map((plan: IPlanSummary) => {
              return (
                <tr key={plan.id}>
                  <td>
                  </td>
                  <td className="px-6 py-4">{plan.name || 'Plan ' + plan.id}</td>
                  <td className="px-6 py-4">{getBudgetTotal(plan)}</td>
                  <td className="px-6 py-4">{getKPI(plan)}</td>
                  <td className="px-6 py-4">{plan.created_at}</td>
                  <td className="px-6 py-4">
                    {plan['config_observation_min_label']}
                    {' - '}
                    {plan['config_observation_max_label']}
                  </td>
                  <td className="px-6 py-4">{plan.observations_max + 1 - plan.observations_min - plan.options.carryoverWeeks}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      
      {page === 3 && (
        <div className="flex place-content-center mt-10">
          <button
            onClick={previousPage}
            className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4 mr-4"
          >
            Previous
          </button>
          <button
            onClick={comparePlans}
            className="clear-left rounded bg-gtPink text-white text-xs px-8 py-2 mt-4"
          >
            Create Comparison
          </button>
        </div>
      )}
      {page === 3 && (
        <div className="text-center my-4 text-gtPink">
          <Link to={ROUTES.SPO.INDEX}>Or, cancel</Link>
        </div>
      )}
    </div>
  )
}

export default connector(MainLayout(CreateComparison))
