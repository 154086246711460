import React, { FC } from 'react'

interface OwnProps {
  className: string
}

type Props = OwnProps

const RegionIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-186-5615h1214V194H-186z" />
        <g stroke="#666" strokeWidth="1.5">
          <path d="M63.25 32C63.25 14.744 49.257.75 32 .75 14.741.75.75 14.743.75 32 .75 49.263 14.738 63.25 32 63.25c17.26 0 31.25-13.988 31.25-31.25z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M54.8 11.26s-1.4 7.755-7 10.58c-5.6-1.412-11.2 2.826-9.8 2.826 1.4 0 2.8 5.65 2.8 5.65 1.4 1.412 4.2 0 4.2 0 3.5 3.531-5.6 9.888-5.6 11.3 0 1.413 3.5 2.12 1.4 4.238-2.1 2.119-4.2 2.825-4.2 2.825 0 7.063-7 7.063-8.4 7.063-1.4 0-4.2-5.65-4.2-7.063 0-1.412 1.4-2.825 1.4-4.238 0-1.412-2.8-4.237-2.8-4.237 0-5.65-4.2-4.238-8.4-4.238s-4.2-5.65-4.2-5.65 0-9.888 7-11.3c7-1.413 7 1.412 7 1.412 2.8 2.825 8.4 0 11.2 0 0 0 1.4-9.888-1.4-8.476-2.8 1.413-5.309.32-5.6-2.825-.406-4.413 12.216-7.062 12.216-7.062"
          />
        </g>
      </g>
    </svg>
  )
}

export default RegionIcon
