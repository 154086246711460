import { useMemo } from 'react'
import {
  Constraint,
  Identifier,
  Observation,
  Transformation,
} from '../../../../../types/PlanTypes'

interface IUseFilterConstraintsAndObservationsProps {
  constraints: Constraint[]
  transformationOptions: Transformation[]
  observations: Observation[]
  selectedObservations: number[]
}

export const useFilterConstraintsAndObservations = ({
  constraints,
  transformationOptions,
  observations,
  selectedObservations,
}: IUseFilterConstraintsAndObservationsProps) => {
  const filterTransformationOptionsForConstraint = (
    transformationOption: Transformation,
    constraintIdenfier: Identifier
  ) => {
    const { identifiers } = transformationOption
    const transformationIdentifier = identifiers as any as Identifier

    return Object.keys(transformationIdentifier)
      .filter((key) => key !== 'region_key')
      .every((key) => transformationIdentifier[key] === constraintIdenfier[key])
  }

  const filteredConstraints = useMemo(
    () =>
      constraints
        .filter((constraint) => {
          const { identifiers } = constraint
          const constraintIdenfier = identifiers as any as Identifier

          const filteredTransformationOptions = transformationOptions.filter(
            (option) =>
              filterTransformationOptionsForConstraint(
                option,
                constraintIdenfier
              )
          )

          return filteredTransformationOptions.length > 0
        })
        .map((constraint) => {
          const { values } = constraint
          const slicedValues = values
            .slice(1) // Removing first element because the first element of the values array is not used for time constraints
            .slice(selectedObservations[0], selectedObservations[1] + 1)

          return {
            ...constraint,
            values: slicedValues,
          }
        }),
    [constraints, transformationOptions, selectedObservations]
  )

  const filteredObservations = useMemo(
    () =>
      observations.slice(selectedObservations[0], selectedObservations[1] + 1),
    [observations, selectedObservations]
  )

  return { filteredConstraints, filteredObservations }
}
