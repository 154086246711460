import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/reducers/RootReducer'
import RegionIcon from '../../../icons/RegionIcon'
import Truncate from 'react-truncate'
import GenericBrandIcon from '../../../icons/spo/GenericBrandIcon'
import { GroupingLevel } from '../../../types/PlanTypes'

interface OwnProps {
  filtersText: string[]
  onEdit: (section: number) => void
}
type Props = OwnProps

const BrandSummary: FC<Props> = (props) => {
  const { planItem } = useSelector((state: AppState) => state.plans)
  const { settings, inChannelSettings } = useSelector((state: AppState) => state.settings)

  const brandIcons = () => {
    return props.filtersText.slice(0, 3).map((brand, index) => (
      <div key={index} className="rounded-full bg-gray-100 w-7 h-7">
        <GenericBrandIcon className="w-7 h-7" key={brand} />
      </div>
    ))
  }
  const editNumber = planItem.config.grouping_levels.findIndex((x: GroupingLevel) => x.key === "brand")

  return (
    <div className="text-neutral-gray-1100 text-xxs flex flex-col space-y-3 w-28 ">
      <div className="flex justify-between w-full">
        <span className="text-xxs font-bold">
          {!planItem.options.in_channel ? settings?.brand_plural.toUpperCase() : inChannelSettings!["brand"].plural.toUpperCase()}
        </span>
        {!planItem.locked_at && (
          <button
            onClick={(_) => props.onEdit(editNumber)}
            className="text-gtPink text-xxs"
          >
            edit
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-1">{brandIcons()}</div>
        <Truncate className="text-xxs">{props.filtersText.join(', ')}</Truncate>
      </div>
    </div>
  )
}

export default BrandSummary
