import { Dispatch } from 'redux'
import {
  SESSION_CREATE_ATTEMPT,
  SESSION_CREATE_FAIL,
  SESSION_CREATE_SUCCESS,
  SESSION_RECOVER_ATTEMPT,
  SESSION_RECOVER_FAIL,
  SESSION_RECOVER_SUCCESS,
  SessionActionTypes,
  GET_USERS_ATTEMPT,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  DELETE_USER_ATTEMPT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CREATE_USER_ATTEMPT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER_ATTEMPT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_GROUP_ATTEMPT,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  GET_GROUPS_ATTEMPT,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  CREATE_USER_GROUP_ATTEMPT,
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_FAIL,
  DELETE_USER_GROUP_ATTEMPT,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
  UPDATE_GROUP_ATTEMPT,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  UPDATE_PASSWORD_ATTEMPT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  SESSION_END_ATTEMPT,
  SESSION_END_SUCCESS,
  SESSION_END_FAIL,
} from './SessionActionTypes'
import api from '../../api'
import {
  CreateGroupAttrs,
  CreateUserAttrs,
  CreateUserGroupAttrs,
  UpdatePasswordAttrs,
} from '../../types/AdminTypes'
import { IGroup, IGroupUpdate } from '../../types'

export const CreateSession = (email: string, password: string) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: SESSION_CREATE_ATTEMPT })
  try {
    const res = await api.Session.createSession(email, password)
    dispatch({ type: SESSION_CREATE_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: SESSION_CREATE_FAIL, payload: "" }) //e.response.data
  }
}

export const RecoverSession = () => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: SESSION_RECOVER_ATTEMPT })

  try {
    const res = await api.Session.recoverSession()
    dispatch({ type: SESSION_RECOVER_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: SESSION_RECOVER_FAIL, payload: "" }) //e.response.data
  }
}

export const GetUsers = () => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: GET_USERS_ATTEMPT })

  try {
    const res = await api.Session.getUsers()

    dispatch({ type: GET_USERS_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: GET_USERS_FAIL })
  }
}

export const DeleteUser = (id: string) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: DELETE_USER_ATTEMPT })
  try {
    const res = await api.Session.deleteUser(id)
    dispatch({ type: DELETE_USER_SUCCESS })
  } catch (e) {
    dispatch({ type: DELETE_USER_FAIL })
  }
}

export const CreateUserAdmin = (attrs: any) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: CREATE_USER_ATTEMPT })
  try {
    const res = await api.Session.createUser(attrs)
    dispatch({ type: CREATE_USER_SUCCESS })
  } catch (e) {
    dispatch({ type: CREATE_USER_FAIL })
  }
}

export const UpdateUser = (attrs: any, id: string) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: UPDATE_USER_ATTEMPT })
  try {
    const res = await api.Session.updateUser(attrs, id)
    dispatch({ type: UPDATE_USER_SUCCESS })
  } catch (e) {
    dispatch({ type: UPDATE_USER_FAIL })
  }
}

export const CreateGroup = (attrs: CreateGroupAttrs) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: CREATE_GROUP_ATTEMPT })
  try {
    const res = await api.Session.createGroup(attrs)
    dispatch({ type: CREATE_GROUP_SUCCESS })
  } catch (e) {
    dispatch({ type: CREATE_GROUP_FAIL })
  }
}

export const GetGroups = () => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: GET_GROUPS_ATTEMPT })
  try {
    const res = await api.Session.getGroups()
    dispatch({ type: GET_GROUPS_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: GET_GROUPS_FAIL })
  }
}

export const CreateUserGroup = (attrs: CreateUserGroupAttrs) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: CREATE_USER_GROUP_ATTEMPT })
  try {
    const res = await api.Session.createUserGroup(attrs)
    dispatch({ type: CREATE_USER_GROUP_SUCCESS })
  } catch (e) {
    dispatch({ type: CREATE_USER_GROUP_FAIL })
  }
}

export const DeleteUserGroup = (id: number) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: DELETE_USER_GROUP_ATTEMPT })
  try {
    const res = await api.Session.deleteUserGroup(id)
    dispatch({ type: DELETE_USER_GROUP_SUCCESS })
  } catch (e) {
    dispatch({ type: DELETE_USER_GROUP_FAIL })
  }
}

export const UpdateGroup = (id: number, attrs: IGroupUpdate) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: UPDATE_GROUP_ATTEMPT })
  try {
    const res = await api.Session.updateGroup(id, attrs)
    dispatch({ type: UPDATE_GROUP_SUCCESS })
  } catch (e) {
    dispatch({ type: UPDATE_GROUP_FAIL })
  }
}

export const UpdatePassword = (attrs: UpdatePasswordAttrs) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: UPDATE_PASSWORD_ATTEMPT })
  try {
    const res = await api.Session.updatePassword(attrs)
    dispatch({ type: UPDATE_PASSWORD_SUCCESS })
  } catch (e) {
    dispatch({ type: UPDATE_PASSWORD_FAIL })
  }
}

export const EndSession = () => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  dispatch({ type: SESSION_END_ATTEMPT })
  try {
    const res = await api.Session.endSession()
    dispatch({ type: SESSION_END_SUCCESS })
  } catch (e) {
    dispatch({ type: SESSION_END_FAIL })
  }
}

export const CreateOktaSession = (accessToken: any) => async (
  dispatch: Dispatch<SessionActionTypes>
) => {
  try {
    const res = await api.Session.createOktaSession({
        token: accessToken
    })
    dispatch({ type: SESSION_CREATE_SUCCESS, payload: res.data })
  } catch (e) {}
}
